import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const PreviewPlayIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="32" cy="32" r="32" fill="#212B36" fillOpacity="0.6" />
      <path
        d="M24 21.7662C24 20.9889 24.848 20.5088 25.5145 20.9087L42.5708 31.1425C43.218 31.5309 43.218 32.4691 42.5708 32.8575L25.5145 43.0912C24.848 43.4912 24 43.0112 24 42.234V21.7662Z"
        fill="white"
      />
    </svg>
  );
});
