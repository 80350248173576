import styled, { css } from 'styled-components';

export const StyledFiltersBlockSkeleton = styled.div`
  grid-area: desktop-toolbar;
  background-color: ${p => p.theme.colors.Background};
  padding: 1rem 1.625rem;
  display: flex;
`;

export const StyledAppsBlockSkeleton = styled.div<{ isMobile?: boolean }>`
  grid-area: desktop-items;
  background-color: ${p => p.theme.colors.Surface};
  display: flex;
  flex-wrap: wrap;
  padding: ${({ isMobile }) => (isMobile ? '0 0.5rem 0.5rem' : '1rem')};
  flex: 1 1 0;
  height: ${p => (p.isMobile ? 'calc(100vh - 3.5rem)' : 'calc(100vh - 6rem)')};
  overflow: hidden;

  .appLinksWrapper {
    display: flex;
    flex-direction: column;
    flex: 3 1 0;
    overflow: hidden;
  }

  & > div {
    height: 100%;
  }
`;

export const StyledAppsHeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledAppsBlock = styled.div`
  margin: 1rem 0 2.75rem;
`;

export const StyledLinksBlock = styled.div`
  display: flex;
  margin: 2rem 0 0 1rem;

  .links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 1.75rem;
  }
`;

export const StyledLinkCardsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.75rem 0 0.5rem;

  & > div {
    width: 100%;
  }
`;

export const StyledChatBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2 1 0;
  margin-left: 1.5rem;
  max-width: 24rem;

  .chat-messages {
    margin-top: 1rem;
    padding: 0.5rem;

    & > * {
      position: relative;
    }
  }

  .chat-input {
    padding: 0.5rem 1rem;
  }
`;

export const StyledAppsViewSkeleton = styled.div`
  flex-grow: 1;
  background-color: ${p => p.theme.colors.Background};
`;

export const StyledAppsNavigation = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  padding: 0 0.25rem 1.5rem;

  ${p =>
    p.isMobile &&
    css`
      justify-content: center;
    `}
`;

export const StyledAppsLayoutGrid = styled.div<{ isMobile?: boolean }>`
  flex: 1;
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 8px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 112px);
  margin: 0 auto;

  @media (max-width: 376px) {
    grid-template-columns: repeat(auto-fill, 100px);

    & > div {
      width: 100px;
      min-width: 100px;
      height: 100px;
    }
  }

  ${({ theme: { breakpoints } }) => breakpoints.md`
    grid-template-columns: repeat(auto-fill, 116px);
    margin: unset;
  `}
`;
