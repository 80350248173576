export interface CustomNotificationsFormValues {
  messagesNotifications: MessagesNotificationsType;
  generalBellNotificationsEnable: boolean;
  billingNotificationsEnable: boolean;
  upcomingMeetingNotificationsEnable: boolean;
}

export enum MessagesNotificationsType {
  all = 'all',
  part = 'part',
  disable = 'disable',
}
