export enum DocumentPreviewTranslation {
  documentPreviewSomethingWentWrong = 'document_documentPreviewSomethingWentWrong',
  documentPreviewOpenDocumetDirectly = 'document_documentPreviewOpenDocumetDirectly',
  documentPreviewOpenDocumetDirectlyButton = 'document_documentPreviewOpenDocumetDirectlyButton',

  loadingPreviewTitle = 'document_loadingPreviewTitle',
}

export type DocumentPreviewLocale = {
  [key in DocumentPreviewTranslation]: string;
};
