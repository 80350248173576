import styled, { css } from 'styled-components';

export interface StyledMembersSidebarProps {
  addGridArea?: boolean;
}

export const MEMBERS_SIDEBAR_WIDTH = 238;

export const StyledMembersSidebar = styled.div<StyledMembersSidebarProps>`
  background-color: ${p => p.theme.colors.Surface};
  border-left: 1px solid ${p => p.theme.colors.SurfaceBorder};
  height: calc(100vh - var(--layout-topbar-height));
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: var(--layout-topbar-height, 0px);
  width: ${MEMBERS_SIDEBAR_WIDTH}px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  z-index: ${({ theme }) => theme.zIndexLevels.PeoplePanel};

  ${p =>
    p.addGridArea &&
    css`
      height: 100%;
      grid-area: desktop-sidebar;
    `};
`;
