export enum OnboardingTranslation {
  passwordLabel = 'onboarding_passwordLabel',
  passwordRequired = 'onboarding_passwordRequired',
  passwordMinimum = 'onboarding_passwordMinimum',
  passwordContainLetter = 'onboarding_passwordContainLetter',
  passwordContainNumber = 'onboarding_passwordContainNumber',
  repeatPasswordLabel = 'onboarding_repeatPasswordLabel',
  repeatPasswordRequired = 'onboarding_repeatPasswordRequired',
  repeatPasswordMinimum = 'onboarding_repeatPasswordMinimum',
  repeatPasswordContainLetter = 'onboarding_repeatPasswordContainLetter',
  repeatPasswordContainNumber = 'onboarding_repeatPasswordContainNumber',
  repeatPasswordMatch = 'onboarding_repeatPasswordMatch',

  registrationFormBannerItemAccessTitle = 'onboarding_registrationFormBannerItemAccessTitle',
  registrationFormBannerItemAccessDescription = 'onboarding_registrationFormBannerItemAccessDescription',
  registrationFormBannerItemOrganizeTitle = 'onboarding_registrationFormBannerItemOrganizeTitle',
  registrationFormBannerItemOrganizeDescription = 'onboarding_registrationFormBannerItemOrganizeDescription',
  registrationFormBannerItemCollaborateTitle = 'onboarding_registrationFormBannerItemCollaborateTitle',
  registrationFormBannerItemCollaborateDescription = 'onboarding_registrationFormBannerItemCollaborateDescription',
  registrationFormBannerItemCommunicateTitle = 'onboarding_registrationFormBannerItemCommunicateTitle',
  registrationFormBannerItemCommunicateDescription = 'onboarding_registrationFormBannerItemCommunicateDescription',
  registrationFormBannerItemSearchTitle = 'onboarding_registrationFormBannerItemSearchTitle',
  registrationFormBannerItemSearchDescription = 'onboarding_registrationFormBannerItemSearchDescription',

  billingPageBannerItemChargeNowTitle = 'onboarding_billingPageBannerItemChargeNowTitle',
  billingPageBannerItemChargeNowDescription = 'onboarding_billingPageBannerItemChargeNowDescription',
  billingPageBannerItemCancelTitle = 'onboarding_billingPageBannerItemCancelTitle',
  billingPageBannerItemCancelDescription = 'onboarding_billingPageBannerItemCancelDescription',
  billingPageBannerItemChangeMethodTitle = 'onboarding_billingPageBannerItemChangeMethodTitle',
  billingPageBannerItemChangeMethodDescription = 'onboarding_billingPageBannerItemChangeMethodDescription',
  billingPageSubmitButton = 'onboarding_billingPageSubmitButton',

  onboardingInvitationExpiredError = 'onboarding_onboardingInvitationExpiredError',
  onboardingRegistrationPageTitle = 'onboarding_onboardingRegistrationPageTitle',
  onboardingRegistrationPageFreeHeadTitle = 'onboarding_onboardingRegistrationPageFreeHeadTitle',
  onboardingRegistrationPageProHeadTitle = 'onboarding_onboardingRegistrationPageProHeadTitle',
  onboardingRegistrationPageInvitationTitle = 'onboarding_onboardingRegistrationPageInvitationTitle',
  onboardingRegistrationPageFreeTitle = 'onboarding_onboardingRegistrationPageFreeTitle',
  onboardingRegistrationPageProTitle = 'onboarding_onboardingRegistrationPageProTitle',
  onboardingRegistrationPageEnterpriseTitle = 'onboarding_onboardingRegistrationPageEnterpriseTitle',
  onboardingRegistrationPageGoogleButton = 'onboarding_onboardingRegistrationPageGoogleButton',
  onboardingRegistrationPageSeparator = 'onboarding_onboardingRegistrationPageSeparator',
  onboardingRegistrationFormFirstNameLabel = 'onboarding_onboardingRegistrationFormFirstNameLabel',
  onboardingRegistrationFormLastNameLabel = 'onboarding_onboardingRegistrationFormLastNameLabel',
  onboardingRegistrationFormNameRequired = 'onboarding_onboardingRegistrationFormNameRequired',
  onboardingRegistrationFormEmailLabel = 'onboarding_onboardingRegistrationFormEmailLabel',
  onboardingRegistrationFormEmailRequired = 'onboarding_onboardingRegistrationFormEmailRequired',
  onboardingRegistrationFormEmailInvalid = 'onboarding_onboardingRegistrationFormEmailInvalid',
  onboardingRegistrationPasswordLabel = 'onboarding_onboardingRegistrationPasswordLabel',
  onboardingRegistrationPasswordRequired = 'onboarding_onboardingRegistrationPasswordRequired',
  onboardingRegistrationFormPasswordMinimum = 'onboarding_onboardingRegistrationFormPasswordMinimum',
  onboardingRegistrationFormPasswordContainLetter = 'onboarding_onboardingRegistrationFormPasswordContainLetter',
  onboardingRegistrationFormPasswordContainNumber = 'onboarding_onboardingRegistrationFormPasswordContainNumber',
  onboardingRegistrationPasswordInvalid = 'onboarding_onboardingRegistrationPasswordInvalid',
  onboardingRegistrationFormSubmitButton = 'onboarding_onboardingRegistrationFormSubmitButton',
  onboardingRegistrationExternalProviderGoogle = 'onboarding_onboardingRegistrationExternalProviderGoogle',
  onboardingRegistrationExternalProviderFacebook = 'onboarding_onboardingRegistrationExternalProviderFacebook',
  onboardingRegistrationExternalProviderApple = 'onboarding_onboardingRegistrationExternalProviderApple',
  onboardingRegistrationExternalProviderMicrosoft = 'onboarding_onboardingRegistrationExternalProviderMicrosoft',
  onboardingRegistrationTermsOfUseMessage = 'onboarding_onboardingRegistrationTermsOfUseMessage',
  onboardingRegistrationTermsOfUseCheckboxRequired = 'onboarding_onboardingRegistrationTermsOfUseCheckboxRequired',
  onboardingRegistrationTermsOfUseLink = 'onboarding_onboardingRegistrationTermsOfUseLink',
  onboardingRegistrationPrivacyPolicyLink = 'onboarding_onboardingRegistrationPrivacyPolicyLink',
  onboardingRegistrationMarketingConsentLabel = 'onboarding_onboardingRegistrationMarketingConsentLabel',
  onboardingRegistrationCaptchaRequired = 'onboarding_onboardingRegistrationCaptchaRequired',
  onboardingRegistrationHelpText = 'onboarding_onboardingRegistrationHelpText',

  onboardingRegistrationFacebookWarningText = 'onboarding_onboardingRegistrationFacebookWarningText',
  onboardingRegistrationFacebookWarningConfirmLabel = 'onboarding_onboardingRegistrationFacebookWarningConfirmLabel',
  onboardingRegistrationFacebookWarningCancelLabel = 'onboarding_onboardingRegistrationFacebookWarningCancelLabel',

  onboardingExternalRegistrationTitle = 'onboarding_onboardingExternalRegistrationTitle',
  onboardingExternalRegistrationNoAccountMessage = 'onboarding_onboardingExternalRegistrationNoAccountMessage',
  onboardingExternalRegistrationPlanLabel = 'onboarding_onboardingExternalRegistrationPlanLabel',
  onboardingExternalRegistrationInvitationWorkspaceNameLabel = 'onboarding_onboardingExternalRegistrationInvitationWorkspaceNameLabel',
  onboardingExternalRegistrationPredefinedPlanLabel = 'onboarding_onboardingExternalRegistrationPredefinedPlanLabel',
  onboardingExternalRegistrationFormEmailLabel = 'onboarding_onboardingExternalRegistrationFormEmailLabel',
  onboardingExternalRegistrationFormSubmitButton = 'onboarding_onboardingExternalRegistrationFormSubmitButton',
  onboardingExternalRegistrationFormWrongEmailMessage = 'onboarding_onboardingExternalRegistrationFormWrongEmailMessage',

  onboardingActivationPageTitle = 'onboarding_onboardingActivationPageTitle',
  onboardingActivationPageHeading = 'onboarding_onboardingActivationPageHeading',
  onboardingActivationPageText = 'onboarding_onboardingActivationPageText',
  onboardingActivationPageImage = 'onboarding_onboardingActivationPageImage',
  onboardingVerificationEmailExpiredMessage = 'onboarding_onboardingActivationWorkspaceTokenExpired',
  onboardingActivationCompletedTitle = 'onboarding_onboardingActivationCompletedTitle',
  onboardingActivationCompletedText = 'onboarding_onboardingActivationCompletedText',
  onboardingActivationResendEmailText = 'onboarding_onboardingActivationResendEmailText',
  onboardingActivationResendEmailButton = 'onboarding_onboardingActivationResendEmailButton',
  onboardingActivationResendEmailSuccessMessage = 'onboarding_onboardingActivationResendEmailSuccessMessage',
  onboardingActivationCompletedButton = 'onboarding_onboardingActivationCompletedButton',

  onboardingAccountDetailsTitle = 'onboarding_onboardingAccountDetailsTitle',

  onboardingUseCasePageTitle = 'onboarding_onboardingUseCasePageTitle',
  onboardingUseCaseDescription = 'onboarding_onboardingUseCaseDescription',
  onboardingBusinessUseCaseTitle = 'onboarding_onboardingBusinessUseCaseTitle',
  onboardingBusinessUseCaseSubtitle = 'onboarding_onboardingBusinessUseCaseSubtitle',
  onboardingPersonalUseCaseTitle = 'onboarding_onboardingPersonalUseCaseTitle',
  onboardingPersonalUseCaseSubtitle = 'onboardingPersonalUseCaseSubtitle',
  onboardingUseCaseNextButton = 'onboarding_onboardingUseCaseNextButton',

  onboardingAboutYouPageTitle = 'onboarding_onboardingAboutYouPageTitle',
  onboardingAboutYouNextButton = 'onboarding_onboardingAboutYouNextButton',
  onboardingAboutYouJobLabel = 'onboarding_onboardingAboutYouJobLabel',
  onboardingAboutYouJobPlaceholder = 'onboarding_onboardingAboutYouJobPlaceholder',
  onboardingAboutYouSelectLabel = 'onboarding_onboardingAboutYouSelectLabel',
  onboardingAboutYouSelectPlaceholder = 'onboarding_onboardingAboutYouSelectPlaceholder',
  onboardingAboutYouJobRequired = 'onboarding_onboardingAboutYouJobRequired',
  onboardingAboutYouIndustryRequired = 'onboarding_onboardingAboutYouIndustryRequired',

  onboardingTeamSizePageTitle = 'onboarding_onboardingTeamSizePageTitle',
  onboardingYouTeamSizeTitle = 'onboarding_onboardingYouTeamSizeTitle',
  onboardingYouTeamSizeSubtitle = 'onboarding_onboardingYouTeamSizeSubtitle',
  onboardingSmallTeamSizeTitle = 'onboarding_onboardingSmallTeamSizeTitle',
  onboardingSmallTeamSizeSubtitle = 'onboarding_onboardingSmallTeamSizeSubtitle',
  onboardingMediumTeamSizeTitle = 'onboarding_onboardingMediumTeamSizeTitle',
  onboardingMediumTeamSizeSubtitle = 'onboarding_onboardingMediumTeamSizeSubtitle',
  onboardingLargeTeamSizeTitle = 'onboarding_onboardingLargeTeamSizeTitle',
  onboardingLargeTeamSizeSubtitle = 'onboarding_onboardingLargeTeamSizeSubtitle',

  onboardingChooseFeatureTitle = 'onboarding_onboardingChooseFeatureTitle',
  onboardingChooseFeatureDescription = 'onboarding_onboardingChooseFeatureDescription',
  onboardingOrganizeAndSearchFeatureTitle = 'onboarding_onboardingOrganizeAndSearchFeatureTitle',
  onboardingOrganizeAndSearchFeatureSubtitle = 'onboarding_onboardingOrganizeAndSearchFeatureSubtitle',
  onboardingCommunicateTitle = 'onboarding_onboardingCommunicateTitle',
  onboardingCommunicateSubtitle = 'onboarding_onboardingCommunicateSubtitle',
  onboardingChooseFeatureInfoToast = 'onboarding_onboardingChooseFeatureInfoToast',

  onboardingPlanPageTitle = 'onboarding_onboardingPlanPageTitle',
  onboardingPlanPageFree = 'onboarding_onboardingPlanPageFree',
  onboardingPlanPagePro = 'onboarding_onboardingPlanPagePro',
  onboardingPlanPageEnterprise = 'onboarding_onboardingPlanPageEnterprise',

  onboardingRequestResetPasswordTitle = 'onboarding_onboardingRequestResetPasswordTitle',
  onboardingRequestResetPasswordDescription = 'onboarding_onboardingRequestResetPasswordDescription',
  onboardingRequestResetPasswordFormEmailLabel = 'onboarding_onboardingRequestResetPasswordFormEmailLabel',
  onboardingRequestResetPasswordFormEmailPlaceholder = 'onboarding_onboardingRequestResetPasswordFormEmailPlaceholder',
  onboardingRequestResetPasswordFormEmailRequired = 'onboarding_onboardingRequestResetPasswordFormEmailRequired',
  onboardingRequestResetPasswordFormEmailInvalid = 'onboarding_onboardingRequestResetPasswordFormEmailInvalid',
  onboardingRequestResetPasswordFormEmailSubmitButton = 'onboarding_onboardingRequestResetPasswordFormEmailSubmitButton',
  onboardingRequestResetPasswordSuccessMessage = 'onboarding_onboardingRequestResetPasswordSuccessMessage',
  onboardingRequestResetPasswordWarningMessage = 'onboarding_onboardingRequestResetPasswordWarningMessage',

  onboardingResetPasswordTitle = 'onboarding_onboardingResetPasswordTitle',
  onboardingResetPasswordDescription = 'onboarding_onboardingResetPasswordDescription',
  onboardingResetPasswordFormPasswordLabel = 'onboarding_onboardingResetPasswordFormPasswordLabel',
  onboardingResetPasswordFormPasswordPlaceholder = 'onboarding_onboardingResetPasswordFormPasswordPlaceholder',
  onboardingResetPasswordFormPasswordRequired = 'onboarding_onboardingResetPasswordFormPasswordRequired',
  onboardingResetPasswordFormPasswordMinimum = 'onboarding_onboardingResetPasswordFormPasswordMinimum',
  onboardingResetPasswordFormPasswordContainLetter = 'onboarding_onboardingResetPasswordFormPasswordContainLetter',
  onboardingResetPasswordFormPasswordContainNumber = 'onboarding_onboardingResetPasswordFormPasswordContainNumber',
  onboardingResetPasswordFormRepeatPasswordLabel = 'onboarding_onboardingResetPasswordFormRepeatPasswordLabel',
  onboardingResetPasswordFormRepeatPasswordPlaceholder = 'onboarding_onboardingResetPasswordFormRepeatPasswordPlaceholder',
  onboardingResetPasswordFormRepeatPasswordRequired = 'onboarding_onboardingResetPasswordFormRepeatPasswordRequired',
  onboardingResetPasswordFormRepeatPasswordMinimum = 'onboarding_onboardingResetPasswordFormRepeatPasswordMinimum',
  onboardingResetPasswordFormRepeatPasswordContainLetter = 'onboarding_onboardingResetPasswordFormRepeatPasswordContainLetter',
  onboardingResetPasswordFormRepeatPasswordContainNumber = 'onboarding_onboardingResetPasswordFormRepeatPasswordContainNumber',
  onboardingResetPasswordFormRepeatPasswordMatch = 'onboarding_onboardingResetPasswordFormRepeatPasswordMatch',
  onboardingResetPasswordSuccessMessage = 'onboarding_onboardingResetPasswordSuccessMessage',
  onboardingResetPasswordSuccessButton = 'onboarding_onboardingResetPasswordSuccessButton',
  onboardingResetPasswordInvalidLink = 'onboarding_onboardingResetPasswordInvalidLink',
  onboardingResetPasswordFormSubmitButton = 'onboarding_onboardingResetPasswordFormSubmitButton',
  onboardingResetPasswordHelpText = 'onboarding_onboardingResetPasswordHelpText',

  onboardingPageLoginText = 'onboarding_onboardingPageLoginText',
  onboardingPageLoginLink = 'onboarding_onboardingPageLoginLink',
  onboardingPageLogoutText = 'onboarding_onboardingPageLogoutText',
  onboardingPageLogoutLink = 'onboarding_onboardingPageLogoutLink',
  onboardingInvitationPageTitle = 'onboarding_onboardingInvitationPageTitle',
  onboardingInvitationPageInvitationValid = 'onboarding_onboardingInvitationPageInvitationValid',
  onboardingInvitationPageInvitationNotValid = 'onboarding_onboardingInvitationPageInvitationNotValid',
  onboardingInvitationPageAcceptWithCurrentAccount = 'onboarding_onboardingInvitationPageAcceptWithCurrentAccount',
  onboardingInvitationPageAcceptWithNewAccount = 'onboarding_onboardingInvitationPageAcceptWithNewAccount',
  onboardingInvitationPageAcceptWithAnotherExistingAccount = 'onboarding_onboardingInvitationPageAcceptWithAnotherExistingAccount',

  onboardingLinkAccountHeader = 'onboarding_onboardingLinkAccountHeader',
  onboardingLinkAccountLinkButton = 'onboarding_onboardingLinkAccountLinkButton',
  onboardingLinkAccountCancelButton = 'onboarding_onboardingLinkAccountCancelButton',
  onboardingLinkAccountSuccessMessage = 'onboarding_onboardingLinkAccountSuccessMessage',
  onboardingLinkAccountCancelMessage = 'onboarding_onboardingLinkAccountCancelMessage',

  onboardingJoinChatHeader = 'onboarding_onboardingJoinChatHeader',
  onboardingJoinChatDescription = 'onboarding_onboardingJoinChatDescription',
  onboardingJoinChatOptionNewDesktopAccountButton = 'onboarding_onboardingJoinChatOptionNewDesktopAccountButton',
  onboardingJoinChatOptionExistingDesktopAccountButton = 'onboarding_onboardingJoinChatOptionExistingDesktopAccountButton',
  onboardingJoinChatOptionSlackAccountButton = 'onboarding_onboardingJoinChatOptionSlackAccountButton',
  onboardingJoinChatOptionSlackLogoAlt = 'onboarding_onboardingJoinChatOptionSlackLogoAlt',
  onboardingJoinChatOptionSlackUnavailable = 'onboarding_onboardingJoinChatOptionSlackUnavailable',
  onboardingJoinChatOptionTeamsAccountButton = 'onboarding_onboardingJoinChatOptionTeamsAccountButton',
  onboardingJoinChatOptionTeamsLogoAlt = 'onboarding_onboardingJoinChatOptionTeamsLogoAlt',
  onboardingJoinChatInviteIdError = 'onboarding_onboardingJoinChatInviteIdError',
  onboardingJoinChatOptionsSeparatorMessage = 'onboarding_onboardingJoinChatOptionsSeparatorMessage',
  onboardingJoinChatSuccessHeader = 'onboarding_onboardingJoinChatSuccessHeader',
  onboardingJoinChatSuccessDescription = 'onboarding_onboardingJoinChatSuccessDescription',
  onboardingJoinChatSuccessOpenSlackButton = 'onboarding_onboardingJoinChatSuccessOpenSlackButton',
  onboardingJoinChatErrorHeader = 'onboarding_onboardingJoinChatErrorHeader',
  onboardingJoinChatErrorDescription = 'onboarding_onboardingJoinChatErrorDescription',

  verifyEmailChangeTitle = 'onboarding_verifyEmailChangeHeaderTitle',
  verifyEmailChangeHeader = 'onboarding_verifyEmailChangeHeader',
  verifyEmailChangeDescription = 'onboarding_verifyEmailChangeDescription',
  verifyEmailChangeWarning = 'onboarding_verifyEmailChangeWarning',
  verifyEmailChangePasswordInputLabel = 'onboarding_verifyEmailChangePasswordInputLabel',
  verifyEmailChangePasswordInputRequired = 'onboarding_verifyEmailChangePasswordInputRequired',
  verifyEmailChangePasswordIncorrectErrorMessage = 'onboarding_verifyEmailChangePasswordIncorrectErrorMessage',
  verifyEmailChangeVerifyButton = 'onboarding_verifyEmailChangeVerifyButton',
  verifyEmailChangeSuccessMessage = 'onboarding_verifyEmailChangeSuccessMessage',
  verifyEmailChangeIncorrectRequestErrorMessage = 'onboarding_verifyEmailChangeIncorrectRequestErrorMessage',
  verifyEmailChangeUnauthorisedErrorMessage = 'onboarding_verifyEmailChangeUnauthorisedErrorMessage',
  verifyEmailChangeUnauthorisedLoginButton = 'onboarding_verifyEmailChangeUnauthorisedLoginButton',

  inviteUsersPageTitle = 'onboarding_inviteUsersPageTitle',
  inviteUsersPageHeading = 'onboarding_inviteUsersPageHeading',
  inviteUsersPageDescription = 'onboarding_inviteUsersPageDescription',
  inviteUsersSkipButton = 'onboarding_inviteUsersSkipButton',
  inviteUserInviteButton = 'onboarding_inviteUserInviteButton',

  installPluginPageTitle = 'onboarding_installPluginPageTitle',
  installPluginPageHeading = 'onboarding_installPluginPageHeading',

  onboardingEndScreenTitle = 'onboarding_endScreenTitle',
  onboardingEndScreenHeading = 'onboarding_endScreenHeading',
  onboardingEndScreenDescription = 'onboarding_endScreenDescription',
  onboardingEndScreenButtonLabel = 'onboarding_endScreenButtonLabel',

  onboardingCreateWorkspaceTitle = 'onboarding_createWorkspaceTitle',
  onboardingCreateWorkspaceHeading = 'onboarding_createWorkspaceHeading',
  onboardingCreateWorkspaceDescription = 'onboarding_createWorkspaceDescription',
  onboardingCreateWorkspaceUrl = 'onboarding_onboardingCreateWorkspaceUrl',
  onboardingStepsCount = 'onboarding_onboardingStepsCount',
  onboardingExampleWorkspaceName = 'onboarding_exampleWorkSpaceName',

  addContentPageTitle = 'onboarding_addContentPageTitle',
  addContentPageHeading = 'onboarding_addContentPageHeading',
  addContentPageDescription = 'onboarding_addContentPageDescription',
  addContentApplyTemplatesButton = 'onboarding_addContentApplyTemplatesButton',
  addContentSkipButton = 'onboarding_addContentSkipButton',
  addContentPlatformTitle = 'onboarding_addContentPlatformTitle',

  resetMfaPageTitle = 'onboarding_resetMfaPageTitle',
  resetMfaPageConfirmButton = 'onboarding_resetMfaPageConfirmButton',
}

export type OnboardingLocale = {
  [key in OnboardingTranslation]: string;
};
