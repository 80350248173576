import React from 'react';
import { NativeWrapperContextType } from './NativeWrapper.types';

export const NativeWrapperContext = React.createContext<NativeWrapperContextType>(
  {
    isNativeWrapperAvailable: false,
    sendMessageToNativeWrapper: () => false,
    callRefreshToken: () => Promise.reject(),
    callTriggerMfa: () => Promise.reject(),
    initialAccessToken: '',
    subscribeToMessages: () => ({
      remove: () => {},
    }),
  },
);
