import { ApolloClient } from '@apollo/client';
import {
  StreamAccountWorkspaceCreateEvent,
  StreamAccountWorkspaceUpdateEvent,
} from '../../GeneralMercure.types';
import {
  AccountApiType,
  AccountOrigin,
  AvailabilityStatusType,
} from '../../../../User/User.types';
import type { DesktopApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';
import { getDesktopByIdFromIDDB } from '../../../../Desktop/data/Desktop/dataSources/DesktopIDDB.dataSource';
import { deleteAccountFromWorkspace } from '../../../../Account/data/Account/operations/AccountIDDB.operations';

export const accountWorkspaceRequest = (
  event: StreamAccountWorkspaceUpdateEvent,
  apolloClient: ApolloClient<object>,
  updateCurrentAccount: (
    updater: (account: AccountApiType) => AccountApiType,
  ) => void,
  updateAccount: (
    accountId: string,
    accountUpdater: (_: AccountApiType) => AccountApiType,
  ) => Promise<void>,
  refetchWorkspaceAccount: () => void,
  refetchAccounts: () => void,
) => {
  const { account } = event;

  if (event['@request'] === 'new') {
    refetchAccounts();
  }
  if (event['@request'] === 'update') {
    if (account.origin === AccountOrigin.webtopcom) {
      account.onlineStatus = AvailabilityStatusType.Online;
    }

    updateCurrentAccount(currentAccount => ({
      ...currentAccount,
      ...(currentAccount.id === account.id && account),
    }));

    updateAccount(account.id, accountInIDDB => ({
      ...accountInIDDB,
      ...(accountInIDDB.id === account.id && account),
    }));

    refetchWorkspaceAccount();
  }

  if (event['@request'] === 'remove') {
    deleteAccountFromWorkspace(event.account.id, event.workspace.id);
  }
};

export const accountWorkspaceCreatedRequest = async (
  event: StreamAccountWorkspaceCreateEvent,
  updateDesktopIDDB: (
    desktopId: string,
    variables: Partial<DesktopApiType>,
  ) => Promise<void>,
  refetchAccounts: () => void,
) => {
  if (event['@request'] === 'new') {
    const {
      accountWorkspace: {
        account: { id: accountId },
      },
      desktopIri,
    } = event;

    refetchAccounts();

    if (desktopIri) {
      const cachedDesktop = await getDesktopByIdFromIDDB(desktopIri);
      if (cachedDesktop) {
        updateDesktopIDDB(desktopIri, {
          accountIds: [...cachedDesktop.sourceData.accountIds, accountId],
        });
      }
    }
  }
};
