export enum AppStoreTranslation {
  appCardAddButton = 'appStore_appCardAddButton',
  appCardDeleteButton = 'appStore_appCardDeleteButton',
  appStoreTabFilter = 'appStore_appStoreTabFilter',
  personalTabFilter = 'appStore_personalTabFilter',
  companyTabFilter = 'appStore_companyTabFilter',
  customAppsTabFilter = 'appStore_customAppsTabFilter',
  customAppsIntegrationsTabFilter = 'appStore_customAppsIntegrationsTabFilter',
  myPasswordsTabFilter = 'appStore_myPasswordsTabFilter',

  appMenu = 'appStore_appMenu',
  editCustomAppButton = 'appStore_editCustomAppButton',
  editCustomAppNotification = 'appStore_editCustomAppNotification',
  deleteCustomAppButton = 'appStore_deleteCustomAppButton',
  deleteCustomAppConfirmation = 'appStore_deleteCustomAppConfirmation',
  deleteCustomAppNotification = 'appStore_deleteCustomAppNotification',

  categoriesSectionTitle = 'appStore_categoriesSectionTitle',

  appsSectionTitle = 'appStore_appsSectionTitle',
  appsSectionSearchPlaceholder = 'appStore_appsSectionSearchPlaceholder',
  noAppsAvailable = 'appStore_noAppsAvailable',
  noAppsAvailableMyPassword = 'appStore_noAppsAvailableMyPassword',
  noAppsAvailableCustomApps = 'appStore_noAppsAvailableCustomApps',
  noAppsAvailablePrivateApps = 'appStore_noAppsAvailablePrivateApps',
  noAppsAvailableComapny = 'appStore_noAppsAvailableComapny',
  noAppsAvailableCategory = 'appStore_noAppsAvailableCategory',
  noAppsAvailableFilter = 'appStore_noAppsAvailableFilter',

  customAppBannerTitle = 'appStore_customAppBannerTitle',
  customAppBannerDescription = 'appStore_customAppBannerDescription',
  customAppBannerButton = 'appStore_customAppBannerTitle',

  customAppFormCreateButton = 'appStore_customAppFormCreateButton',
  customAppFormUpdateButton = 'appStore_customAppFormUpdateButton',
  customAppFormManagePasswordsButton = 'appStore_customAppFormManagePasswordsButton',
  customAppFormManagePasswordsDisabledTooltip = 'appStore_customAppFormManagePasswordsDisabledTooltip',
  customAppFormNameLabel = 'appStore_customAppFormNameLabel',
  customAppFormNamePlaceholder = 'appStore_customAppFormNamePlaceholder',
  customAppFormNameRequired = 'appStore_customAppFormNameRequired',
  customAppFormUrlRequired = 'appStore_customAppFormUrlRequired',
  customAppFormUrlIsNotValid = 'appStore_customAppFormUrlIsNotValid',
  customAppFormUrlLabel = 'appStore_customAppFormUrlLabel',
  customAppFormUrlPlaceholder = 'appStore_customAppFormUrlPlaceholder',
  customAppFormUrlInvalid = 'appStore_customAppFormUrlInvalid',
  customAppFormUrlIncomplete = 'appStore_customAppFormUrlUnchanged',
  customAppFormCategoryLabel = 'appStore_customAppFormCategoryLabel',
  customAppFormCategoryPlaceholder = 'appStore_customAppFormCategoryPlaceholder',
  customAppFormCategoryRequired = 'appStore_customAppFormCategoryRequired',
  customAppFormLogoLabel = 'appStore_customAppFormLogoLabel',
  customAppFormLogoButton = 'appStore_customAppFormLogoButton',
  customAppFormFetchAppImageByUrl = 'appStore_customAppFormFetchAppImageByUrl',
  customAppFormPreviewInfo = 'appStore_customAppFormPreviewInfo',
  customAppFormPreviewError = 'appStore_customAppFormPreviewError',
  customAppFormLogoRequired = 'appStore_customAppFormLogoRequired',

  createCustomAppModalHeader = 'appStore_createCustomAppModalHeader',
  createCustomAppSuccessMessage = 'appStore_createCustomAppSuccessMessage',

  editCustomAppModalHeader = 'appStore_editCustomAppModalHeader',
  editCustomAppSuccessMessage = 'appStore_editCustomAppSuccessMessage',

  customAppPreviewHeader = 'appStore_customAppPreviewHeader',
  customAppPreviewSuccessMessage = 'appStore_customAppPreviewSuccessMessage',
  customAppPreviewDesktopButton = 'appStore_customAppPreviewDesktopButton',
  customAppAddedToDesktop = 'appStore_customAppAddedToDesktop',
  customAppPreviewContinueButton = 'appStore_customAppPreviewContinueButton',

  ssoAppTabName = 'appStore_ssoAppTabName',
  ssoAppGridTitle = 'appStore_ssoAppGridTitle',
  ssoAppGridSubtitle = 'appStore_ssoAppGridSubtitle',
  ssoAppGridIntroText = 'appStore_ssoAppGridIntroText',
  ssoAppGridCustomAppSsoMessage = 'appStore_ssoAppGridCustomAppSsoMessage',
  ssoAppGridCustomAppSsoButton = 'appStore_ssoAppGridCustomAppSsoButton',
  ssoAppGridConfiguredTitle = 'appStore_ssoAppGridConfiguredTitle',
  ssoAppGridPredefinedTitle = 'appStore_ssoAppGridPredefinedTitle',
  ssoAppGridCompatibleTitle = 'appStore_ssoAppGridCompatibleTitle',
  ssoAppGridPredefinedSubtitle = 'appStore_ssoAppGridPredefinedSubtitle',
  ssoAppGridCompatibleSubtitle = 'appStore_ssoAppGridCompatibleSubtitle',
  ssoAppGridCompatibleEmptyText = 'appStore_ssoAppGridCompatibleEmptyText',
  ssoAppCardLabelsConfigure = 'appStore_ssoAppCardLabelsConfigure',
  ssoAppCardLabelsEdit = 'appStore_ssoAppCardLabelsEdit',
  ssoAppCardLabelsEnable = 'appStore_ssoAppCardMenuEnable',
  ssoAppCardLabelsEnabled = 'appStore_ssoAppCardLabelsEnabled',
  ssoAppCardLabelsEnabledForApps = 'appStore_ssoAppCardLabelsEnabledForApps',
  ssoAppCardLabelsDisable = 'appStore_ssoAppCardMenuDisable',
  ssoAppCardLabelsDisabled = 'appStore_ssoAppCardLabelsDisabled',
  ssoAppCardLabelsDisabledForApps = 'appStore_ssoAppCardLabelsDisabledForApps',
  ssoAppCardLabelsSave = 'appStore_ssoAppCardLabelsSave',
  ssoAppCardLabelsInitialize = 'appStore_ssoAppCardLabelsInitialize',
  ssoAppCardLabelsCopy = 'appStore_ssoAppCardLabelsCopy',
  ssoAppConfigureCompatibleApps = 'appStore_ssoAppConfigureCompatibleApps',
  ssoAppConfigureFieldRequired = 'appStore_ssoAppConfigureFieldRequired',
  ssoAppConfigureFieldRequiredEnabled = 'appStore_ssoAppConfigureFieldRequiredEnabled',
  ssoAppDangerZoneTitle = 'appStore_ssoAppDangerZoneTitle',
  ssoAppDangerZoneDeleteButton = 'appStore_ssoAppDangerZoneDeleteButton',
  ssoAppDangerZoneDeleteDescription = 'appStore_ssoAppDangerZoneDeleteDescription',
  ssoAppMessagesConfigurationCreated = 'appStore_ssoAppMessagesConfigurationCreated',
  ssoAppMessagesConfigurationUpdated = 'appStore_ssoAppMessagesConfigurationUpdated',
  ssoAppMessagesConfigurationEnabled = 'appStore_ssoAppMessagesConfigurationEnabled',
  ssoAppMessagesConfigurationDisabled = 'appStore_ssoAppMessagesConfigurationDisabled',
  ssoAppMessagesConfigurationDeleted = 'appStore_ssoAppMessagesConfigurationDeleted',
  ssoAppConfirmationConfigurationDelete = 'appStore_ssoAppConfirmationConfigurationDelete',
  customSsoClaimsNameIdentifier = 'appStore_customSsoClaims_name_identifier',
  customSsoClaimsEmail = 'appStore_customSsoClaims_email',
  customSsoClaimsGivenName = 'appStore_customSsoClaims_given_name',
  customSsoClaimsFamilyName = 'appStore_customSsoClaims_family_name',
  customSsoClaimsCustom = 'appStore_customSsoClaims_custom',
  customSsoClaimValuesUserId = 'appStore_customSsoClaimValues_user_id',
  customSsoClaimValuesEmail = 'appStore_customSsoClaimValues_email',
  customSsoClaimValuesGivenName = 'appStore_customSsoClaimValues_given_name',
  customSsoClaimValuesFamilyName = 'appStore_customSsoClaimValues_family_name',
  customSsoNameIdFormatLabel = 'appStore_customSsoNameIdFormatLabel',
  customSsoClaimNameLabel = 'appStore_customSsoCustomClaimNameLabel',
  customSsoClaimValueLabel = 'appStore_customSsoCustomClaimValueLabel',
  customSsoAddClaimButton = 'appStore_customSsoAddClaimButton',
}

export type AppStoreLocale = {
  [key in AppStoreTranslation]: string;
};
