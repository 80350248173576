import { ThemeType } from './../../../../domains/Theme/Theme.types';
import { EditorThemesType } from './Editor.theme.types';

export const EditorTheme: EditorThemesType = {
  [ThemeType.light]: {
    colors: {
      PlaceholderTextColor: '#b8bdc2',
      MentionBackgroundColor: 'rgba(54, 159, 255, 0)',
    },
  },
  [ThemeType.dark]: {
    colors: {
      PlaceholderTextColor: '#626c75',
      MentionBackgroundColor: 'rgba(54, 159, 255, 0)',
    },
  },
};
