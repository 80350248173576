import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const LaunchIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 1.25V5.396C16.0001 5.44551 15.9855 5.49393 15.958 5.53513C15.9305 5.57632 15.8915 5.60843 15.8457 5.62739C15.8 5.64635 15.7497 5.6513 15.7011 5.64162C15.6525 5.63194 15.608 5.60805 15.573 5.573L14.03 4.03L10.28 7.78C10.2113 7.85369 10.1285 7.91279 10.0365 7.95378C9.94454 7.99477 9.84523 8.01682 9.74452 8.01859C9.64382 8.02037 9.54379 8.00184 9.4504 7.96412C9.35701 7.9264 9.27218 7.87026 9.20096 7.79904C9.12974 7.72782 9.0736 7.64299 9.03588 7.5496C8.99816 7.45621 8.97963 7.35618 8.98141 7.25548C8.98318 7.15478 9.00523 7.05546 9.04622 6.96346C9.08721 6.87146 9.14631 6.78866 9.22 6.72L12.97 2.97L11.427 1.427C11.3919 1.39204 11.3681 1.34745 11.3584 1.2989C11.3487 1.25034 11.3536 1.20001 11.3726 1.15427C11.3916 1.10853 11.4237 1.06945 11.4649 1.04199C11.5061 1.01453 11.5545 0.999912 11.604 1H15.75C15.8163 1 15.8799 1.02634 15.9268 1.07322C15.9737 1.12011 16 1.1837 16 1.25Z"
          fill="currentColor"
          fillOpacity="0.87"
        />
        <path
          d="M3.75 3.5C3.6837 3.5 3.62011 3.52634 3.57322 3.57322C3.52634 3.62011 3.5 3.6837 3.5 3.75V12.25C3.5 12.388 3.612 12.5 3.75 12.5H4.75H8.75H12.25C12.3163 12.5 12.3799 12.4737 12.4268 12.4268C12.4737 12.3799 12.5 12.3163 12.5 12.25V8.75C12.5 8.55109 12.579 8.36032 12.7197 8.21967C12.8603 8.07902 13.0511 8 13.25 8C13.4489 8 13.6397 8.07902 13.7803 8.21967C13.921 8.36032 14 8.55109 14 8.75V12.25C14 12.7141 13.8156 13.1592 13.4874 13.4874C13.1592 13.8156 12.7141 14 12.25 14H9.06H4H3.75C3.28587 14 2.84075 13.8156 2.51256 13.4874C2.18437 13.1592 2 12.7141 2 12.25V3.75C2 2.784 2.784 2 3.75 2H8.25C8.44891 2 8.63968 2.07902 8.78033 2.21967C8.92098 2.36032 9 2.55109 9 2.75C9 2.94891 8.92098 3.13968 8.78033 3.28033C8.63968 3.42098 8.44891 3.5 8.25 3.5H3.75Z"
          fill="currentColor"
          fillOpacity="0.87"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
});
