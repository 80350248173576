import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import delay from 'delay';
import { format } from 'date-fns';
import {
  IDLE_DETECTOR_IDLE_COEFFICIENT,
  IDLE_DETECTOR_INTERVAL,
  PING_MERCURE_MAX_ATTEMPTS,
  PING_MERCURE_RESPONSE_WAITING_TIME,
  PING_MERCURE_RETRY_INTERVAL,
} from './IdleDetectionProvider.constants';
import { usePingMercureMutation } from './IdleDetectionProvider.hooks';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  StreamEventActionRequest,
  StreamEventType,
} from '../../../Mercure/General';
import { captureException } from '../../../ErrorInterceptor';
import { useMercureListener } from '../../../Mercure/General/GeneralMercure.hooks';

export const IdleDetectionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { refetchAccountData } = useCurrentAccount();
  const [pingMercureMutation] = usePingMercureMutation();

  const pongEventStatusRef = useRef<{
    data: string;
    waiting: boolean;
    timerId: ReturnType<typeof setTimeout> | null;
  }>({
    data: '',
    waiting: false,
    timerId: null,
  });

  const logIdleDetectorEvent = useCallback((event: string) => {
    if (window.DESKTOPCOM_LOG_IDLE_DETECTOR_EVENTS) {
      console.log(
        `[${format(new Date(), 'hh:mm:ss.SSS')}] Idle Detector:`,
        event,
      );
    }
  }, []);

  const sendPingToMercure = useCallback(() => {
    const pingMercure = (data: string, attempt = 1): Promise<unknown> => {
      if (attempt > PING_MERCURE_MAX_ATTEMPTS) {
        return Promise.reject();
      }
      return pingMercureMutation({
        variables: {
          data,
        },
      }).catch(e => {
        if (e.networkError) {
          return delay(PING_MERCURE_RETRY_INTERVAL * attempt).then(() =>
            pingMercure(data, attempt + 1),
          );
        }
        throw new Error(e);
      });
    };

    const pingData = Math.random().toString();
    pongEventStatusRef.current.data = pingData;
    pongEventStatusRef.current.waiting = true;

    logIdleDetectorEvent('Trying to send ping, waiting for pong...');

    pingMercure(pingData)
      .then(() => {
        pongEventStatusRef.current.timerId = setTimeout(() => {
          if (pongEventStatusRef.current.waiting) {
            logIdleDetectorEvent(
              'Failed to receive pong event, re-connecting Mercure.',
            );
            refetchAccountData?.();
          }
          pongEventStatusRef.current.waiting = false;
          pongEventStatusRef.current.data = '';
        }, PING_MERCURE_RESPONSE_WAITING_TIME);
      })
      .catch(e => {
        logIdleDetectorEvent('Failed to send ping.');
        captureException(e, 'Unable to trigger ping event');
        pongEventStatusRef.current.waiting = false;
        pongEventStatusRef.current.data = '';
      });
  }, [logIdleDetectorEvent, pingMercureMutation, refetchAccountData]);

  const { addListener, removeListener } = useMercureListener();

  useEffect(() => {
    const listener: Parameters<typeof addListener>[0] = e => {
      if (!pongEventStatusRef.current.waiting) {
        return;
      }

      if (
        e['@type'] === StreamEventType.ACTION &&
        e['@request'] === StreamEventActionRequest.PONG_MERCURE &&
        e['@payload'].status === pongEventStatusRef.current.data
      ) {
        logIdleDetectorEvent(
          'Pong event received successfully, nothing to do.',
        );

        if (pongEventStatusRef.current.timerId) {
          clearTimeout(pongEventStatusRef.current.timerId);
        }
        pongEventStatusRef.current.data = '';
        pongEventStatusRef.current.waiting = false;
        pongEventStatusRef.current.timerId = null;
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [addListener, logIdleDetectorEvent, removeListener]);

  useEffect(() => {
    let lastIntervalTime = new Date().getTime();
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      if (
        currentTime >
        lastIntervalTime +
          IDLE_DETECTOR_INTERVAL * IDLE_DETECTOR_IDLE_COEFFICIENT
      ) {
        // app was idle
        logIdleDetectorEvent('Idle time detected, sending ping...');
        sendPingToMercure();
      }
      lastIntervalTime = currentTime;
    }, IDLE_DETECTOR_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [logIdleDetectorEvent, sendPingToMercure]);

  return <>{children}</>;
};
