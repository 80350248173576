import styled from 'styled-components';
import React, { FC } from 'react';

const StyledMenuSeparator = styled.div<{ margin: string }>`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.Divider};
  margin: ${p => p.margin};
`;

export const MenuSeparator: FC<{ margin?: string }> = React.memo(
  ({ margin = '4px 0' }) => {
    return (
      <li>
        <StyledMenuSeparator margin={margin} />
      </li>
    );
  },
);
