import React from 'react';
import { PingEvent } from './PingMercure.types';

export interface PingMercureContextType {
  addListener: (listener: (e: PingEvent) => void) => void;
  removeListener: (listener: (e: PingEvent) => void) => void;
}

export const PingMercureContext = React.createContext<PingMercureContextType>({
  addListener: () => {},
  removeListener: () => {},
});
