import { gql } from '@apollo/client';
import {
  DesktopAppFragmentFields,
  DesktopFragmentWithAccessFieldsNoFilter,
} from './Desktop.fragments';
import { DesktopTab } from './Desktop.constants';
import type {
  DesktopApiType,
  DesktopAppSortType,
  DesktopCreateInputType,
  DesktopEditInputType,
  DesktopSortType,
  EditHidingChatInputType,
} from './data/Desktop/types/Desktop.types';

export const CREATE_DESKTOP = gql`
  mutation CreateDesktop($input: createDesktopInput!) {
    __typename
    createDesktop(input: $input) {
      desktop {
        ${DesktopFragmentWithAccessFieldsNoFilter}
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export interface CreateDesktopVariables {
  input: DesktopCreateInputType;
}

export interface CreateDesktopResponse {
  createDesktop: {
    desktop: DesktopApiType;
  };
}

export const EDIT_DESKTOP = gql`
  mutation EditDesktop($input: editDesktopInput!) {
    editDesktop(input: $input) {
      desktop {
        id
        name
        description
      }
    }
  }
`;

export interface EditDesktopVariables {
  input: DesktopEditInputType;
}

export interface EditDesktopResponse {
  editDesktop: {
    desktop: DesktopApiType;
  };
}

export const EDIT_HIDING_CHAT = gql`
  mutation EditHidingChat($input: editHidingChatDesktopInput!) {
    editHidingChatDesktop(input: $input) {
      desktop {
        id
        chatEnabled
        accountsThatChatHidden
      }
    }
  }
`;

export interface EditHidingChatVariables {
  input: EditHidingChatInputType;
}

export interface EditHidingChatResponse {
  editHidingChatDesktop: {
    desktop: DesktopApiType;
  };
}

export const UPDATE_DESKTOP_SORT = gql`
  mutation UpdateDesktopSort($input: updateDesktopSortInput!) {
    updateDesktopSort(input: $input) {
      clientMutationId
    }
  }
`;

export interface UpdateDesktopSortVariables {
  input: {
    desktops: DesktopSortType[];
  };
}

export interface UpdateDesktopSortResponse {
  updateDesktopSort: {
    clientMutationId: string;
  };
}

export const UPDATE_DESKTOP_APP_SORT = gql`
  mutation UpdateDesktopAppSort($input: updateDesktopAppSortInput!) {
    updateDesktopAppSort(input: $input) {
      clientMutationId
      __typename
    }
  }
`;

export interface UpdateDesktopAppSortVariables {
  input: {
    desktopApps: DesktopAppSortType[];
  };
}

export interface UpdateDesktopAppSortResponse {
  updateDesktopAppSort: {
    clientMutationId: string;
  };
}

export const DELETE_DESKTOP = gql`
  mutation DeleteDesktop($input: deleteDesktopInput!) {
    deleteDesktop(input: $input) {
      desktop {
        id
      }
    }
  }
`;

export interface DeleteDesktopVariables {
  input: {
    id: string;
  };
}

export interface DeleteDesktopResponse {
  deleteDesktop: {
    desktop: {
      id: string;
    };
  };
}

export const LEAVE_DESKTOP = gql`
  mutation LeaveDesktop($input: leaveDesktopInput!) {
    leaveDesktop(input: $input) {
      desktop {
        id
      }
    }
  }
`;

export interface LeaveDesktopVariables {
  input: {
    id: string;
  };
}

export interface LeaveDesktopResponse {
  leaveDesktop: {
    desktop: {
      id: string;
    };
  };
}

export const MOVE_APP = gql`
  mutation MoveAppDesktop($input: moveAppDesktopInput!) {
    __typename
    moveAppDesktop(input: $input) {
      desktop {
        apps {
          edges {
            node {
              ${DesktopAppFragmentFields}
            }
          }
        }
      }
    }
  }
`;

export interface MoveAppVariables {
  input: {
    id: string;
    app: string;
    toDesktop: string;
  };
}

export interface MoveAppResponse {
  moveAppDesktop: {
    desktop: DesktopApiType;
  };
}

export const DELETE_SECTION = gql`
  mutation DeleteSection($input: deleteSectionFromDesktopInput!) {
    deleteSectionFromDesktop(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeleteSectionVariables {
  input: {
    id: string;
    sections: DesktopTab[];
  };
}

export interface DeleteSectionResponse {
  clientMutationId: string;
}

export const COPY_APPS_TO_DESKTOP = gql`
  mutation CopyAppsToDesktop($input: copyAppsToDesktopInput!) {
    copyAppsToDesktop(input: $input) {
      clientMutationId
    }
  }
`;

export interface CopyAppsToDesktopVariables {
  input: {
    id: string;
    fromDesktop: string;
  };
}

export interface CopyAppsToDesktopResponse {
  clientMutationId: string;
}

export const COPY_LINKS_TO_DESKTOP = gql`
  mutation CopyLinksToDesktop($input: copyLinksToDesktopInput!) {
    copyLinksToDesktop(input: $input) {
      clientMutationId
    }
  }
`;

export interface CopyLinksToDesktopVariables {
  input: {
    id: string;
    fromDesktop: string;
  };
}

export interface CopyLinksToDesktopResponse {
  clientMutationId: string;
}

export const CREATE_CLIPBOARD = gql`
  mutation CreateClipboard($input: createClipboardInput!) {
    createClipboard(input: $input) {
      clientMutationId
    }
  }
`;

export interface CreateClipboardVariables {
  input: {
    uuid: string;
    data: string;
  };
}

export interface CreateClipboardResponse {
  clientMutationId: string;
}
