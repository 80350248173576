import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const MicrosoftIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M16.3002 0.200195H0.700195V15.8002H16.3002V0.200195Z"
          fill="#F3F3F3"
        />
        <path
          d="M8.19805 0.804199H1.3042V7.69805H8.19805V0.804199Z"
          fill="#F35325"
        />
        <path
          d="M15.6961 0.804199H8.80225V7.69805H15.6961V0.804199Z"
          fill="#81BC06"
        />
        <path
          d="M8.19805 8.30225H1.3042V15.1961H8.19805V8.30225Z"
          fill="#05A6F0"
        />
        <path
          d="M15.6961 8.30225H8.80225V15.1961H15.6961V8.30225Z"
          fill="#FFBA08"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            x="0.700195"
            y="0.200195"
            width="15.6"
            height="15.6"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
});
