import { InboxLocale, InboxTranslation } from './i18n.types';

export const enInboxLocale: InboxLocale = {
  [InboxTranslation.inboxHeader]: 'Inbox',
  [InboxTranslation.mentions]: 'Mentions',
  [InboxTranslation.threads]: 'Threads',
  [InboxTranslation.markAllRead]: 'Mark all as read',
  [InboxTranslation.emptyMentionsMessage]: 'No mentions',
  [InboxTranslation.emptyThreadsMessage]: 'No threads',
  [InboxTranslation.unread]: 'Unread inbox notification',
  [InboxTranslation.fetchMoreNotifications]: 'Load more inbox notifications',
  [InboxTranslation.inboxChatMentioned]:
    'mentioned you in a {chatType} conversation <strong>{chatName}</strong>',
  [InboxTranslation.timeAgo]: '{time} ago',

  [InboxTranslation.tooltipInboxMentionsTab]:
    'Chats/notes where you were mentioned',
  [InboxTranslation.tooltipInboxThreadsTab]: 'Updates to chat threads',
  [InboxTranslation.tooltipInboxOptionsButton]: 'Notification options',
};
