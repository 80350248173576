import type { DesktopAppEdgeApiType } from '../../Desktop/data/Desktop/types/Desktop.types';
import type { DatabaseTablesType } from '../Database.types';

export const DESKTOP_APPS_TABLE_COLUMNS =
  '++id, desktopId, workspaceId, accountFavorite, createdAt, sourceData, appId';

export interface DesktopAppsTableType
  extends Omit<DatabaseTablesType, 'createdAt'> {
  desktopId: string;
  workspaceId: string;
  accountFavorite: boolean;
  createdAt: string;
  sourceData: DesktopAppEdgeApiType;
  appId: string;
}
