import {
  GRAPHQL_TYPENAME_TAG,
  GRAPHQL_TYPENAME_TAG_CONNECTION,
  GRAPHQL_TYPENAME_TAG_EDGE,
} from '../../../../../Link/Link.constants';
import { StreamLinkEvent } from '../../../GeneralMercure.types';
import { LinkApiType } from '../../../../../Link/Link.types';

export const makeLink = (linkFromMercure: StreamLinkEvent): LinkApiType => {
  return {
    id: linkFromMercure.id,
    _id: linkFromMercure._id,
    accountFavorite: linkFromMercure.accountFavorite,
    workspaceId: linkFromMercure.workspaceId,
    createdBy: {
      email: linkFromMercure.createdBy?.email ?? '',
      createdAt: linkFromMercure.createdBy?.createdAt ?? '',
      modifiedAt: linkFromMercure.createdBy?.modifiedAt ?? '',
      firstName: linkFromMercure.createdBy?.firstName ?? '',
      id: linkFromMercure.createdBy?.id ?? '',
      image: linkFromMercure.createdBy?.image ?? null,
      lastName: linkFromMercure.createdBy?.lastName ?? '',
    },
    createdAt: linkFromMercure.createdAt,
    movedAt: linkFromMercure.movedAt,
    data: linkFromMercure.data,
    folder: linkFromMercure.folder,
    tags: {
      edges: [
        ...(linkFromMercure.tags.map(tag => ({
          __typename: GRAPHQL_TYPENAME_TAG_EDGE,
          node: {
            id: tag.id,
            _id: tag._id,
            name: tag.name,
            __typename: GRAPHQL_TYPENAME_TAG,
          },
        })) || { node: [], __typename: GRAPHQL_TYPENAME_TAG }),
      ],
      __typename: GRAPHQL_TYPENAME_TAG_CONNECTION,
    },
    customTitle: linkFromMercure.customTitle,
    customImage: linkFromMercure.customImage,
    desktopId: linkFromMercure.desktopId,
    chatConversationIri: linkFromMercure.chatConversationIri,
    messagesCount: linkFromMercure.messagesCount,
  };
};
