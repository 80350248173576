import Color from 'color';
import { ThemeType } from './Theme.types';

export const SELECTED_THEME = 'selected_theme';

export const Breakpoints = {
  xs: 320,
  sm: 480,
  md: 768,
  lg: 1025,
  xlg: 1200,
  xxl: 1500,
};

export const BasicColors = {
  [ThemeType.light]: {
    OnSurface: Color.rgb(2, 17, 33),
  },
  [ThemeType.dark]: {
    OnSurface: Color.rgb(255, 255, 255),
  },
};

export const OnNavbar = Color.rgb(255, 255, 255);

export const DESKTOP_HEADER_PLUS_FOOTER_HEIGHT = 40;
export const MOBILE_HEADER_PLUS_FOOTER_HEIGHT = 84;
