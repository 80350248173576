import _get from 'lodash/get';
import { generateRandomId } from '../../../shared/utils/id';

type SourceType = 'regular' | 'chat';
type EventType = object;
type ListenerType = {
  id: string;
  filters: Record<string, string>;
  callback: (event: EventType) => void;
};

class GeneralMercureInterceptor {
  regularListeners: ListenerType[];
  chatListeners: ListenerType[];

  constructor() {
    this.regularListeners = [];
    this.chatListeners = [];
  }

  getListeners(source: SourceType) {
    if (source === 'regular') {
      return this.regularListeners;
    }
    return this.chatListeners;
  }

  addListener(source: SourceType, listener: Omit<ListenerType, 'id'>) {
    const id = generateRandomId();
    this.getListeners(source).push({
      id,
      ...listener,
    });
    return id;
  }

  removeListener(id: string) {
    this.regularListeners = this.regularListeners.filter(
      listener => listener.id !== id,
    );
    this.chatListeners = this.chatListeners.filter(
      listener => listener.id !== id,
    );
  }

  onEventReceived(source: SourceType, event: EventType) {
    const listeners = this.getListeners(source);
    listeners.forEach(listener => {
      const filterPaths = Object.keys(listener.filters);
      if (
        !filterPaths.length ||
        filterPaths.every(path => _get(event, path) === listener.filters[path])
      ) {
        listener.callback(event);
      }
    });
  }
}

if (localStorage.getItem('enableMercureInterceptor') === 'yes') {
  window.desktop_MercureInterceptor = new GeneralMercureInterceptor();
}
