import { getLongId } from '../../../../shared/utils/id';
import { AccessApiType } from '../types/Access.types';

export const createAccessMap = (
  accesses: AccessApiType[] = [],
): Record<string, AccessApiType[]> => {
  return accesses.reduce((acc, curr) => {
    const desktopIri = getLongId('desktops', curr.desktopId);
    return {
      ...acc,
      [desktopIri]: [...(acc[desktopIri] || []), curr],
    };
  }, {} as Record<string, AccessApiType[]>);
};

export const createTeamAccessMap = (
  accesses: AccessApiType[] = [],
): Record<string, AccessApiType[]> => {
  return accesses
    .filter(access => !!access?.workspaceTeam?.id)
    .reduce((acc, curr) => {
      const teamIri = getLongId('desktops', curr.workspaceTeam!.id);
      return {
        ...acc,
        [teamIri]: [...(acc[teamIri] || []), curr],
      };
    }, {} as Record<string, AccessApiType[]>);
};

export const createAccountAccessMap = (
  accesses: AccessApiType[] = [],
): Record<string, AccessApiType[]> => {
  return accesses
    .filter(access => !!access?.account?.id)
    .reduce((acc, curr) => {
      const accountIri = getLongId('desktops', curr.account!.id);
      return {
        ...acc,
        [accountIri]: [...(acc[accountIri] || []), curr],
      };
    }, {} as Record<string, AccessApiType[]>);
};
