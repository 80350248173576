import { ApolloClient } from '@apollo/client';
import _get from 'lodash/get';
import _find from 'lodash/find';
import {
  GET_WORKSPACES_BY_IDENTITY,
  GetWorkspacesByIdentityResponse,
  GetWorkspacesByIdentityVariables,
} from '../Workspace.queries';
import { WorkspaceApiType } from '../Workspace.types';
import { AccountApiType } from '../../User/User.types';
import { existsInNestedArray } from '../../../shared/utils/list.utils';

export const addWorkspaceToWorkspacesCache = (
  apolloClient: Pick<ApolloClient<object>, 'readQuery' | 'writeQuery'>,
  workspace: WorkspaceApiType,
  account: AccountApiType,
) => {
  try {
    const workspacesCache = apolloClient.readQuery<
      GetWorkspacesByIdentityResponse,
      GetWorkspacesByIdentityVariables
    >({
      query: GET_WORKSPACES_BY_IDENTITY,
      variables: { id: account.identityId },
    });
    if (!workspacesCache) {
      return;
    }

    const currentAccount = _find(
      _get(workspacesCache, 'accountIdentity.myAccounts.edges'),
      { node: { id: account.id } },
    );
    if (!currentAccount) {
      return;
    }

    const alreadyExists = existsInNestedArray(
      currentAccount,
      'node.workspaces.edges',
      {
        node: { workspace: { id: workspace.id } },
      },
    );
    if (alreadyExists) {
      return;
    }

    apolloClient.writeQuery<
      GetWorkspacesByIdentityResponse,
      GetWorkspacesByIdentityVariables
    >({
      query: GET_WORKSPACES_BY_IDENTITY,
      variables: { id: account.identityId },
      data: {
        ...workspacesCache,
        accountIdentity: {
          ...workspacesCache.accountIdentity,
          myAccounts: {
            ...workspacesCache.accountIdentity.myAccounts,
            edges: workspacesCache.accountIdentity.myAccounts.edges.map(edge =>
              edge.node.id === account.id
                ? ({
                    ...edge,
                    node: {
                      ...edge.node,
                      workspaces: {
                        ...edge.node.workspaces,
                        edges: [
                          {
                            __typename: 'AccountWorkspaceEdge',
                            node: {
                              __typename: 'AccountWorkspace',
                              id: `/account-workspaces/${Math.random()}`,
                              accountWorkspaceId: '',
                              isMfaRequired: false,
                              isMfaValid: true,
                              workspace,
                            },
                          },
                          ...edge.node.workspaces.edges,
                        ],
                      },
                    },
                  } as any)
                : edge,
            ),
          },
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};

export const removeWorkspaceFromWorkspacesCache = (
  apolloClient: Pick<ApolloClient<object>, 'readQuery' | 'writeQuery'>,
  workspaceId: string,
  account: AccountApiType,
) => {
  try {
    const workspacesCache = apolloClient.readQuery<
      GetWorkspacesByIdentityResponse,
      GetWorkspacesByIdentityVariables
    >({
      query: GET_WORKSPACES_BY_IDENTITY,
      variables: { id: account.identityId },
    });
    if (!workspacesCache) {
      return;
    }

    const currentAccount = _find(
      _get(workspacesCache, 'accountIdentity.myAccounts.edges'),
      { node: { id: account.id } },
    );
    if (!currentAccount) {
      return;
    }

    apolloClient.writeQuery<
      GetWorkspacesByIdentityResponse,
      GetWorkspacesByIdentityVariables
    >({
      query: GET_WORKSPACES_BY_IDENTITY,
      variables: { id: account.identityId },
      data: {
        ...workspacesCache,
        accountIdentity: {
          ...workspacesCache.accountIdentity,
          myAccounts: {
            ...workspacesCache.accountIdentity.myAccounts,
            edges: workspacesCache.accountIdentity.myAccounts.edges.map(edge =>
              edge.node.id === account.id
                ? ({
                    ...edge,
                    node: {
                      ...edge.node,
                      workspaces: {
                        ...edge.node.workspaces,
                        edges: [
                          ...edge.node.workspaces.edges.filter(edge => {
                            return edge.node.workspace.id !== workspaceId;
                          }),
                        ],
                      },
                    },
                  } as any)
                : edge,
            ),
          },
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};
