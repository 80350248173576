import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';

export const StyledTooltip = styled(Tooltip)`
  background: ${p => p.theme.colors.Tooltip} !important;
  font-family: ${p => p.theme.fonts.Medium};
  font-weight: normal;
  padding: 0.375rem 0.5rem !important;
  line-height: 1.2;
  font-size: 0.75rem;
  text-align: center !important;
  z-index: ${p => p.theme.zIndexLevels.Tooltip};
  min-width: 8.75rem !important;
  word-break: break-word;

  &.place-top::after {
    border-top-color: ${p => p.theme.colors.Tooltip} !important;
  }

  &.place-bottom::after {
    border-bottom-color: ${p => p.theme.colors.Tooltip} !important;
  }

  &.place-right::after {
    border-right-color: ${p => p.theme.colors.Tooltip} !important;
  }

  &.place-left::after {
    border-left-color: ${p => p.theme.colors.Tooltip} !important;
  }

  .emphasize {
    color: ${p => p.theme.colors.Primary};
  }

  .reaction-tooltip {
    max-width: 11rem;
    text-align: center;

    .reacted-with {
      color: rgba(255, 255, 255, 0.7);
    }

    .reacted-emoji {
      text-align: center;
      font-size: 1.5rem;
      padding: 0.25rem 0;
    }
  }
`;
