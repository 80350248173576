import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const GoogleSheetIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <rect x="3" y="3" width="18" height="18" rx="1.6875" fill="#0F9D58" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4375 5.25H9.1875V9.1875L5.25 9.1875V11.4375H9.1875V19.3125H11.4375V11.4375L18.75 11.4375V9.1875L11.4375 9.1875V5.25Z"
        fill="white"
      />
    </svg>
  );
});
