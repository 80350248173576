import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const PeopleGroupIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.333 10a2.667 2.667 0 1 0 0 5.333 2.667 2.667 0 0 0 0-5.333zm-4.666 2.667a4.667 4.667 0 1 1 7.864 3.398 7.342 7.342 0 0 1 4.045 5.446 1 1 0 1 1-1.976.313 5.335 5.335 0 0 0-10.533 0 1.002 1.002 0 0 1-1.976-.315 7.344 7.344 0 0 1 4.045-5.442 4.654 4.654 0 0 1-1.47-3.4zm12-2a1 1 0 1 0 0 2 2 2 0 0 1 .888 3.792 1 1 0 0 0-.555.896v.469a1 1 0 0 0 .765.973c1.6.386 2.883 1.6 3.363 3.163a1 1 0 1 0 1.912-.587 6.68 6.68 0 0 0-3.413-4.016 4 4 0 0 0-2.96-6.69zM8.005 10.667a1 1 0 1 1 0 2 2 2 0 0 0-.888 3.792 1 1 0 0 1 .555.896v.469a1 1 0 0 1-.765.973 4.676 4.676 0 0 0-3.363 3.163 1 1 0 1 1-1.912-.587 6.68 6.68 0 0 1 3.413-4.016 4 4 0 0 1 2.96-6.69z"
        fill="currentColor"
      />
    </svg>
  );
});
