import React, { ReactNode, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ModalAnimation, ModalThemeOverride } from '.';

import {
  GlobalStyledModalBody,
  StyledModalBackdrop,
  StyledModalContainer,
  StyledModalScroll,
  StyledModalWindow,
} from './Modal.styled';

const ESCAPE_KEY = 'Escape';
const DEFAULT_WIDTH = 480;
const DEFAULT_BORDER_RADIUS = 8;
export const DEFAULT_MODAL_CONTAINER_Z_INDEX = 25;

export interface ModalProps {
  visible: boolean;
  isColoredModal?: boolean;
  width?: number;
  minHeight?: 'auto' | number;
  animation?: ModalAnimation;
  borderRadius?: number;
  containerZIndex?: number;
  fullScreen?: boolean;
  fullHeight?: boolean;
  onRequestClose: () => void;
  renderOverlay?: (props: {
    onClick: () => void;
    'data-testid': string;
  }) => ReactNode;
  className?: string;
  children?: ReactNode;
  topAligned?: boolean;
  themeOverride?: ModalThemeOverride;
  disableOutsideModalClose?: boolean;
  //TODO add confirmation popup when close unsaved modal
  // addOutsideClickModalConfirmation?: boolean;
}

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  // @ts-ignore
  //TODO Remove ts ignore
  (
    {
      visible,
      isColoredModal = false,
      width = DEFAULT_WIDTH,
      minHeight = 'auto',
      animation = ModalAnimation.fadeIn,
      borderRadius = DEFAULT_BORDER_RADIUS,
      containerZIndex = DEFAULT_MODAL_CONTAINER_Z_INDEX,
      fullScreen = false,
      fullHeight = false,
      onRequestClose,
      children,
      renderOverlay,
      className,
      topAligned = false,
      themeOverride = ModalThemeOverride.LIGHT,
      disableOutsideModalClose = false,
      // addOutsideClickModalConfirmation = false,
    },
    ref,
  ) => {
    // const intl = useIntl();
    // const { askConfirmation } = useConfirm();

    const closeOnEscapeKeydown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === ESCAPE_KEY) {
          onRequestClose();
        }
      },
      [onRequestClose],
    );

    // const handleOutsideModalClick = useCallback(() => {
    //   if (addOutsideClickModalConfirmation) {
    //     askConfirmation(
    //       intl.formatMessage({
    //         id: GlobalTranslation.confirmCloseModalOnOutsideClick,
    //       }),
    //     ).then(confirm => {
    //       if (confirm) {
    //         onRequestClose();
    //       }
    //     });
    //   } else {
    //     onRequestClose();
    //   }
    // }, [
    //   askConfirmation,
    //   intl,
    //   onRequestClose,
    //   addOutsideClickModalConfirmation,
    // ]);

    useEffect(() => {
      document.addEventListener('keydown', closeOnEscapeKeydown, false);
      return () =>
        document.removeEventListener('keydown', closeOnEscapeKeydown, false);
    }, [closeOnEscapeKeydown]);

    return ReactDOM.createPortal(
      <StyledModalContainer
        ref={ref}
        className={className}
        visible={visible}
        containerZIndex={containerZIndex}
        role="dialog"
        data-testid="modal">
        <StyledModalScroll fullHeight={fullHeight} topAligned={topAligned}>
          {!disableOutsideModalClose &&
            (renderOverlay ? (
              renderOverlay({
                onClick: onRequestClose,
                'data-testid': 'modal-backdrop',
              })
            ) : (
              <StyledModalBackdrop
                onClick={onRequestClose}
                data-testid="modal-backdrop"
              />
            ))}
          <StyledModalWindow
            width={width}
            minHeight={minHeight}
            isColoredModal={isColoredModal}
            animation={animation}
            borderRadius={borderRadius}
            fullScreen={fullScreen}
            fullHeight={fullHeight}
            topAligned={topAligned}
            data-testid="modal-window"
            themeOverride={themeOverride}>
            {visible && children}
          </StyledModalWindow>

          {visible && <GlobalStyledModalBody />}
        </StyledModalScroll>
      </StyledModalContainer>,
      document.getElementById('modal') as Element,
    );
  },
);
