export enum InboxTranslation {
  inboxHeader = 'inbox_inboxHeader',
  mentions = 'inbox_mentions',
  threads = 'inbox_threads',
  markAllRead = 'inbox_markAllRead',
  emptyMentionsMessage = 'inbox_emptyMentionsMessage',
  emptyThreadsMessage = 'inbox_emptyThreadsMessage',
  unread = 'inbox_unread',
  fetchMoreNotifications = 'inbox_fetchMoreNotifications',
  inboxChatMentioned = 'inbox_inboxChatMentioned',
  timeAgo = 'inbox_timeAgo',

  tooltipInboxMentionsTab = 'inbox_tooltipInboxMentionsTab',
  tooltipInboxThreadsTab = 'inbox_tooltipInboxThreadsTab',
  tooltipInboxOptionsButton = 'inbox_tooltipInboxOptionsButton',
}

export type InboxLocale = {
  [key in InboxTranslation]: string;
};
