import {
  StreamDesktopAccessEvent,
  StreamEventRequest,
} from '../../GeneralMercure.types';
import {
  addAccessToIDDB,
  removeAccessByIdIDDB,
  updateAccessInIDDB,
} from '../../../../Access/data/operations/AccessIDDB.operation';
import { getLongId } from '../../../../../shared/utils/id';
import { makeAccessFromMercureEvent } from '../../../../Access/data/utils/AccessMercure.utils';
import {
  addRelatedTeamsToDesktopsIDDB,
  removeRelatedTeamsFromDesktopsIDDB,
} from '../../../../Desktop/data/TeamDesktop/operations/TeamDesktop.operations';
import {
  addRelatedDesktopsToTeamsIDDB,
  removeRelatedDesktopsFromTeamsIDDB,
} from '../../../../Team/data/DesktopTeam/operations/DesktopTeam.operations';
import {
  addRelatedAccountsToDesktopsIDDB,
  removeRelatedAccountsFromDesktopsIDDB,
} from '../../../../Desktop/data/AccountDesktop/operations/AccountDesktop.operations';
import {
  addRelatedDesktopsToAccountsIDDB,
  removeRelatedDesktopsFromAccountsIDDB,
} from '../../../../Account/data/DesktopAccounts/operations/DesktopAccountIDDB.operations';

export const updateDesktopMembers = (event: StreamDesktopAccessEvent) => {
  const desktopId = event.desktop?.id;
  const workspaceId = event.desktop?.workspace?.id;
  const desktopAccessId = getLongId('desktop-accesses', event.id);

  if (!desktopAccessId || !desktopId || !workspaceId) {
    return;
  }

  if (event['@request'] === StreamEventRequest.NEW) {
    const newDesktopAccess = makeAccessFromMercureEvent(event);
    addAccessToIDDB(newDesktopAccess, workspaceId);

    if (newDesktopAccess?.account?.id) {
      addRelatedAccountsToDesktopsIDDB(
        [desktopId],
        [newDesktopAccess?.account?.id],
      );
      addRelatedDesktopsToAccountsIDDB(
        [newDesktopAccess?.account?.id],
        [desktopId],
      );
    }
    if (newDesktopAccess?.workspaceTeam?.id) {
      addRelatedTeamsToDesktopsIDDB(
        [desktopId],
        [newDesktopAccess?.workspaceTeam?.id],
      );
      addRelatedDesktopsToTeamsIDDB(
        [newDesktopAccess?.workspaceTeam?.id],
        [desktopId],
      );
    }
  }

  if (event['@request'] === StreamEventRequest.UPDATE) {
    updateAccessInIDDB(desktopAccessId, event.accessType);
  }

  if (event['@request'] === StreamEventRequest.REMOVE) {
    removeAccessByIdIDDB(desktopAccessId);

    if (event?.account?.id) {
      removeRelatedAccountsFromDesktopsIDDB([desktopId], [event?.account?.id]);
      removeRelatedDesktopsFromAccountsIDDB([event?.account?.id], [desktopId]);
    }
    if (event?.workspaceTeam?.id) {
      removeRelatedTeamsFromDesktopsIDDB(
        [desktopId],
        [event?.workspaceTeam?.id],
      );
      removeRelatedDesktopsFromTeamsIDDB(
        [event?.workspaceTeam?.id],
        [desktopId],
      );
    }
  }
};
