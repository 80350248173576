import {
  StreamEventRequest,
  StreamFolderEvent,
} from '../../GeneralMercure.types';
import {
  folderRequestNew,
  folderRequestRemove,
  folderRequestUpdate,
} from './Requests';

export const folderRequest = (event: StreamFolderEvent) => {
  switch (event['@request']) {
    case StreamEventRequest.NEW:
      folderRequestNew(event);
      break;
    case StreamEventRequest.UPDATE:
      folderRequestUpdate(event);
      break;
    case StreamEventRequest.REMOVE:
      folderRequestRemove(event);
      break;
    default:
      break;
  }
};
