import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const SearchWithArrow: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.4268 5.42678L17.8232 8.82322C17.9209 8.92085 18.0792 8.92085 18.1768 8.82322L21.5732 5.42678C21.7307 5.26928 21.6192 5 21.3964 5H14.6035C14.3808 5 14.2693 5.26929 14.4268 5.42678Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4999 5.9999C10.4999 6.59084 10.3835 7.17601 10.1573 7.72197C9.93121 8.26794 9.59971 8.76401 9.18191 9.1819C8.76401 9.5997 8.26794 9.9312 7.72197 10.1574C7.17601 10.3835 6.59085 10.4999 5.9999 10.4999C5.40895 10.4999 4.82379 10.3835 4.27782 10.1574C3.73186 9.9312 3.23578 9.5997 2.81792 9.1819C2.40005 8.76401 2.06859 8.26794 1.84244 7.72197C1.61629 7.17601 1.4999 6.59084 1.4999 5.9999C1.4999 4.80642 1.974 3.66183 2.81792 2.81792C3.66183 1.974 4.80642 1.4999 5.9999 1.4999C7.19337 1.4999 8.33796 1.974 9.18191 2.81792C10.0258 3.66183 10.4999 4.80642 10.4999 5.9999ZM9.67991 10.7399C8.47428 11.6759 6.95727 12.1172 5.4377 11.974C3.91813 11.8309 2.51024 11.114 1.50065 9.9693C0.491052 8.82461 -0.0443482 7.33819 0.00343178 5.81265C0.0512218 4.2871 0.678602 2.8371 1.75785 1.75785C2.83711 0.678602 4.2871 0.0512221 5.81265 0.00343212C7.3382 -0.0443509 8.82461 0.491052 9.96931 1.50065C11.114 2.51024 11.8309 3.91813 11.974 5.4377C12.1172 6.95726 11.6759 8.47427 10.7399 9.6799L13.7799 12.7199C13.8536 12.7886 13.9127 12.8714 13.9537 12.9634C13.9947 13.0554 14.0167 13.1547 14.0185 13.2554C14.0203 13.3561 14.0017 13.4561 13.964 13.5495C13.9263 13.6429 13.8701 13.7277 13.7989 13.7989C13.7277 13.8702 13.6429 13.9263 13.5495 13.964C13.4561 14.0017 13.3561 14.0203 13.2554 14.0185C13.1547 14.0167 13.0553 13.9947 12.9634 13.9537C12.8714 13.9127 12.7886 13.8536 12.7199 13.7799L9.67991 10.7399Z"
        fill="currentColor"
      />
    </svg>
  );
});
