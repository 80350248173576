import { ApiConnection, ImageApiType } from '../../shared/api/api.types';
import { WorkspaceEdgeApiType } from '../Workspace/Workspace.types';
import { GeneralSettingsApiType } from '../GeneralSettings/GeneralSettings.types';
import { StreamTopic } from '../Mercure/Mercure.types';
import { PaymentPlan } from '../Billing/Billing.types';
import { KeysetApiType } from '../Encryption/Encryption.types';
import { SignUpStep } from '../Onboarding/Onboarding.types';
import { GRAPHQL_TYPENAME_ACCOUNT } from './User.constants';
import { ResentSearchesType } from '../GlobalSearch/GlobalSearch.types';
import type {
  DesktopAccessType,
  DesktopWithAccessApiType,
} from '../Desktop/data/Desktop/types/Desktop.types';
import { TeamApiType } from '../Team/data/Team/types/Team.types';
import { WorkspaceInfoApiType } from '../WorkspaceInfo/data/WorkspaceInfo/types/WorkspaceInfo.types';

export enum AvailabilityStatusType {
  Online = 'online',
  Away = 'away',
  Busy = 'busy',
  Offline = 'offline',
  Invisible = 'invisible',
}

export interface UnreadChatMessagesType
  extends UnreadNotificationsCountApiType {
  chatConversations?: Record<string, UnreadChatMessagesConversationType>;
}

export interface AccountApiType {
  __typename?: typeof GRAPHQL_TYPENAME_ACCOUNT;
  id: string;
  identityId: string;
  email: string;
  phoneNumber: string;
  pendingEmail?: string;
  firstName: string;
  lastName: string;
  groups?: ApiConnection<AccountGroupEdgeApiType>;
  image: ImageApiType | null;
  verifiedEmail: boolean;
  createdWorkspace: boolean;
  currentFlow?: string;
  currentStep?: SignUpStep;
  mercureInfo?: AccountMercureInfoApiType;
  identity: GeneralSettingsApiType;
  isMfaValid: boolean;
  unreadNotifications: UnreadNotificationsCountApiType[];
  unreadMentions: UnreadNotificationsCountApiType[];
  unreadThreads?: UnreadNotificationsCountApiType[];
  workspaceInfo?: WorkspaceInfoApiType[];
  pendingLinkRequestId?: string;
  isMainAccount?: boolean;
  mfaEnabled?: boolean;
  onlineStatus?: AvailabilityStatusType;
  lastActionAt?: string;
  unreadChatMessages: UnreadChatMessagesType[];
  unreadChatConferences?: UnreadNotificationsCountApiType[];
  keysets?: ApiConnection<KeysetApiType> | KeysetApiType[];
  origin?: AccountOrigin;
  originEmail?: string;
  invitedBy?: Pick<
    AccountApiType,
    'id' | 'firstName' | 'lastName' | 'email'
  > | null;
  timezone?: string;
  companyName?: string;
  companyUrl?: string;
  recentSearches: ResentSearchesType | null;
  onCall: boolean;
  hasSetPassword: boolean;
  filterIsBots: boolean;
  accessType?: DesktopAccessType | null;
  workspaceCache?: { [key: string]: AccountWorkspaceCacheApiType };
}

export type UnreadNotificationsCountApiType = {
  workspace: string;
  count: number;
};

export type UnreadChatMessagesConversationType = {
  hasUnread?: boolean;
  redCount?: number;
};

export type AccountApiTypeWithoutIdentity = Omit<
  AccountApiType,
  'identityId' | 'identity'
>;

export interface AccountWithWorkspacesApiType extends AccountApiType {
  workspaces: ApiConnection<WorkspaceEdgeApiType>;
}

export interface AccountWithCountsApiType extends AccountApiType {
  workspaceCache: { [key: string]: AccountWorkspaceCacheApiType };
}

export interface AccountWithRelatedApiType extends AccountApiType {
  workspaces: ApiConnection<{
    active: boolean;
    groupIdentifier: string;
    groupLabel: string;
    accountWorkspaceTeams: ApiConnection<{ workspaceTeam: TeamApiType }>;
  }>;
  desktops: ApiConnection<{
    id: string;
    desktop: DesktopWithAccessApiType;
    workspaceTeam: TeamApiType;
  }>;
  workspaceCache: { [key: string]: AccountWorkspaceCacheApiType };
}

export interface AccountIdentityApiType {
  id: string;
  _id: string;
  myAccounts: ApiConnection<AccountWithWorkspacesApiType>;
}

/*
 * Invitations
 * */

/*
 * Counts
 * */

export interface AccountTeamsCountApiType {
  active: boolean;
  teams: {
    totalCount: number;
  };
}

export interface AccountsDesktopsCountApiType {
  totalCount: number;
}

export interface AccountWorkspaceCacheApiType {
  group: string;
  groupIdentifier: string;
  teams: number;
  desktops: number;
  active: boolean;
}

/*
 * Members
 * */

export enum MemberTypeEnum {
  Account = 'account',
  GuestAccount = 'account_guest',
  Team = 'team',
  extended_Guest = 'extended_guest',
}

export interface MemberApiType {
  id: string;
  name: string;
  type: MemberTypeEnum;
  image?: ImageApiType;
  email: string;
  origin?: AccountOrigin;
}

/*
 * Groups
 * */

export interface AccountGroupEdgeApiType {
  id: string;
  group: AccountGroupApiType;
  workspace?: {
    id: string;
    type: PaymentPlan;
  };
}

export interface AccountGroupApiType {
  id: string;
  _id: string;
  identifier: string;
  label: string;
}

/*
 * Forms
 * */

export interface EditUserFormValues {
  id: string;
  groupIdentifier: string | null;
}

export interface InviteUsersFormFields {
  email: string;
  groupIdentifier?: string;
  type?: string;
}

/*
 * Mercure
 * */

export interface AccountMercureInfoApiType {
  general: AccountMercureCategoryInfoApiType;
  chat: AccountMercureCategoryInfoApiType;
  ping: AccountMercureCategoryInfoApiType;
}

export interface AccountMercureCategoryInfoApiType {
  authorization: string;
  topics: {
    [key in StreamTopic]: string | string[];
  };
}

export interface EditUserProfileFormValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  image?: string | null;
  phoneNumber?: string | null;
  companyName?: string;
  companyUrl?: string;
  timezone: string;
}

export interface UserProfileInput extends EditUserProfileFormValues {
  id: string;
}

export interface ChangeUserPasswordFormValues {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export interface ChangeUserPasswordInput extends ChangeUserPasswordFormValues {
  id: string;
  pubKey: string;
  privKeyEncrypted: string;
}

export enum AccountOrigin {
  webtopcom = 'webtopcom',
  slack = 'slack',
  microsoft = 'microsoft',
  bot = 'bot',
}

export interface GoogleContactsApiType {
  id: string;
  email: string;
  displayName: string;
  imageUrl: string;
}

export interface MicrosoftContactsApiType {
  id: string;
  email: string;
  displayName: string;
  image: string;
}
