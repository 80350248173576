import { gql } from '@apollo/client';
import { LinkFragmentFields } from '../../../Link.fragments';
import { LinkApiType } from '../../../Link.types';

export const CREATE_LINK = gql`
  mutation CreateLink($input: createLinkInput!) {
    __typename
    createLink(input: $input) {
      link {
        ${LinkFragmentFields}
      }
    }
  }
`;

export interface CreateLinkInputVariables {
  url: string;
  desktop: string;
  folder?: string;
  customImage?: string;
}

export interface CreateLinkVariables {
  input: CreateLinkInputVariables;
}

export interface CreateLinkResponse {
  createLink: {
    link: LinkApiType;
  };
}
