import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DesktopsIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 7V5.5h11v7H19V8a1 1 0 0 0-1-1H9.5zM8 7V5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2v2a1 1 0 0 1-1 1h-2v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h2V8a1 1 0 0 1 1-1h2zm8 8.5h1.5v-7h-11V10H15a1 1 0 0 1 1 1v4.5z"
        fill="currentColor"
      />
    </svg>
  );
});
