export const MERCURE_UNAUTHORIZED_ERROR = 'MERCURE_UNAUTHORIZED_ERROR';
export const MERCURE_RETRY_INTERVAL = 5000; // 5s
export const LAST_EVENT_RECEIVED_AT = 'lastEventReceivedAt';
export const LAST_EVENT_ID = 'lastEventId';
export const LAST_CHAT_EVENT_ID = 'lastChatEventId';
export const LAST_PING_EVENT_ID = 'lastPingEventId';

export enum MercureCategory {
  GENERAL = 'general',
  CHAT = 'chat',
  PING = 'ping',
}
