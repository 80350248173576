import { getLongId } from '../../../shared/utils/id';
import { FolderApiType } from '../../Folder/Folder.types';
import { DesktopFoldersTableType } from './DesktopFoldersTable';

/**
 * Converts a FolderApiType to DesktopFolderTableRow
 * @param folder
 * @returns DesktopFoldersTableType
 */
export const FolderApiTypeToDesktopFolderTableRow = (
  folder: FolderApiType,
  workspaceId: string,
) => {
  const row: DesktopFoldersTableType = {
    id: getLongId('folders', folder.id),
    desktopId: getLongId('desktops', folder.desktop.id),
    workspaceId: getLongId('workspaces', workspaceId),
    accountFavorite: folder.accountFavorite ?? false,
    parentId: folder.parentId
      ? getLongId('folders', folder.parentId)
      : folder.parent?.id
      ? getLongId('folders', folder.parent.id)
      : null,
    createdAt: folder.createdAt,
    sourceData: folder,
  };
  return row;
};
