import {
  GeneralSettingsLocale,
  GeneralSettingsTranslation,
} from './i18n.types';

export const enGeneralSettingsLocale: GeneralSettingsLocale = {
  [GeneralSettingsTranslation.iconLabel]: 'Settings',
  [GeneralSettingsTranslation.modalTitle]: 'Preferences',

  [GeneralSettingsTranslation.behaviorSectionHeader]: 'Behavior',
  [GeneralSettingsTranslation.themeSectionHeader]: 'Theme',

  [GeneralSettingsTranslation.mfaSectionHeader]: 'Multi-Factor Authentication',
  [GeneralSettingsTranslation.mfaInputTitle]:
    'Activate Multi-Factor Authentication for all your workspaces – <a href="{learnMoreUrl}" rel="noopener noreferrer" target="_blank">Learn more</a>',
  [GeneralSettingsTranslation.mfaActivateConfirmationTitle]:
    'Activate Multi-Factor Authentication',
  [GeneralSettingsTranslation.mfaActivateConfirmationMessage]:
    'You will be required to complete the MFA setup. This will secure access to all your workspaces. Proceed?',

  [GeneralSettingsTranslation.selectAppLabel]: 'How should your apps open?',
  [GeneralSettingsTranslation.selectLinkLabel]: 'How should your links open?',

  [GeneralSettingsTranslation.optionNewTab]: 'Open in a new tab',
  [GeneralSettingsTranslation.optionCurrentTab]: 'Open in the current tab',
  [GeneralSettingsTranslation.optionSeparatedWindow]: 'Open in App-like window',

  [GeneralSettingsTranslation.optionThemeSystemPreference]: 'System preference',
  [GeneralSettingsTranslation.optionThemeTitle]: 'Interface theme',
  [GeneralSettingsTranslation.optionThemeLight]: 'Light',
  [GeneralSettingsTranslation.optionThemeDark]: 'Dark',

  [GeneralSettingsTranslation.startupLabel]: 'Auto startup',
  [GeneralSettingsTranslation.checkboxLabel]: 'Launch Webtop.com on startup',

  [GeneralSettingsTranslation.startPageLabel]: 'Startpage',
  [GeneralSettingsTranslation.browserExtensionLabel]: 'Browser extension',
  [GeneralSettingsTranslation.startPageButton]:
    'Make Webtop.com your startpage',
  [GeneralSettingsTranslation.browserExtensionButton]: 'Get browser extension',

  [GeneralSettingsTranslation.settingsMenuAccount]: 'Account',
  [GeneralSettingsTranslation.settingsMenuAppStore]: 'AppStore',
  [GeneralSettingsTranslation.settingsMenuCompanyProfile]: 'Account profile',
  [GeneralSettingsTranslation.settingsMenuCompanyUsers]: 'Users',
  [GeneralSettingsTranslation.settingsMenuCompanyTeams]: 'Groups',
  [GeneralSettingsTranslation.settingsMenuCompanyDesktops]: 'Desktops',
  [GeneralSettingsTranslation.settingsMenuCompanyBilling]: 'Billing',
  [GeneralSettingsTranslation.settingsMenuCompanyLogOut]: 'Log out',

  [GeneralSettingsTranslation.settingsDrawerMenuHeader]: 'Settings',
  [GeneralSettingsTranslation.settingsDrawerAccountHeaders]: 'Account',
  [GeneralSettingsTranslation.settingsDrawerCloseButton]: 'Close',
  [GeneralSettingsTranslation.settingsDrawerBackButton]: 'Back',

  [GeneralSettingsTranslation.tooltipGeneralSettingsButton]:
    'Settings and preferences',

  [GeneralSettingsTranslation.notificationsSectionHeader]: 'Email preferences',
  [GeneralSettingsTranslation.serviceUpdatesInputTitle]: 'Newsletter',
  [GeneralSettingsTranslation.serviceUpdatesInputDescription]:
    'Promo codes, review emails, new features, special offers and other service updates',
  [GeneralSettingsTranslation.productInformationInputTitle]:
    'Product information',
  [GeneralSettingsTranslation.productInformationInputDescription]:
    'Tips & Guides, how-to-guides, hacks and tips to get the most out of your Webtop.com account',
  [GeneralSettingsTranslation.transactionalEmailsInputTitle]:
    'Transactional emails',
  [GeneralSettingsTranslation.transactionalEmailsInputDescription]:
    'Emails with important information related directly to your account, security and privacy updates',
  [GeneralSettingsTranslation.transactionalEmailsInputTooltip]:
    'You cannot unsubscribe to the transactional emails',

  ///Global notifications
  [GeneralSettingsTranslation.notificationsModalTitle]: 'Global Notifications',
  [GeneralSettingsTranslation.notificationsNotActiveOnComputer]:
    "When I'm not active on a computer...",
  [GeneralSettingsTranslation.notificationsToMyMobileDevices]:
    'Send notifications to my mobile devices: ',
  [GeneralSettingsTranslation.notificationsMessages]: 'Messages: ',
  [GeneralSettingsTranslation.notificationsAboutAllMessages]: 'All messages',
  [GeneralSettingsTranslation.notificationsAboutDirectMentionsReply]:
    'Direct Messages, @mentions, reply in threads your part of',
  [GeneralSettingsTranslation.notificationsDisableMessagesNotifications]:
    'Don`t notify me about messages',
  [GeneralSettingsTranslation.notificationsGeneralBell]:
    '<b>General bell</b> - apps, links, users',
  [GeneralSettingsTranslation.notificationsBilling]:
    '<b>Billing</b> - invoices',
  [GeneralSettingsTranslation.notificationsUpcomingMeetings]:
    '<b>Upcoming meetings</b>',
  [GeneralSettingsTranslation.notificationsNotifyMeAbout]: 'Notify me about...',
  [GeneralSettingsTranslation.notificationsOptionInactive]:
    'as soon as I’m inactive',
  [GeneralSettingsTranslation.notificationsOptionAlways]: 'always',
  [GeneralSettingsTranslation.notificationsOptionNever]: 'never',
  [GeneralSettingsTranslation.notificationsSounds]: 'Sounds',
  [GeneralSettingsTranslation.notificationsMuteAllSounds]:
    'Mute all sounds from Webtop.com',
};
