import { gql } from '@apollo/client';
import { LinkFragment } from '../../../Link.fragments';
import { LinkApiType } from '../../../Link.types';

export const GET_LINK = gql`
  query GetLink($id: ID!) {
    link(id: $id) {
      ...LinkFragment
    }
  }
  ${LinkFragment}
`;

export interface GetLinkVariables {
  id: string;
}

export interface GetLinkResponse {
  link: LinkApiType | null;
}
