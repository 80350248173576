import { AvatarForm } from './Avatar.types';
import styled, { css } from 'styled-components';

interface StyledAvatarProps {
  size: number;
  mode?: AvatarMode;
  variant?: AvatarForm;
}

interface StyledImgProps {
  size: number;
  initials: string;
}

export enum AvatarMode {
  circle = 'circle',
}

export const StyledAvatar = styled.div<StyledAvatarProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  flex: 0 0 ${({ size }) => size}px;

  border-radius: ${({ variant }) =>
    variant === AvatarForm.ROUNDED ? '50%' : '4px'};
  background: ${({ theme }) => theme.colors.AvatarBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${({ variant }) =>
    variant === AvatarForm.REGULAR &&
    css`
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.BoxShadowDefault};
    `}

  ${({ mode }) =>
    mode === AvatarMode.circle &&
    css`
      border-radius: 50%;
    `};
`;

export const StyledInitials = styled.span<StyledAvatarProps>`
  /* At size == 40, font-size should equal 16px */
  font-size: ${({ size }) => size * 0.4}px;
  color: ${({ theme }) => theme.colors.OnNavbar};
  text-transform: uppercase;
  pointer-events: none;
`;

export const StyledImg = styled.img<StyledImgProps>`
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: ${({ theme }) => theme.colors.OnNavbar};
  text-transform: uppercase;
  object-fit: cover;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '${({ initials }) => initials}';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.AvatarBackground};
    font-size: 9px;
    line-height: ${({ size }) => size}px;
    text-align: center;
  }
`;
