import { gql } from '@apollo/client';

export const GET_DESKTOP_REST = gql`
  query GetDesktopRest($desktopId: ID!) {
    desktop(desktopId: $desktopId)
      @rest(path: "/desktop/{args.desktopId}")
      @connection(key: "desktop_rest", filter: ["args.desktopId"]) {
      accountsThatChatHidden
      createdAt
      createdBy
      id
      modifiedAt
    }
  }
`;

export interface GetDesktopRestVariables {
  desktopId: string;
}

export interface DesktopRestApiType {
  accountsThatChatHidden: string[];
  createdAt: string;
  createdBy: null | {};
  id: string;
  modifiedAt: string;
}
export interface GetDesktopRestResponse {
  desktop: DesktopRestApiType;
}
