import { ThemeType } from '../../../../Theme/Theme.types';

export const FORM_FIELD_APPS_OPEN_TYPE = 'openApp';
export const FORM_FIELD_LINKS_OPEN_TYPE = 'openLink';
export const FORM_FIELD_MFA_TYPE = 'mfaRequired';
export const FORM_FIELD_THEME_TYPE = 'theme';

export enum OpenType {
  currentTab = 'current_tab',
  newTab = 'new_tab',
  separateWindow = 'separate_window',
}

export type openTypeOptions = {
  [key in OpenType]: string;
};

export type themeTypeOptions = {
  [key in ThemeType]: string;
};
