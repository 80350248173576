import { gql } from '@apollo/client';
import { AccountIdentityApiType } from '../User/User.types';
import { WorkspacePermissionsApiType } from './Workspace.types';
import { KeysetFragmentFields } from '../Encryption/Encryption.fragments';
import { ApiConnection } from '../../shared/api/api.types';
import { KeysetApiType } from '../Encryption/Encryption.types';

export const GET_WORKSPACES_BY_IDENTITY = gql`
  query GetWorkspacesByIdentity($id: ID!) {
    accountIdentity(id: $id) {
      id
      mfaRequired
      myAccounts {
        edges {
          node {
            keysets {
              edges {
                node {
                  id
                  pubKey
                  privKeyEncrypted
                  createdAt
                }
              }
            }
            id
            _id
            email
            phoneNumber
            pendingEmail
            firstName
            lastName
            isMainAccount
            mfaEnabled
            timezone
            companyName
            companyUrl
            image {
              id
              contentUrl
            }
            onlineStatus
            lastActionAt
            workspaces(active: true) {
              edges {
                node {
                  id
                  workspace {
                    id
                    _id
                    name
                    accountSortPosition
                    description
                    contactEmail
                    homepageUrl
                    freeTrialDaysLeft
                    billingRequired
                    billingOk
                    type
                    image {
                      id
                      contentUrl
                    }
                    mfaRequired
                    canceledAt
                    membersCount
                    nextRenewAt
                    identifier
                    isUpgrading
                    isDowngrading
                    uri
                    accountIrisAfterDowngrade
                    gracePeriodFinishedAt
                    organizeAndSearch
                    communicate
                  }
                  isMfaRequired
                  isMfaValid
                  webPushEnabled
                  webPushEndpoints
                  accountWorkspaceSetting {
                    id
                    messagesNotifications
                    generalBellNotificationsEnable
                    billingNotificationsEnable
                    upcomingMeetingNotificationsEnable
                    soundsEnable
                    useGlobalSettings
                    integrationsNotificationsSettings
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface GetWorkspacesByIdentityVariables {
  id: string;
}

export interface GetWorkspacesByIdentityResponse {
  accountIdentity: AccountIdentityApiType;
}

export interface SearchWorkspaceMembersVariables {
  workspace: string;
  text: string;
}

export const WORKSPACE_PERMISSIONS = gql`
  query GetAccountPermissionsForWorkspace($workspace: ID!) {
    workspacePermission(workspace: $workspace) {
      desktops
      canAccessAdminPanel
      canCreateDesktop
      canCreateCompanyDesktop
      canEditWorkspace
      canResetMfaRequest
      canAddCustomApp
      canRemoveCustomApp
      canMenuCustomAppsPage
      canViewCustomAppsPage
      canCreateScheduleCalls
      canMenuBillingPage
      canViewBillingPage
      canEditBillingPage
      canUpgrade
      canDowngrade
      canEnableMfa
      canMenuSsoPage
      canViewSsoPage
      canEditSsoPage
      canChangeIdentifier
      canTogglePersona
      canMenuGroupsPage
      canViewGroupsPage
      canEditGroupsPage
      canMenuDesktopsPage
      canViewDesktopsPage
      canEditDesktopsPage
      canViewProfilePage
      canInviteUsers
      canMenuUsersPage
      canViewUsersPage
      canEditUsersPage
      canChangeWorkspaceName
      canChangeWorkspaceAdminEmail
      canViewFullWorkspaceInfo
      canMenuDomainPage
      canViewDomainPage
      canEditDomainPage
      canViewWorkspaceUserDesktops
      canUnlockCredentials
      canSeeMyPasswords
      canCreateGroupChat
      canCreateGroupCall
      canUpdateGroupChat
      canExplicitlyShareVault
      canViewWorkspaceAppIntegrations
      canViewPersonalAppIntegrations
      canEditWorkspaceFeatures
      canMenuAppIntegrationsPage
      canViewGlobalSearchIntegrations
      canViewGlobalSearchApps
      canViewGlobalSearchLinks
      canViewGlobalSearchChats
      canViewGlobalSearchMeetings
      canMenuDesktopsSidebarItem
      canMenuChatsSidebarItem
      canMenuMeetingsSidebarItem
      canAccessDesktopPage
      canAccessChatPage
      canAccessMeetingPage
      canViewInboxNotifications
      canRedirectToDesktopByDefault
      canViewDesktopChatSection
      canCallAllDesktopMembers
      canCallWorkspaceMembers
      canMessageWorkspaceMembers
      canSeeInstallPluginToast
    }
  }
`;

export interface WorkspacePermissionsVariables {
  workspace: string;
}

export interface WorkspacePermissionsResponse {
  workspacePermission: WorkspacePermissionsApiType | null;
}

export const WORKSPACE_OWNER_ACCOUNT = gql`
  query GetWorkspaceOwnerAccount($workspace: ID!) {
    getWorkspaceOwnerAccount(workspace: $workspace) {
      account {
        id
        keysets {
          edges {
            node {
              ${KeysetFragmentFields}
            }
          }
        }
      }
    }
  }
`;

export interface GetWorkspaceOwnerAccountVariables {
  workspace: string;
}

export interface GetWorkspaceOwnerAccountResponse {
  getWorkspaceOwnerAccount: {
    account: { id: string; keysets: ApiConnection<KeysetApiType> };
  };
}
