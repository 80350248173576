import { database } from '../../../../Database';
import { DesktopsTableType } from '../../../../Database/DesktopTable/DesktopsTable';

export const getWorkspaceDesktopsFromIDDB = (
  workspaceId: string,
  shareable?: boolean,
  accessible?: boolean,
) => {
  return database.desktops
    .where({
      workspaceId,
      ...(shareable !== undefined ? { shareable: shareable.toString() } : {}),
      ...(accessible !== undefined
        ? { accessible: accessible.toString() }
        : {}),
    })
    .toArray();
};

export const getDesktopByIdFromIDDB = async (
  id: string,
): Promise<DesktopsTableType | undefined> => {
  try {
    return database.desktops.get(id);
  } catch (error) {
    console.error(error);
  }
};
