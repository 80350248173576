import React, { FC, PropsWithChildren } from 'react';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { useMobile } from '../../../shared/hooks';
import { BurgerMenuIcon } from '../../../shared/icons';
import { useMobileNavigationSidebar } from '../../Workspace/Workspace.hooks';
import {
  StyledSegmentPageTitleBar,
  TitleBarContent,
  TitleBarMobilemenu,
} from './SegmentPageTitleBar.styled';

interface SegmentPageTitleBarProps {
  hideMenuButton?: boolean;
}

export const SegmentPageTitleBar: FC<
  PropsWithChildren<SegmentPageTitleBarProps>
> = ({ children, hideMenuButton = false }) => {
  const isMobile = useMobile();
  const { mobileNavigationSidebarToggle } = useMobileNavigationSidebar();

  return (
    <StyledSegmentPageTitleBar
      data-testid="segment-page-titlebar"
      isMobile={isMobile}
      hideBorder={hideMenuButton}>
      {isMobile && !hideMenuButton && (
        <TitleBarMobilemenu>
          <ButtonWithIcon
            data-testid="titlebar-mobilemenu"
            className="burgerButton"
            icon={BurgerMenuIcon}
            onClick={mobileNavigationSidebarToggle}
          />
        </TitleBarMobilemenu>
      )}
      <TitleBarContent data-testid="titlebar-content">
        {children}
      </TitleBarContent>
    </StyledSegmentPageTitleBar>
  );
};
