import { useCallback, useMemo } from 'react';
import { useCookie } from 'react-use';
import Cookies from 'js-cookie';

export const COOKIE_BOOL_TRUE = 'true';

const COOKIE_DOMAIN = (() => {
  const host = window.location.host;
  const hostParts = host.split('.');
  return hostParts.length > 1 ? `.${hostParts.slice(1).join('.')}` : undefined;
})();

const COOKIE_EXPIRES_IN = 365; // days

export const useCookieStorage = (
  key: string,
  defaultValue: string | null = null,
  options: Cookies.CookieAttributes = {},
): [string | null, (newValue: string) => void, () => void] => {
  const [value, cookieSetter, cookieCleaner] = useCookie(key);

  const setter = useCallback(
    (newValue: string) => {
      cookieSetter(newValue, {
        domain: COOKIE_DOMAIN,
        expires: COOKIE_EXPIRES_IN,
        ...options,
      });
    },
    [cookieSetter, options],
  );

  return [value ?? defaultValue, setter, cookieCleaner];
};

type StorableObject = { [key: string]: string };

export const useObjectCookieStorage = <T = StorableObject>(
  key: string,
): [T | null, (newValue: T) => void, () => void] => {
  const [cookieValue, cookieSetter, cookieCleaner] = useCookieStorage(key);

  const value = useMemo(() => {
    let result = null;
    if (cookieValue) {
      try {
        result = JSON.parse(cookieValue) as T;
      } catch (e) {}
    }
    return result;
  }, [cookieValue]);

  const setter = useCallback(
    (newValue: T) => {
      cookieSetter(JSON.stringify(newValue));
    },
    [cookieSetter],
  );

  return [value, setter, cookieCleaner];
};

export const setCookie = (name: string, value: string) => {
  return Cookies.set(name, value, {
    domain: COOKIE_DOMAIN,
    expires: COOKIE_EXPIRES_IN,
  });
};

export const getCookie = (name: string) => {
  return Cookies.get(name);
};

export const removeCookie = (name: string) => {
  return Cookies.remove(name, { domain: COOKIE_DOMAIN });
};
