export enum LinkTranslation {
  formNameLabel = 'link_formNameLabel',
  formNamePlaceholder = 'link_formNamePlaceholder',
  formNameRequired = 'link_formNameRequired',
  formUrlLabel = 'link_formUrlLabel',
  formUrlPlaceholder = 'link_formUrlPlaceholder',
  formUrlRequired = 'link_formUrlRequired',
  formUrlInvalid = 'link_formUrlInvalid',
  formSubmitButton = 'link_formSubmitButton',
  formCancelButton = 'link_formCancelButton',

  tagFormNameLabel = 'link_tagFormNameLabel',
  tagFormNamePlaceholder = 'link_tagFormNamePlaceholder',
  tagFormEmptyStateError = 'link_tagFormEmptyStateError',
  tagFormsCreateButton = 'link_tagFormsCreateButton',
  tagFormsSaveButton = 'link_tagFormsSaveButton',
  tagFormCancelButton = 'link_tagFormCancelButton',
  tagFormCreateTagOption = 'link_tagFormCreateTagOption',

  createLinkModalHeader = 'link_createLinkModalHeader',
  editLinkModalHeader = 'link_editLinkModalHeader',

  createTagModalHeader = 'link_createTagModalHeader',
  manageTagModalHeader = 'link_manageTagModalHeader',

  linksViewFoldersLink = 'folder_linksViewFoldersLink',
  linksViewMoveTo = 'folder_linksViewMoveTo',
  linksViewEditLink = 'link_linksViewEditLink',
  linksViewTagsLink = 'link_linksViewTagsLink',
  linksViewCopyLink = 'link_linksViewCopyLink',
  linksViewDeleteLink = 'link_linksViewDeleteLink',
  linksViewDeleteLinkConfirmation = 'link_linksViewDeleteLinkConfirmation',
  linksViewDeleteMultipleLinksConfirmation = 'link_linksViewDeleteMultipleLinksConfirmation',
  linksViewDeleteChatLinkConfirmation = 'link_linksViewDeleteChatLinkConfirmation',
  linksViewLinkWarningLabel = 'link_linksViewLinkWarningLabel',
  linksViewLinkPotentialDanger = 'link_linksViewLinkPotentialDanger',
  linksViewRequiresDirectAccess = 'link_linksViewRequiresDirectAccess',

  emptyHeader = 'link_emptyHeader',
  emptyMessage = 'link_emptyMessage',
  emptyAddLinksButton = 'link_emptyAddLinksButton',

  emptyFavoritesHeader = 'link_emptyFavoritesHeader',
  emptyFavoritesMessage = 'link_emptyFavoritesMessage',

  importYourApps = 'link_importYourApps',
  addNewLinks = 'link_addNewLinks',
  importFromBrowserButton = 'link_importFromBrowserButton',

  uncheckedLinkModalHeader = 'link_uncheckedLinkModalHeader',
  uncheckedLinkDescription = 'link_uncheckedLinkDescription',
  uncheckedLinkInformationLink = 'link_uncheckedLinkInformationLink',
  uncheckedLinkOpenConfirm = 'link_uncheckedLinkOpenConfirm',
  uncheckedLinkOpenCancel = 'link_uncheckedLinkOpenCancel',

  untrustedLinkModalHeader = 'link_untrustedLinkModalHeader',
  untrustedLinkDescription = 'link_untrustedLinkDescription',
  untrustedLinkReason = 'link_untrustedLinkReason',
  untrustedLinkInformationLink = 'link_untrustedLinkInformationLink',
  untrustedLinkOpenConfirm = 'link_untrustedLinkOpenConfirm',
  untrustedLinkOpenCancel = 'link_untrustedLinkOpenCancel',

  searchLinksResultNoFound = 'link_searchLinksResultNoFound',

  emptyAppsStateHelpText = 'link_emptyAppsStateHelpText',
  emptyAppsStateOnboardingTour = 'link_emptyAppsStateOnboardingTour',
  emptyAppsStateFaqLink = 'link_emptyAppsStateFaqLink',

  tooltipThreeDotsMore = 'link_tooltipThreeDotsMore',
  tooltipLinkDetails = 'link_tooltipLinkDetails',
  tooltipLinkCommentsCounter = 'link_tooltipLinkCommentsCounter',
  tooltipCreatedByName = 'link_tooltipCreatedByName',
  tooltipCreatedByYou = 'link_tooltipCreatedByYou',
  tooltipEditLinkDetails = 'link_tooltipEditLinkDetails',
  tooltipEditLinkTags = 'link_tooltipEditLinkTags',
  tooltipAddLinkTags = 'link_tooltipAddLinkTags',
  tooltipHideLinkDetails = 'link_tooltipHideLinkDetails',
  tooltipHideLinkComments = 'link_tooltipHideLinkComments',

  linkCopiedMessage = 'link_linkCopiedMessage',
  linksCopiedMessage = 'link_linksCopiedMessage',

  tagsSelectNoOptionsMessage = 'link_tagsSelectNoOptionsMessage',

  unsupportedDroppedUrlFile = 'link_unsupportedDroppedUrlFile',

  linkAlreadyExistInDesktop = 'link_linkAlreadyExistInDesktop',
  linkAlreadyExistInFolder = 'link_linkAlreadyExistInFolder',
  linkLimitFolderReached = 'link_linkLimitFolderReached',

  linkDetailsModalHeader = 'link_linkDetailsModalHeader',
  linkDetailsLoadingErrorMessage = 'link_linkDetailsLoadingErrorMessage',

  linkMenuItemLinkDetails = 'link_linkMenuItemLinkDetails',
  linkMenuItemLinkComments = 'link_linkMenuItemLinkComments',
  linkMenuItemLinkComment = 'link_linkMenuItemLinkComment',
  linkMenuItemLinkPreview = 'link_linkMenuItemLinkPreview',
  linkMenuItemLinkSelect = 'link_linkMenuItemLinkSelect',
  linkMenuItemLinkSelectAll = 'link_linkMenuItemLinkSelectAll',
  linkMenuItemLinkUnselect = 'link_linkMenuItemLinkUnselect',
  linkMenuItemLinkUnselectAll = 'link_linkMenuItemLinkUnselectAll',

  linkMenuItemSignInWithGoogle = 'link_linkMenuItemSignInWithGoogle',

  linkDetailsCommentsHeading = 'link_linkDetailsCommentsHeading',

  linkDetailsInformationHeading = 'link_linkDetailsInformationHeading',
  linkDetailsInformationPreview = 'link_linkDetailsInformationPreview',
  linkDetailsInformationLocation = 'link_linkDetailsInformationLocation',
  linkDetailsInformationLocationOpenDesktop = 'link_linkDetailsInformationLocationOpenDesktop',
  linkDetailsInformationLocationDesktopLabel = 'link_linkDetailsInformationLocationDesktopLabel',
  linkDetailsInformationTags = 'link_linkDetailsInformationTags',
  linkDetailsInformationTagsEmpty = 'link_linkDetailsInformationTagsEmpty',
  linkDetailsInformationTimeline = 'link_linkDetailsInformationTimeline',
  linkDetailsInformationEditAction = 'link_linkDetailsInformationEditAction',
  linkDetailsInformationAddAction = 'link_linkDetailsInformationAddAction',

  linkDetailsTitleButtonTooltipOpenLink = 'link_linkDetailsTitleButtonTooltipOpenLink',
  linkDetailsTitleButtonTooltipCopyLink = 'link_linkDetailsTitleButtonTooltipCopyLink',

  linkHoverLinkDetails = 'link_linkHoverLinkDetails',
  linkHoverOpenLink = 'link_linkHoverOpenLink',

  linkDuplicateTagMessage = 'link_linkDuplicateTagMessage',
}

export type LinkLocale = {
  [key in LinkTranslation]: string;
};
