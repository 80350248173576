export const KeysetFragmentFields = `
    id
    privKeyEncrypted
    pubKey
    createdAt
`;

export const VaultAccessFragmentFields = `
    id
    vaultKeyEncrypted
    keyset {
      id
      _id
      __typename
    }
    isImplicitlyShared
    shared
    parent {
      id
      vaultKeyEncrypted
      keyset {
        id
        _id
        __typename
      }
    }
    createdAt
`;

export const AppVaultItemDesktopConfigFragmentFields = `
    id
    desktop {
      id
    }
`;

export const AppVaultItemConfigFragmentFields = `
  id
  app {
    id
    name
    fullName
    custom
    logo {
      id
      contentUrl
    }
    groupApp {
      id
    }
    hidden
    uri
    openUri
    loginUri
    loginUriPattern
  }
  appVaultItemConfigDesktops {
    edges {
      node {
        ${AppVaultItemDesktopConfigFragmentFields}
      }
    }
  }
`;

export const VaultItemFragmentFields = `
    id
    login
    password
    url
    image {
      id
      _id
      contentUrl
      __typename
    }
    type
    appVaultItemConfigs {
      edges {
        node {
          ${AppVaultItemConfigFragmentFields}
        }
      }
    }
`;

export const VaultFragmentFields = `
    id
    vaultAccesses {
      edges {
        node {
          ${VaultAccessFragmentFields}
        }
      }
    }
    vaultItems {
      edges {
        node {
          ${VaultItemFragmentFields}
        }
      }
    }
    createdAt
    modifiedAt
`;

export const PasswordRecoveryRequestFragment = `
  id
  requestedBy {
    id
    email
    firstName
    lastName
    keysets {
      edges {
        node {
          id
          pubKey
          createdAt
        }
      }
    }
  }
  status
  __typename
`;
