import { MessageToNativeWrapper, NativePlatform } from './NativeWrapper.types';
import { appEnv } from '../../appEnv';
import { NATIVE_PLATFORM_STORAGE_KEY } from './NativeWrapper.constants';
import { captureException } from '../ErrorInterceptor';

/**
 * Sends message to either iOS or Android depending on which one is available.
 * Returns true if message was sent successfully, and false otherwise.
 */
export const sendMessageToNativeWrapper = (
  data: MessageToNativeWrapper,
): boolean => {
  if (window.DESKTOPCOM_LOG_NATIVE_WRAPPER) {
    console.log('[NativeWrapper] Message TO native wrapper:', data);
  }
  try {
    if (
      typeof window.webkit?.messageHandlers?.jsMessageHandler?.postMessage ===
      'function'
    ) {
      window.webkit.messageHandlers.jsMessageHandler.postMessage(
        JSON.stringify(data),
      );
      return true;
    }

    if (typeof window.JSBridge?.sendMessageToNative === 'function') {
      window.JSBridge.sendMessageToNative(JSON.stringify(data));
      return true;
    }

    return false;
  } catch (e) {
    captureException(e as Error, 'Failed to send message to Native Wrapper');
    console.error(e);
    return false;
  }
};

export const isIOSNativeWrapperAvailable = () => {
  return (
    typeof window.webkit?.messageHandlers?.jsMessageHandler?.postMessage ===
    'function'
  );
};

export const isAndroidNativeWrapperAvailable = () => {
  return typeof window.JSBridge?.sendMessageToNative === 'function';
};

export const isNativeWrapperAvailable = () => {
  return isIOSNativeWrapperAvailable() || isAndroidNativeWrapperAvailable();
};

export const parseNativePlatform = (platform?: unknown) => {
  if (platform === NativePlatform.iOS) {
    return NativePlatform.iOS;
  }
  if (platform === NativePlatform.Android) {
    return NativePlatform.Android;
  }
  return undefined;
};

export const getNativeWrapperProtocol = (platform: NativePlatform | null) => {
  switch (platform) {
    case NativePlatform.iOS:
      return appEnv.NATIVE_WRAPPER_PROTOCOL_IOS;
    case NativePlatform.Android:
      return appEnv.NATIVE_WRAPPER_PROTOCOL_ANDROID;
    default:
      return null;
  }
};

export const openDeepLinkInNativeWrapper = (
  platform: NativePlatform,
  url: string,
) => {
  const protocol = getNativeWrapperProtocol(platform);
  if (!protocol) {
    return;
  }
  window.location.href = `${protocol}://${url}`;
};

export const rememberNativePlatform = (platform: NativePlatform) => {
  localStorage.setItem(NATIVE_PLATFORM_STORAGE_KEY, platform);
};

export const getStoredNativePlatform = () => {
  return parseNativePlatform(localStorage.getItem(NATIVE_PLATFORM_STORAGE_KEY));
};

export const clearStoredNativePlatform = () => {
  localStorage.removeItem(NATIVE_PLATFORM_STORAGE_KEY);
};

export const openInDeviceBrowser = (url: string) => {
  sendMessageToNativeWrapper({
    type: 'OPEN_IN_BROWSER',
    url,
  });
};
