import { DatabaseTablesType } from '../../../../Database/Database.types';
import { AccountApiType } from '../../../../User/User.types';

export const ACCOUNTS_TABLE_COLUMNS =
  '[id+workspaceId], workspaceId, payload, *relatedTeams, *relatedAccounts';

export interface AccountsTableType
  extends Omit<DatabaseTablesType, 'createdAt'> {
  workspaceId: string;
  sourceData: AccountApiType;
  relatedTeams: string[];
  relatedDesktops: string[];
}
