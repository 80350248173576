import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const KebabIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="3"
      viewBox="0 0 16 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.67157 2.32843 0 1.5 0C0.671573 0 0 0.67157 0 1.5C0 2.32843 0.671573 3 1.5 3ZM8 3C8.82843 3 9.5 2.32843 9.5 1.5C9.5 0.67157 8.82843 0 8 0C7.17157 0 6.5 0.67157 6.5 1.5C6.5 2.32843 7.17157 3 8 3ZM16 1.5C16 2.32843 15.3284 3 14.5 3C13.6716 3 13 2.32843 13 1.5C13 0.67157 13.6716 0 14.5 0C15.3284 0 16 0.67157 16 1.5Z"
        fill="currentColor"
      />
    </svg>
  );
});
