import React from 'react';
import type { PreviewSegmentContextType } from './PreviewSegment.types';

export const PreviewSegmentContext = React.createContext<PreviewSegmentContextType>(
  {
    isPreviewMode: false,
    navigateToSegment: () => {},
    handleHoverPreview: () => {},
    handleClickPreview: () => {},
    closePreview: () => {},
  },
);
