import styled, { css } from 'styled-components';
import React, {
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import { IconProps } from '../../icons/icon.types';
import { MenuContext } from './Menu.context';
import { CheckmarkIcon, KeyboardArrowRightIcon } from '../../icons';

export const StyledMenuButton = styled.button<{
  withIcon: boolean;
  withNextLevelArrow: boolean;
  bigIcon: boolean;
  highlightRed?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 2rem;
  padding: 0.25rem ${p => (p.withNextLevelArrow ? 4 : 2)}rem 0.25rem 1rem;
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.875rem;
  line-height: 18px;
  position: relative;
  text-align: left;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};

  ${p =>
    p.highlightRed &&
    css`
      color: ${p.theme.colors.CriticalLight};
    `}
  ${p =>
    p.withIcon &&
    css`
      padding-left: ${p.bigIcon ? 3 : 2.5}rem;
    `}
  svg {
    color: ${({ highlightRed, theme }) =>
      highlightRed && theme.colors.CriticalLight} !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  .iconWrapper,
  .nextLevelArrowWrapper,
  .checkMarkWrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;

    ${p =>
      p.bigIcon &&
      css`
        width: 1.5rem;
        height: 1.5rem;
      `}
    svg {
      color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
    }
  }

  .iconWrapper {
    left: 1rem;
    color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  }

  .nextLevelArrowWrapper,
  .checkMarkWrapper {
    right: 0.75rem;
  }

  &:disabled {
    pointer-events: none;

    span,
    div {
      color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
    }

    svg {
      color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
    }
  }
`;

interface MenuItemProps {
  icon?: FC<IconProps> | false;
  closeOnClick?: boolean;
  displayNextLevelArrow?: boolean;
  displayCheckMark?: boolean;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  bigIcon?: boolean;
  highlightRed?: boolean;
  renderContent?: () => ReactNode;
  isDisabled?: boolean;
  isDraggable?: boolean;
}

const StyledMenuItem = styled.li`
  white-space: nowrap;
  position: relative;
`;

export const MenuItem: FC<PropsWithChildren<MenuItemProps>> = React.memo(
  ({
    icon: Icon,
    children,
    isDisabled = false,
    closeOnClick = true,
    displayNextLevelArrow = false,
    displayCheckMark = false,
    bigIcon = true,
    highlightRed = false,
    renderContent,
    onClick,
    isDraggable,
    ...props
  }) => {
    const { closeMenu } = useContext(MenuContext);
    const handleClick = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
        if (closeOnClick) {
          closeMenu();
        }
      },
      [closeOnClick, onClick, closeMenu],
    );

    return (
      <StyledMenuItem {...props}>
        {renderContent ? (
          renderContent()
        ) : (
          <>
            <StyledMenuButton
              disabled={isDisabled}
              type="button"
              withIcon={!!Icon}
              withNextLevelArrow={displayNextLevelArrow}
              bigIcon={bigIcon}
              highlightRed={highlightRed}
              onClick={handleClick}
              data-testid="item-button">
              {Icon && (
                <div className="iconWrapper">
                  <Icon data-testid="item-icon" />
                </div>
              )}
              <span>{children}</span>
              {displayNextLevelArrow && (
                <div className="nextLevelArrowWrapper">
                  <KeyboardArrowRightIcon />
                </div>
              )}
              {displayCheckMark && (
                <div className="checkMarkWrapper">
                  <CheckmarkIcon />
                </div>
              )}
            </StyledMenuButton>
          </>
        )}
      </StyledMenuItem>
    );
  },
);
