export enum TimelineEventScope {
  apps = 'apps',
  desktop_apps = 'desktop-apps',
  links = 'links',
  desktops = 'desktops',
  folders = 'folders',
  tags = 'tags',
  workspaces = 'workspaces',
  teams = 'teams',
  payment = 'payment',
  workspace_teams = 'workspace-teams',
  payment_customers = 'payment-customers',
}

export enum TimelineEventAction {
  add = 'add',
  open = 'open',
  remove = 'remove',
  create = 'create',
  delete = 'delete',
  update = 'update',
  comment = 'comment',
  remove_custom = 'remove_custom',
  create_custom = 'create_custom',
  add_custom_to_desktop = 'add_custom_to_desktop',
  remove_custom_from_desktop = 'remove_custom_from_desktop',
  add_team = 'add_team',
  remove_team = 'remove_team',
  add_receiver = 'add_receiver',
  remove_receiver = 'remove_receiver',
  add_sender = 'add_sender',
  remove_sender = 'remove_sender',
  failed_payment_attempt = 'failed_payment_attempt',
  guest_limit_exceed = 'guest_limit_exceed',
  account_deactivated_receiver = 'account_deactivated_receiver',
  account_deactivated_sender = 'account_deactivated_sender',
  account_activated_receiver = 'account_activated_receiver',
  account_activated_sender = 'account_activated_sender',
  account_removed_receiver = 'account_removed_receiver',
  account_removed_sender = 'account_removed_sender',
  set_admin_sender = 'set_admin_sender',
  set_admin_receiver = 'set_admin_receiver',
  lost_owner = 'lost_owner',
  gained_owner = 'gained_owner',
  remove_app_section_from_desktop = 'remove_app_section_from_desktop',
  remove_link_section_from_desktop = 'remove_link_section_from_desktop',
}

export enum SearchOptions {
  all = '',
  apps = 'apps',
  links = 'links',
  events = 'events',
}

export const DATE_RANGE_FILTER_FORMAT = 'MMM d, yyyy';
export const DATE_RANGE_FORMAT = 'yyyy-MM-dd';
export const TIMELINE_EVENTS_PAGE_SIZE = 20;

export const GRAPHQL_TYPENAME_TIMELINE_EVENT = 'Timeline';
