export enum EncryptionTranslation {
  // Master Password lock open button and screen
  masterPasswordLockOpenButtonText = 'encryption_masterPasswordLockOpenButtonText',
  masterPasswordUnlockMessageText = 'encryption_masterPasswordUnlockMessageText',
  masterPasswordUnlockMessageFirstRow = 'encryption_masterPasswordUnlockMessageFirstRow',
  masterPasswordUnlockMessageSecondRow = 'encryption_masterPasswordUnlockMessageSecondRow',
  masterPasswordUnlockMessageThirdRow = 'encryption_masterPasswordUnlockMessageThirdRow',
  masterPasswordUnlockInputTitle = 'encryption_masterPasswordUnlockInputTitle',
  masterPasswordCreatePasswordTitle = 'encryption_masterPasswordCreatePasswordTitle',
  masterPasswordCreatePasswordSubTitle = 'encryption_masterPasswordCreatePasswordSubTitle',
  masterPasswordUnlockButtonText = 'encryption_masterPasswordUnlockButtonText',
  masterPasswordUnlockInputPlaceholder = 'encryption_masterPasswordUnlockInputPlaceholder',
  masterPasswordUnlockScreenErrorMessage = 'encryption_masterPasswordUnlockScreenErrorMessage',
  masterPasswordUnlockExplanationMessage = 'encryption_masterPasswordUnlockExplanationMessage',
  masterPasswordUnlockDisabledMessage = 'encryption_masterPasswordUnlockDisabledMessage',
  masterPasswordSetPasswordMessage = 'encryption_masterPasswordSetPasswordMessage',

  // Master Password lock screen for Make Owner modal
  masterPasswordUnlockOnMakeOwnerInputTitle = 'encryption_masterPasswordUnlockOnMakeOwnerInputTitle',
  masterPasswordUnlockOnMakeOwnerInputPlaceholder = 'encryption_masterPasswordUnlockOnMakeOwnerInputPlaceholder',
  masterPasswordUnlockOnMakeOwnerScreenErrorMessage = 'encryption_masterPasswordUnlockOnMakeOwnerScreenErrorMessage',
  masterPasswordUnlockOnMakeOwnerButtonText = 'encryption_masterPasswordUnlockOnMakeOwnerButtonText',

  // Passwords page
  passwordsPagePasswordSharedIcon = 'encryption_passwordsPagePasswordSharedIcon',
  passwordsPagePasswordLaunchIcon = 'encryption_passwordsPagePasswordLaunchIcon',
  passwordsPageEmptyStateDescription = 'encryption_passwordsPageEmptyStateDescription',
  passwordsPageEmptyStateSupportText = 'encryption_passwordsPageEmptyStateSupportText',

  // Create vault form
  createVaultFormUpdateButton = 'encryption_createVaultFormUpdateButton',
  createVaultModalHeader = 'encryption_createVaultModalHeader',
  createVaultModalTabManual = 'encryption_createVaultModalTabManual',
  createVaultModalTabGroup = 'encryption_createVaultModalTabGroup',
  createVaultModalTabImport = 'encryption_createVaultModalTabImport',

  // Edit vault form
  editVaultFormUpdateButton = 'encryption_editVaultFormUpdateButton',
  editVaultFormOwnerNoSharingWarningMessage = 'encryption_editVaultFormOwnerNoSharingWarningMessage',
  editVaultFormShowPasswordButton = 'encryption_editVaultFormShowPasswordButton',
  editVaultFormSuccessMessage = 'encryption_editVaultFormSuccessMessage',
  editVaultFormEncryptionErrorMessage = 'encryption_editVaultFormEncryptionErrorMessage',
  editVaultOwnerHasNoKeysetErrorMessage = 'encryption_editVaultOwnerHasNoKeysetErrorMessage',

  // Edit vault modal and form
  editPasswordsModalHeader = 'encryption_editPasswordsModalHeader',

  // Share vault modal and form
  sharePasswordsModalHeader = 'encryption_sharePasswordsModalHeader',
  sharePasswordsFormShareButton = 'encryption_sharePasswordsFormShareButton',
  sharePasswordsAccountsListDeleteButton = 'encryption_sharePasswordsAccountsListDeleteButton',
  sharePasswordsAccountsListDeleteConfirmationMessage = 'encryption_sharePasswordsAccountsListDeleteConfirmationMessage',

  // Select vault for desktop
  selectVaultForDesktopModalHeader = 'encryption_selectVaultForDesktopModalHeader',
  selectVaultEncryptedItemPlaceholder = 'encryption_selectVaultEncryptedItemPlaceholder',
  selectVaultNoneOption = 'encryption_selectVaultNoneOption',
  selectVaultItemExpandButton = 'encryption_selectVaultItemExpandButton',
  selectVaultAddPasswordButton = 'encryption_selectVaultAddPasswordButton',
  selectVaultCloseButton = 'encryption_selectVaultCloseButton',

  // Unshare vault
  unshareVaultModalHeader = 'encryption_unshareVaultModalHeader',
  unshareVaultDescription = 'encryption_unshareVaultDescription',
  unshareVaultDeleteButton = 'encryption_unshareVaultDeleteButton',
  unshareVaultCancelButton = 'encryption_unshareVaultCancelButton',

  // Delete vault
  deleteVaultModalHeader = 'encryption_deleteVaultModalHeader',
  deleteVaultDescription = 'encryption_deleteVaultDescription',
  deleteVaultDeleteButton = 'encryption_deleteVaultDeleteButton',
  deleteVaultCancelButton = 'encryption_deleteVaultCancelButton',

  // Delete multiple vaults
  deleteMultipleVaultsModalHeader = 'encryption_deleteMultipleVaultsModalHeader',
  deleteMultipleVaultsDescription = 'encryption_deleteMultipleVaultsDescription',
  deleteMultipleVaultsInputDescription = 'encryption_deleteMultipleVaultsInputDescription',
  deleteMultipleVaultsDeleteButton = 'encryption_deleteMultipleVaultsDeleteButton',
  deleteMultipleVaultsCancelButton = 'encryption_deleteMultipleVaultsCancelButton',

  // Passwords generator
  editPasswordsPasswordLength = 'encryption_editPasswordsPasswordLength',
  editPasswordsUppercase = 'encryption_editPasswordsUppercase',
  editPasswordsLowercase = 'encryption_editPasswordsLowercase',
  editPasswordsDigits = 'encryption_editPasswordsDigits',
  editPasswordsSymbols = 'encryption_editPasswordsSymbols',
  editPasswordsGenerateNewPassword = 'encryption_editPasswordsGenerateNewPassword',
  editPasswordsCancel = 'encryption_editPasswordsCancel',
  editPasswordsErrorText = 'encryption_editPasswordsErrorText',
  editPasswordLoginInputLabel = 'encryption_editPasswordLoginInputLabel',
  editPasswordInputLabel = 'encryption_editPasswordInputLabel',
  editPasswordUrlInputLabel = 'encryption_editPasswordUrlInputLabel',
  editPasswordCopiedMessage = 'encryption_editPasswordCopiedMessage',
  editPasswordShowPassword = 'encryption_editPasswordShowPassword',
  editPasswordHidePassword = 'encryption_editPasswordHidePassword',
  editPasswordCopyPassword = 'encryption_editPasswordCopyPassword',
  formUrlRequired = 'encryption_formUrlRequired',

  // Select vault item app group
  vaultItemGroupSelectDescription = 'encryption_vaultItemGroupSelectDescription',

  // Reencrypt vault accesses
  reencryptAccessesModalHeader = 'encryption_reencryptAccessesModalHeader',
  reencryptAccessesDescription = 'encryption_reencryptAccessesDescription',
  reencryptAccessesAccountInfo = 'encryption_reencryptAccountInfo',
  reencryptAccessesAccept = 'encryption_reencryptAccessesAccept',
  reencryptAccessesAcceptConfirmationMessage = 'encryption_reencryptAccessesAcceptConfirmationMessage',
  reencryptAccessesDecline = 'encryption_reencryptAccessesDecline',
  reencryptAccessesRequestAcceptedMessage = 'encryption_reencryptAccessesRequestAcceptedMessage',
  reencryptAccessesRequestDeclinedMessage = 'encryption_reencryptAccessesRequestDeclinedMessage',
  switcherHeader = 'encryption_switcherHeader',
  downloadPasswordsButton = 'encryption_downloadPasswordsButton',

  //Passwords
  passwordCreateButton = 'encryption_passwordCreateButton',
  passwordFilter = 'encryption_passwordFilter',
  passwordEditPassword = 'encryption_passwordEditPassword',
  passwordSharePassword = 'encryption_passwordSharePassword',
  passwordSharePasswordTooltipMessage = 'encryption_passwordSharePasswordTooltipMessage',
  passwordSelectPassword = 'encryption_passwordSelectPassword',
  passwordUnselectPassword = 'encryption_passwordUnselectPassword',
  passwordUnselectAllPasswords = 'encryption_passwordUnselectAllPasswords',
  passwordSelectAllPasswords = 'encryption_passwordSelectAllPasswords',
  passwordEditApp = 'encryption_passwordEditApp',
  passwordDeletePassword = 'encryption_passwordDeletePassword',
  passwordDeleteSeveralPassword = 'encryption_passwordDeleteSeveralPassword',
  passwordViewLoginRow = 'encryption_passwordViewLoginRow',
  passwordViewLoginCopyButton = 'encryption_passwordViewLoginCopyButton',
  passwordViewPasswordRow = 'encryption_passwordViewPasswordRow',
  passwordViewPasswordCopyButton = 'encryption_passwordViewPasswordCopyButton',
  passwordViewStrengthRow = 'encryption_passwordViewStrengthRow',
  passwordViewWebsiteRow = 'encryption_passwordViewWebsiteRow',
  passwordStrengthVeryStrong = 'encryption_passwordStrengthVeryStrong',
  passwordStrengthMedium = 'encryption_passwordStrengthMedium',
  passwordStrengthWeak = 'encryption_passwordStrengthWeak',
  passwordControlsEdit = 'encryption_passwordControlsEdit',
  passwordControlsDelete = 'encryption_passwordControlsDelete',
  passwordControlsUnshare = 'encryption_passwordControlsUnshare',
  passwordControlsShare = 'encryption_passwordControlsShare',
  passwordControlsSharedWithTitle = 'encryption_passwordControlsSharedWithTitle',
  passwordControlsSharedByTitle = 'encryption_passwordControlsSharedByTitle',
  passwordControlsSharingDisabledNonAdmin = 'encryption_passwordControlsSharingDisabledNonAdmin',
  passwordControlsSharingDisabledNonPro = 'encryption_passwordControlsSharingDisabledNonPro',
  passwordCopyLoginToastText = 'encryption_passwordCopyLoginToastText',
  passwordCopyPasswordToastText = 'encryption_passwordCopyPasswordToastText',
  passwordVaultItemExpandTooltip = 'encryption_passwordVaultItemExpandTooltip',
  passwordSharePasswordInfo = 'encryption_passwordSharePasswordInfo',
  passwordUnableToDecryptMessage = 'encryption_passwordUnableToDecryptMessage',
  passwordSelectTooltip = 'encryption_passwordSelectTooltip',
  passwordLaunchButtonTooltip = 'encryption_passwordLaunchButtonTooltip',
  passwordMoreButtonTooltip = 'encryption_passwordMoreButtonTooltip',

  importPasswords = 'encryption_importPasswords',
  importPasswordsModalSubTitle = 'encryption_importPasswordsModalSubTitle',
  importPasswordsModalUploadButton = 'encryption_importPasswordsModalUploadButton',
  importPasswordsModalUploadButtonOnDragging = 'encryption_importPasswordsModalUploadButtonOnDragging',
  importPasswordsControlsHelpLink = 'encryption_importPasswordsControlsHelpLink',
  importPasswordsControlsSubmitButton = 'encryption_importPasswordsControlsSubmitButton',
  importPasswordsControlsRemoveButton = 'encryption_importPasswordsControlsRemoveButton',
  importPasswordsErrorInFile = 'encryption_importPasswordsErrorInFile',
  importPasswordsImportingMessage = 'encryption_importPasswordsImportingMessage',
  importPasswordsImportingOkButton = 'encryption_importPasswordsImportingOkButton',

  passwordsAppPasswords = 'encryption_passwordsAppPasswords',
  passwordsGroupPasswords = 'encryption_passwordsGroupPasswords',
  passwordsOtherPasswords = 'encryption_passwordsOtherPasswords',

  importPasswordsSuccessImportMessage = 'encryption_importPasswordsSuccessImportMessage',
  passwordsBadFileErrorMessage = 'encryption_passwordsBadFileErrorMessage',
  passwordsWrongFileTypeErrorMessage = 'encryption_passwordsWrongFileTypeErrorMessage',
  passwordsWrongRowsErrorMessage = 'encryption_passwordsWrongRowsErrorMessage',
  passwordsRowsLimitErrorMessage = 'encryption_passwordsRowsLimitErrorMessage',
  passwordsDuplicatedMessage = 'encryption_passwordsDuplicatedMessagee',
}

export type EncryptionLocale = {
  [key in EncryptionTranslation]: string;
};
