import React, { FC } from 'react';
import { IconProps } from '../../shared/icons/icon.types';

export const SharpIcon: FC<IconProps> = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.368 3.00999C8.46533 3.02544 8.55866 3.05993 8.64265 3.11147C8.72665 3.16301 8.79966 3.23061 8.85752 3.31038C8.91538 3.39016 8.95694 3.48055 8.97985 3.5764C9.00275 3.67225 9.00654 3.77168 8.991 3.86899L8.57 6.49999H12.55L13.01 3.63199C13.0481 3.44193 13.1583 3.274 13.3175 3.16342C13.4767 3.05284 13.6726 3.00819 13.864 3.03884C14.0554 3.0695 14.2275 3.17306 14.3442 3.32783C14.4609 3.48259 14.5132 3.67654 14.49 3.86899L14.07 6.49999H16.25C16.4489 6.49999 16.6397 6.57901 16.7803 6.71966C16.921 6.86031 17 7.05108 17 7.24999C17 7.4489 16.921 7.63967 16.7803 7.78032C16.6397 7.92097 16.4489 7.99999 16.25 7.99999H13.83L13.19 12H15.75C15.9489 12 16.1397 12.079 16.2803 12.2197C16.421 12.3603 16.5 12.5511 16.5 12.75C16.5 12.9489 16.421 13.1397 16.2803 13.2803C16.1397 13.421 15.9489 13.5 15.75 13.5H12.95L12.49 16.369C12.4519 16.5591 12.3417 16.727 12.1825 16.8376C12.0233 16.9481 11.8274 16.9928 11.636 16.9621C11.4446 16.9315 11.2725 16.8279 11.1558 16.6732C11.0391 16.5184 10.9868 16.3244 11.01 16.132L11.43 13.5H7.45L6.99 16.369C6.9519 16.5591 6.84169 16.727 6.68249 16.8376C6.52328 16.9481 6.32744 16.9928 6.13604 16.9621C5.94464 16.9315 5.77254 16.8279 5.65582 16.6732C5.53911 16.5184 5.48685 16.3244 5.51 16.132L5.93 13.5H3.75C3.55109 13.5 3.36032 13.421 3.21967 13.2803C3.07902 13.1397 3 12.9489 3 12.75C3 12.5511 3.07902 12.3603 3.21967 12.2197C3.36032 12.079 3.55109 12 3.75 12H6.17L6.81 7.99999H4.25C4.05109 7.99999 3.86032 7.92097 3.71967 7.78032C3.57902 7.63967 3.5 7.4489 3.5 7.24999C3.5 7.05108 3.57902 6.86031 3.71967 6.71966C3.86032 6.57901 4.05109 6.49999 4.25 6.49999H7.05L7.51 3.63199C7.54146 3.43581 7.64947 3.26013 7.81034 3.14351C7.9712 3.02689 8.17176 2.97887 8.368 3.00999ZM11.67 12L12.31 7.99999H8.33L7.69 12H11.67Z"
        fill="currentColor"
      />
    </svg>
  );
};
