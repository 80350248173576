import { gql } from '@apollo/client';
import {
  GRAPHQL_TYPENAME_DESKTOP_ACCESS,
  GRAPHQL_TYPENAME_DESKTOP_APP,
} from './Desktop.constants';

export const DesktopAccessFragmentFields = `
id
accessType
account {
  id
  _id
  email
  firstName
  lastName
  image {
    id
    contentUrl
  }
  origin
}
workspaceTeam {
  id
  _id
  name
  membersCount
  description
}
`;

export const DesktopFragmentFields = `
id
_id
name
description
shareable
accountIds
memberCount
userCount
teamCount
accountSortPosition
chatConversationIri
chatEnabled
accountsThatChatHidden
createdBy {
  id
  firstName
  lastName
}
`;

export const DesktopFragmentWithAccessFields = `
${DesktopFragmentFields}
access(filterOnlyAccountOrTeamAccess: true) {
  edges {
    node {
      ${DesktopAccessFragmentFields}
    }
  }
}
`;

export const DesktopFragmentWithAccessFieldsNoFilter = `
${DesktopFragmentFields}
access {
  edges {
    node {
      ${DesktopAccessFragmentFields}
    }
  }
}
`;

export const AppFragmentFields = `
id
_id
name
fullName
custom
logo {
  id
  contentUrl
}
uri
loginUri
openUri
category {
  _id
  id
  name
}
groupApp {
  id
}
hasSsoOverride
loginUriPattern
groupApp {
  id
}
groupEnabled
`;

export const DesktopAppFragmentFields = `
id
_id
createdAt
accountFavorite
accountSortPosition
app {
  ${AppFragmentFields}
}
`;

export const DesktopAccessFragment = gql`
  fragment DesktopAccessFragment on ${GRAPHQL_TYPENAME_DESKTOP_ACCESS} {
    ${DesktopAccessFragmentFields}
  }
`;

export const DesktopAppFragment = gql`
  fragment DesktopAppFragment on ${GRAPHQL_TYPENAME_DESKTOP_APP} {
    ${DesktopAppFragmentFields}
  }
`;
