export enum BillingTranslation {
  // Plan intervals
  planIntervalDay = 'billing_planIntervalDay',
  planIntervalWeek = 'billing_planIntervalWeek',
  planIntervalMonth = 'billing_planIntervalMonth',
  planIntervalYear = 'billing_planIntervalYear',
  planIntervalPerUser = 'billing_planIntervalPerUser',

  // Plans
  planFreeTitle = 'billing_planFreeTitle',
  planFreeDescription = 'billing_planFreeDescription',
  planProTitle = 'billing_planProTitle',
  planProDescription = 'billing_planProDescription',
  planEnterpriseTitle = 'billing_planEnterpriseTitle',
  planEnterpriseDescription = 'billing_planEnterpriseDescription',
  planEnterpriseUsps = 'billing_planEnterpriseUsps',
  planTrialNote = 'billing_planTrialNote',

  // General billing form translations
  formNameCardDetailsLabel = 'billing_formNameCardDetailsLabel',
  formNameOfCardHolderLabel = 'billing_formNameOfCardHolderLabel',
  formNameOfCardHolderRequired = 'billing_formNameOfCardHolderRequired',
  formBillingNameLabel = 'billing_formBillingNameLabel',
  formBillingNameRequired = 'billing_formBillingNameRequired',
  formVatType = 'billing_formVatType',
  formVatTypeRequired = 'billing_formVatTypeRequired',
  formVatNumber = 'billing_formVatNumber',
  formVatNumberRequired = 'billing_formVatNumberRequired',
  formCountryLabel = 'billing_formCountryLabel',
  formCityLabel = 'billing_formCityLabel',
  formCityRequired = 'billing_formCityRequired',
  formLine1Label = 'billing_formLine1Label',
  formLine1Required = 'billing_formLine1Required',
  formLine2Label = 'billing_formLine2Label',
  formPostCodeLabel = 'billing_formPostCodeLabel',
  formPostCodeRequired = 'billing_formPostCodeRequired',
  formStateLabel = 'billing_formStateLabel',
  formCouponLabel = 'billing_formCouponLabel',
  formCouponPlaceholder = 'billing_formCouponPlaceholder',
  formEditSubmitButton = 'billing_formEditSubmitButton',
  formEditCancelButton = 'billing_formEditCancelButton',
  formEmailLabel = 'billing_formEmailLabel',
  formEmailInvalid = 'billing_formEmailInvalid',
  formEmailRequired = 'billing_formEmailRequired',
  formVatLearnMoreLabelLink = 'billing_formVatLearnMoreLabelLink',
  formVatLearnMoreLink = 'billing_formVatLearnMoreLink',
  formClusterTitleBillingContact = 'billing_formClusterTitleBillingContact',
  formClusterTitleAddress = 'billing_formClusterTitleAddress',
  formClusterTitleVat = 'billing_formClusterTitleVat',

  // Stripe specific translations
  stripeInvalidNumber = 'billing_stripeInvalidNumber',
  stripeIncompleteNumber = 'billing_stripeIncompleteNumber',
  stripeIncompleteExpiry = 'billing_stripeIncompleteExpiry',
  stripeInvalidExpiryMonthPast = 'billing_stripeInvalidExpiryMonthPast',
  stripeInvalidExpiryYearPast = 'billing_stripeInvalidExpiryYearPast',
  stripeIncompleteCvc = 'billing_stripeIncompleteCvc',

  // Onboard billing translations
  onboardingBillingTitle = 'billing_onboardingBillingTitle',
  onboardingChangeCardInfoMessage = 'billing_onboardingChangeCardInfoMessage',
  onboardingAddPaymentMethodButton = 'billing_onboardingAddPaymentMethodButton',
  onboardingBillingSuccess = 'billing_onboardingBillingSuccess',

  // Workspace admin translations
  adminHeaderTitle = 'billing_adminHeaderTitle',

  adminBillingCancelled = 'billing_adminBillingCancelled',
  adminBillingNotOk = 'billing_adminBillingNotOk',

  adminBillingPlanTitle = 'billing_adminBillingPlanTitle',
  adminBillingPlanButton = 'billing_adminBillingPlanButton',
  adminBillingCouponMessage = 'billing_adminBillingCouponMessage',
  adminBillingDowngradeModalTitle = 'billing_adminBillingDowngradeModalTitle',
  adminBillingDowngradeModalHighlightText = 'billing_adminBillingDowngradeModalHighlightText',
  adminBillingDowngradeModalText = 'billing_adminBillingDowngradeModalText',
  downgradeModalTextBullet1 = 'billing_downgradeModalTextBullet1',
  downgradeModalTextBullet2 = 'billing_downgradeModalTextBullet2',
  downgradeModalTextBullet3 = 'billing_downgradeModalTextBullet3',
  downgradeModalTextBullet4 = 'billing_downgradeModalTextBullet4',
  downgradeModalTextBullet5 = 'billing_downgradeModalTextBullet5',
  downgradeModalTextBullet6 = 'billing_downgradeModalTextBullet6',
  downgradeModalTextBullet7 = 'billing_downgradeModalTextBullet7',
  downgradeModalTextBullet8 = 'billing_downgradeModalTextBullet8',
  adminBillingDowngradeButton = 'billing_adminBillingDowngradeButton',
  adminBillingCancelSubscriptionConfirmationHeader = 'billing_adminBillingCancelSubscriptionConfirmationHeader',
  adminBillingCancelSubscriptionConfirmationMessage = 'billing_adminBillingCancelSubscriptionConfirmationMessage',
  adminBillingCancelSubscriptionSuccessMessage = 'billing_adminBillingCancelSubscriptionSuccessMessage',
  adminBillingActivateSubscriptionButton = 'billing_adminBillingActivateSubscriptionButton',
  adminBillingCancelDowngradeSubscriptionButton = 'billing_adminBillingCancelDowngradeSubscriptionButton',
  adminBillingActivateSubscriptionConfirmationHeader = 'billing_adminBillingActivateSubscriptionConfirmationHeader',
  adminBillingActivateSubscriptionConfirmationMessage = 'billing_adminBillingActivateSubscriptionConfirmationMessage',
  adminBillingActivateSubscriptionSuccessMessage = 'billing_adminBillingActivateSubscriptionSuccessMessage',
  adminBillingCancelDowngradeConfirmationMessage = 'billing_adminBillingCancelDowngradeConfirmationMessage',
  adminBillingCancelDowngradeConfirmationHeader = 'billing_adminBillingCancelDowngradeConfirmationHeader',
  adminBillingCancelDowngradeSuccessMessage = 'billing_adminBillingCancelDowngradeSuccessMessage',
  adminBillingWorkspaceMembersCount = 'billing_adminBillingWorkspaceMembersCount',
  adminBillingNextRenewalDate = 'billing_adminBillingNextRenewalDate',
  adminBillingSubscriptionEnds = 'billing_adminBillingSubscriptionEnds',

  adminBillingInformationTitle = 'billing_adminBillingInformationTitle',
  adminBillingInformationEditButton = 'billing_adminBillingInformationEditButton',
  adminBillingInformationUpdatedMessage = 'billing_adminBillingInformationUpdatedMessage',
  adminBillingInformationModalTitle = 'billing_adminBillingInformationModalTitle',

  adminBillingContactTitle = 'billing_adminBillingContactTitle',
  adminBillingContactBody = 'billing_adminBillingContactBody',
  adminBillingContactLabel = 'billing_adminBillingContactLabel',
  adminBillingContactSubmitButton = 'billing_adminBillingContactSubmitButton',
  adminBillingContactUpdatedMessage = 'billing_adminBillingContactUpdatedMessage',

  adminPaymentMethodsTitle = 'billing_adminPaymentMethodsTitle',
  adminPaymentMethodsAddButton = 'billing_adminPaymentMethodsAddButton',
  adminPaymentMethodChangeSuccess = 'billing_adminPaymentMethodChangeSuccess',
  adminPaymentMethodModalTitle = 'billing_adminPaymentMethodModalTitle',
  adminPaymentMethodSetAsDefault = 'billing_adminPaymentMethodSetAsDefault',
  adminPaymentMethodDeleteSuccess = 'billing_adminPaymentMethodDeleteSuccess',
  adminPaymentMethodDeleteConfirmationHeader = 'billing_adminPaymentMethodDeleteConfirmationHeader',
  adminPaymentMethodDeleteConfirmation = 'billing_adminPaymentMethodDeleteConfirmation',
  adminPaymentMethodDeleteConfirmationButtonText = 'billing_adminPaymentMethodDeleteConfirmationButtonText',

  adminInvoicesTitle = 'billing_adminInvoicesTitle',
  adminInvoicesNoInvoices = 'billing_adminInvoicesNoInvoices',

  // Table headers
  adminDateTableHeader = 'billing_adminDateTableHeader',
  adminPaymentMethodTableHeader = 'billing_adminPaymentMethodTableHeader',
  adminAmountTableHeader = 'billing_adminAmountTableHeader',
  adminPDFTableHeader = 'billing_adminPDFTableHeader',
  adminDefaultMetodTableHeader = 'billing_adminDefaultMetodTableHeader',
  adminPaymentAccountTableHeader = 'billing_adminPaymentAccountTableHeader',
  adminExpiryDateTableHeader = 'billing_adminExpiryDateTableHeader',

  // Upgrade
  upgradeFreeModalTitle = 'billing_upgradeFreeModalTitle',
  upgradeFreeModalSubheaderText = 'billing_upgradeFreeModalSubheaderText',
  upgradeFreeModalSubheaderPrice = 'billing_upgradeFreeModalSubheaderPrice',
  upgradeToProButtonLabel = 'billing_upgradeToProButtonLabel',
  upgradeToProTitle = 'billing_upgradeToProTitle',
  upgradeToProDescription = 'billing_upgradeToProDescription',
  upgradeToProDomainSecurity = 'billing_upgradeToProDomainSecurity',
  upgradeToProMembersUnlimitedGuests = 'billing_upgradeToProMembersUnlimitedGuests',
  upgradeToProAudioAndVideoCalls = 'billing_upgradeToProAudioAndVideoCalls',
  upgradeToProSlackChatIntegration = 'billing_upgradeToProSlackChatIntegration',
  upgradeToProSharingPasswords = 'billing_upgradeToProSharingPasswords',
  upgradeToProPriorityOnlineSupport = 'billing_upgradeToProPriorityOnlineSupport',
  upgradeToProChatStorage = 'billing_upgradeToProChatStorage',
  upgradeToProAppIntegrations = 'billing_upgradeToProAppIntegrations',
  upgradeToProSingleSignOn = 'billing_upgradeToProSingleSignOn',
  upgradeToProSubdomain = 'billing_upgradeToProSubdomain',
  upgradeToProSuccessMessage = 'billing_upgradeToProSuccessMessage',
  upgradeToProSuccessContinueButton = 'billing_upgradeToProSuccessContinueButton',
  upgradeConfirmationTitle = 'billing_upgradeConfirmationTitle',
  upgradeConfirmation = 'billing_upgradeConfirmation',
  upgradeEnterNewDetailsButton = 'billing_upgradeEnterNewDetailsButton',
  upgradeContinueButton = 'billing_upgradeContinueButton',
  upgradeAccountSuccess = 'billing_upgradeAccountSuccess',

  // Need to upgrade
  confirmationHeader = 'billing_confirmationHeader',
  confirmButton = 'billing_confirmButton',
  generalUpgradeMessage = 'billing_generalUpgradeMessage',
  requestUpgradeModalTitle = 'billing_requestUpgradeModalTitle',
  requestUpgradeModalIntro = 'billing_requestUpgradeModalIntro',
  requestUpgradeModalUsp1 = 'billing_requestUpgradeModalUsp1',
  requestUpgradeModalUsp2 = 'billing_requestUpgradeModalUsp2',
  requestUpgradeModalUsp3 = 'billing_requestUpgradeModalUsp3',
  requestUpgradeModalUsp4 = 'billing_requestUpgradeModalUsp4',
  requestUpgradeModalUsp5 = 'billing_requestUpgradeModalUsp5',
  requestUpgradeModalUsp6 = 'billing_requestUpgradeModalUsp6',
  requestUpgradeModalUsp7 = 'billing_requestUpgradeModalUsp7',
  requestUpgradeModalUsp8 = 'billing_requestUpgradeModalUsp8',
  requestUpgradeModalUsp9 = 'billing_requestUpgradeModalUsp9',
  requestUpgradeModalUsp10 = 'billing_requestUpgradeModalUsp10',
  requestUpgradeModalConfirm = 'billing_requestUpgradeModalConfirm',
  requestUpgradeModalCancel = 'billing_requestUpgradeModalCancel',
  requestUpgradeModalComing = 'billing_requestUpgradeModalComing',
  seeAllFeaturesLinkText = 'billing_seeAllFeaturesLinkText',
  seeAllFeaturesInProLinkText = 'billing_seeAllFeaturesInProLinkText',

  noAccessToFeature = 'billing_noAccessToFeature',

  downgradeModalTitle = 'billing_downgradeModalTitle',
  downgradeModalHeaderText = 'billing_downgradeModalHeaderText',
  downgradeModalText = 'billing_downgradeModalText',
  downgradeModalWarningText = 'billing_downgradeModalWarningText',
  downgradeModalSubmit = 'billing_downgradeModalSubmit',
  downgradeModalInputPlaceholder = 'billing_downgradeModalInputPlaceholder',
  downgradeModalErrorText = 'billing_downgradeModalErrorText',
}

export type BillingLocale = {
  [key in BillingTranslation]: string;
};
