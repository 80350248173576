import { useCallback, useMemo } from 'react';
import { database } from '../../../Database';
import {
  AccountsRepositoryProps,
  AccountsRepositoryResp,
} from './types/Account.types';
import { getOnlineStatus } from '../../Account.utils';
import { useMultipleEntityRepository } from '../../../../shared/hooks/repository/multipleEntityRepository.hooks';
import {
  getAccountsApi,
  getWorkspaceStatisticsApi,
} from './dataSources/AccountApi.dataSources';
import {
  AccountApiTypeToAccountTableType,
  createAccountRowsMap,
} from './utils/AccountIDDB.utils';
import { getAccountsFromIDDB } from './dataSources/AccountIDDB.dataSources';
import { getRedundantCacheAccounts } from './utils/AccountRepository.utils';
import {
  bulkDeleteAccountsFromIDDB,
  bulkPutAccountsToIDDB,
} from './operations/AccountIDDB.operations';

export const useAccountsRepository = ({
  variables,
  fetchPolicy = 'cache-and-network',
}: AccountsRepositoryProps): AccountsRepositoryResp => {
  const { workspaceId } = variables;

  const fetchAccounts = useCallback(async () => {
    const response = await getAccountsApi(workspaceId);
    const workspaceCacheResponse = await getWorkspaceStatisticsApi(workspaceId);

    if (!response?.ok || !workspaceCacheResponse?.ok) {
      // TODO: add proper retry logic if request failed
      return;
    }

    const workspaceCache = workspaceCacheResponse?.data || {};

    const accountsFromAPI =
      response?.data?.map(account => ({
        ...account,
        workspaceCache: {
          [account.id]: workspaceCache[account.id],
        },
        onlineStatus: getOnlineStatus(
          account.onlineStatus,
          account.lastActionAt,
        ),
      })) || [];

    try {
      const cachedAccountRows = await getAccountsFromIDDB(workspaceId);
      const cachedAccountRowsMap = createAccountRowsMap(cachedAccountRows);
      const redundantCacheAccounts = getRedundantCacheAccounts(
        cachedAccountRows,
        accountsFromAPI,
      );

      const accountsRows = accountsFromAPI.map(account =>
        AccountApiTypeToAccountTableType(
          account,
          workspaceId,
          cachedAccountRowsMap[account.id]?.relatedTeams,
          cachedAccountRowsMap[account.id]?.relatedDesktops,
        ),
      );

      bulkPutAccountsToIDDB(accountsRows);
      bulkDeleteAccountsFromIDDB(
        redundantCacheAccounts.map(account => account.id),
        workspaceId,
      );

      return accountsFromAPI;
    } catch (error) {
      console.error(error);
    }
  }, [workspaceId]);

  const { entities: accounts, loading } = useMultipleEntityRepository({
    fetchFunction: fetchAccounts,
    iddbQuerier: () =>
      database.accounts.where('workspaceId').equals(workspaceId).toArray(),
    dependencies: [workspaceId],
    fetchPolicy,
  });

  return useMemo(
    () => ({
      accounts,
      loading,
      fetchAccounts,
    }),
    [accounts, loading, fetchAccounts],
  );
};
