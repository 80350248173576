export const isPWAWindow = (): boolean => {
  return window.matchMedia('(display-mode: standalone)').matches;
};

export const updateServiceWorker = async () => {
  try {
    const registration = await navigator.serviceWorker.ready;
    return registration.update();
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};
