import { OnNavbar, ThemeType } from '../../Theme';
import { WorkspaceThemesType } from './Workspace.theme.types';

export const WorkspaceTheme: WorkspaceThemesType = {
  [ThemeType.light]: {
    colors: {
      WorkspaceIconDefault: '#454F5B',
    },
  },
  [ThemeType.dark]: {
    colors: {
      WorkspaceIconDefault: OnNavbar.alpha(0.12).rgb().string(),
    },
  },
};
