import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ClipboardIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 1C5.55109 1 5.36032 1.07902 5.21967 1.21967C5.07902 1.36032 5 1.55109 5 1.75V4.75C5 5.164 5.336 5.5 5.75 5.5H10.25C10.4489 5.5 10.6397 5.42098 10.7803 5.28033C10.921 5.13968 11 4.94891 11 4.75V1.75C11 1.55109 10.921 1.36032 10.7803 1.21967C10.6397 1.07902 10.4489 1 10.25 1H5.75ZM6.5 4V2.5H9.5V4H6.5ZM3.626 3.533C3.79813 3.43328 3.92359 3.26926 3.97478 3.07704C4.02598 2.88482 3.99872 2.68013 3.899 2.508C3.79928 2.33587 3.63526 2.21041 3.44304 2.15922C3.25082 2.10802 3.04613 2.13528 2.874 2.235C2.60823 2.38867 2.38757 2.60956 2.23416 2.87548C2.08075 3.1414 1.99999 3.443 2 3.75V13.25C2 14.216 2.784 15 3.75 15H12.25C12.7141 15 13.1592 14.8156 13.4874 14.4874C13.8156 14.1592 14 13.7141 14 13.25V3.75C14 3.443 13.9193 3.1414 13.7658 2.87548C13.6124 2.60956 13.3918 2.38867 13.126 2.235C12.9539 2.13528 12.7492 2.10802 12.557 2.15922C12.3647 2.21041 12.2007 2.33587 12.101 2.508C12.0013 2.68013 11.974 2.88482 12.0252 3.07704C12.0764 3.26926 12.2019 3.43328 12.374 3.533C12.4123 3.55486 12.4441 3.58644 12.4662 3.62455C12.4883 3.66265 12.5 3.70593 12.5 3.75V13.25C12.5 13.3163 12.4737 13.3799 12.4268 13.4268C12.3799 13.4737 12.3163 13.5 12.25 13.5H3.75C3.6837 13.5 3.62011 13.4737 3.57322 13.4268C3.52634 13.3799 3.5 13.3163 3.5 13.25V3.75C3.50001 3.70593 3.51168 3.66265 3.5338 3.62455C3.55593 3.58644 3.58774 3.55486 3.626 3.533Z"
        fill="currentColor"
      />
    </svg>
  );
});
