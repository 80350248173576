import {
  ChromeExtensionLocale,
  ChromeExtensionTranslation,
} from './i18n.types';

export const enChromeExtensionLocale: ChromeExtensionLocale = {
  [ChromeExtensionTranslation.import]: 'Import',
  [ChromeExtensionTranslation.importApps]: 'Import from browser',
  [ChromeExtensionTranslation.importantMessage]:
    'Currently, the import feature only works with chromium based browsers, <a href="{faqUrl}" target="_blank" rel="noopener noreferrer">please read our FAQ.</a>',
  [ChromeExtensionTranslation.importInfo]:
    'Import your apps and bookmarks from a chromium based browser into your Webtop.com account',
  [ChromeExtensionTranslation.importFromChrome]: 'Import from browser',
  [ChromeExtensionTranslation.importInfoInstallExtensionLink]:
    'Install extension',

  [ChromeExtensionTranslation.importLocation]: 'Import location',
  [ChromeExtensionTranslation.importDesktopDescription]:
    'Webtop.com will import all your existing apps and links into a new desktop. Feel free to modify this import desktop name below.',

  [ChromeExtensionTranslation.installExtensionBannerText]:
    'Browser extension is required to be installed for apps and links import.',
  [ChromeExtensionTranslation.installExtensionBannerLink]: 'Install extension',

  [ChromeExtensionTranslation.importAppsSpinnerMessage]:
    'Just a moment, we’re busy loading your apps...',
  [ChromeExtensionTranslation.importAppsTitle]: 'Select apps to add',
  [ChromeExtensionTranslation.importAppsDescription]:
    'These are the apps we found in your browser history and bookmarks that matches available apps in our App Store',
  [ChromeExtensionTranslation.importSelectAllButton]: 'Select All',
  [ChromeExtensionTranslation.importClearAllButton]: 'Clear All',
  [ChromeExtensionTranslation.importSkipToLinksButton]: 'Skip to links',
  [ChromeExtensionTranslation.importSelectedAppsButton]: 'Import selected apps',

  [ChromeExtensionTranslation.importAppsAdded]:
    'Your Apps were successfully imported to your desktop',
  [ChromeExtensionTranslation.importContinueWithLinks]:
    'Continue and add links',
  [ChromeExtensionTranslation.importGoToDesktop]: 'I’m done, go to {desktop}',
  [ChromeExtensionTranslation.importGoToAccount]: 'I’m done, go to my account',

  [ChromeExtensionTranslation.importLinksSpinnerMessage]:
    'Just a moment, we’re busy loading your links...',
  [ChromeExtensionTranslation.importLinksTitle]: 'Select links to add',

  [ChromeExtensionTranslation.importLinksImportantMessage]:
    'The import link feature currently only works with one level of nested folders. If you import sub-folders, they will appear in the root folder.',
  [ChromeExtensionTranslation.importSkipButton]: 'Skip',
  [ChromeExtensionTranslation.importSelectedLinksButton]:
    'Import selected links',
  [ChromeExtensionTranslation.importLinksAddedSubtitle]:
    'Got it! We’ll take it from here.',
  [ChromeExtensionTranslation.importLinksAddedDescription]:
    'Depending on the number of items being imported, this may take several minutes. Once complete, your desktop will be updated automatically.',

  [ChromeExtensionTranslation.installPluginTitle]: 'Install Webtop.com plugin',
  [ChromeExtensionTranslation.installPluginFeaturesTitle]:
    'The Webtop.com browser extension allows you to unlock these features:',
  [ChromeExtensionTranslation.installPluginPasswordManagerFeature]:
    'Password manager',
  [ChromeExtensionTranslation.installPluginImportFeature]:
    'Import apps and bookmarks',
  [ChromeExtensionTranslation.installPluginQuickAddFeature]:
    'Quick-add links straight from browser',
  [ChromeExtensionTranslation.installPluginLearnMore]:
    'The plugin works with all chromium based browsers – <a href="{learnMoreLink}" target="_blank" rel="noopener noreferrer">Learn more</a>',
  [ChromeExtensionTranslation.installPluginFeatures]: '',
  [ChromeExtensionTranslation.installPluginDescription]:
    'Install Webtop.com plugin',
  [ChromeExtensionTranslation.installPluginButton]:
    'Install Webtop.com Browser Extension',
  [ChromeExtensionTranslation.installPluginSkipButton]: 'Skip for now',
  [ChromeExtensionTranslation.installPluginNextButton]: 'Next',
  [ChromeExtensionTranslation.installPluginSuccessMessage]:
    'Browser extension was successfully installed',

  [ChromeExtensionTranslation.installPluginToastTitle]:
    'Add the browser extension',
  [ChromeExtensionTranslation.installPluginToastDescription]:
    'To enable import features and password manager',
  [ChromeExtensionTranslation.installPluginToastLinkLabel]: 'Add extension',
};
