import styled, { css } from 'styled-components';

export const ModalContent = styled.div<{
  backgroundColor?: string;
  textColor?: string;
}>`
  padding: 1.5rem;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
    `}
`;
