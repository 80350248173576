import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const MentionIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="mention-icon"
      {...props}>
      <path
        fill="currentColor"
        fillOpacity="0.87"
        d="M4.749 2.37c-.74.428-1.39.996-1.91 1.673-.52.678-.9 1.45-1.12 2.275-.45 1.665-.21 3.44.65 4.933.86 1.493 2.28 2.583 3.95 3.029 1.66.446 3.44.213 4.93-.65.17-.09.37-.112.56-.058.19.053.34.177.44.346.1.168.13.368.08.556-.05.189-.17.352-.33.455-1.51.873-3.27 1.227-5 1.008-1.73-.22-3.35-1-4.6-2.22-1.24-1.222-2.06-2.818-2.31-4.546-.26-1.727.06-3.491.9-5.022.84-1.53 2.16-2.744 3.75-3.455 1.6-.712 3.38-.881 5.08-.484 1.7.397 3.23 1.34 4.34 2.685 1.12 1.344 1.761 3.016 1.831 4.76.01.033.01.064.01.096v1.5c0 .596-.19 1.175-.55 1.652-.36.476-.86.824-1.43.99-.57.167-1.18.143-1.74-.068-.56-.21-1.03-.596-1.35-1.1-.64.683-1.49 1.122-2.41 1.242-.931.12-1.861-.085-2.651-.582-.79-.496-1.38-1.252-1.67-2.138-.29-.885-.26-1.844.08-2.711.34-.866.97-1.587 1.79-2.037.81-.45 1.76-.602 2.68-.428.91.173 1.74.66 2.33 1.379.59.717.92 1.619.92 2.55v1.25c0 .333.13.65.37.885.23.234.55.366.88.366.33 0 .65-.132.88-.366.24-.234.37-.552.37-.884V7.868c-.02-1.13-.34-2.234-.92-3.204-.58-.97-1.4-1.771-2.39-2.326-.98-.555-2.09-.843-3.22-.837-1.13.005-2.24.306-3.22.87zM10.499 8c0-.662-.26-1.298-.73-1.767C9.3 5.763 8.66 5.5 8 5.5c-.66 0-1.3.263-1.77.732S5.499 7.338 5.499 8s.26 1.299.73 1.768c.47.469 1.11.732 1.77.732.66 0 1.3-.263 1.77-.732.47-.47.73-1.105.73-1.768z"
      />
    </svg>
  );
});
