export enum NotificationTranslation {
  notificationsHeader = 'notifications_notificationsHeader',
  unread = 'notifications_unread',
  markAllRead = 'notifications_markAllRead',
  markAllReadSuccessMessage = 'notifications_markAllReadSuccessMessage',
  settings = 'notifications_settings',
  emptyMessage = 'notifications_emptyMessage',
  fetchMoreNotifications = 'notifications_fetchMoreNotifications',
  missingTitle = 'notifications_missingTitle',
  linkPreviewModalTitle = 'notifications_linkPreviewModalTitle',
  linkPreviewModalDesktopLink = 'notifications_linkPreviewModalDesktopLink',

  NotificationMessageDesktopLinkAdded = 'notifications_NotificationMessageDesktopLinkAdded',
  NotificationMessageDesktopLinkToFileAdded = 'notifications_NotificationMessageDesktopLinkToFileAdded',
  NotificationMessageDesktopLinkAbused = 'notifications_NotificationMessageDesktopLinkAbused',
  NotificationMessageDesktopLinkRemoved = 'notifications_NotificationMessageDesktopLinkRemoved',
  NotificationMessageDesktopAppAdded = 'notifications_NotificationMessageDesktopAppAdded',
  NotificationMessageDesktopAppRemoved = 'notifications_NotificationMessageDesktopAppRemoved',
  NotificationMessageDesktopUserAdded = 'notifications_NotificationMessageDesktopUserAdded',
  NotificationMessageDesktopChatEnabled = 'notifications_NotificationMessageDesktopChatEnabled',

  NotificationMessageUserRoleChange = 'notifications_NotificationMessageUserRoleChange',
  NotificationMessageUserAddedToDesktop = 'notifications_NotificationMessageUserAddedToDesktop',
  NotificationMessageUserRemovedFromDesktop = 'notifications_NotificationMessageUserRemovedFromDesktop',
  NotificationMessageUserTeamAdded = 'notifications_NotificationMessageUserTeamAdded',
  NotificationMessageUserTeamRemoved = 'notifications_NotificationMessageUserTeamRemoved',
  NotificationMessageUserBookmarksImportFinished = 'notifications_NotificationMessageUserBookmarksImportFinished',

  NotificationMessagePaymentNewInvoice = 'notifications_NotificationMessagePaymentNewInvoice',
  NotificationMessagePaymentFailed = 'notifications_NotificationMessagePaymentFailed',
  NotificationMessagePaymentVatInvalid = 'notifications_NotificationMessagePaymentVatInvalid',

  NotificationMessageWorkspaceCustomAppRemoved = 'notifications_NotificationMessageWorkspaceCustomAppRemoved',

  NotificationMessagePasswordRecoveryRequestCreated = 'notifications_NotificationMessagePasswordRecoveryRequestCreated',
  NotificationMessagePasswordRecoveryRequestAccepted = 'notifications_NotificationMessagePasswordRecoveryRequestAccepted',
  NotificationMessagePasswordRecoveryRequestDeclined = 'notifications_NotificationMessagePasswordRecoveryRequestDeclined',
  NotificationMessageVaultAccessSharedWithYou = 'notifications_NotificationMessageVaultAccessSharedWithYou',
  NotificationMessageVaultAccessSharedRemovedFromYou = 'notifications_NotificationMessageVaultAccessSharedRemovedFromYou',

  NotificationMessageScheduledConferenceInvitedMember = 'notifications_NotificationMessageScheduledConferenceInvitedMember',
  NotificationMessageScheduledConferenceBeforeStart = 'notifications_NotificationMessageScheduledConferenceBeforeStart',
  NotificationMessageScheduledConferenceUpdated = 'notifications_NotificationMessageScheduledConferenceUpdated',
  NotificationMessageScheduledConferenceCanceled = 'notifications_NotificationMessageScheduledConferenceCanceled',
  NotificationMessageTeamDesktopAdded = 'notifications_NotificationMessageTeamDesktopAdded',
  NotificationMessageTeamDesktopRemoved = 'notifications_NotificationMessageTeamDesktopRemoved',

  tooltipNotificationOptionsButton = 'notifications_tooltipNotificationOptionsButton',
}

export type NotificationLocale = {
  [key in NotificationTranslation]: string;
};
