import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const OrganizeAndSearchIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="organize-and-search-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.667 4.33301H12.167H22.0003C22.7367 4.33301 23.3337 4.92996 23.3337 5.66634V10.1663V11.6663H23.8337H24.8337H25.3337V10.1663V3.83301V2.33301H23.8337H12.167H10.667V2.83301V3.83301V4.33301ZM21.3337 13.6663H19.8337H19.3337V15.6663H19.8337H21.3337V15.1663V14.1663V13.6663ZM19.3337 17.6663H22.0003C22.7367 17.6663 23.3337 17.0694 23.3337 16.333V15.1663V13.6663H24.8337H26.0003C26.7367 13.6663 27.3337 13.0694 27.3337 12.333V1.66634C27.3337 0.929962 26.7367 0.333008 26.0003 0.333008H10.0003C9.26395 0.333008 8.66699 0.929962 8.66699 1.66634V2.83301V4.33301H8.00033V4.99967H6.00033V4.33301C5.26395 4.33301 4.66699 4.92996 4.66699 5.66634V8.33301H1.66699C1.11471 8.33301 0.666992 8.78072 0.666992 9.33301V20.6663C0.666992 21.2186 1.11471 21.6663 1.66699 21.6663H18.3337C18.8859 21.6663 19.3337 21.2186 19.3337 20.6663V17.6663ZM6.66699 8.33301H8.66699V7.83301V6.33301H8.16699H7.16699H6.66699V7.83301V8.33301ZM10.667 8.33301V7.83301V6.33301H12.167H19.8337H21.3337V7.83301V10.1663V11.6663H19.8337H19.3337V9.33301C19.3337 8.78072 18.8859 8.33301 18.3337 8.33301H10.667Z"
        fill="currentColor"
        fillOpacity="0.87"
      />
    </svg>
  );
});
