import { AccountApiType } from '../User/User.types';

export enum SignUpFlow {
  INVITATION = 'flow_signup_invitation',
  FREE = 'flow_signup_free',
  PRO = 'flow_signup_pro',
  ENTERPRISE = 'flow_signup_enterprise',
  EXTERNAL_SELECT_PLAN = 'flow_signup_external',
  COMPLETED = 'completed',
}

export enum SignUpFlowPath {
  FREE = 'free',
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
}

export enum SignUpStep {
  verified_email = 'verified_email',
  billing = 'billing',
  use_case = 'use_case',
  about_you = 'about_you',
  about_team = 'about_team',
  choose_feature = 'choose_feature',
  activate_workspace = 'activate_workspace',
  add_content = 'add_content',
  invite_users = 'invite_users',
  install_extension = 'install_extension',
  completed = 'completed',
  select_plan = 'select_plan',
}

export interface RegistrationFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  repeatPassword: string;
  acceptTerms: boolean;
  newsSubscription: boolean;
  captchaToken?: string;
}

export interface CreateAccountInputType extends RegistrationFormValues {
  flow: SignUpFlow;
}

export interface ExternalRegistrationFormValues {
  email: string;
  flow: string;
  newsSubscription: boolean;
  acceptTerms?: boolean;
}

export interface RequestResetPasswordFormValues {
  email: string;
}

export interface ResetPasswordFormValues {
  newPassword: string;
  repeatPassword: string;
}

export interface ResetPasswordInput extends ResetPasswordFormValues {
  id: string;
  passwordHash: string;
  email: string;
  pubKey?: string;
  privKeyEncrypted?: string;
}

export interface AccountLinkRequestApiType {
  id: string;
  _id: string;
  validUntil: string;
  linked: boolean;
  mainAccount: AccountApiType;
}

export interface VerifyEmailChangeFormValues {
  currentPassword: string;
}

export interface VerifyEmailChangeInputType
  extends VerifyEmailChangeFormValues {
  id: string;
  activationHash: string;
  newPassword: string;
  repeatPassword: string;
}

export enum UseCaseType {
  business = 'business',
  personal = 'personal',
}

export interface UseCaseFormValues {
  useCase: UseCaseType;
}

export interface AboutYouFormValues {
  jobTitle: string;
  industry: string;
}

export interface IndustryApiType {
  id: string;
  label: string;
}

export enum TeamSizeType {
  just_you = 'just_you',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface AboutTeamFormValues {
  teamSize: TeamSizeType;
}

export enum ChooseFeatureType {
  organize_and_search = 'organize_and_search',
  communicate = 'communicate',
}

export interface ChooseFeatureFormValues {
  organizeAndSearch: boolean;
  communicate: boolean;
}
