import { ApolloClient, gql } from '@apollo/client';
import {
  GET_NOTIFICATIONS,
  GetNotificationsResponse,
  GetNotificationsVariables,
} from '../Notifications.queries';
import {
  NotificationApiType,
  NotificationMessageApiType,
} from '../Notifications.types';
import {
  GRAPHQL_TYPENAME_NOTIFICATION,
  GRAPHQL_TYPENAME_NOTIFICATION_EDGE,
} from '../Notifications.constants';
import { updateCursor } from '../../../shared/api/api.utils';
import { existsInNestedArray } from '../../../shared/utils/list.utils';

export const addNotificationToNotificationsCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetNotificationsVariables,
  notification: NotificationApiType,
) => {
  try {
    const notificationsCache = apolloClient.readQuery<
      GetNotificationsResponse,
      GetNotificationsVariables
    >({
      query: GET_NOTIFICATIONS,
      variables: queryVariables,
    });
    if (!notificationsCache) {
      return;
    }

    const alreadyExists = existsInNestedArray(
      notificationsCache,
      'notifications.edges',
      {
        node: { id: notification.id },
      },
    );

    if (alreadyExists) {
      return;
    }

    apolloClient.writeQuery<
      GetNotificationsResponse,
      GetNotificationsVariables
    >({
      query: GET_NOTIFICATIONS,
      variables: queryVariables,
      data: {
        ...notificationsCache,
        notifications: {
          ...notificationsCache.notifications,
          edges: [
            {
              __typename: GRAPHQL_TYPENAME_NOTIFICATION_EDGE,
              node: notification,
            },
            ...notificationsCache.notifications.edges,
          ],
          pageInfo: {
            ...notificationsCache.notifications.pageInfo,
            endCursor: updateCursor(
              notificationsCache.notifications.pageInfo.endCursor,
              prevValue => prevValue + 1,
            ),
          },
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};

const NotificationFragment = gql`
  fragment NotificationFragment on Notification {
    status
    message {
      id
      fields
    }
  }
`;

type NotificationFragmentType = Pick<NotificationApiType, 'status'> & {
  message: Pick<NotificationMessageApiType, 'id' | 'fields'>;
};

export const updateNotificationInCache = (
  proxy: {
    readFragment: ApolloClient<any>['readFragment'];
    writeFragment: ApolloClient<any>['writeFragment'];
  },
  notificationId: string,
  newData: NotificationFragmentType,
) => {
  try {
    const fragmentCache = proxy.readFragment<
      NotificationFragmentType,
      undefined
    >({
      id: `${GRAPHQL_TYPENAME_NOTIFICATION}:${notificationId}`,
      fragment: NotificationFragment,
    });

    if (!fragmentCache) {
      return;
    }

    const updatedCache = {
      ...fragmentCache,
      ...newData,
    };

    proxy.writeFragment<NotificationFragmentType, undefined>({
      id: `${GRAPHQL_TYPENAME_NOTIFICATION}:${notificationId}`,
      fragment: NotificationFragment,
      data: updatedCache,
    });
  } catch {}
};

export const removeNotificationFromNotificationsCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetNotificationsVariables,
  notificationId: string,
) => {
  try {
    const notificationsCache = apolloClient.readQuery<
      GetNotificationsResponse,
      GetNotificationsVariables
    >({
      query: GET_NOTIFICATIONS,
      variables: queryVariables,
    });
    if (!notificationsCache) {
      return;
    }

    apolloClient.writeQuery<
      GetNotificationsResponse,
      GetNotificationsVariables
    >({
      query: GET_NOTIFICATIONS,
      variables: queryVariables,
      data: {
        ...notificationsCache,
        notifications: {
          ...notificationsCache.notifications,
          edges: notificationsCache.notifications.edges.filter(
            ({ node }) => node.id !== notificationId,
          ),
          pageInfo: {
            ...notificationsCache.notifications.pageInfo,
            endCursor: updateCursor(
              notificationsCache.notifications.pageInfo.endCursor,
              prevValue => prevValue - 1,
            ),
          },
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};
