import { mercureDesktopEventToDesktopApiType } from '../../../../../Desktop/data/Desktop/utils/DesktopMercure.utils';
import { StreamDesktopEvent } from '../../../GeneralMercure.types';
import { getDesktopByIdFromIDDB } from '../../../../../Desktop/data/Desktop/dataSources/DesktopIDDB.dataSource';
import { updateDesktopInIDDB } from '../../../../../Desktop/data/Desktop/operations/DesktopIDDB.operations';

export const desktopRequestUpdate = async (event: StreamDesktopEvent) => {
  event.id = event['@iri'];

  const cachedDesktop = await getDesktopByIdFromIDDB(event.id);
  const desktopFromEvent = mercureDesktopEventToDesktopApiType(event);

  if (cachedDesktop) {
    updateDesktopInIDDB(event.id, desktop => ({
      ...desktop,
      sourceData: {
        ...(desktop.sourceData || {}),
        ...desktopFromEvent,
      },
    }));
  }
};
