import { StreamDesktopEventAction } from '../../../GeneralMercure.types';
import { deleteConversationIDDB } from '../../../../../Chat/Data/DataSource/ChatConversation/ChatConversationsIndexedDBSource';
import { updateDesktopInIDDB } from '../../../../../Desktop/data/Desktop/operations/DesktopIDDB.operations';

export const removedFromDesktop = (
  event: StreamDesktopEventAction,
  refetchPermissions: () => void,
  removeDesktopByIdInIDDB: (id: string) => Promise<void>,
) => {
  const payload = event['@payload'];

  if (!payload) {
    return;
  }

  if (payload.shareable) {
    removeDesktopByIdInIDDB(payload.id);
  } else {
    updateDesktopInIDDB(payload.id, desktopRow => ({
      ...desktopRow,
      accessible: 'false', // We use string here since boolean cannot be used as an index for IndexedDB
    }));
  }

  if (payload.chatConversationIri) {
    deleteConversationIDDB(payload.chatConversationIri);
  }

  refetchPermissions();
};
