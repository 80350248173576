import {
  AccountApiType,
  AccountOrigin,
  AccountWithCountsApiType,
  AccountWithRelatedApiType,
  AccountWorkspaceCacheApiType,
} from './User.types';
import { WorkspaceAccountGroupIdentity } from './User.constants';
import { WorkspaceApiType } from '../Workspace/Workspace.types';
import { useIntl } from 'react-intl';
import { UserTranslation } from './i18n';
import { useCallback } from 'react';
import { extractNodes } from '../../shared/api/api.utils';
import { KeysetApiType } from '../Encryption/Encryption.types';

export const isBotAccount = (account: Pick<AccountApiType, 'origin'>) =>
  account.origin === AccountOrigin.bot;

export const getAccountName = (
  account: Partial<
    Pick<AccountApiType, 'firstName' | 'lastName' | 'email' | 'origin'>
  >,
  onlyFirstNameOrLastName: boolean = false,
) => {
  const { firstName, lastName, email } = account;
  if (isBotAccount(account)) {
    return firstName;
  }
  if (firstName && lastName && !onlyFirstNameOrLastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName || lastName) {
    return firstName ? firstName : lastName;
  }
  return email;
};

export const getWorkspaceInitials = (workspace: WorkspaceApiType) => {
  if (workspace?.name) {
    return workspace.name.charAt(0);
  }
  return '';
};

export const getAccountInitials = (
  account: Partial<Pick<AccountApiType, 'firstName' | 'lastName' | 'email'>>,
) => {
  const { firstName, lastName, email } = account;
  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  } else if (firstName || lastName) {
    return firstName ? firstName.charAt(0) : lastName!.charAt(0);
  }
  return email ? email.charAt(0) : '';
};

export const getAccountWorkspacesNode = (
  account: AccountWithRelatedApiType,
) => {
  const workspaces = account?.workspaces
    ? account.workspaces.edges.map(({ node }) => node)
    : [];
  return workspaces.length ? workspaces[0] : null;
};

export const getAccountGroupIdentifier = (
  account: AccountWithRelatedApiType,
) => {
  const workspaceNode = getAccountWorkspacesNode(account);
  return workspaceNode && workspaceNode.groupIdentifier;
};

export const getAccountGroupLabel = (account: AccountWithRelatedApiType) => {
  const workspaceNode = getAccountWorkspacesNode(account);
  return workspaceNode && workspaceNode.groupLabel;
};

export const isAdmin = (account: AccountWithRelatedApiType | null) => {
  if (!account) {
    return false;
  }

  const identifier = getAccountGroupIdentifier(account);
  return (
    identifier === WorkspaceAccountGroupIdentity.ADMIN ||
    identifier === WorkspaceAccountGroupIdentity.OWNER
  );
};

export const isOwner = (account: AccountWithRelatedApiType | null) => {
  if (!account) {
    return false;
  }

  const identifier = getAccountGroupIdentifier(account);
  return identifier === WorkspaceAccountGroupIdentity.OWNER;
};

export const isGuest = (account: AccountWithRelatedApiType | null) => {
  if (!account) {
    return false;
  }

  const identifier = getAccountGroupIdentifier(account);
  return identifier === WorkspaceAccountGroupIdentity.GUEST;
};

export const workspaceUserIsSuspended = (
  account: AccountWithRelatedApiType | null,
): boolean => {
  if (!account) {
    return true;
  }

  return !account.workspaces?.edges[0]?.node.active;
};

export const isAccountGuest = (
  account: Pick<AccountWithCountsApiType, 'workspaceCache' | 'id' | 'email'>,
) => {
  if (!account?.workspaceCache) {
    return false;
  }

  return (
    account.workspaceCache?.[account.id]?.groupIdentifier ===
    WorkspaceAccountGroupIdentity.GUEST
  );
};

export const isAccountActive = (
  account?: Pick<AccountWithCountsApiType, 'id' | 'workspaceCache'>,
  workspaceId?: string,
) => {
  if (
    !account ||
    !workspaceId ||
    !account?.workspaceCache ||
    !account.workspaceCache?.[account.id]
  ) {
    return false;
  }

  return account.workspaceCache?.[account.id].active;
};

export const isCurrentAccountGuest = (
  account: Pick<AccountApiType, 'workspaceInfo'> | null,
  workspaceShortId: string,
) => {
  if (!account) {
    return false;
  }

  return account.workspaceInfo?.some(
    workspaceInfo =>
      workspaceInfo.id === workspaceShortId &&
      workspaceInfo.role === WorkspaceAccountGroupIdentity.GUEST,
  );
};

export const isAccountAdmin = (account: AccountWithCountsApiType | null) => {
  if (!account?.workspaceCache) {
    return false;
  }

  return [
    WorkspaceAccountGroupIdentity.OWNER,
    WorkspaceAccountGroupIdentity.ADMIN,
  ].includes(
    account.workspaceCache?.[account.id]
      ?.groupIdentifier as WorkspaceAccountGroupIdentity,
  );
};

export const isCurrentAccountMember = (
  account: Pick<AccountApiType, 'workspaceInfo'> | null,
  workspaceShortId: string,
) => {
  if (!account) {
    return false;
  }

  return account.workspaceInfo?.some(
    workspaceInfo =>
      workspaceInfo.id === workspaceShortId &&
      workspaceInfo.role === WorkspaceAccountGroupIdentity.MEMBER,
  );
};

export const isCurrentAccountHasPassword = (
  account?: AccountApiType | null,
) => {
  return !!account?.hasSetPassword;
};

export const makeAccountIdentityIri = (id: string) =>
  `/account-identities/${id}`;

export const useGetUserRoleTitle = () => {
  const intl = useIntl();

  return useCallback(
    (account: Pick<AccountWithCountsApiType, 'workspaceCache' | 'id'>) => {
      const accountWorkspaceCache: {
        [key: string]: AccountWorkspaceCacheApiType;
      } = account?.workspaceCache;

      if (
        !account ||
        !accountWorkspaceCache ||
        !accountWorkspaceCache[account.id]
      ) {
        return '';
      }

      if (!accountWorkspaceCache[account.id]?.groupIdentifier) {
        return intl.formatMessage({
          id: UserTranslation.workspace_member,
        });
      }

      return intl.formatMessage({
        id: (UserTranslation as { [key: string]: string })[
          accountWorkspaceCache[account.id]?.groupIdentifier
        ],
      });
    },
    [intl],
  );
};

export const getAccountEntityKeysets = (
  account: AccountApiType | null = null,
): KeysetApiType[] => {
  if (account?.keysets && 'edges' in account.keysets) {
    return extractNodes(account.keysets);
  }

  if (account && Array.isArray(account?.keysets)) {
    return account.keysets;
  }

  return [];
};
