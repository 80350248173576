/**
 * This file provides functions that will modify the chat conversation database table.
 * Any functionality that somehow modifies the table shall be found here.
 */

import { database } from '../../../../Database';
import { ChatConversationsTableType } from '../../../../Database/ChatConversationsTable/ChatConversationsTable';
import { captureException } from '@sentry/react';

/**
 * Get a chat conversation/conversations
 */
export const getConversationIDDB = async (conversationId: string) => {
  try {
    const conversation = await database.chatConversations.get(conversationId);
    return conversation;
  } catch (error) {
    captureException(error);
  }
};
export const getConversationsFromIDDB = async (workspaceId: string) => {
  try {
    return await database.chatConversations
      .where('workspaceId')
      .equals(workspaceId)
      .toArray();
  } catch (error) {
    captureException(error);
  }
};

/**
 * Add or update existing conversation to the database
 * When adding multiple conversations use "bulkPut" instead.
 */
export const updateConversationIDDB = async (
  conversation: ChatConversationsTableType,
) => {
  try {
    database.chatConversations.update(conversation.id, conversation);
  } catch (error) {
    captureException(error);
  }
};

/**
 * Add or replace existing chat conversations array to the database.
 * When adding single chat message use "putOne" instead.
 */
export const createOrUpdateMultipleConversationsIDDB = async (
  conversations: ChatConversationsTableType[],
) => {
  try {
    await database.chatConversations.bulkPut(conversations);
  } catch (error) {
    captureException(error);
  }
};

/**
 * Update a chat conversation in the database
 */
export const createConversationIDDB = async (
  conversation: ChatConversationsTableType,
) => {
  try {
    if (!conversation) {
      return;
    }
    database.chatConversations.put(conversation);
  } catch (error) {
    captureException(error);
  }
};

/**
 * Delete a chat conversation from the database
 */
export const deleteConversationIDDB = async (conversationId: string) => {
  try {
    return await database.chatConversations.delete(conversationId);
  } catch (error) {
    captureException(error);
  }
};

export const deleteConversationsFromIDDB = async (
  conversationIds: string[],
) => {
  try {
    return await database.chatConversations.bulkDelete(conversationIds);
  } catch (error) {
    captureException(error);
  }
};
