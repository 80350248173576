export enum AccountTranslation {
  // Plan intervals
  statusOnline = 'availability_statusOnline',
  statusAway = 'availability_statusAway',
  statusBusy = 'availability_statusBusy',
  statusOffline = 'availability_statusOffline',
  statusInvisible = 'availability_statusInvisible',

  statusForcedOnline = 'availability_statusForcedOnline',

  deletedAccountFirstName = 'availability_deletedAccountFirstName',
  deletedAccountLastName = 'availability_deletedAccountLastName',
  deletedAccountEmail = 'availability_deletedAccountEmail',
}

export type AccountLocale = {
  [key in AccountTranslation]: string;
};
