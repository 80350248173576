import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AccountsProviderContext } from './providers/AccountsProvider/AccountsProvider.context';
import { AccountWithCountsApiType } from '../User/User.types';
import { AccountTranslation } from './i18n';
import { OpenType } from '../GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';
import { ThemeType } from '../Theme';
import { MessagesNotificationsType } from '../GeneralSettings/NotificationsSettingsAdmin/CustomNotificationsSettingsForm/CustomNotifications.types';
import { SendNotificationsType } from '../GeneralSettings/NotificationsSettingsAdmin/NotificationsSettingsForm';
import { DELETED_ACCOUNT_ID } from './Account.constants';

export const useAccountsContext = () => useContext(AccountsProviderContext);

export const useDeletedAccount = (): AccountWithCountsApiType => {
  const { formatMessage } = useIntl();
  return useMemo(() => {
    return {
      id: `/accounts/${DELETED_ACCOUNT_ID}`,
      _id: DELETED_ACCOUNT_ID,
      identityId: '/account-identities/deleted-account-identity',
      firstName: formatMessage({
        id: AccountTranslation.deletedAccountFirstName,
      }),
      lastName: formatMessage({
        id: AccountTranslation.deletedAccountLastName,
      }),
      email: formatMessage({ id: AccountTranslation.deletedAccountEmail }),
      phoneNumber: '',
      image: null,
      verifiedEmail: false,
      createdWorkspace: false,
      identity: {
        id: `/identity-settings/${DELETED_ACCOUNT_ID}`,
        openApp: OpenType.newTab,
        openLink: OpenType.newTab,
        theme: ThemeType.light,
        mfaRequired: false,
        accountIdentitySetting: {
          id: `/account-identity-settings/${DELETED_ACCOUNT_ID}`,
          soundsEnable: false,
          mobileNotifications: SendNotificationsType.inactive,
          messagesNotifications: MessagesNotificationsType.disable,
          generalBellNotificationsEnable: false,
          billingNotificationsEnable: false,
          upcomingMeetingNotificationsEnable: false,
        },
      },
      isMfaValid: false,
      unreadNotifications: [],
      unreadMentions: [],
      unreadChatMessages: [],
      onCall: false,
      hasSetPassword: false,
      filterIsBots: false,
      workspaceCache: {},
      recentSearches: {},
    };
  }, [formatMessage]);
};

export const useAccountByIri = (accountIri: string) => {
  const { accountsWithAvailability } = useAccountsContext();
  const deletedAccount = useDeletedAccount();
  return accountsWithAvailability[accountIri] ?? deletedAccount;
};
