import { ChatMessageInternalType } from '../../Chat/Chat.types';
import { DatabaseTablesType } from '../Database.types';

export const CHAT_MESSAGES_TABLE_COLUMNS =
  '++id, conversationId, parentId, createdAt, messageData';

export interface ChatMessagesTableType extends DatabaseTablesType {
  parentId?: string | null;
  conversationId: string;
  data: ChatMessageInternalType;
  canLoadBefore?: boolean;
  canLoadAfter?: boolean;
  isFirstUnread: boolean;
}

/**
 * Converts a ChatMessageInternalType to ChatMessagesTableType
 * @param chatMessage
 * @returns ChatMessagesTableType
 */
export const ChatMessageToChatMessageTableRow = (
  chatMessage: ChatMessageInternalType,
  canLoadBefore?: boolean,
  canLoadAfter?: boolean,
): ChatMessagesTableType => {
  return {
    id: chatMessage.id,
    parentId: chatMessage.parentId || null,
    createdAt: chatMessage.createdAt,
    conversationId: chatMessage.conversationId,
    data: chatMessage,
    isFirstUnread: chatMessage.isFirstUnread,
    canLoadBefore,
    canLoadAfter,
  };
};
