import { ApolloClient } from '@apollo/client';
import {
  GET_MENTIONS,
  GET_THREADS,
  GetMentionsResponse,
  GetMentionsVariables,
  GetThreadsResponse,
  GetThreadsVariables,
} from '../Inbox.queries';
import { NotificationApiType } from '../../Notifications/Notifications.types';
import { GRAPHQL_TYPENAME_NOTIFICATION_EDGE } from '../../Notifications/Notifications.constants';
import { MentionsApiType, ThreadNotificationApiType } from '../Inbox.types';
import { updateCursor } from '../../../shared/api/api.utils';
import { existsInNestedArray } from '../../../shared/utils/list.utils';

export const addMentionToMentionsCache = (
  apolloClient: Pick<ApolloClient<any>, 'readQuery' | 'writeQuery'>,
  queryVariables: GetMentionsVariables,
  notification: NotificationApiType,
) => {
  try {
    const inboxCache = apolloClient.readQuery<
      GetMentionsResponse,
      GetMentionsVariables
    >({
      query: GET_MENTIONS,
      variables: queryVariables,
    });
    if (!inboxCache) {
      return;
    }

    apolloClient.writeQuery<GetMentionsResponse, GetMentionsVariables>({
      query: GET_MENTIONS,
      variables: queryVariables,
      data: {
        ...inboxCache,
        notifications: {
          ...inboxCache.notifications,
          edges: [
            {
              __typename: GRAPHQL_TYPENAME_NOTIFICATION_EDGE,
              node: notification as MentionsApiType,
            },
            ...inboxCache.notifications.edges,
          ],
          pageInfo: {
            ...inboxCache.notifications.pageInfo,
            endCursor: updateCursor(
              inboxCache.notifications.pageInfo.endCursor,
              prevValue => prevValue + 1,
            ),
          },
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};

export const addThreadToThreadsCache = (
  apolloClient: Pick<ApolloClient<any>, 'readQuery' | 'writeQuery'>,
  queryVariables: GetThreadsVariables,
  notification: NotificationApiType,
) => {
  try {
    const threadsCache = apolloClient.readQuery<
      GetThreadsResponse,
      GetThreadsVariables
    >({
      query: GET_THREADS,
      variables: queryVariables,
    });
    if (!threadsCache) {
      return;
    }

    const alreadyExists = existsInNestedArray(
      threadsCache,
      'notifications.edges',
      {
        node: { id: notification.id },
      },
    );

    if (alreadyExists) {
      return;
    }

    apolloClient.writeQuery<GetThreadsResponse, GetThreadsVariables>({
      query: GET_THREADS,
      variables: queryVariables,
      data: {
        ...threadsCache,
        notifications: {
          ...threadsCache.notifications,
          edges: [
            {
              __typename: GRAPHQL_TYPENAME_NOTIFICATION_EDGE,
              node: notification as ThreadNotificationApiType,
            },
            ...threadsCache.notifications.edges,
          ],
          pageInfo: {
            ...threadsCache.notifications.pageInfo,
            endCursor: updateCursor(
              threadsCache.notifications.pageInfo.endCursor,
              prevValue => prevValue + 1,
            ),
          },
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};

export const removeMentionFromMentionsCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetMentionsVariables,
  mentionNotificationId: string,
) => {
  try {
    const mentionsCache = apolloClient.readQuery<
      GetMentionsResponse,
      GetMentionsVariables
    >({
      query: GET_MENTIONS,
      variables: queryVariables,
    });
    if (!mentionsCache) {
      return;
    }

    apolloClient.writeQuery<GetMentionsResponse, GetMentionsVariables>({
      query: GET_MENTIONS,
      variables: queryVariables,
      data: {
        ...mentionsCache,
        notifications: {
          ...mentionsCache.notifications,
          edges: mentionsCache.notifications.edges.filter(
            ({ node }) => node.id !== mentionNotificationId,
          ),
          pageInfo: {
            ...mentionsCache.notifications.pageInfo,
            endCursor: updateCursor(
              mentionsCache.notifications.pageInfo.endCursor,
              prevValue => prevValue - 1,
            ),
          },
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};

export const removeThreadFromThreadsCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetThreadsVariables,
  threadNotificationId: string,
) => {
  try {
    const threadsCache = apolloClient.readQuery<
      GetThreadsResponse,
      GetThreadsVariables
    >({
      query: GET_THREADS,
      variables: queryVariables,
    });
    if (!threadsCache) {
      return;
    }

    apolloClient.writeQuery<GetThreadsResponse, GetThreadsVariables>({
      query: GET_THREADS,
      variables: queryVariables,
      data: {
        ...threadsCache,
        notifications: {
          ...threadsCache.notifications,
          edges: threadsCache.notifications.edges.filter(
            ({ node }) => node.id !== threadNotificationId,
          ),
          pageInfo: {
            ...threadsCache.notifications.pageInfo,
            endCursor: updateCursor(
              threadsCache.notifications.pageInfo.endCursor,
              prevValue => prevValue - 1,
            ),
          },
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};
