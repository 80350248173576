import React, { FC, useCallback } from 'react';
import {
  AccountApiType,
  AccountApiTypeWithoutIdentity,
  AccountWithCountsApiType,
  AccountWithRelatedApiType,
} from '../User.types';
import {
  AvatarMode,
  StyledAvatar,
  StyledImg,
  StyledInitials,
} from './Avatar.styled';
import { getAccountInitials, getAccountName } from '../User.utils';
import { getGlideImageSrcSet } from '../../../shared/utils/url.utils';
import { AvatarForm } from './Avatar.types';
interface AvatarProps {
  account:
    | AccountApiType
    | AccountWithRelatedApiType
    | AccountWithCountsApiType
    | AccountApiTypeWithoutIdentity;
  size?: number;
  mode?: AvatarMode;
  variant?: AvatarForm;
}

export const Avatar: FC<AvatarProps> = ({
  account,
  size = 40,
  mode,
  variant = AvatarForm.REGULAR,
}) => {
  const name = useCallback(
    () => (account ? getAccountName(account) : ''),
    [account],
  );
  const initials = useCallback(
    () => (account ? getAccountInitials(account) : ''),
    [account],
  );

  if (!account) {
    return null;
  }

  return (
    <StyledAvatar
      size={size}
      mode={mode}
      title={name()}
      data-testid="avatar"
      variant={variant}>
      {account.image ? (
        <StyledImg
          src={account.image.contentUrl}
          srcSet={getGlideImageSrcSet(account.image.contentUrl, {
            w: size,
            h: size,
            fit: 'crop-center',
          })}
          size={size}
          initials={initials()}
          alt={name()}
          data-testid="avatar-img"
        />
      ) : (
        <StyledInitials size={size} data-testid="initials">
          {initials()}
        </StyledInitials>
      )}
    </StyledAvatar>
  );
};
