import { ApiConnection } from '../../shared/api/api.types';
import { GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP } from './Encryption.constants';
import type { AppStoreAppApiType } from '../AppStore/AppStore.types';
import type { AccountApiType } from '../User/User.types';
import type { ImageAssetApiType } from '../Asset/Asset.types';
import type { DesktopApiType } from '../Desktop/data/Desktop/types/Desktop.types';

/*
 * KEYSET
 * */

export interface KeysetApiType {
  id: string;
  _id: string;
  privKeyEncrypted: string;
  pubKey: string;
  createdAt: string;
  __typename?: string;
}

export type KeysetInputType = Pick<
  KeysetApiType,
  'privKeyEncrypted' | 'pubKey'
>;
export type KeysetFirstTimeInputType = Pick<
  KeysetApiType,
  'privKeyEncrypted' | 'pubKey'
> & {
  account: string;
};

/*
 * VAULT
 * */

export interface VaultApiType {
  __typename?: string;
  id: string;
  vaultAccesses: ApiConnection<VaultAccessApiType>;
  vaultItems?: ApiConnection<VaultItemApiType>;
  createdAt: string;
  modifiedAt: string;
}

/*
 * VAULT ITEM
 * */

export enum VaultItemType {
  app = 'app',
  manual = 'manual',
}

export interface VaultItemApiType {
  id: string;
  login: string;
  password: string;
  description: string;
  url?: string;
  image?: ImageAssetApiType | null;
  type: VaultItemType;
  appVaultItemConfigs: ApiConnection<AppVaultItemConfigApiType>;
}

export interface ImportedVaultItemApiType
  extends Omit<
    VaultItemApiType,
    'appVaultItemConfigs' | 'description' | 'id' | 'type'
  > {}

export interface VaultItemCreateInputType {
  login: string;
  password: string;
  url?: string;
  image?: string;
  app?: string;
  description?: string;
  workspace: string;
}

export interface VaultItemEditInputType {
  id: string;
  login: string;
  password: string;
  url?: string;
  description?: string;
}

export interface VaultItemFormValues {
  login: string;
  password: string;
  url?: string;
  image?: string;
}

export interface ShareVaultFormValues {
  shareWith: { value: string; label: string }[];
}

/*
 * VAULT ITEM CONFIG
 * */

export type AppStoreAppForVaultApiType = Pick<
  AppStoreAppApiType,
  | 'id'
  | 'name'
  | 'fullName'
  | 'logo'
  | 'uri'
  | 'custom'
  | 'hidden'
  | 'groupApp'
  | 'openUri'
  | 'loginUri'
  | 'loginUriPattern'
>;

export interface AppVaultItemConfigApiType {
  id: string;
  app: AppStoreAppForVaultApiType;
  appVaultItemConfigDesktops: ApiConnection<AppVaultItemDesktopConfigApiType>;
  __typename?: string;
}

export interface AppVaultItemDesktopConfigApiType {
  id: string;
  desktop: Pick<DesktopApiType, 'id' | '__typename'>;
  __typename?: typeof GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP;
}

/*
 * VAULT ACCESS
 * */

export interface VaultAccessApiType {
  id: string;
  keyset: Pick<KeysetApiType, 'id' | '_id' | '__typename'>;
  vaultKeyEncrypted: string;
  isImplicitlyShared?: boolean;
  shared: boolean;
  parent: {
    id: string;
    keyset?: Pick<KeysetApiType, 'id' | '_id' | '__typename'>;
    vaultKeyEncrypted?: string;
  } | null;
  vault?: {
    id: string;
  };
  createdAt: string;
  __typename?: string;
}

export type VaultAccessInputType = Pick<
  VaultAccessApiType,
  'vaultKeyEncrypted' | 'isImplicitlyShared'
> & {
  vault?: string;
  keyset: string;
  parent?: string;
  workspace: string;
};

/*
 * Password Recovery Request
 * */

// export type PasswordRecoveryRequestId = `/password-recovery/${string}`;
export type PasswordRecoveryRequestId = string;

export enum PasswordRecoveryRequestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

interface PasswordRecoveryRequestedBy
  extends Pick<AccountApiType, 'id' | 'email' | 'firstName' | 'lastName'> {
  keysets: ApiConnection<KeysetApiType>;
}
export interface PasswordRecoveryRequest {
  id: PasswordRecoveryRequestId;
  requestedBy: PasswordRecoveryRequestedBy;
  status: PasswordRecoveryRequestStatus;
}

export enum PasswordStrength {
  STRONG = 'STRONG',
  MEDIUM = 'MEDIUM',
  WEAK = 'WEAK',
}

/*
 * Provider maps
 * */

// workspaceId -> vaults
export type WorkspaceVaultsMap = Record<string, Record<string, VaultApiType>>;

// workspaceId -> appId -> vault
export type WorkspaceAppVaultsMap = Record<
  string,
  Record<string, Record<string, VaultApiType>>
>;

// workspaceId -> appId -> desktopId -> vaultId
export type WorkspaceAppDesktopVaultsMap = Record<
  string,
  Record<string, Record<string, string>>
>;

// workspaceId -> groupAppId -> vaultId
export type WorkspaceAppGroupVaultsMap = Record<
  string,
  Record<string, Record<string, boolean>>
>;

// workspaceId -> vaultId -> decrypted login
export type WorkspaceVaultLoginsMap = Record<string, Record<string, string>>;
