import { AccountApiType } from '../../../../User/User.types';
import { AccountsTableType } from '../types/AccountIDDB.types';

export const getRedundantCacheAccounts = (
  cacheAccounts: AccountsTableType[] = [],
  apiAccounts: AccountApiType[] = [],
) => {
  const apiAccountsMap = apiAccounts.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {} as Record<string, AccountApiType>,
  );

  return cacheAccounts.filter(account => !apiAccountsMap[account.id]);
};
