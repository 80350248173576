import { ThemeType } from '../../Theme';
import { LinkThemesType } from './Link.theme.types';

export const LinkTheme: LinkThemesType = {
  [ThemeType.light]: {
    colors: {
      LinkDescriptionBackground: '#454F5B',
      LinkHoverBackground: 'rgba(2, 17, 33, 0.7)',
      LinkHoverIcon: '#fff',
      LinkHoverButtonBackground: 'rgba(255, 255, 255, 0.12)',
      LinkHoverButtonText: '#fff',
    },
  },
  [ThemeType.dark]: {
    colors: {
      LinkDescriptionBackground: '#454F5B',
      LinkHoverBackground: 'rgba(2, 17, 33, 0.7)',
      LinkHoverIcon: '#fff',
      LinkHoverButtonBackground: 'rgba(255, 255, 255, 0.12)',
      LinkHoverButtonText: '#fff',
    },
  },
};
