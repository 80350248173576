import { useCallback, useMemo } from 'react';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';
import type { DesktopApiType } from './types/Desktop.types';
import { useDesktopsActions } from './Desktop.actions';
import type {
  DesktopRepositoryProps,
  DesktopsRepositoryProps,
  DesktopsRepositoryResp,
} from './DesktopRepository.types';
import { useMultipleEntityRepository } from '../../../../shared/hooks/repository/multipleEntityRepository.hooks';
import { useSingleEntityRepository } from '../../../../shared/hooks/repository/singleEntityRepository.hooks';
import {
  getDesktopByIdFromIDDB,
  getWorkspaceDesktopsFromIDDB,
} from './dataSources/DesktopIDDB.dataSource';
import { getRedundantCacheDesktops } from './utils/DesktopRepository.utils';
import {
  bulkDeleteDesktopsFromIDDB,
  putDesktopToIDDB,
} from './operations/DesktopIDDB.operations';
import {
  getAccessibleDesktopsApi,
  getDesktopApi,
  getWorkspaceInternalDesktopsApi,
} from './dataSources/DesktopRESTApi.dataSources';

export const useDesktopsRepository = ({
  fetchPolicy = 'cache-and-network',
  ...args
}: DesktopsRepositoryProps): DesktopsRepositoryResp => {
  const { workspace } = useCurrentWorkspace();
  const { bulkPutDesktopsToIDDB } = useDesktopsActions();

  const fetchDesktops = useCallback(async () => {
    try {
      const desktopsFromApiResponse = await (args.variables.filterOnlyAccessible
        ? getAccessibleDesktopsApi(workspace.id)
        : getWorkspaceInternalDesktopsApi(workspace.id));
      const desktopsFromApi = desktopsFromApiResponse?.data;

      if (desktopsFromApiResponse?.ok && desktopsFromApi) {
        const cacheDesktopRows = await getWorkspaceDesktopsFromIDDB(
          workspace.id,
          args.variables.shareable,
          args.variables.filterOnlyAccessible,
        );

        const redundantCacheDesktops = getRedundantCacheDesktops(
          cacheDesktopRows,
          desktopsFromApi,
        );

        await bulkPutDesktopsToIDDB(desktopsFromApi);
        await bulkDeleteDesktopsFromIDDB(
          redundantCacheDesktops.map(desktop => desktop.id),
        );
      }

      return desktopsFromApi;
    } catch (error) {
      console.error(error);
    }
  }, [args, bulkPutDesktopsToIDDB, workspace.id]);

  const { entities: desktops, loading } =
    useMultipleEntityRepository<DesktopApiType>({
      fetchFunction: fetchDesktops,
      iddbQuerier: () =>
        getWorkspaceDesktopsFromIDDB(
          workspace.id,
          args.variables.shareable,
          args.variables.filterOnlyAccessible,
        ),
      dependencies: [args.variables, workspace.id],
      fetchPolicy,
    });

  return useMemo(
    () => ({
      desktops,
      loading,
      refetchDesktops: fetchDesktops,
    }),
    [desktops, loading, fetchDesktops],
  );
};

export const useDesktopRepository = ({
  fetchPolicy = 'cache-and-network',
  desktopId,
}: DesktopRepositoryProps) => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();

  const fetchDesktop = useCallback(async () => {
    try {
      const response = await getDesktopApi(desktopId);
      const desktopFromAPI = response?.data;

      if (desktopFromAPI && response?.ok) {
        putDesktopToIDDB(
          workspace.id,
          desktopFromAPI,
          desktopFromAPI.accountIds.includes(account.id),
        );
      }
      return desktopFromAPI;
    } catch (error) {
      console.error(error);
    }
  }, [desktopId, workspace.id, account.id]);

  const { entity: desktop, loading } =
    useSingleEntityRepository<DesktopApiType | null>({
      fetchFunction: fetchDesktop,
      iddbQuerier: () => getDesktopByIdFromIDDB(desktopId),
      fetchPolicy,
      dependencies: [desktopId],
    });

  return {
    desktop,
    loading,
    fetchDesktop,
  };
};
