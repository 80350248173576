import { StreamAccountLogoutEventAction } from '../../..';
import { getCookie } from '../../../../../../shared/utils/storage';
import { SESSION_INSTANCE_ID } from '../../../../../Auth/Auth.constants';

export const closeMercureConnection = (
  event: StreamAccountLogoutEventAction,
) => {
  const currentSessionId = getCookie(SESSION_INSTANCE_ID);
  const sessionId = event['@payload'].sessionId;
  if (currentSessionId === sessionId) {
    window.location.href = '/';
  }
};
