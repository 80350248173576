import {
  captureException as captureExceptionInSentry,
  captureMessage as captureMessageInSentry,
  init,
  reactRouterV6Instrumentation,
  setUser as setUserInSentry,
  User,
} from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  isAndroidNativeWrapperAvailable,
  isIOSNativeWrapperAvailable,
} from '../NativeWrapper';
import { appEnv } from '../../appEnv';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from 'react-router-dom';

export { withProfiler } from '@sentry/react';

export const initErrorReporting = () => {
  const dsn = appEnv.SENTRY_DSN;
  if (!dsn) {
    return;
  }

  const environment = {
    environment: '',
  };
  if (isIOSNativeWrapperAvailable()) {
    environment.environment = 'ios-app';
  } else if (isAndroidNativeWrapperAvailable()) {
    environment.environment = 'android-app';
  } else {
    environment.environment = 'browser';
  }

  if (appEnv.SENTRY_ENV) {
    environment.environment = `${environment.environment}-${appEnv.SENTRY_ENV}`;
  }

  const apiUrl = new URL(appEnv.API_URL);

  init({
    dsn,
    ...environment,
    release: appEnv._VERSION,
    integrations: [
      // @ts-ignore
      new BrowserTracing({
        tracingOrigins: [apiUrl.hostname],
        // @ts-ignore
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 0.1,
    beforeSend(event, hint) {
      const error = hint?.originalException;
      if (
        error &&
        (error as Error).message &&
        (error as Error).message.indexOf(
          'ResizeObserver loop limit exceeded',
        ) !== -1
      ) {
        return null;
      }
      return event;
    },
  });
};

export const setUser = (user: User) => {
  setUserInSentry(user);
};

export const captureMessage = (message: string) => {
  captureMessageInSentry(message);
};

export const captureException = (error: Error, message?: string) => {
  if (message) {
    captureMessage(message);
  }
  captureExceptionInSentry(error);
};
