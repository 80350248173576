import {
  DocumentPreviewLocale,
  DocumentPreviewTranslation,
} from './i18n.types';

export const enDocumentPreviewLocale: DocumentPreviewLocale = {
  [DocumentPreviewTranslation.documentPreviewSomethingWentWrong]:
    'Something went wrong :(',
  [DocumentPreviewTranslation.documentPreviewOpenDocumetDirectly]:
    'Please open the document directly by clicking the button below',
  [DocumentPreviewTranslation.documentPreviewOpenDocumetDirectlyButton]: 'Open',

  [DocumentPreviewTranslation.loadingPreviewTitle]: 'Loading preview',
};
