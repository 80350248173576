import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const SEGMENT_NAVIGATOR_WIDTH = 256;

export const StyledSegmentNavigator = styled.div<{ secondarySegment: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: ${SEGMENT_NAVIGATOR_WIDTH}px;
  max-width: ${SEGMENT_NAVIGATOR_WIDTH}px;
  background-color: ${p => p.theme.colors.Background};
  background-color: ${p =>
    p.secondarySegment ? p.theme.colors.Surface : p.theme.colors.Background};
  border-right: 1px solid ${p => p.theme.colors.SurfaceBorder};
`;

export const SegmentHeader = styled.div`
  height: ${({ theme }) => theme.fixedValues.segmentHeaderHeight};
  min-height: ${({ theme }) => theme.fixedValues.segmentHeaderHeight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.m};
  border-bottom: 1px solid ${({ theme }) => theme.colors.SurfaceBorder};
`;

export const HeaderTitle = styled.span`
  ${({ theme }) => theme.fontTemplates.headerMain};
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
`;

export const HeaderActions = styled.div`
  display: flex;
  grid-gap: ${({ theme }) => theme.spacing.xs};
`;

export const NavigatorContainer = styled.div<{ defaultPadding: boolean }>`
  padding: ${({ theme, defaultPadding }) =>
    defaultPadding && `${theme.spacing.xs}`};
  flex: 1;
  overflow-y: auto;
`;

export const NavigatorActionButton = styled(ButtonWithIcon)<{
  secondaryButton?: boolean;
  iconSize?: number;
  buttonWithText?: boolean;
}>`
  width: 2rem;
  height: 2rem;

  svg {
    width: ${({ iconSize }) => iconSize || '24'}px;
    height: ${({ iconSize }) => iconSize || '24'}px;
    margin: 0;
    color: ${p =>
      p.secondaryButton
        ? p.theme.colors.OnSurfaceMediumEmphasis
        : p.theme.colors.Primary};
  }

  ${({ buttonWithText, theme }) =>
    buttonWithText &&
    css`
      width: 100%;
      justify-content: flex-start;
      height: auto;
      padding: 1rem 1.5rem;
      border-radius: 0;
      border-top: 1px solid ${theme.colors.BorderDefault};
      color: ${theme.colors.OnSurfaceHighEmphasis};
      font-family: ${theme.fonts.Semibold};

      span ~ svg {
        margin-right: 1rem;
      }
    `};

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:active {
    outline: none;
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
  }

  &:disabled {
    svg {
      color: ${({ theme }) => theme.colors.BorderLight};
    }

    &:hover {
      background-color: unset;
    }
  }
`;

export const StyledRedirectToDesktopButtonContainer = styled.div`
  display: flex;
`;

export const ConferenceNavigatorTitleActionsContainer = styled.div`
  display: flex;
  position: relative;
`;
