export enum UserTranslation {
  workspace_guest = 'workspace_guest',
  workspace_member = 'workspace_member',
  workspace_admin = 'workspace_admin',
  workspace_owner = 'workspace_owner',
  workspace_pending = 'workspace_pending',
  workspace_suspended = 'workspace_suspended',

  adminListHeaderTitle = 'user_adminListHeaderTitle',
  adminSingleUserHeaderTitle = 'user_adminSingleUserHeaderTitle',
  adminListInviteUserButton = 'user_adminListInviteUserButton',
  adminListEmptyMessage = 'user_adminListEmptyMessage',
  adminListPendingInvitesHeader = 'user_adminListPendingInvitesHeader',
  adminListPendingEmptyMessage = 'user_adminListPendingEmptyMessage',
  adminListPendingUserExpires = 'user_adminListPendingUserExpires',
  adminListPendingUserExpired = 'user_adminListPendingUserExpired',
  adminAccountInvitationEmail = 'user_adminAccountInvitationEmail',
  adminAccountInvitationCreatedAt = 'user_adminAccountInvitationCreatedAt',
  adminAccountInvitationExpiresAt = 'user_adminAccountInvitationExpiresAt',
  adminAccountInvitationExpiredAt = 'user_adminAccountInvitationExpiredAt',
  adminAccountInvitationSpots = 'user_adminAccountInvitationSpots',
  adminAccountInvitationAssignedRole = 'user_adminAccountInvitationAssignedRole',
  adminAccountInvitationUrlHeader = 'user_adminAccountInvitationUrlHeader',
  adminAccountInvitationResend = 'user_adminAccountInvitationResend',
  adminAccountInvitationResendSuccess = 'user_adminAccountInvitationResendSuccess',
  adminListInvitationHeaderTitle = 'user_adminListInvitationHeaderTitle',
  adminListTeam = 'user_adminListTeam',
  adminListTeams = 'user_adminListTeams',
  adminListDesktop = 'user_adminListDesktop',
  adminListDesktops = 'user_adminListDesktops',
  adminGeneralInfo = 'user_adminGeneralInfo',
  adminGeneralInfoEdit = 'user_adminGeneralInfoEdit',
  adminDesktopsHeader = 'user_adminDesktopsHeader',
  adminManageDesktopsButton = 'user_adminManageDesktopsButton',
  adminDesktopsEmptyMessage = 'user_adminDesktopsEmptyMessage',
  adminTeamsHeader = 'user_adminTeamsHeader',
  adminManageTeamsButton = 'user_adminManageTeamsButton',
  adminTeamsEmptyMessage = 'user_adminTeamsEmptyMessage',
  adminDangerZone = 'user_adminDangerZone',
  adminDangerZoneSuspendButton = 'user_adminDangerZoneSuspendButton',
  adminDangerZoneSuspendWarning = 'user_adminDangerZoneSuspendWarning',
  adminDangerZoneSuspendConfirmTitle = 'user_adminDangerZoneSuspendConfirmTitle',
  adminDangerZoneSuspendConfirm = 'user_adminDangerZoneSuspendConfirm',
  adminDangerZoneSuspendSuccess = 'user_adminDangerZoneSuspendSuccess',
  adminDangerZoneActivateButton = 'user_adminDangerZoneActivateButton',
  adminDangerZoneActivateConfirmTitle = 'user_adminDangerZoneActivateConfirmTitle',
  adminDangerZoneActivateConfirm = 'user_adminDangerZoneActivateConfirm',
  adminDangerZoneActivateSuccess = 'user_adminDangerZoneActivateSuccess',
  adminDangerZoneMakeOwnerButton = 'user_adminDangerZoneMakeOwnerButton',
  adminDangerZoneMakeOwnerConfirmButton = 'user_adminDangerZoneMakeOwnerConfirmButton',
  adminDangerZoneMakeOwnerNoKeysetErrorMessage = 'user_adminDangerZoneMakeOwnerNoKeysetErrorMessage',
  adminDangerZoneMakeOwnerWarning = 'user_adminDangerZoneMakeOwnerWarning',
  adminDangerZoneMakeOwnerSuccess = 'user_adminDangerZoneMakeOwnerSuccess',
  adminDangerZoneRemoveUserSuccess = 'user_adminDangerZoneRemoveUserSuccess',
  adminDangerZoneRemoveUserModalMessage = 'user_adminDangerZoneRemoveUserModalMessage',
  adminDangerZoneResetMfaModalMessage = 'user_adminDangerZoneResetMfaModalMessage',
  adminDangerZoneResetMfaModalHeader = 'user_adminDangerZoneResetMfaModalHeader',
  adminDangerZoneResetMfaSuccessMessage = 'user_adminDangerZoneResetMfaSuccessMessage',
  adminDangerZoneRemoveUserModalHeader = 'user_adminDangerZoneRemoveUserModalHeader',
  adminDangerZoneRemoveUserButton = 'user_adminDangerZoneRemoveButton',
  adminDangerZoneRemoveUserWarning = 'user_adminDangerZoneRemoveWarning',
  adminDangerZoneResetMfaButton = 'user_adminDangerZoneResetMfaButton',
  adminDangerZoneResetMfaWarning = 'user_adminDangerZoneResetMfaWarning',
  adminDangerZoneRevokeButton = 'user_adminDangerZoneRevokeButton',
  adminDangerZoneRevokeWarning = 'user_adminDangerZoneRevokeWarning',
  adminDangerZoneRevokeConfirm = 'user_adminDangerZoneRevokeConfirm',
  adminDangerZoneRevokeSuccess = 'user_adminDangerZoneRevokeSuccess',
  adminDangerZoneMakeOwnerModalHeader = 'user_adminDangerZoneMakeOwnerModalHeader',
  adminDangerZoneMakeOwnerModalWarning = 'user_adminDangerZoneMakeOwnerModalWarning',
  adminDangerZoneMakeOwnerModalMessage = 'user_adminDangerZoneMakeOwnerModalMessage',
  editModalHeader = 'user_editModalHeader',
  adminUserFormRoleLabel = 'user_adminUserFormRoleLabel',
  adminUserFormCancelButton = 'user_adminUserFormCancelButton',
  adminUserFormSubmitButton = 'user_adminUserFormSubmitButton',
  adminUserTeamRemoveConfirmation = 'user_adminUserTeamRemoveConfirmation',
  adminTeamsManageModalHeader = 'user_adminTeamsManageModalHeader',
  manageModalUpdateButton = 'user_manageModalUpdateButton',
  adminUserDesktopsManageModalHeader = 'user_adminUserDesktopsManageModalHeader',
  adminUserDesktopsRemoveConfirmation = 'user_adminUserDesktopsRemoveConfirmation',
  adminUserDesktopsPartOfTeam = 'user_adminUserDesktopsPartOfTeam',
  adminUserNotFound = 'user_adminUserNotFound',
  adminInvitationNotFound = 'user_adminInvitationNotFound',

  inviteUsersModalHeader = 'user_inviteUsersModalHeader',
  inviteUsersEmailPlaceholder = 'user_inviteUsersEmailPlaceholder',
  inviteUsersSuccessToast = 'user_inviteUsersSuccessToast',
  inviteUsersDuplicatesToast = 'user_inviteUsersDuplicatesToast',
  inviteUsersAlreadyExistsToast = 'user_inviteUsersAlreadyExistsToast',
  inviteUsersTitle = 'user_inviteUsersTitle',
  inviteUsersDescription = 'user_inviteUsersDescription',
  inviteUsersSubmitButton = 'user_inviteUsersSubmitButton',
  inviteUsersSkipButton = 'user_inviteUsersSkipButton',
  inviteUsersRemoveButton = 'user_inviteUsersRemoveButton',
  inviteUsersAddButton = 'user_inviteUsersAddButton',
  inviteUsersFormEmailInvalid = 'user_inviteUsersFormEmailInvalid',
  inviteUsersFormEmailRequired = 'user_inviteUsersFormEmailRequired',

  profileEditPageHeader = 'user_profileEditPageHeader',
  profileFormHeader = 'user_profileFormHeader',
  profileImageUploadButton = 'user_profileImageUploadButton',
  profileImageChangeButton = 'user_profileImageChangeButton',
  profileImageRemoveButton = 'user_profileImageRemoveButton',
  profilePasswordImportantMessage = 'user_profilePasswordImportantMessage',
  profileFormButton = 'user_profileFormButton',
  formSuccessMessage = 'user_formSuccessMessage',
  profilePasswordModalHeader = 'user_profilePasswordModalHeader',
  profileFormFirstNameLabel = 'user_profileFormFirstNameLabel',
  profileFormLastNameLabel = 'user_profileFormLastNameLabel',
  profileFormEmailLabel = 'user_profileFormEmailLabel',
  profileFormPhoneLabel = 'user_profileFormPhoneLabel',
  profileFormPhoneLengthWarning = 'user_profileFormPhoneLengthWarning',
  profileFormCompanyNameLabel = 'user_profileFormCompanyNameLabel',
  profileFormCompanyNameLengthWarning = 'user_profileFormCompanyNameLengthWarning',
  profileFormCompanyUrlLabel = 'user_profileFormCompanyUrlLabel',
  profileFormTimezoneLabel = 'user_profileFormTimezoneLabel',
  profileFormTimezonePlaceholder = 'user_profileFormTimezonePlaceholder',
  profileOldPasswordLabel = 'user_profileOldPasswordLabel',
  profileNewPasswordLabel = 'user_profileNewPasswordLabel',
  profileConfirmPasswordLabel = 'user_profileConfirmPasswordLabel',
  profileMarketingConsentLabel = 'userProfileMarketingConsentLabel',

  profileFormFirstNameRequired = 'user_profileFormFirstNameRequired',
  profileFormLastNameRequired = 'user_profileFormLastNameRequired',
  profileFormEmailRequired = 'user_profileFormEmailRequired',
  profileFormCompanyUrlPlaceholder = 'user_profileFormCompanyUrlPlaceholder',
  profileFormCompanyUrlIsNotValid = 'user_profileFormCompanyUrlIsNotValid',
  profileFormOldPasswordRequired = 'user_profileFormOldPasswordRequired',
  profileFormNewPasswordRequired = 'user_profileFormNewPasswordRequired',
  profileFormPasswordMinimum = 'user_profileFormPasswordMinimum',
  profileFormConfirmPasswordRequired = 'user_profileFormConfirmPasswordRequired',
  profileFormPasswordMatch = 'user_profileFormPasswordMatch',
  profileFormPasswordDidNotChange = 'user_profileFormPasswordDidNotChange',
  profileFormPasswordContainLetter = 'user_profileFormPasswordContainLetter',
  profileFormPasswordContainNumber = 'user_profileFormPasswordContainNumber',
  profileFormPasswordErrorReencryptingKeys = 'user_profileFormPasswordErrorReencryptingKeys',

  dangerZoneDeleteAccountButton = 'user_dangerZoneDeleteAccountButton',
  dangerZoneDeleteWarning = 'user_dangerZoneDeleteWarning',
  dangerZoneConfirmTitle = 'user_dangerZoneConfirmTitle',
  dangerZoneConfirmWarning = 'user_dangerZoneConfirmWarning',
  dangerZoneConfirmMessage = 'user_dangerZoneConfirmMessage',
  confirmDeleteText = 'user_confirmDeleteText',
  deleteAccountSuccess = 'user_deleteAccountSuccess',
  deleteAccountLinkedAccountsMFAWarning = 'auth_deleteAccountLinkedAccountsMFAWarning',

  profileSectionTitle = 'user_profileSectionTitle',
  profileEditButton = 'user_profileEditButton',
  profileSubdomainSectionTitle = 'user_profileSubdomainSectionTitle',
  profileEditSubdomain = 'user_profileEditSubdomain',
  profilePasswordSectionTitle = 'user_profilePasswordSectionTitle',
  profileChangePassword = 'user_profileChangePasswordButton',
  profileSetPassword = 'user_profileSetPassword',
  profileChangePasswordDescription = 'user_profileChangePasswordDescription',

  profileEditTitle = 'user_profileEditTitle',

  profileMenuStatusHeading = 'user_profileMenuStatusHeading',
  profileMenuMyProfile = 'user_profileMenuMyProfile',
  profileMenuPreferences = 'user_profileMenuPreferences',
  profileMenuHelpCenter = 'user_profileMenuHelpCenter',
  profileMenuLogout = 'user_profileMenuLogout',

  profileConfirmChangeEmail = 'user_profileConfirmChangeEmail',
  revokeEmailChangeButton = 'user_revokeEmailChangeButton',
  revokeEmailChangeConfirm = 'user_revokeEmailChangeConfirm',
  revokeEmailChangeSuccess = 'user_revokeEmailChangeSuccess',

  tooltipInviteMembers = 'user_tooltipInviteMembers',
  tooltipEditUserProfile = 'user_tooltipEditUserProfile',
  tooltipEditUserRole = 'user_tooltipEditUserRole',
  tooltipManageUserGroup = 'user_tooltipManageUserGroup',
  tooltipManageUserDesktopAccess = 'user_tooltipManageUserDesktopAccess',

  inviteGoogleUsersButton = 'user_inviteGoogleUsersButton',
  inviteGoogleUsersDisclosure = 'user_inviteGoogleUsersDisclosure',
  inviteMicrosoftUsersButton = 'user_inviteMicrosoftUsersButton',

  inviteRemoveExternalUsersButton = 'user_inviteRemoveExternalUsersButton',
  inviteGoogleUsersEmptyStateMessage = 'user_inviteGoogleUsersEmptyStateMessage',
  inviteMicrosoftUsersEmptyStateMessage = 'user_inviteMicrosoftUsersEmptyStateMessage',
  inviteGoogleUsersTitle = 'user_inviteGoogleUsersTitle',
  inviteMicrosoftUsersTitle = 'user_inviteMicrosoftUsersTitle',
}

export type UserLocale = {
  [key in UserTranslation]: string;
};
