import styled from 'styled-components';
import React, { FC, PropsWithChildren } from 'react';

const StyledMenuHeading = styled.div`
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.625rem;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  text-transform: uppercase;
  margin: 0.5rem 1rem 0.25rem;
`;

export const MenuHeading: FC<PropsWithChildren> = React.memo(({ children }) => {
  return (
    <li>
      <StyledMenuHeading>{children}</StyledMenuHeading>
    </li>
  );
});
