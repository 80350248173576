import styled from 'styled-components';

export const StyledMessageListSkeleton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledMessage = styled.div`
  margin: 0.5rem 1rem;

  .message-wrapper {
    display: flex;
    margin-left: 0;
    margin-top: 0.125rem;

    > div:first-child {
      min-width: 2.25rem;
    }
  }
`;
