import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const CallFilledIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      data-testid="call-filled-icon"
      {...props}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8144 3.1621C4.94444 3.57587 4.84924 4.04529 4.51626 4.37826L3.51269 5.38184C4.25382 6.66824 5.32974 7.74098 6.6189 8.48657L7.62174 7.48374C7.94426 7.16121 8.42234 7.05054 8.84882 7.19514C9.45951 7.39658 10.1211 7.506 10.8 7.506C11.4614 7.506 12 8.04463 12 8.706V10.8C12 11.4614 11.4614 12 10.8 12C4.83463 12 0 7.16537 0 1.2C0 0.538629 0.538629 0 1.2 0H3.3C3.96137 0 4.5 0.538629 4.5 1.2C4.5 1.88564 4.60965 2.54212 4.81172 3.15379L4.8144 3.1621Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
