import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const GoogleFormsIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <rect x="3" y="3" width="18" height="18" rx="1.6875" fill="#7627BB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9375 8.0625C6.9375 8.68382 7.44118 9.1875 8.0625 9.1875C8.68382 9.1875 9.1875 8.68382 9.1875 8.0625C9.1875 7.44118 8.68382 6.9375 8.0625 6.9375C7.44118 6.9375 6.9375 7.44118 6.9375 8.0625ZM9.75 8.48438C9.75 8.79504 10.0018 9.04688 10.3125 9.04688L16.5 9.04687C16.8107 9.04687 17.0625 8.79503 17.0625 8.48437V7.64062C17.0625 7.32996 16.8107 7.07812 16.5 7.07812L10.3125 7.07812C10.0018 7.07812 9.75 7.32996 9.75 7.64062V8.48438Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9375 12C6.9375 12.6213 7.44118 13.125 8.0625 13.125C8.68382 13.125 9.1875 12.6213 9.1875 12C9.1875 11.3787 8.68382 10.875 8.0625 10.875C7.44118 10.875 6.9375 11.3787 6.9375 12ZM9.75 12.4219C9.75 12.7325 10.0018 12.9844 10.3125 12.9844L16.5 12.9844C16.8107 12.9844 17.0625 12.7325 17.0625 12.4219V11.5781C17.0625 11.2675 16.8107 11.0156 16.5 11.0156L10.3125 11.0156C10.0018 11.0156 9.75 11.2675 9.75 11.5781V12.4219Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9375 15.9375C6.9375 16.5588 7.44118 17.0625 8.0625 17.0625C8.68382 17.0625 9.1875 16.5588 9.1875 15.9375C9.1875 15.3162 8.68382 14.8125 8.0625 14.8125C7.44118 14.8125 6.9375 15.3162 6.9375 15.9375ZM9.75 16.3594C9.75 16.67 10.0018 16.9219 10.3125 16.9219L16.5 16.9219C16.8107 16.9219 17.0625 16.67 17.0625 16.3594V15.5156C17.0625 15.205 16.8107 14.9531 16.5 14.9531L10.3125 14.9531C10.0018 14.9531 9.75 15.205 9.75 15.5156V16.3594Z"
        fill="white"
      />
    </svg>
  );
});
