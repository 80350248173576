import React, { memo } from 'react';
import { useAppleTouchDevice } from '../../hooks';
import { StyledTooltip } from './Tooltip.styled';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

export enum TooltipPlace {
  top = 'top',
  bottom = 'bottom',
  right = 'right',
  left = 'left',
}

const REBUILD_ROUTE_CHANGE_DELAY = 2000;
const TOOLTIP_DELAY_SHOW = 200;

export const TooltipComponent = memo(() => {
  const location = useLocation();
  const isAppleTouchDevice = useAppleTouchDevice();

  if (isAppleTouchDevice) {
    return null;
  }

  return ReactDOM.createPortal(
    <StyledTooltip id="global-tooltip" />,
    document.getElementById('tooltip') as Element,
  );
});
