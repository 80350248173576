export enum SegmentTranslation {
  segmentDesktops = 'segment_segmentDesktops',
  segmentChats = 'segment_segmentChats',
  segmentMeetings = 'segment_segmentMeetings',
  segmentPasswords = 'segment_segmentPasswords',
  segmentVault = 'segment_segmentVault',
  segmentVaultPwmAvailable = 'segment_segmentVaultPwmAvailable',
}

export type SegmentLocale = {
  [key in SegmentTranslation]: string;
};
