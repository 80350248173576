import { useContext } from 'react';
import { PreviewSegmentContext } from './PreviewSegment.context';

export const usePreviewSegment = () => {
  const {
    isPreviewMode,
    navigateToSegment,
    handleHoverPreview,
    handleClickPreview,
    closePreview,
  } = useContext(PreviewSegmentContext);
  return {
    isPreviewMode,
    navigateToSegment,
    handleHoverPreview,
    handleClickPreview,
    closePreview,
  };
};
