import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const InformationIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM8 9.75C8 9.3358 8.3358 9 8.75 9H10.25C10.6642 9 11 9.3358 11 9.75V13.5H11.75C12.1642 13.5 12.5 13.8358 12.5 14.25C12.5 14.6642 12.1642 15 11.75 15H8.75C8.3358 15 8 14.6642 8 14.25C8 13.8358 8.3358 13.5 8.75 13.5H9.5V10.5H8.75C8.3358 10.5 8 10.1642 8 9.75ZM10 7C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5C9.4477 5 9 5.44772 9 6C9 6.55228 9.4477 7 10 7Z"
        fill="currentColor"
      />
    </svg>
  );
});
