import { getLongId } from '../../../shared/utils/id';
import { LinkApiType } from '../../Link/Link.types';
import { DesktopLinksTableType } from './DesktopLinksTable';

/**
 * Converts a LinksApiType to DesktopLinksTableRow
 * @param link
 * @param workspaceId
 * @returns DesktopLinksTableType
 */
export const LinksApiTypeToDesktopLinksTableRow = (
  link: LinkApiType,
  workspaceId: string,
): DesktopLinksTableType => ({
  id: getLongId('links', link.id),
  desktopId: getLongId('desktops', link.desktopId),
  workspaceId: getLongId('workspaces', workspaceId),
  accountFavorite: link.accountFavorite ?? false,
  folder: link.folder?.id ? getLongId('folders', link.folder?.id) : null,
  createdAt: link.createdAt,
  sourceData: link,
});
