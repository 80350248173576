import { WorkspaceInfoApiType } from '../types/WorkspaceInfo.types';
import { WorkspaceInfoApiTypeToWorkspaceInfoTableType } from '../utils/WorksapceIndoIDDB.utils';
import { database } from '../../../../Database';

export const putWorkspaceInfoToIDDB = (workspaceInfo: WorkspaceInfoApiType) => {
  try {
    const workspaceInfoRow =
      WorkspaceInfoApiTypeToWorkspaceInfoTableType(workspaceInfo);
    database.workspaceInfo.put(workspaceInfoRow);
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};
