export const getShortId = (fullId: string): string => {
  if (fullId) {
    const idParts = fullId.split('/');
    return idParts[idParts.length - 1];
  }
  return fullId;
};

type identifiers =
  | 'accounts'
  | 'account-invitations'
  | 'desktops'
  | 'desktop-accesses'
  | 'workspaces'
  | 'links'
  | 'folders'
  | 'desktop-apps'
  | 'apps'
  | 'chat-conversations'
  | 'chat-messages'
  | 'workspace-teams';

/**
 * Util to make sure an ID is in iri format.
 * Used now since there is a variation in id formats between REST and GraphQL responses.
 * @param identifier
 * @param id
 * @returns
 */
export const getLongId = (identifier: identifiers, id: string): string => {
  if (id) {
    const idParts = id.split('/');

    if (idParts.length === 3) {
      return id;
    }

    return `/${identifier}/${id}`;
  }
  return id;
};

export const isValidGuid = (guid: string): boolean => {
  var pattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return pattern.test(guid);
};

export const generateRandomId = () => {
  if ('crypto' in window && 'randomUUID' in crypto) {
    return crypto.randomUUID();
  }

  // Fallback for crypto.randomUUID is based on Math.random
  // which cannot be 100% reliable. Please take it into account.
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => {
    const r = Math.floor(Math.random() * 16);
    return r.toString(16);
  });
};
