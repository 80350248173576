import { StreamWorkspaceAccountInfoEventAction } from '../../../GeneralMercure.types';
import { putWorkspaceInfoToIDDB } from '../../../../../WorkspaceInfo/data/WorkspaceInfo/operations/WorkspaceInfoIDDB.operations';
import { updateAccountInIDDB } from '../../../../../Account/data/Account/operations/AccountIDDB.operations';
import { getLongId } from '../../../../../../shared/utils/id';
import { AccountWorkspaceCacheApiType } from '../../../../../User/User.types';

export const workspaceInfoRequestUpdate = (
  event: StreamWorkspaceAccountInfoEventAction,
) => {
  if (event?.['@payload']) {
    const { id, account } = event?.['@payload'];
    const workspaceIri = getLongId('workspaces', id);
    const accountIri = getLongId('accounts', account);

    putWorkspaceInfoToIDDB(event?.['@payload']);
    updateAccountInIDDB(accountIri, workspaceIri, account => {
      return {
        ...account,
        workspaceCache: {
          [accountIri]: {
            ...(account.workspaceCache?.[accountIri] || {}),
            active: event?.['@payload'].active,
            groupIdentifier: event?.['@payload'].role,
          } as AccountWorkspaceCacheApiType,
        },
      };
    });
  }
};
