import { isAppleTouchDevice, isIPad, isSafari } from '../utils/user-agent';

export const useAppleTouchDevice = () => {
  return isAppleTouchDevice();
};

export const useIPad = () => {
  return isIPad();
};

export const useSafari = () => {
  return isSafari();
};
