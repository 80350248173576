import styled from 'styled-components';
import { StyledScheduleRepeatRadio } from '../../../Conference/ConferenceSchedule/ScheduleRepeatingConferenceForm/ScheduleRepeatingConferenceForm.styled';
import { NotificationsSectionBodyItem } from '../NotificationsSettingsForm/NotificationsSettingsForm.styled';

export const CustomNotificationsCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  line-height: 1.5rem;

  & label {
    width: 100%;
  }
`;

export const NotificationsSeparatorStyled = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  background-color: ${p => p.theme.colors.BorderLight};
`;

export const StyledNotificationsRadio = styled(StyledScheduleRepeatRadio)`
  height: auto;
  margin-bottom: 1.125rem;

  & label {
    cursor: pointer;
  }
`;

export const StyledNotificationsTitle = styled.div`
  margin-bottom: 1.125rem;
`;

export const StyledCustomNotificationsBodyItem = styled(
  NotificationsSectionBodyItem,
)`
  &:not(:first-of-type) {
    margin-top: 1.125rem;
  }
`;
