export enum AnnouncementTranslation {
  announcementHeaderTitle = 'announcement_headerTitle',
  announcement2FeaturesTitle = 'announcement2_announcement2FeaturesTitle',
  announcement3FeaturesTitle = 'announcement3_announcement3FeaturesTitle',
  announcement4FeaturesTitle = 'announcement4_announcement3FeaturesTitle',
  announcement5FeaturesTitle = 'announcement4_announcement4FeaturesTitle',

  // Announcement2
  announcement2AppIntegrationsTitle = 'announcement2_announcement2AppIntegrationsTitle',
  announcement2AppIntegrationsDescriptionWithPermission = 'announcement2_announcement2AppIntegrationsDescriptionWithPermission',
  announcement2AppIntegrationsDescriptionWithoutPermission = 'announcement2_announcement2AppIntegrationsDescriptionWithoutPermission',

  announcement2PasswordManagementChangesTitle = 'announcement2_announcement2PasswordManagementChangesTitle',
  announcement2PasswordManagementChangesDescription = 'announcement2_announcement2PasswordManagementChangesDescription',

  // Announcement3
  announcement3FirstParagraphTitle = 'announcement3_announcement3FirstParagraphTitle',
  announcement3FirstParagraphContent = 'announcement3_announcement3FirstParagraphContent',
  announcement3SecondParagraphTitle = 'announcement3_announcement3SecondParagraphTitle',
  announcement3SecondParagraphContent = 'announcement3_announcement3SecondParagraphContent',
  announcement3ThirdParagraphTitle = 'announcement3_announcement3ThirdParagraphTitle',
  announcement3ThirdParagraphContent = 'announcement3_announcement3ThirdParagraphContent',

  // Announcement4
  announcement4FirstParagraphTitle = 'announcement4_announcement3FirstParagraphTitle',
  announcement4FirstParagraphContent = 'announcement4_announcement3FirstParagraphContent',
  announcement4SecondParagraphTitle = 'announcement4_announcement3SecondParagraphTitle',
  announcement4SecondParagraphContent = 'announcement4_announcement3SecondParagraphContent',
  announcement4ThirdParagraphTitle = 'announcement4_announcement3ThirdParagraphTitle',
  announcement4ThirdParagraphContent = 'announcement4_announcement3ThirdParagraphContent',

  // Announcement5 Rebranding
  announcement5FirstParagraphTitle = 'announcement5_announcement5FirstParagraphTitle',
  announcement5FirstParagraphContent = 'announcement5_announcement5FirstParagraphContent',
  announcement5SecondParagraphTitle = 'announcement5_announcement5SecondParagraphTitle',
  announcement5SecondParagraphContent = 'announcement5_announcement5SecondParagraphContent',
  announcement5ThirdParagraphTitle = 'announcement5_announcement5ThirdParagraphTitle',
  announcement5ThirdParagraphContent = 'announcement5_announcement5ThirdParagraphContent',
  announcement5FourthParagraphContent = 'announcement5_announcement5FourthParagraphContent',
}

export type AnnouncementLocale = {
  [key in AnnouncementTranslation]: string;
};
