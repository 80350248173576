import React, { FC } from 'react';
import { IconProps } from '../../shared/icons/icon.types';

export const MissedCallIcon: FC<IconProps> = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0669 2.81694C14.311 2.57286 14.311 2.17714 14.0669 1.93306C13.8229 1.68898 13.4271 1.68898 13.1831 1.93306L11.9375 3.17862L10.6919 1.93306C10.4479 1.68898 10.0521 1.68898 9.80806 1.93306C9.56398 2.17714 9.56398 2.57286 9.80806 2.81694L11.0536 4.0625L9.80806 5.30806C9.56398 5.55214 9.56398 5.94786 9.80806 6.19194C10.0521 6.43602 10.4479 6.43602 10.6919 6.19194L11.9375 4.94638L13.1831 6.19194C13.4271 6.43602 13.8229 6.43602 14.0669 6.19194C14.311 5.94786 14.311 5.55214 14.0669 5.30806L12.8214 4.0625L14.0669 2.81694Z"
        fill="#021121"
        fillOpacity="0.6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0626 10.9551V12.6426C13.0638 12.9593 12.9315 13.262 12.6981 13.4761C12.4647 13.6903 12.1518 13.7961 11.8363 13.7676C10.1054 13.5795 8.44275 12.988 6.98193 12.0407C5.62284 11.1771 4.47056 10.0248 3.60693 8.66568C2.6563 7.19823 2.06469 5.52749 1.88006 3.78881C1.85164 3.4743 1.95671 3.16232 2.16961 2.92909C2.38252 2.69586 2.68364 2.56285 2.99943 2.56256H4.68693C5.25161 2.557 5.7329 2.97091 5.81193 3.53006C5.88316 4.07009 6.01525 4.60034 6.20568 5.11068C6.36022 5.5218 6.26138 5.98526 5.95256 6.29756L5.23818 7.01193C6.03893 8.42017 7.20494 9.58618 8.61318 10.3869L9.32756 9.67256C9.63985 9.36373 10.1033 9.26489 10.5144 9.41943C11.0248 9.60986 11.555 9.74196 12.0951 9.81318C12.6607 9.89298 13.0767 10.384 13.0626 10.9551Z"
        stroke="#021121"
        strokeOpacity="0.6"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
