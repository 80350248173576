import styled, { css } from 'styled-components';

interface StyledMenuProps {
  opened: boolean;
  width?: number | 'auto' | null;
  maxHeight: number | null;
  x: number;
  y: number;
  alignTop?: boolean;
  firstLevelHidden: boolean;
  scrollProbability?: string;
}

export const StyledMenu = styled.ul<StyledMenuProps>`
  display: block;
  position: fixed;
  ${({ alignTop, y }) =>
    alignTop
      ? css`
          bottom: ${y || 0}px;
        `
      : css`
          top: ${y || 0}px;
        `};
  left: ${p => p.x || 0}px;
  margin: 0;
  padding: 8px 0;
  ${({ width }) =>
    width &&
    css`
      width: ${width === 'auto' ? 'auto' : `${width}px`};
    `}
  min-height: 50px;
  max-width: 100vw;
  ${p =>
    p.scrollProbability &&
    css`
      max-height: ${p.scrollProbability
        ? `${p.scrollProbability}`
        : `${p.maxHeight}px`};
    `}
  overflow-y: auto;
  background-color: ${p => p.theme.colors.Surface};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: ${p => p.theme.zIndexLevels.Menu};
  border: 1px solid ${p => p.theme.colors.BorderDefault};

  ${({ firstLevelHidden }) =>
    firstLevelHidden &&
    css`
      & > li,
      & > span {
        display: none;
      }
    `}
`;
