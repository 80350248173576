import { getLongId } from '../../../../../shared/utils/id';
import { WorkspaceInfoApiType } from '../types/WorkspaceInfo.types';
import { WorkspaceInfoTableType } from '../types/WorkspaceInfoIDDB.types';

export const WorkspaceInfoApiTypeToWorkspaceInfoTableType = (
  workspaceInfo: WorkspaceInfoApiType,
) => {
  const row: WorkspaceInfoTableType = {
    id: getLongId('workspaces', workspaceInfo.id),
    accountId: getLongId('accounts', workspaceInfo.account),
    sourceData: workspaceInfo,
  };
  return row;
};

export const WorkspaceInfoRowTableTypeToWorkspaceInfoApiType = (
  workspaceInfoRow: WorkspaceInfoTableType | undefined,
) => {
  return workspaceInfoRow?.sourceData;
};
