import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { ThemeContext } from './Theme.context';
import { DarkTheme, getCurrentThemeType, LightTheme, ThemeType } from './';

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeType>(getCurrentThemeType());

  // const detectSystemThemePreference = () => {
  //   const isSystemPreferredThemeLight = window.matchMedia(
  //     `(prefers-color-scheme: ${ThemeType.light})`,
  //   ).matches;
  //   return isSystemPreferredThemeLight ? ThemeType.light : ThemeType.dark;
  // };
  //
  // useEffect(() => {
  //   if (theme === ThemeType.system) {
  //     const systemPreferredTheme = detectSystemThemePreference();
  //     return setTheme(systemPreferredTheme);
  //   }
  // }, [theme]);

  const themeContextData = useMemo(
    () => ({
      theme: ThemeType.dark === theme ? DarkTheme : LightTheme,
      themeType: ThemeType.dark === theme ? ThemeType.dark : ThemeType.light,
      setTheme,
    }),
    [theme],
  );

  return (
    <ThemeContext.Provider value={themeContextData}>
      <StyledComponentsThemeProvider theme={themeContextData.theme}>
        {children}
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  );
};
