import React from 'react';
import { CountersContextType } from './Counters.types';

export const CountersContext = React.createContext<CountersContextType>({
  unreadNotifications: {},
  unreadChatMessages: {},
  unreadChatConferences: {},
  unreadMentions: {},
  unreadThreads: {},
  unreadChatConversations: {},
});
