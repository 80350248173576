import { AccountLocale, AccountTranslation } from './i18n.types';

export const enAccountLocale: AccountLocale = {
  // Plan intervals
  [AccountTranslation.statusOnline]: 'Online',
  [AccountTranslation.statusAway]: 'Inactive',
  [AccountTranslation.statusBusy]: 'Do not disturb',
  [AccountTranslation.statusOffline]: 'Offline',
  [AccountTranslation.statusInvisible]: 'Invisible',

  [AccountTranslation.statusForcedOnline]:
    'Your status has been automatically set to Online',

  [AccountTranslation.deletedAccountFirstName]: 'Deleted',
  [AccountTranslation.deletedAccountLastName]: 'User',
  [AccountTranslation.deletedAccountEmail]: '@',
};
