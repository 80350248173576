import {
  StreamEventActions,
  StreamWorkspaceAccountInfoEventAction,
} from '../../GeneralMercure.types';
import { workspaceInfoRequestUpdate } from './Requests';

export const workspaceInfoRequest = (event: StreamEventActions) => {
  if (event) {
    workspaceInfoRequestUpdate(event as StreamWorkspaceAccountInfoEventAction);
  }
};
