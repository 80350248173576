import { gql } from '@apollo/client';
import {
  BillingContactFormValues,
  PaymentCustomerApiType,
  PaymentPlan,
} from './Billing.types';

export const CREATE_PAYMENT_CUSTOMER = gql`
  mutation CreatePaymentCustomer($input: createPaymentCustomerInput!) {
    createPaymentCustomer(input: $input) {
      clientMutationId
    }
  }
`;

export interface CreatePaymentCustomerVariables {
  input: {
    paymentMethodId: string;
    billingEmail: string | null;
    workspace: string;
    billingName: string;
    country: string | null;
    city: string | null;
    line1: string | null;
    line2: string | null;
    postalCode: string | null;
    state: string | null;
    coupon: string | null;
    vatType: string | null;
    vatNumber: string | null;
  };
}

export interface CreatePaymentCustomerResponse {
  paymentCustomer: {
    clientMutationId: string;
  };
}

export const UPDATE_PAYMENT_CUSTOMER = gql`
  mutation UpdatePaymentCustomer($input: updatePaymentCustomerInput!) {
    __typename
    updatePaymentCustomer(input: $input) {
      paymentCustomer {
        id
        billingName
        billingEmail
        country
        city
        line1
        line2
        postalCode
        state
        vatNumber
        vatType
        invoices {
          edges {
            node {
              id
              _id
              periodStart
              periodEnd
              amount
              currency
              paymentMethod
              pdf {
                contentUrl
              }
            }
          }
        }
      }
    }
  }
`;

export interface UpdatePaymentCustomerVariables {
  input: BillingContactFormValues;
}

export interface UpdatePaymentCustomerResponse {
  updatePaymentCustomer: {
    paymentCustomer: PaymentCustomerApiType;
  };
}

export const ADD_PAYMENT_METHOD_TO_PAYMENT_CUSTOMER = gql`
  mutation AddPaymentMethodPaymentCustomer(
    $input: addPaymentMethodPaymentCustomerInput!
  ) {
    addPaymentMethodPaymentCustomer(input: $input) {
      clientMutationId
    }
  }
`;

export interface AddPaymentMethodPaymentCustomerVariables {
  input: {
    id: string;
    paymentMethodId: string;
    default: boolean;
  };
}

export interface AddPaymentMethodPaymentCustomerResponse {
  clientMutationId: string;
}

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation MakePaymentMethodDefaultPaymentCustomer(
    $input: makePaymentMethodDefaultPaymentCustomerInput!
  ) {
    makePaymentMethodDefaultPaymentCustomer(input: $input) {
      clientMutationId
    }
  }
`;

export interface MakePaymentMethodDefaultPaymentCustomerVariables {
  input: {
    id: string;
    paymentMethodId: string;
  };
}

export interface MakePaymentMethodDefaultPaymentCustomerResponse {
  clientMutationId: string;
}

export const DELETE_PAYMENT_METHOD = gql`
  mutation DeletePaymentMethodPaymentCustomer(
    $input: deletePaymentMethodPaymentCustomerInput!
  ) {
    deletePaymentMethodPaymentCustomer(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeletePaymentMethodVariables {
  input: {
    id: string;
    paymentMethodId: string;
  };
}

export interface DeletePaymentMethodResponse {
  clientMutationId: string;
}

export const DOWNGRADE_WORKSPACE = gql`
  mutation DowngradeWorkspace($input: downgradeWorkspaceInput!) {
    downgradeWorkspace(input: $input) {
      clientMutationId
    }
  }
`;

export interface DowngradeWorkspaceVariables {
  input: {
    id: string;
    newType: PaymentPlan.FREE;
    accounts: string[];
  };
}

export interface DowngradeWorkspaceResponse {
  clientMutationId: string;
}

export const CANCEL_DOWNGRADE_WORKSPACE = gql`
  mutation CancelDowngradeWorkspace($input: cancelDowngradeWorkspaceInput!) {
    cancelDowngradeWorkspace(input: $input) {
      clientMutationId
    }
  }
`;

export interface CancelDowngradeWorkspaceVariables {
  input: {
    id: string;
  };
}

export interface CancelDowngradeWorkspaceResponse {
  clientMutationId: string;
}

export const ACTIVATE_SUBSCRIPTION_PAYMENT = gql`
  mutation activateSubscriptionPaymentCustomer(
    $input: activateSubscriptionPaymentCustomerInput!
  ) {
    activateSubscriptionPaymentCustomer(input: $input) {
      clientMutationId
    }
  }
`;

export interface ActivateSubscriptionPaymentVariables {
  input: {
    id: string;
  };
}

export interface ActivateSubscriptionPaymentResponse {
  clientMutationId: string;
}
