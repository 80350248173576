export const NOTIFICATIONS_INITIAL_LOAD_COUNT = 10;
export const NOTIFICATIONS_FETCHMORE_LOAD_COUNT = 10;

export const ADMIN_NOTIFICATIONS_PATHNAME =
  '/workspace/:workspaceId/admin/notifications';

export const GRAPHQL_TYPENAME_NOTIFICATION_CONNECTION =
  'NotificationConnection';
export const GRAPHQL_TYPENAME_NOTIFICATION = 'Notification';
export const GRAPHQL_TYPENAME_NOTIFICATION_PAGEINFO = 'NotificationPageInfo';
export const GRAPHQL_TYPENAME_NOTIFICATION_EDGE = 'NotificationEdge';
export const GRAPHQL_TYPENAME_NOTIFICATION_MESSAGE = 'NotificationMessage';
