import { getLongId } from '../../../shared/utils/id';
import type { DesktopApiType } from '../../Desktop/data/Desktop/types/Desktop.types';
import type { DesktopsTableType } from './DesktopsTable';

export const DesktopApiTypeToDesktopTableType = (
  desktop: DesktopApiType,
  workspaceId: string,
  accessible: boolean = false,
  relatedTeams: string[] = [],
): DesktopsTableType => {
  return {
    id: getLongId('desktops', desktop.id),
    workspaceId: getLongId('workspaces', workspaceId),
    createdAt: desktop.createdAt,
    sourceData: desktop,
    relatedTeams,
    relatedAccounts: desktop.accountIds,
    shareable: desktop.shareable.toString(),
    accessible: accessible.toString(),
  };
};
