import { DesktopFragmentFields } from '../Desktop/Desktop.fragments';

export const ChatMessageFields = `
id
_id
type
message
createdAt
microtimeAt
modified
context
reactions
threadMessagesCount
parentChatMessageIri
seenBy
accountIri
threadMessagesAccounts
`;

export const ChatConversationFields = `
id
_id
type
users
lastMessageAt
createdAt
conversationTitle
pendingEmails
desktopIri
accountsThatPinned
accountsThatHidden
createdByIri
`;

export const RemoveChatConversationFields = `
id
_id
type
users
lastMessageAt
createdAt
conversationTitle
pendingEmails
desktop {
  ${DesktopFragmentFields}
}
accountsThatPinned
accountsThatHidden
`;

export const DraftChatMessageFields = `
id
_id
type
message
context
parentChatMessageIri
conversationIri
microtimeAt
`;
