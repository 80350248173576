import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ChevronBackIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.78 19.78a.75.75 0 0 1-1.06 0l-7.25-7.25a.75.75 0 0 1 0-1.06l7.25-7.25a.75.75 0 1 1 1.06 1.06L5.06 12l6.72 6.72a.75.75 0 0 1 0 1.06z"
        fill="currentColor"
        fillOpacity=".6"
      />
    </svg>
  );
});
