import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const CalendarIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M8.75 2a.75.75 0 0 1 .75.75V4h5V2.75a.75.75 0 0 1 1.5 0V4h3.25c.966 0 1.75.783 1.75 1.75v13.5A1.75 1.75 0 0 1 19.25 21H4.75A1.75 1.75 0 0 1 3 19.25V5.75C3 4.783 3.784 4 4.75 4H8V2.75A.75.75 0 0 1 8.75 2zm0 3.5h10.5a.25.25 0 0 1 .25.25V8h-15V5.75a.25.25 0 0 1 .25-.25h4zm-4.25 4v9.75c0 .138.112.25.25.25h14.5a.25.25 0 0 0 .25-.25V9.5h-15z"
      />
    </svg>
  );
});
