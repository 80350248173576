import React from 'react';

type MaintenanceContextType = {
  scheduledDowntime: string;
  setScheduledDowntime: (_: string) => void;
};

export const ScheduledDowntimeContext = React.createContext<MaintenanceContextType>(
  {
    scheduledDowntime: '',
    setScheduledDowntime: () => {},
  } as MaintenanceContextType,
);
