import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const BellIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M4.5 6C4.5 4.80653 4.97411 3.66193 5.81802 2.81802C6.66193 1.97411 7.8065 1.5 9 1.5C10.1935 1.5 11.3381 1.97411 12.182 2.81802C13.0259 3.66193 13.5 4.80653 13.5 6V8.917C13.5 9.9448 13.8333 10.9448 14.45 11.767L16.5 14.5H1.5L3.55 11.767C4.16666 10.9448 4.5 9.9448 4.5 8.917V6ZM9 0C7.4087 0 5.88258 0.63214 4.75736 1.75736C3.63214 2.88258 3 4.4087 3 6V8.917C3 9.6202 2.77192 10.3044 2.35 10.867L0.300003 13.6C0.132863 13.8229 0.0310827 14.0878 0.00606268 14.3653C-0.0189573 14.6427 0.0337828 14.9217 0.158363 15.1708C0.282943 15.42 0.474443 15.6295 0.711403 15.776C0.948373 15.9224 1.22143 16 1.5 16H5.5C5.5 16.4596 5.59053 16.9148 5.76642 17.3394C5.94231 17.764 6.20012 18.1499 6.52513 18.4749C6.85013 18.7999 7.236 19.0577 7.6606 19.2336C8.0852 19.4095 8.5404 19.5 9 19.5C9.4596 19.5 9.9148 19.4095 10.3394 19.2336C10.764 19.0577 11.1499 18.7999 11.4749 18.4749C11.7999 18.1499 12.0577 17.764 12.2336 17.3394C12.4095 16.9148 12.5 16.4596 12.5 16H16.5C16.7786 16 17.0516 15.9224 17.2886 15.776C17.5256 15.6295 17.7171 15.42 17.8416 15.1708C17.9662 14.9217 18.019 14.6427 17.9939 14.3653C17.9689 14.0878 17.8671 13.8229 17.7 13.6L15.65 10.867C15.2281 10.3044 15 9.6202 15 8.917V6C15 4.4087 14.3679 2.88258 13.2426 1.75736C12.1174 0.63214 10.5913 0 9 0ZM11 16H7C7 16.5304 7.2107 17.0391 7.5858 17.4142C7.9609 17.7893 8.4696 18 9 18C9.5304 18 10.0391 17.7893 10.4142 17.4142C10.7893 17.0391 11 16.5304 11 16Z"
      />
    </svg>
  );
});
