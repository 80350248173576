import { TeamLocale, TeamTranslation } from './i18n.types';

export const enTeamLocale: TeamLocale = {
  [TeamTranslation.adminListHeaderTitle]: 'Groups',
  [TeamTranslation.adminListAddButton]: 'New group',
  [TeamTranslation.adminListEmptyMessage]: 'No groups to display',
  [TeamTranslation.adminTeamGeneralInfo]: 'General information',
  [TeamTranslation.adminTeamEditButton]: 'Edit',
  [TeamTranslation.adminTeamMembers]: 'Members',
  [TeamTranslation.adminTeamMembersManageButton]: 'Manage',
  [TeamTranslation.adminTeamMembersEmptyMessage]:
    'This group has no members yet',
  [TeamTranslation.adminTeamMembersManageModalHeader]: 'Manage members',
  [TeamTranslation.adminTeamMembersRemoveConfirmation]:
    'Remove this member from the group?',
  [TeamTranslation.adminTeamDesktops]: 'Desktops',
  [TeamTranslation.adminTeamDesktopsEmptyMessage]:
    'This group has no related desktops yet',
  [TeamTranslation.adminTeamDesktopsManageButton]: 'Manage',
  [TeamTranslation.adminTeamDesktopsRemoveConfirmation]:
    'Hide this desktop for the group?',
  [TeamTranslation.adminTeamDesktopsManageModalHeader]: 'Manage desktops',
  [TeamTranslation.adminTeamDangerZone]: 'Danger zone',
  [TeamTranslation.adminTeamDangerZoneDeleteButton]: 'Delete group',
  [TeamTranslation.adminTeamDangerZoneDeleteWarning]:
    'Deleting a group cannot be reversed',
  [TeamTranslation.adminTeamDangerZoneDeleteConfirmation]: 'Delete this group?',
  [TeamTranslation.adminTeamNotFound]: 'Group not found',

  [TeamTranslation.manageModalUpdateButton]: 'Update',

  [TeamTranslation.createModalHeader]: 'Create group',
  [TeamTranslation.createSubmitButton]: 'Create',
  [TeamTranslation.createCancelButton]: 'Cancel',
  [TeamTranslation.editModalHeader]: 'Edit group',
  [TeamTranslation.editSubmitButton]: 'Update',

  [TeamTranslation.formNameLabel]: 'Name',
  [TeamTranslation.formNamePlaceholder]: 'Type the group name',
  [TeamTranslation.formNameRequiredError]: 'Please enter group name',
  [TeamTranslation.formDescriptionLabel]: 'Description',
  [TeamTranslation.formDescriptionPlaceholder]: ' ',
};
