import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import {
  SkeletonInner,
  SkeletonLinkTitleWrapper,
  SkeletonLinkContent,
  SkeletonLinkPreviewImage,
  SkeletonLinkTitle,
  SkeletonLinkUrl,
  SkeletonLinkUserAvatar,
  StyledBlockSkeleton,
  StyledButtonBlockSkeleton,
  StyledLinksViewSkeleton,
} from './LinksViewSkeleton.styled';
import {
  SkeletonBox,
  SkeletonElement,
} from '../../../../shared/components/SkeletonBox';
import { useMobile } from '../../../../shared/hooks';

export const ButtonBlockSkeleton = () => {
  const { colors } = useTheme();
  return (
    <StyledButtonBlockSkeleton>
      <SkeletonBox
        width={5.625}
        height={1.125}
        color={colors.LinksViewSkeleton}
      />
    </StyledButtonBlockSkeleton>
  );
};

export const FolderBlockSkeleton = () => {
  const { colors } = useTheme();
  const isMobile = useMobile();

  return (
    <StyledBlockSkeleton>
      {new Array(isMobile ? 1 : 4).fill(null).map((_, i) => (
        <SkeletonInner
          key={i}
          width={9.375}
          height={9.375}
          margin={[1.25, 0, 0, 1.25]}
          color={colors.LinksViewSkeleton}
          borderRadius={0.5}
        />
      ))}
    </StyledBlockSkeleton>
  );
};

export const LinkSkeleton: FC<{ fluid?: boolean }> = React.memo(({ fluid }) => {
  const { colors } = useTheme();

  return (
    <SkeletonInner
      width={fluid ? 'auto' : 20}
      height={fluid ? '96px' : 9.375}
      margin={[1.25, 0, 0, 1.25]}
      color={colors.LinksViewSkeleton}
      borderRadius={0.5}>
      <SkeletonLinkContent>
        <SkeletonLinkTitleWrapper>
          <SkeletonElement
            width={fluid ? '90%' : 9}
            height={1}
            color={colors.LinkViewSkeleton}
            borderRadius={0.2}
          />
          <SkeletonElement
            width={fluid ? '60%' : 4}
            height={1}
            color={colors.LinkViewSkeleton}
            borderRadius={0.2}
          />
        </SkeletonLinkTitleWrapper>
        <SkeletonElement
          width={fluid ? '60%' : 4}
          height={0.5}
          color={colors.LinkViewSkeleton}
          borderRadius={0.2}
        />
      </SkeletonLinkContent>
      <SkeletonElement
        width={fluid ? '96px' : 4.375}
        height={fluid ? '100%' : 4.375}
        color={colors.LinkViewSkeleton}
        borderRadius={0.5}
      />
    </SkeletonInner>
  );
});

export const LinkBlockSkeleton = () => {
  const isMobile = useMobile();

  return (
    <StyledBlockSkeleton>
      {new Array(isMobile ? 2 : 3).fill(null).map((_, i) => (
        <LinkSkeleton key={i} />
      ))}
    </StyledBlockSkeleton>
  );
};

export const LinksViewSkeleton = React.memo(() => (
  <StyledLinksViewSkeleton>
    <ButtonBlockSkeleton />
    <FolderBlockSkeleton />
    <LinkBlockSkeleton />
  </StyledLinksViewSkeleton>
));

export const LinksSectionSkeleton = () => {
  const { colors } = useTheme();

  return (
    <>
      {new Array(6).fill(null).map((_, i) => (
        <StyledBlockSkeleton key={i}>
          <SkeletonInner
            height={5.5}
            color={colors.SurfaceSubdued}
            borderRadius={0.5}>
            <SkeletonLinkUrl
              width={7.5}
              height={0.75}
              color={colors.LinkViewSkeleton}
              borderRadius={0.2}
            />
            <SkeletonLinkTitle
              width={6}
              height={0.875}
              color={colors.LinkViewSkeleton}
              borderRadius={0.2}
            />
            <SkeletonLinkPreviewImage
              width={6}
              height={4.375}
              color={colors.LinkViewSkeleton}
              borderRadius={0.375}
            />
            <SkeletonLinkUserAvatar
              width={0.9375}
              height={0.9375}
              color={colors.LinkViewSkeleton}
              borderRadius={0.2}
            />
          </SkeletonInner>
        </StyledBlockSkeleton>
      ))}
    </>
  );
};
