import type { DesktopApiType } from '../../Desktop/data/Desktop/types/Desktop.types';
import type { DatabaseTablesType } from '../Database.types';

export const DESKTOPS_TABLE_COLUMNS =
  '++id, createdAt, [workspaceId+shareable], [workspaceId+accessible], payload, *relatedTeams, *relatedAccounts';

export interface DesktopsTableType
  extends Omit<DatabaseTablesType, 'createdAt'> {
  workspaceId: string;
  createdAt: string;
  sourceData: DesktopApiType;
  relatedTeams: string[];
  relatedAccounts: string[];
  shareable: string; // IndexedDB cannot use boolean as a key
  accessible: string; // IndexedDB cannot use boolean as a key
}
