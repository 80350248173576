import {
  AppStoreAppForVaultApiType,
  AppVaultItemConfigApiType,
  KeysetApiType,
  PasswordStrength,
  VaultAccessApiType,
  VaultApiType,
  VaultItemApiType,
  VaultItemType,
} from './Encryption.types';
import { extractNodes } from '../../shared/api/api.utils';
import { AccountApiType } from '../User/User.types';
import { sortByCreationDate } from '../../shared/utils/list.utils';
import {
  checkExtensionInstalled,
  sendExtensionMessage,
} from '../../shared/utils/extension.utils';
import {
  GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP_EDGE,
  MEDIUM_PASSWORD_REGEXP,
  STRONG_PASSWORD_REGEXP,
} from './Encryption.constants';
import _flatten from 'lodash/flatten';
import { SelectVaultItemForAppResponse } from './Encryption.mutations';
import { format } from 'date-fns';
import { getAccountEntityKeysets } from '../User/User.utils';

/*
 * ENTITIES TRANSFORMATION
 * */

export const getAccountKeyset = (
  account: AccountApiType,
): KeysetApiType | undefined => {
  const accountKeysets = getAccountEntityKeysets(account);

  return accountKeysets.sort(sortByCreationDate)[0];
};

export const getAccountPreviousKeysets = (account: AccountApiType) => {
  const accountKeysets = getAccountEntityKeysets(account);

  return accountKeysets.sort(sortByCreationDate).slice(1);
};

// TODO: remove duplicate
export const getVaultApp = (
  vault?: VaultApiType,
): AppStoreAppForVaultApiType | null => {
  if (!vault) {
    return null;
  }
  const apps = getVaultApps(vault);
  return apps.find(app => app.hidden) || apps[0] || null;
};
export const getVaultApps = (
  vault?: VaultApiType,
): AppStoreAppForVaultApiType[] => {
  if (!vault) {
    return [];
  }
  const appVaultItemConfigs = getVaultItem(vault)?.appVaultItemConfigs;
  return extractNodes(appVaultItemConfigs)
    .map(({ app }) => app)
    .filter(Boolean)
    .sort((a, b) => a.fullName?.localeCompare(b.fullName));
};
export const getVaultAppIds = (vault: VaultApiType): string[] => {
  return getVaultApps(vault).map(app => app.id);
};
export const getVaultGroupAppId = (vault: VaultApiType): string => {
  return getVaultApp(vault)?.groupApp?.id || '';
};
// TODO: remove duplicate
export const getVaultItemConfig = (
  vault: VaultApiType,
): AppVaultItemConfigApiType => {
  const appVaultItemConfigs = extractNodes(vault.vaultItems)[0]
    ?.appVaultItemConfigs;
  return extractNodes(appVaultItemConfigs)[0];
};
export const getVaultItemConfigs = (
  vault: VaultApiType,
  appId?: string,
): AppVaultItemConfigApiType[] => {
  return _flatten(
    extractNodes(vault.vaultItems).map(vaultItem =>
      appId
        ? extractNodes(vaultItem.appVaultItemConfigs).filter(
            appConfig => !appId || appConfig.app.id === appId,
          )
        : extractNodes(vaultItem.appVaultItemConfigs),
    ),
  );
};
export const getVaultItemDesktopsMap = (
  vault: VaultApiType,
  appId?: string,
): Record<string, boolean> => {
  const vaultItemConfig = getVaultItemConfigs(vault, appId);
  return vaultItemConfig.reduce((accDesktopsMap, currAppConfig) => {
    return {
      ...accDesktopsMap,
      ...extractNodes(currAppConfig.appVaultItemConfigDesktops).reduce(
        (acc, curr) => ({ ...acc, [curr.desktop.id]: true }),
        {},
      ),
    };
  }, {});
};
export const getVaultItem = (vault: VaultApiType): VaultItemApiType => {
  const items = extractNodes(vault?.vaultItems);
  return items[0];
};
export const getVaultAccess = (
  vault: VaultApiType,
  keysetId?: string,
  withImplicitlyShared?: boolean,
): VaultAccessApiType | null => {
  const accesses = extractNodes(vault.vaultAccesses).filter(
    access =>
      (withImplicitlyShared ? true : !access.isImplicitlyShared) &&
      access.keyset.id === keysetId,
  );

  return accesses.length ? accesses[0] : null;
};
export const isVaultShared = (vault: VaultApiType): boolean => {
  return extractNodes(vault.vaultAccesses).some(access => access.shared);
};
export const isSharedWithKeysetId = (
  vault: VaultApiType,
  keysetId: string,
): boolean => {
  return extractNodes(vault.vaultAccesses).some(
    access => access.shared && keysetId === access.keyset.id,
  );
};
export const isVaultManuallyCreated = (vault: VaultApiType): boolean => {
  return getVaultItem(vault)?.type === VaultItemType.manual;
};
export const getVaultAccessKey = (
  vault: VaultApiType,
  keysetId: string,
): string | null => {
  const access = getVaultAccess(vault, keysetId);
  return access?.vaultKeyEncrypted || null;
};

export const getVaultLogin = (vault: VaultApiType): string | null => {
  return extractNodes(vault.vaultItems)[0]?.login;
};

export const getVaultPassword = (vault: VaultApiType): string | null => {
  return extractNodes(vault.vaultItems)[0]?.password;
};

export const getVaultIri = (shortId: string): string => {
  return '/vaults/' + shortId;
};

export const getVaultItemIri = (shortId: string): string => {
  return '/vault-items/' + shortId;
};

export const getKeysetIri = (shortId: string): string => {
  return '/keysets/' + shortId;
};

export const getAppConfigIri = (shortId: string): string => {
  return '/app-vault-item-configs/' + shortId;
};

export const getAppConfigDesktopIri = (shortId: string): string => {
  return '/app-vault-item-config-desktops/' + shortId;
};

export const makeNewAppConfigFromResponse = (
  response: SelectVaultItemForAppResponse | null | undefined,
  desktopId: string,
  appId: string,
) => {
  if (!response || !response.selectVaultItemForAppVaultItemConfigDesktop) {
    return null;
  }

  const { appVaultItemConfig, ...appVaultItemConfigDesktop } =
    response.selectVaultItemForAppVaultItemConfigDesktop
      .appVaultItemConfigDesktop;
  return {
    ...appVaultItemConfig,
    app: {
      id: appId,
    },
    appVaultItemConfigDesktops: {
      edges: [
        {
          node: {
            ...appVaultItemConfigDesktop,
            desktop: {
              id: desktopId,
            },
          },
          __typename: GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP_EDGE,
        },
      ],
    },
  };
};

/*
 * EXTENSION RELATED
 * */

export const getCEPH = (email: string): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    checkExtensionInstalled()
      .catch(() => reject('No extension installed'))
      .then(() => {
        sendExtensionMessage<string | null>(
          {
            getCEPH: {
              email,
            },
          },
          res => {
            resolve(res || null);
          },
        );
      });
  });
};

export const setCEPH = (email: string, hash: string): Promise<null> => {
  return new Promise((resolve, reject) => {
    checkExtensionInstalled()
      .catch(() => reject('No extension installed'))
      .then(() => {
        sendExtensionMessage(
          {
            setCEPH: {
              email,
              hash,
            },
          },
          () => {
            resolve(null);
          },
        );
      });
  });
};

export const clearCEPH = (): Promise<unknown> => {
  return checkExtensionInstalled().then(installed => {
    if (installed) {
      sendExtensionMessage({
        clearCEPH: true,
      });
    }
  });
};

export const passwordStrengthChecker = (PasswordParameter: string) => {
  if (STRONG_PASSWORD_REGEXP.test(PasswordParameter)) {
    return PasswordStrength.STRONG;
  } else if (MEDIUM_PASSWORD_REGEXP.test(PasswordParameter)) {
    return PasswordStrength.MEDIUM;
  } else {
    return PasswordStrength.WEAK;
  }
};

/*
 * Logs
 * */

export const logPwmInfo = (message: string, data?: any) => {
  if (window.DESKTOPCOM_LOG_PWM) {
    const text = `[${format(new Date(), 'hh:mm:ss.SSS')}] ${message}`;
    if (message) {
      console.log(`${text}: `, data);
    }
  }
};
