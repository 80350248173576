import { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import { WorkspaceStatisticsApiType } from '../../../../Workspace/Data/DataSource/WorkspaceApi.types';
import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';
import { AccountApiType } from '../../../../User/User.types';

export const getAccountsApi = (
  workspaceId: string,
): Promise<RestApiClientResponse<AccountApiType[]>> | undefined => {
  try {
    const shortWorkspaceId = getShortId(workspaceId);
    const restApiClient = getRestApiClient();
    return restApiClient!.fetch(`/workspace/${shortWorkspaceId}/accounts`);
  } catch (e) {
    console.error(e);
  }
};

export const getWorkspaceStatisticsApi = (
  workspaceId: string,
): Promise<RestApiClientResponse<WorkspaceStatisticsApiType>> | undefined => {
  try {
    const shortWorkspaceId = getShortId(workspaceId);
    const restApiClient = getRestApiClient();
    return restApiClient!.fetch(
      `/workspace/${shortWorkspaceId}/accounts/workspace-statistics`,
    );
  } catch (e) {
    console.error(e);
  }
};
