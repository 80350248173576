import styled, { css } from 'styled-components';
import { Button } from '../../Button/Button';

export const ConfirmModalContent = styled.div`
  padding: 20px;
  word-break: break-all;
  word-break: break-word;
`;

export const ConfirmModalControls = styled.div<{ dangerConfirm?: boolean }>`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  ${Button} ~ ${Button} {
    margin-left: 10px;

    ${({ dangerConfirm, theme }) =>
      dangerConfirm &&
      css`
        &:not([disabled]) {
          background-color: ${theme.colors.CriticalLight};
          &:hover {
            opacity: 0.8;
          }
        }
      `}
  }
`;
