import {
  StreamEventRequest,
  StreamTeamEvent,
} from '../../GeneralMercure.types';
import { mercureTeamEventToTeamApiType } from '../../../../Team/data/Team/utils/TeamMercure.utils';
import {
  deleteTeamInIDDB,
  putTeamToIDDB,
  updateTeamInIDDB,
} from '../../../../Team/data/Team/operations/TeamIDDB.operations';

export const teamRequest = (event: StreamTeamEvent) => {
  switch (event['@request']) {
    case StreamEventRequest.NEW:
      const newTeam = mercureTeamEventToTeamApiType(event);
      putTeamToIDDB(newTeam, event.workspace.id);
      break;
    case StreamEventRequest.UPDATE:
      const updatedTeam = mercureTeamEventToTeamApiType(event);
      updateTeamInIDDB(updatedTeam.id, team => ({
        ...team,
        ...updatedTeam,
      }));
      break;
    case StreamEventRequest.REMOVE:
      deleteTeamInIDDB(event.id);
      break;
    default:
      break;
  }
};
