import { database } from '../../../../../../Database';
import { DesktopLinksTableType } from '../../../../../../Database/DesktopLinksTable/DesktopLinksTable';

export const favoriteLinkRequestNew = (linkNode: DesktopLinksTableType) => {
  const cachedLink = database.desktopLinks.get(linkNode.id);
  database.desktopLinks.put({
    ...linkNode,
    ...cachedLink,
  });
};
