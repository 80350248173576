import { extractNodes } from '../../../../shared/api/api.utils';
import { getLongId } from '../../../../shared/utils/id';
import { database } from '../../../Database';
import { ChatConversationApiToChatConversationsTableRow } from '../../../Database/ChatConversationsTable/ChatConversationsTable.utils';
import { DesktopAppApiTypeToDesktopAppsTableRow } from '../../../Database/DesktopAppsTable/DesktopAppsTable.utils';
import { FolderApiTypeToDesktopFolderTableRow } from '../../../Database/DesktopFoldersTable/DesktopFoldersTable.utils';
import { LinksApiTypeToDesktopLinksTableRow } from '../../../Database/DesktopLinksTable/DesktopLinksTable.utils';
import {
  getDesktopAppsRest,
  getDesktopChatRest,
  getDesktopFolderLinksRest,
  getDesktopFoldersRest,
  getDesktopLinkByFolderIdApi,
  getDesktopLinksRest,
} from '../DataSource/DesktopApiDataSource';

export const getDesktopLinksRepository = async (
  desktopId: string,
  workspaceId: string,
  folderId?: string,
) => {
  let response;

  if (folderId) {
    response = await getDesktopFolderLinksRest(desktopId, folderId);
  } else {
    response = await getDesktopLinksRest(desktopId);
  }

  if (response.data?.desktopLinks.edges.length) {
    // Update dexie database with fetched links
    try {
      const linksRowsArray = extractNodes(response.data.desktopLinks).map(
        link => LinksApiTypeToDesktopLinksTableRow(link, workspaceId),
      );

      database.desktopLinks.bulkPut(linksRowsArray);
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};

export const getDesktopFoldersRepository = async (
  desktopId: string,
  workspaceId: string,
) => {
  const response = await getDesktopFoldersRest(desktopId);

  if (response.data?.desktopFolders.edges.length) {
    // Update dexie database with fetched folders
    try {
      const foldersRowsArray = extractNodes(response.data.desktopFolders).map(
        folder => FolderApiTypeToDesktopFolderTableRow(folder, workspaceId),
      );

      database.desktopFolders.bulkPut(foldersRowsArray);
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};

export const getDesktopAppsRepository = async (
  desktopId: string,
  workspaceId: string,
) => {
  const response = await getDesktopAppsRest(desktopId);

  if (response.data?.desktopApps.edges.length) {
    // Update dexie database with fetched folders
    try {
      const appsRowsArray = extractNodes(response.data.desktopApps).map(app =>
        DesktopAppApiTypeToDesktopAppsTableRow(app, desktopId, workspaceId),
      );
      database.desktopApps.bulkPut(appsRowsArray);
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};

export const getDesktopChatRepository = async (
  desktopId: string,
  workspaceId: string,
  accountId: string,
) => {
  const response = await getDesktopChatRest(desktopId);

  const { data } = response;

  if (data?.desktopChat.chatConversation) {
    // Update dexie database with desktop conversation
    try {
      const desktopConversationRow =
        ChatConversationApiToChatConversationsTableRow(
          data?.desktopChat.chatConversation,
          {
            workspaceId: getLongId('workspaces', workspaceId),
            accountId,
          },
        );

      database.chatConversations.put(desktopConversationRow);
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};

export const getDesktopLinksByFolderId = async (
  workspaceId: string,
  folderId: string,
) => {
  const response = await getDesktopLinkByFolderIdApi(workspaceId, folderId);

  const { data } = response;

  if (data?.links) {
    // Update dexie database with desktop conversation
    try {
      const linksRowsArray = extractNodes(data.links).map(link =>
        LinksApiTypeToDesktopLinksTableRow(link, workspaceId),
      );

      await database.desktopLinks.bulkPut(linksRowsArray);

      return {
        linksTotalCount: data.links.totalCount,
      };
    } catch (error) {
      console.error(error);
    }
  }
};
