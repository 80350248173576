import React, { FC, PropsWithChildren, useMemo, useRef } from 'react';
import { ButtonWithIconProps } from '../ButtonWithIcon/ButtonWithIcon';
import { CloseIcon, KebabIcon } from '../../icons';
import {
  ModalHeaderActionButton,
  ModalHeaderActions,
  ModalHeaderActionsContainer,
  StyledModalHeader,
  StyledModalHeaderData,
} from './ModalHeader.styled';
import { useMobile } from '../../hooks';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../Menu';
import { TooltipPlace } from '../Tooltip';
import { ModalThemeOverride } from '.';

export type ModalHeaderAction = {
  isActive?: boolean;
  hidden?: boolean;
  menuLabel: string;
  toolTip?: string;
} & Pick<ButtonWithIconProps, 'icon'>;

interface ModalHeaderProps {
  underline?: boolean;
  border?: boolean;
  onRequestClose: () => void;
  actionButtons?: ModalHeaderAction[];
  themeOverride?: ModalThemeOverride;
}

export const ModalHeader: FC<PropsWithChildren<ModalHeaderProps>> = ({
  children,
  underline = true,
  border = true,
  onRequestClose,
  themeOverride = ModalThemeOverride.LIGHT,
  actionButtons = [],
}) => {
  const isMobile = useMobile();
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const renderActions = useMemo(() => {
    if (isMobile) {
      return (
        <>
          <ModalHeaderActionButton ref={menuButtonRef} icon={KebabIcon} />
          <Menu
            trigger={menuButtonRef}
            width={180}
            vAlign={VerticalAlignment.BOTTOM}
            hAlign={HorizontalAlignment.RIGHT}>
            {actionButtons.map((actionProps, index) => {
              const { isActive, hidden, icon, menuLabel, ...restProps } =
                actionProps;

              if (hidden) return false;
              const MenuIcon = icon!;

              return (
                <MenuItem
                  key={index}
                  displayCheckMark={isActive}
                  isDisabled={isActive}
                  {...restProps}
                  icon={() => <MenuIcon />}>
                  {menuLabel}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      );
    }

    return actionButtons.map((actionProps, index) => {
      if (actionProps.hidden) return false;
      return (
        <ModalHeaderActionButton
          key={index}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={actionProps.toolTip}
          data-tooltip-place={TooltipPlace.bottom}
          {...actionProps}
        />
      );
    });
  }, [actionButtons, isMobile]);

  return (
    <StyledModalHeader
      underline={underline}
      border={border}
      themeOverride={themeOverride}
      data-testid="modal-header">
      <StyledModalHeaderData>{children}</StyledModalHeaderData>
      <ModalHeaderActionsContainer>
        {actionButtons.length > 0 && (
          <ModalHeaderActions>{renderActions}</ModalHeaderActions>
        )}
        <ModalHeaderActionButton
          icon={CloseIcon}
          iconSize={12}
          onClick={onRequestClose}
          data-testid="modal-close-icon"
        />
      </ModalHeaderActionsContainer>
    </StyledModalHeader>
  );
};
