import { RefObject, useCallback, useEffect, useState } from 'react';

export const useOnClickOutside = (
  ref: RefObject<any>,
  handler: (event: Event) => void,
  submenuRef?: RefObject<any>,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        (submenuRef?.current?.menuRef &&
          submenuRef.current.menuRef.contains(event.target))
      ) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, submenuRef]);
};

export const useMenuLevelControls = () => {
  const [visible, setVisible] = useState(false);
  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);
  return { visible, open, close };
};
