import { LinkLocale, LinkTranslation } from './i18n.types';

export const enLinkLocale: LinkLocale = {
  [LinkTranslation.formNameLabel]: 'Title',
  [LinkTranslation.formNamePlaceholder]: 'Link title',
  [LinkTranslation.formNameRequired]: 'Title is required',
  [LinkTranslation.formUrlLabel]: 'Link',
  [LinkTranslation.formUrlPlaceholder]: 'https://',
  [LinkTranslation.formUrlRequired]: 'Link is required',
  [LinkTranslation.formUrlInvalid]: 'Link is invalid',
  [LinkTranslation.formSubmitButton]: 'Save',
  [LinkTranslation.formCancelButton]: 'Cancel',

  [LinkTranslation.tagFormNameLabel]: 'Name',
  [LinkTranslation.tagFormNamePlaceholder]: ' ',
  [LinkTranslation.tagFormEmptyStateError]:
    'At least one tag should be selected',
  [LinkTranslation.tagFormsCreateButton]: 'Create',
  [LinkTranslation.tagFormsSaveButton]: 'Update',
  [LinkTranslation.tagFormCancelButton]: 'Cancel',

  [LinkTranslation.createLinkModalHeader]: 'Add new link to',
  [LinkTranslation.editLinkModalHeader]: 'Edit link',

  [LinkTranslation.createTagModalHeader]: 'Create tag',
  [LinkTranslation.manageTagModalHeader]:
    'Type to add a new tag or select from the list',
  [LinkTranslation.tagFormCreateTagOption]: 'Create tag',

  [LinkTranslation.linksViewFoldersLink]: 'Folders',
  [LinkTranslation.linksViewMoveTo]: 'Move to...',
  [LinkTranslation.linksViewEditLink]: 'Edit',
  [LinkTranslation.linksViewTagsLink]: 'Manage tags',
  [LinkTranslation.linksViewCopyLink]: 'Copy',
  [LinkTranslation.linksViewDeleteLink]: 'Remove',
  [LinkTranslation.linksViewDeleteLinkConfirmation]:
    'Are you sure? This action will remove the link for all users with access to this desktop.',
  [LinkTranslation.linksViewDeleteMultipleLinksConfirmation]:
    'Are you sure? This action will remove all {count} selected links for all users with access to this desktop.',
  [LinkTranslation.linksViewDeleteChatLinkConfirmation]:
    'Are you sure? This action will remove the link preview for all participants.',
  [LinkTranslation.linksViewLinkWarningLabel]: 'Warning',
  [LinkTranslation.linksViewLinkPotentialDanger]:
    'Potential danger: {abuseDescription}',
  [LinkTranslation.linksViewRequiresDirectAccess]: 'Requires direct access',

  [LinkTranslation.emptyHeader]: 'Start adding content to your Desktop!',
  [LinkTranslation.emptyMessage]:
    'Desktops let you keep your links organized in one place. All links will be listed here.',
  [LinkTranslation.emptyAddLinksButton]: 'Add new links',

  [LinkTranslation.emptyFavoritesHeader]:
    "You don't have any favorite links at the moment",
  [LinkTranslation.emptyFavoritesMessage]:
    'Click the heart icon on a link to add it to your favorites.',

  [LinkTranslation.importYourApps]:
    'Import your apps and bookmarks from a chromium based browser',
  [LinkTranslation.addNewLinks]: 'Add new links to your desktop',
  [LinkTranslation.importFromBrowserButton]: 'Import from browser',
  [LinkTranslation.untrustedLinkModalHeader]: 'Warning: this link is untrusted',
  [LinkTranslation.untrustedLinkDescription]:
    'We detected that the link you are about to open comes from an untrusted source, are you sure you want to open it?',
  [LinkTranslation.untrustedLinkReason]: 'Reason: {reason}',
  [LinkTranslation.untrustedLinkInformationLink]:
    'Learn more about untrusted links',
  [LinkTranslation.untrustedLinkOpenConfirm]: 'Proceed at my own risks',
  [LinkTranslation.untrustedLinkOpenCancel]: 'Back',

  [LinkTranslation.uncheckedLinkModalHeader]: 'Warning',
  [LinkTranslation.uncheckedLinkDescription]:
    'We have not analyzed the source of this link yet, are you sure you what to open it?',
  [LinkTranslation.uncheckedLinkInformationLink]:
    'Learn more about untrusted links',
  [LinkTranslation.uncheckedLinkOpenConfirm]: 'Proceed at my own risks',
  [LinkTranslation.uncheckedLinkOpenCancel]: 'Back',

  [LinkTranslation.searchLinksResultNoFound]: 'No results found',

  [LinkTranslation.emptyAppsStateHelpText]: 'Need help starting out? Start an',
  [LinkTranslation.emptyAppsStateOnboardingTour]: ' onboarding tour ',
  [LinkTranslation.emptyAppsStateFaqLink]:
    'or consult our  <a href="{faqUrl}" target="_blank" rel="noopener noreferrer">FAQ</a>.',

  [LinkTranslation.tooltipThreeDotsMore]: 'Link options',
  [LinkTranslation.tooltipLinkDetails]: 'Link details',
  [LinkTranslation.tooltipLinkCommentsCounter]:
    '{count} link {count, plural, one {comment} other {comments}}',
  [LinkTranslation.tooltipCreatedByName]: 'Added by {name}',
  [LinkTranslation.tooltipCreatedByYou]: 'You',
  [LinkTranslation.tooltipEditLinkDetails]: 'Edit link properties',
  [LinkTranslation.tooltipEditLinkTags]: 'Edit link tags',
  [LinkTranslation.tooltipAddLinkTags]: 'Add link tags',
  [LinkTranslation.tooltipHideLinkDetails]: 'Hide link details',
  [LinkTranslation.tooltipHideLinkComments]: 'Hide link comments',

  [LinkTranslation.linkCopiedMessage]: 'Link copied to your clipboard',
  [LinkTranslation.linksCopiedMessage]:
    '{count} links copied to your clipboard',

  [LinkTranslation.tagsSelectNoOptionsMessage]: 'Press enter to add a new tag',

  [LinkTranslation.unsupportedDroppedUrlFile]:
    '{fileName} is not a valid url file',

  [LinkTranslation.linkAlreadyExistInDesktop]:
    'A link with the url <strong>{url}</strong> already exists',
  [LinkTranslation.linkAlreadyExistInFolder]:
    'A link with the url <strong>{url}</strong> already exists in the folder <strong>{folderName}</strong>',
  [LinkTranslation.linkLimitFolderReached]:
    'Unable to add a link for <strong>{url}</strong>. Limit of max links per folder is reached.',

  [LinkTranslation.linkDetailsModalHeader]: 'Link details',
  [LinkTranslation.linkDetailsLoadingErrorMessage]:
    'Error while loading the link',

  [LinkTranslation.linkMenuItemLinkDetails]: 'Link details',
  [LinkTranslation.linkMenuItemLinkComments]: 'Link comments',

  [LinkTranslation.linkMenuItemLinkComment]: 'Comment',
  [LinkTranslation.linkMenuItemLinkPreview]: 'Preview',
  [LinkTranslation.linkMenuItemLinkSelect]: 'Select',
  [LinkTranslation.linkMenuItemLinkSelectAll]: 'Select all',
  [LinkTranslation.linkMenuItemLinkUnselect]: 'Unselect',
  [LinkTranslation.linkMenuItemLinkUnselectAll]: 'Unselect all',

  [LinkTranslation.linkMenuItemSignInWithGoogle]: 'Sign in with Google',

  [LinkTranslation.linkDetailsCommentsHeading]: 'Comments',

  [LinkTranslation.linkDetailsInformationHeading]: 'Details',
  [LinkTranslation.linkDetailsInformationPreview]: 'Link properties',
  [LinkTranslation.linkDetailsInformationLocation]: 'Location',
  [LinkTranslation.linkDetailsInformationLocationOpenDesktop]: 'Go to desktop',
  [LinkTranslation.linkDetailsInformationLocationDesktopLabel]: 'Desktop',
  [LinkTranslation.linkDetailsInformationTags]: 'Tags',
  [LinkTranslation.linkDetailsInformationTagsEmpty]:
    "This link doesn't have any tags",
  [LinkTranslation.linkDetailsInformationTimeline]: 'Activity',
  [LinkTranslation.linkDetailsInformationEditAction]: 'Edit',
  [LinkTranslation.linkDetailsInformationAddAction]: 'Add',

  [LinkTranslation.linkDetailsTitleButtonTooltipOpenLink]: 'Open link',
  [LinkTranslation.linkDetailsTitleButtonTooltipCopyLink]: 'Copy link',

  [LinkTranslation.linkHoverOpenLink]: 'Open link',
  [LinkTranslation.linkHoverLinkDetails]: 'Link details',

  [LinkTranslation.linkDuplicateTagMessage]: 'Link tag already exists',
};
