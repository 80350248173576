import {
  AccountNotificationsProps,
  UseAccountApiRepositoryProps,
} from './types/CurrentAccountRepository.types';
import {
  AccountApiType,
  AccountMercureInfoApiType,
  UnreadChatMessagesType,
  UnreadNotificationsCountApiType,
} from '../../../User/User.types';
import {
  getCurrentAccountApi,
  getCurrentAccountChatUnreadApi,
  getCurrentAccountChatUnreadByWorkspaceApi,
  getCurrentAccountInboxUnreadApi,
  getCurrentAccountMercureInfoApi,
  getCurrentAccountUnreadChatConferencesApi,
  getCurrentAccountUnreadNotificationsApi,
  getCurrentAccountWorkspaceInfoApi,
} from './dataSources/CurrentAccountApi.dataSources';
import {
  CURRENT_ACCOUNT_IDDB_CACHE_ID,
  GET_ACCOUNT_ACCESS_ERROR,
  GET_ACCOUNT_INTERNAL_SERVER_ERROR,
  GET_ACCOUNT_NETWORK_ERROR,
  GET_ACCOUNT_RESPONSE_NOT_OK_ERROR,
} from './CurrentAccount.constants';
import { captureException } from '@sentry/react';
import { database } from '../../../Database';
import { useCallback, useEffect, useState } from 'react';
import { SignUpStep } from '../../../Onboarding/Onboarding.types';
import delay from 'delay';
import { captureMessage } from '../../../ErrorInterceptor';
import { AccountApiTypeToCurrentAccountTableType } from './utils/CurrentAccountIDDB.utils';
import { useSingleEntityRepository } from '../../../../shared/hooks/repository/singleEntityRepository.hooks';
import { useCurrentAccountActions } from './CurrentAccount.actions';
import { RepositoryFetchPolicy } from '../../../../shared/hooks/repository/multipleEntityRepository.hooks';
import { WorkspaceInfoApiType } from '../../../WorkspaceInfo/data/WorkspaceInfo/types/WorkspaceInfo.types';

const FETCH_ACCOUNT_RETRY_DELAY = 3000; // 3s
const FETCH_ACCOUNT_RESPONSE_ERROR_RETRY_LIMIT = 3;

interface AccountRepositoryProps {
  fetchPolicy?: RepositoryFetchPolicy;
}

export const useCurrentAccountRepository = ({
  fetchPolicy = 'cache-and-network',
}: AccountRepositoryProps) => {
  const {
    getAccount,
    getAccountNotifications,
    getAccountWorkspaceInfo,
    getAccountMercureInfo,
  } = useAccountApiRepository();
  const { putAccountToIDDB } = useCurrentAccountActions();
  const [authenticatedAccount, setAuthenticatedAccount] = useState<
    AccountApiType | null | undefined
  >();

  const retryAccountData = useCallback(
    (timeout: number) => {
      let retries = 0;
      const fetchWithDelay = (): Promise<AccountApiType | null> => {
        retries++;
        return delay(timeout)
          .then(() => getAccount())
          .catch(error => {
            if (error.message === GET_ACCOUNT_NETWORK_ERROR) {
              return fetchWithDelay();
            }

            if (
              error.message === GET_ACCOUNT_ACCESS_ERROR ||
              error.message === GET_ACCOUNT_INTERNAL_SERVER_ERROR ||
              error.message === GET_ACCOUNT_RESPONSE_NOT_OK_ERROR
            ) {
              if (retries < FETCH_ACCOUNT_RESPONSE_ERROR_RETRY_LIMIT) {
                return fetchWithDelay();
              } else {
                captureMessage(
                  `[Auth.service] logging out after ${retries} retries with error ${error.message}`,
                );
                database.currentAccount.put(
                  AccountApiTypeToCurrentAccountTableType(null),
                );
              }
            }

            return null;
          });
      };

      return fetchWithDelay();
    },
    [getAccount],
  );

  const fetchAccountData = useCallback(
    async (
      prevAccount?: AccountApiType | null | undefined,
    ): Promise<AccountApiType | null | undefined> => {
      const account = await getAccount().catch(() => {
        return retryAccountData(FETCH_ACCOUNT_RETRY_DELAY);
      });

      if (!account) {
        setAuthenticatedAccount(null);
        return;
      }

      let workspaceInfo: WorkspaceInfoApiType[] | null = null;
      let mercureInfo: AccountMercureInfoApiType | undefined;

      if (
        account?.currentStep &&
        account.currentStep !== SignUpStep.select_plan
      ) {
        try {
          workspaceInfo = await getAccountWorkspaceInfo();

          if (prevAccount?.mercureInfo) {
            mercureInfo = prevAccount.mercureInfo;
          } else {
            mercureInfo = await getAccountMercureInfo();
          }
        } catch (error) {
          captureException(error);
        }
      }

      let accountNotifications: AccountNotificationsProps | null = null;
      if (
        account?.currentStep &&
        account.currentStep === SignUpStep.completed
      ) {
        try {
          accountNotifications = await getAccountNotifications();
        } catch (error) {
          captureException(error);
        }
      }

      const aggregatedAccountObject = {
        ...account,
        ...(workspaceInfo && { workspaceInfo }),
        ...(mercureInfo && { mercureInfo }),
        ...(accountNotifications && {
          ...accountNotifications,
        }),
      };

      putAccountToIDDB(aggregatedAccountObject);

      return aggregatedAccountObject;
    },
    [
      getAccount,
      getAccountMercureInfo,
      getAccountNotifications,
      getAccountWorkspaceInfo,
      retryAccountData,
      putAccountToIDDB,
    ],
  );

  const { entity, loading } = useSingleEntityRepository<AccountApiType | null>({
    fetchFunction: fetchAccountData,
    iddbQuerier: () =>
      database.currentAccount.get(CURRENT_ACCOUNT_IDDB_CACHE_ID),
    fetchPolicy,
  });

  useEffect(() => {
    setAuthenticatedAccount(entity);
  }, [entity]);

  const setAccountAsNull = useCallback(() => {
    setAuthenticatedAccount(null);
  }, []);

  return {
    account: authenticatedAccount,
    loading,
    fetchAccountData,
    setAccountAsNull,
  };
};

// TODO: Rework to a new Repository structure (this remains from the old one)
export const useAccountApiRepository = (): UseAccountApiRepositoryProps => {
  const getAccount = async (
    withWorkspace?: boolean,
  ): Promise<AccountApiType | null> => {
    const { data, status, ok } = await getCurrentAccountApi();

    const accountWorkspace = withWorkspace
      ? await getAccountWorkspaceInfo()
      : null;

    if (!status) {
      throw Error(GET_ACCOUNT_NETWORK_ERROR);
    }

    if (status === 401 || status === 403) {
      throw Error(GET_ACCOUNT_ACCESS_ERROR);
    }
    if (status === 500) {
      throw Error(GET_ACCOUNT_INTERNAL_SERVER_ERROR);
    }

    if (!ok) {
      throw Error(GET_ACCOUNT_RESPONSE_NOT_OK_ERROR);
    }

    return {
      ...data,
      ...(accountWorkspace && {
        workspaceInfo: accountWorkspace,
      }),
    };
  };

  const getAccountWorkspaceInfo = async () => {
    const { data: workspaceInfo } = await getCurrentAccountWorkspaceInfoApi();

    return workspaceInfo;
  };

  const getAccountNotifications =
    async (): Promise<AccountNotificationsProps> => {
      const unreadNotifications = await getAccountUnreadNotifications();
      const accountInboxUnreadApiResp = await getAccountInboxUnread();
      const unreadChatConferences = await getAccountUnreadChatConferences();
      const unreadChatMessages = await getAccountUnreadChatMessages();

      return {
        unreadNotifications,
        unreadChatMessages,
        unreadChatConferences,
        unreadMentions: accountInboxUnreadApiResp.mentions,
        unreadThreads: accountInboxUnreadApiResp.threads,
      };
    };

  const getAccountMercureInfo = async (): Promise<
    AccountMercureInfoApiType | undefined
  > => {
    try {
      const { data } = await getCurrentAccountMercureInfoApi();
      return data;
    } catch (err) {
      captureException(err);
      return;
    }
  };

  const getAccountUnreadNotifications = async (): Promise<
    UnreadNotificationsCountApiType[]
  > => {
    try {
      const { data: notifications } =
        await getCurrentAccountUnreadNotificationsApi();

      return notifications
        ? Object.entries(notifications).map(([key, value]) => ({
            workspace: key,
            count: value as number,
          }))
        : [];
    } catch (err) {
      captureException(err);
      return [];
    }
  };

  const getAccountInboxUnread = async () => {
    try {
      const { data: accountInbox } = await getCurrentAccountInboxUnreadApi();

      return {
        threads: Object.entries(accountInbox?.threads || {}).map(
          ([key, value]) => ({
            workspace: key,
            count: value,
          }),
        ),
        mentions: Object.entries(accountInbox?.mentions || {}).map(
          ([key, value]) => ({
            workspace: key,
            count: value,
          }),
        ),
      };
    } catch (err) {
      captureException(err);
      return {
        threads: [],
        mentions: [],
      };
    }
  };

  const getAccountUnreadChatConferences = async (): Promise<
    UnreadNotificationsCountApiType[]
  > => {
    try {
      const { data: unreadChatConferencesResp } =
        await getCurrentAccountUnreadChatConferencesApi();

      return Object.entries(unreadChatConferencesResp || {}).map(
        ([key, value]) => ({
          workspace: key,
          count: value,
        }),
      );
    } catch (err) {
      captureException(err);

      return [];
    }
  };

  const getAccountUnreadChatMessages = async (): Promise<
    UnreadChatMessagesType[]
  > => {
    try {
      const { data: accountChatUnreadResp } =
        await getCurrentAccountChatUnreadApi();

      return accountChatUnreadResp?.length
        ? accountChatUnreadResp.map(accountChatUnread => ({
            workspace: accountChatUnread.workspace,
            count: accountChatUnread.count,
            chatConversations: accountChatUnread.chatConversations,
          }))
        : [];
    } catch (err) {
      captureException(err);

      return [];
    }
  };

  const getAccountUnreadChatMessagesByWorkspace = async (
    workspaceId: string,
  ): Promise<UnreadChatMessagesType | null> => {
    try {
      const { data: accountChatUnreadResp } =
        await getCurrentAccountChatUnreadByWorkspaceApi(workspaceId);

      return accountChatUnreadResp
        ? {
            workspace: accountChatUnreadResp.workspace,
            count: accountChatUnreadResp.count,
            chatConversations: accountChatUnreadResp.chatConversations,
          }
        : null;
    } catch (err) {
      captureException(err);

      return null;
    }
  };

  return {
    getAccount,
    getAccountNotifications,
    getAccountWorkspaceInfo,
    getAccountMercureInfo,
    getAccountUnreadNotifications,
    getAccountInboxUnread,
    getAccountUnreadChatConferences,
    getAccountUnreadChatMessages,
    getAccountUnreadChatMessagesByWorkspace,
  };
};
