import { ConferenceCallType } from '../Conference/Conference.types';

export type CallStartedMessage = {
  domain: string;
  roomName: string;
  token: string;
  callType: ConferenceCallType;
  mercureToken: string | null;
  mercureTopics: string | null;
} & (
  | {
      type: 'CALL_CREATED';
      recipientName: string | null;
      recipientAvatarUrl: string | null;
    }
  | { type: 'CALL_JOINED' }
);

export type RefreshTokenMessage = {
  type: 'REFRESH_TOKEN';
};

export type TriggerMFAMessage = {
  type: 'TRIGGER_MFA';
};

export type LogoutMessage = {
  type: 'LOGOUT';
};

export type LoginMessage = {
  type: 'LOGIN';
  connection: string | null;
  appState: string | null;
  loginHint: string | null;
};

export type OpenInBrowserMessage = {
  type: 'OPEN_IN_BROWSER';
  url: string;
};

export type ErrorPageShownMessage = {
  type: 'ERROR_PAGE_SHOWN';
};

export type ReloadAppToastShownMessage = {
  type: 'RELOAD_APP_TOAST_SHOWN';
};

export type CephSubscribedMessage = {
  type: 'CEPH_SUBSCRIBED';
};

export type SetCEPHMessage = {
  type: 'SET_CEPH';
  accountId: string;
  CEPH: string;
};

export type MessageToNativeWrapper =
  | CallStartedMessage
  | RefreshTokenMessage
  | TriggerMFAMessage
  | LogoutMessage
  | LoginMessage
  | OpenInBrowserMessage
  | ErrorPageShownMessage
  | ReloadAppToastShownMessage
  | CephSubscribedMessage
  | SetCEPHMessage;

export type MessageExchangeableWithNativeWrapper = RefreshTokenMessage;

export type SetAccessTokenMessage = {
  type: 'SET_ACCESS_TOKEN';
  accessToken: string;
};

export type MfaCancelledMessage = {
  type: 'MFA_CANCELLED';
};

export type MessageFromNativeWrapper =
  | SetAccessTokenMessage
  | MfaCancelledMessage
  | SetCEPHMessage;

export type NativeWrapperContextType = {
  isNativeWrapperAvailable: boolean;
  sendMessageToNativeWrapper: (message: MessageToNativeWrapper) => boolean;
  callRefreshToken: () => Promise<string>;
  callTriggerMfa: () => Promise<string>;
  initialAccessToken: string | null;
  subscribeToMessages: (
    subscriber: (message: MessageFromNativeWrapper) => void,
  ) => { remove: () => void };
};

export enum NativePlatform {
  iOS = 'ios',
  Android = 'android',
}
