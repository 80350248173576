import { clearPersistedStorageCache } from '../PersistedStorage/PersistedStorage.utils';
import {
  LAST_OPENED_PATH_MAP_STORAGE_KEY,
  LAST_OPENED_WORKSPACE_STORAGE_KEY,
} from '../Workspace/Workspace.constants';
import { LAST_OPENED_CHAT } from '../Chat/Chat.constants';
import { LAST_OPENED_DESKTOPS_MAP_STORAGE_KEY } from '../Desktop/Desktop.constants';
import {
  REQUIRE_MFA_STORAGE_KEY,
  TRIGGER_MFA_VERIFICATION,
} from '../Onboarding/Onboarding.constants';
import { HIDE_PERMISSION_MODAL_STORAGE_KEY } from '../WebPush/WebPush.constants';
import { removeCookie } from '../../shared/utils/storage';
import { clearIndexedDB } from '../Database/Database.utils';

export const clearBrowserStorageData = async () => {
  try {
    localStorage.clear();
    sessionStorage.clear();
    await clearPersistedStorageCache();
    await clearIndexedDB();
    [
      LAST_OPENED_PATH_MAP_STORAGE_KEY,
      LAST_OPENED_CHAT,
      LAST_OPENED_WORKSPACE_STORAGE_KEY,
      LAST_OPENED_DESKTOPS_MAP_STORAGE_KEY,
      REQUIRE_MFA_STORAGE_KEY,
      HIDE_PERMISSION_MODAL_STORAGE_KEY,
      TRIGGER_MFA_VERIFICATION,
    ].forEach(key => removeCookie(key));
  } catch {}
};
