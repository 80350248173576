import { StreamFolderEvent } from '../../../GeneralMercure.types';
import { database } from '../../../../../Database';

export const folderRequestRemove = (event: StreamFolderEvent) => {
  event.id = event['@iri'];

  // remove folder as sub folder if applies
  database.desktopFolders
    .get(event.id)
    .then(dbFolder => {
      if (dbFolder?.parentId) {
        database.desktopFolders.get(dbFolder?.parentId).then(dbParentFolder => {
          if (dbParentFolder) {
            database.desktopFolders.update(dbParentFolder.id, {
              'sourceData.subFolderIds': [
                ...(dbParentFolder.sourceData.subFolderIds?.filter(
                  id => id !== dbFolder.id,
                ) || []),
              ],
            });
          }
        });
      }
    })
    .finally(() => {
      // remove the folder it self
      database.desktopFolders.delete(event.id);
    });
};
