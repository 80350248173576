import { NO_REST_API_CLIENT_ERROR } from '../../../../../shared/api/api.constants';
import { getRestApiClient } from '../../../../Api/Api.utils';
import { RequestMethod } from '../../../../Api/RestApiClient/RestApiClient.types';
import { DesktopApiType, DesktopCreateInputType } from '../types/Desktop.types';

export const createDesktopRestApi = (
  variables: DesktopCreateInputType,
): Promise<DesktopApiType> => {
  const restApiClient = getRestApiClient();

  if (!restApiClient) {
    return Promise.reject(NO_REST_API_CLIENT_ERROR);
  }

  return restApiClient
    .fetch(`/desktop`, RequestMethod.POST, variables)
    .then(({ data, ok }) => (ok ? data : Promise.reject(data)));
};
