import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const RoundedCheckmarkIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect width="20" height="20" rx="10" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7799 6.22001C15.9203 6.36063 15.9992 6.55126 15.9992 6.75001C15.9992 6.94876 15.9203 7.13938 15.7799 7.28001L8.52985 14.53C8.38922 14.6704 8.1986 14.7493 7.99985 14.7493C7.8011 14.7493 7.61047 14.6704 7.46985 14.53L4.21985 11.28C4.08737 11.1378 4.01525 10.9498 4.01867 10.7555C4.0221 10.5612 4.10081 10.3758 4.23823 10.2384C4.37564 10.101 4.56103 10.0223 4.75533 10.0188C4.94963 10.0154 5.13767 10.0875 5.27985 10.22L7.99985 12.94L14.7199 6.22001C14.8605 6.07956 15.0511 6.00067 15.2499 6.00067C15.4486 6.00067 15.6393 6.07956 15.7799 6.22001Z"
        fill="white"
      />
    </svg>
  );
});
