import {
  QueryHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';

import {
  GET_PAYMENT_COUPON,
  GET_PAYMENT_CUSTOMER_BY_WORKSPACE,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_PLANS,
  GET_REPEATED_PAYMENT_CUSTOMER_BY_WORKSPACE,
  GetPaymentCouponResponse,
  GetPaymentCouponVariables,
  GetPaymentCustomerByWorkspaceResponse,
  GetPaymentCustomerByWorkspaceVariables,
  GetPaymentMethodsResponse,
  GetPaymentMethodsVariables,
  GetPaymentPlansResponse,
  GetPaymentPlansVariables,
} from './Billing.queries';

import {
  ACTIVATE_SUBSCRIPTION_PAYMENT,
  ActivateSubscriptionPaymentResponse,
  ActivateSubscriptionPaymentVariables,
  ADD_PAYMENT_METHOD_TO_PAYMENT_CUSTOMER,
  AddPaymentMethodPaymentCustomerResponse,
  AddPaymentMethodPaymentCustomerVariables,
  CANCEL_DOWNGRADE_WORKSPACE,
  CancelDowngradeWorkspaceResponse,
  CancelDowngradeWorkspaceVariables,
  CREATE_PAYMENT_CUSTOMER,
  CreatePaymentCustomerResponse,
  CreatePaymentCustomerVariables,
  DELETE_PAYMENT_METHOD,
  DeletePaymentMethodResponse,
  DeletePaymentMethodVariables,
  DOWNGRADE_WORKSPACE,
  DowngradeWorkspaceResponse,
  DowngradeWorkspaceVariables,
  MakePaymentMethodDefaultPaymentCustomerResponse,
  MakePaymentMethodDefaultPaymentCustomerVariables,
  SET_DEFAULT_PAYMENT_METHOD,
  UPDATE_PAYMENT_CUSTOMER,
  UpdatePaymentCustomerResponse,
  UpdatePaymentCustomerVariables,
} from './Billing.mutations';
import { useRefreshableQuery } from '../Api/Api.hooks';

export const useGetPaymentMethodsQuery = (
  options?: QueryHookOptions<
    GetPaymentMethodsResponse,
    GetPaymentMethodsVariables
  >,
) =>
  useLazyQuery<GetPaymentMethodsResponse, GetPaymentMethodsVariables>(
    GET_PAYMENT_METHODS,
    options,
  );

export const useGetPaymentPlansQuery = (
  options?: QueryHookOptions<GetPaymentPlansResponse, GetPaymentPlansVariables>,
) =>
  useRefreshableQuery<GetPaymentPlansResponse, GetPaymentPlansVariables>(
    GET_PAYMENT_PLANS,
    options,
  );

export const useGetPaymentCouponQuery = (
  options?: QueryHookOptions<
    GetPaymentCouponResponse,
    GetPaymentCouponVariables
  >,
) =>
  useQuery<GetPaymentCouponResponse, GetPaymentCouponVariables>(
    GET_PAYMENT_COUPON,
    options,
  );

export const useGetPaymentCustomerByWorkspaceQuery = (
  options?: QueryHookOptions<
    GetPaymentCustomerByWorkspaceResponse,
    GetPaymentCustomerByWorkspaceVariables
  >,
) =>
  useQuery<
    GetPaymentCustomerByWorkspaceResponse,
    GetPaymentCustomerByWorkspaceVariables
  >(GET_PAYMENT_CUSTOMER_BY_WORKSPACE, options);

export const useGetRepeatedPaymentCustomerByWorkspaceQuery = (
  options?: QueryHookOptions<
    GetPaymentCustomerByWorkspaceResponse,
    GetPaymentCustomerByWorkspaceVariables
  >,
) =>
  useRefreshableQuery<
    GetPaymentCustomerByWorkspaceResponse,
    GetPaymentCustomerByWorkspaceVariables
  >(GET_REPEATED_PAYMENT_CUSTOMER_BY_WORKSPACE, options);

export const useCreatePaymentCustomerMutation = () =>
  useMutation<CreatePaymentCustomerResponse, CreatePaymentCustomerVariables>(
    CREATE_PAYMENT_CUSTOMER,
    { errorPolicy: 'all' },
  );

export const useUpdatePaymentCustomerMutation = () =>
  useMutation<UpdatePaymentCustomerResponse, UpdatePaymentCustomerVariables>(
    UPDATE_PAYMENT_CUSTOMER,
  );

export const useAddPaymentMethodPaymentCustomerMutation = () =>
  useMutation<
    AddPaymentMethodPaymentCustomerResponse,
    AddPaymentMethodPaymentCustomerVariables
  >(ADD_PAYMENT_METHOD_TO_PAYMENT_CUSTOMER);

export const useMakePaymentMethodDefaultPaymentCustomerMutation = () =>
  useMutation<
    MakePaymentMethodDefaultPaymentCustomerResponse,
    MakePaymentMethodDefaultPaymentCustomerVariables
  >(SET_DEFAULT_PAYMENT_METHOD);

export const useDeletePaymentMethodMutation = () =>
  useMutation<DeletePaymentMethodResponse, DeletePaymentMethodVariables>(
    DELETE_PAYMENT_METHOD,
  );

export const useDowngradeWorkspaceMutation = () =>
  useMutation<DowngradeWorkspaceResponse, DowngradeWorkspaceVariables>(
    DOWNGRADE_WORKSPACE,
  );

export const useCancelDowngradeWorkspaceMutation = () =>
  useMutation<
    CancelDowngradeWorkspaceResponse,
    CancelDowngradeWorkspaceVariables
  >(CANCEL_DOWNGRADE_WORKSPACE);

export const useActivateSubscriptionPaymentMutation = () =>
  useMutation<
    ActivateSubscriptionPaymentResponse,
    ActivateSubscriptionPaymentVariables
  >(ACTIVATE_SUBSCRIPTION_PAYMENT);
