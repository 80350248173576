import { AnnouncementLocale, AnnouncementTranslation } from './i18n.types';

export const enAnnouncementLocale: AnnouncementLocale = {
  [AnnouncementTranslation.announcementHeaderTitle]: `What's NEW`,
  [AnnouncementTranslation.announcement2FeaturesTitle]:
    '<a href="https://www.webtop.com" target="_blank" data-testid="desktop-url">Webtop.com</a> x OneDrive & Faster Navigation',
  [AnnouncementTranslation.announcement3FeaturesTitle]:
    '<a href="https://www.webtop.com" target="_blank" data-testid="desktop-url">Webtop.com</a> x OneDrive & Faster Navigation',
  [AnnouncementTranslation.announcement4FeaturesTitle]:
    'Customize Your Workspace To Fit YOUR Needs',
  [AnnouncementTranslation.announcement5FeaturesTitle]:
    'Improved video calls. Customize your workspace',

  // Anouncement2
  [AnnouncementTranslation.announcement2AppIntegrationsTitle]:
    'App Integrations.',
  [AnnouncementTranslation.announcement2AppIntegrationsDescriptionWithPermission]:
    '<span><em>Jira, Google, Trello, Asana, Dropbox, Notion, Salesforce, Hubspot, Mailchimp …</em><br>Connect your favorite apps to your Webtop.com workspace!</span><br><span>Enabling <a href="{featuresIntegrationsSupportUrl}" target="_blank" data-testid="features-integrations-support-url">app integrations</a> is a great way to minimize switching between apps while staying informed.</span><br>Head to Settings > App Integrations to set it up, or learn more <a href="{setUpAppIntegrationsSupportUrl}" rel="noopener" target="_blank" data-testid="set-up-app-integrations-support-url">here!</a>',
  [AnnouncementTranslation.announcement2AppIntegrationsDescriptionWithoutPermission]:
    '<span><em>Jira, Google, Trello, Asana, Dropbox, Notion, Salesforce, Hubspot, Mailchimp …</em><br>Connect your favorite apps to your Webtop.com workspace!</span><br><span>Enabling app integrations is a great way to minimize switching between apps while staying informed.</span><br>Head to Settings > App Integrations to set it up, or learn more <a href="{setUpAppIntegrationsSupportUrl}" rel="noopener" target="_blank" data-testid="set-up-app-integrations-support-url">here!</a>',

  [AnnouncementTranslation.announcement2PasswordManagementChangesTitle]:
    'Smarter Team Password Management.',
  [AnnouncementTranslation.announcement2PasswordManagementChangesDescription]:
    '<span>Get yourself a secure password management service that allows you to:<br><ul><li>Instantly see which passwords have been shared with what team member</li><li>Easily revoke shared passwords</li><li>Get and stay organized even as the business grows</li><li>Create group passwords</li></ul></span><br>But wait, you already have all of this baked into your Webtop.com workspace.<br><span>Activate your secure Password Manager in three easy steps:</span><br><span>Unlock Password Manager > Import passwords > Download the Browser Extension <a href="{announcement3SecondParagraphTitle}" rel="noopener" target="_blank" data-testid="download-browser-extension-support-url">(click here!)</a></span><br>Works across all your devices.',

  // Anouncement3
  [AnnouncementTranslation.announcement3FirstParagraphTitle]:
    'Find what you need quicker',
  [AnnouncementTranslation.announcement3FirstParagraphContent]:
    '<span>Introducing two new integrations: OneDrive and Hubspot!</span><span>Use the global search bar in your <a href="https://www.webtop.com" target="_blank" data-testid="desktop-url"> Webtop.com</a> workspace to conveniently search across your  <a href="{featuresIntegrationsSupportUrl}" target="_blank" data-testid="features-integrations-support-url">integrated apps</a> - perfect for optimizing efficiency & maintaining focus!</span><span>Head to Settings > App Integrations to turn them on, or learn more <a href="{setUpAppIntegrationsSupportUrl}" rel="noopener" target="_blank" data-testid="set-up-app-integrations-support-url">here!</a></span>',

  [AnnouncementTranslation.announcement3SecondParagraphTitle]:
    'Switching view within your workspace',
  [AnnouncementTranslation.announcement3SecondParagraphContent]:
    '<span>Navigate your workspace seamlessly & quickly with the new left global sidebar menu!</span><span>Now with added icon descriptions and a quick-view that is instantly shown just by hovering over one of the menu items.</span>',

  [AnnouncementTranslation.announcement3ThirdParagraphTitle]:
    'Sign up for our Newsletter to get all the latest news, offers and updates!',
  [AnnouncementTranslation.announcement3ThirdParagraphContent]:
    '<span>Sign-up field available at the bottom of the site <a href="https://www.webtop.com" target="_blank" data-testid="desktop-url">https://www.webtop.com</a> </span>',

  // Anouncement4
  [AnnouncementTranslation.announcement4FirstParagraphTitle]:
    'Turn ON/OFF certain functionality',
  [AnnouncementTranslation.announcement4FirstParagraphContent]:
    '<span>You’re now able to let your dashboard view better reflect how you primarily use your Webtop.com account by easily removing features that you don’t use.</span><span>How?</span><span>Head to Settings > Workspace Settings > Use the toggle to turn on/off feature packages, or learn more <a href="{supportLink}" target="_blank">here</a></span>',

  [AnnouncementTranslation.announcement4SecondParagraphTitle]:
    'Boost your current workspace',
  [AnnouncementTranslation.announcement4SecondParagraphContent]:
    '<span>Want all functions to remain turned on - that’s awesome too. Don’t forget to enable new app integrations as they are continuously added, to supercharge your global search!</span><span>Settings > Select Workspace > App Integrations, or learn more <a href="{featuresIntegrationsSupportUrl}" target="_blank" data-testid="features-integrations-support-url">here</a></span>',

  [AnnouncementTranslation.announcement4ThirdParagraphTitle]:
    'Sign up for our Newsletter to get all the latest news, offers and updates!',
  [AnnouncementTranslation.announcement4ThirdParagraphContent]:
    '<span>Sign-up field available at the bottom of the site <a href="https://www.webtop.com" target="_blank" data-testid="desktop-url">https://www.webtop.com</a> </span>',

  // Announcement5 Rebranding
  [AnnouncementTranslation.announcement5FirstParagraphTitle]:
    'New version of the calling feature',
  [AnnouncementTranslation.announcement5FirstParagraphContent]:
    'The calling feature for video and audio calls has been improved in this latest version. Enjoy faster connections and crisp full HD video calls.',

  [AnnouncementTranslation.announcement5SecondParagraphTitle]:
    'App Integrations',
  [AnnouncementTranslation.announcement5SecondParagraphContent]:
    'Don’t forget to enable new app integrations as they are continuously added, to supercharge your global search! Learn more <a href="{supportLink}" target="_blank" data-testid="features-integrations-support-url">here</a>',

  [AnnouncementTranslation.announcement5ThirdParagraphTitle]:
    'Turn ON/OFF features for your workspace',
  [AnnouncementTranslation.announcement5ThirdParagraphContent]:
    '<span>Want to add or remove Webtop communication features, or just use the communication feature?</span><span>Head to Settings > Workspace Settings > Use the toggle to turn on/off features.</span><span>Read more about this <a href="{supportLink}" target="_blank" data-testid="features-integrations-support-url">here</a></span>',

  [AnnouncementTranslation.announcement5FourthParagraphContent]:
    '<span>Sign up for our Newsletter to get all the latest news, offers and updates!</span></span><span>Sign-up field available at the bottom of the site <a href="https://www.webtop.com" target="_blank" data-testid="desktop-url">https://www.webtop.com</a> </span>',
};
