import {
  GRAPHQL_TYPENAME_NOTIFICATION,
  GRAPHQL_TYPENAME_NOTIFICATION_EDGE,
  GRAPHQL_TYPENAME_NOTIFICATION_MESSAGE,
} from './Notifications.constants';

export enum NotificationType {
  DESKTOP_APP_ADDED = 'desktop.app.added',
  DESKTOP_APP_REMOVED = 'desktop.app.removed',
  DESKTOP_LINK_ADDED = 'desktop.link.added',
  DESKTOP_LINK_ABUSED = 'desktop.link.abused',
  DESKTOP_LINK_REMOVED = 'desktop.link.removed',
  DESKTOP_USER_ADDED = 'desktop.user.added',
  DESKTOP_CHAT_ENABLED = 'desktop.chat.enabled',
  USER_WORKSPACE_CHANGED_ROLE = 'user.workspace.changed_role',
  USER_REMOVED_FROM_DESKTOP = 'user.desktop.removed',
  USER_DESKTOP_ADDED = 'user.desktop.added',
  USER_TEAM_ADDED = 'user.team.added',
  USER_TEAM_REMOVED = 'user.team.removed',
  USER_BOOKMARK_IMPORT_FINISHED = 'user.bookmark.import_finished',
  USER_CHAT_MENTIONED = 'user.chat.mentioned',
  USER_CHAT_THREAD = 'user.chat.thread',
  PAYMENT_NEW_INVOICE = 'payment.new_invoice',
  PAYMENT_FAILED = 'payment.failed',
  WORKSPACE_CUSTOM_APP_REMOVED = 'workspace.custom_app.removed',
  PAYMENT_INVALID_VAT = 'payment.invalid_vat',
  PASSWORD_RECOVERY_REQUEST_CREATED = 'password.recovery.request.created',
  PASSWORD_RECOVERY_REQUEST_ACCEPTED = 'password.recovery.request.accepted',
  PASSWORD_RECOVERY_REQUEST_DECLINED = 'password.recovery.request.declined',
  SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_BEFORE_START = 'schedule_chat_conference.notification.before_start',
  SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_INVITED_MEMBER = 'schedule_chat_conference.notification.invited_member',
  SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_UPDATED = 'schedule_chat_conference.notification.updated',
  SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_CANCELED = 'schedule_chat_conference.notification.canceled',
  VAULT_ITEM_SHARED_WITH_YOU = 'vault_item.shared.with_you',
  VAULT_ITEM_SHARED_REMOVED_FROM_YOU = 'vault_item.shared.removed_from_you',
  TEAM_DESKTOP_ADDED = 'team.desktop.added',
  TEAM_DESKTOP_REMOVED = 'team.desktop.removed',
}

export enum NotificationStatus {
  NEW = 'NEW',
  READ = 'READ',
}

export interface NotificationsControls {
  toggleNotifications: () => void;
  closeNotifications: () => void;
  keepNotificationsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  keepOpen: boolean;
}

export interface NotificationApiType {
  id: string;
  _id: string;
  message: NotificationMessageApiType;
  status: NotificationStatus;
  createdAt: string;
  __typename: typeof GRAPHQL_TYPENAME_NOTIFICATION;
}

export interface NotificationEdgeApiType {
  node: NotificationApiType;
  __typename: typeof GRAPHQL_TYPENAME_NOTIFICATION_EDGE;
}

export interface NotificationMessageApiType {
  id: string;
  fields: NotificationMessageFields;
  type: NotificationType;
  __typename: typeof GRAPHQL_TYPENAME_NOTIFICATION_MESSAGE;
}

export interface NotificationMessageBaseFields {
  notification_image: string;
  context_image?: string;
}

/**
 * Desktop related notification types
 */
export interface NotificationMessageDesktopBaseFields
  extends NotificationMessageBaseFields {
  account_first_name: string;
  account_last_name: string;
  desktop_id: string;
  desktop_name: string;
}

export interface NotificationMessageDesktopAppFields
  extends NotificationMessageDesktopBaseFields {
  app_name: string;
  app_id: string;
}

export interface NotificationMessageDesktopLinkFields
  extends NotificationMessageDesktopBaseFields {
  link_name: string;
  link_id: string;
  link_iri: string;
  link_url: string;
}

export interface NotificationMessageDesktopLinkAbusedFields
  extends NotificationMessageDesktopLinkFields {
  folder_name: string;
  abuse_description: string;
}

export interface NotificationMessageDesktopUserAddedFields
  extends NotificationMessageDesktopBaseFields {}

export interface NotificationMessageDesktopChatEnabledFields
  extends NotificationMessageDesktopBaseFields {}

/**
 * Workspace related notification types
 */
export interface NotificationMessageWorkspaceCustomAppRemoved
  extends NotificationMessageBaseFields {
  app_name: string;
  account_first_name: string;
  account_last_name: string;
  by_account_last_name: string;
  workspace_name: string;
}

/**
 * Password Manager related notification types
 */

export interface NotificationMessageVaultAccessSharedWithYouFields
  extends NotificationMessageBaseFields {
  vault_id: string;
  account_first_name: string;
  account_last_name: string;
  vault_item_url: string;
  app_name: string;
}

export interface NotificationMessageVaultAccessSharedRemovedFromYouFields
  extends NotificationMessageBaseFields {
  vault_id: string;
  account_first_name: string;
  account_last_name: string;
  app_name: string;
}

export interface NotificationMessagePasswordRecoveryRequestCreated
  extends NotificationMessageBaseFields {
  request_id: string;
  requested_by_first_name: string;
  requested_by_id: string;
  requested_by_last_name: string;
  requested_by_username: string;
}

/**
 * Conference related
 */
export interface NotificationMessageScheduledConferenceBeforeStart
  extends NotificationMessageBaseFields {
  title: string;
  description: string;
  startAt: string;
  endAt: string;
  created_by_fullname: string;
  created_by_id: string;
  timezone: string;
  chat_conference_url: string;
  chat_conference_id: string;
  how_many_minutes_before_start: number;
}

export interface NotificationMessageScheduledConferenceInvitedMember
  extends NotificationMessageBaseFields {
  title: string;
  description: string;
  startAt: string;
  endAt: string;
  created_by_fullname: string;
  created_by_id: string;
  timezone: string;
  chat_conference_url: string;
  schedule_chat_conference_id: string;
}

export interface NotificationMessageScheduledConferenceUpdated
  extends NotificationMessageBaseFields {
  title: string;
  description: string;
  startAt: string;
  endAt: string;
  created_by_fullname: string;
  created_by_id: string;
  timezone: string;
  schedule_chat_conference_id: string;
  start_at_human: string;
  is_repeating: boolean;
}

export interface NotificationMessageScheduledConferenceCanceled
  extends NotificationMessageBaseFields {
  title: string;
  description: string;
  startAt: string;
  endAt: string;
  canceled_by: string;
  canceled_by_id: string;
  timezone: string;
  schedule_chat_conference_id: string;
  start_at_human: string;
  is_repeating: boolean;
}

export interface NotificationMessageTeamDesktopAdded
  extends NotificationMessageBaseFields {
  substance_type: string;
  team_id: string;
  team_name: string;
  desktop_id: string;
  desktop_name: string;
  by_account_id: string;
  by_account_username: string;
  by_account_last_name: string;
  by_account_first_name: string;
}

export interface NotificationMessageTeamDesktopRemoved
  extends NotificationMessageBaseFields {
  substance_type: string;
  team_id: string;
  team_name: string;
  desktop_id: string;
  desktop_name: string;
  by_account_id: string;
  by_account_username: string;
  by_account_last_name: string;
  by_account_first_name: string;
}

/**
 * User related notification types
 */
export interface NotificationMessageUserChangeRoleFields
  extends NotificationMessageBaseFields {
  workspace_id: string;
  workspace_name: string;
  group_label: string;
}

export interface NotificationMessageUserRemovedFromDesktop
  extends NotificationMessageBaseFields {
  desktop_name: string;
}

export interface NotificationMessageUserAddedToDesktop
  extends NotificationMessageBaseFields {
  account_first_name: string;
  by_account_first_name: string;
  account_last_name: string;
  desktop_id: string;
  desktop_name: string;
}

export interface NotificationMessageUserAddedToTeam
  extends NotificationMessageBaseFields {
  account_first_name: string;
  account_last_name: string;
  team_name: string;
}

export interface NotificationMessageUserRemovedFromTeam
  extends NotificationMessageBaseFields {
  account_first_name: string;
  account_last_name: string;
  team_name: string;
}

export interface NotificationMessageUserBookmarksImportFinished
  extends NotificationMessageBaseFields {
  bookmark_count: number;
  desktop_id: string;
  desktop_name: string;
  failure_count: number;
  imported_count: number;
  success: boolean;
}

export interface NotificationMessageUserChatMentionedFields
  extends NotificationMessageBaseFields {
  message_id: string;
  message_text: string;
  conversation_id?: string;
  conversation_title: string;
  conversation_type?: string;
  account_id: string;
  account_username: string;
  account_last_name: string;
  account_first_name: string;
  parent_message_id?: string;
  parent_message_created_at?: string;
  link_iri?: string;
  link_id?: string;
}

export type NotificationMessageFields =
  | NotificationMessageDesktopAppFields
  | NotificationMessageDesktopLinkFields
  | NotificationMessageDesktopLinkAbusedFields
  | NotificationMessageDesktopUserAddedFields
  | NotificationMessageWorkspaceCustomAppRemoved
  | NotificationMessageUserChangeRoleFields
  | NotificationMessageUserRemovedFromDesktop
  | NotificationMessageUserAddedToDesktop
  | NotificationMessageUserAddedToTeam
  | NotificationMessageUserRemovedFromTeam
  | NotificationMessagePasswordRecoveryRequestCreated
  | NotificationMessageScheduledConferenceBeforeStart
  | NotificationMessageScheduledConferenceInvitedMember
  | NotificationMessageUserChatMentionedFields
  | NotificationMessageScheduledConferenceUpdated
  | NotificationMessageScheduledConferenceCanceled
  | NotificationMessageVaultAccessSharedWithYouFields
  | NotificationMessageVaultAccessSharedRemovedFromYouFields
  | NotificationMessageTeamDesktopAdded
  | NotificationMessageTeamDesktopRemoved;
