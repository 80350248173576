import {
  GRAPHQL_TYPENAME_CHAT_CONVERSATION,
  GRAPHQL_TYPENAME_CHAT_MESSAGE,
} from './Chat.constants';
import { ConferenceCallType } from '../Conference/Conference.types';
import {
  FileAssetId,
  ImageAssetId,
  OriginalVideoMetaData,
  VideoMetaData,
} from '../Asset/Asset.types';
import { LinkApiType, LinkDocumentMetaData } from '../Link/Link.types';

export enum ConversationType {
  desktop = 'desktop',
  private = 'private',
  group = 'group',
  schedule = 'schedule',
  pending = 'pending',
  appIntegration = 'app_integration',
  link = 'link',
}

export enum MessageType {
  Message = 'message',
  Draft = 'draft',
  ThreadMessage = 'thread_message',
  Conference = 'conference',
  System = 'system',
}

export interface ChatConversationApiType {
  __typename?: typeof GRAPHQL_TYPENAME_CHAT_CONVERSATION;
  id: string;
  _id: string;
  type: ConversationType;
  users: string[];
  conversationTitle: string;
  lastMessageAt: string | null;
  createdAt: string;
  pendingEmails: string[];
  desktopIri: string | null;
  accountsThatPinned: AccountsThatPinnedApiType[];
  createdByIri: string;
  accountsThatHidden: AccountsThatHiddenApiType[];
}

export interface ChatConversationRestApiType {
  '@id': string;
  accountsThatPinned: AccountsThatPinnedApiType[];
  accountsThatHidden: AccountsThatHiddenApiType[];
  conversationTitle: string;
  createdAt: string;
  createdByIri: string;
  desktopIri: string | null;
  lastMessageAt: string | null;
  pendingEmails: string[];
  type: ConversationType;
  users: string[];
}

export interface ChatMessageRestApiType {
  '@id': string;
  accountIri: string;
  context: {
    assets: string[];
  };
  conversationIri: string;
  createdAt: string;
  id: string;
  isLinkComment: boolean;
  message: string;
  microtimeAt?: string;
  modified: boolean;
  modifiedAt: string;
  origin: string;
  parentChatMessageIri: string | null;
  plainTextMessage: string;
  reactions: ChatMessageReactionApiType[];
  seenBy: string[];
  threadMessagesCount: number;
  type: MessageType;
  workspaceIri: string;
}

export interface ChatConversationInternalType {
  id: string;
  workspaceId: string;
  title: string;
  type: ConversationType;
  userIds: string[];
  lastMessageAt: string | null;
  createdAt: string;
  createdById: string;
  pendingEmails: string[];
  desktopId: string | null;
  pinnedAt: string | null;
  isHidden: boolean;
  isHiddenAt: string | null;
}

export interface AccountsThatPinnedApiType {
  accountIri: string;
  pinnedAt: string;
}

export interface AccountsThatHiddenApiType {
  accountIri: string;
  hiddenAt: string;
}

export interface SearchChatConversationApiType {
  id: string;
  chatConversation: ChatConversationApiType;
  hitMessage: ChatMessageApiType;
}

export interface ChatBaseMessageApiType {
  __typename?: typeof GRAPHQL_TYPENAME_CHAT_MESSAGE;
  id: string;
  type: MessageType;
  accountIri: string;
  createdAt: string;
  microtimeAt?: string;
  modified: boolean;
  threadMessagesCount: number;
  reactions: ChatMessageReactionApiType[];
  parentChatMessageIri: string;
  seenBy: string[];
  lastMessageInThreadAt: string | null;
  threadMessagesAccounts: string[];
  isFirstUnread?: boolean;
  isOptimistic?: boolean;
  linkData?: LinkApiType | null;
}

export type ChatMessageReactionInternalType = {
  emoji: string;
  userIds: string[];
};

export interface BaseChatMessageInternalType {
  id: string;
  optimisticId?: string;
  conversationId: string;
  parentId: string | null | undefined;
  accountIri: string | null;
  type: MessageType;
  authorId: string | null;
  createdAt: Date;
  isModified: boolean;
  microtimeAt?: string;
  isSeen: boolean;
  threadMessagesCount: number;
  reactions: ChatMessageReactionInternalType[];
  message: string;
  seenBy: string[];
  isFirstUnread: boolean;
  isOptimistic?: boolean;
  lastMessageInThreadAt: Date | null;
  threadMessagesAccounts: string[];
  linkData?: LinkApiType | null;
}

export interface ChatTextMessageInternalType
  extends BaseChatMessageInternalType {
  type: MessageType.Message | MessageType.ThreadMessage;
  context: ChatTextMessageContext;
}

export interface ChatSystemMessageInternalType
  extends BaseChatMessageInternalType {
  authorId: null;
  type: MessageType.System;
  context: {};
}

export interface ChatConferenceMessageInternalType
  extends BaseChatMessageInternalType {
  type: MessageType.Conference;
  context: ChatConferenceMessageContext;
}

export type ChatMessageInternalType =
  | ChatTextMessageInternalType
  | ChatSystemMessageInternalType
  | ChatConferenceMessageInternalType;

export interface ChatMessageAssetApiType {
  assetIri: FileAssetId | ImageAssetId;
  filename: string;
  hash: string;
  mimeType: string;
  size: string;
  url: string;
  contentUrl: string;
  metadata: LinkDocumentMetaData | VideoMetaData | OriginalVideoMetaData | null;
  dimensions: string;
}

export interface ChatTextMessageContext {
  assets?: ChatMessageAssetApiType[];
  links?: string[];
  mentions?: { [key: string]: string };
}

export interface ChatTextMessageApiType extends ChatBaseMessageApiType {
  type: MessageType.Message | MessageType.ThreadMessage;
  message: string;
  context: ChatTextMessageContext;
}

export interface ChatSystemMessageApiType
  extends Omit<ChatBaseMessageApiType, 'accountIri'> {
  type: MessageType.System;
  message: string;
  context: [];
  accountIri: null;
}

export interface ChatConferenceMessageContext {
  callType: ConferenceCallType;
  chatConferenceIri: string;
  active: boolean;
  participants: string[];
  participants_total?: string[];
}

export interface ChatConferenceMessageContextApiType {
  callType: ConferenceCallType;
  chatConferenceIri: string;
  active: boolean;
  participants: string[];
  participants_total?: string[];
}

export interface ChatConferenceMessageApiType extends ChatBaseMessageApiType {
  type: MessageType.Conference;
  message: null;
  context: ChatConferenceMessageContextApiType;
}

export type ChatMessageApiType =
  | ChatTextMessageApiType
  | ChatConferenceMessageApiType
  | ChatSystemMessageApiType;

export type DraftChatMessageApiType = Pick<
  ChatTextMessageApiType,
  'message' | 'context'
> & { conversationIri: string | null; type: MessageType.Draft };

export type ChatMessageReactionApiType = {
  emoji: string;
  accounts: string[];
};

export enum SpecialMentionType {
  Here = 'here',
  Channel = 'channel',
}

export type SpecialMention = {
  type: SpecialMentionType;
};

export enum ConversationListSections {
  pinned = 'pinned',
  desktop = 'desktop',
  private = 'private',
  appIntegrations = 'appIntegrations',
}

export interface ConversationsSortedByGroups {
  [ConversationListSections.pinned]: ChatConversationInternalType[];
  [ConversationListSections.desktop]: ChatConversationInternalType[];
  [ConversationListSections.private]: ChatConversationInternalType[];
  [ConversationListSections.appIntegrations]: ChatConversationInternalType[];
}

export type ExpandedConversationsType = ChatConversationInternalType | string;

export type ChatItemType =
  | (ChatMessageInternalType & {
      secondary: boolean;
    })
  | {
      dateDelimiter: string;
    };

export interface ChatConversationUserContext {
  workspaceId: string;
  accountId: string;
}
