import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const GoogleDocsIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      data-testid="document-google-docs-icon"
      {...props}>
      <rect x="3" y="3" width="18" height="18" rx="1.688" fill="#4285F4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.375 8.625v-2.25h11.25v2.25H6.375zm0 4.5v-2.25h11.25v2.25H6.375zm0 2.25v2.25h7.313v-2.25H6.375z"
        fill="#fff"
      />
    </svg>
  );
});
