import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ShieldIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.077 2.563C12.0269 2.5468 11.9731 2.5468 11.923 2.563L3.673 5.24C3.62278 5.2561 3.57897 5.28772 3.54788 5.33031C3.51679 5.37291 3.50003 5.42427 3.5 5.477V10.5C3.5 15.961 6.78 19.983 11.93 21.926C11.9751 21.9429 12.0249 21.9429 12.07 21.926C17.22 19.983 20.5 15.961 20.5 10.5V5.476C20.4998 5.42331 20.483 5.37202 20.4519 5.32945C20.4209 5.28689 20.3771 5.25523 20.327 5.239L12.077 2.563ZM11.46 1.137C11.811 1.02314 12.189 1.02314 12.54 1.137L20.79 3.812C21.1416 3.92605 21.448 4.14848 21.6654 4.44741C21.8827 4.74633 21.9999 5.1064 22 5.476V10.5C22 16.69 18.23 21.205 12.599 23.33C12.2129 23.4755 11.7871 23.4755 11.401 23.33C5.771 21.204 2 16.69 2 10.5V5.476C2 4.716 2.49 4.046 3.21 3.812L11.46 1.137ZM13 12.232C13.3813 12.0119 13.6792 11.6721 13.8477 11.2653C14.0162 10.8586 14.0457 10.4076 13.9318 9.98236C13.8178 9.55711 13.5667 9.18134 13.2175 8.91332C12.8682 8.64531 12.4403 8.50002 12 8.5C11.5597 8.50002 11.1318 8.64531 10.7825 8.91332C10.4333 9.18134 10.1822 9.55711 10.0682 9.98236C9.95429 10.4076 9.98384 10.8586 10.1523 11.2653C10.3208 11.6721 10.6187 12.0119 11 12.232V15C11 15.2652 11.1054 15.5196 11.2929 15.7071C11.4804 15.8946 11.7348 16 12 16C12.2652 16 12.5196 15.8946 12.7071 15.7071C12.8946 15.5196 13 15.2652 13 15V12.232Z"
        fill="currentColor"
      />
    </svg>
  );
});
