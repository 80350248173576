import React, { FC, memo } from 'react';
import { SpinnerTitle, StyledSpinner } from './Spinner.styled';
import { DesktopLogoDarkIcon, DesktopLogoIcon } from '../../icons';
import { FormattedMessage } from 'react-intl';
import { Translation } from '../../../domains/Intl/i18n';

export interface SpinnerProps {
  size?: number;
  containerHeight?: number;
  darkIcon?: boolean;
  description?: Translation;
}

export const Spinner: FC<SpinnerProps> = memo(
  ({ size = 32, containerHeight, darkIcon, description }) => (
    <StyledSpinner height={containerHeight} data-testid="spinner-container">
      {darkIcon ? (
        <DesktopLogoDarkIcon width={size} height={size} data-testid="spinner" />
      ) : (
        <DesktopLogoIcon width={size} height={size} data-testid="spinner" />
      )}
      {description && (
        <FormattedMessage id={description} tagName={SpinnerTitle} />
      )}
    </StyledSpinner>
  ),
);
