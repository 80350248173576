import styled, { css } from 'styled-components';
import { Button } from '../../../shared/components/Button/Button';

export const Section = styled.div`
  padding: 0 1.25rem;

  ${({ theme: { breakpoints, colors } }) => breakpoints.md`
      padding: 0;
  `}

  ul {
    padding: 0;
  }
  & ~ & {
    margin-top: 42px;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const SectionHeaderTitle = styled.h3`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 1rem;
  line-height: 18px;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const SectionDescription = styled.p<{ noBottomMargin?: boolean }>`
  margin-bottom: 20px;
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.875rem;
  line-height: 18px;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  ${({ noBottomMargin }) =>
    noBottomMargin &&
    css`
      margin-bottom: 0;
    `}
`;

export const SectionHeaderGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    display: block;
  `}
`;

export const SectionHeaderButton = styled(Button)`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 18px;
  color: ${p => p.theme.colors.Primary};

  & ~ & {
    margin-left: 16px;
  }
`;

export const SectionBody = styled.div<{ noPadding?: boolean }>`
  ${({ noPadding, theme }) =>
    !noPadding &&
    css`
      padding: 1.125rem 1rem;
    `}
  background-color: ${p => p.theme.colors.Surface};
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  border-radius: 0.5rem;
`;

export const MFASectionBody = styled.div<{ noPadding?: boolean }>`
  ${({ noPadding, theme }) =>
    !noPadding &&
    css`
      padding: 1.125rem 1rem;
    `}
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${p => p.theme.colors.Surface};
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  border-radius: 0.5rem;

  a {
    color: ${p => p.theme.colors.Primary};
  }
`;
