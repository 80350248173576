import { gql } from '@apollo/client';
import {
  GRAPHQL_QUERY_PAGE_INFO,
  GRAPHQL_QUERY_PAGINATION_VARIABLE_TYPES,
  GRAPHQL_QUERY_PAGINATION_VARIABLES,
} from '../../shared/api/api.constants';
import {
  DesktopAppFragment,
  DesktopAppFragmentFields,
} from './Desktop.fragments';
import {
  GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
  GRAPHQL_TYPENAME_DESKTOP_APP_EDGE,
} from './Desktop.constants';
import type {
  DesktopAppEdgeApiType,
  RecentAppsApiType,
} from './data/Desktop/types/Desktop.types';
import type { ApiConnection } from '../../shared/api/api.types';

export const FILTER_DESKTOP_APPS = gql`
  query FilterDesktop($desktop: String, $filterFreeTextSearch: String) {
    desktopApps(
      desktop: $desktop
      filterFreeTextSearch: $filterFreeTextSearch
    ) {
      edges {
        node {
          ${DesktopAppFragmentFields}
          __typename
        }
        __typename
      }
    }
  }
`;

export interface FilterDesktopAppsVariables {
  desktop: string;
  filterFreeTextSearch: string;
}

export interface FilterDesktopAppsResponse {
  desktopApps: ApiConnection<
    DesktopAppEdgeApiType,
    typeof GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
    typeof GRAPHQL_TYPENAME_DESKTOP_APP_EDGE
  >;
}

export const GET_ALL_DESKTOPS_APPS = gql`
  query GetAllDesktopsApps(
    $workspaceId: String!
    $filterFreeTextSearch: String
    ${GRAPHQL_QUERY_PAGINATION_VARIABLE_TYPES}
  ) {
    desktopApps(
      desktop_workspace: $workspaceId
      filterFreeTextSearch: $filterFreeTextSearch
      filterOnlyAccessible: true
      ${GRAPHQL_QUERY_PAGINATION_VARIABLES}
    ) {
      ${GRAPHQL_QUERY_PAGE_INFO}
      edges {
        node {
          ...DesktopAppFragment
        }
      }
    }
  }
  ${DesktopAppFragment}
`;

export const GET_RECENT_APPS = gql`
  query GetRecentApps($workspace: ID!) {
    getRecentApps(workspace: $workspace, orderBy: "occurredAt", orderType: "desc") {
      desktopApp {
        ${DesktopAppFragmentFields}
        desktop {
          id
        }
      }
    }
  }
`;

export interface GetRecentAppsResponse {
  getRecentApps: RecentAppsApiType[];
}

export interface GetRecentAppsVariables {
  workspace: string;
}

export const GET_CLIPBOARD = gql`
  query GetClipboard($uuid: String) {
    getClipboard(uuid: $uuid) {
      data
      uuid
    }
  }
`;

export interface GetClipboardResponse {
  getClipboard: {
    data: string;
  };
}

export interface GetClipboardVariables {
  uuid: string;
}
