import styled from 'styled-components';
import {
  SkeletonBox,
  SkeletonElement,
} from '../../../../shared/components/SkeletonBox';

export const StyledButtonBlockSkeleton = styled.div`
  background-color: ${p => p.theme.colors.Background};
  padding: 1rem 1.625rem;
  display: flex;
`;

export const SkeletonInner = styled(SkeletonBox)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
`;

export const StyledBlockSkeleton = styled.div<{ flex?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${({ flex }) => flex && 'flex: 1'};
`;

export const StyledLinksViewSkeleton = styled.div`
  flex-grow: 1;
  background-color: ${p => p.theme.colors.Background};
  grid-column: desktop-items;
  grid-row-start: desktop-toolbar;
  grid-row-end: desktop-items;
`;

export const SkeletonLinkUrl = styled(SkeletonElement)`
  position: absolute;
  bottom: 13px;
  left: 35px;
`;

export const SkeletonLinkContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 0.5rem;
  flex: 1;
`;

export const SkeletonLinkTitleWrapper = styled.div`
  width: 100%;
`;

export const SkeletonLinkTitle = styled(SkeletonElement)`
  position: absolute;
  top: 14px;
  left: 12px;
`;

export const SkeletonLinkPreviewImage = styled(SkeletonElement)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const SkeletonLinkUserAvatar = styled(SkeletonElement)`
  position: absolute;
  bottom: 12px;
  left: 12px;
`;
