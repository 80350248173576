import { BasicColors, ThemeType } from '../../Theme';
import { BillingThemesType } from './Billing.theme.types';

export const BillingTheme: BillingThemesType = {
  [ThemeType.light]: {
    colors: {
      ProUpgradeBackground: '#ECF9FE',
      ProUpgradeBorder: '#B4E1FA',
    },
  },
  [ThemeType.dark]: {
    colors: {
      ProUpgradeBackground: BasicColors[ThemeType.dark].OnSurface.alpha(0.08)
        .rgb()
        .string(),
      ProUpgradeBorder: BasicColors[ThemeType.dark].OnSurface.alpha(0.38)
        .rgb()
        .string(),
    },
  },
};
