import { StreamTimelineEvent } from '../../../GeneralMercure.types';
import { ApolloClient } from '@apollo/client';
import { addTimelineToCache } from '../../../../../Timeline/cache';
import { TIMELINE_EVENTS_PAGE_SIZE } from '../../../../../Timeline/Timeline.constants';
import { addTagToTagsCache } from '../../../../../Link/cache';
import { getShortId } from '../../../../../../shared/utils/id';

export const timelineRequestNew = (
  event: StreamTimelineEvent,
  apolloClient: ApolloClient<object>,
) => {
  if (!event.id) {
    return;
  }

  event.id = `/timeline-events/${event.id}`;

  addTimelineToCache(
    apolloClient,
    {
      workspace: event.context.workspace || event.workspace?.id,
      page: 0,
      pageSize: TIMELINE_EVENTS_PAGE_SIZE,
      query: '',
      scope: '',
    },
    event,
  );

  if (event.context.tag_name) {
    addTagToTagsCache(
      apolloClient,
      { workspace: event.workspace?.id },
      {
        id: event.scopeId,
        _id: getShortId(event.scopeId),
        name: event.context.tag_name,
      },
    );
  }

  if (event.context.desktop) {
    addTimelineToCache(
      apolloClient,
      {
        workspace: event.context.workspace || event.workspace?.id,
        desktop: event.context.desktop,
        page: 0,
        pageSize: TIMELINE_EVENTS_PAGE_SIZE,
        query: '',
        scope: '',
      },
      event,
    );
  }
};
