export enum ConferenceTranslation {
  // switcher
  switcherHeader = 'conference_switcherHeader',
  switcherHeaderScheduledCallButton = 'conference_switcherHeaderScheduledCallButton',
  switcherHeaderNewCallButton = 'conference_switcherHeaderNewCallButton',

  // conferences list
  conferencesListEmptyStateTitle = 'conferences_conferencesListEmptyStateTitle',
  conferencesListEmptyStateSubtitle = 'conferences_conferencesListEmptyStateSubtitle',
  conferencesListEmptyConferenceTitle = 'conferences_conferencesListEmptyConferenceTitle',

  // chat avatar
  conferenceAvatarAlt = 'conference_conferenceAvatarAlt',

  // conference view
  conferencesViewEmptyConferenceTitle = 'conferences_conferencesViewEmptyConferenceTitle',
  conferenceViewControlMessage = 'conference_conferenceViewControlMessage',
  conferenceViewControlCall = 'conference_conferenceViewControlCall',
  conferenceViewControlVideoCall = 'conference_conferenceViewControlVideoCall',
  conferenceViewControlJoinVideoCall = 'conference_conferenceViewControlJoinVideoCall',
  conferenceViewControlJoinCall = 'conference_conferenceViewControlJoinCall',
  conferenceViewDetailsTitle = 'conference_conferenceViewDetailsTitle',
  conferenceViewDetailsItemStart = 'conference_conferenceViewDetailsItemStart',
  conferenceViewDetailsItemDuration = 'conference_conferenceViewDetailsItemDuration',
  conferenceViewTabNameCalls = 'conference_conferenceViewTabNameCalls',
  conferenceViewEmptyContent = 'conference_conferenceViewEmptyContent',

  conferenceStatusOutgoing = 'conference_conferenceStatusOutgoing',
  conferenceStatusIncoming = 'conference_conferenceStatusIncoming',
  conferenceStatusMissed = 'conference_conferenceStatusMissed',

  // call toast
  callToastIncomingAudioCall = 'conference_callToastIncomingAudioCall',
  callToastIncomingVideoCall = 'conference_callToastIncomingVideoCall',

  // conference page
  conferenceClosedBannerMessage = 'conference_closedBannerMessage',
  conferenceClosedBannerRejoinButton = 'conference_closedBannerRejoinButton',
  conferenceClosedBannerCloseButton = 'conference_closedBannerCloseButton',
  limitReachedBannerMessage = 'conference_limitReachedBanner',
  limitReachedBannerRetryButton = 'conference_limitReachedBannerRetryButton',
  limitReachedBannerCloseButton = 'conference_limitReachedBannerCloseButton',
  nonSafariIOSBannerMessage = 'conference_nonSafariIOSBannerMessage',
  nonSafariIOSBannerProceedButton = 'conference_nonSafariIOSBannerProceedButton',
  nonSafariIOSBannerCloseButton = 'conference_nonSafariIOSBannerCloseButton',

  // conference preview
  previewHeaderTitle = 'conference_previewHeaderTitle',
  previewNewMeetingTitle = 'conference_previewNewMeetingTitle',
  previewDescription = 'conference_previewDescription',
  previewJoinButton = 'conference_previewJoinButton',
  previewCopyLinkButton = 'conference_previewCopyLinkButton',
  previewCloseButton = 'conference_previewCloseButton',
  previewLinkCopiedMessage = 'conference_previewLinkCopiedMessage',

  // chat
  chatButton = 'conference_chatButton',

  // schedule upcoming
  scheduledUpcomingConferenceUpcoming = 'conference_scheduledUpcomingConferenceUpcoming',
  scheduledUpcomingConferencePast = 'conference_scheduledUpcomingConferencePast',
  scheduledUpcomingConferenceScheduled = 'conference_scheduledUpcomingConferenceScheduled',
  scheduledUpcomingConferenceEditMeeting = 'conference_scheduledUpcomingConferenceEditMeeting',
  scheduledUpcomingConferenceCopyMeetingsLink = 'conference_scheduledUpcomingConferenceCopyMeetingsLink',
  scheduledUpcomingConferenceDeleteMeeting = 'conference_scheduledUpcomingConferenceDeleteMeeting',
  scheduledUpcomingConferenceMeetingDate = 'conference_scheduledUpcomingConferenceMeetingDate',
  scheduledUpcomingConferenceMeetingLink = 'conference_scheduledUpcomingConferenceMeetingLink',
  scheduledUpcomingConferenceInvitees = 'conference_scheduledUpcomingConferenceInvitees',
  scheduledUpcomingConferenceCopyLinkToastText = 'conference_scheduledUpcomingConferenceCopyLinkToastText',
  scheduledUpcomingConferenceCopyEmailToastText = 'conference_scheduledUpcomingConferenceCopyEmailToastText',
  scheduledUpcomingConferenceJoin = 'conference_scheduledUpcomingConferenceJoin',
  scheduledUpcomingConferenceOrganizer = 'conference_scheduledUpcomingConferenceOrganizer',
  scheduledUpcomingConferenceIcalFile = 'conference_scheduledUpcomingConferenceIcalFile',
  scheduledUpcomingConferenceOptional = 'conference_scheduledUpcomingConferenceOptional',
  scheduledUpcomingConferenceTimeStartNoOptionsSelected = 'conference_scheduledUpcomingConferenceTimeStartNoOptionsSelected',
  scheduledUpcomingConferenceMembersNoOptionsSelected = 'conference_scheduledUpcomingConferenceMembersNoOptionsSelected',

  // schedule
  scheduleConferenceMeeting = 'conference_scheduleConferenceMeeting',
  scheduleConferenceEmptyStateText = 'conference_scheduleConferenceEmptyStateText',
  scheduleConferenceRepeatingInfo = 'conference_scheduleConferenceRepeatingInfo',
  scheduleConferenceFormTopic = 'conference_scheduleConferenceFormTopic',
  scheduleConferenceEnterTopic = 'conference_scheduleConferenceEnterTopic',
  scheduleConferenceFormDescription = 'conference_scheduleConferenceFormDescription',
  scheduleConferenceEnterDescription = 'conference_scheduleConferenceEnterDescription',
  scheduleConferenceFormDateTime = 'conference_scheduleConferenceFormDateTime',
  scheduleConferenceTo = 'conference_scheduleConferenceTo',
  scheduleConferenceFormInvitees = 'conference_scheduleConferenceFormInvitees',
  scheduleConferenceFormMeetingId = 'conference_scheduleConferenceFormMeetingId',
  scheduleConferenceFormNameLabel = 'conference_scheduleConferenceFormNameLabel',
  scheduleConferenceFormSave = 'conference_scheduleConferenceFormSave',
  scheduleConferenceMembersIsRequired = 'conference_scheduleConferenceMembersIsRequired',
  scheduleConferenceMaxMembersForFreeWorkspace = 'conference_scheduleConferenceMaxMembersForFreeWorkspace',
  scheduleConferenceYouAreAddedAsOrganizer = 'conference_scheduleConferenceYouAreAddedAsOrganizer',
  scheduleConferenceTopicIsRequired = 'conference_scheduleConferenceTopicIsRequired',
  scheduleConferenceStartTimeIsRequired = 'conference_scheduleConferenceStartTimeIsRequired',
  scheduleConferenceEndTimeIsRequired = 'conference_scheduleConferenceEndTimeIsRequired',
  scheduleConferenceTimezoneIsRequired = 'conference_scheduleConferenceTimezoneIsRequired',
  scheduleConferenceStartAtInPast = 'conference_scheduleConferenceStartAtInPast',
  scheduleConferenceEarlierThenStartAt = 'conference_scheduleConferenceEarlierThenStartAt',

  // Repeating schedule conference
  scheduleRepeatingConferenceCustomRecurrence = 'conference_scheduleRepeatingConferenceCustomRecurrence',
  scheduleRepeatingConferenceRepeatEvery = 'conference_scheduleRepeatingConferenceRepeatEvery',
  scheduleRepeatingConferenceRepeatOn = 'conference_scheduleRepeatingConferenceRepeatOn',
  scheduleRepeatingConferenceEnds = 'conference_scheduleRepeatingConferenceEnds',
  scheduleRepeatingConferenceNever = 'conference_scheduleRepeatingConferenceNever',
  scheduleRepeatingConferenceOn = 'conference_scheduleRepeatingConferenceOn',
  scheduleRepeatingConferenceAfter = 'conference_scheduleRepeatingConferenceAfter',
  scheduleRepeatingConferenceDays = 'conference_scheduleRepeatingConferenceDays',
  scheduleRepeatingConferenceWeeks = 'conference_scheduleRepeatingConferenceWeeks',
  scheduleRepeatingConferenceMonthly = 'conference_scheduleRepeatingConferenceMonthly',
  scheduleRepeatingConferenceOccurrences = 'conference_scheduleRepeatingConferenceOccurrences',
  scheduleRepeatingConferenceDoesNotRepeat = 'conference_scheduleRepeatingConferenceDoesNotRepeat',
  scheduleRepeatingConferenceDoesCustomOption = 'conference_scheduleRepeatingConferenceDoesCustomOption',
  scheduleRepeatingConferenceFirstWeekInMonth = 'conference_scheduleRepeatingConferenceFirstWeekInMonth',
  scheduleRepeatingConferenceSecondWeekInMonth = 'conference_scheduleRepeatingConferenceSecondWeekInMonth',
  scheduleRepeatingConferenceThirdWeekInMonth = 'conference_scheduleRepeatingConferenceThirdWeekInMonth',
  scheduleRepeatingConferenceFourthWeekInMonth = 'conference_scheduleRepeatingConferenceFourthWeekInMonth',
  scheduleRepeatingConferenceFifthWeekInMonth = 'conference_scheduleRepeatingConferenceFifthWeekInMonth',
  scheduleRepeatingConferenceMonthlyOnDay = 'conference_scheduleRepeatingConferenceMonthOnDay',
  scheduleRepeatingConferenceMonthlyOnThe = 'conference_scheduleRepeatingConferenceMonthlyOnThe',
  scheduledRepeatingConferenceConfirmationModalTitle = 'conference_scheduledRepeatingConferenceConfirmationModalTitle',
  scheduledConferenceUpdateConfirmationModalUpdateOne = 'conference_scheduledConferenceUpdateConfirmationModalUpdateOne',
  scheduledConferenceUpdateConfirmationModalUpdateThisAndFollowing = 'conference_scheduledConferenceUpdateConfirmationModalUpdateThisAndFollowing',
  scheduledConferenceUpdateConfirmationModalUpdateAll = 'conference_scheduledConferenceUpdateConfirmationModalUpdateAll',
  scheduledConferenceDeleteConfirmationModalUpdateOne = 'conference_scheduledConferenceDeleteConfirmationModalUpdateOne',
  scheduledConferenceDeleteConfirmationModalThisAndFollowing = 'conference_scheduledConferenceDeleteConfirmationModalThisAndFollowing',
  scheduledConferenceDeleteConfirmationModalUpdateAll = 'conference_scheduledConferenceDeleteConfirmationModalUpdateAll',
  scheduledConferenceMonday = 'conference_scheduledConferenceMonday',
  scheduledConferenceTuesday = 'conference_scheduledConferenceTuesday',
  scheduledConferenceWednesday = 'conference_scheduledConferenceWednesday',
  scheduledConferenceThursday = 'conference_scheduledConferenceThursday',
  scheduledConferenceFriday = 'conference_scheduledConferenceFriday',
  scheduledConferenceSaturday = 'conference_scheduledConferenceSaturday',
  scheduledConferenceSunday = 'conference_scheduledConferenceSunday',
  scheduledConferenceDaily = 'conference_scheduledConferenceDaily',
  scheduledConferenceWeekly = 'conference_scheduledConferenceWeekly',
  scheduledConferenceMonthly = 'conference_scheduledConferenceMonthly',
  scheduledConferenceTimes = 'conference_scheduledConferenceTimes',
  scheduledConferenceOn = 'conference_scheduledConferenceOn',
  scheduledConferenceEvery = 'conference_scheduledConferenceEvery',
  scheduledConferenceDay = 'conference_scheduledConferenceDay',
  scheduledConferenceUntil = 'conference_scheduledConferenceUntil',
  scheduledConferenceMonth = 'conference_scheduledConferenceMonth',
  scheduledConferenceWeek = 'conference_scheduledConferenceWeek',
  scheduledConferenceAdd = 'conference_scheduledConferenceAdd',
  scheduledConferenceEmptyMeetingLink = 'conference_scheduledConferenceEmptyMeetingLink',
  scheduledConferenceDeclineMeeting = 'conference_scheduledConferenceDeclineMeeting',
  scheduledConferenceDeclineSingleConference = 'conference_scheduledConferenceDeclineSingleConference',
  scheduledConferenceDeclineAllConferences = 'conference_scheduledConferenceDeclineAllConferences',
  scheduledConferenceDeclineConfirmationTitle = 'conference_scheduledConferenceDeclineConfirmationTitle',
  scheduledConferenceDeclineConfirmationMessage = 'conference_scheduledConferenceDeclineConfirmationMessage',
  scheduledConferenceDeclineConfirmationMessageSingle = 'conference_scheduledConferenceDeclineConfirmationMessageSingle',
  scheduledConferenceDeleteConfirmationTitle = 'conference_scheduledConferenceDeleteConfirmationTitle',
  scheduledConferenceDeleteConfirmationMessage = 'conference_scheduledConferenceDeleteConfirmationMessage',
  scheduledConferenceDeleteConfirmationTitleRepeating = 'conference_scheduledConferenceDeleteConfirmationTitleRepeating',
  scheduledConferenceDeleteConfirmationMessageRepeating = 'conference_scheduledConferenceDeleteConfirmationMessageRepeating',

  tooltipScheduleMeeting = 'conference_tooltipScheduleMeeting',
  tooltipStartNewScheduleMeeting = 'conference_tooltipStartNewScheduleMeeting',
  tooltipTabPastCalls = 'conference_tooltipTabPastCalls',
  tooltipTabScheduleMeeting = 'conference_tooltipTabScheduleMeeting',
}

export type ConferenceLocale = {
  [key in ConferenceTranslation]: string;
};
