export const IDLE_DETECTOR_INTERVAL = 60000; // 60 seconds
/**
 * If distance between setInterval calls
 * is longer than expected distance multiplied by idle coefficient
 * we treat it as idle time.
 */
export const IDLE_DETECTOR_IDLE_COEFFICIENT = 1.5;

export const PING_MERCURE_MAX_ATTEMPTS = 3;
export const PING_MERCURE_RETRY_INTERVAL = 1000; // 1 second
export const PING_MERCURE_RESPONSE_WAITING_TIME = 5000; // 5 seconds
