import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const CloseIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.219668 0.219668C0.512558 -0.0732225 0.987438 -0.0732225 1.28033 0.219668L6 4.9393L10.7197 0.219668C11.0126 -0.0732225 11.4874 -0.0732225 11.7803 0.219668C12.0732 0.512558 12.0732 0.987438 11.7803 1.28033L7.0607 6L11.7803 10.7197C12.0732 11.0126 12.0732 11.4874 11.7803 11.7803C11.4874 12.0732 11.0126 12.0732 10.7197 11.7803L6 7.0607L1.28033 11.7803C0.987438 12.0732 0.512558 12.0732 0.219668 11.7803C-0.0732225 11.4874 -0.0732225 11.0126 0.219668 10.7197L4.9393 6L0.219668 1.28033C-0.0732225 0.987438 -0.0732225 0.512558 0.219668 0.219668Z"
        fill="currentColor"
      />
    </svg>
  );
});
