import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DesktopLogoDarkIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        className="bottom-left"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.313 167.992H64.5705C56.8796 167.992 50.645 174.227 50.645 181.917V388.756C50.645 396.447 56.8796 402.682 64.5705 402.682H388.754C396.445 402.682 402.68 396.447 402.68 388.756V167.992H402.678V344.009H109.313V167.992Z"
        fill="#919EAB"
      />
      <path
        className="top-right"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M447.426 344.008C455.117 344.008 461.352 337.774 461.352 330.083V123.244C461.352 115.553 455.117 109.318 447.426 109.318H123.242C115.552 109.318 109.317 115.553 109.317 123.244V167.992H402.678V344.008H447.426Z"
        fill="#919EAB"
      />
    </svg>
  );
});
