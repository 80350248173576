export const delay = (ms: number) =>
  new Promise<void>(resolve => {
    setTimeout(() => resolve(), ms);
  });

export const retry = <T>(
  retryHandler: () => Promise<T | undefined>,
  retryLimit: number = 1,
  retryDelay = 0,
  increasingDelay = false,
): Promise<T | undefined> => {
  let retryCount = 0;
  const retryWithDelay = (): Promise<T | undefined> => {
    return retryHandler().catch(() => {
      if (retryLimit < 0 || retryCount < retryLimit) {
        retryCount++;
        const currentRetryDelay = increasingDelay
          ? retryDelay * retryCount
          : retryDelay;
        return delay(currentRetryDelay).then(retryWithDelay);
      }
    });
  };
  return retryWithDelay();
};

export const retryWithResolver = (ms: number, resolver: () => boolean) =>
  new Promise<void>(resolve => {
    const interval = setInterval(() => {
      if (resolver()) {
        resolve();
        clearInterval(interval);
      }
    }, ms);
  });
