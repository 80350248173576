import { useEffect, useMemo, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import objectHash from 'object-hash';
import type { RepositoryFetchPolicy } from './multipleEntityRepository.hooks';

let initialEntityFetched: Set<string> = new Set();

interface SingleEntityRepositoryProps<T> {
  fetchFunction: (prevEntities?: T) => Promise<T | undefined>;
  iddbQuerier: () => Promise<TableType<T> | undefined>;
  fetchPolicy?: RepositoryFetchPolicy;
  dependencies?: any[];
}

export interface SingleEntityRepositoryReturn<T> {
  entity: T | undefined;
  loading: boolean;
}

export interface TableType<T> {
  id: string;
  sourceData: T;
}

export const useSingleEntityRepository = <T>({
  fetchFunction,
  iddbQuerier,
  fetchPolicy = 'cache-and-network',
  dependencies = [],
}: SingleEntityRepositoryProps<T>): SingleEntityRepositoryReturn<T> => {
  const [loading, setLoading] = useState(true);

  const argumentsHash = useMemo(() => {
    return objectHash({
      fetchFunction,
      iddbQuerier,
      dependencies,
    });
  }, [fetchFunction, iddbQuerier, dependencies]);

  useEffect(() => {
    if (
      fetchPolicy === 'cache-and-network' &&
      !initialEntityFetched.has(argumentsHash)
    ) {
      initialEntityFetched.add(argumentsHash);
      setLoading(true);
      fetchFunction().finally(() => {
        setLoading(false);
      });
    }
  }, [argumentsHash, fetchFunction, fetchPolicy]);

  const iddbEntity = useLiveQuery(
    () =>
      iddbQuerier().catch(error => {
        console.error(error);
      }),
    [argumentsHash],
    undefined,
  );

  const entity = iddbEntity?.sourceData;

  return useMemo(
    () => ({
      entity,
      loading,
    }),
    [entity, loading],
  );
};
