import { getLongId } from '../../../../../shared/utils/id';
import { AccountInvitationApiType } from '../types/Invitation.types';
import { InvitationsTableType } from '../types/InvitationIDDB.types';

export const InvitationsApiTypeToInvitationTableType = (
  invitation: AccountInvitationApiType,
  workspaceId: string,
  desktopId?: string,
) => {
  const row: InvitationsTableType = {
    id: getLongId('account-invitations', invitation.id),
    workspaceId: getLongId('workspaces', workspaceId),
    sourceData: invitation,
    ...(desktopId ? { desktopId: getLongId('desktops', desktopId) } : {}),
  };
  return row;
};

export const InvitationTableTypeToInvitationApiType = (
  invitationRow: InvitationsTableType,
) => {
  return invitationRow?.sourceData;
};

export const createInvitationRowsMap = (
  invitationRows: InvitationsTableType[],
) => {
  return (
    invitationRows?.reduce((acc, curr) => {
      return { ...acc, [curr.id]: curr };
    }, {} as Record<string, InvitationsTableType>) || {}
  );
};
