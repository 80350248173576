import { gql } from '@apollo/client';
import { DesktopAppFragmentFields } from '../../../Desktop.fragments';
import type { ApiConnection } from '../../../../../shared/api/api.types';
import type { DesktopAppEdgeApiType } from '../../../data/Desktop/types/Desktop.types';

export const GET_DESKTOP_APPS_REST = gql`
  query GetDesktopAppsRest($desktopId: ID!) {
    desktopApps(desktopId: $desktopId)
      @rest(path: "/desktop/{args.desktopId}/apps")
      @connection(key: "desktop_apps_rest", filter: ["args.desktopId"]) {
        edges {
          node {
            ${DesktopAppFragmentFields}
          }
        }
    }
  }
`;

export interface GetDesktopAppsRestVariables {
  desktopId: string;
}

export interface GetDesktopAppsRestResponse {
  desktopApps: ApiConnection<DesktopAppEdgeApiType>;
}
