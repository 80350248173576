import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ChatVideoIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="chat-video-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0625 6.125C9.94647 6.125 9.83519 6.1711 9.75313 6.25313C9.67109 6.33519 9.625 6.44648 9.625 6.5625V35.4375C9.625 35.679 9.821 35.875 10.0625 35.875H31.9375C32.0535 35.875 32.1648 35.829 32.2469 35.7469C32.329 35.6648 32.375 35.5535 32.375 35.4375V15.75H25.8125C25.0003 15.75 24.2214 15.4274 23.647 14.853C23.0727 14.2787 22.75 13.4997 22.75 12.6875V6.125H10.0625ZM25.375 7.105V12.6875C25.375 12.929 25.571 13.125 25.8125 13.125H31.395L25.375 7.105ZM7 6.5625C7 4.872 8.372 3.5 10.0625 3.5H24.213C25.025 3.5 25.8037 3.822 26.3777 4.39775L34.1022 12.1222C34.678 12.6962 35 13.475 35 14.287V35.4375C35 35.8396 34.9207 36.2379 34.7669 36.6095C34.6129 36.981 34.3873 37.3186 34.1029 37.603C33.8186 37.8873 33.481 38.1129 33.1095 38.2669C32.7379 38.4207 32.3396 38.5 31.9375 38.5H10.0625C9.25027 38.5 8.47131 38.1773 7.89698 37.603C7.32265 37.0286 7 36.2497 7 35.4375V6.5625Z"
        fill="currentColor"
      />
      <path
        d="M17.5 17.9382C17.5 17.5981 17.871 17.3881 18.1626 17.563L25.6247 22.0403C25.9079 22.2102 25.9079 22.6207 25.6247 22.7906L18.1626 27.2679C17.871 27.4429 17.5 27.2329 17.5 26.8928V17.9382Z"
        fill="currentColor"
      />
    </svg>
  );
});
