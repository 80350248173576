import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DownloadIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.47 9.78C4.61063 9.92045 4.80125 9.99934 5 9.99934C5.19875 9.99934 5.38937 9.92045 5.53 9.78L9.28 6.03C9.41248 5.88783 9.4846 5.69978 9.48118 5.50548C9.47775 5.31118 9.39903 5.12579 9.26162 4.98838C9.12421 4.85097 8.93882 4.77225 8.74452 4.76883C8.55022 4.7654 8.36217 4.83752 8.22 4.97L5.75 7.44V0.75C5.75 0.551088 5.67098 0.360322 5.53033 0.21967C5.38968 0.0790177 5.19891 0 5 0C4.80109 0 4.61032 0.0790177 4.46967 0.21967C4.32902 0.360322 4.25 0.551088 4.25 0.75V7.44L1.78 4.97C1.63783 4.83752 1.44978 4.7654 1.25548 4.76883C1.06118 4.77225 0.875791 4.85097 0.738378 4.98838C0.600965 5.12579 0.522253 5.31118 0.518825 5.50548C0.515397 5.69978 0.58752 5.88783 0.72 6.03L4.47 9.78ZM0.75 12C0.551088 12 0.360322 12.079 0.21967 12.2197C0.0790175 12.3603 0 12.5511 0 12.75C0 12.9489 0.0790175 13.1397 0.21967 13.2803C0.360322 13.421 0.551088 13.5 0.75 13.5H9.25C9.44891 13.5 9.63968 13.421 9.78033 13.2803C9.92098 13.1397 10 12.9489 10 12.75C10 12.5511 9.92098 12.3603 9.78033 12.2197C9.63968 12.079 9.44891 12 9.25 12H0.75Z"
        fill="currentColor"
      />
    </svg>
  );
});
