import { database } from '../../../../../../Database';
import { DesktopFoldersTableType } from '../../../../../../Database/DesktopFoldersTable/DesktopFoldersTable';

export const favoriteFolderRequestRemove = (
  folderNode: DesktopFoldersTableType,
) => {
  database.desktopFolders
    .update(folderNode.id, {
      accountFavorite: folderNode.accountFavorite,
      'sourceData.accountFavorite': folderNode.accountFavorite,
    })
    //Add element to database if update return 0 https://dexie.org/docs/Table/Table.update()
    .then(updated => {
      if (!updated) {
        database.desktopFolders.put(folderNode).catch(error => {
          console.error(error);
        });
      }
    });
};
