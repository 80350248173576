import styled from 'styled-components';

const SEGMENT_TITLEBAR_HEIGHT = 56;
export const SEGMENT_TITLEBAR_HEIGHT_MOBILE = 44;

export const StyledSegmentPageTitleBar = styled.div<{
  isMobile: boolean;
  hideBorder: boolean;
}>`
  height: ${({ isMobile }) =>
    isMobile ? SEGMENT_TITLEBAR_HEIGHT_MOBILE : SEGMENT_TITLEBAR_HEIGHT}px;
  min-height: ${({ isMobile }) =>
    isMobile ? SEGMENT_TITLEBAR_HEIGHT_MOBILE : SEGMENT_TITLEBAR_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.m};
  border-bottom: ${p =>
    !p.hideBorder ? `1px solid ${p.theme.colors.SurfaceBorder}` : 'none'};
  position: relative;
  width: 100%;
`;

export const TitleBarMobilemenu = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.xxs};
  width: 32px;
  height: 32px;
`;

export const TitleBarContent = styled.div.attrs({
  'data-testid': 'titlebar-content',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  grid-gap: ${({ theme }) => theme.spacing.xs};
  height: 100%;
  width: 100%;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    max-width: 100%;
  `}
`;

export const TitleBarControls = styled.div.attrs({
  'data-testid': 'titlebar-controls',
})`
  display: inline-flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.xs};
`;

export const TitleBarTitleContainer = styled.div.attrs({
  'data-testid': 'titlebar-title-container',
})`
  display: flex;
  grid-gap: ${({ theme }) => theme.spacing.xs};
  height: 100%;
  max-width: 70%;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    max-width: 40%;
  `}

  ${({ theme: { breakpoints } }) => breakpoints.lg`
    max-width: 60%;
  `}
`;

export const TitleBarAvatar = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleBarDivider = styled.div`
  height: 1.25rem;
  width: 1px;
  background-color: ${p => p.theme.colors.Divider};
`;

export const ConversationHeaderTitle = styled.div.attrs({
  'data-testid': 'conversation-header-title',
})`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ConversationTitle = styled.div`
  display: inline-grid;
`;
