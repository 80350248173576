import { WorkspaceApiType } from './Workspace.types';
import { AccountApiType } from '../User/User.types';
import _uniqBy from 'lodash/uniqBy';
import { getShortId } from '../../shared/utils/id';
import {
  checkExtensionInstalled,
  sendExtensionMessage,
} from '../../shared/utils/extension.utils';
import type { DesktopApiType } from '../Desktop/data/Desktop/types/Desktop.types';
import { WorkspaceInfoApiType } from '../WorkspaceInfo/data/WorkspaceInfo/types/WorkspaceInfo.types';

export const getWorkspaceInfoMap = (
  account: AccountApiType | null,
): { [key: string]: WorkspaceInfoApiType } => {
  return (
    account?.workspaceInfo?.reduce((acc, curr) => {
      return { ...acc, [curr.id]: curr };
    }, {}) || {}
  );
};

export const getWorkspaceAdminCloseLink = (
  currentWorkspace: WorkspaceApiType,
  lastOpenedDesktop: DesktopApiType | null,
): string => {
  if (!lastOpenedDesktop) {
    return `/workspace/${getShortId(currentWorkspace.id)}`;
  }
  return `/workspace/${getShortId(currentWorkspace.id)}${
    lastOpenedDesktop?.id ? '/desktop/' + getShortId(lastOpenedDesktop?.id) : ''
  }`;
};

export const hasMultipleAccounts = (workspaceAccountMap: {
  [key: string]: AccountApiType;
}) => {
  return _uniqBy(Object.values(workspaceAccountMap), 'id').length > 1;
};

export const getCurrentWorkspaceInfo = (
  account: AccountApiType,
  workspaceId: string,
): WorkspaceInfoApiType | undefined =>
  account.workspaceInfo?.find(info => info.id === workspaceId);

export const getWorkspaceIdFromCurrentUrl = () => {
  const pathname = window.location.pathname;
  const pathNameArray = pathname.split('/');
  return pathNameArray[1] === 'workspace' ? pathNameArray[2] : null;
};

export const generateLastUsedWorkspaceLocalstorageKey = (identityId: string) =>
  `workspace:lastUsed:${getShortId(identityId)}`;

export const workspaceIdExistInWorkspaces = (
  workspaceId: string | null,
  workspaces: WorkspaceApiType[],
) =>
  workspaceId
    ? !!workspaces.find(
        workspace =>
          workspace.id === workspaceId ||
          getShortId(workspace.id) === workspaceId,
      )
    : false;

export const sendLastOpenedWorkspaceExtensionMessage = (): Promise<
  string | null
> => {
  return new Promise((resolve, reject) => {
    checkExtensionInstalled()
      .catch(() => reject('No extension installed'))
      .then(() => {
        sendExtensionMessage<string | null>(
          {
            updateLastOpenedWorkspace: true,
          },
          res => {
            resolve(res || null);
          },
        );
      });
  });
};
