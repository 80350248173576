import { ConferenceLocale, ConferenceTranslation } from './i18n.types';

export const enConferenceLocale: ConferenceLocale = {
  // switcher
  [ConferenceTranslation.switcherHeader]: 'Meetings',
  [ConferenceTranslation.switcherHeaderScheduledCallButton]: 'Schedule',
  [ConferenceTranslation.switcherHeaderNewCallButton]: 'New',

  // list
  [ConferenceTranslation.conferencesListEmptyStateTitle]: 'No call history',
  [ConferenceTranslation.conferencesListEmptyStateSubtitle]:
    'Your calls and meetings will be listed here',
  [ConferenceTranslation.conferencesListEmptyConferenceTitle]: 'Deleted user',

  // chat avatar
  [ConferenceTranslation.conferenceAvatarAlt]: '{accountName} avatar',

  // conference view
  [ConferenceTranslation.conferencesViewEmptyConferenceTitle]: 'Deleted user',
  [ConferenceTranslation.conferenceViewControlMessage]: 'Message',
  [ConferenceTranslation.conferenceViewControlCall]: 'Call',
  [ConferenceTranslation.conferenceViewControlVideoCall]: 'Video call',
  [ConferenceTranslation.conferenceViewControlJoinCall]: 'Join',
  [ConferenceTranslation.conferenceViewControlJoinVideoCall]: 'Join',
  [ConferenceTranslation.conferenceViewDetailsTitle]: 'Details',
  [ConferenceTranslation.conferenceViewDetailsItemStart]: 'Call start time',
  [ConferenceTranslation.conferenceViewDetailsItemDuration]: 'Call duration',
  [ConferenceTranslation.conferenceViewTabNameCalls]: 'Calls',
  [ConferenceTranslation.conferenceViewEmptyContent]:
    'Your call or scheduled meeting will appear here',

  // conference status
  [ConferenceTranslation.conferenceStatusOutgoing]: 'outgoing',
  [ConferenceTranslation.conferenceStatusIncoming]: 'incoming',
  [ConferenceTranslation.conferenceStatusMissed]: 'missed',

  // call toast
  [ConferenceTranslation.callToastIncomingAudioCall]: 'Incoming audio call',
  [ConferenceTranslation.callToastIncomingVideoCall]: 'Incoming video call',

  // conference page
  [ConferenceTranslation.conferenceClosedBannerMessage]: 'You left the call',
  [ConferenceTranslation.conferenceClosedBannerRejoinButton]: 'Rejoin',
  [ConferenceTranslation.conferenceClosedBannerCloseButton]: 'Close',
  [ConferenceTranslation.limitReachedBannerMessage]:
    'The conference is full. The maximum number participants in this conference is {limit}. Please contact the person that invited you in order to resolve this issue',
  [ConferenceTranslation.limitReachedBannerRetryButton]: 'Try again',
  [ConferenceTranslation.limitReachedBannerCloseButton]: 'Close',
  [ConferenceTranslation.limitReachedBannerCloseButton]: 'Close',
  [ConferenceTranslation.nonSafariIOSBannerMessage]:
    'This part of the app only works in Safari on iOS.',
  [ConferenceTranslation.nonSafariIOSBannerCloseButton]: 'Close',
  [ConferenceTranslation.nonSafariIOSBannerProceedButton]: 'Proceed',

  // conference preview
  [ConferenceTranslation.previewHeaderTitle]: 'Share your meeting link',
  [ConferenceTranslation.previewNewMeetingTitle]: 'New meeting',
  [ConferenceTranslation.previewDescription]:
    'Copy the meeting link below and send it to people you want to meet with. If you save it, you can use it again later.',
  [ConferenceTranslation.previewJoinButton]: 'Join now',
  [ConferenceTranslation.previewCopyLinkButton]: 'Copy meeting link',
  [ConferenceTranslation.previewCloseButton]: 'Close',
  [ConferenceTranslation.previewLinkCopiedMessage]:
    'Link copied to your clipboard',
  // upcoming conference
  [ConferenceTranslation.scheduledUpcomingConferenceUpcoming]: 'Upcoming',
  [ConferenceTranslation.scheduledUpcomingConferencePast]: 'Past',
  [ConferenceTranslation.scheduledUpcomingConferenceScheduled]: 'Scheduled',
  [ConferenceTranslation.scheduledUpcomingConferenceEditMeeting]: 'Edit',
  [ConferenceTranslation.scheduledUpcomingConferenceCopyMeetingsLink]:
    'Copy meeting link',
  [ConferenceTranslation.scheduledUpcomingConferenceDeleteMeeting]: 'Delete',
  [ConferenceTranslation.scheduledUpcomingConferenceMeetingDate]: 'Date',
  [ConferenceTranslation.scheduledUpcomingConferenceMeetingLink]:
    'Meeting link',
  [ConferenceTranslation.scheduledUpcomingConferenceInvitees]: 'Invitees',
  [ConferenceTranslation.scheduledUpcomingConferenceCopyLinkToastText]:
    'The link was copied to the clipboard',
  [ConferenceTranslation.scheduledUpcomingConferenceCopyEmailToastText]:
    'The email was copied to the clipboard',
  [ConferenceTranslation.scheduledUpcomingConferenceJoin]: 'Join',
  [ConferenceTranslation.scheduledUpcomingConferenceOrganizer]: 'Organizer',
  [ConferenceTranslation.scheduledUpcomingConferenceIcalFile]:
    'Download iCal file',
  [ConferenceTranslation.scheduledUpcomingConferenceOptional]: '(optional)',
  [ConferenceTranslation.scheduledUpcomingConferenceTimeStartNoOptionsSelected]:
    'Example 11:32AM',
  [ConferenceTranslation.scheduledUpcomingConferenceMembersNoOptionsSelected]:
    'Select member or enter valid email',

  // chat
  [ConferenceTranslation.chatButton]: 'Chat',

  // schedule
  [ConferenceTranslation.scheduleConferenceMeeting]: 'Schedule meeting',
  [ConferenceTranslation.scheduleConferenceEmptyStateText]:
    'You have no scheduled meetings',
  [ConferenceTranslation.scheduleConferenceRepeatingInfo]:
    'The creation of your recurring scheduled meetings is being processed. It may take some time before all the meetings are shown in the scheduled meetings overview.',
  [ConferenceTranslation.scheduleConferenceFormTopic]: 'Topic',
  [ConferenceTranslation.scheduleConferenceEnterTopic]: 'Enter meeting topic',
  [ConferenceTranslation.scheduleConferenceFormDescription]: 'Description',
  [ConferenceTranslation.scheduleConferenceEnterDescription]: 'Add description',
  [ConferenceTranslation.scheduleConferenceFormDateTime]: 'Date & Time',
  [ConferenceTranslation.scheduleConferenceTo]: 'to',
  [ConferenceTranslation.scheduleConferenceFormMeetingId]: 'Meeting ID',
  [ConferenceTranslation.scheduleConferenceFormInvitees]: 'Add Invitees',
  [ConferenceTranslation.scheduleConferenceFormNameLabel]: 'Type name or email',
  [ConferenceTranslation.scheduleConferenceFormSave]: 'Save',
  [ConferenceTranslation.scheduleConferenceMembersIsRequired]:
    'Members is required',
  [ConferenceTranslation.scheduleConferenceMaxMembersForFreeWorkspace]:
    'You can invite only one member for free workspace type',
  [ConferenceTranslation.scheduleConferenceYouAreAddedAsOrganizer]:
    'You are added as the organizer',
  [ConferenceTranslation.scheduleConferenceTopicIsRequired]:
    'Topic is required',
  [ConferenceTranslation.scheduleConferenceStartTimeIsRequired]:
    'Start time is required',
  [ConferenceTranslation.scheduleConferenceEndTimeIsRequired]:
    'End time is required',
  [ConferenceTranslation.scheduleConferenceTimezoneIsRequired]:
    'Timezone is required',
  [ConferenceTranslation.scheduleConferenceStartAtInPast]:
    'Cannot schedule in past',
  [ConferenceTranslation.scheduleConferenceEarlierThenStartAt]:
    'Earlier than start date',

  // Repeating schedule conference
  [ConferenceTranslation.scheduleRepeatingConferenceCustomRecurrence]:
    'Custom recurrence',
  [ConferenceTranslation.scheduleRepeatingConferenceRepeatEvery]:
    'Repeat every',
  [ConferenceTranslation.scheduleRepeatingConferenceRepeatOn]: 'Repeat on',
  [ConferenceTranslation.scheduleRepeatingConferenceEnds]: 'Ends',
  [ConferenceTranslation.scheduleRepeatingConferenceNever]: 'Never',
  [ConferenceTranslation.scheduleRepeatingConferenceOn]: 'On',
  [ConferenceTranslation.scheduleRepeatingConferenceAfter]: 'After',
  [ConferenceTranslation.scheduleRepeatingConferenceDays]: 'day(s)',
  [ConferenceTranslation.scheduleRepeatingConferenceWeeks]: 'week(s)',
  [ConferenceTranslation.scheduleRepeatingConferenceMonthly]: 'monthly',
  [ConferenceTranslation.scheduleRepeatingConferenceOccurrences]: 'occurrences',
  [ConferenceTranslation.scheduleRepeatingConferenceDoesNotRepeat]:
    'Does not repeat',
  [ConferenceTranslation.scheduleRepeatingConferenceDoesCustomOption]:
    'Custom option',
  [ConferenceTranslation.scheduleRepeatingConferenceFirstWeekInMonth]: 'first',
  [ConferenceTranslation.scheduleRepeatingConferenceSecondWeekInMonth]:
    'second',
  [ConferenceTranslation.scheduleRepeatingConferenceThirdWeekInMonth]: 'third',
  [ConferenceTranslation.scheduleRepeatingConferenceFourthWeekInMonth]:
    'fourth',
  [ConferenceTranslation.scheduleRepeatingConferenceFifthWeekInMonth]: 'last',
  [ConferenceTranslation.scheduleRepeatingConferenceMonthlyOnDay]:
    'Monthly on day',
  [ConferenceTranslation.scheduleRepeatingConferenceMonthlyOnThe]:
    'Monthly on the',
  [ConferenceTranslation.scheduledRepeatingConferenceConfirmationModalTitle]:
    'Update all repeating conferences?',
  [ConferenceTranslation.scheduledConferenceUpdateConfirmationModalUpdateOne]:
    'Only this',
  [ConferenceTranslation.scheduledConferenceUpdateConfirmationModalUpdateThisAndFollowing]:
    'This and following',
  [ConferenceTranslation.scheduledConferenceUpdateConfirmationModalUpdateAll]:
    'All',
  [ConferenceTranslation.scheduledConferenceDeleteConfirmationModalUpdateOne]:
    'Only this',
  [ConferenceTranslation.scheduledConferenceDeleteConfirmationModalThisAndFollowing]:
    'This and following',
  [ConferenceTranslation.scheduledConferenceDeleteConfirmationModalUpdateAll]:
    'All',
  [ConferenceTranslation.scheduledConferenceMonday]: 'Monday',
  [ConferenceTranslation.scheduledConferenceTuesday]: 'Tuesday',
  [ConferenceTranslation.scheduledConferenceWednesday]: 'Wednesday',
  [ConferenceTranslation.scheduledConferenceThursday]: 'Thursday',
  [ConferenceTranslation.scheduledConferenceFriday]: 'Friday',
  [ConferenceTranslation.scheduledConferenceSaturday]: 'Saturday',
  [ConferenceTranslation.scheduledConferenceSunday]: 'Sunday',
  [ConferenceTranslation.scheduledConferenceDaily]: 'Daily',
  [ConferenceTranslation.scheduledConferenceWeekly]: 'Weekly',
  [ConferenceTranslation.scheduledConferenceMonthly]: 'Monthly',
  [ConferenceTranslation.scheduledConferenceTimes]: 'times',
  [ConferenceTranslation.scheduledConferenceOn]: 'on',
  [ConferenceTranslation.scheduledConferenceEvery]: 'every',
  [ConferenceTranslation.scheduledConferenceDay]: 'day',
  [ConferenceTranslation.scheduledConferenceUntil]: 'until',
  [ConferenceTranslation.scheduledConferenceMonth]: 'month',
  [ConferenceTranslation.scheduledConferenceWeek]: 'week',
  [ConferenceTranslation.scheduledConferenceAdd]: 'Add',
  [ConferenceTranslation.scheduledConferenceEmptyMeetingLink]:
    'A meeting link will be generated when you create the meeting',
  [ConferenceTranslation.scheduledConferenceDeclineMeeting]: 'Decline meeting',
  [ConferenceTranslation.scheduledConferenceDeclineSingleConference]:
    'Decline only this',
  [ConferenceTranslation.scheduledConferenceDeclineAllConferences]:
    'Decline all',
  [ConferenceTranslation.scheduledConferenceDeclineConfirmationTitle]:
    'Are you sure you want to decline this meeting?',
  [ConferenceTranslation.scheduledConferenceDeclineConfirmationMessage]:
    'All the meetings in this recurring meeting series will disappear from your meeting list and you will be not able to redo this action',
  [ConferenceTranslation.scheduledConferenceDeclineConfirmationMessageSingle]:
    'The meeting will disappear from your meeting list and you will be not able to redo this action.',
  [ConferenceTranslation.scheduledConferenceDeleteConfirmationTitle]:
    'Are you sure you want to delete the meeting?',
  [ConferenceTranslation.scheduledConferenceDeleteConfirmationMessage]:
    'The meeting will be deleted for all the meeting participants and they will receive a cancellation notification on email.',
  [ConferenceTranslation.scheduledConferenceDeleteConfirmationTitleRepeating]:
    'Are you sure you want to delete this recurring meeting?',
  [ConferenceTranslation.scheduledConferenceDeleteConfirmationMessageRepeating]:
    'The meeting / meetings in this recurring meeting series will be deleted for all the meeting participants and they will receive a cancellation notification on email.',

  [ConferenceTranslation.tooltipScheduleMeeting]: 'Schedule a meeting',
  [ConferenceTranslation.tooltipStartNewScheduleMeeting]:
    'Start or share a new meeting',
  [ConferenceTranslation.tooltipTabPastCalls]: 'Your past calls',
  [ConferenceTranslation.tooltipTabScheduleMeeting]:
    'Upcoming and past meetings',
};
