import {
  StreamEventRequest,
  StreamLinkEvent,
} from '../../GeneralMercure.types';

import {
  linkRequestNew,
  linkRequestRemove,
  linkRequestUpdate,
} from './Requests';

export const linkRequest = (event: StreamLinkEvent) => {
  switch (event['@request']) {
    case StreamEventRequest.NEW:
      linkRequestNew(event);
      break;
    case StreamEventRequest.REMOVE:
      linkRequestRemove(event);
      break;
    case StreamEventRequest.UPDATE:
      linkRequestUpdate(event);
      break;
    default:
      break;
  }
};
