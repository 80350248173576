import Dexie, { Table } from 'dexie';
import type { ChatConversationsTableType } from './ChatConversationsTable/ChatConversationsTable';
import { CHAT_CONVERSATIONS_TABLE_COLUMNS } from './ChatConversationsTable/ChatConversationsTable';
import type { ChatMessagesTableType } from './ChatMessagesTable/ChatMessagesTable';
import { CHAT_MESSAGES_TABLE_COLUMNS } from './ChatMessagesTable/ChatMessagesTable';
import type { DesktopAppsTableType } from './DesktopAppsTable/DesktopAppsTable';
import { DESKTOP_APPS_TABLE_COLUMNS } from './DesktopAppsTable/DesktopAppsTable';
import type { DesktopFoldersTableType } from './DesktopFoldersTable/DesktopFoldersTable';
import { DESKTOP_FOLDERS_TABLE_COLUMNS } from './DesktopFoldersTable/DesktopFoldersTable';
import type { DesktopLinksTableType } from './DesktopLinksTable/DesktopLinksTable';
import { DESKTOP_LINKS_TABLE_COLUMNS } from './DesktopLinksTable/DesktopLinksTable';
import type { DesktopsTableType } from './DesktopTable/DesktopsTable';
import { DESKTOPS_TABLE_COLUMNS } from './DesktopTable/DesktopsTable';
import { captureMessage } from '@sentry/react';
import type { TeamsTableType } from './WorkspaceTeams/WorkspaceTeamsTable';
import { TEAMS_COLUMNS } from './WorkspaceTeams/WorkspaceTeamsTable';
import type { AccessTableType } from './AccessTable/AccessTable';
import { ACCESS_COLUMNS } from './AccessTable/AccessTable';
import type { CurrentAccountTableType } from '../Account/data/CurrentAccount/types/CurrentAccountIDDB.types';
import { CURRENT_ACCOUNT_TABLE_COLUMNS } from '../Account/data/CurrentAccount/types/CurrentAccountIDDB.types';
import type { AccountsTableType } from '../Account/data/Account/types/AccountIDDB.types';
import { ACCOUNTS_TABLE_COLUMNS } from '../Account/data/Account/types/AccountIDDB.types';
import {
  WORKSPACE_INFO_TABLE_COLUMNS,
  WorkspaceInfoTableType,
} from '../WorkspaceInfo/data/WorkspaceInfo/types/WorkspaceInfoIDDB.types';
import {
  INVITATIONS_TABLE_COLUMNS,
  InvitationsTableType,
} from '../Invitation/data/Invitation/types/InvitationIDDB.types';
import { isMatchingPath } from '../../shared/utils/url.utils';
import { LOGOUT_PATHNAME } from '../Auth/Auth.constants';

export const DATABASE_NAME = 'desktop-com-database';

// https://dexie.org/docs/Tutorial/Design#database-versioning
const DATABASE_VERSION = 8;

class DexieDatabase extends Dexie {
  currentAccount!: Table<CurrentAccountTableType>;
  accounts!: Table<AccountsTableType>;
  invitations!: Table<InvitationsTableType>;
  chatMessages!: Table<ChatMessagesTableType>;
  chatConversations!: Table<ChatConversationsTableType>;
  desktopLinks!: Table<DesktopLinksTableType>;
  desktopFolders!: Table<DesktopFoldersTableType>;
  desktopApps!: Table<DesktopAppsTableType>;
  desktops!: Table<DesktopsTableType>;
  teams!: Table<TeamsTableType>;
  access!: Table<AccessTableType>;
  workspaceInfo!: Table<WorkspaceInfoTableType>;

  constructor() {
    super(DATABASE_NAME);

    this.version(DATABASE_VERSION).stores({
      currentAccount: CURRENT_ACCOUNT_TABLE_COLUMNS,
      accounts: ACCOUNTS_TABLE_COLUMNS,
      invitations: INVITATIONS_TABLE_COLUMNS,
      chatMessages: CHAT_MESSAGES_TABLE_COLUMNS,
      chatConversations: CHAT_CONVERSATIONS_TABLE_COLUMNS,
      desktopLinks: DESKTOP_LINKS_TABLE_COLUMNS,
      desktopFolders: DESKTOP_FOLDERS_TABLE_COLUMNS,
      desktopApps: DESKTOP_APPS_TABLE_COLUMNS,
      desktops: DESKTOPS_TABLE_COLUMNS,
      teams: TEAMS_COLUMNS,
      access: ACCESS_COLUMNS,
      workspaceInfo: WORKSPACE_INFO_TABLE_COLUMNS,
    });

    this.handleUnhandledRejection();
  }

  handleUnhandledRejection() {
    window.addEventListener(
      'unhandledrejection',
      (error: PromiseRejectionEvent) => {
        if (
          error?.reason?.name === Dexie.errnames.DatabaseClosed &&
          !isMatchingPath(window.location.href, LOGOUT_PATHNAME)
        ) {
          captureMessage(
            '%Database.ts line:59 = User logged out after unhandledrejection of IDDB',
          );

          window.location.replace('/logout');
        }
      },
    );
  }
}

export const database = new DexieDatabase();
