import React, { FC, PropsWithChildren } from 'react';
import { useMercureListenerEffect } from '../../Mercure/General/GeneralMercure.hooks';
import { StreamEventRequest, StreamEventType } from '../../Mercure/General';
import { InvitationMercureEventToApiType } from '../data/Invitation/utils/InvitationMercure.utils';
import {
  bulkPutInvitationsToIDDB,
  deleteInvitationFromIDDB,
} from '../data/Invitation/operations/InvitationIDDB.operations';

export const InvitationMercureProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  useMercureListenerEffect(e => {
    if (e?.['@type'] === StreamEventType.ACCOUNT_INVITATION) {
      switch (e?.['@request']) {
        case StreamEventRequest.NEW:
          const invitation = InvitationMercureEventToApiType(e);
          bulkPutInvitationsToIDDB(
            [invitation],
            invitation.referenceId,
            invitation.desktopId,
          );
          break;
        case StreamEventRequest.REMOVE:
          deleteInvitationFromIDDB(e.id);
          break;
      }
    }
  });

  return <>{children}</>;
};
