import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { format, formatDistance, formatRelative } from 'date-fns';
import { DateLocales } from '../../domains/Intl/i18n/i18n.dates';

export const useDateFormatters = (): {
  format: typeof format;
  formatRelative: typeof formatRelative;
  formatDistance: typeof formatDistance;
} => {
  const { locale } = useIntl();
  const dateFnsLocale = DateLocales[locale];
  return {
    format: useCallback(
      (...args) => {
        return format(args[0], args[1], {
          locale: dateFnsLocale,
          ...args[2],
        });
      },
      [dateFnsLocale],
    ),
    formatRelative: useCallback(
      (...args) => {
        return formatRelative(args[0], args[1], {
          locale: dateFnsLocale,
          ...args[2],
        });
      },
      [dateFnsLocale],
    ),
    formatDistance: useCallback(
      (...args) => {
        return formatDistance(args[0], args[1], {
          locale: dateFnsLocale,
          ...args[2],
        });
      },
      [dateFnsLocale],
    ),
  };
};
