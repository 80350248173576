import { ApolloClient } from '@apollo/client';
import { StreamNotificationEvent } from '../../../GeneralMercure.types';
import { updateNotificationInCache } from '../../../../../Notifications/cache';

export const notificationRequestUpdate = (
  event: StreamNotificationEvent,
  apolloClient: ApolloClient<object>,
) => {
  updateNotificationInCache(apolloClient, event.id, {
    status: event.status,
    message: {
      id: event.message.id,
      fields: event.message.fields,
    },
  });
};
