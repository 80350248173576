import { StreamRemoveWorkspaceTeamAccountEvent } from '../../../GeneralMercure.types';
import { removeRelatedTeamsFromAccountsIDDB } from '../../../../../Account/data/TeamAccounts/operations/TeamAccount.operations';
import { removeRelatedAccountsFromTeamsIDDB } from '../../../../../Team/data/AccountTeam/operations/AccountTeamIDDB.oprations';

export const removeWorkspaceTeamAccountActionRequest = async (
  event: StreamRemoveWorkspaceTeamAccountEvent,
) => {
  if (event['@payload']?.workspaceTeamId && event['@payload']?.accountId) {
    removeRelatedTeamsFromAccountsIDDB(
      [event['@payload']?.accountId],
      [event['@payload']?.workspaceTeamId],
    );
    removeRelatedAccountsFromTeamsIDDB(
      [event['@payload']?.workspaceTeamId],
      [event['@payload']?.accountId],
    );
  }
};
