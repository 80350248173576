import { TimelineLocale, TimelineTranslation } from './i18n.types';

export const enTimelineLocale: TimelineLocale = {
  [TimelineTranslation.headerTitle]: 'Timeline',

  [TimelineTranslation.openTimelineButton]: 'Open timeline',
  [TimelineTranslation.closeTimelineButton]: 'Close timeline',

  [TimelineTranslation.noEventsMessage]: 'No events to display',
  [TimelineTranslation.loadMoreEventsButton]: 'Load more',

  [TimelineTranslation.eventTime]: 'at {time}',

  [TimelineTranslation.eventsFilterInputPlaceholder]: 'Filter all activities',

  [TimelineTranslation.eventsFilterOptionsHeading]: 'Search options',
  [TimelineTranslation.eventsFilterOptionsApps]: 'Apps',
  [TimelineTranslation.eventsFilterOptionsLinks]: 'Links',
  [TimelineTranslation.eventsFilterOptionsEvents]: 'Events',
  [TimelineTranslation.eventsFilterOptionsAll]: 'All',
  [TimelineTranslation.eventTitleTheApp]: 'The App ',
  [TimelineTranslation.eventTitleTheLink]: 'The Link ',
  [TimelineTranslation.eventTitleTheAppsSection]: 'The Apps Section ',
  [TimelineTranslation.eventTitleTheLinksSection]: 'The Links Section ',

  /*
   * Day date custom formats
   * */

  [TimelineTranslation.dayDateToday]: 'Today',
  [TimelineTranslation.dayDateYesterday]: 'Yesterday',

  /*
   * Timeline event messages
   * */

  [TimelineTranslation.eventDesktopCreated]: '{desktopInfo} was created',
  [TimelineTranslation.eventDesktopDeleted]: '{desktopInfo} was removed',

  [TimelineTranslation.eventTeamAddedToDesktop]:
    'The group {teamInfo} was added {desktopInfo}',
  [TimelineTranslation.eventTeamRemovedFromDesktop]:
    'The group {teamInfo} was removed {desktopInfo}',

  [TimelineTranslation.eventMemberAddedToDesktop]:
    '{accountInfo} was added {desktopInfo}',
  [TimelineTranslation.eventMemberRemovedFromDesktop]:
    '{accountInfo} was removed {desktopInfo}',

  [TimelineTranslation.eventMemberLeftTheDesktop]:
    '{accountInfo} left the desktop',

  [TimelineTranslation.eventUserAddedDesktop]:
    '{memberInfo} was added {desktopInfo}',
  [TimelineTranslation.eventUserRemovedFromDesktop]:
    '{memberInfo} was removed {desktopInfo}',

  [TimelineTranslation.eventAddTeam]:
    '<span class="highlighted">{teamName}</span> was added',
  [TimelineTranslation.eventTeamRemoved]:
    '<span class="highlighted">{teamName}</span> was removed',

  [TimelineTranslation.eventAccountAddToTeam]:
    '<span class="highlighted">{memberName}</span> was added to <span class="highlighted">{teamName}</span>',
  [TimelineTranslation.eventAccountAddedToTeam]:
    '<span class="highlighted">This account</span> was added to <span class="highlighted">{teamName}</span>',

  [TimelineTranslation.eventAccountToRemoveFromTeam]:
    '<span class="highlighted">{memberName}</span> was removed from <span class="highlighted">{teamName}</span>',
  [TimelineTranslation.eventAccountRemovedFromTeam]:
    '<span class="highlighted">This account</span> was removed from <span class="highlighted">{teamName}</span>',

  [TimelineTranslation.eventAppOpened]: '{appInfo} was opened {desktopInfo}',
  [TimelineTranslation.eventAppAddedToDesktop]: '{appInfo} was created',
  [TimelineTranslation.eventAppRemovedFromDesktop]: '{appInfo} was removed',
  [TimelineTranslation.eventCustomAppAddedToDesktop]:
    '{appInfo} was added {desktopInfo}',
  [TimelineTranslation.eventCustomAppRemovedFromDesktop]:
    '{appInfo} was removed {desktopInfo}',

  [TimelineTranslation.eventLinkOpened]:
    '{linkInfo} was opened {folderInfo} {desktopInfo}',
  [TimelineTranslation.eventLinkAddedToDesktop]:
    '{linkInfo} was added {desktopInfo}',
  [TimelineTranslation.eventLinkUpdated]:
    '{linkInfo} was updated {desktopInfo}',
  [TimelineTranslation.eventLinkMovedToFolder]:
    '{linkInfo} was moved to {folderInfo}',
  [TimelineTranslation.eventLinkMovedFromFolder]:
    '{linkInfo} was moved from {folderInfo}',
  [TimelineTranslation.eventLinkRemovedFromDesktop]:
    '{linkInfo} was removed {desktopInfo}',
  [TimelineTranslation.eventLinkComment]:
    '{linkInfo} was commented {desktopInfo}',

  [TimelineTranslation.eventFolderAddedToDesktop]:
    '{folderInfo} was added {desktopInfo}',
  [TimelineTranslation.eventFolderRemovedFromDesktop]:
    '{folderInfo} was removed {desktopInfo}',

  [TimelineTranslation.eventTagAddedToLink]:
    '<span class="highlighted">{tagName}</span> was added',

  [TimelineTranslation.eventPaymentFailed]:
    '<span class="highlighted">Payment</span> was failed',

  [TimelineTranslation.eventGuestLimitExceeded]:
    'Limit of <span class="highlighted">{desktopName}</span> guests was exceeded',

  [TimelineTranslation.eventAccountActivated]:
    '<span class="highlighted">This account</span> was activated for <span class="highlighted">{workspaceName}</span>',
  [TimelineTranslation.eventAccountToActivate]:
    '<span class="highlighted">{memberName}</span> was activated for <span class="highlighted">{workspaceName}</span>',
  [TimelineTranslation.eventAccountToSuspend]:
    '<span class="highlighted">{memberName}</span> was suspended for <span class="highlighted">{workspaceName}</span>',
  [TimelineTranslation.eventAccountSuspended]:
    '<span class="highlighted">This account</span> was suspended for <span class="highlighted">{workspaceName}</span>',
  [TimelineTranslation.eventAccountToRemove]:
    '<span class="highlighted">{memberName}</span> was removed from <span class="highlighted">{workspaceName}</span>',
  [TimelineTranslation.eventAccountRemoved]:
    '<span class="highlighted">This account</span> was removed from <span class="highlighted">{workspaceName}</span>',
  [TimelineTranslation.eventAccountToAssignAdmin]:
    '<span class="highlighted">{memberName}</span> was assigned as <span class="highlighted">admin</span>',
  [TimelineTranslation.eventAccountAssignedAdmin]:
    '<span class="highlighted">This account</span> was assigned as <span class="highlighted">admin</span>',
  [TimelineTranslation.eventAccountLostOwner]:
    '<span class="highlighted">{memberName}</span> lost the <span class="highlighted">owner</span> role',
  [TimelineTranslation.eventAccountGainedOwner]:
    '<span class="highlighted">This account</span> was assigned as <span class="highlighted">owner</span>',

  [TimelineTranslation.eventCreatedBy]: 'By {memberName}',
  [TimelineTranslation.eventCreatedByMeLabel]: 'You',
  [TimelineTranslation.eventThisAccountLabel]: 'This account',
  [TimelineTranslation.eventToLabel]: 'to',
  [TimelineTranslation.eventFromLabel]: 'from',
  [TimelineTranslation.eventInLabel]: 'in',

  [TimelineTranslation.eventAppSectionRemoved]:
    'Apps section was removed ({appsNumber} {appsNumber, plural, one {app} other {apps}})',
  [TimelineTranslation.eventLinkSectionRemoved]:
    'Links section was removed ({linksNumber} {linksNumber, plural, one {link} other {links}})',

  [TimelineTranslation.eventLinkDetailsAdd]: 'Added',
  [TimelineTranslation.eventLinkDetailsUpdate]: 'Updated',
  [TimelineTranslation.eventLinkDetailsComment]: 'Commented',
  [TimelineTranslation.eventLinkDetailsOpen]: 'Opened link',

  [TimelineTranslation.eventUndoMenuItem]: 'Undo',
  [TimelineTranslation.eventUnDoItemConfirmationTitle]:
    'Are you sure you want to restore {itemName}? ',
  [TimelineTranslation.eventUnDoItemConfirmationDescription]:
    '{itemType} will be available for anyone and all Webtop.com members will receive a notification.',

  [TimelineTranslation.eventRestoreItemMessage]: '{itemType} was restored',
  [TimelineTranslation.eventAppSectionRestoreTitle]:
    'Apps section ({appsNumber} {appsNumber, plural, one {app} other {apps}})',
  [TimelineTranslation.eventLinkSectionRestoreTitle]:
    'Links section ({linksNumber} {linksNumber, plural, one {link} other {links}})',
};
