import styled from 'styled-components';
import { BorderedListItem } from '../../shared/components/List';
import { Link } from 'react-router-dom';
import { Breakpoints } from '../Theme';

export const AdminList = styled.ul`
  @media (max-width: ${Breakpoints.md - 1}px) {
    padding: 0 20px;
  }
`;

export const AdminListItem = styled(BorderedListItem)`
  display: flex;
  align-items: center;
  min-height: 68px;
  //word-break: break-all;
  //word-break: break-word;

  & ~ & {
    margin-top: 12px;
  }

  & > * {
    width: 100%;
  }
`;

export const AdminListItemLink = styled(Link)`
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const AdminListEmptyMessage = styled.div.attrs({
  'data-testid': 'empty-list',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 68px;
  padding: 12px;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-size: 1rem;
  line-height: 19px;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  box-sizing: border-box;
  border-radius: 8px;
  word-break: break-all;
  word-break: break-word;
`;
