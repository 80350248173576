import uniqBy from 'lodash/uniqBy';
import { parseISO } from 'date-fns';
import { getShortId } from '../../shared/utils/id';
import { AccountApiType } from '../User/User.types';
import type {
  BaseChatMessageInternalType,
  ChatConferenceMessageApiType,
  ChatConversationApiType,
  ChatConversationInternalType,
  ChatConversationRestApiType,
  ChatConversationUserContext,
  ChatItemType,
  ChatMessageApiType,
  ChatMessageInternalType,
  ChatSystemMessageApiType,
  ChatTextMessageApiType,
  ChatTextMessageContext,
  ChatTextMessageInternalType,
} from './Chat.types';
import { MessageType } from './Chat.types';
import mentionSoundMp3 from './sounds/mentionSound.mp3';
import { NewChatConversationEvent } from '../Mercure/Chat/ChatMercure.types';

export const getConversationPinnedDate = (
  conversation: ChatConversationApiType,
  currentAccount: AccountApiType,
) => {
  if (!conversation.accountsThatPinned.length) {
    return false;
  }

  const accountPinnedData = conversation.accountsThatPinned.filter(
    accountThatPinned => accountThatPinned.accountIri === currentAccount.id,
  );

  return accountPinnedData.length ? accountPinnedData[0].pinnedAt : false;
};

export function sortByPinnedDate<T>(
  prop: keyof T,
  currentAccount: AccountApiType,
) {
  return (a: any, b: any) => {
    const pinnedData = [...a[prop], ...b[prop]].map(elem => elem.accountIri);

    if (pinnedData.includes(currentAccount.id)) {
      const pinnedAtA = getConversationPinnedDate(a, currentAccount);
      const pinnedAtB = getConversationPinnedDate(b, currentAccount);

      if (!pinnedAtA) {
        return 0;
      }

      if (!pinnedAtB) {
        return -1;
      }

      return pinnedAtA < pinnedAtB ? 1 : -1;
    }

    return 0;
  };
}

export const getChatMessageIri = (shortId: string) =>
  `/chat-messages/${shortId}`;

export const getChatConversationIri = (shortId: string) =>
  `/chat-conversations/${shortId}`;

export const playMentionSound = (() => {
  let soundUrl = mentionSoundMp3;

  // this check is here because fetch is not defined in jest
  if (window.fetch) {
    window
      .fetch(mentionSoundMp3)
      .then(response => response.blob())
      .then(blob => {
        soundUrl = URL.createObjectURL(blob);
      });
  }

  return () => {
    const audioNode = new Audio(soundUrl);
    audioNode.play();
  };
})();

export const isChatMessageSeen = (
  chatMessage: Pick<ChatMessageInternalType, 'isSeen'>,
  accountId: string,
) => chatMessage.isSeen;

/**
 * Conversation scroll utils
 * Keeps track of which message index is the current active scroll
 * location for each conversation
 */
type ConversationScrollType = {
  index: number;
  chatItemsCount: number;
  id: string;
};

const CONVERSATION_SCROLL_KEY = 'conversation:scroll:';

//!@Nika_Beridze remove localStorage logic and make logic in context that will save scroll positions if REF
export const getConversationScroll = (
  conversationId: string,
): ConversationScrollType | null => {
  const data = localStorage.getItem(
    `${CONVERSATION_SCROLL_KEY}${getShortId(conversationId)}`,
  );
  return data ? JSON.parse(data) : null;
};

export const setConversationScroll = (data: ConversationScrollType) => {
  localStorage.setItem(
    `${CONVERSATION_SCROLL_KEY}${getShortId(data.id)}`,
    JSON.stringify(data),
  );
};

export const clearConversationScroll = (conversationId: string) => {
  localStorage.removeItem(
    `${CONVERSATION_SCROLL_KEY}${getShortId(conversationId)}`,
  );
};

/**
 * Get the index of a messageState message id
 * so we can scroll to it properly
 */
export const getSearchMessageIndex = (
  chatItems: ChatItemType[],
  searchMessageId?: string,
) => {
  if (!searchMessageId) return null;
  return chatItems.findIndex(item =>
    'id' in item && item.id === searchMessageId ? true : false,
  );
};

export const mergeMessagesArrays = (
  A: ChatMessageApiType[],
  B: ChatMessageApiType[],
): ChatMessageApiType[] => {
  if (!A.length) return B;
  if (!B.length) return A;
  return uniqBy([...A, ...B], '_id');
};

export const convertRestApiChatConversationToInternal = (
  chatConversation: ChatConversationRestApiType,
  userContext: {
    workspaceId: string;
    accountId: string;
  },
): ChatConversationInternalType => {
  return {
    id: chatConversation['@id'],
    workspaceId: userContext.workspaceId,
    title: chatConversation.conversationTitle,
    type: chatConversation.type,
    userIds: chatConversation.users,
    lastMessageAt: chatConversation.lastMessageAt,
    createdAt: chatConversation.createdAt,
    createdById: chatConversation.createdByIri,
    pendingEmails: chatConversation.pendingEmails,
    desktopId: chatConversation.desktopIri,
    pinnedAt:
      chatConversation.accountsThatPinned.find(
        ({ accountIri }) => accountIri === userContext.accountId,
      )?.pinnedAt ?? null,
    isHidden: !!chatConversation.accountsThatHidden.find?.(
      ({ accountIri }) => accountIri === userContext.accountId,
    )?.hiddenAt,
    isHiddenAt:
      chatConversation.accountsThatHidden.find?.(
        ({ accountIri }) => accountIri === userContext.accountId,
      )?.hiddenAt || null,
  };
};

export const convertGraphQLApiChatConversationToInternal = (
  chatConversation: ChatConversationApiType | NewChatConversationEvent,
  userContext: ChatConversationUserContext,
): ChatConversationInternalType => {
  const isChatMercureEventData = '@iri' in chatConversation;

  const chatConversationId = isChatMercureEventData
    ? chatConversation['@iri']
    : null;

  const chatDesktopId =
    isChatMercureEventData && chatConversation.desktop
      ? chatConversation.desktop.id
      : null;

  return {
    id: chatConversationId || chatConversation.id,
    workspaceId: userContext.workspaceId,
    title: chatConversation.conversationTitle,
    type: chatConversation.type,
    userIds: chatConversation.users,
    lastMessageAt: chatConversation.lastMessageAt,
    createdAt: chatConversation.createdAt,
    createdById: chatConversation.createdByIri,
    pendingEmails: chatConversation.pendingEmails,
    desktopId: chatConversation.desktopIri || chatDesktopId,
    pinnedAt:
      chatConversation.accountsThatPinned.find(
        ({ accountIri }) => accountIri === userContext.accountId,
      )?.pinnedAt ?? null,
    isHidden: !!chatConversation.accountsThatHidden.find?.(
      ({ accountIri }) => accountIri === userContext.accountId,
    )?.hiddenAt,
    isHiddenAt:
      chatConversation.accountsThatHidden.find?.(
        ({ accountIri }) => accountIri === userContext.accountId,
      )?.hiddenAt || null,
  };
};

export const convertGraphQLApiThreadChatMessageToInternal = (
  threadChatMessage: ChatMessageApiType,
  baseMessage: Omit<BaseChatMessageInternalType, 'type'>,
  userContext: {
    accountId: string;
  },
): ChatTextMessageInternalType | null => {
  if (threadChatMessage) {
    return {
      ...baseMessage,
      id: threadChatMessage.id,
      type: MessageType.ThreadMessage,
      parentId: threadChatMessage.parentChatMessageIri,
      authorId: threadChatMessage.accountIri
        ? threadChatMessage.accountIri
        : null,
      isModified: threadChatMessage.modified,
      reactions: threadChatMessage.reactions.map(item => ({
        emoji: item.emoji,
        userIds: item.accounts,
      })),
      microtimeAt: threadChatMessage.microtimeAt,
      createdAt: threadChatMessage.microtimeAt
        ? new Date(parseInt(threadChatMessage.microtimeAt, 10) / 1000)
        : parseISO(threadChatMessage.createdAt),
      message: threadChatMessage.message as string,
      context: (threadChatMessage.context as ChatTextMessageContext) || {},
      threadMessagesCount: 0,
      isSeen: threadChatMessage.seenBy.includes(userContext.accountId),
      linkData: threadChatMessage.linkData || null,
    };
  }

  return null;
};

export const convertGraphQLApiChatMessageToInternal = (
  chatMessage: ChatMessageApiType,
  userContext: {
    conversationId: string;
    accountId: string;
  },
  firstUnreadMessage?: ChatMessageApiType | null,
): ChatMessageInternalType => {
  const baseMessage: Omit<BaseChatMessageInternalType, 'type'> = {
    id: chatMessage.id,
    parentId: chatMessage.parentChatMessageIri || '',
    conversationId: userContext.conversationId,
    accountIri: chatMessage.accountIri,
    authorId: chatMessage.accountIri ? chatMessage.accountIri : null,
    createdAt: chatMessage.microtimeAt
      ? new Date(parseInt(chatMessage.microtimeAt, 10) / 1000)
      : parseISO(chatMessage.createdAt),
    isModified: chatMessage.modified,
    isSeen: chatMessage.seenBy.includes(userContext.accountId),
    seenBy: chatMessage.seenBy,
    threadMessagesCount: chatMessage.threadMessagesCount,
    reactions: chatMessage.reactions.map(item => ({
      emoji: item.emoji,
      userIds: item.accounts,
    })),
    microtimeAt: chatMessage.microtimeAt,
    message: chatMessage.message ?? '',
    isFirstUnread: chatMessage.id === firstUnreadMessage?.id,
    isOptimistic: chatMessage.isOptimistic || false,
    lastMessageInThreadAt: chatMessage.lastMessageInThreadAt
      ? parseISO(chatMessage.lastMessageInThreadAt)
      : null,
    threadMessagesAccounts: chatMessage.threadMessagesAccounts,
    linkData: chatMessage.linkData || null,
  };

  if (chatMessage.type === MessageType.System) {
    return {
      ...baseMessage,
      type: MessageType.System,
      authorId: null,
      context: {},
    };
  }
  if (chatMessage.type === MessageType.Conference) {
    return {
      ...baseMessage,
      type: MessageType.Conference,
      context: chatMessage.context,
    };
  }

  return {
    ...baseMessage,
    type: chatMessage.type,
    context: chatMessage.context,
  };
};

export const makeChatConversationIri = (id: string) =>
  `/chat-conversations/${id}`;

export const convertInternalToGraphQLApiChatMessage = (
  chatMessage: ChatMessageInternalType,
  userContext: {
    accountId: string;
  },
): ChatMessageApiType => {
  const message = {
    id: chatMessage.id,
    type: chatMessage.type,
    message: chatMessage.message ?? '',
    createdAt: chatMessage.createdAt.toString(),
    microtimeAt: '',
    modified: false,
    reactions: chatMessage.reactions.map(reaction => ({
      emoji: reaction.emoji,
      accounts: reaction.userIds,
    })),
    context: chatMessage.context,
    threadMessagesCount: chatMessage.threadMessagesCount,
    parentChatMessageIri: chatMessage.parentId ?? '',
    seenBy: chatMessage.isSeen ? [userContext.accountId] : [],
    accountIri: chatMessage.authorId ? chatMessage.authorId : null,
    isFirstUnread: chatMessage.isFirstUnread,
  };

  if (chatMessage.type === MessageType.System) {
    return {
      ...message,
      accountIri: null,
      context: [],
      isFirstUnread: false,
    } as ChatSystemMessageApiType;
  }

  if (chatMessage.type === MessageType.Conference) {
    return {
      ...message,
      message: null,
      isFirstUnread: false,
    } as ChatConferenceMessageApiType;
  }

  return message as ChatTextMessageApiType;
};
