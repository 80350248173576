export enum ModalAnimation {
  fadeIn = 'fadeIn',
  slideUp = 'slideUp',
  none = 'none',
}

export enum ModalThemeOverride {
  DARK = 'dark',
  LIGHT = 'light',
}
