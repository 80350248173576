import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const SearchIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.5 7c0 .591-.117 1.176-.343 1.722-.226.546-.557 1.042-.975 1.46-.418.418-.914.75-1.46.976-.546.226-1.131.342-1.722.342s-1.176-.116-1.722-.342c-.546-.227-1.042-.558-1.46-.976-.418-.418-.75-.914-.976-1.46C2.616 8.176 2.5 7.591 2.5 7c0-1.193.474-2.338 1.318-3.182C4.662 2.974 5.806 2.5 7 2.5c1.193 0 2.338.474 3.182 1.318C11.026 4.662 11.5 5.807 11.5 7zm-.82 4.74c-1.206.936-2.723 1.377-4.242 1.234-1.52-.143-2.928-.86-3.937-2.005C1.49 9.825.956 8.34 1.003 6.813c.048-1.526.676-2.976 1.755-4.055 1.08-1.08 2.53-1.707 4.055-1.754C8.338.956 9.825 1.49 10.969 2.5c1.145 1.01 1.862 2.417 2.005 3.937.143 1.52-.298 3.036-1.234 4.242l3.04 3.04c.074.069.133.151.174.243.04.093.063.192.064.293.002.1-.016.2-.054.294-.038.093-.094.178-.165.249-.071.071-.156.127-.25.165-.093.038-.193.056-.294.055-.1-.002-.2-.024-.292-.065-.092-.041-.174-.1-.243-.174l-3.04-3.04z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
});
