import { GraphQLError } from 'graphql/error/GraphQLError';
import { getApolloClient } from '../../../Api/Api.utils';
import { GET_DESKTOP_FOLDERS_REST } from '../Operations/Queries/GetDesktopFolders.query';
import { GET_LINKS } from '../../../Link/Link.queries';
import { LINKS_PER_QUERY } from '../../../Link/LinksView/SectionLinks/SectionLinks.constants';
import { GET_DESKTOP_REST } from '../Operations/Queries/GetDesktop.query';
import { GET_DESKTOP_APPS_REST } from '../Operations/Queries/GetDesktopApps.query';
import { GET_DESKTOP_CHAT_REST } from '../Operations/Queries/GetDesktopChat.query';
import { GET_DESKTOP_FOLDER_LINKS_REST } from '../Operations/Queries/GetDesktopFolderLinks.query';
import { GET_DESKTOP_LINKS_REST } from '../Operations/Queries/GetDesktopLinks.query';
import type {
  GetLinksResponse,
  GetLinksVariables,
} from '../../../Link/Link.queries';
import type {
  GetDesktopRestResponse,
  GetDesktopRestVariables,
} from '../Operations/Queries/GetDesktop.query';
import type {
  GetDesktopAppsRestResponse,
  GetDesktopAppsRestVariables,
} from '../Operations/Queries/GetDesktopApps.query';
import type {
  GetDesktopChatRestResponse,
  GetDesktopChatRestVariables,
} from '../Operations/Queries/GetDesktopChat.query';
import type { GetDesktopFolderLinksRestVariables } from '../Operations/Queries/GetDesktopFolderLinks.query';
import type {
  GetDesktopFoldersRestResponse,
  GetDesktopFoldersRestVariables,
} from '../Operations/Queries/GetDesktopFolders.query';
import type {
  GetDesktopLinksRestResponse,
  GetDesktopLinksRestVariables,
} from '../Operations/Queries/GetDesktopLinks.query';

/**
 * Get desktop from API
 * @param desktopId
 * @returns Desktop data
 */
export const getDesktopRest = async (
  desktopId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: GetDesktopRestResponse;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.query<
      GetDesktopRestResponse,
      GetDesktopRestVariables
    >({
      query: GET_DESKTOP_REST,
      variables: {
        desktopId: desktopId,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Get desktop links from API
 * @param desktopId
 * @returns Desktop links
 */
export const getDesktopLinksRest = async (
  desktopId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: GetDesktopLinksRestResponse;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.query<
      GetDesktopLinksRestResponse,
      GetDesktopLinksRestVariables
    >({
      query: GET_DESKTOP_LINKS_REST,
      variables: {
        desktopId: desktopId,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Get desktop links for folder from API
 * @param desktopId
 * @param folderId
 * @returns Desktop links
 */
export const getDesktopFolderLinksRest = async (
  desktopId: string,
  folderId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: GetDesktopLinksRestResponse;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.query<
      GetDesktopLinksRestResponse,
      GetDesktopFolderLinksRestVariables
    >({
      query: GET_DESKTOP_FOLDER_LINKS_REST,
      variables: {
        desktopId,
        folderId,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Get desktop folders from API
 * @param desktopId
 * @returns Desktop folders
 */
export const getDesktopFoldersRest = async (
  desktopId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: GetDesktopFoldersRestResponse;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.query<
      GetDesktopFoldersRestResponse,
      GetDesktopFoldersRestVariables
    >({
      query: GET_DESKTOP_FOLDERS_REST,
      variables: {
        desktopId: desktopId,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Get desktop apps from API
 * @param desktopId
 * @returns Desktop apps
 */
export const getDesktopAppsRest = async (
  desktopId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: GetDesktopAppsRestResponse;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.query<
      GetDesktopAppsRestResponse,
      GetDesktopAppsRestVariables
    >({
      query: GET_DESKTOP_APPS_REST,
      variables: {
        desktopId: desktopId,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Get desktop chat from API
 * @param desktopId
 * @returns Desktop links
 */
export const getDesktopChatRest = async (
  desktopId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: GetDesktopChatRestResponse;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.query<
      GetDesktopChatRestResponse,
      GetDesktopChatRestVariables
    >({
      query: GET_DESKTOP_CHAT_REST,
      variables: {
        desktopId: desktopId,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

export const getDesktopLinkByFolderIdApi = async (
  workspaceId: string,
  folderId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: GetLinksResponse;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.query<
      GetLinksResponse,
      GetLinksVariables
    >({
      query: GET_LINKS,
      variables: {
        hasFolder: true,
        desktop: undefined,
        desktop_workspace: workspaceId,
        filterOnlyAccessible: true,
        folderId,
        last: LINKS_PER_QUERY,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};
