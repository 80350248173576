import { useEffect, useMemo, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import objectHash from 'object-hash';

export type RepositoryFetchPolicy = 'cache-and-network' | 'cache-only';

let initialEntitiesFetched: Set<string> = new Set();

interface MultipleEntityRepositoryProps<T> {
  fetchFunction: (prevEntities?: T[]) => Promise<T[] | undefined>;
  iddbQuerier: () => Promise<TableType<T>[]>;
  dependencies?: any[];
  fetchPolicy: RepositoryFetchPolicy;
}

export interface MultipleEntityRepositoryReturn<T> {
  entities: T[];
  loading: boolean;
}

export interface TableType<T> {
  id: string;
  sourceData: T;
}

export const useMultipleEntityRepository = <T>({
  fetchFunction,
  iddbQuerier,
  dependencies = [],
  fetchPolicy = 'cache-and-network',
}: MultipleEntityRepositoryProps<T>): MultipleEntityRepositoryReturn<T> => {
  const [loading, setLoading] = useState(false);

  const argumentsHash = useMemo(() => {
    return objectHash({
      fetchFunction,
      iddbQuerier,
      dependencies,
    });
  }, [fetchFunction, iddbQuerier, dependencies]);

  useEffect(() => {
    if (
      fetchPolicy === 'cache-and-network' &&
      !initialEntitiesFetched.has(argumentsHash)
    ) {
      initialEntitiesFetched.add(argumentsHash);
      setLoading(true);
      fetchFunction().finally(() => {
        setLoading(false);
      });
    }
  }, [argumentsHash, fetchFunction, fetchPolicy]);

  const iddbEntities = useLiveQuery(
    () =>
      iddbQuerier().catch(error => {
        console.error(error);
      }),
    [argumentsHash],
    [],
  );

  const entities = useMemo(() => {
    return iddbEntities?.map(iddbEntity => iddbEntity.sourceData) || [];
  }, [iddbEntities]);

  return useMemo(
    () => ({
      entities,
      loading,
    }),
    [entities, loading],
  );
};
