import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { GlobalTranslation } from '../../../../domains/Intl/i18n/i18n.types';
import { StyledFormSelect } from './FormSelect.styled';
import { Select } from '../Select';
import { ErrorMessage } from '../../Input/FormInput';

interface FormInputProps {
  label?: string;
  className?: string;
  inlineError?: boolean;
}

export const FormSelect: FC<FieldProps & FormInputProps> = ({
  field,
  form,
  label,
  className,
  inlineError = false,
  ...props
}) => {
  const errorIdToDisplay = form.touched[field.name] && form.errors[field.name];
  return (
    <StyledFormSelect
      className={className}
      invalid={!!errorIdToDisplay}
      inlineError={inlineError}>
      {label && <label htmlFor={field.name}>{label}</label>}
      <Select id={field.name} {...field} {...props} />
      {errorIdToDisplay && (
        <ErrorMessage className="error" inlineError={inlineError}>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </StyledFormSelect>
  );
};
