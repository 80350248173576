import { useCallback } from 'react';
import { database } from '../../../Database';
import { DesktopApiTypeToDesktopTableType } from '../../../Database/DesktopTable/DesktopsTable.utils';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
} from '../../../Workspace/Workspace.hooks';

import {
  DesktopApiType,
  DesktopCreateInputType,
  DesktopEditInputType,
} from './types/Desktop.types';
import {
  deleteDesktopByIdGraphQLApi,
  updateDesktopByIdGraphQLApi,
} from './operations/DesktopGraphQLApi.operations';
import { createDesktopRowsMap } from './utils/DesktopIDDB.utils';
import { getWorkspaceDesktopsFromIDDB } from './dataSources/DesktopIDDB.dataSource';
import { createDesktopRestApi } from './operations/DesktopREST.operations';
import { putDesktopToIDDB } from './operations/DesktopIDDB.operations';
import { showToastRestApiErrors } from '../../../../shared/components/Toast';

export const useDesktopsActions = () => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const { refetchPermissions } = useCurrentWorkspacePermissions();

  const createDesktop = useCallback(
    async (desktop: DesktopCreateInputType) => {
      const newDesktop = await createDesktopRestApi(desktop).catch(e => {
        if (e.errors) {
          showToastRestApiErrors(e.errors);
        }
      });

      if (newDesktop) {
        await putDesktopToIDDB(
          workspace.id,
          newDesktop,
          newDesktop.accountIds?.includes(account.id),
        );
        refetchPermissions();
        return newDesktop;
      }
      return null;
    },
    [workspace.id, account.id, refetchPermissions],
  );

  const bulkPutDesktopsToIDDB = useCallback(
    async (desktops: DesktopApiType[]) => {
      const cachedDesktopRows = await getWorkspaceDesktopsFromIDDB(
        workspace.id,
      );
      const cachedDesktopRowsMap = createDesktopRowsMap(cachedDesktopRows);

      const desktopsRows = desktops.map(desktop =>
        DesktopApiTypeToDesktopTableType(
          desktop,
          workspace.id,
          desktop.accountIds.includes(account.id),
          cachedDesktopRowsMap[desktop.id]?.relatedTeams,
        ),
      );

      database.desktops.bulkPut(desktopsRows);
    },
    [workspace.id, account.id],
  );

  const updateDesktopInIDDB = useCallback(
    async (desktopId: string, variables: Partial<DesktopApiType>) => {
      let desktopNameExist = false;

      try {
        if (variables.name) {
          const allDesktops = await getWorkspaceDesktopsFromIDDB(workspace.id);

          desktopNameExist = allDesktops.some(
            desktop => desktop.sourceData.name === variables.name,
          );
        }

        const cachedDesktop = await database.desktops.get(desktopId);

        if (!cachedDesktop) {
          return;
        }

        database.desktops.update(cachedDesktop.id, {
          ...cachedDesktop,
          sourceData: {
            ...cachedDesktop.sourceData,
            ...variables,
            name: desktopNameExist
              ? cachedDesktop.sourceData.name
              : variables.name,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [workspace.id],
  );

  const updateDesktopById = (variables: DesktopEditInputType) => {
    try {
      updateDesktopInIDDB(variables.id, variables);
    } catch (e) {
      console.error(e);
    }

    return updateDesktopByIdGraphQLApi({
      id: variables.id,
      name: variables.name,
      description: variables.description,
    });
  };

  const removeDesktopById = async (id: string) => {
    try {
      removeDesktopByIdInIDDB(id);
      deleteDesktopByIdGraphQLApi(id);
    } catch (error) {
      console.error(error);
    }
  };

  const removeDesktopByIdInIDDB = async (id: string) => {
    try {
      const cachedDesktop = await database.desktops.get(id);

      if (!cachedDesktop) {
        return;
      }

      database.desktops.delete(cachedDesktop.id);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    createDesktop,
    bulkPutDesktopsToIDDB,
    updateDesktopInIDDB,
    updateDesktopById,
    removeDesktopById,
    removeDesktopByIdInIDDB,
  };
};
