import React from 'react';
import * as queryString from 'query-string';
import { GlideOptions } from 'types/glide';
import { OpenType } from '../../domains/GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';
import { escapeStringRegex } from './regex.utils';

const SVG_FILE_EXTENSION = '([a-zA-Z0-9\\s_\\\\.\\-\\(\\):])+(.\\.svg)';

export const isSvgImageUrl = (url: string) => {
  return new RegExp(SVG_FILE_EXTENSION).test(url);
};

export const getQueryParamsFrom = (object: { [key: string]: any }) => {
  return queryString.stringify(object);
};

export const appendQueryParamsToPath: typeof getQueryParamsFrom = object => {
  const queryParams = getQueryParamsFrom(object);
  return queryParams ? `?${queryParams}` : queryParams;
};

export const urlHasProtocol = (url: string) => {
  const pattern =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return pattern.test(url);
};

export const addProtocol = (url: string | undefined) => {
  if (!url) {
    return '';
  }

  const protocolCheckPattern = /^([a-zA-Z]+):\/\/|:\/\//;
  protocolCheckPattern.test(url);

  return `https://${url.replace(protocolCheckPattern, '')}`;
};

export const getGlideImageSrcSet = (
  contentUrl: string,
  options: GlideOptions,
) => {
  if (!contentUrl || !options.w || isSvgImageUrl(contentUrl)) {
    return '';
  }
  const params = getQueryParamsFrom(options);
  return `
    ${contentUrl}?${params}&dpr=1 1x,
    ${contentUrl}?${params}&dpr=2 2x,
    ${contentUrl}?${params}&dpr=3 3x`;
};

export const getImageSrcSet = (
  contentUrl: string,
  width: number,
  height?: number,
) => {
  if (!contentUrl || !width || isSvgImageUrl(contentUrl)) {
    return '';
  }

  const DPI_LEVELS = 3;
  const srcSet = [];

  const url = new URL(
    `${!urlHasProtocol(contentUrl) && 'https'}:${contentUrl}`,
  );
  url.searchParams.append('w', '');
  url.searchParams.append('h', '');

  for (let dpi = 1; dpi <= DPI_LEVELS; dpi++) {
    url.searchParams.set('w', `${width * dpi}`);
    url.searchParams.set('h', `${(height || width) * dpi}`);
    srcSet.push(`${url} ${dpi}x`);
  }

  return decodeURIComponent(srcSet.join());
};

export const openUrl = (
  openType: OpenType,
  url: string,
  title: string,
  event?: MouseEvent | React.MouseEvent,
) => {
  switch (openType) {
    case OpenType.separateWindow:
      window.open(url, title, 'menubar=no');
      break;
    case OpenType.newTab:
      window.open(url, '_blank');
      break;
    case OpenType.currentTab:
      if (event?.ctrlKey || event?.metaKey) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
      break;
  }
};

export const isValidURL = (url: string) => {
  var pattern = new RegExp(
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  );
  return !!pattern.test(url);
};

export const extractUrlFromString = (text: string) => {
  const stringData = text.split(' ');
  return stringData?.filter(item => {
    return isValidURL(item);
  });
};

export const getEntityFromUrl = (url: string, entity: string) => {
  const parsedUrl = url.split('/');
  const entityPos = parsedUrl.indexOf(entity);
  return entityPos > 0 ? parsedUrl[entityPos + 1] : [];
};

const URL_PATH_REGEXP =
  /^\/[/.a-zA-Z0-9-]+(\?[\w-]+(=[\w-]*)?(&[\w-]+(=[\w-]*)?)*)?$/;
export const sanitizeUrl = (url?: string, hostname?: string): string => {
  if (!url) {
    return 'about:blank';
  }

  if (url.match(URL_PATH_REGEXP)) {
    return url;
  }

  let sanitizedUrl;
  try {
    const sanitisedUrlObject = new URL(url);
    const hostnameExistsAndAllowed =
      Boolean(sanitisedUrlObject.hostname) &&
      (hostname
        ? sanitisedUrlObject.hostname.match(
            new RegExp(`^([\\w]+[\\.\\/])*${escapeStringRegex(hostname)}$`),
          )
        : true);
    sanitizedUrl = hostnameExistsAndAllowed
      ? sanitisedUrlObject.href
      : 'about:blank';
  } catch {
    sanitizedUrl = 'about:blank';
  }

  return sanitizedUrl;
};

export const isWorkspaceSettingsView = (url: string) => /admin/.test(url);

export const currentLocationHostnameMatches = (hostname: string) => {
  return window.location.hostname === hostname;
}

export const isMatchingPath = (url: string, pathname: string): boolean => {
  if (!pathname.startsWith('/')) {
    throw new Error('Pathname should start with a forward slash (/).');
  }
  const regex = new RegExp(`^https?:\/\/[^/]+${pathname}(?:\/?|\\?.*)$`);
  return regex.test(url);
};
