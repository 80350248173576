import { VideoPlayerLocale, VideoPlayerTranslation } from './i18n.types';

export const enVideoPlayerLocale: VideoPlayerLocale = {
  [VideoPlayerTranslation.playerErrorMessage]:
    "Seems Your browser doesn't support current type of video file, try to download it directly",
  [VideoPlayerTranslation.playerPlayButton]: 'Play (k)',
  [VideoPlayerTranslation.playerPauseButton]: 'Pause (k)',
  [VideoPlayerTranslation.playerExitFullScreen]: 'Exit full screen (f)',
  [VideoPlayerTranslation.vimeo]: 'Vimeo',
  [VideoPlayerTranslation.youtube]: 'Youtube',
  [VideoPlayerTranslation.externalAlt]: 'External',
  [VideoPlayerTranslation.videoIsBeingProcessed]: 'Video is being processed...',
};
