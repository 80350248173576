import type { StreamTeamEvent } from '../../../../Mercure/General';
import { TeamApiType } from '../types/Team.types';

export const mercureTeamEventToTeamApiType = (
  event: StreamTeamEvent,
): TeamApiType => {
  return {
    id: event.id,
    name: event.name,
    description: event.description,
    system: event.system,
    createdAt: event.createdAt,
    modifiedAt: event.modifiedAt,
    membersCount: event.membersCount,
  };
};
