import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DocumentOfficeXlsIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="document-office-xls-icon"
      {...props}>
      <path
        d="m16.7929 1.75h-10.0429c-.27615 0-.5.22385-.5.5v19.5c0 .2761.22385.5.5.5h14.5c.2761 0 .5-.2239.5-.5v-15.0429c0-.1326-.0527-.25978-.1465-.35355l-4.457-4.4571c-.0938-.09377-.221-.14645-.3536-.14645z"
        fill="#fff"
      />
      <path
        clipRule="evenodd"
        d="m6 2.25c0-.41422.33578-.75.75-.75h10.0429c.1989 0 .3897.07902.5303.21967l4.4571 4.4571c.1407.14066.2197.33142.2197.53033v15.0429c0 .4142-.3358.75-.75.75h-14.5c-.41422 0-.75-.3358-.75-.75zm.75-.25c-.13808 0-.25.11192-.25.25v19.5c0 .1381.11192.25.25.25h14.5c.1381 0 .25-.1119.25-.25v-15.0429c0-.06629-.0263-.12988-.0732-.17677l-4.4571-4.4571c-.0469-.0469-.1105-.07323-.1768-.07323z"
        fill="#979593"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m16.5 6.25v-4.5h.5v4.5c0 .13808.1119.25.25.25h4.5v.5h-4.5c-.4142 0-.75-.33578-.75-.75z"
        fill="#979593"
        fillRule="evenodd"
      />
      <path
        d="m12.75 15.25h-2.5v-.5h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0-1.5h-2.5v-.5h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0-1.5h-2.5v-.5h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0-1.5h-2.5v-.5h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0 6h-2.5v-.5h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm7-1.5h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0 1.5h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0-3h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0-1.5h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0-1.5h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm-3.5 4.5h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0 1.5h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0-3h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0-1.5h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25zm0-1.5h-2.5c-.1381 0-.25-.1119-.25-.25s.1119-.25.25-.25h2.5c.1381 0 .25.1119.25.25s-.1119.25-.25.25z"
        fill="#c8c6c4"
      />
      <path
        d="m3 18.5h8c.5523 0 1-.4477 1-1v-8c0-.55228-.4477-1-1-1h-8c-.55228 0-1 .44772-1 1v8c0 .5523.44772 1 1 1z"
        fill="#107c41"
      />
      <path
        d="m4.23828 16.5 1.9712-3.0084-1.80542-2.9916h1.45287l.9856 1.9122c.09058.1813.15283.3167.18678.4059h.0127c.06542-.145.1333-.286.2041-.4226l1.05345-1.8955h1.33375l-1.85205 2.975 1.89892 3.025h-1.41895l-1.13842-2.1004c-.0481-.0779-.0935-.1715-.13598-.2804h-.01685c-.01977.0671-.06372.1578-.1316.272l-1.1726 2.1088z"
        fill="#fff"
      />
    </svg>
  );
});
