import { getCookie } from '../../shared/utils/storage';
import { DarkTheme, LightTheme, SELECTED_THEME, ThemeType } from './';

export const getCurrentThemeType = () => {
  const selectedTheme = getCookie(SELECTED_THEME);

  switch (selectedTheme) {
    case ThemeType.dark:
      return ThemeType.dark;
    case ThemeType.light:
    default:
      return ThemeType.light;
  }
};

export const getCurrentTheme = () => {
  const selectedThemeType = getCurrentThemeType();

  switch (selectedThemeType) {
    case ThemeType.dark:
      return DarkTheme;
    case ThemeType.light:
      return LightTheme;
    default:
      return LightTheme;
  }
};
