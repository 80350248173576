import { OnboardingLocale, OnboardingTranslation } from './i18n.types';

export const enOnboardingLocale: OnboardingLocale = {
  // TODO: replace all password input translations with this universal one
  [OnboardingTranslation.passwordLabel]: 'Password',
  [OnboardingTranslation.passwordRequired]: 'Password is required',
  [OnboardingTranslation.passwordMinimum]:
    'Password should contain at least 8 characters',
  [OnboardingTranslation.passwordContainLetter]:
    'Password should contain at least 1 letter',
  [OnboardingTranslation.passwordContainNumber]:
    'Password should contain at least 1 number',
  [OnboardingTranslation.repeatPasswordLabel]: 'Confirm password',
  [OnboardingTranslation.repeatPasswordRequired]:
    'Confirm password is required',
  [OnboardingTranslation.repeatPasswordMinimum]:
    'Confirm password should contain at least 8 characters',
  [OnboardingTranslation.repeatPasswordContainLetter]:
    'Confirm password should contain at least 1 letter',
  [OnboardingTranslation.repeatPasswordContainNumber]:
    'Confirm password should contain at least 1 number',
  [OnboardingTranslation.repeatPasswordMatch]:
    'Passwords do not match. Please ensure that you enter the same password.',

  [OnboardingTranslation.registrationFormBannerItemAccessTitle]: 'Access',
  [OnboardingTranslation.registrationFormBannerItemAccessDescription]:
    'Securely access all your tools, teams and conversations from any device, at any time',
  [OnboardingTranslation.registrationFormBannerItemOrganizeTitle]: 'Organize',
  [OnboardingTranslation.registrationFormBannerItemOrganizeDescription]:
    'Create an organized, shareable space for every need, team or project',
  [OnboardingTranslation.registrationFormBannerItemCollaborateTitle]:
    'Collaborate',
  [OnboardingTranslation.registrationFormBannerItemCollaborateDescription]:
    'Improve collaboration across and within teams',
  [OnboardingTranslation.registrationFormBannerItemCommunicateTitle]:
    'Communicate',
  [OnboardingTranslation.registrationFormBannerItemCommunicateDescription]:
    'Communicate with other users or external parties via chat, voice calls or full HD quality video calls right in the context of your workspace',
  [OnboardingTranslation.registrationFormBannerItemSearchTitle]: 'Search',
  [OnboardingTranslation.registrationFormBannerItemSearchDescription]:
    'Search across your online content by integrating your apps',

  [OnboardingTranslation.billingPageBannerItemChargeNowTitle]:
    'Will my credit card be charged now?\n',
  [OnboardingTranslation.billingPageBannerItemChargeNowDescription]:
    'No, your credit card will not be charged at this time.\nYou will only be charged if you don’t cancel your free trial within 14 days from the day you sign up for it.',
  [OnboardingTranslation.billingPageBannerItemCancelTitle]:
    'Can I cancel my free trial at any time?',
  [OnboardingTranslation.billingPageBannerItemCancelDescription]:
    'Absolutely! You can cancel it at any time.',
  [OnboardingTranslation.billingPageBannerItemChangeMethodTitle]:
    'Can I change my payment method later?',
  [OnboardingTranslation.billingPageBannerItemChangeMethodDescription]:
    'Yes, you can change it at any time by simply going to your account settings menu.',
  [OnboardingTranslation.billingPageSubmitButton]: 'Next',

  [OnboardingTranslation.onboardingInvitationExpiredError]:
    'Invitation is expired or not valid',
  [OnboardingTranslation.onboardingRegistrationPageTitle]:
    'Create webtop.com account',
  [OnboardingTranslation.onboardingRegistrationPageFreeHeadTitle]:
    'Register Free',
  [OnboardingTranslation.onboardingRegistrationPageProHeadTitle]:
    'Register Pro',
  [OnboardingTranslation.onboardingRegistrationPageInvitationTitle]:
    'Create a free account to join {workspace}',
  [OnboardingTranslation.onboardingRegistrationPageFreeTitle]:
    'Create a free webtop.com account',
  [OnboardingTranslation.onboardingRegistrationPageProTitle]:
    'Create a pro webtop.com account',
  [OnboardingTranslation.onboardingRegistrationPageEnterpriseTitle]:
    'Create an enterprise webtop.com account',
  [OnboardingTranslation.onboardingRegistrationPageGoogleButton]:
    'Register in with Google',
  [OnboardingTranslation.onboardingRegistrationPageSeparator]: 'or',
  [OnboardingTranslation.onboardingRegistrationFormFirstNameLabel]:
    'First name',
  [OnboardingTranslation.onboardingRegistrationFormLastNameLabel]: 'Last name',
  [OnboardingTranslation.onboardingRegistrationFormNameRequired]:
    'Name is required',
  [OnboardingTranslation.onboardingRegistrationFormEmailLabel]: 'Email',
  [OnboardingTranslation.onboardingRegistrationFormEmailRequired]:
    'Email is required',
  [OnboardingTranslation.onboardingRegistrationFormEmailInvalid]: '',
  [OnboardingTranslation.onboardingRegistrationPasswordLabel]: 'Password',
  [OnboardingTranslation.onboardingRegistrationPasswordRequired]:
    'Password is required',
  [OnboardingTranslation.onboardingRegistrationFormPasswordMinimum]:
    'Password should contain at least 8 characters',
  [OnboardingTranslation.onboardingRegistrationFormPasswordContainLetter]:
    'Password should contain at least 1 letter',
  [OnboardingTranslation.onboardingRegistrationFormPasswordContainNumber]:
    'Password should contain at least 1 number',
  [OnboardingTranslation.onboardingRegistrationPasswordInvalid]: '',
  [OnboardingTranslation.onboardingRegistrationFormSubmitButton]: 'Continue',
  [OnboardingTranslation.onboardingRegistrationExternalProviderGoogle]:
    'Continue with Google',
  [OnboardingTranslation.onboardingRegistrationExternalProviderFacebook]:
    'Continue with Facebook',
  [OnboardingTranslation.onboardingRegistrationExternalProviderApple]:
    'Continue with Apple',
  [OnboardingTranslation.onboardingRegistrationExternalProviderMicrosoft]:
    'Continue with Microsoft',
  [OnboardingTranslation.onboardingRegistrationTermsOfUseMessage]:
    'I agree to Webtop.com’s {termsLink} and {privacyPolicyLink}',
  [OnboardingTranslation.onboardingRegistrationTermsOfUseCheckboxRequired]:
    'You should agree to Terms and Privacy policy to continue.',
  [OnboardingTranslation.onboardingRegistrationTermsOfUseLink]: 'Terms',
  [OnboardingTranslation.onboardingRegistrationPrivacyPolicyLink]:
    'Privacy policy',
  [OnboardingTranslation.onboardingRegistrationMarketingConsentLabel]:
    'Sign me up to receive Webtop.com emails with information about new features, promotional offers and general updates.',
  [OnboardingTranslation.onboardingRegistrationCaptchaRequired]:
    'You should submit captcha to proceed',
  [OnboardingTranslation.onboardingRegistrationHelpText]:
    'Create a strong password. The password that you choose is used to unlock your Webtop.com Password Manager.' +
    ' <a href="{learnMoreLink}" target="_blank" rel="noopener noreferrer">Learn more</a>',

  [OnboardingTranslation.onboardingRegistrationFacebookWarningText]:
    '<p>All accounts need to have an email address for authentication. Please do not choose to hide your email address when signing up using your Facebook id.</p><p>See <a href="{signUpWithFacebookLink}" target="_blank" rel="noopener noreferrer">here</a> if you have issues using your Facebook id.</p>',
  [OnboardingTranslation.onboardingRegistrationFacebookWarningConfirmLabel]:
    'Proceed',
  [OnboardingTranslation.onboardingRegistrationFacebookWarningCancelLabel]:
    'Cancel signup',

  [OnboardingTranslation.onboardingExternalRegistrationTitle]:
    'Complete user registration',
  [OnboardingTranslation.onboardingExternalRegistrationNoAccountMessage]:
    'We could not find an account with the email {email} in our system so we created a new account for you, please complete the registration by filling out the form',
  [OnboardingTranslation.onboardingExternalRegistrationPlanLabel]: 'Your plan',
  [OnboardingTranslation.onboardingExternalRegistrationInvitationWorkspaceNameLabel]:
    'Account',
  [OnboardingTranslation.onboardingExternalRegistrationPredefinedPlanLabel]:
    'Select plan',
  [OnboardingTranslation.onboardingExternalRegistrationFormEmailLabel]:
    'E-mail',
  [OnboardingTranslation.onboardingExternalRegistrationFormSubmitButton]:
    'Continue',
  [OnboardingTranslation.onboardingExternalRegistrationFormWrongEmailMessage]:
    '<div class="message-title">The invitation is for {email}</div>You need to sign up with the same email you have been invited',

  [OnboardingTranslation.onboardingActivationPageTitle]: 'Verify e-mail',
  [OnboardingTranslation.onboardingActivationPageHeading]:
    'Please, verify your e-mail',
  [OnboardingTranslation.onboardingActivationPageText]:
    'Once you verify your email address, you’ll be able to use your Webtop\n' +
    '        account!',
  [OnboardingTranslation.onboardingActivationPageImage]:
    'Illustration with paper planes',
  [OnboardingTranslation.onboardingVerificationEmailExpiredMessage]:
    'The verification email is expired',
  [OnboardingTranslation.onboardingActivationCompletedTitle]: 'Email verified',
  [OnboardingTranslation.onboardingActivationCompletedText]:
    'Click "Next" to set up your workspace.',
  [OnboardingTranslation.onboardingActivationResendEmailText]:
    'Didn’t receive an email?',
  [OnboardingTranslation.onboardingActivationResendEmailButton]: 'Resend email',
  [OnboardingTranslation.onboardingActivationResendEmailSuccessMessage]:
    'New e-mail has been sent.',
  [OnboardingTranslation.onboardingActivationCompletedButton]: 'Next',

  [OnboardingTranslation.onboardingAccountDetailsTitle]: 'Your account details',

  [OnboardingTranslation.onboardingUseCasePageTitle]:
    'How will you be using Webtop.com?',
  [OnboardingTranslation.onboardingUseCaseDescription]:
    'This will help us set up your account',
  [OnboardingTranslation.onboardingBusinessUseCaseTitle]: 'Business use',
  [OnboardingTranslation.onboardingBusinessUseCaseSubtitle]:
    'For your business, a team or just for you',
  [OnboardingTranslation.onboardingPersonalUseCaseTitle]: 'Personal use',
  [OnboardingTranslation.onboardingPersonalUseCaseSubtitle]:
    'For family, friends or just for you',
  [OnboardingTranslation.onboardingUseCaseNextButton]: 'Next',

  [OnboardingTranslation.onboardingAboutYouPageTitle]:
    'Tell us a bit about yourself',
  [OnboardingTranslation.onboardingAboutYouNextButton]: 'Next',
  [OnboardingTranslation.onboardingAboutYouJobLabel]: 'What is your job title',
  [OnboardingTranslation.onboardingAboutYouJobPlaceholder]: 'Your job title',
  [OnboardingTranslation.onboardingAboutYouSelectLabel]:
    'What industry are you in?',
  [OnboardingTranslation.onboardingAboutYouSelectPlaceholder]:
    'Select an industry',
  [OnboardingTranslation.onboardingAboutYouJobRequired]: 'Job is required',
  [OnboardingTranslation.onboardingAboutYouIndustryRequired]:
    'Industry is required',

  [OnboardingTranslation.onboardingTeamSizePageTitle]:
    'What size is your team?',
  [OnboardingTranslation.onboardingYouTeamSizeTitle]: 'Just you',
  [OnboardingTranslation.onboardingYouTeamSizeSubtitle]:
    'Freelancer and solo entrepreneurs',
  [OnboardingTranslation.onboardingSmallTeamSizeTitle]: 'Small group',
  [OnboardingTranslation.onboardingSmallTeamSizeSubtitle]: '1 - 10 members',
  [OnboardingTranslation.onboardingMediumTeamSizeTitle]: 'Medium-sized',
  [OnboardingTranslation.onboardingMediumTeamSizeSubtitle]: '10 - 30 members',
  [OnboardingTranslation.onboardingLargeTeamSizeTitle]: 'Large-sized',
  [OnboardingTranslation.onboardingLargeTeamSizeSubtitle]: '30 - 100 members',

  [OnboardingTranslation.onboardingChooseFeatureTitle]: 'Choose Features',
  [OnboardingTranslation.onboardingChooseFeatureDescription]:
    'Select the Webtop.com feature package that best suits your needs. You can edit this choice in Settings at any time.',
  [OnboardingTranslation.onboardingOrganizeAndSearchFeatureTitle]:
    'Organize & Search',
  [OnboardingTranslation.onboardingOrganizeAndSearchFeatureSubtitle]:
    'Search across apps, links & integrations. Organize and share online content and tools.',
  [OnboardingTranslation.onboardingCommunicateTitle]: 'Communicate',
  [OnboardingTranslation.onboardingCommunicateSubtitle]:
    'Chat, Meetings & Video Conferencing',
  [OnboardingTranslation.onboardingChooseFeatureInfoToast]:
    'Please pick at least one',

  [OnboardingTranslation.onboardingPlanPageTitle]: 'Choose your plan',
  [OnboardingTranslation.onboardingPlanPageFree]: 'Free',
  [OnboardingTranslation.onboardingPlanPagePro]: 'Pro',
  [OnboardingTranslation.onboardingPlanPageEnterprise]: 'Enterprise',

  [OnboardingTranslation.onboardingRequestResetPasswordTitle]:
    'Forgot password?',
  [OnboardingTranslation.onboardingRequestResetPasswordDescription]:
    'Enter the email address you used when you joined and we’ll send you instructions to reset your password.',
  [OnboardingTranslation.onboardingRequestResetPasswordWarningMessage]:
    'If you are an account owner, please note that a password reset will result in the loss of any stored credentials.',
  [OnboardingTranslation.onboardingRequestResetPasswordFormEmailLabel]: 'Email',
  [OnboardingTranslation.onboardingRequestResetPasswordFormEmailPlaceholder]:
    'Your email address',
  [OnboardingTranslation.onboardingRequestResetPasswordFormEmailRequired]:
    'Email is required',
  [OnboardingTranslation.onboardingRequestResetPasswordFormEmailInvalid]:
    'Email is invalid',
  [OnboardingTranslation.onboardingRequestResetPasswordFormEmailSubmitButton]:
    'Reset password',
  [OnboardingTranslation.onboardingRequestResetPasswordSuccessMessage]:
    'If email exists in our system, you will get a reset link. Please check your e-mail.',

  [OnboardingTranslation.onboardingResetPasswordTitle]: 'Reset password',
  [OnboardingTranslation.onboardingResetPasswordDescription]:
    'We are in the middle of your password reset. Please enter your new password bellow.',
  [OnboardingTranslation.onboardingResetPasswordFormPasswordLabel]:
    'New password',
  [OnboardingTranslation.onboardingResetPasswordFormPasswordPlaceholder]: ' ',
  [OnboardingTranslation.onboardingResetPasswordFormPasswordRequired]:
    'New password is required',
  [OnboardingTranslation.onboardingResetPasswordFormPasswordMinimum]:
    'Password should contain minimum 8 characters',
  [OnboardingTranslation.onboardingResetPasswordFormPasswordContainLetter]:
    'Password should contain at least 1 letter',
  [OnboardingTranslation.onboardingResetPasswordFormPasswordContainNumber]:
    'Password should contain at least 1 number',
  [OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordLabel]:
    'Confirm new password',
  [OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordPlaceholder]:
    ' ',
  [OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordRequired]:
    'You should repeat your new password',
  [OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordMinimum]:
    'Password should contain minimum 8 characters',
  [OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordContainLetter]:
    'Password should contain at least 1 letter',
  [OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordContainNumber]:
    'Password should contain at least 1 number',
  [OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordMatch]:
    'Passwords do not match. Please ensure that you enter the same password.',
  [OnboardingTranslation.onboardingResetPasswordSuccessMessage]:
    'Your password has been reset.',
  [OnboardingTranslation.onboardingResetPasswordSuccessButton]:
    'Log in with new password',
  [OnboardingTranslation.onboardingResetPasswordInvalidLink]:
    'We got invalid reset password params. Please check your link',
  [OnboardingTranslation.onboardingResetPasswordFormSubmitButton]:
    'Reset password',

  [OnboardingTranslation.onboardingPageLoginText]: 'Already have an account?',
  [OnboardingTranslation.onboardingPageLoginLink]: 'Log in',
  [OnboardingTranslation.onboardingPageLogoutText]: ' ',
  [OnboardingTranslation.onboardingPageLogoutLink]: 'Sign out',
  [OnboardingTranslation.onboardingInvitationPageTitle]: 'Accept invitation',
  [OnboardingTranslation.onboardingInvitationPageInvitationValid]:
    'Your account <b>{accountName}</b> will be added to <b>{workspaceName}</b> account',
  [OnboardingTranslation.onboardingInvitationPageInvitationNotValid]:
    'Your account does not match this invitation.',
  [OnboardingTranslation.onboardingInvitationPageAcceptWithCurrentAccount]:
    'Accept with this account',
  [OnboardingTranslation.onboardingInvitationPageAcceptWithNewAccount]:
    'Register new account',
  [OnboardingTranslation.onboardingInvitationPageAcceptWithAnotherExistingAccount]:
    'Log in with another account',

  [OnboardingTranslation.onboardingLinkAccountHeader]: 'Linking an account',
  [OnboardingTranslation.onboardingLinkAccountLinkButton]: 'Link account',
  [OnboardingTranslation.onboardingLinkAccountCancelButton]: 'Cancel',
  [OnboardingTranslation.onboardingLinkAccountSuccessMessage]:
    'Your account was successfully added, you’ll be shortly redirected to your desktop',
  [OnboardingTranslation.onboardingLinkAccountCancelMessage]:
    'Link request was cancelled',
  [OnboardingTranslation.onboardingResetPasswordHelpText]:
    'A notification has been sent to the workspace owner. If your account has access to stored app passwords, the owner will need to approve your password change before these are accessible again.  ' +
    '<a href="{learnMoreLink}" target="_blank" rel="noopener noreferrer">Learn more</a>',

  [OnboardingTranslation.onboardingJoinChatHeader]: 'Join Chat',
  [OnboardingTranslation.onboardingJoinChatDescription]:
    'You can use any of the options below.',
  [OnboardingTranslation.onboardingJoinChatOptionNewDesktopAccountButton]:
    'Create Webtop.com account',
  [OnboardingTranslation.onboardingJoinChatOptionExistingDesktopAccountButton]:
    'Webtop.com',
  [OnboardingTranslation.onboardingJoinChatOptionSlackAccountButton]: 'Slack',
  [OnboardingTranslation.onboardingJoinChatOptionSlackLogoAlt]: 'Slack logo',
  [OnboardingTranslation.onboardingJoinChatOptionSlackUnavailable]:
    'Slack integration available for Pro plan',
  [OnboardingTranslation.onboardingJoinChatOptionTeamsAccountButton]:
    'Microsoft Teams',
  [OnboardingTranslation.onboardingJoinChatOptionTeamsLogoAlt]:
    'Microsoft Teams logo',
  [OnboardingTranslation.onboardingJoinChatInviteIdError]:
    'This Join Chat invitation link is incomplete or expired',
  [OnboardingTranslation.onboardingJoinChatOptionsSeparatorMessage]:
    'Don’t have any of those accounts?',
  [OnboardingTranslation.onboardingJoinChatSuccessHeader]: 'Success!',
  [OnboardingTranslation.onboardingJoinChatSuccessDescription]:
    'You are now part of a new chat! Open Slack to start messaging',
  [OnboardingTranslation.onboardingJoinChatSuccessOpenSlackButton]:
    'Open Slack',
  [OnboardingTranslation.onboardingJoinChatErrorHeader]: 'Error',
  [OnboardingTranslation.onboardingJoinChatErrorDescription]:
    'Something went wrong while joining to chat with Slack account',

  [OnboardingTranslation.verifyEmailChangeTitle]: 'Verify email change',
  [OnboardingTranslation.verifyEmailChangeHeader]: 'Verify email change',
  [OnboardingTranslation.verifyEmailChangeDescription]:
    'You are changing your email from <strong>{email}</strong> to <strong>{pendingEmail}</strong>. Please enter your password to verify this action.',
  [OnboardingTranslation.verifyEmailChangeWarning]:
    'After completing this action you will be logged out and your MFA settings will be reset. You can reconfigure it during your next login.',
  [OnboardingTranslation.verifyEmailChangePasswordInputLabel]: 'Password',
  [OnboardingTranslation.verifyEmailChangePasswordInputRequired]:
    'Password is required',
  [OnboardingTranslation.verifyEmailChangePasswordIncorrectErrorMessage]:
    'Please make sure password is correct',
  [OnboardingTranslation.verifyEmailChangeVerifyButton]: 'Verify',
  [OnboardingTranslation.verifyEmailChangeSuccessMessage]:
    'Email was changed successfully',
  [OnboardingTranslation.verifyEmailChangeIncorrectRequestErrorMessage]:
    'This email change request is incorrect or expired',
  [OnboardingTranslation.verifyEmailChangeUnauthorisedErrorMessage]:
    'You should be logged in with your old email to verify your email change.',
  [OnboardingTranslation.verifyEmailChangeUnauthorisedLoginButton]: 'Log in',

  [OnboardingTranslation.inviteUsersPageTitle]: 'Invite users',
  [OnboardingTranslation.inviteUsersPageHeading]: 'Let’s invite some people!',
  [OnboardingTranslation.inviteUsersPageDescription]:
    'You can easily manage user roles, create groups and edit access settings for all invited users in your Settings menu.',
  [OnboardingTranslation.inviteUserInviteButton]: 'Next',
  [OnboardingTranslation.inviteUsersSkipButton]: 'Skip for now',

  [OnboardingTranslation.installPluginPageTitle]: 'Browser extension',
  [OnboardingTranslation.installPluginPageHeading]:
    'Webtop.com browser extension',

  [OnboardingTranslation.onboardingEndScreenTitle]: 'Done',
  [OnboardingTranslation.onboardingEndScreenHeading]: 'Done!',
  [OnboardingTranslation.onboardingEndScreenDescription]:
    'Good job, your workspace is all set!',
  [OnboardingTranslation.onboardingEndScreenButtonLabel]: 'Go to workspace',

  [OnboardingTranslation.onboardingCreateWorkspaceTitle]:
    'Create your workspace',
  [OnboardingTranslation.onboardingCreateWorkspaceHeading]:
    'Create your workspace',
  [OnboardingTranslation.onboardingCreateWorkspaceDescription]:
    'Your workspace consists of one or several desktops that lets you separate and organize conversations, apps and links based on e.g. project, department or team.',
  [OnboardingTranslation.onboardingCreateWorkspaceUrl]:
    'Your first desktop will be called <b>General</b> and will be shared with all workspace members you invite.',
  [OnboardingTranslation.onboardingStepsCount]:
    'Step <span>{count}</span> of <span>{length}</span>',

  [OnboardingTranslation.addContentPageTitle]: 'Add content',
  [OnboardingTranslation.addContentPageHeading]:
    'Select some apps to get started',
  [OnboardingTranslation.addContentPageDescription]:
    'Here is a selection of our most popular apps – pick the ones that you use and we will automatically add them to your workspace. You can rearrange them or delete them later, if you wish.',
  [OnboardingTranslation.addContentApplyTemplatesButton]: 'Next',
  [OnboardingTranslation.addContentSkipButton]: 'Skip for now',
  [OnboardingTranslation.onboardingExampleWorkspaceName]:
    '<a href="{exampleWorkSpaceNameLink}" target="_blank" rel="noopener noreferrer">See examples of workspace names</a>',
  [OnboardingTranslation.addContentPlatformTitle]: 'Platforms',

  [OnboardingTranslation.resetMfaPageTitle]: 'Reset MFA for your account',
  [OnboardingTranslation.resetMfaPageConfirmButton]: 'Reset MFA',
};
