import { database } from './Database';

export const clearIndexedDB = async () => {
  return await database
    .delete()
    .then(() => database.open())
    .catch(err => {
      console.error(err);
    });
};
