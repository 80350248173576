import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const KeyboardArrowRightIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.21969 0.219668C0.51259 -0.0732225 0.98739 -0.0732225 1.28029 0.219668L6.53029 5.4697C6.82319 5.7626 6.82319 6.2374 6.53029 6.5303L1.28029 11.7803C0.98739 12.0732 0.51259 12.0732 0.21969 11.7803C-0.07323 11.4874 -0.07323 11.0126 0.21969 10.7197L4.93929 6L0.21969 1.28033C-0.07323 0.987438 -0.07323 0.512558 0.21969 0.219668Z"
        fill="currentColor"
      />
    </svg>
  );
});
