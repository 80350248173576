import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  AWAY_THRESHOLD,
  PING_AWAY_AT_TICK,
  PING_BUSY_AT_TICK,
  PING_ONLINE_AT_TICK,
  TICK_DURATION,
  USER_ACTIVITY_DEBOUNCE_TIME,
  userActivityEvents,
} from '../../Account.constants';
import { IdleDetectionProvider } from '../IdleDetectionProvider';
import { usePublishPing } from '../../../Mercure/Ping/PingMercure.hooks';
import { AvailabilityStatusType } from '../../../User/User.types';
import { useAccountsContext } from '../../Account.hooks';
import { useCurrentWorkspaceAccount } from '../../../Workspace/Workspace.hooks';

export const AccountOnlineStatusProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { account: authenticatedAccount } = useCurrentAccount();
  const { account } = useCurrentWorkspaceAccount();
  const { accountsWithAvailability, updateAccount } = useAccountsContext();
  const currentWorkspaceAccount = accountsWithAvailability[account.id];
  const publishPing = usePublishPing();
  const publishPingRef = useRef(publishPing);
  publishPingRef.current = publishPing;
  const onlineStatusRef = useRef<AvailabilityStatusType | undefined>(undefined);
  const [initTicks, setInitTicks] = useState(false);

  const setStatus = useCallback((status: AvailabilityStatusType) => {
    publishPingRef.current(status);
    onlineStatusRef.current = status;
  }, []);

  // ===========================
  // Initially set current account online status to Online if Away of Offline.
  // ===========================
  useEffect(() => {
    if (
      currentWorkspaceAccount?.onlineStatus === AvailabilityStatusType.Offline
    ) {
      updateAccount(currentWorkspaceAccount.id, updatedAccount => ({
        ...updatedAccount,
        onlineStatus: AvailabilityStatusType.Online,
      }));
      publishPingRef.current(AvailabilityStatusType.Online);
    }
  }, [currentWorkspaceAccount?.onlineStatus]); // eslint-disable-line

  // ===========================
  // Set away / user activity
  // ===========================
  const setAwayTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearAwayTimer = useCallback(() => {
    if (setAwayTimer.current) {
      clearTimeout(setAwayTimer.current);
      setAwayTimer.current = null;
    }
  }, []);

  const setAwayStatus = useCallback(() => {
    if (onlineStatusRef.current === AvailabilityStatusType.Online) {
      setStatus(AvailabilityStatusType.Away);
    }
  }, [setStatus]);

  const initAwayTimer = useCallback(() => {
    clearAwayTimer();
    setAwayTimer.current = setTimeout(setAwayStatus, AWAY_THRESHOLD);
  }, [clearAwayTimer, setAwayStatus]);

  /**
   * detectedUserActivity
   * Function run when any userActivityEvent is triggered
   */
  const detectedUserActivityHandler = useDebouncedCallback(() => {
    switch (onlineStatusRef.current) {
      case AvailabilityStatusType.Online:
        initAwayTimer();
        break;
      case AvailabilityStatusType.Away:
      case AvailabilityStatusType.Offline:
        setStatus(AvailabilityStatusType.Online);
        initAwayTimer();
        break;
    }
  }, USER_ACTIVITY_DEBOUNCE_TIME);

  /**
   * Add eventlisteners to catch user activity
   */
  useEffect(() => {
    userActivityEvents.forEach(userActivityEvent => {
      document.addEventListener(
        userActivityEvent,
        detectedUserActivityHandler,
        true,
      );
    });

    return () => {
      userActivityEvents.forEach(userActivityEvent => {
        document.removeEventListener(
          userActivityEvent,
          detectedUserActivityHandler,
          true,
        );
      });
    };
  }, [detectedUserActivityHandler]);

  // ===========================
  // Ticker
  // ===========================

  const tickCounter = useRef(0);
  const shouldOverrideStatus = useRef(true);

  /**
   * Keep the onlineStatusRef updated
   */
  useEffect(() => {
    if (!authenticatedAccount?.mercureInfo || !currentWorkspaceAccount) {
      return;
    }

    switch (currentWorkspaceAccount.onlineStatus) {
      case AvailabilityStatusType.Online:
        if (shouldOverrideStatus.current) {
          initAwayTimer();
        }
        break;
      case AvailabilityStatusType.Offline:
        if (shouldOverrideStatus.current) {
          setTimeout(() => {
            setStatus(AvailabilityStatusType.Online);
            initAwayTimer();
          }, 500);
        }
        break;
      case AvailabilityStatusType.Busy:
      case AvailabilityStatusType.Invisible:
        clearAwayTimer();
        break;
    }

    if (shouldOverrideStatus.current) {
      setInitTicks(true);
      shouldOverrideStatus.current = false;
    }

    onlineStatusRef.current = currentWorkspaceAccount.onlineStatus;
  }, [
    authenticatedAccount,
    currentWorkspaceAccount,
    initAwayTimer,
    clearAwayTimer,
    setStatus,
  ]);

  /**
   * Each tick we decide what to do.
   * This sends keep-alive pings for given statuses
   */
  const tickHandler = useCallback(() => {
    if (!onlineStatusRef.current) {
      return;
    }

    switch (onlineStatusRef.current) {
      case AvailabilityStatusType.Online:
        if (tickCounter.current >= PING_ONLINE_AT_TICK) {
          tickCounter.current = 0;
          publishPingRef.current(AvailabilityStatusType.Online);
        }
        break;
      case AvailabilityStatusType.Away:
        if (tickCounter.current >= PING_AWAY_AT_TICK) {
          tickCounter.current = 0;
          publishPingRef.current(AvailabilityStatusType.Away);
        }
        break;
      case AvailabilityStatusType.Busy:
        if (tickCounter.current >= PING_BUSY_AT_TICK) {
          tickCounter.current = 0;
          publishPingRef.current(AvailabilityStatusType.Busy);
        }
        break;
    }

    tickCounter.current++;
  }, []);

  /***
   * Initialize ticks when account is available
   * Set status to online if account is offline or away
   */
  useEffect(() => {
    if (!onlineStatusRef.current) {
      return;
    }

    if (onlineStatusRef.current === AvailabilityStatusType.Online) {
      initAwayTimer();
    } else if (
      onlineStatusRef.current === AvailabilityStatusType.Offline ||
      onlineStatusRef.current === AvailabilityStatusType.Away
    ) {
      setStatus(AvailabilityStatusType.Online);
      initAwayTimer();
    }

    tickHandler();
    const tickInterval = setInterval(tickHandler, TICK_DURATION);

    return () => {
      clearInterval(tickInterval);
    };
  }, [initAwayTimer, setStatus, tickHandler, initTicks]);

  /**
   * Clean up on unmount
   */
  useEffect(
    () => () => {
      setAwayTimer.current && clearTimeout(setAwayTimer.current);
      setAwayTimer.current = null;
    },
    [],
  );

  return (
    <>
      {children}
      <IdleDetectionProvider />
    </>
  );
};
