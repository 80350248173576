import { HorizontalAlignment, VerticalAlignment } from './Menu.types';
import { MENU_MARGIN } from './Menu';

export interface getMenuFixedCoordinatesOptions {
  menuRect: { width: number; height: number };
  triggerRect: { x: number; y: number; width: number; height: number };
  vAlign: VerticalAlignment;
  hAlign: HorizontalAlignment;
  cursorX?: number;
  cursorY?: number;
  clientWidth: number;
  clientHeight: number;
}

export const getMenuFixedCoordinates = (
  options: getMenuFixedCoordinatesOptions,
) => {
  const {
    menuRect: { width: menuWidth, height: menuHeight },
    triggerRect: {
      x: triggerElementX,
      y: triggerElementY,
      width: triggerElementWidth,
      height: triggerElementHeight,
    },
    vAlign,
    hAlign,
    cursorX,
    cursorY,
    clientWidth,
    clientHeight,
  } = options;

  let xPos, yPos;

  switch (vAlign) {
    case VerticalAlignment.TOP:
      yPos = cursorY
        ? cursorY - menuHeight
        : triggerElementY - menuHeight - MENU_MARGIN;
      break;

    case VerticalAlignment.INSIDE_TOP:
      yPos = cursorY ? cursorY : triggerElementY - MENU_MARGIN;
      break;

    case VerticalAlignment.CENTER:
      yPos = cursorY
        ? cursorY - menuHeight / 2
        : triggerElementY + triggerElementHeight / 2 - menuHeight / 2;
      break;

    case VerticalAlignment.BOTTOM:
    default:
      yPos = cursorY
        ? cursorY
        : triggerElementY + triggerElementHeight + MENU_MARGIN;
      break;
  }

  switch (hAlign) {
    case HorizontalAlignment.RIGHT:
      xPos = cursorX
        ? cursorX - menuWidth
        : triggerElementX + triggerElementWidth - menuWidth;
      break;

    case HorizontalAlignment.CENTER:
      xPos = cursorX
        ? cursorX - menuWidth / 2
        : triggerElementX + triggerElementWidth / 2 - menuWidth / 2;
      break;

    case HorizontalAlignment.OUTSIDE_RIGHT:
      xPos = cursorX
        ? cursorX - menuWidth
        : triggerElementX + triggerElementWidth + MENU_MARGIN / 2;
      break;

    case HorizontalAlignment.LEFT:
    default:
      xPos = cursorX ? cursorX : triggerElementX;
      break;
  }

  // Make sure the menu is not outside the viewport
  if (xPos + menuWidth > clientWidth - MENU_MARGIN) {
    xPos = clientWidth - menuWidth - MENU_MARGIN;
  } else if (xPos < MENU_MARGIN) {
    xPos = MENU_MARGIN;
  }

  if (yPos < MENU_MARGIN) {
    yPos = MENU_MARGIN;
  } else if (yPos + menuHeight > clientHeight - MENU_MARGIN) {
    yPos = clientHeight - (menuHeight + MENU_MARGIN);
  }

  // TODO: Add check to see if height of menu should be altered? I.e. a long menu and not so tall viewport?

  return {
    x: xPos,
    y: yPos,
  };
};
