/* eslint-disable prettier/prettier */
import { BillingLocale, BillingTranslation } from "./i18n.types";

export const enBillingLocale: BillingLocale = {
  // Plan intervals
  [BillingTranslation.planIntervalDay]: 'day',
  [BillingTranslation.planIntervalWeek]: 'week',
  [BillingTranslation.planIntervalMonth]: 'month',
  [BillingTranslation.planIntervalYear]: 'year',
  [BillingTranslation.planIntervalPerUser]: ' / user',

  // Plans
  [BillingTranslation.planFreeTitle]: 'Free',
  [BillingTranslation.planFreeDescription]: 'Free description here..',
  [BillingTranslation.adminBillingDowngradeButton]: 'Downgrade',
  [BillingTranslation.adminBillingCancelSubscriptionConfirmationHeader]:
    'Cancel subscription',
  [BillingTranslation.adminBillingCancelSubscriptionConfirmationMessage]:
    'Are you sure you want to cancel your current subscription? Your account will be suspended after remaining paid period ends.',
  [BillingTranslation.adminBillingCancelSubscriptionSuccessMessage]:
    'Subscription was cancelled',
  [BillingTranslation.adminBillingActivateSubscriptionButton]: 'Activate',
  [BillingTranslation.adminBillingCancelDowngradeSubscriptionButton]:
    'Cancel downgrade',
  [BillingTranslation.adminBillingActivateSubscriptionConfirmationHeader]:
    'Activate subscription',
  [BillingTranslation.adminBillingActivateSubscriptionConfirmationMessage]:
    'Are you sure you want to activate your subscription?',
  [BillingTranslation.adminBillingActivateSubscriptionSuccessMessage]:
    'Subscription was activated',
  [BillingTranslation.adminBillingCancelDowngradeConfirmationMessage]:
    'Are you sure you want to cancel downgrade?',
  [BillingTranslation.adminBillingCancelDowngradeConfirmationHeader]:
    'Cancel downgrade',
  [BillingTranslation.adminBillingCancelDowngradeSuccessMessage]:
    'Downgrade was canceled',
  [BillingTranslation.adminBillingWorkspaceMembersCount]:
    'Workspace members: <strong>{membersCount}</strong>',
  [BillingTranslation.adminBillingNextRenewalDate]:
    'Next renewal date - <span class="date">{date}</span>.',
  [BillingTranslation.adminBillingSubscriptionEnds]:
    'Subscription ends - <span class="date">{date}</span>.',

  [BillingTranslation.planProTitle]: 'Pro',
  [BillingTranslation.planProDescription]:
    'Great for personal projects, students, freelancers and light use.',

  [BillingTranslation.planEnterpriseTitle]: 'Enterprise',
  [BillingTranslation.planEnterpriseDescription]: '',
  [BillingTranslation.planEnterpriseUsps]:
    'Everything in Pro|More than 250 members|Unlimited guests|Audio and video calls for up to 100 participants|Directory federation|Account provisioning|Invoice per account|Dedicated support|20 GB chat storage per member',
  [BillingTranslation.planTrialNote]:
    'You have <span>{days}</span> days remaining of your trial.',

  // General billing form translations
  [BillingTranslation.formNameCardDetailsLabel]: 'Card information',
  [BillingTranslation.formNameOfCardHolderLabel]: 'Name of card holder',
  [BillingTranslation.formNameOfCardHolderRequired]: 'Required',
  [BillingTranslation.formBillingNameLabel]: 'Billing contact',
  [BillingTranslation.formBillingNameRequired]: 'Billing contact is required',
  [BillingTranslation.formVatType]: 'Tax ID type (optional)',
  [BillingTranslation.formVatTypeRequired]:
    'Tax ID type is required if number filled in',
  [BillingTranslation.formVatNumber]: 'Number',
  [BillingTranslation.formVatNumberRequired]: 'Number is required',
  [BillingTranslation.formCountryLabel]: 'Country',
  [BillingTranslation.formCityLabel]: 'City',
  [BillingTranslation.formCityRequired]: 'City is required',
  [BillingTranslation.formLine1Label]: 'Line 1',
  [BillingTranslation.formLine1Required]: 'Line 1 is required',
  [BillingTranslation.formLine2Label]: 'Line 2',
  [BillingTranslation.formPostCodeLabel]: 'Post code',
  [BillingTranslation.formPostCodeRequired]: 'Required',
  [BillingTranslation.formStateLabel]: 'State',
  [BillingTranslation.formCouponLabel]: 'Discount coupon',
  [BillingTranslation.formCouponPlaceholder]: 'Code',
  [BillingTranslation.formEditSubmitButton]: 'Save',
  [BillingTranslation.formEditCancelButton]: 'Cancel',
  [BillingTranslation.formEmailLabel]: 'Billing e-mail',
  [BillingTranslation.formEmailInvalid]: 'E-mail address is not valid',
  [BillingTranslation.formEmailRequired]: 'E-mail is required',
  [BillingTranslation.formVatLearnMoreLabelLink]: 'Learn more about ',
  [BillingTranslation.formVatLearnMoreLink]: 'VAT number formats',
  [BillingTranslation.formClusterTitleBillingContact]: 'Billing contact',
  [BillingTranslation.formClusterTitleAddress]: 'Billing address',
  [BillingTranslation.formClusterTitleVat]: 'VAT (optional)',

  // Stripe specific translations
  [BillingTranslation.stripeInvalidNumber]: 'Invalid card number',
  [BillingTranslation.stripeIncompleteNumber]: 'Incomplete card number',
  [BillingTranslation.stripeIncompleteExpiry]: 'Incomplete expiry date',
  [BillingTranslation.stripeInvalidExpiryMonthPast]:
    'Expiry month is in the past',
  [BillingTranslation.stripeInvalidExpiryYearPast]:
    'Expiry year is in the past',
  [BillingTranslation.stripeIncompleteCvc]: 'Incomplete CVC code',

  // Onboard billing translations
  [BillingTranslation.onboardingBillingTitle]: 'Add your credit card',
  [BillingTranslation.onboardingChangeCardInfoMessage]:
    'Your credit card will not be charged during the 14 day free trial. Cancel anytime.',
  [BillingTranslation.onboardingAddPaymentMethodButton]:
    'Save billing information',
  [BillingTranslation.onboardingBillingSuccess]: 'Thank you!',

  // Workspace admin translations
  [BillingTranslation.adminHeaderTitle]: 'Billing',

  [BillingTranslation.adminBillingCancelled]:
    'Please upgrade your subscription in order to access paid features.',
  [BillingTranslation.adminBillingNotOk]:
    'We encountered an issue when processing your payment. Please update your billing information to access paid features.',

  [BillingTranslation.adminBillingPlanTitle]: 'Current plan',
  [BillingTranslation.adminBillingPlanButton]: 'Change plan',
  [BillingTranslation.adminBillingCouponMessage]:
    '<span class="couponName">{couponName}</span><span class="couponDescription">-{percentOff}% off listed price until {finishedAt}</span>',
  [BillingTranslation.adminBillingDowngradeModalTitle]: 'Confirmation required',
  [BillingTranslation.adminBillingDowngradeModalHighlightText]:
    'Cancelling your subscription will delete all data and content related to this workspace and your account. We will store a backup of your data for 3 calendar months counting from the date of cancelation. Once this grace period expires we will not be able to restore your account data.',
  [BillingTranslation.adminBillingDowngradeModalText]:
    'The cancellation and deletion of data and content will be performed on your next subscription renewal date. All workspace members and guest users will be notified that the subscription has been canceled. We do not currently support downgrading from a pro to a free plan.<br><br>The cancelation request may be rescinded prior to the next subscription renewal date.<br><br>Are you sure you want to proceed?',

  [BillingTranslation.adminBillingInformationTitle]: 'Billing information',
  [BillingTranslation.adminBillingInformationEditButton]: 'Edit',
  [BillingTranslation.adminBillingInformationUpdatedMessage]:
    'Information is updated',
  [BillingTranslation.adminBillingInformationModalTitle]:
    'Edit billing information',

  [BillingTranslation.adminBillingContactTitle]: 'Billing contact',
  [BillingTranslation.adminBillingContactBody]:
    'You can add a billing contact if you want invoices to be sent each month at a specific address.',
  [BillingTranslation.adminBillingContactLabel]: 'Billing e-mail',
  [BillingTranslation.adminBillingContactSubmitButton]: 'Save',
  [BillingTranslation.adminBillingContactUpdatedMessage]:
    'Billing e-mail is updated',

  [BillingTranslation.adminPaymentMethodsTitle]: 'Payment methods',
  [BillingTranslation.adminPaymentMethodsAddButton]: 'Add payment method',
  [BillingTranslation.adminPaymentMethodChangeSuccess]:
    'Payment method updated',
  [BillingTranslation.adminPaymentMethodModalTitle]: 'Add credit card',
  [BillingTranslation.adminPaymentMethodSetAsDefault]:
    'Set as preferred payment method',
  [BillingTranslation.adminPaymentMethodDeleteSuccess]:
    'Payment method was deleted',
  [BillingTranslation.adminPaymentMethodDeleteConfirmationHeader]:
    'Confirm payment method deletion',
  [BillingTranslation.adminPaymentMethodDeleteConfirmation]:
    'Are you sure that you want to delete this payment method?',
  [BillingTranslation.adminPaymentMethodDeleteConfirmationButtonText]: 'Delete',

  [BillingTranslation.adminInvoicesTitle]: 'Invoices',
  [BillingTranslation.adminInvoicesNoInvoices]: 'No invoices yet',

  // Table headers
  [BillingTranslation.adminDateTableHeader]: 'Date',
  [BillingTranslation.adminPaymentMethodTableHeader]: 'Payment method',
  [BillingTranslation.adminAmountTableHeader]: 'Amount',
  [BillingTranslation.adminPDFTableHeader]: 'PDF',
  [BillingTranslation.adminDefaultMetodTableHeader]: 'Default method',
  [BillingTranslation.adminPaymentAccountTableHeader]: 'Personal profile',
  [BillingTranslation.adminExpiryDateTableHeader]: 'Expiration date',

  // Upgrade
  [BillingTranslation.upgradeFreeModalTitle]:
    'Upgrade to Pro - starting 14 days trial',
  [BillingTranslation.upgradeFreeModalSubheaderText]:
    'Your credit card will not be charged until the end of the 14 days trial period. Prior to the end of the trial period, you may cancel at any moment to avoid being charged.',
  [BillingTranslation.upgradeFreeModalSubheaderPrice]:
    '${amount} USD per user per month',
  [BillingTranslation.upgradeToProButtonLabel]: 'Upgrade to pro',
  [BillingTranslation.upgradeToProDescription]:
    'Enjoy the best of Webtop.com by upgrading your account and benefit from our Pro features!',
  [BillingTranslation.upgradeToProTitle]:
    'Upgrade to Pro (<span>${cost}</span> USD / Month) - 14 days trial',
  [BillingTranslation.upgradeToProDomainSecurity]: 'Domain security',
  [BillingTranslation.upgradeToProMembersUnlimitedGuests]:
    'Up to 250 members. Unlimited guests',
  [BillingTranslation.upgradeToProAudioAndVideoCalls]:
    'Audio and video calls for up to 25 participants',
  [BillingTranslation.upgradeToProSingleSignOn]: 'Single sign on (SSO)',
  [BillingTranslation.upgradeToProSlackChatIntegration]:
    'Slack chat integration',
  [BillingTranslation.upgradeToProSharingPasswords]: 'Sharing passwords',
  [BillingTranslation.upgradeToProSubdomain]: 'Subdomain',
  [BillingTranslation.upgradeToProPriorityOnlineSupport]:
    'Priority online support',
  [BillingTranslation.upgradeToProChatStorage]: '10 GB chat storage per member',
  [BillingTranslation.upgradeToProAppIntegrations]:
    'App integrations for search and notifications',
  [BillingTranslation.upgradeToProSuccessMessage]:
    'Thank you for subscribing to {workspaceDomain} pro!',
  [BillingTranslation.upgradeToProSuccessContinueButton]:
    'Continue and configure your subdomain',
  [BillingTranslation.upgradeConfirmationTitle]: 'Upgrade to pro',
  [BillingTranslation.upgradeConfirmation]:
    'Would you like to continue using your previous payment method, or enter new details?',
  [BillingTranslation.upgradeEnterNewDetailsButton]: 'Enter new details',
  [BillingTranslation.upgradeContinueButton]: 'Continue',
  [BillingTranslation.upgradeAccountSuccess]: 'Account successfully upgraded',

  // Need to upgrade
  [BillingTranslation.confirmationHeader]: 'This is a Pro feature',
  [BillingTranslation.confirmButton]: 'Upgrade',
  [BillingTranslation.generalUpgradeMessage]:
    'To enjoy this feature, and several others, please upgrade to the Pro tier.',
  [BillingTranslation.requestUpgradeModalTitle]:
    'Upgrade to Pro (<span>${cost}</span> USD / Month) - 14 days trial',
  [BillingTranslation.requestUpgradeModalIntro]:
    '<p>Enjoy the best of <a href="{appUrl}" rel="noopener" target="_blank">Webtop.com</a> by upgrading your account and benefit from our Pro features!</p>',
  [BillingTranslation.requestUpgradeModalUsp1]:
    'Up to 250 members. Unlimited guests',
  [BillingTranslation.requestUpgradeModalUsp2]:
    'Audio and video calls for up to 25 participants',
  [BillingTranslation.requestUpgradeModalUsp3]: 'Single Sign-On (SSO)',
  [BillingTranslation.requestUpgradeModalUsp4]: 'Slack chat integration',
  [BillingTranslation.requestUpgradeModalUsp5]: 'Sharing passwords',
  [BillingTranslation.requestUpgradeModalUsp6]: 'Subdomain',
  [BillingTranslation.requestUpgradeModalUsp7]: 'Domain security',
  [BillingTranslation.requestUpgradeModalUsp8]: 'Priority online support',
  [BillingTranslation.requestUpgradeModalUsp9]: '10 GB chat storage per member',
  [BillingTranslation.requestUpgradeModalUsp10]:
    'App integrations for search and notifications',
  [BillingTranslation.requestUpgradeModalConfirm]: 'Next',
  [BillingTranslation.requestUpgradeModalCancel]: 'Cancel',
  [BillingTranslation.requestUpgradeModalComing]: 'Coming',
  [BillingTranslation.seeAllFeaturesLinkText]: 'See all features',
  [BillingTranslation.seeAllFeaturesInProLinkText]: 'See all features in Pro',
  [BillingTranslation.noAccessToFeature]:
    'You are not allowed to access this feature',
  [BillingTranslation.downgradeModalTitle]: 'Downgrading to a Free plan',
  [BillingTranslation.downgradeModalHeaderText]:
    'Are you sure you want to downgrade?',
  [BillingTranslation.downgradeModalText]:
    'Downgrading to a Free plan will significantly limit your account and delete certain data: ',
  [BillingTranslation.downgradeModalTextBullet1]:
    'Downgrading will reduce the number of workspace members to 2, including yourself.',
  [BillingTranslation.downgradeModalTextBullet2]:
    'Please choose which workspace member to keep in addition to yourself, after the downgrade:',
  [BillingTranslation.downgradeModalTextBullet3]: 'Other notable limitations: ',
  [BillingTranslation.downgradeModalTextBullet4]:
    'No guest users are allowed on the Free plan and access for already invited guest users will be revoked',
  [BillingTranslation.downgradeModalTextBullet5]: 'No Single-Sign-On',
  [BillingTranslation.downgradeModalTextBullet6]:
    'No ability to share passwords',
  [BillingTranslation.downgradeModalTextBullet7]: 'No app integrations',
  [BillingTranslation.downgradeModalTextBullet8]:
    'For a full list, please visit <a href="{learMoreLink}" rel="noopener" target="_blank" data-testid="lear-more-link">learn more</a>',
  [BillingTranslation.downgradeModalWarningText]:
    'You may cancel the downgrade at any time prior to your next billing cycle <span class="nextRenewAt">{date}</span>. After this date any additional workspace members will be removed, and their data will be permanently deleted and can not be recovered.',
  [BillingTranslation.downgradeModalSubmit]: 'Downgrade',
  [BillingTranslation.downgradeModalInputPlaceholder]:
    'Select the workspace member that you would like to keep',
  [BillingTranslation.downgradeModalErrorText]: 'Only one users can be left',
};
