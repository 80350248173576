import styled, { keyframes } from 'styled-components';
import { memo } from 'react';

type MarginConfig =
  | [number]
  | [number, number]
  | [number, number, number]
  | [number, number, number, number];

interface SkeletonBoxProps {
  color?: string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number;
  margin?: MarginConfig;
}

const generateMarginRule = (margin?: MarginConfig): string =>
  margin ? `margin: ${margin.map(d => (d ? `${d}rem` : 0)).join(' ')};` : '';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

export const SkeletonBox = memo(styled.div<SkeletonBoxProps>`
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: ${({ borderRadius = 0.125 }) => `${borderRadius}rem`};
  height: ${({ height }) =>
    `${typeof height === 'number' ? `${height}rem` : '100%'}`};
  width: ${({ width }) =>
    `${typeof width === 'number' ? `${width}rem` : '100%'}`};
  background-color: ${({ color = '#dfe3e8' }) => color};
  ${({ margin }) => generateMarginRule(margin)}

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.05) 20%,
      rgba(255, 255, 255, 0.3) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${shimmer} 5s infinite;
    content: '';
  }
`);

export const SkeletonElement = styled(SkeletonBox)`
  &::after {
    content: none;
  }
`;
