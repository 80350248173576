import styled from 'styled-components';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const RelatedItemDeleteButton = styled(ButtonWithIcon)`
  color: ${p => p.theme.colors.CriticalLight};
  padding: ${({ theme }) => theme.spacing.xs};

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:active {
    outline: none;
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
  }
`;
