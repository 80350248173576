import { memo, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

interface MenuViaPortalProps {
  portalContainerId: string;
}

export const MenuViaPortal = memo<PropsWithChildren<MenuViaPortalProps>>(
  ({ children, portalContainerId }) => {
    return ReactDOM.createPortal(
      children,
      document.getElementById(portalContainerId) as Element,
    );
  },
);
