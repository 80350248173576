import React, { Dispatch, SetStateAction } from 'react';
import { LinkApiType } from '../Link/Link.types';
import { FolderApiType } from '../Folder/Folder.types';
import { DesktopLayout } from './Desktop.constants';
import type {
  DesktopApiType,
  DesktopAppEdgeApiType,
} from './data/Desktop/types/Desktop.types';
import { AccessApiType } from '../Access/data/types/Access.types';

export const DesktopContext = React.createContext<
  DesktopApiType | null | undefined
>(null);

export type CurrentDesktopContentContextType = {
  desktopApps: DesktopAppEdgeApiType[];
  desktopAppsLoading: boolean;
  desktopFolders: Array<FolderApiType>;
  desktopLinks: LinkApiType[];
  desktopLinksLoading: boolean;
  desktopLinksTotal: number;
  hasChat: boolean;
  desktopAccessForCurrentAccount?: AccessApiType | null;
  setDesktopLayout: (layout: DesktopLayout) => void;
  desktopLayout: DesktopLayout;
  setMovingLinkToFolder: Dispatch<SetStateAction<string | undefined>>;
  movingLinkToFolder?: string;
};

export const CurrentDesktopContentContext =
  React.createContext<CurrentDesktopContentContextType>({
    desktopApps: [],
    desktopAppsLoading: false,
    desktopFolders: [],
    desktopLinks: [],
    desktopLinksLoading: false,
    desktopLinksTotal: 0,
    hasChat: false,
    desktopAccessForCurrentAccount: null,
    setDesktopLayout: () => {},
    desktopLayout: DesktopLayout.CONSOLIDATED,
    setMovingLinkToFolder: () => {},
    movingLinkToFolder: undefined,
  });
