import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const TagIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0C1.10218 0 0.72064 0.15804 0.43934 0.43934C0.15804 0.72064 0 1.10218 0 1.5V8.44C0 8.837 0.158 9.219 0.44 9.5L9.19 18.25C9.4713 18.5309 9.8525 18.6887 10.25 18.6887C10.6475 18.6887 11.0287 18.5309 11.31 18.25L18.25 11.31C18.5309 11.0287 18.6887 10.6475 18.6887 10.25C18.6887 9.8525 18.5309 9.4713 18.25 9.19L9.5 0.44C9.219 0.15862 8.8377 0.00035 8.44 0H1.5ZM1.5 1.5H8.44L17.19 10.25L10.25 17.19L1.5 8.44V1.5ZM6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6C5 6.26522 5.10536 6.51957 5.29289 6.70711C5.48043 6.89464 5.73478 7 6 7C6.26522 7 6.51957 6.89464 6.70711 6.70711C6.89464 6.51957 7 6.26522 7 6C7 5.73478 6.89464 5.48043 6.70711 5.29289C6.51957 5.10536 6.26522 5 6 5Z"
        fill="currentColor"
      />
    </svg>
  );
});
