import { FileAssetApiType, ImageAssetApiType } from '../../Asset/Asset.types';
import { LinkApiType, LinkData, TagApiType } from '../../Link/Link.types';
import {
  AccountApiType,
  AccountApiTypeWithoutIdentity,
  AccountWorkspaceCacheApiType,
} from '../../User/User.types';
import { WorkspaceApiType } from '../../Workspace/Workspace.types';
import { NotificationApiType } from '../../Notifications/Notifications.types';
import { TimelineEventContext } from '../../Timeline/Timeline.types';
import {
  ChatConversationApiType,
  ChatMessageApiType,
} from '../../Chat/Chat.types';
import { FolderApiType } from '../../Folder/Folder.types';
import {
  AppVaultItemConfigApiType,
  AppVaultItemDesktopConfigApiType,
  VaultItemApiType,
  VaultItemType,
} from '../../Encryption/Encryption.types';
import type {
  DesktopApiType,
  DesktopAppEdgeApiType,
} from '../../Desktop/data/Desktop/types/Desktop.types';
import { DesktopAccessType } from '../../Desktop/data/Desktop/types/Desktop.types';
import { WorkspaceInfoApiType } from '../../WorkspaceInfo/data/WorkspaceInfo/types/WorkspaceInfo.types';
import { TeamApiType } from '../../Team/data/Team/types/Team.types';
import { StreamInvitationEvent } from '../../Invitation/data/Invitation/types/InvitationMercure.types';

export enum StreamEventRequest {
  NEW = 'new',
  REMOVE = 'remove',
  UPDATE = 'update',
}

export enum StreamEventActionRequest {
  ADDED_TO_DESKTOP = 'addedToDesktop',
  REMOVED_FROM_DESKTOP = 'removedFromDesktop',
  REFRESH_WORKSPACE_PERMISSION = 'refreshWorkspacePermission',
  REFRESH_WORKSPACE_LIST = 'refreshWorkspaceList',
  DEPLOY = 'Deploy',
  WORKSPACE_CREATED = 'workspaceCreated',
  WORKSPACE_ACCOUNT_INFO_UPDATE = 'workspaceAccountInfoUpdate',
  WORKSPACE_STATISTICS_UPDATE = 'workspaceStatisticsUpdate',
  ACCOUNT_WORKSPACE_STATISTICS_UPDATE = 'accountWorkspaceStatisticsUpdate',
  REMOVED_FROM_WORKSPACE = 'removedFromWorkspace',
  ACTIVATED_IN_WORKSPACE = 'ActivatedInWorkspace',
  SUSPENDED_IN_WORKSPACE = 'SuspendedInWorkspace',
  REFRESH_WORKSPACE_DESKTOP_LIST = 'refreshWorkspaceDesktopList',
  SUGGESTED_APPS_FROM_BROWSER_HISTORY = 'suggestedAppsFromBrowserHistory',
  REMOVE_FROM_CHAT_CONVERSATION = 'removedFromChatConversation',
  ADD_TO_CHAT_CONVERSATION = 'addedToChatConversation',
  DESKTOP_CHAT_ENABLED = 'desktopChatEnabled',
  PONG_MERCURE = 'pongMercure',
  ACCOUNT_LOGOUT = 'accountLogout',
  ACCEPT_INBOUND_CALL = 'acceptInboundCall',
  REJECT_INBOUND_CALL = 'rejectInboundCall',
  GOOGLE_ACCOUNT_CONNECTED = 'googleAccountConnected',
  MICROSOFT_ACCOUNT_CONNECTED = 'microsoftAccountConnected',
  SCHEDULED_MAINTENANCE = 'ScheduledMaintenance',
  SCHEDULED_MAINTENANCE_CANCEL = 'ScheduledMaintenanceCancel',
  READ_MULTIPLE_CHAT_MESSAGES = 'readMultipleChatMessages',
  REFRESH_ACCOUNT_UNREADS = 'refreshAccountUnreads',
  REFRESH_ACCOUNT_NOTIFICATIONS = 'refreshAccountNotifications',
  APP_SECTION_DELETED = 'AppSectionDeleted',
  LINK_SECTION_DELETED = 'LinkSectionDeleted',
  ADD_WORKSPACE_TEAM_ACCOUNT = 'addWorkspaceTeamAccount',
  REMOVE_WORKSPACE_TEAM_ACCOUNT = 'removeWorkspaceTeamAccount',
}

export enum StreamEventType {
  DESKTOP_APP = 'DesktopApp',
  DESKTOP_ACCESS = 'DesktopAccess',
  DESKTOP = 'Desktop',
  FOLDER = 'Folder',
  LINK = 'Link',
  ACTION = 'Action',
  NOTIFICATION = 'Notification',
  TIMELINE = 'App\\Timeline\\DTO\\QueryTimelineEventOutput',
  ACCOUNT_WORKSPACE = 'AccountWorkspace',
  ACCOUNT_WORKSPACE_CREATED = 'App\\Account\\Background\\Message\\Event\\AccountWorkspaceCreatedEvent',
  ACCOUNT = 'Account',
  ACCOUNT_INVITATION = 'AccountInvitation',
  VAULT = 'Vault',
  VAULT_ACCESS = 'VaultAccess',
  VAULT_ITEM = 'VaultItem',
  VAULT_ITEM_CONFIG_DESKTOP = 'AppVaultItemConfigDesktop',
  APPS_INTEGRATIONS_ACTION = 'Action',
  FILE_ASSET_UPDATE = 'FileAsset',
  FAVORITE = 'Favorite',
  TEAM = 'WorkspaceTeam',
}

export enum SteamEventLoginAppIntegrationsRequest {
  GOOGLE_ACCOUNT_CONNECTED = 'googleAccountConnected',
  GITHUB_ACCOUNT_CONNECTED = 'githubAccountConnected',
  TRELLO_ACCOUNT_CONNECTED = 'trelloAccountConnected',
  DROPBOX_ACCOUNT_CONNECTED = 'dropboxAccountConnected',
  MAILCHIMP_ACCOUNT_CONNECTED = 'mailchimpAccountConnected',
  NOTION_ACCOUNT_CONNECTED = 'notionAccountConnected',
  SALESFORCE_ACCOUNT_CONNECTED = 'salesforceAccountConnected',
  ASANA_ACCOUNT_CONNECTED = 'asanaAccountConnected',
  JIRA_ACCOUNT_CONNECTED = 'jiraAccountConnected',
  HUBSPOT_ACCOUNT_CONNECTED = 'hubspotAccountConnected',
  ONEDRIVE_ACCOUNT_CONNECTED = 'microsoftAccountConnected',
}

export interface StreamBaseEvent {
  '@iri': string;
  '@request': StreamEventRequest;
  '@type': StreamEventType;
  id: string;
  createdBy?: AccountApiTypeWithoutIdentity;
  '@timestamp': number;
}

export interface StreamWorkspaceType
  extends Pick<WorkspaceApiType, 'id' | 'name' | 'desktops'> {}

export interface StreamDesktopType
  extends Pick<
    DesktopApiType,
    | 'id'
    | 'name'
    | 'description'
    | 'accountsThatChatHidden'
    | 'chatConversationIri'
    | 'accountIds'
    | 'shareable'
    | 'memberCount'
    | 'userCount'
    | 'teamCount'
    | 'accountSortPosition'
    | 'chatEnabled'
    | 'isGeneral'
  > {
  workspace: StreamWorkspaceType;
}

export interface StreamFolderType extends FolderApiType {}

export interface StreamDesktopAccessEvent extends StreamBaseEvent {
  '@type': StreamEventType.DESKTOP_ACCESS;
  '@request': StreamEventRequest;
  desktop: StreamDesktopType;
  accessType: DesktopAccessType;
  account?: AccountApiType;
  workspaceTeam?: TeamApiType;
  createdAt: string;
  modifiedAt: string;
}

export interface StreamDesktopAppEvent extends StreamBaseEvent {
  '@type': StreamEventType.DESKTOP_APP;
  '@request': StreamEventRequest;
  desktop: StreamDesktopType;
}

export interface StreamDesktopEvent extends StreamBaseEvent, StreamDesktopType {
  '@type': StreamEventType.DESKTOP;
  '@request': StreamEventRequest;
  workspace: StreamWorkspaceType;
}

export interface StreamTeamEvent extends StreamBaseEvent, TeamApiType {
  '@type': StreamEventType.TEAM;
  '@request': StreamEventRequest;
  workspace: StreamWorkspaceType;
}

export interface StreamFavoriteEvent extends StreamBaseEvent {
  '@type': StreamEventType.FAVORITE;
  '@request': StreamEventRequest;
  id: string;
  desktopApp?: DesktopAppEdgeApiType;
  link?: LinkApiType;
  folder?: FolderApiType;
}

export interface StreamFolderEvent extends StreamBaseEvent, FolderApiType {
  '@type': StreamEventType.FOLDER;
  '@request': StreamEventRequest;
  desktopIri: string;
  workspaceIri: string;
}

export interface StreamLinkEvent
  extends Omit<LinkApiType, 'tags'>,
    Omit<StreamBaseEvent, 'createdBy'> {
  '@type': StreamEventType.LINK;
  desktop: StreamDesktopType;
  folder: StreamFolderType | null;
  data: LinkData;
  tags: TagApiType[];
}

export interface StreamFileAssetEvent
  extends StreamBaseEvent,
    Pick<FileAssetApiType, 'metadata'> {
  '@type': StreamEventType.FILE_ASSET_UPDATE;
  '@request': StreamEventRequest.UPDATE;
}

export interface StreamNotificationEvent
  extends StreamBaseEvent,
    NotificationApiType {
  '@type': StreamEventType.NOTIFICATION;
  '@request': StreamEventRequest;
  recipient: {
    id: string;
    onCall: boolean;
  };
  workspace: {
    _id: string;
    id: string;
  };
}

export interface StreamTimelineEvent extends StreamBaseEvent {
  '@class': string;
  '@iri': string;
  '@type': StreamEventType.TIMELINE;
  '@request': StreamEventRequest;
  context: TimelineEventContext;
  scopeId: string;
  workspace: {
    createdAt: string;
    id: string;
    _id: string;
    modifiedAt: string;
    name: string;
    __typename: string;
  };
}

export interface StreamVaultEvent extends StreamBaseEvent {
  '@class': string;
  '@iri': string;
  '@type': StreamEventType.VAULT;
  '@request': StreamEventRequest;
  id: string;
  createdAt: string;
  modifiedAt: string;
  vaultItems: (Omit<VaultItemApiType, 'appVaultItemConfigs'> & {
    appVaultItemConfigs: (Omit<
      AppVaultItemConfigApiType,
      'appVaultItemConfigDesktops'
    > & { appVaultItemConfigDesktops: AppVaultItemDesktopConfigApiType[] })[];
  })[];
  workspace: WorkspaceApiType;
}

export interface StreamVaultAccessEvent extends StreamBaseEvent {
  '@class': string;
  '@iri': string;
  '@type': StreamEventType.VAULT_ACCESS;
  '@request': StreamEventRequest;
  id: string;
  keyset: {
    _id: string;
  };
  vaultKeyEncrypted: string;
  isImplicitlyShared?: boolean;
  shared: boolean;
  parent: {
    id: string;
    keyset: {
      _id: string;
    };
    vaultKeyEncrypted: string;
    isImplicitlyShared?: boolean;
    shared: boolean;
    parent: false;
    createdAt: string;
  } | null;
  createdAt: string;
  vault: {
    id: string;
    vaultItems: (Omit<VaultItemApiType, 'appVaultItemConfigs'> & {
      appVaultItemConfigs: (Omit<
        AppVaultItemConfigApiType,
        'appVaultItemConfigDesktops'
      > & { appVaultItemConfigDesktops: AppVaultItemDesktopConfigApiType[] })[];
    })[];
    createdAt: string;
    modifiedAt: string;
    workspace: WorkspaceApiType;
  };
}

export interface StreamVaultItemConfigDesktopRequestEvent
  extends StreamBaseEvent {
  '@class': string;
  '@iri': string;
  '@type': StreamEventType.VAULT_ITEM_CONFIG_DESKTOP;
  '@request': StreamEventRequest;
  account: AccountApiType;
  appVaultItemConfig: AppVaultItemConfigApiType & {
    vaultItem: {
      vault: {
        _id: string;
        workspace: Pick<WorkspaceApiType, 'id'>;
      };
    };
  };
  desktop: DesktopApiType;
}

export interface AppsIntegrationsResultItem {
  context: string;
  context_id: string;
  external_uri: string;
  summary: string;
  title: string;
  app?: {
    id: string;
    logo: {
      contentUrl: string;
    };
  };
}

export interface AppsIntegrationsPayload {
  context: string;
  context_id: string;
  external_uri: string;
  summary: string;
  title: string;
  results: AppsIntegrationsResultItem[];
  app: {
    id: string;
    logo: {
      contentUrl: string;
    };
  };
  workerTask: {
    id: string;
  };
  workspace: {
    createdAt: string;
    id: string;
    _id: string;
    modifiedAt: string;
    name: string;
    __typename: string;
  };
}

export interface StreamVaultItemEvent extends StreamBaseEvent {
  '@class': string;
  '@iri': string;
  '@type': StreamEventType.VAULT_ITEM;
  '@request': StreamEventRequest;
  id: string;
  image: ImageAssetApiType;
  login: string;
  password: string;
  url: string;
  type: VaultItemType;
  account: AccountApiType;
  appVaultItemConfigs: AppVaultItemConfigApiType[];
  vault: {
    id: string;
    vaultItems: VaultItemApiType[];
    workspace: WorkspaceApiType;
  };
}

// The EventAction differs from the other Events that they
// comes with a payload – and is a unique event for a specific user.
interface StreamBaseEventAction {
  '@type': StreamEventType.ACTION;
  '@timestamp': number;
}

export interface StreamDesktopEventAction extends StreamBaseEventAction {
  '@request':
    | StreamEventActionRequest.ADDED_TO_DESKTOP
    | StreamEventActionRequest.REMOVED_FROM_DESKTOP
    | StreamEventActionRequest.REFRESH_WORKSPACE_LIST
    | StreamEventActionRequest.REFRESH_WORKSPACE_PERMISSION
    | StreamEventActionRequest.APP_SECTION_DELETED
    | StreamEventActionRequest.LINK_SECTION_DELETED;
  '@payload': DesktopApiType;
}

export interface StreamWorkspaceAccountInfoEventAction
  extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.WORKSPACE_ACCOUNT_INFO_UPDATE;
  '@payload': WorkspaceInfoApiType;
}

export interface StreamPongEventAction extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.PONG_MERCURE;
  '@payload': {
    status: string;
  };
}

export enum DeployEventStatus {
  Started = 'STARTED',
  Finished = 'FINISHED',
}

export interface StreamDeployEventAction extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.DEPLOY;
  '@payload': {
    status: DeployEventStatus;
  };
}

export interface StreamScheduledMaintenanceEventAction
  extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.SCHEDULED_MAINTENANCE;
  '@payload': {
    time: string;
  };
}

export interface StreamScheduledMaintenanceCancelEventAction
  extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.SCHEDULED_MAINTENANCE_CANCEL;
  '@payload': {
    status: string;
  };
}

export interface ConversationEventApiType
  extends Omit<ChatConversationApiType, 'lastMessage'> {
  workspace: WorkspaceApiType;
  lastMessage: ChatMessageApiType;
}

export interface StreamRemoveFromChatConversation
  extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.REMOVE_FROM_CHAT_CONVERSATION;
  '@payload': ConversationEventApiType;
}

export interface StreamAddToChatConversation extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.ADD_TO_CHAT_CONVERSATION;
  '@payload': ConversationEventApiType;
}

export interface StreamDesktopChatEnabled extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.DESKTOP_CHAT_ENABLED;
  '@payload': DesktopApiType;
}

export type WorkspaceEventApiType = Pick<
  WorkspaceApiType,
  'id' | '__typename' | 'name' | 'type' | 'uri'
>;

export interface StreamWorkspaceEventAction extends StreamBaseEventAction {
  '@request':
    | StreamEventActionRequest.WORKSPACE_CREATED
    | StreamEventActionRequest.WORKSPACE_ACCOUNT_INFO_UPDATE
    | StreamEventActionRequest.REMOVED_FROM_WORKSPACE
    | StreamEventActionRequest.REFRESH_WORKSPACE_DESKTOP_LIST
    | StreamEventActionRequest.ACTIVATED_IN_WORKSPACE
    | StreamEventActionRequest.SUSPENDED_IN_WORKSPACE
    | StreamEventActionRequest.REFRESH_ACCOUNT_NOTIFICATIONS;
  '@payload': WorkspaceEventApiType;
}

export interface StreamWorkspaceStatisticsUpdateEventAction
  extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.WORKSPACE_STATISTICS_UPDATE;
  '@payload': {
    workspaceId: string;
  };
}

export interface StreamAccountWorkspaceStatisticsUpdateEventAction
  extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.ACCOUNT_WORKSPACE_STATISTICS_UPDATE;
  '@payload': {
    accountId: string;
    workspaceId: string;
  } & AccountWorkspaceCacheApiType;
}

export interface StreamSuggestedAppsEventAction extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.SUGGESTED_APPS_FROM_BROWSER_HISTORY;
  '@payload': DesktopAppEdgeApiType;
}

export interface StreamAccountWorkspaceCreateEvent
  extends Omit<StreamBaseEvent, '@iri'> {
  '@iri': null;
  '@type': StreamEventType.ACCOUNT_WORKSPACE_CREATED;
  '@request': StreamEventRequest.NEW;
  id: string;
  accountWorkspace: {
    account: AccountApiType;
    workspace: WorkspaceApiType;
    __typename: 'AccountWorkspace';
    _id: string;
  };
  chatConversationIri: string | null;
  desktopIri: string | null;
}

export interface StreamAccountWorkspaceUpdateEvent extends StreamBaseEvent {
  account: AccountApiType;
  workspace: WorkspaceApiType;
  pingAt: string;
  createdAt: string;
  modifiedAt: string;
  _id: string;
  __typename: 'AccountWorkspace';
  '@type': StreamEventType.ACCOUNT_WORKSPACE;
}

export interface StreamAccountUpdateEvent
  extends Pick<
      AccountApiType,
      | 'unreadNotifications'
      | 'unreadChatMessages'
      | 'unreadChatConferences'
      | 'unreadMentions'
      | 'unreadThreads'
    >,
    StreamBaseEvent {
  '@type': StreamEventType.ACCOUNT;
  '@request': StreamEventRequest.UPDATE;
}

export const enum StreamRefreshAccountUnreadsEventPayloadTypes {
  chat = 'chat',
  meetings = 'meetings',
  inbox = 'inbox',
  notifications = 'notifications',
}

export interface StreamRefreshAccountUnreadsEventPayload {
  types: StreamRefreshAccountUnreadsEventPayloadTypes;
  workspace?: string | null;
}

export interface StreamRefreshAccountUnreadsEvent
  extends StreamBaseEventAction {
  '@payload': StreamRefreshAccountUnreadsEventPayload;
  '@request': StreamEventActionRequest.REFRESH_ACCOUNT_UNREADS;
}

export interface CustomMercureListener {
  action: StreamEventRequest;
  type: StreamEventType;
  callback: () => void;
}

export interface StreamAccountLogoutEventAction extends StreamBaseEventAction {
  '@iri': string;
  '@payload': {
    accountId: string;
    sessionId: string;
  };
  '@request': StreamEventActionRequest.ACCOUNT_LOGOUT;
}

export interface StreamAcceptInboundCallEventAction
  extends StreamBaseEventAction {
  '@payload': {
    id: string;
  };
  '@request': StreamEventActionRequest.ACCEPT_INBOUND_CALL;
}

export interface StreamRejectInboundCallEventAction
  extends Omit<StreamAcceptInboundCallEventAction, '@request'> {
  '@request': StreamEventActionRequest.REJECT_INBOUND_CALL;
}

export interface StreamGoogleAccountConnectedAction
  extends Omit<StreamBaseEvent, '@request'> {
  '@request': StreamEventActionRequest.GOOGLE_ACCOUNT_CONNECTED;
  '@type': StreamEventType.ACTION;
}

export interface StreamMicrosoftAccountConnectedAction
  extends Omit<StreamBaseEvent, '@request'> {
  '@request': StreamEventActionRequest.MICROSOFT_ACCOUNT_CONNECTED;
  '@type': StreamEventType.ACTION;
}

export interface StreamLoginAppIntegrationsEvent
  extends Omit<StreamBaseEvent, '@request' | '@type'> {
  '@request': SteamEventLoginAppIntegrationsRequest;
  '@type': StreamEventType.APPS_INTEGRATIONS_ACTION;
  '@payload': {
    type: string;
  };
}

export interface ReadMultipleChatMessagesEventAction
  extends StreamBaseEventAction {
  '@request': StreamEventActionRequest.READ_MULTIPLE_CHAT_MESSAGES;
  '@payload': {
    account: string;
    chatMessagesIris: string[];
  };
}

export const enum WorkspaceSettingsTypes {
  GROUPS = 'groups',
}

export interface WorkspaceTeamAccountPayload {
  workspaceTeamId: string;
  accountId: string;
}

export interface StreamAddWorkspaceTeamAccountEvent
  extends Omit<StreamBaseEvent, '@request'> {
  '@type': StreamEventType.ACTION;
  '@request': StreamEventActionRequest.ADD_WORKSPACE_TEAM_ACCOUNT;
  '@payload': WorkspaceTeamAccountPayload;
}

export interface StreamRemoveWorkspaceTeamAccountEvent
  extends Omit<StreamBaseEvent, '@request'> {
  '@type': StreamEventType.ACTION;
  '@request': StreamEventActionRequest.REMOVE_WORKSPACE_TEAM_ACCOUNT;
  '@payload': WorkspaceTeamAccountPayload;
}

export type StreamEventActions =
  | StreamDesktopEventAction
  | StreamDeployEventAction
  | StreamWorkspaceEventAction
  | StreamAccountWorkspaceStatisticsUpdateEventAction
  | StreamWorkspaceStatisticsUpdateEventAction
  | StreamSuggestedAppsEventAction
  | StreamRemoveFromChatConversation
  | StreamAddToChatConversation
  | StreamDesktopChatEnabled
  | StreamPongEventAction
  | StreamAccountLogoutEventAction
  | StreamAcceptInboundCallEventAction
  | StreamRejectInboundCallEventAction
  | StreamGoogleAccountConnectedAction
  | StreamMicrosoftAccountConnectedAction
  | StreamLoginAppIntegrationsEvent
  | StreamScheduledMaintenanceEventAction
  | StreamScheduledMaintenanceCancelEventAction
  | ReadMultipleChatMessagesEventAction
  | StreamRefreshAccountUnreadsEvent
  | StreamWorkspaceAccountInfoEventAction;

export type StreamEvent =
  | StreamDesktopAccessEvent
  | StreamDesktopAppEvent
  | StreamDesktopEvent
  | StreamFolderEvent
  | StreamFavoriteEvent
  | StreamLinkEvent
  | StreamNotificationEvent
  | StreamEventActions
  | StreamTimelineEvent
  | StreamTeamEvent
  | StreamAccountWorkspaceCreateEvent
  | StreamAccountWorkspaceUpdateEvent
  | StreamAccountUpdateEvent
  | StreamVaultEvent
  | StreamVaultAccessEvent
  | StreamVaultItemEvent
  | StreamVaultItemConfigDesktopRequestEvent
  | StreamFileAssetEvent
  | StreamAddWorkspaceTeamAccountEvent
  | StreamRemoveWorkspaceTeamAccountEvent
  | StreamInvitationEvent;
