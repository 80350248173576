import { gql } from '@apollo/client';
import { GRAPHQL_TYPENAME_FOLDER } from './Folder.constants';

export const FolderFragmentFields = `
  id
  _id
  accountFavorite
  name
  createdAt
  desktop {
    id
  }
  parent {
    id
  }
  subFolderIds
  linksCount
  image {
    id
    contentUrl
  }
`;

export const FolderFragment = gql`
  fragment FolderFragment on ${GRAPHQL_TYPENAME_FOLDER} {
    ${FolderFragmentFields}
  }
`;
