import { matchPath, useLocation } from 'react-router';
import {
  ADMIN_PANEL_PATHNAME,
  CHATS_CONVERSATION_PATHNAME,
  CHATS_FIND_ACCOUNT_CHAT_PATHNAME,
  CHATS_ROOT_PATHNAME,
  COMPANY_DESKTOP_ID_PATHNAME,
  CONFERENCES_CONFERENCE_PATHNAME,
  CONFERENCES_ROOT_PATHNAME,
  DESKTOP_ID_PATHNAME,
  DESKTOP_LAYOUT_KEY,
  DESKTOP_TAB_PATHNAME,
  DesktopLayout,
  DesktopType,
  FAVORITES_ROOT_PATHNAME,
  FAVORITES_TAB_PATHNAME,
  PASSWORDS_ROOT_PATHNAME,
  PASSWORDS_VAULT_PATHNAME,
} from './Desktop.constants';
import { useQueryParams } from '../../shared/hooks';
import {
  checkExtensionInstalled,
  sendExtensionMessage,
} from '../../shared/utils/extension.utils';
import {
  showBrowserNotFullySupportedToast,
  showChromeExtensionAvailableToast,
} from '../../shared/components/Toast';
import { isAppleTouchDevice, isSafari } from '../../shared/utils/user-agent';
import { getShortId } from '../../shared/utils/id';
import type {
  DesktopApiType,
  DesktopPermissionsApiType,
  OrganizedDesktopsType,
} from './data/Desktop/types/Desktop.types';

export const isShared = (desktop: DesktopApiType) =>
  desktop ? !desktop.shareable || desktop.memberCount > 1 : false;

export const getDesktopType = (
  desktop: Pick<DesktopApiType, 'shareable' | 'accountIds'>,
): DesktopType => {
  if (!desktop.shareable) {
    return DesktopType.INTERNAL;
  }
  if (desktop.accountIds.length > 1) {
    return DesktopType.SHARED;
  }
  return DesktopType.PRIVATE;
};

export const generateInsertedDesktopPositions = (
  desktops: DesktopApiType[],
  insertedDesktop: DesktopApiType,
  position: number,
) => {
  if (
    !desktops ||
    !insertedDesktop ||
    position === null ||
    position === undefined
  ) {
    return desktops.map((desktop, index) => ({
      id: desktop.id,
      position: index,
    }));
  }

  let updatedCurrentDesktops = desktops.map(desktop => {
    const { accountSortPosition } = desktop;

    const updatedPosition =
      accountSortPosition >= position
        ? accountSortPosition + 1
        : accountSortPosition;

    return {
      id: desktop.id,
      position: updatedPosition,
    };
  });

  const mergedDesktops = [
    ...updatedCurrentDesktops,
    {
      id: insertedDesktop.id,
      position: position,
    },
  ];

  return mergedDesktops.sort((a, b) => (a.position > b.position ? 1 : -1));
};

export const organizeDesktopsByPosition = (
  desktops: DesktopApiType[] | undefined,
): OrganizedDesktopsType => {
  const personalDesktops = !desktops
    ? []
    : desktops
        .filter(desktop => desktop.shareable)
        .sort((a, b) =>
          a.accountSortPosition > b.accountSortPosition ? 1 : -1,
        );

  const companyDesktops = !desktops
    ? []
    : desktops
        .filter(desktop => !desktop.shareable)
        .sort((a, b) =>
          a.accountSortPosition > b.accountSortPosition ? 1 : -1,
        );

  return {
    personalDesktops: personalDesktops,
    companyDesktops: companyDesktops,
  };
};

export const getPermissionsForDesktopId = (
  desktopsPermissions: DesktopPermissionsApiType[],
  desktopId: string,
): DesktopPermissionsApiType | undefined => {
  if (!desktopId || desktopsPermissions.length < 1) {
    return undefined;
  }

  return desktopsPermissions.find(
    permissions => permissions.desktop === desktopId,
  );
};

export const useCurrentTypeOfPage = (): {
  isNormalDesktop: boolean;
  isFavoritesDesktop: boolean;
  isCompanyDesktop: boolean;
  isChatsPage: boolean;
  isConferencesPage: boolean;
  isPasswordsPage: boolean;
  isAdminPage: boolean;
  isSearching: boolean;
  isSearchingDesktop: boolean;
  isSearchingAll: boolean;
} => {
  const { pathname } = useLocation();

  const queryParams = useQueryParams();
  const { desktopFilter } = queryParams;

  const isFavoritesPath =
    matchPath(FAVORITES_ROOT_PATHNAME, pathname) ||
    matchPath(FAVORITES_TAB_PATHNAME, pathname);

  const isChatsPath =
    matchPath(CHATS_ROOT_PATHNAME, pathname) ||
    matchPath(CHATS_FIND_ACCOUNT_CHAT_PATHNAME, pathname) ||
    matchPath(CHATS_CONVERSATION_PATHNAME, pathname);

  const isPasswordsPath =
    matchPath(PASSWORDS_ROOT_PATHNAME, pathname) ||
    matchPath(PASSWORDS_VAULT_PATHNAME, pathname);

  const isConferencesPage =
    matchPath(CONFERENCES_ROOT_PATHNAME, pathname) ||
    matchPath(CONFERENCES_CONFERENCE_PATHNAME, pathname);

  const isNormalDesktopPath =
    matchPath(DESKTOP_ID_PATHNAME, pathname) ||
    matchPath(DESKTOP_TAB_PATHNAME, pathname);

  const isCompanyDesktopPath = matchPath(COMPANY_DESKTOP_ID_PATHNAME, pathname);

  const isAdminPagePath = pathname.includes(ADMIN_PANEL_PATHNAME);

  return {
    isFavoritesDesktop: !!isFavoritesPath,
    isNormalDesktop: !!isNormalDesktopPath,
    isCompanyDesktop: !!isCompanyDesktopPath,
    isChatsPage: !!isChatsPath,
    isConferencesPage: !!isConferencesPage,
    isPasswordsPage: !!isPasswordsPath,
    isAdminPage: !!isAdminPagePath,
    isSearching: !!desktopFilter,
    isSearchingAll: !!desktopFilter && !isNormalDesktopPath,
    isSearchingDesktop: !!desktopFilter && !!isNormalDesktopPath,
  };
};

export const getDesktopIdFromUrl = (
  url: string,
): { id: string; _id: string } | null => {
  const DESKTOP_ID_REGEX: RegExp =
    /(\/desktop\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;

  const results = url.match(DESKTOP_ID_REGEX);

  if (results) {
    const guid = results[0].split('/desktop/')[1];
    return {
      id: `/desktops/${guid}`,
      _id: guid,
    };
  }

  return null;
};

export const getDesktopIri = (shortId: string) => {
  return '/desktops/' + shortId;
};

export const getFolderIri = (shortId: string) => {
  return '/folders/' + shortId;
};

/**
 * DesktopLayout utils
 */
export const getDesktopLayoutStorage = (desktopId?: string): DesktopLayout => {
  if (!desktopId) return DesktopLayout.CONSOLIDATED;

  const layout = localStorage.getItem(
    `${DESKTOP_LAYOUT_KEY}${getShortId(desktopId)}`,
  );
  return layout ? (layout as DesktopLayout) : DesktopLayout.CONSOLIDATED;
};

export const setDesktopLayoutStorage = (
  desktopId: string,
  layout: DesktopLayout,
) => {
  localStorage.setItem(`${DESKTOP_LAYOUT_KEY}${getShortId(desktopId)}`, layout);
};

/*
 * EXTENSION-RELATED
 * */

export const sendCredentialsToInsert = (
  url: string,
  login: string,
  password: string,
  loginUriPattern?: string,
) => {
  return checkExtensionInstalled()
    .then(installed => {
      if (installed) {
        sendExtensionMessage({
          insertUserCredentials: {
            url,
            login,
            password,
            loginUriPattern,
          },
        });
      } else {
        showChromeExtensionAvailableToast();
      }
    })
    .catch(() => {
      if ((isAppleTouchDevice() && !isSafari()) || !isAppleTouchDevice()) {
        showBrowserNotFullySupportedToast();
      }
    });
};
