import { DomainLocale, DomainTranslation } from './i18n.types';

export const enDomainLocale: DomainLocale = {
  [DomainTranslation.adminListHeaderTitle]: 'Domain',
  [DomainTranslation.adminListNewDomainButton]: 'Create domain',
  [DomainTranslation.adminListCreateDomainButton]: 'New Domain',
  [DomainTranslation.adminListDeleteDomainConfirmation]: 'Delete this domain?',
  [DomainTranslation.adminListEnableDomainConfirmation]: 'Enable this domain?',
  [DomainTranslation.adminListDisableDomainConfirmation]:
    'Disable this domain?',
  [DomainTranslation.adminListEmptyMessage]: 'No domains to display',
  [DomainTranslation.adminListEditModalHeader]: 'Edit domain',

  [DomainTranslation.adminListFormNamePlaceholder]: 'Domain name',
  [DomainTranslation.adminListFormNameLabel]: 'Domain',
  [DomainTranslation.adminListFormCheckboxLabel]: 'Enable domain',
  [DomainTranslation.adminListCancelButton]: 'Cancel',
  [DomainTranslation.adminListCreateSubmitButton]: 'Create',
  [DomainTranslation.adminListEditSubmitButton]: 'Update',
  [DomainTranslation.adminListFormDomainRequired]: 'Domain name is required',

  [DomainTranslation.adminListDomainSectionHeader]: 'Manage your domains',
  [DomainTranslation.adminListDomainSectionDescription]:
    'For added security you may add domain names used by your company for email. Admins will only be allowed to add internal members to the company that are verified email users of any of the domain names listed here. Guests invited to shared desktops may have any email domains and are not restricted by the domains listed here.',
  [DomainTranslation.adminListDomainSectionTitle]: 'Domain names',
  [DomainTranslation.adminListEnableButtonLabel]: 'Enable',
  [DomainTranslation.adminListDisableButtonLabel]: 'Disable',

  [DomainTranslation.adminListCreateDomainToastMessage]:
    'The domain was created',
  [DomainTranslation.adminListDeleteDomainToastMessage]:
    'The domain was deleted',
  [DomainTranslation.adminListEditDomainToastMessage]: 'The domain was edited',
  [DomainTranslation.adminListEnableDomainToastMessage]:
    'The domain was enabled',
  [DomainTranslation.adminListDisableDomainToastMessage]:
    'The domain was disabled',
};
