import { gql } from '@apollo/client';
import { ApiConnection } from '../../shared/api/api.types';
import {
  PasswordRecoveryRequest,
  VaultAccessApiType,
  VaultApiType,
} from './Encryption.types';
import {
  PasswordRecoveryRequestFragment,
  VaultAccessFragmentFields,
  VaultFragmentFields,
} from './Encryption.fragments';

export const GET_VAULTS = gql`
  query VaultsList($workspaceId: String!) {
    vaults(workspace: $workspaceId) @rest(path: "/vaults?{args}") {
      edges {
        node {
          ${VaultFragmentFields}
        }
      }
    }
  }
`;

export interface GetVaultsVariables {
  workspaceId: string;
  vaultItemConfigDesktopsAccountId?: string;
}

export interface GetVaultsResponse {
  vaults: ApiConnection<VaultApiType>;
}

export const GET_VAULT_ACCESSES_CHANGE_OWNER = gql`
  query VaultAccessesChangeOwner($workspace: ID!, $newOwner: String!) {
    vaultAccesses(workspace: $workspace, newOwner: $newOwner) @rest(path: "/vault-access/change-owner?{args}") {
      edges {
        node {
          ${VaultAccessFragmentFields}
          vault {
            id
          }
        }
      }
    }
  }
`;

export interface GetVaultAccessesChangeOwnerVariables {
  workspace: string;
  newOwner: string;
}

export interface GetVaultAccessesChangeOwnerResponse {
  vaultAccesses: ApiConnection<VaultAccessApiType>;
}

export const GET_VAULT_ACCESSES_RECOVERY_REQUEST = gql`
  query VaultAccessesRecoveryRequest($recoveryRequest: ID!) {
    vaultAccesses(recoveryRequest: $recoveryRequest) @rest(path: "/vault-access/recover-password?{args}") {
      edges {
        node {
          ${VaultAccessFragmentFields}
          vault {
            id
          }
        }
      }
    }
  }
`;

export interface GetVaultAccessesRecoveryRequestVariables {
  recoveryRequest: string;
}

export interface GetVaultAccessesRecoveryRequestResponse {
  vaultAccesses: ApiConnection<VaultAccessApiType>;
}

export const GET_PASSWORD_RECOVERY_REQUEST = gql`
  query GetPasswordRecoveryRequest($id: ID!) {
    passwordRecoveryRequest(id: $id) {
      ${PasswordRecoveryRequestFragment}
    }
  }
`;

export interface GetPasswordRecoveryRequestVariables {
  id: string;
}

export interface GetPasswordRecoveryRequestResponse {
  passwordRecoveryRequest: PasswordRecoveryRequest;
}
