import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const HeartIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="heart-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M5.25 2C3.41421 2 1.5 3.66421 1.5 6C1.5 8.7695 3.57359 11.3251 5.86118 13.2727C6.98201 14.2269 8.1066 14.9947 8.9527 15.5245C9.375 15.7889 9.726 15.9928 9.9699 16.1298C9.9801 16.1356 9.9901 16.1412 10 16.1467C10.0098 16.1412 10.0199 16.1356 10.0301 16.1298C10.274 15.9928 10.625 15.7889 11.0473 15.5245C11.8934 14.9947 13.018 14.2269 14.1388 13.2727C16.4264 11.3251 18.5 8.7695 18.5 6C18.5 3.66421 16.5858 2 14.75 2C12.8879 2 11.3816 3.22683 10.7115 5.23717C10.6094 5.54343 10.3228 5.75 10 5.75C9.6772 5.75 9.3906 5.54343 9.2885 5.23717C8.6184 3.22683 7.11212 2 5.25 2ZM10 17C9.6574 17.6672 9.6572 17.6671 9.6569 17.6669L9.6479 17.6623L9.6251 17.6504C9.6057 17.6402 9.5777 17.6254 9.5418 17.6062C9.4699 17.5676 9.3662 17.5112 9.2352 17.4376C8.9732 17.2904 8.6016 17.0744 8.1567 16.7958C7.26844 16.2397 6.08049 15.4294 4.88882 14.4148C2.5514 12.4249 0 9.4805 0 6C0 2.83579 2.58579 0.5 5.25 0.5C7.30732 0.5 8.9728 1.57857 10 3.23441C11.0272 1.57857 12.6927 0.5 14.75 0.5C17.4142 0.5 20 2.83579 20 6C20 9.4805 17.4486 12.4249 15.1112 14.4148C13.9195 15.4294 12.7316 16.2397 11.8433 16.7958C11.3984 17.0744 11.0268 17.2904 10.7648 17.4376C10.6338 17.5112 10.5301 17.5676 10.4582 17.6062C10.4223 17.6254 10.3943 17.6402 10.3749 17.6504L10.3521 17.6623L10.3431 17.6669C10.3428 17.6671 10.3426 17.6672 10 17ZM10 17L10.3426 17.6672C10.1276 17.7776 9.8724 17.7776 9.6574 17.6672L10 17Z"
      />
    </svg>
  );
});
