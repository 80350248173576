import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import {
  DeployEventStatus,
  StreamEventActionRequest,
  StreamEventActions,
} from '../../GeneralMercure.types';
import {
  addedToDesktop,
  addToConversation,
  removedFromDesktop,
  removedFromWorkspace,
  removeFromConversation,
} from './Requests';
import { addPersonalWorkspace } from './Requests/addPersonalWorkspace';
import { AccountApiType } from '../../../../User/User.types';
import { PaymentPlan } from '../../../../Billing/Billing.types';
import { closeMercureConnection } from './Requests/closeMercureConnection';
import { refreshAccountUnreads } from '../AccountRequest';
import {
  removeDesktopAppsSection,
  removeDesktopLinksSection,
} from '../DesktopSectionRequest';
import { GetWorkspacesByIdentityResponse } from '../../../../Workspace/Workspace.queries';
import type { DesktopApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';
import { UseAccountApiRepositoryProps } from '../../../../Account/data/CurrentAccount/types/CurrentAccountRepository.types';
import { updateServiceWorker } from '../../../../../shared/utils/pwa.utils';
import { getDesktopByIdFromIDDB } from '../../../../Desktop/data/Desktop/dataSources/DesktopIDDB.dataSource';
import { workspaceInfoRequest } from '../WorkspaceInfoRequest';
import { accountWorkspaceStatisticsUpdate } from './Requests/accountWorkspaceStatisticsUpdate';
import { workspaceStatisticsUpdate } from './Requests/workspaceStatisticsUpdate';
import { refreshAccountNotifications } from './Requests/refreshAccountNotifications';

export const actionRequest = async (
  event: StreamEventActions,
  apolloClient: ApolloClient<object>,
  refetchPermissions: () => void,
  account: AccountApiType,
  refetchWorkspaceDesktops: () => void,
  refetchWorkspaces: () => Promise<
    ApolloQueryResult<GetWorkspacesByIdentityResponse>
  >,
  refetchAccounts: () => Promise<void>,
  refetchCurrentAccount: () => Promise<AccountApiType | null | undefined>,
  setMaintenance: (_: boolean) => void,
  setScheduledDowntime: (_: string) => void,
  workspaceId: string,
  accountUpdater: (
    updater: (account: AccountApiType) => AccountApiType,
  ) => void,
  accountApiRepository: UseAccountApiRepositoryProps,
  updateDesktopInIDDB: (
    desktopId: string,
    variables: Partial<DesktopApiType>,
  ) => Promise<void>,
  addDesktopToIDDB: (
    workspaceId: string,
    desktop: DesktopApiType,
    accessible: boolean,
  ) => Promise<void>,
  removeDesktopByIdInIDDB: (id: string) => Promise<void>,
) => {
  switch (event['@request']) {
    case StreamEventActionRequest.ADDED_TO_DESKTOP:
      addedToDesktop(event, refetchPermissions, addDesktopToIDDB);
      break;
    case StreamEventActionRequest.DESKTOP_CHAT_ENABLED:
      const desktopData = event['@payload'];
      const cachedDesktop = await getDesktopByIdFromIDDB(desktopData.id);

      if (cachedDesktop) {
        updateDesktopInIDDB(cachedDesktop.id, {
          ...cachedDesktop,
          ...desktopData,
        });
      }

      break;
    case StreamEventActionRequest.REMOVED_FROM_DESKTOP:
      removedFromDesktop(event, refetchPermissions, removeDesktopByIdInIDDB);
      break;
    case StreamEventActionRequest.REFRESH_WORKSPACE_PERMISSION:
      refetchAccounts();
      refetchPermissions();
      refetchCurrentAccount();
      refetchWorkspaces();
      break;
    case StreamEventActionRequest.REFRESH_WORKSPACE_LIST:
      if (refetchWorkspaces) {
        refetchWorkspaces();
      }
      break;
    case StreamEventActionRequest.DEPLOY:
      if (
        event['@payload'] &&
        event['@payload'].status === DeployEventStatus.Started
      ) {
        // TODO: return to regular setMaintenance after domain change
        // setMaintenance(true);
        window.location.reload();
      } else if (
        event['@payload'] &&
        event['@payload'].status === DeployEventStatus.Finished
      ) {
        setMaintenance(false);
        updateServiceWorker();
      }
      break;
    case StreamEventActionRequest.WORKSPACE_CREATED:
      if (event['@payload'] && event['@payload'].type === PaymentPlan.FREE) {
        addPersonalWorkspace(
          account,
          event['@payload'],
          apolloClient,
          refetchPermissions,
        );
      }
      break;
    case StreamEventActionRequest.REMOVED_FROM_WORKSPACE:
      removedFromWorkspace(
        account,
        event['@payload'],
        apolloClient,
        refetchPermissions,
      );
      break;
    case StreamEventActionRequest.ACTIVATED_IN_WORKSPACE:
      if (refetchWorkspaces) {
        refetchWorkspaces();
      }
      break;
    case StreamEventActionRequest.SUSPENDED_IN_WORKSPACE:
      if (refetchWorkspaces) {
        refetchWorkspaces();
      }

      break;
    case StreamEventActionRequest.REMOVE_FROM_CHAT_CONVERSATION:
      removeFromConversation(event['@payload']);
      break;
    case StreamEventActionRequest.ADD_TO_CHAT_CONVERSATION:
      addToConversation(event['@payload'], workspaceId, account.id);
      break;
    case StreamEventActionRequest.REFRESH_WORKSPACE_DESKTOP_LIST:
      refetchWorkspaceDesktops();
      break;
    case StreamEventActionRequest.ACCOUNT_LOGOUT:
      closeMercureConnection(event);
      break;
    case StreamEventActionRequest.SCHEDULED_MAINTENANCE:
      if (event['@payload'] && event['@payload'].time) {
        setScheduledDowntime(event['@payload'].time);
      }
      break;
    case StreamEventActionRequest.SCHEDULED_MAINTENANCE_CANCEL:
      if (event['@payload'] && event['@payload'].status === 'CANCEL') {
        setScheduledDowntime('');
      }
      break;
    case StreamEventActionRequest.REFRESH_ACCOUNT_UNREADS:
      refreshAccountUnreads(
        event,
        accountUpdater,
        accountApiRepository,
        account,
      );
      break;
    case StreamEventActionRequest.APP_SECTION_DELETED:
      removeDesktopAppsSection(event);
      break;
    case StreamEventActionRequest.LINK_SECTION_DELETED:
      removeDesktopLinksSection(event);
      break;
    case StreamEventActionRequest.WORKSPACE_ACCOUNT_INFO_UPDATE:
      workspaceInfoRequest(event);
      break;
    case StreamEventActionRequest.ACCOUNT_WORKSPACE_STATISTICS_UPDATE:
      accountWorkspaceStatisticsUpdate(event);
      break;
    case StreamEventActionRequest.WORKSPACE_STATISTICS_UPDATE:
      workspaceStatisticsUpdate(event);
      break;
    case StreamEventActionRequest.REFRESH_ACCOUNT_NOTIFICATIONS:
      refreshAccountNotifications(event, apolloClient);
      break;
    default:
      break;
  }
};
