import { gql } from '@apollo/client';
import {
  ActivateWorkspaceInputType,
  CreateSubdomainInputValues,
  DowngradeWorkspaceInputType,
  EditWorkspaceInputType,
  UpgradeWorkspaceInputType,
  WorkspaceApiType,
  WorkspaceSortType,
} from './Workspace.types';

export const ACTIVATE_WORKSPACE = gql`
  mutation ActivateWorkspace($input: activateWorkspaceInput!) {
    activateWorkspace(input: $input) {
      workspace {
        id
        _id
        name
        uri
      }
    }
  }
`;

export interface ActivateWorkspaceVariables {
  input: ActivateWorkspaceInputType & { id: string };
}

export interface ActivateWorkspaceResponse {
  activateWorkspace: {
    workspace: WorkspaceApiType;
  };
}

export const UPGRADE_WORKSPACE = gql`
  mutation UpgradeWorkspace($input: upgradeWorkspaceInput!) {
    upgradeWorkspace(input: $input) {
      workspace {
        id
        _id
        name
      }
    }
  }
`;

export interface UpgradeWorkspaceVariables {
  input: UpgradeWorkspaceInputType;
}

export interface UpgradeWorkspaceResponse {
  upgradeWorkspace: {
    workspace: WorkspaceApiType;
  };
}

export const DOWNGRADE_WORKSPACE = gql`
  mutation DowngradeWorkspace($input: downgradeWorkspaceInput!) {
    downgradeWorkspace(input: $input) {
      clientMutationId
    }
  }
`;

export interface DowngradeWorkspaceVariables {
  input: DowngradeWorkspaceInputType;
}

export interface DowngradeWorkspaceResponse {
  downgradeWorkspace: {
    clientMutationId: string;
  };
}

export const CANCEL_UPGRADE_WORKSPACE = gql`
  mutation CancelUpgradeWorkspace($input: cancelUpgradeWorkspaceInput!) {
    cancelUpgradeWorkspace(input: $input) {
      clientMutationId
    }
  }
`;

export interface CancelUpgradeWorkspaceVariables {
  input: {
    id: string;
  };
}

export interface CancelUpgradeWorkspaceResponse {
  downgradeWorkspace: {
    clientMutationId: string;
  };
}

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace($input: updateWorkspaceInput!) {
    __typename
    updateWorkspace(input: $input) {
      workspace {
        id
        _id
        name
        description
        contactEmail
        homepageUrl
        mfaRequired
        uri
      }
    }
  }
`;

export interface UpdateWorkspaceVariables {
  input: EditWorkspaceInputType;
}

export interface UpdateWorkspaceResponse {
  updateWorkspace: {
    workspace: WorkspaceApiType;
  };
}

export const UPDATE_IDENTIFIER_WORKSPACE = gql`
  mutation UpdateIdentifierWorkspace($input: updateIdentifierWorkspaceInput!) {
    updateIdentifierWorkspace(input: $input) {
      clientMutationId
    }
  }
`;

export interface UpdateIdentifierWorkspaceVariables {
  input: CreateSubdomainInputValues;
}

export interface UpdateIdentifierWorkspaceResponse {
  updateIdentifierWorkspace: {
    clientMutationId: String;
  };
}

export const UPDATE_WORKSPACE_SORT = gql`
  mutation UpdateWorkspaceSort($input: updateWorkspaceSortInput!) {
    updateWorkspaceSort(input: $input) {
      clientMutationId
    }
  }
`;

export interface UpdateWorkspaceSortVariables {
  input: {
    workspaces: WorkspaceSortType[];
  };
}

export interface UpdateWorkspaceSortResponse {
  updateWorkspaceSort: {
    clientMutationId: string;
  };
}

export const CHANGE_FEATURE_FOR_WORKSPACE = gql`
  mutation changeFeatureForWorkspace($input: changeFeatureForWorkspaceInput!) {
    changeFeatureForWorkspace(input: $input) {
      workspace {
        id
        organizeAndSearch
        communicate
      }
    }
  }
`;

export interface ChangeFeatureForWorkspaceVariables {
  input: {
    id: string;
    organizeAndSearch: boolean;
    communicate: boolean;
  };
}

export interface changeFeatureForWorkspaceResponse {
  changeFeatureForWorkspace: {
    workspace: {
      id: string;
      organizeAndSearch: boolean;
      communicate: boolean;
    };
  };
}
