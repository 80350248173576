import {
  addHours,
  differenceInMilliseconds,
  formatISO,
  isAfter,
  parseISO,
  startOfTomorrow,
} from 'date-fns';
import { getPingTimestamp } from '../Mercure/Ping/PingMercure.utils';
import { AvailabilityStatusType } from '../User/User.types';
import {
  AWAY_THRESHOLD,
  BUSY_STATE_VALID_TO_TIMESTAMP_KEY,
  HOUR_OF_DAY_FOR_VALID_TIMESTAMP,
  INVISIBLE_STATE_VALID_TO_TIMESTAMP_KEY,
  OFFLINE_THRESHOLD,
} from './Account.constants';

export const getOnlineStatus = (
  onlineStatus?: AvailabilityStatusType,
  lastActionAt?: string,
) => {
  if (
    !lastActionAt ||
    onlineStatus === AvailabilityStatusType.Busy ||
    onlineStatus === AvailabilityStatusType.Invisible
  ) {
    return onlineStatus;
  }

  const lastActionDate = parseISO(lastActionAt);
  const now = parseISO(getPingTimestamp());
  const timeDifference = differenceInMilliseconds(now, lastActionDate);

  if (timeDifference > OFFLINE_THRESHOLD) {
    return AvailabilityStatusType.Offline;
  } else if (timeDifference > AWAY_THRESHOLD) {
    return AvailabilityStatusType.Away;
  }

  return onlineStatus;
};

export const setStatusValidTimestamp = (
  status: AvailabilityStatusType.Busy | AvailabilityStatusType.Invisible,
) => {
  const localStorageKey =
    status === AvailabilityStatusType.Busy
      ? BUSY_STATE_VALID_TO_TIMESTAMP_KEY
      : INVISIBLE_STATE_VALID_TO_TIMESTAMP_KEY;

  var validUntil = addHours(startOfTomorrow(), HOUR_OF_DAY_FOR_VALID_TIMESTAMP);

  localStorage.setItem(localStorageKey, formatISO(validUntil));
};

export const clearStatusValidTimestamps = () => {
  localStorage.removeItem(BUSY_STATE_VALID_TO_TIMESTAMP_KEY);
  localStorage.removeItem(INVISIBLE_STATE_VALID_TO_TIMESTAMP_KEY);
};

export const isStatusValidTimestampExpired = (
  status: AvailabilityStatusType.Busy | AvailabilityStatusType.Invisible,
) => {
  const localStorageKey =
    status === AvailabilityStatusType.Busy
      ? BUSY_STATE_VALID_TO_TIMESTAMP_KEY
      : INVISIBLE_STATE_VALID_TO_TIMESTAMP_KEY;

  const timestamp = localStorage.getItem(localStorageKey);
  if (!timestamp) return false;

  const now = parseISO(getPingTimestamp());

  return isAfter(now, parseISO(timestamp));
};
