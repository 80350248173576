import { ThemeType } from '../../../../domains/Theme';
import { CarouselThemesType } from './Carousele.theme.types';

export const CarouselTheme: CarouselThemesType = {
  [ThemeType.light]: {
    colors: {
      AssetsModalBackground: '#021121',
      AssetsModalHeaderTextColor: 'rgba(255, 255, 255, 0.6)',
    },
  },
  [ThemeType.dark]: {
    colors: {
      AssetsModalBackground: '#021121',
      AssetsModalHeaderTextColor: 'rgba(255, 255, 255, 0.6)',
    },
  },
};
