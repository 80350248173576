import { StreamDesktopAppEvent } from '../../../GeneralMercure.types';
import { database } from '../../../../../Database';

export const desktopAppRequestRemove = (event: StreamDesktopAppEvent) => {
  // TODO: It seems it is hard to get proper IRI-id's in subentities
  // so for now we have to manually correct some of these. Marius is looking for a backend fix
  event.id = event['@iri'];

  database.desktopApps.delete(event.id);
};
