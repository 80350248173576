import React, { FC, memo } from 'react';
import styled, { css } from 'styled-components';
import { Button, ButtonProps } from '../Button/Button';
import { IconProps } from '../../icons/icon.types';

export interface ButtonWithIconProps extends ButtonProps {
  icon: FC<IconProps> | null;
  iconFirst?: boolean;
  contentFill?: boolean;
  opacityDisabled?: boolean;
  isMobile?: boolean;
}

export const StyledButtonWithIcon = styled(Button)<
  Pick<ButtonWithIconProps, 'iconFirst' | 'contentFill' | 'opacityDisabled'>
>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  span {
    display: flex;
  }

  * {
    pointer-events: none;
  }

  span ~ svg {
    margin-left: 8px;
    flex: 0 0 16px;
  }

  ${({ iconFirst }) =>
    iconFirst &&
    css`
      span ~ svg {
        order: -1;
        margin-left: 0;
        margin-right: 8px;
      }
    `}

  ${({ contentFill }) =>
    contentFill &&
    css`
      width: 100%;
      height: 100%;
    `}

  ${({ opacityDisabled }) =>
    opacityDisabled &&
    css`
      &:disabled {
        background: transparent;
        color: inherit;
        opacity: 0.3;
      }
    `}
`;

export const ButtonWithIcon = memo(
  React.forwardRef<HTMLButtonElement, ButtonWithIconProps>(
    ({ icon: Icon, children, ...buttonProps }, ref) => {
      return (
        <StyledButtonWithIcon ref={ref} {...buttonProps}>
          {children && <span>{children}</span>}
          {Icon && <Icon />}
        </StyledButtonWithIcon>
      );
    },
  ),
);
