import {
  StreamDesktopAppEvent,
  StreamEventRequest,
} from '../../GeneralMercure.types';
import { desktopAppRequestNew, desktopAppRequestRemove } from './Requests';

export const desktopAppRequest = (event: StreamDesktopAppEvent) => {
  switch (event['@request']) {
    case StreamEventRequest.NEW:
      desktopAppRequestNew(event);
      break;
    case StreamEventRequest.REMOVE:
      desktopAppRequestRemove(event);
      break;
    default:
      break;
  }
};
