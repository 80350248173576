import { GlobalSearchLocale, GlobalSearchTranslation } from './i18n.types';

export const enGlobalSearchLocale: GlobalSearchLocale = {
  [GlobalSearchTranslation.cancelButtonText]: 'Cancel',
  [GlobalSearchTranslation.searchButton]: 'Search',

  [GlobalSearchTranslation.searchInputPlaceholder]: 'Search this workspace',
  [GlobalSearchTranslation.searchAppIntegrationsInputPlaceholder]:
    'Search in integrations',

  [GlobalSearchTranslation.expandedSearchAllContent]: 'All Content',
  [GlobalSearchTranslation.expandedSearchAppsTab]: 'Apps',
  [GlobalSearchTranslation.expandedSearchLinksTab]: 'Links',
  [GlobalSearchTranslation.expandedSearchChatsTab]: 'Messages & Chats',
  [GlobalSearchTranslation.expandedSearchMeetingsTab]: 'Meetings',

  [GlobalSearchTranslation.tooltipAppIntegrations]:
    'Search across integrated apps',
  [GlobalSearchTranslation.tooltipMeetingsTab]:
    'Show past or upcoming meetings',
  [GlobalSearchTranslation.tooltipAppIntegrationsGoBack]:
    'Search only within Webtop.com',
  [GlobalSearchTranslation.tooltipAllAppsTab]:
    'Show search results across apps',
  [GlobalSearchTranslation.tooltipManageButton]: 'Manage app integrations',

  [GlobalSearchTranslation.expandedSearchRecentSearches]: 'Recent searches',
  [GlobalSearchTranslation.expandedSearchRecentApps]: 'Recent apps',
  [GlobalSearchTranslation.expandedSearchFoundApps]: 'Found apps',
  [GlobalSearchTranslation.expandedSearchRecentLinks]: 'Recent links',
  [GlobalSearchTranslation.expandedSearchFoundLinks]: 'Found links',

  [GlobalSearchTranslation.expandedSearchAppType]: 'App',
  [GlobalSearchTranslation.expandedSearchLinkType]: 'Link',
  [GlobalSearchTranslation.expandedSearchFolderType]: 'Folder',
  [GlobalSearchTranslation.expandedSearchLinkCommentType]: 'Link comment',
  [GlobalSearchTranslation.expandedSearchCopyLink]: 'Copy link',
  [GlobalSearchTranslation.expandedSearchLinkDetails]: 'Link details',
  [GlobalSearchTranslation.expandedSearchOpenLinkLocation]:
    'Open link location',
  [GlobalSearchTranslation.expandedSearchOpenAppLocation]: 'Open app location',
  [GlobalSearchTranslation.expandedSearchLinkWasCopied]:
    'The link was copied to the clipboard',
  [GlobalSearchTranslation.expandedSearchChatType]: 'Chat',
  [GlobalSearchTranslation.expandedSearchConversationType]: 'Conversation',
  [GlobalSearchTranslation.expandedSearchDeletedAccount]: 'Deleted account',
  [GlobalSearchTranslation.expandedSearchMeetingType]: 'Meeting',

  [GlobalSearchTranslation.expandedSearchChatIn]: 'in',

  [GlobalSearchTranslation.closeSearchButton]: 'Close search',
  [GlobalSearchTranslation.openSearchButton]: 'Open search',

  [GlobalSearchTranslation.searchInAppIntegrationButton]:
    'Switch to integrated apps search',
  [GlobalSearchTranslation.searchInAppIntegrationEmptyButton]:
    'Expand your search by connecting your tools',
  [GlobalSearchTranslation.searchInDesktopButton]:
    'Go back to search in Webtop.com',
  [GlobalSearchTranslation.searchFilterAppsText]: 'Filter Integrations search',
  [GlobalSearchTranslation.searchFilterDeselectAll]: 'Deselect All',
  [GlobalSearchTranslation.searchFilterAllAppsTabName]: 'All Apps',
  [GlobalSearchTranslation.searchManageIntegrations]: 'Manage',
  [GlobalSearchTranslation.searchResultNoFound]: 'No results found',
  [GlobalSearchTranslation.appIntegrationsNoAccess]: 'This is a pro feature',
  [GlobalSearchTranslation.searchOnlyInSpecificChannel]:
    'Only search in <b>{channel}</b> channel',
};
