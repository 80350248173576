import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const LockIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="lock-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 7C8.5 6.54037 8.59053 6.08525 8.76642 5.66061C8.94231 5.23597 9.20012 4.85013 9.52513 4.52513C9.85013 4.20012 10.236 3.94231 10.6606 3.76642C11.0852 3.59053 11.5404 3.5 12 3.5C12.4596 3.5 12.9148 3.59053 13.3394 3.76642C13.764 3.94231 14.1499 4.20012 14.4749 4.52513C14.7999 4.85013 15.0577 5.23597 15.2336 5.66061C15.4095 6.08525 15.5 6.54037 15.5 7V10H8.5V7ZM7 10V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V10H18.5C18.8978 10 19.2794 10.158 19.5607 10.4393C19.842 10.7206 20 11.1022 20 11.5V19.5C20 19.8978 19.842 20.2794 19.5607 20.5607C19.2794 20.842 18.8978 21 18.5 21H5.5C5.10218 21 4.72064 20.842 4.43934 20.5607C4.15804 20.2794 4 19.8978 4 19.5V11.5C4 11.1022 4.15804 10.7206 4.43934 10.4393C4.72064 10.158 5.10218 10 5.5 10H7ZM5.5 19.5V11.5H18.5V19.5H5.5Z"
        fill="currentColor"
      />
    </svg>
  );
});
