import styled, { css } from 'styled-components';
import { Button } from '../../../shared/components/Button/Button';
import { SectionHeaderTitle } from './Section.styled';

export const DangerZone = styled.div`
  display: flex;
  align-items: center;
  padding: 1.125rem 1rem;

  &:not(:first-child) {
    border-top: 1px solid ${p => p.theme.colors.BorderLight};
  }
`;

export const DangerZoneHeaderTitle = styled(SectionHeaderTitle)`
  color: ${p => p.theme.colors.CriticalLight};
`;

export enum DangerZoneButtonMode {
  normal = 'normal',
  critical = 'critial',
}

export const DangerZoneButton = styled(Button)<{
  dangerMode?: DangerZoneButtonMode;
}>`
  white-space: nowrap;
  display: flex;
  align-items: center;

  ${({ dangerMode, theme }) =>
    (!dangerMode || dangerMode === DangerZoneButtonMode.normal) &&
    css`
      color: ${theme.colors.OnSurfaceHighEmphasis};
      border-color: ${theme.colors.BorderDefault};

      :not([disabled]):hover {
        background-color: ${p => p.theme.colors.OnSurfaceHover};
      }
    `}

  ${({ dangerMode, theme }) =>
    dangerMode === DangerZoneButtonMode.critical &&
    css`
      color: ${theme.colors.CriticalLight};

      :not([disabled]):hover {
        color: ${p => p.theme.colors.PrimaryTextLight};
        background-color: ${p => p.theme.colors.CriticalLight};
        border-color: ${theme.colors.CriticalLight};
      }
    `}
`;

export const DangerZoneWarning = styled.p`
  display: flex;
  align-items: center;
  margin-left: 26px;
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.875rem;
  line-height: 14px;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const DangerZoneConfirmationMessage = styled.p.attrs({
  'data-testid': 'confirmation-message',
})<{ highlight?: boolean }>`
  font-size: 0.875rem;
  margin-bottom: 0.375rem;
  line-height: 1.3;
  &:last-of-type {
    margin-bottom: 1.25rem;
  }

  ${({ highlight, theme }) =>
    highlight &&
    css`
      color: ${theme.colors.CriticalLight};
      font-family: ${p => p.theme.fonts.Semibold};
    `}
`;
