/* eslint-disable prettier/prettier */
import { NotificationLocale, NotificationTranslation } from './i18n.types';

export const enNotificationLocale: NotificationLocale = {
  [NotificationTranslation.notificationsHeader]: 'Notifications',
  [NotificationTranslation.unread]: 'Unread notification',
  [NotificationTranslation.markAllRead]: 'Mark all as read',
  [NotificationTranslation.markAllReadSuccessMessage]:
    'All notifications marked as read',
  [NotificationTranslation.settings]: 'Notifications settings',
  [NotificationTranslation.emptyMessage]: 'No notifications',
  [NotificationTranslation.fetchMoreNotifications]: 'Load more notifications',
  [NotificationTranslation.missingTitle]: '<title is missing>',
  [NotificationTranslation.linkPreviewModalTitle]: 'Link preview',
  [NotificationTranslation.linkPreviewModalDesktopLink]: 'Published in ',

  // Desktop events
  [NotificationTranslation.NotificationMessageDesktopLinkAdded]:
    'The link <strong>{linkName}</strong> was <span class="green">added</span> to the desktop <strong>{desktopName}</strong> by <span title="{fullName}">{firstName}</span>',
  [NotificationTranslation.NotificationMessageDesktopLinkToFileAdded]:
    '<span class="notification-link-name">The link <strong>{linkName}</strong></span> was <span class="green">added</span> to the desktop <strong>{desktopName}</strong> by <span title="{fullName}">{firstName}</span>',
  [NotificationTranslation.NotificationMessageDesktopLinkAbused]:
    '<span class="red"><strong>Warning!</strong></span> <span title="{fullName}">{firstName}</span> added the link <strong>{linkName}</strong> to the desktop <strong>{desktopName}</strong>{folderName}. This link was reported to be <strong>{abuseCategories}</strong>',
  [NotificationTranslation.NotificationMessageDesktopLinkRemoved]:
    'The link <strong>{linkName}</strong> was <span class="red">removed</span> from the desktop <strong>{desktopName}</strong> by <span title="{fullName}">{firstName}</span>',
  [NotificationTranslation.NotificationMessageDesktopAppAdded]:
    'The app <strong>{appName}</strong> was <span class="green">added</span> to the desktop <strong>{desktopName}</strong> by <span title="{fullName}">{firstName}</span>',
  [NotificationTranslation.NotificationMessageDesktopAppRemoved]:
    'The app <strong>{appName}</strong> was <span class="red">removed</span> from the desktop <strong>{desktopName}</strong> by <span title="{fullName}">{firstName}</span>',
  [NotificationTranslation.NotificationMessageDesktopUserAdded]:
    '<strong title="{fullName}">{firstName}</strong> was <span class="green">added</span> to the desktop <strong>{desktopName}</strong>',
  [NotificationTranslation.NotificationMessageDesktopChatEnabled]:
    '<strong title="{fullName}">{firstName}</strong> enabled chat for the desktop <strong>{desktopName}</strong>',

  // User events
  [NotificationTranslation.NotificationMessageUserRoleChange]:
    '<strong>You</strong> were <span class="green">assigned</span> as <strong>{groupLabel}</strong> of the workspace <strong>{workspaceName}</strong>',
  [NotificationTranslation.NotificationMessageUserAddedToDesktop]:
    '<strong>You</strong> were <span class="green">added</span> to the desktop <strong>{desktopName}</strong> by <span title="{fullName}">{firstName}</span>',
  [NotificationTranslation.NotificationMessageUserRemovedFromDesktop]:
    '<strong>You</strong> were <span class="red">removed</span> from the desktop <strong>{desktopName}</strong> by <span title="{fullName}">{firstName}</span>',
  [NotificationTranslation.NotificationMessageUserTeamAdded]:
    '<strong>You</strong> were <span class="green">added</span> to the group <strong>{teamName}</strong> by <span title="{fullName}">{firstName}</span>',
  [NotificationTranslation.NotificationMessageUserTeamRemoved]:
    '<strong>You</strong> were <span class="red">removed</span> from the group <strong>{teamName}</strong> by <span title="{fullName}">{firstName}</span>',
  [NotificationTranslation.NotificationMessageUserBookmarksImportFinished]: `{importedBookmarks} out of {totalBookmarks, plural, one {# bookmark was} other {# bookmarks were}} imported to <strong>{desktopName}</strong>`,

  // Payment events
  [NotificationTranslation.NotificationMessagePaymentNewInvoice]:
    'A <strong>new invoice</strong> is available',
  [NotificationTranslation.NotificationMessagePaymentFailed]:
    'There was a <strong>problem with the payment</strong>',
  [NotificationTranslation.NotificationMessagePaymentVatInvalid]:
    'The <strong>VAT number</strong> you used during registration is <span class="red">invalid</span>. <strong>Please update</strong> your VAT number in the <strong>billing page</strong>.',

  // Workspace events
  [NotificationTranslation.NotificationMessageWorkspaceCustomAppRemoved]:
    'The custom app <strong>{appName}</strong> was <span class="red">removed</span> from the workspace <strong>{workspaceName}</strong> by <span title="{fullName}">{firstName}</span>',

  // Password manager events
  [NotificationTranslation.NotificationMessagePasswordRecoveryRequestCreated]:
    'Credentials recovery request from <strong>{fullName}</strong>',
  [NotificationTranslation.NotificationMessagePasswordRecoveryRequestAccepted]:
    'Your credentials recovery request <strong>accepted</strong> by owner',
  [NotificationTranslation.NotificationMessagePasswordRecoveryRequestDeclined]:
    'Your credentials recovery request <strong>declined</strong> by owner',
  [NotificationTranslation.NotificationMessageVaultAccessSharedWithYou]:
    '<strong>{fullName}</strong> shared a password for the app <strong>{appName}</strong> with you.',
  [NotificationTranslation.NotificationMessageVaultAccessSharedRemovedFromYou]:
    '<strong>{fullName}</strong> removed an access to the password for the app <strong>{appName}</strong> for you.',

  // Conference events
  [NotificationTranslation.NotificationMessageScheduledConferenceInvitedMember]:
    '<strong>{fullName}</strong> invited you to <strong>{title}</strong>, starts at {startAt}',
  [NotificationTranslation.NotificationMessageScheduledConferenceBeforeStart]:
    '{minutes} {minutes, plural, one {minute} other {minutes}} until the event <strong>{title}</strong>',
  [NotificationTranslation.NotificationMessageScheduledConferenceUpdated]:
    '<strong>{title}</strong> was updated by <strong>{fullName}</strong>',
  [NotificationTranslation.NotificationMessageScheduledConferenceCanceled]:
    '<strong>{title}</strong> was canceled by <strong>{fullName}</strong>',
  [NotificationTranslation.NotificationMessageTeamDesktopAdded]:
    'The group <strong>{teamName}</strong> was added to the desktop <strong>{desktopName}</strong>',
  [NotificationTranslation.NotificationMessageTeamDesktopRemoved]:
    'The group <strong>{teamName}</strong> was removed from the desktop <strong>{desktopName}</strong>',

  // Tooltips
  [NotificationTranslation.tooltipNotificationOptionsButton]:
    'Notification options',
};
