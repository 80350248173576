import React from 'react';
import { StreamEvent } from './GeneralMercure.types';

export interface MercureContextType {
  addListener: (listener: (e: StreamEvent) => void) => void;
  removeListener: (listener: (e: StreamEvent) => void) => void;
}

export const MercureContext = React.createContext<MercureContextType>({
  addListener: () => {},
  removeListener: () => {},
});
