import { StreamAccountWorkspaceStatisticsUpdateEventAction } from '../../../GeneralMercure.types';
import { updateAccountInIDDB } from '../../../../../Account/data/Account/operations/AccountIDDB.operations';

export const accountWorkspaceStatisticsUpdate = (
  event: StreamAccountWorkspaceStatisticsUpdateEventAction,
) => {
  if (event?.['@payload']) {
    const { workspaceId, accountId, ...workspaceStatistics } =
      event?.['@payload'];
    updateAccountInIDDB(accountId, workspaceId, account => ({
      ...account,
      workspaceCache: {
        ...(account.workspaceCache || {}),
        [accountId]: workspaceStatistics,
      },
    }));
  }
};
