import React, { FC } from 'react';
import { ListItemContent } from '../../../../shared/components/List';
import { UserTranslation } from '../../../User/i18n';
import { AdminListItem } from '../../List.styled';
import {
  DesktopAccessType,
  DesktopApiType,
} from '../../../Desktop/data/Desktop/types/Desktop.types';
import { useIntl } from 'react-intl';
import { ListItemMenu } from '../../../Desktop/ShareDesktopPopover/MembersList/ListItemMenu';
import { AccessApiType } from '../../../Access/data/types/Access.types';

interface RelatedDesktopsListItemProps {
  desktop: DesktopApiType;
  desktopAccess: AccessApiType | undefined;
  disableAccessChange?: boolean;
  isOnlyFullAccessAllowed?: boolean;
  onChangeAccess?: (
    desktop: DesktopApiType,
    newAccessType: DesktopAccessType,
  ) => void;
  onRemove?: (desktop: DesktopApiType) => void;
}

export const RelatedDesktopsListItem: FC<RelatedDesktopsListItemProps> = ({
  desktop,
  desktopAccess,
  disableAccessChange,
  isOnlyFullAccessAllowed,
  onChangeAccess,
  onRemove,
}) => {
  const intl = useIntl();

  return (
    <AdminListItem key={desktop.id}>
      <ListItemContent
        title={desktop.name}
        subtitle={desktop.description || ''}
        subtitleSecondLine={
          disableAccessChange
            ? intl.formatMessage({
                id: UserTranslation.adminUserDesktopsPartOfTeam,
              })
            : ''
        }>
        {!!desktopAccess && (
          <ListItemMenu
            isDisabled={disableAccessChange}
            accessType={desktopAccess.accessType}
            isOnlyFullAccessAllowed={isOnlyFullAccessAllowed}
            onEdit={value => onChangeAccess?.(desktop, value)}
            onDelete={() => onRemove?.(desktop)}
          />
        )}
      </ListItemContent>
    </AdminListItem>
  );
};
