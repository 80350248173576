import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DocumentPdfIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      fill="none"
      height="46"
      viewBox="0 0 46 46"
      width="46"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="document-pdf-icon"
      {...props}>
      <clipPath>
        <path d="m0 0h23v21.083h-23z" transform="translate(11.5 15.333)" />
      </clipPath>
      <path
        clipRule="evenodd"
        d="m11.02 6.708a.48.48 0 0 0 -.479.48v31.625c0 .264.215.479.48.479h23.958a.48.48 0 0 0 .48-.48v-21.562h-7.189a3.354 3.354 0 0 1 -3.355-3.354v-7.188h-13.894zm16.772 1.074v6.114c0 .264.214.479.479.479h6.114zm-20.127-.594a3.355 3.355 0 0 1 3.355-3.355h15.498c.89 0 1.742.353 2.37.984l8.46 8.46c.632.629.984 1.481.984 2.37v23.166a3.357 3.357 0 0 1 -3.354 3.354h-23.957a3.354 3.354 0 0 1 -3.355-3.355v-31.623z"
        fill="#bf0711"
        fillRule="evenodd"
      />
      <g>
        <path
          d="m32.541 26.86c-1.096-1.078-4.222-.782-5.785-.584-1.546-.944-2.579-2.246-3.307-4.16.35-1.447.908-3.648.485-5.031-.377-2.354-3.396-2.12-3.827-.53-.395 1.446-.036 3.459.629 6.028-.899 2.147-2.237 5.031-3.18 6.684-1.797.926-4.223 2.354-4.583 4.151-.296 1.42 2.336 4.96 6.838-2.803 2.012-.665 4.204-1.482 6.145-1.806 1.698.917 3.684 1.528 5.013 1.528 2.291 0 2.516-2.534 1.572-3.477zm-17.798 6.99c.459-1.231 2.202-2.65 2.732-3.145-1.707 2.722-2.732 3.207-2.732 3.145zm7.332-17.125c.665 0 .602 2.884.161 3.666-.395-1.249-.386-3.666-.161-3.666zm-2.193 12.273c.872-1.518 1.618-3.324 2.22-4.915.745 1.357 1.698 2.444 2.704 3.19-1.869.386-3.495 1.177-4.924 1.725zm11.824-.45s-.45.54-3.351-.7c3.153-.234 3.674.485 3.35.7z"
          fill="#bf0711"
        />
      </g>
    </svg>
  );
});
