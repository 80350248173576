import React, { Dispatch, SetStateAction } from 'react';
import {
  VaultApiType,
  WorkspaceAppDesktopVaultsMap,
  WorkspaceAppGroupVaultsMap,
  WorkspaceAppVaultsMap,
  WorkspaceVaultsMap,
} from './Encryption.types';
import { ApolloQueryResult } from '@apollo/client';
import { GetVaultsResponse } from './Encryption.queries';

export interface EncryptionContextType {
  accountPublicKeysMap: Record<string, CryptoKey>;
  accountPrivateKeysMap: Record<string, CryptoKey>;
  workspaceVaultsMap: WorkspaceVaultsMap;
  workspaceAppVaultsMap: WorkspaceAppVaultsMap;
  workspaceAppGroupVaultsMap: WorkspaceAppGroupVaultsMap;
  workspaceAppDesktopVaultsMap: WorkspaceAppDesktopVaultsMap;
  workspaceVaultLoginsMap: Record<string, Record<string, string>>;
  workspaceVaultsLoading: boolean;
  decryptCurrentAccountPrivateKey: (passphrase: string) => Promise<CryptoKey>;
  showMasterPasswordLockToast: () => void;
  refetchWorkspaceVaults: () => Promise<ApolloQueryResult<GetVaultsResponse>>;
  setWorkspaceVaultsCache: Dispatch<
    SetStateAction<Record<string, VaultApiType[]>>
  >;
}

export const EncryptionContext = React.createContext<EncryptionContextType>({
  accountPublicKeysMap: {},
  accountPrivateKeysMap: {},
  workspaceVaultsMap: {},
  workspaceAppVaultsMap: {},
  workspaceAppGroupVaultsMap: {},
  workspaceAppDesktopVaultsMap: {},
  workspaceVaultLoginsMap: {},
  workspaceVaultsLoading: false,
  decryptCurrentAccountPrivateKey: (_: string) =>
    Promise.resolve({}) as Promise<CryptoKey>,
  showMasterPasswordLockToast: () => {},
  refetchWorkspaceVaults: () =>
    Promise.resolve({} as ApolloQueryResult<GetVaultsResponse>),
  setWorkspaceVaultsCache: () => {},
});
