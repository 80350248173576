import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const GridIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1.5 1.25H2.5C2.63807 1.25 2.75 1.36193 2.75 1.5V2.5C2.75 2.63807 2.63807 2.75 2.5 2.75H1.5C1.36193 2.75 1.25 2.63807 1.25 2.5V1.5C1.25 1.36193 1.36193 1.25 1.5 1.25Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 5.5H1.5C0.947715 5.5 0.5 5.94772 0.5 6.5V7.5C0.5 8.05228 0.947715 8.5 1.5 8.5H2.5C3.05228 8.5 3.5 8.05228 3.5 7.5V6.5C3.5 5.94772 3.05228 5.5 2.5 5.5Z"
        fill="currentColor"
      />
      <path
        d="M2.5 10.5H1.5C0.947715 10.5 0.5 10.9477 0.5 11.5V12.5C0.5 13.0523 0.947715 13.5 1.5 13.5H2.5C3.05228 13.5 3.5 13.0523 3.5 12.5V11.5C3.5 10.9477 3.05228 10.5 2.5 10.5Z"
        fill="currentColor"
      />
      <path
        d="M11.5 1.25H12.5C12.6381 1.25 12.75 1.36193 12.75 1.5V2.5C12.75 2.63807 12.6381 2.75 12.5 2.75H11.5C11.3619 2.75 11.25 2.63807 11.25 2.5V1.5C11.25 1.36193 11.3619 1.25 11.5 1.25Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 5.5H11.5C10.9477 5.5 10.5 5.94772 10.5 6.5V7.5C10.5 8.05228 10.9477 8.5 11.5 8.5H12.5C13.0523 8.5 13.5 8.05228 13.5 7.5V6.5C13.5 5.94772 13.0523 5.5 12.5 5.5Z"
        fill="currentColor"
      />
      <path
        d="M12.5 10.5H11.5C10.9477 10.5 10.5 10.9477 10.5 11.5V12.5C10.5 13.0523 10.9477 13.5 11.5 13.5H12.5C13.0523 13.5 13.5 13.0523 13.5 12.5V11.5C13.5 10.9477 13.0523 10.5 12.5 10.5Z"
        fill="currentColor"
      />
      <path
        d="M7.5 0.5H6.5C5.94772 0.5 5.5 0.947715 5.5 1.5V2.5C5.5 3.05228 5.94772 3.5 6.5 3.5H7.5C8.05228 3.5 8.5 3.05228 8.5 2.5V1.5C8.5 0.947715 8.05228 0.5 7.5 0.5Z"
        fill="currentColor"
      />
      <path
        d="M7.5 5.5H6.5C5.94772 5.5 5.5 5.94772 5.5 6.5V7.5C5.5 8.05228 5.94772 8.5 6.5 8.5H7.5C8.05228 8.5 8.5 8.05228 8.5 7.5V6.5C8.5 5.94772 8.05228 5.5 7.5 5.5Z"
        fill="currentColor"
      />
      <path
        d="M7.5 10.5H6.5C5.94772 10.5 5.5 10.9477 5.5 11.5V12.5C5.5 13.0523 5.94772 13.5 6.5 13.5H7.5C8.05228 13.5 8.5 13.0523 8.5 12.5V11.5C8.5 10.9477 8.05228 10.5 7.5 10.5Z"
        fill="currentColor"
      />
    </svg>
  );
});
