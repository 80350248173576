import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const MaximiseIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M14.9999 1.7495C14.9998 1.55074 14.9208 1.36017 14.7803 1.21963C14.6397 1.07909 14.4492 1.00009 14.2504 0.999962L9.9471 0.999961C9.75289 1.00682 9.56892 1.08879 9.43396 1.2286C9.29899 1.36842 9.22356 1.55516 9.22356 1.74949C9.22356 1.94383 9.29899 2.13057 9.43396 2.27039C9.56893 2.4102 9.75289 2.49217 9.9471 2.49903L12.4402 2.49903L8.70967 6.22957C8.56901 6.37022 8.49 6.56099 8.49 6.7599C8.49 6.95881 8.56901 7.14958 8.70967 7.29023C8.85032 7.43088 9.04108 7.5099 9.24 7.5099C9.43891 7.5099 9.62967 7.43089 9.77033 7.29023L13.5009 3.55969L13.5009 6.0528C13.5077 6.24701 13.5897 6.43097 13.7295 6.56594C13.8693 6.70091 14.0561 6.77634 14.2504 6.77634C14.4447 6.77634 14.6315 6.70091 14.7713 6.56594C14.9111 6.43097 14.9931 6.24701 14.9999 6.0528L14.9999 1.7495Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M1.74949 15.0099C1.55074 15.0098 1.36017 14.9308 1.21963 14.7903C1.07909 14.6497 1.00009 14.4592 0.999961 14.2604V9.95711C1.00682 9.7629 1.08879 9.57893 1.2286 9.44397C1.36842 9.309 1.55516 9.23357 1.7495 9.23357C1.94383 9.23357 2.13057 9.309 2.27039 9.44397C2.4102 9.57894 2.49217 9.7629 2.49903 9.95711V12.4502L6.22957 8.71968C6.37022 8.57902 6.56099 8.50001 6.7599 8.50001C6.95882 8.50001 7.14958 8.57902 7.29023 8.71968C7.43088 8.86033 7.5099 9.05109 7.5099 9.25001C7.5099 9.44892 7.43088 9.63968 7.29023 9.78034L3.55969 13.5109L6.0528 13.5109C6.24701 13.5177 6.43097 13.5997 6.56594 13.7395C6.70091 13.8793 6.77634 14.0661 6.77634 14.2604C6.77634 14.4547 6.70091 14.6415 6.56594 14.7813C6.43097 14.9211 6.24701 15.0031 6.0528 15.0099L1.74949 15.0099Z"
      />
    </svg>
  );
});
