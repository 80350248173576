import { RefObject } from 'react';

export enum VerticalAlignment {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
  INSIDE_TOP = 'inside_top',
}

export enum HorizontalAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  OUTSIDE_RIGHT = 'outside_right',
}

export interface MenuProps {
  trigger: RefObject<any>;
  wrapperWithCursor?: boolean;
  width?: number | 'auto' | null;
  maxHeight?: number | null;
  alignTop?: boolean;
  processContextMenuEvent?: boolean;
  firstLevelHidden?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  vAlign?: VerticalAlignment;
  hAlign?: HorizontalAlignment;
  scrollProbability?: string;
  portalContainerId?: string;
  viaPortal?: boolean;
  submenuRef?: RefObject<any>;
}

export interface MenuControlsProps {
  forceClose: () => void;
}

export interface Event {
  preventDefault: Function;
  clientX: number;
  clientY: number;
}

export interface MenuControls {
  closeMenu: () => void;
}
