export enum GeneralSettingsTranslation {
  iconLabel = 'generalSettings_iconLabel',
  modalTitle = 'generalSettings_modalTitle',

  behaviorSectionHeader = 'generalSettings_behaviorSectionHeader',
  themeSectionHeader = 'generalSettings_themeSectionHeader',

  mfaSectionHeader = 'generalSettings_mfaSectionHeader',
  mfaInputTitle = 'generalSettings_mfaInputTitle',
  mfaActivateConfirmationTitle = 'generalSettings_mfaActivateConfirmationTitle',
  mfaActivateConfirmationMessage = 'generalSettings_mfaActivateConfirmationMessage',

  selectAppLabel = 'generalSettings_selectAppLabel',
  selectLinkLabel = 'generalSettings_selectLinkLabel',

  optionCurrentTab = 'generalSettings_optionCurrentTab',
  optionNewTab = 'generalSettings_optionNewTab',
  optionSeparatedWindow = 'generalSettings_optionSeparatedWindow',

  optionThemeSystemPreference = 'generalSettings_optionThemeSystemPreference',
  optionThemeTitle = 'generalSettings_optionThemeTitle',
  optionThemeLight = 'generalSettings_optionThemeLight',
  optionThemeDark = 'generalSettings_optionThemeDark',

  startupLabel = 'generalSettings_startupLabel',
  checkboxLabel = 'generalSettings_checkboxLabel',

  startPageLabel = 'generalSettings_startPageLabel',
  browserExtensionLabel = 'generalSettings_browserExtensionLabel',
  startPageButton = 'generalSettings_startPageButton',
  browserExtensionButton = 'generalSettingsB_browserExtensionButton',

  settingsMenuAccount = 'generalSettings_settingsMenuAccount',
  settingsMenuAppStore = 'generalSettings_settingsMenuAppStore',
  settingsMenuCompanyProfile = 'generalSettings_settingsMenuCompanyProfile',
  settingsMenuCompanyUsers = 'generalSettings_settingsMenuCompanyUsers',
  settingsMenuCompanyTeams = 'generalSettings_settingsMenuCompanyTeams',
  settingsMenuCompanyDesktops = 'generalSettings_settingsMenuCompanyDesktops',
  settingsMenuCompanyBilling = 'generalSettings_settingsMenuCompanyBilling',
  settingsMenuCompanyLogOut = 'generalSettings_settingsMenuCompanyLogOut',

  settingsDrawerMenuHeader = 'generalSettings_settingsDrawerMenuHeader',
  settingsDrawerAccountHeaders = 'generalSettings_settingsDrawerAccountHeaders',
  settingsDrawerCloseButton = 'generalSettings_settingsDrawerCloseButton',
  settingsDrawerBackButton = 'generalSettings_settingsDrawerBackButton',

  tooltipGeneralSettingsButton = 'generalSettings_tooltipGeneralSettingsButton',

  notificationsSectionHeader = 'generalSettings_notificationsSectionHeader',
  serviceUpdatesInputTitle = 'generalSettings_serviceUpdatesInputTitle',
  serviceUpdatesInputDescription = 'generalSettings_serviceUpdatesInputDescription',
  productInformationInputTitle = 'generalSettings_productInformationInputTitle',
  productInformationInputDescription = 'generalSettings_productInformationInputDescription',
  transactionalEmailsInputTitle = 'generalSettings_transactionalEmailsInputTitle',
  transactionalEmailsInputDescription = 'generalSettings_transactionalEmailsInputDescription',
  transactionalEmailsInputTooltip = 'generalSettings_transactionalEmailsInputTooltip',

  // Global notifications
  notificationsModalTitle = 'generalSettings_notificationsModalTitle',
  notificationsNotActiveOnComputer = 'generalSettings_notificationsNotActiveOnComputer',
  notificationsToMyMobileDevices = 'generalSettings_notificationsToMyMobileDevices',
  notificationsMessages = 'generalSettings_notificationsMessages',
  notificationsAboutAllMessages = 'generalSettings_notificationsAboutAllMessages',
  notificationsAboutDirectMentionsReply = 'generalSettings_notificationsAboutDirectMentionsReply',
  notificationsDisableMessagesNotifications = 'generalSettings_notificationsDisableMessagesNotifications',
  notificationsGeneralBell = 'generalSettings_generalBell',
  notificationsBilling = 'generalSettings_billing',
  notificationsUpcomingMeetings = 'generalSettings_upcomingMeetings',
  notificationsNotifyMeAbout = 'generalSettings_notificationsNotifyMeAbout',
  notificationsOptionInactive = 'generalSettings_notificationsOptionInactive',
  notificationsOptionAlways = 'generalSettings_notificationsOptionAlways',
  notificationsOptionNever = 'generalSettings_notificationsOptionNever',
  notificationsSounds = 'generalSettings_notificationsSounds',
  notificationsMuteAllSounds = 'generalSettings_notificationsMuteAllSounds',
}

export type GeneralSettingsLocale = {
  [key in GeneralSettingsTranslation]: string;
};
