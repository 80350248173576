export const FORM_FIELD_SERVICE_UPDATES_TYPE = 'serviceUpdatesEmailsEnabled';
export const FORM_FIELD_PRODUCT_INFORMATION_TYPE =
  'productInformationEmailsEnabled';
export const FORM_FIELD_TRANSACTIONAL_EMAILS_TYPE = 'transactionalEmail';

export const MOBILE_NOTIFICATIONS_FIELD_DROPDOWN_TYPE = 'mobileNotifications';

export const FORM_FIELD_NOTIFICATIONS_MUTE_SOUNDS = 'soundsEnable';

export const NOTIFICATIONS_GENERAL_BELL = 'generalBellNotificationsEnable';
export const NOTIFICATIONS_BILLING = 'billingNotificationsEnable';
export const NOTIFICATIONS_UPCOMMINT_MEETING =
  'upcomingMeetingNotificationsEnable';
export const NOTIFICATION_SOUNDS = 'soundsEnable';
export const MESSAGES_NOTIFICATIONS = 'messagesNotifications';
