export enum OfflineTranslation {
  // Page
  offlinePageTitle = 'offline_offlinePageTitle',
  offlinePageContent = 'offline_offlinePageContent',

  // Modal
  offlineModalTitle = 'offline_offlineModalTitle',
  offlineModalContent = 'offline_offlineModalContent',
}

export type OfflineLocale = {
  [key in OfflineTranslation]: string;
};
