import { DefaultTheme } from 'styled-components';
import { BasicTheme } from './Basic.theme';
import { BasicColors, OnNavbar, ThemeType } from '../';
import { SharedTheme } from '../../../shared/Theme';
import { OnboardingTheme } from '../../Onboarding/Theme';
import { AccountTheme } from '../../Account/theme';
import { UserTheme } from '../../User/Theme';
import { BillingTheme } from '../../Billing/Theme';
import { ChatTheme } from '../../Chat/Theme';
import { WorkspaceTheme } from '../../Workspace/Theme';
import { ConferenceTheme } from '../../Conference/Theme';
import { LinkTheme } from '../../Link/Theme';
import { EditorTheme } from '../../../shared/components/Editor/Theme';
import { CarouselTheme } from './../../../shared/components/Carousel/Theme/Carousel.theme';
import { ChatMessageSearchInputTheme } from '../../Chat/ChatMessageSearch/theme/ChatMessageSearchInput.theme';
import { ConferencePreviewTheme } from '../../Conference/CreateConferencePage/ConferencePreview/Theme';
import { SegmentSwitcherTheme } from '../../Segment/SegmentSwitcher/Theme';

export const LightTheme: DefaultTheme = {
  ...BasicTheme,
  colors: {
    ...SharedTheme[ThemeType.light].colors,
    ...OnboardingTheme[ThemeType.light].colors,
    ...AccountTheme[ThemeType.light].colors,
    ...UserTheme[ThemeType.light].colors,
    ...BillingTheme[ThemeType.light].colors,
    ...ChatTheme[ThemeType.light].colors,
    ...WorkspaceTheme[ThemeType.light].colors,
    ...ConferenceTheme[ThemeType.light].colors,
    ...LinkTheme[ThemeType.light].colors,
    ...EditorTheme[ThemeType.light].colors,
    ...CarouselTheme[ThemeType.light].colors,
    ...ChatMessageSearchInputTheme[ThemeType.light].colors,
    ...ConferencePreviewTheme[ThemeType.light].colors,
    ...SegmentSwitcherTheme[ThemeType.light].colors,

    Background: '#F4F6F8',
    Surface: '#FFF',
    OnSurface: BasicColors[ThemeType.light].OnSurface.hex(),
    Navbar: '#454F5B',
    OnNavbar: OnNavbar.hex(),
    IdentityBar: '#162433',
    PwaThemeColor: '#FFF',
    White: '#FFF',

    //TODO: organize OnSurface variables with opacity for semantic use
    OnSurfaceHighEmphasis: BasicColors[ThemeType.light].OnSurface.alpha(0.87)
      .rgb()
      .string(),
    OnSurfaceMediumEmphasis: BasicColors[ThemeType.light].OnSurface.alpha(0.6)
      .rgb()
      .string(),
    OnSurfaceLightEmphasis: BasicColors[ThemeType.light].OnSurface.alpha(0.38)
      .rgb()
      .string(),

    OnSurfacePressed: BasicColors[ThemeType.light].OnSurface.alpha(0.12)
      .rgb()
      .string(),
    OnSurfaceSelected: BasicColors[ThemeType.light].OnSurface.alpha(0.08)
      .rgb()
      .string(),
    OnSurfaceHover: BasicColors[ThemeType.light].OnSurface.alpha(0.04)
      .rgb()
      .string(),

    OnNavbarMediumEmphasis: OnNavbar.alpha(0.6).rgb().string(),
    OnNavbarSelected: OnNavbar.alpha(0.12).rgb().string(),
    OnNavbarHover: OnNavbar.alpha(0.04).rgb().string(),

    SurfaceBorder: BasicColors[ThemeType.light].OnSurface.alpha(0.12)
      .rgb()
      .string(),
    NavbarBorder: OnNavbar.alpha(0.12).rgb().string(),
    SurfaceSubdued: BasicColors[ThemeType.light].OnSurface.alpha(0.08)
      .rgb()
      .string(),
    OnNavbarSubdued: OnNavbar.alpha(0.2).rgb().string(),

    OnNavbarDivider: BasicColors[ThemeType.dark].OnSurface.alpha(0.12)
      .rgb()
      .string(),
    Divider: BasicColors[ThemeType.light].OnSurface.alpha(0.12).rgb().string(),

    // Primary
    PrimaryLightest: '#DFF0F9',
    PrimaryLight: '#68B7FF',
    Primary: '#0889FA',
    PrimaryDark: '#007BE6',
    PrimaryDarker: '#026CC8',
    PrimaryDarkest: '#0B3B65',

    // Success
    Success: '#50B83C',
    SuccessDark: '#108043',
    SuccessLighter: '#E3F1DF',

    // Critical
    CriticalLight: '#DE3618',
    CriticalDark: '#BF0711',
    CriticalLighter: '#FBEAE5',

    // Warning
    Warning: '#EEC200',
    WarningLight: '#FCF1CD',
    WarningDark: '#8A6116',

    //Pending
    Pending: '#F49342',

    //Backgrounds
    BackgroundLight: '#DFE3E8',
    BackgroundInputAutofill: '#DFF0F9',

    //Borders
    BorderDefault: BasicColors[ThemeType.light].OnSurface.alpha(0.12)
      .rgb()
      .string(),
    BorderLight: BasicColors[ThemeType.light].OnSurface.alpha(0.12)
      .rgb()
      .string(),
    ModalHeaderBorder: BasicColors[ThemeType.light].OnSurface.alpha(0.08)
      .rgb()
      .string(),

    //Disabled elements
    DisabledElement: '#C4CDD5',

    //Elements shadow
    BoxShadowDefault: '#DFE3E8',

    //Text
    PrimaryTextLight: '#FFF',

    //Icons
    IconLight: '#FFF',

    //Favorite icon
    FavoriteIcon: '#C4CDD5',
    FavoriteIconHover: '#454F5B',

    //Links and buttons
    PrimaryActiveButtonLinkBackground: '#212B36',
    DarkActiveButtonLinkBackground: '#212B36',
    NavigatorLinkDraggingShadow: '#212B36',
    NavigatorLinkDraggingBackground: '#637381',
    WorkspaceLinkHover: '#919EAB',

    //Skeletons
    WorkspaceSwitcherSkeleton: '#DFE3E8',
    DesktopSwitcherSkeleton: '#DFE3E8',
    AppTopBarSkeleton: '#454F5B',
    LinkViewSkeleton: '#DFE3E8',
    ChatViewSkeleton: '#DFE3E8',
    DesktopViewSkeleton: '#F4F6F8',
    AppsViewSkeleton: '#DFE3E8',
    LinksViewSkeleton: '#DFE3E8',
    DesktopViewHeaderSkeleton: '#FFF',
    TextHighlighterBackgroundColor: '#cee8ff',
  },
};
