import {
  existsInNestedArray,
  mergeWithArray,
} from '../../../shared/utils/list.utils';
import { ApolloClient, gql } from '@apollo/client';
import {
  FILTER_LINKS,
  FilterLinksResponse,
  FilterLinksVariables,
  GET_LINKS,
  GetLinksResponse,
  GetLinksVariables,
} from '../Link.queries';
import { LinkApiType } from '../Link.types';
import {
  GRAPHQL_TYPENAME_LINK,
  GRAPHQL_TYPENAME_LINK_CONNECTION,
  GRAPHQL_TYPENAME_LINK_EDGE,
} from '../Link.constants';
import { extractNodes } from '../../../shared/api/api.utils';
import { FolderApiType } from '../../Folder/Folder.types';

const getLinksCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetLinksVariables,
) =>
  apolloClient.readQuery<GetLinksResponse, GetLinksVariables>({
    query: GET_LINKS,
    variables: queryVariables,
  });

export const addLinkToLinksCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetLinksVariables,
  link: LinkApiType,
) => {
  try {
    const linksCache = getLinksCache(apolloClient, queryVariables);

    if (!linksCache) {
      return;
    }

    const alreadyExist = existsInNestedArray(linksCache, 'links.edges', {
      node: { id: link.id },
    });
    if (alreadyExist) {
      return;
    }

    apolloClient.writeQuery({
      query: GET_LINKS,
      variables: queryVariables,
      data: mergeWithArray(linksCache, {
        links: {
          edges: [
            {
              __typename: GRAPHQL_TYPENAME_LINK_EDGE,
              node: link,
            },
          ],
          __typename: GRAPHQL_TYPENAME_LINK_CONNECTION,
        },
      }),
    });
  } catch (e) {
    /* There is no cache */
  }
};

export const removeLinkFromLinksCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetLinksVariables,
  linkId: string,
) => {
  try {
    const linksCache = getLinksCache(apolloClient, queryVariables);

    if (!linksCache) {
      return;
    }

    const updatedLinks = extractNodes(linksCache.links).filter(node => {
      return node.id !== linkId;
    });

    const updatedCache = {
      ...linksCache,
      links: {
        ...linksCache.links,
        totalCount: updatedLinks.length,
        edges: updatedLinks.map(node => ({
          node: node,
          __typename: GRAPHQL_TYPENAME_LINK_EDGE,
        })),
        __typename: GRAPHQL_TYPENAME_LINK_CONNECTION,
      },
    };

    apolloClient.writeQuery({
      query: GET_LINKS,
      variables: queryVariables,
      data: updatedCache,
    });
  } catch (e) {
    /* There is no cache */
  }
};

export const updateLinkInLinksCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetLinksVariables,
  link: LinkApiType,
) => {
  try {
    const linksCache = getLinksCache(apolloClient, queryVariables);

    if (!linksCache) {
      return;
    }

    const updatedLinks = extractNodes(linksCache.links).map(node => {
      if (node.id === link.id) {
        return link;
      }
      return node;
    });

    const updatedCache = {
      ...linksCache,
      links: {
        ...linksCache.links,
        edges: updatedLinks.map(node => ({
          node: node,
          __typename: GRAPHQL_TYPENAME_LINK_EDGE,
        })),
        __typename: GRAPHQL_TYPENAME_LINK_CONNECTION,
      },
    };

    apolloClient.writeQuery({
      query: GET_LINKS,
      variables: queryVariables,
      data: updatedCache,
    });
  } catch (e) {
    /* There is no cache */
  }
};

export const removeLinkFromFilteredLinksCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: FilterLinksVariables,
  linkId: string,
) => {
  try {
    const linksCache = apolloClient.readQuery<
      FilterLinksResponse,
      FilterLinksVariables
    >({
      query: FILTER_LINKS,
      variables: queryVariables,
    });

    if (!linksCache) {
      return;
    }

    apolloClient.writeQuery<FilterLinksResponse, FilterLinksVariables>({
      query: FILTER_LINKS,
      variables: queryVariables,
      data: {
        ...linksCache,
        links: {
          ...linksCache.links,
          edges: linksCache.links.edges.filter(
            ({ node }) => node.id !== linkId,
          ),
          __typename: GRAPHQL_TYPENAME_LINK_CONNECTION,
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};

// TODO @bqk -> remove this if favorites works as expected
// export const removeFavoriteLinkFromFavoritesLinksCache = (
//   apolloClient: ApolloClient<any>,
//   queryVariables: GetFavoritesVariables,
//   linkId: string,
// ) => {
//   try {
//     const favoritesCache = apolloClient.readQuery<
//       GetFavoritesResponse,
//       GetFavoritesVariables
//     >({
//       query: GET_FAVORITES,
//       variables: queryVariables,
//     });
//     if (!favoritesCache) {
//       return;
//     }

//     const data: GetFavoritesResponse = {
//       ...favoritesCache,
//       favorites: {
//         ...favoritesCache.favorites,
//         edges: favoritesCache.favorites.edges.filter(
//           ({ node }) => !node.link || node.link?.id !== linkId,
//         ),
//       },
//     };

//     apolloClient.writeQuery({
//       query: GET_FAVORITES,
//       variables: queryVariables,
//       data: data,
//     });
//   } catch (e) {
//     /* There is no cache */
//   }
// };

export const updateLinkFolderInLinksCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetLinksVariables,
  link: LinkApiType,
  newFolder: FolderApiType | null,
  oldFolderId: string | undefined,
) => {
  try {
    const linksCache = getLinksCache(apolloClient, queryVariables);

    if (!linksCache) {
      return;
    }

    const updatedLinks = [
      ...extractNodes(linksCache.links),
      {
        ...link,
        folder: newFolder,
      },
    ];

    const updatedCache = {
      ...linksCache,
      links: {
        ...linksCache.links,
        edges: updatedLinks.map(node => ({
          node: node,
          __typename: GRAPHQL_TYPENAME_LINK_EDGE,
        })),
        __typename: GRAPHQL_TYPENAME_LINK_CONNECTION,
      },
    };

    apolloClient.writeQuery({
      query: GET_LINKS,
      variables: queryVariables,
      data: updatedCache,
    });

    // Remove link from current folder
    removeLinkFromLinksCache(
      apolloClient,
      {
        ...queryVariables,
        hasFolder: !!oldFolderId,
        folderId: oldFolderId,
      },
      link.id,
    );
  } catch (e) {
    /* There is no cache */
  }
};

const LinkFragment = gql`
  fragment LinkFragment on Link {
    chatConversationIri
  }
`;

type LinkFragmentType = Pick<
  LinkApiType,
  'chatConversationIri'
  // add more if needed
>;

export const updateLinkInCache = (
  proxy: {
    readFragment: ApolloClient<any>['readFragment'];
    writeFragment: ApolloClient<any>['writeFragment'];
  },
  linkId: string,
  updater: (link: LinkFragmentType) => Partial<LinkFragmentType>,
) => {
  try {
    const cacheId = `${GRAPHQL_TYPENAME_LINK}:${linkId}`;

    const fragmentCache = proxy.readFragment<LinkFragmentType, undefined>({
      id: cacheId,
      fragment: LinkFragment,
    });
    if (!fragmentCache) {
      return;
    }

    proxy.writeFragment<LinkFragmentType, undefined>({
      id: cacheId,
      fragment: LinkFragment,
      data: {
        ...fragmentCache,
        ...updater(fragmentCache),
      },
    });
  } catch (e) {}
};
