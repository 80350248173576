import { ChatLocale, ChatTranslation } from './i18n.types';

export const enChatLocale: ChatLocale = {
  // various labels
  [ChatTranslation.labelsYou]: 'You',
  [ChatTranslation.labelsSystem]: 'Webtop.com',
  [ChatTranslation.labelDeletedAccount]: 'Deleted user',

  // switcher
  [ChatTranslation.switcherHeader]: 'Chats',

  // conversations list
  [ChatTranslation.switcherListItemPinButton]: 'Pin conversation',
  [ChatTranslation.switcherListItemMarkAllRead]: 'Mark all as read',
  [ChatTranslation.switcherListItemOptionsTooltip]: 'Chat options',
  [ChatTranslation.hideConversation]: 'Hide conversation',

  // message form
  [ChatTranslation.newMessageFormInputPlaceholder]: 'Message {name}',
  [ChatTranslation.newMessageFormSendButton]: 'Send message',
  [ChatTranslation.newMessageFormPickEmojiButton]: 'Pick emoji',
  [ChatTranslation.newMessageFormInsertMentionButton]: 'Insert mention',
  [ChatTranslation.newMessageFormInputEditPlaceholder]: 'Edit message',
  [ChatTranslation.newMessageFormInputReplyPlaceholder]: 'Reply',
  [ChatTranslation.newMessageFormInputLinkChatPlaceholder]: 'Add comment',
  [ChatTranslation.newMessageFormCancelButton]: 'Cancel',
  [ChatTranslation.newMessageFormSaveButton]: 'Save',
  [ChatTranslation.newMessageFormFileUploadAccountLimitReached]:
    'File upload quota has been reached for your account. Would you like to DELETE files to upload this one? The oldest files in any of your chats will be deleted.',

  // chat avatar
  [ChatTranslation.chatAvatarAlt]: '{accountName} avatar',

  // account popover
  [ChatTranslation.accountPopoverMessageButton]: 'Message',
  [ChatTranslation.accountPopoverCallButton]: 'Call',
  [ChatTranslation.accountPopoverVideoCallButton]: 'Video call',
  [ChatTranslation.accountPopoverEmailLabel]: 'E-mail',
  [ChatTranslation.accountPopoverPhoneLabel]: 'Phone number',
  [ChatTranslation.accountPopoverAddPhoneNumberText]: 'Add your phone number',
  [ChatTranslation.accountPopoverLocalTimeLabel]: 'Local time',
  [ChatTranslation.accountPopoverCompanyNameLabel]: 'Company',
  [ChatTranslation.accountPopoverAddCompanyText]: 'Add your company name',
  [ChatTranslation.accountPopoverInvitedByText]:
    'Invited by <span class="emphasize">{firstName}<span>',
  [ChatTranslation.accountPopoverContactDisabledFeature]:
    'The communication feature can be<br>activated in workspace settings',

  // sidebar
  [ChatTranslation.sidebarTitle]: 'People',
  [ChatTranslation.sidebarChatParticipants]: 'In this chat',
  [ChatTranslation.sidebarMeetingParticipants]: 'In this meeting',
  [ChatTranslation.sidebarDesktopAccounts]: 'In this desktop',
  [ChatTranslation.sidebarWorkspaceMembers]: 'Workspace members',
  [ChatTranslation.sidebarWorkspaceGuests]: 'Workspace guests',
  [ChatTranslation.sidebarGuest]: 'Guest',
  [ChatTranslation.closeMembersButton]: 'Close members',
  [ChatTranslation.openMembersButton]: 'Open members',

  //create chat
  [ChatTranslation.createConversationModalHeader]: 'New chat',
  [ChatTranslation.createConversationErrorMessage]:
    'Select field must have at least 1 item',
  [ChatTranslation.createConversationButton]: 'Create',
  [ChatTranslation.createConversationTitleFieldPlaceholder]: 'Title (optional)',
  [ChatTranslation.cancelCreateConversationButton]: 'Cancel',
  [ChatTranslation.createConversationImportantMessage]:
    '<strong>Invite any members, guests or external parties to a chat</strong><br/>An external person can create a Webtop.com account on the fly, or even join with their Slack account<strong>{proFeatureLabel}</strong> – <a href="{learnMoreUrl}" rel="noopener" target="_blank">learn more</a>',
  [ChatTranslation.createConversationImportantMessageProFeatureLabel]:
    '(Pro feature)',
  [ChatTranslation.recentContactsTitle]: 'Recent contacts',
  [ChatTranslation.conversationSelectPlaceholder]:
    'Enter user or email address',
  [ChatTranslation.conversationSelectEmptyText]:
    'Enter email to send an invitation',
  [ChatTranslation.conversationSelectInviteText]: 'Invite "{email}"',
  [ChatTranslation.conversationUsersMenuRemoveFromChat]: 'Remove',
  [ChatTranslation.conversationUsersMenuRemoveConfirmation]:
    'Are you sure that you want to remove this user from conversation?',
  [ChatTranslation.conversationUsersMenuRevokeInvitation]: 'Revoke invitation',
  [ChatTranslation.conversationUsersMenuCopyInviteLink]: 'Copy invite link',
  [ChatTranslation.conversationUsersMenuCopyInviteLinkSuccessMessage]:
    'Invite link was copied',
  [ChatTranslation.conversationUsersPendingLabel]: 'Pending',
  [ChatTranslation.conversationUsersCloseButton]: 'Close',

  // chat messages
  [ChatTranslation.chatConferenceMessageCallStarted]: 'Call started',
  [ChatTranslation.chatConferenceMessageCallEnded]: 'Call ended',
  [ChatTranslation.chatConferenceMessageJoin]: 'Join',
  [ChatTranslation.chatConferenceMessageParticipantsCounter]:
    '{participantsCount} {participantsCount, plural, one {person} other {people}} joined',
  [ChatTranslation.chatConferenceMessageNoOneInTheCall]: 'No one in the call',

  [ChatTranslation.recentContactsWarning]: 'There are no users available',
  [ChatTranslation.searchLinksResultNoFound]: 'No results found',

  [ChatTranslation.conferenceParticipantsTitle]: 'Participants',

  [ChatTranslation.noMembersMessage]: 'No members',
  [ChatTranslation.filterMembersPlaceholder]: 'Filter all members',
  [ChatTranslation.filterMembersAddUserTooltip]: 'Add user',

  [ChatTranslation.messageMenuItemEdit]: 'Edit message',
  [ChatTranslation.messageMenuItemDelete]: 'Delete message',
  [ChatTranslation.messageMenuItemCopyLink]: 'Copy link',

  [ChatTranslation.linksMenuRemovePreview]: 'Remove preview',

  [ChatTranslation.messageAssetMenuItemDownload]: 'Download',
  [ChatTranslation.messageAssetMenuItemDownloadOriginal]: 'Download original',
  [ChatTranslation.messageAssetMenuItemDownloadCompressed]:
    'Download compressed',
  [ChatTranslation.messageAssetMenuItemDelete]: 'Delete file',

  [ChatTranslation.messageDeleteConfirmation]:
    'Are you sure that you want to delete this message?',
  [ChatTranslation.messageAssetDeleteConfirmation]:
    'Are you sure that you want to delete this file?',

  [ChatTranslation.noMessagesInChat]:
    'There are no messages in this conversation yet.',

  [ChatTranslation.messageModifiedLabel]: '(Edited)',

  [ChatTranslation.chatDateDelimiterToday]: 'Today',

  [ChatTranslation.chatEmptyState]: 'Your chats will appear here.',

  // group chat menu
  [ChatTranslation.groupChatMenuManageUsers]: 'Manage users',
  [ChatTranslation.groupChatMenuRenameChat]: 'Rename chat',
  [ChatTranslation.chatFormTitleMaximumSymbols]:
    'Chat name can contain 100 characters maximum.',
  [ChatTranslation.groupChatMenuLeaveChat]: 'Leave chat',
  [ChatTranslation.groupChatMenuDeleteChat]: 'Delete chat',

  // rename chat
  [ChatTranslation.renameChatModalTitle]: 'Rename chat',
  [ChatTranslation.chatFormTitleFieldLabel]: 'Chat name',
  [ChatTranslation.chatFormTitleFieldPlaceholder]: ' ',
  [ChatTranslation.chatFormSubmitButtonLabel]: 'Save',

  // manage users
  [ChatTranslation.manageUsersModalTitle]: 'Manage users',
  [ChatTranslation.manageUsersSubmitButtonLabel]: 'Save',

  // leave chat
  [ChatTranslation.leaveChatModalTitle]: 'Leave {chatTitle}?',
  [ChatTranslation.leaveChatModalSubmitButtonLabel]: 'Leave chat',
  [ChatTranslation.leaveChatModalCancelButtonLabel]: 'Cancel',
  [ChatTranslation.leaveChatModalCaseWithoutChatName]: 'this chat',

  // delete chat
  [ChatTranslation.removeChatModalTitle]: 'Remove {chatTitle}?',
  [ChatTranslation.removeChatModalSubmitButtonLabel]: 'Remove chat',
  [ChatTranslation.removeChatModalCancelButtonLabel]: 'Cancel',
  [ChatTranslation.removeChatModalCaseWithoutChatName]: 'this chat',

  // manage user
  [ChatTranslation.manageUserButtonLabel]: 'Manage',

  // attachment
  [ChatTranslation.attachmentRemoveButtonLabel]: 'Remove attachment',
  [ChatTranslation.attachmentImagePreviewAlt]: 'Image preview',
  [ChatTranslation.attachmentUploadButtonLabel]: 'Upload attachment',

  // reactions
  [ChatTranslation.reactionsCurrentUserOnly]: 'You (click to remove)',
  [ChatTranslation.reactionsOtherUsers]:
    '<div class="reaction-tooltip"><div class="reacted-emoji">{emoji}</div>{userNames}{userNamesSuffix} <span class="reacted-with">reacted with: {emojiName}</span></div>',
  [ChatTranslation.reactionsCurrentUserSuffix]: ' and you',

  // threads
  [ChatTranslation.threadModalHeader]: 'Thread',
  [ChatTranslation.expandThreadButton]:
    'Show {count} {count, plural, =1 {reply} other {replies}}',
  [ChatTranslation.expandMoreThreadButton]:
    'Show {count} more {count, plural, =1 {reply} other {replies}}',
  [ChatTranslation.collapseThreadButton]: 'Hide replies',
  [ChatTranslation.replyThreadButton]: 'Reply',
  [ChatTranslation.threadCount]:
    '{count} {count, plural, =1 {reply} other {replies}}',
  [ChatTranslation.lastReplyAt]: 'Last reply {at}',
  [ChatTranslation.viewThread]: 'View thread',

  // people panel
  [ChatTranslation.peoplePanelInACallBadge]: 'In a call',

  // tooltips
  [ChatTranslation.tooltipMentionIcon]: 'Mention someone',
  [ChatTranslation.tooltipEmojiIcon]: 'Emoji',
  [ChatTranslation.tooltipAttachFileIcon]: 'Attach file',
  [ChatTranslation.tooltipSendMessageIcon]: 'Send message',
  [ChatTranslation.tooltipDisabledInputMessageForSuspendedUser]:
    'No ability to send a message to suspended user',
  [ChatTranslation.tooltipGoToLastMessage]: 'Go to last message',
  [ChatTranslation.tooltipChatAudioCallButton]: 'Call all members of this chat',
  [ChatTranslation.tooltipChatVideoCallButton]:
    'Video call all members of this chat',
  [ChatTranslation.tooltipReplyInTreadButton]: 'Reply in thread',
  [ChatTranslation.tooltipMoreActionsButton]: 'More actions',
  [ChatTranslation.tooltipAddReactionButton]: 'Add reaction',
  [ChatTranslation.tooltipCreateChatButton]: 'Create a new chat',
  [ChatTranslation.tooltipPinChatButton]: 'Pin this chat',
  [ChatTranslation.tooltipUnpinChatButton]: 'Unpin this chat',
  [ChatTranslation.tooltipMemberDetails]: 'Click to see details',
  [ChatTranslation.tooltipGoToDesktopButton]: 'Go to desktop',

  // last chat message attachment
  [ChatTranslation.chatAttachment]: 'Attachment',
  [ChatTranslation.chatAttachments]: 'Attachments',

  // call type
  [ChatTranslation.chatCallTypeVideo]: 'Video Call',
  [ChatTranslation.chatCallTypeAudio]: 'Audio Call',

  // message asset
  [ChatTranslation.chatMessageAssetAltText]: 'Attached image',

  [ChatTranslation.conversationListPinned]: 'pinned',
  [ChatTranslation.conversationListIntegrations]: 'integrations',
  [ChatTranslation.conversationListChannels]: 'Channels',
  [ChatTranslation.conversationListDirectMessages]: 'direct messages',
  [ChatTranslation.conversationFilterPlaceholder]: 'Filter all chats',

  [ChatTranslation.appIntegrationTooltip]: 'Manage integrations',
  [ChatTranslation.appIntegrationNoAccessTooltip]:
    'App integrations is a Pro feature',

  [ChatTranslation.usersSelectGroupTypeMembers]: 'Members',
  [ChatTranslation.usersSelectGroupTypeGuests]: 'Guests',

  // chat message thread sidebar
  [ChatTranslation.chatMessageSidebarTitle]: 'Thread',
  [ChatTranslation.chatMessageSidebarLabelClose]: 'Close thread sidebar',
};
