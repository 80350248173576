import styled, { css } from 'styled-components';
import { APPTOPBAR_MOBILE_HEIGHT } from '../../AppTopBar/AppTopBar.styled';
import { MEMBERS_SIDEBAR_WIDTH } from '../../Chat/MembersSidebar/MembersSidebar.styled';
import { SEGMENT_NAVIGATOR_WIDTH } from '../SegmentNavigator/SegmentNavigator.styled';
import { SEGMENT_SWITCHER_WIDTH } from '../SegmentSwitcher/SegmentSwitcher.styled';

export const getSegmentNavigatorWidth = (withTwoSegments: boolean) => {
  return withTwoSegments
    ? SEGMENT_NAVIGATOR_WIDTH * 2
    : SEGMENT_NAVIGATOR_WIDTH;
};

export const StyledSegmentContent = styled.div<{
  isMobile: boolean;
  withSidebar: boolean;
  withSwitcher: boolean;
  withTwoSegments: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.Surface};

  ${({ withSidebar, theme: { breakpoints } }) =>
    breakpoints.xlg &&
    `
    margin-right: ${withSidebar ? `${MEMBERS_SIDEBAR_WIDTH}px` : 'unset'};
  `}

  ${({ isMobile, withSidebar, withSwitcher, withTwoSegments }) =>
    !isMobile &&
    css`
      max-width: ${withSidebar && withSwitcher
        ? `calc(100% - ${
            getSegmentNavigatorWidth(withTwoSegments) +
            SEGMENT_SWITCHER_WIDTH +
            MEMBERS_SIDEBAR_WIDTH
          }px)`
        : withSidebar && !withSwitcher
        ? `calc(100% - ${
            getSegmentNavigatorWidth(withTwoSegments) + MEMBERS_SIDEBAR_WIDTH
          }px)`
        : !withSidebar && withSwitcher
        ? `calc(100% - ${
            getSegmentNavigatorWidth(withTwoSegments) + SEGMENT_SWITCHER_WIDTH
          }px)`
        : `calc(100% - ${getSegmentNavigatorWidth(withTwoSegments)}px)`};
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: ${APPTOPBAR_MOBILE_HEIGHT}px;
      transform: translateX(0px);
      transition: 0.2s transform ease-out;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1),
        0 4px 10px 0 rgba(0, 0, 0, 0.15);

      &.menu-open {
        transform: translateX(
          ${SEGMENT_NAVIGATOR_WIDTH + SEGMENT_SWITCHER_WIDTH}px
        );
      }
    `}
`;

export const MobileMenuCloseOverlay = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
`;
