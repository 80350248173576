import { database } from '../../../../Database';
import { TeamApiTypeToTeamTableType } from '../utils/TeamIDDB.utils';
import type { TeamApiType } from '../types/Team.types';
import { TeamsTableType } from '../../../../Database/WorkspaceTeams/WorkspaceTeamsTable';
import { getWorkspaceTeamFromIDDB } from '../dataSources/TeamIDDB.dataSoureces';

export const putTeamToIDDB = async (team: TeamApiType, workspaceId: string) => {
  try {
    const cachedTeam = await getWorkspaceTeamFromIDDB(team.id);
    const newTeamRow = TeamApiTypeToTeamTableType(team, workspaceId);
    if (cachedTeam) {
      newTeamRow.relatedAccounts = cachedTeam.relatedAccounts;
      newTeamRow.relatedDesktops = cachedTeam.relatedDesktops;
    }
    return database.teams.put(newTeamRow);
  } catch (err) {
    console.error(err);
  }
};

export const updateTeamInIDDB = async (
  teamId: string,
  updater: (team: TeamApiType) => TeamApiType,
) => {
  const cachedTeam = await database.teams.where({ id: teamId });

  if (!cachedTeam) {
    return;
  }

  return cachedTeam.modify((value, ref) => {
    ref.value = {
      ...value,
      sourceData: updater(value.sourceData),
    };
  });
};

export const deleteTeamInIDDB = (teamId: string): Promise<void> => {
  return database.teams.delete(teamId);
};

export const modifyTeamInIDDB = async (
  workspaceTeamId: string,
  teamName: string,
  teamDescription: string,
) => {
  try {
    const cachedTeam = await database.teams.where({ id: workspaceTeamId });

    if (!cachedTeam) {
      return;
    }

    cachedTeam.modify((value, ref) => {
      ref.value = {
        ...value,
        sourceData: {
          ...value.sourceData,
          name: teamName,
          description: teamDescription,
        },
      };
    });
  } catch (error) {
    console.error(error);
  }
};

export const bulkPutTeamsToIDDB = (
  teamRows: TeamsTableType[],
  workspaceId: string,
) => {
  return database.teams.bulkPut(teamRows);
};

export const deleteTeamIDDB = async (workspaceTeamId: string) => {
  try {
    const cachedTeam = await database.teams.get(workspaceTeamId);

    if (!cachedTeam) {
      return;
    }

    database.teams.delete(cachedTeam.id);
  } catch (error) {
    console.error(error);
  }
};

export const bulkDeleteTeamsFromIDDB = (teamIds: string[]) => {
  try {
    return database.teams.bulkDelete(teamIds);
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};
