import { database } from '../../../../Database';
import {
  addEntityIdsToIDDBRelatedEntitiesList,
  removeEntityIdsFromIDDBRelatedEntitiesList,
} from '../../../../../shared/utils/iddb.utils';

export const addRelatedDesktopsToTeamsIDDB = async (
  teamIds: string[] = [],
  desktopIds: string[] = [],
) => {
  try {
    return database.teams
      .where('id')
      .anyOf(teamIds)
      .modify((value, ref) => {
        ref.value = {
          ...value,
          relatedDesktops: addEntityIdsToIDDBRelatedEntitiesList(
            value.relatedDesktops,
            desktopIds,
          ),
        };
      });
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};

export const removeRelatedDesktopsFromTeamsIDDB = async (
  teamIds: string[] = [],
  desktopIds: string[] = [],
) => {
  try {
    return database.teams
      .where('id')
      .anyOf(teamIds)
      .modify((value, ref) => {
        ref.value = {
          ...value,
          relatedDesktops: removeEntityIdsFromIDDBRelatedEntitiesList(
            value.relatedDesktops,
            desktopIds,
          ),
        };
      });
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};
