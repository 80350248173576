export enum ChromeExtensionTranslation {
  import = 'chromeExtension_import',
  importApps = 'chromeExtension_importApps',
  importantMessage = 'chromeExtension_importantMessage',
  importInfo = 'chromeExtension_importInfo',
  importFromChrome = 'chromeExtension_importFromChrome',
  importInfoInstallExtensionLink = 'chromeExtension_importInfoInstallExtensionLink',

  importLocation = 'chromeExtension_importLocation',
  importDesktopDescription = 'chromeExtension_importDesktopDescription',

  installExtensionBannerText = 'chromeExtension_installExtensionBannerText',
  installExtensionBannerLink = 'chromeExtension_installExtensionBannerLink',

  importAppsSpinnerMessage = 'chromeExtension_importAppsSpinnerMessage',
  importAppsTitle = 'chromeExtension_importAppsTitle',
  importAppsDescription = 'chromeExtension_importAppsDescription',
  importSelectAllButton = 'chromeExtension_importSelectAllButton',
  importClearAllButton = 'chromeExtension_importClearAllButton',
  importSkipToLinksButton = 'chromeExtension_importSkipToLinksButton',
  importSelectedAppsButton = 'chromeExtension_importSelectedAppsButton',

  importAppsAdded = 'chromeExtension_importAppsAdded',
  importContinueWithLinks = 'chromeExtension_importContinueWithLinks',
  importGoToDesktop = 'chromeExtension_importGoToDesktop',
  importGoToAccount = 'chromeExtension_importGoToAccount',

  importLinksSpinnerMessage = 'chromeExtension_importLinksSpinnerMessage',
  importLinksTitle = 'chromeExtension_importLinksTitle',
  importLinksImportantMessage = 'chromeExtension_importLinksImportantMessage',
  importSkipButton = 'chromeExtension_importSkipButton',
  importSelectedLinksButton = 'chromeExtension_importSelectedLinksButton',
  importLinksAddedSubtitle = 'chromeExtension_importLinksAddedSubtitle',
  importLinksAddedDescription = 'chromeExtension_importLinksAddedDescription',

  installPluginTitle = 'chromeExtension_installPluginTitle',
  installPluginFeaturesTitle = 'chromeExtension_installPluginFeaturesTitle',
  installPluginFeatures = 'chromeExtension_installPluginFeatures',
  installPluginPasswordManagerFeature = 'chromeExtension_installPluginPasswordManagerFeature',
  installPluginImportFeature = 'chromeExtension_installPluginImportFeature',
  installPluginQuickAddFeature = 'chromeExtension_installPluginQuickAddFeature',
  installPluginDescription = 'chromeExtension_installPluginDescription',
  installPluginLearnMore = 'chromeExtension_installPluginLearnMore',
  installPluginSkipButton = 'chromeExtension_installPluginSkipButton',
  installPluginNextButton = 'chromeExtension_installPluginNextButton',
  installPluginSuccessMessage = 'chromeExtension_installPluginSuccessMessage',
  installPluginButton = 'chromeExtension_installPluginButton',

  installPluginToastTitle = 'chromeExtension_installPluginToastTitle',
  installPluginToastDescription = 'chromeExtension_installPluginToastDescription',
  installPluginToastLinkLabel = 'chromeExtension_installPluginToastLinkLabel',
}

export type ChromeExtensionLocale = {
  [key in ChromeExtensionTranslation]: string;
};
