import React from 'react';
import type { AvailabilityContextType } from './AccountsProvider.types';

export const AccountsProviderContext =
  React.createContext<AvailabilityContextType>({
    accountsWithAvailability: {},
    refetchAccounts: () => Promise.resolve(),
    loading: false,
    updateAccount: () => Promise.resolve(),
  });
