import styled from 'styled-components';
import { Button } from '../../../shared/components/Button/Button';

export const GeneralInfoSection = styled.header`
  background-color: ${p => p.theme.colors.Surface};
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  border-radius: 8px;
`;

export const GeneralInfoContent = styled.div`
  display: grid;
  grid-template-areas: 'gi-image gi-meta';
  grid-template-columns: auto 1fr;
`;

export const GeneralInfoImage = styled.div`
  grid-area: gi-image;
  margin-right: 1.5rem;
`;

export const GeneralInfoMeta = styled.div`
  grid-area: gi-meta;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const GeneralInfoTitle = styled.h4`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 1.125rem;
  line-height: 27px;
  word-break: break-all;
  word-break: break-word;
  display: flex;
  align-items: center;
`;

export const GeneralInfoBadges = styled.div`
  margin-left: 0.5rem;
  margin-top: -0.25rem;
`;

export const GeneralInfoSubtitle = styled.p`
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.875rem;
  line-height: 18px;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  word-break: break-all;
  word-break: break-word;

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const GeneralInfoActionButton = styled(Button)`
  margin-top: 14px;
`;

export const GeneralInfoGroupTag = styled.div`
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.875rem;
  line-height: 18px;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  margin-top: 12px;
`;
