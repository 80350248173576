import { database } from '../../../Database';
import { LinksApiTypeToDesktopLinksTableRow } from '../../../Database/DesktopLinksTable/DesktopLinksTable.utils';
import { CreateLinkInputVariables } from '../Operations/Mutations/CreateLink.mutation';
import { EditLinkInputVariables } from '../Operations/Mutations/EditLink.mutation';
import {
  bulkDeleteLinkApi,
  createLinkApi,
  editLinkApi,
  getLinkApi,
  moveLinks,
} from '../DataSource/LinkApiDataSource';
import { showToastError } from '../../../../shared/components/Toast';
import { captureException } from '@sentry/react';

export const editLinkRepository = async (
  linkId: string,
  workspaceId: string,
  linkData: Omit<EditLinkInputVariables, 'id'>,
) => {
  const response = await editLinkApi(linkId, linkData);
  // TODO: optimistic response?

  const { data, errors } = response;

  if (data) {
    // Update dexie database with edited link
    try {
      const linkRow = LinksApiTypeToDesktopLinksTableRow(
        data.editLink.link,
        workspaceId,
      );
      database.desktopLinks.put(linkRow, linkRow.id);
    } catch (error) {
      console.error(error);
    }
  }

  if (errors) {
    const message = errors[0].message;
    if (message) {
      showToastError(message);
    }
  }

  return response;
};

export const createLinkRepository = async (
  workspaceId: string,
  linkData: CreateLinkInputVariables,
) => {
  const response = await createLinkApi(linkData);
  // TODO: optimistic response?

  const { data } = response;

  if (data) {
    try {
      // add link to dexie database
      const linkRow = LinksApiTypeToDesktopLinksTableRow(
        data.createLink.link,
        workspaceId,
      );
      database.desktopLinks.add(linkRow, linkRow.id);
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};

export const deleteLinksRepository = async (linkIds: string[]) => {
  try {
    database.desktopLinks.bulkDelete(linkIds);

    const response = await bulkDeleteLinkApi(linkIds);
    // TODO: optimistic response here?

    return response;
  } catch (error) {
    captureException(error);
  }
};

export const getLinkRepository = async (
  workspaceId: string,
  linkId: string,
) => {
  const response = await getLinkApi(linkId);

  const { data } = response;

  if (data && data.link) {
    // add / update link in dexie database
    try {
      const linkRow = LinksApiTypeToDesktopLinksTableRow(
        data.link,
        workspaceId,
      );
      database.desktopLinks.put(linkRow);
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};

export const moveLinksRepository = async (
  linkIds: string[],
  desktopId?: string,
  folderId?: string,
  copy?: boolean,
) => {
  const response = await moveLinks(linkIds, desktopId, folderId, copy);
  // TODO: optimistic response?

  const { errors } = response;

  if (errors) {
    const message = errors[0]?.message;
    if (message) {
      showToastError(message);
    }
  }

  return response;
};
