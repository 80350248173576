import React, { FC, useCallback, useRef } from 'react';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../../../shared/components/Menu';
import { FormattedMessage, useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../i18n';
import {
  StyledDivider,
  StyledListItemMenuWrapper,
  StyledMenuItemContent,
} from './ListItemMenu.styled';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { KeyboardArrowDownIcon } from '../../../../../shared/icons';
import { useTheme } from 'styled-components';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { DesktopAccessType } from '../../../data/Desktop/types/Desktop.types';

const UserRights: { [key: string]: string } = {
  [DesktopAccessType.Manager]: DesktopTranslation.userRightsFullAccess,
  [DesktopAccessType.EditLink]: DesktopTranslation.userRightsCanEdit,
  [DesktopAccessType.CanComment]: DesktopTranslation.userRightsCanComment,
};

interface ListItemMenuProps {
  onDelete?: () => void;
  onEdit: (value: DesktopAccessType) => void;
  accessType: DesktopAccessType;
  isGuest?: boolean;
  isDisabled?: boolean;
  isOnlyFullAccessAllowed?: boolean;
}

export const ListItemMenu: FC<ListItemMenuProps> = ({
  onDelete,
  onEdit,
  accessType,
  isGuest,
  isDisabled,
  isOnlyFullAccessAllowed,
}) => {
  const intl = useIntl();
  const buttonRef = useRef(null);
  const theme = useTheme();

  const handleMenuItemClick = useCallback(
    (value: DesktopAccessType) => {
      onEdit(value);
    },
    [onEdit],
  );

  return (
    <StyledListItemMenuWrapper>
      <ButtonWithIcon
        ref={buttonRef}
        data-testid="manage-user-rights-button"
        icon={() => (
          <KeyboardArrowDownIcon color={theme.colors.OnSurfaceMediumEmphasis} />
        )}>
        <FormattedMessage id={UserRights[accessType]} />
      </ButtonWithIcon>
      <Menu
        trigger={buttonRef}
        width="auto"
        vAlign={VerticalAlignment.BOTTOM}
        hAlign={HorizontalAlignment.LEFT}
        data-testid="manage-user-rights-menu">
        {!isGuest && (
          <>
            <MenuItem
              displayCheckMark={accessType === DesktopAccessType.Manager}
              onClick={() => handleMenuItemClick(DesktopAccessType.Manager)}
              isDisabled={isDisabled}
              data-testid="user-rights-full-access">
              <StyledMenuItemContent>
                <FormattedMessage
                  tagName="div"
                  id={DesktopTranslation.userRightsFullAccess}
                />
                <FormattedMessage
                  tagName="span"
                  id={DesktopTranslation.userRightsFullAccessSubTitle}
                />
              </StyledMenuItemContent>
            </MenuItem>
            <StyledDivider />
          </>
        )}
        <MenuItem
          displayCheckMark={accessType === DesktopAccessType.EditLink}
          onClick={() => handleMenuItemClick(DesktopAccessType.EditLink)}
          isDisabled={isDisabled || isOnlyFullAccessAllowed}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={
            isOnlyFullAccessAllowed
              ? intl.formatMessage({
                  id: DesktopTranslation.accessPickerDisabledForAdmin,
                })
              : ''
          }
          data-tooltip-place={TooltipPlace.top}
          data-testid="user-rights-edit-link">
          <StyledMenuItemContent>
            <FormattedMessage
              tagName="div"
              id={DesktopTranslation.userRightsCanEdit}
            />
            <FormattedMessage
              tagName="span"
              id={DesktopTranslation.userRightsCanEditSubTitle}
            />
          </StyledMenuItemContent>
        </MenuItem>
        <MenuItem
          displayCheckMark={accessType === DesktopAccessType.CanComment}
          onClick={() => handleMenuItemClick(DesktopAccessType.CanComment)}
          isDisabled={isDisabled || isOnlyFullAccessAllowed}
          data-testid="user-rights-can-comment"
          data-tooltip-id="global-tooltip"
          data-tooltip-content={
            isOnlyFullAccessAllowed
              ? intl.formatMessage({
                  id: DesktopTranslation.accessPickerDisabledForAdmin,
                })
              : ''
          }
          data-tooltip-place={TooltipPlace.top}>
          <StyledMenuItemContent>
            <FormattedMessage
              tagName="div"
              id={DesktopTranslation.userRightsCanComment}
            />
            <FormattedMessage
              tagName="span"
              id={DesktopTranslation.userRightsCanCommentSubTitle}
            />
          </StyledMenuItemContent>
        </MenuItem>
        {onDelete && (
          <>
            <StyledDivider />
            <MenuItem
              onClick={onDelete}
              isDisabled={isDisabled}
              data-testid="remove-user">
              <StyledMenuItemContent isRemoveItem={true}>
                <FormattedMessage
                  tagName="div"
                  id={DesktopTranslation.accessRemoveAccess}
                />
              </StyledMenuItemContent>
            </MenuItem>
          </>
        )}
      </Menu>
    </StyledListItemMenuWrapper>
  );
};
