import type { AccessApiType } from '../../Access/data/types/Access.types';
import type { DatabaseTablesType } from '../Database.types';

export const ACCESS_COLUMNS =
  '++id, desktopId, teamId, workspaceId, sourceData';

export interface AccessTableType extends Omit<DatabaseTablesType, 'createdAt'> {
  id: string;
  desktopId: string | null;
  teamId: string | null;
  workspaceId: string;
  sourceData: AccessApiType;
}
