import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AccountsProviderContext } from './AccountsProvider.context';
import { AvailabilityAccountsType } from './AccountsProvider.types';
import { useAccountsRepository } from '../../data/Account/Account.repositories';
import { AccountWithCountsApiType } from '../../../User/User.types';
import { useAccountsActions } from '../../data/Account/Account.actions';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';

export const AccountsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { workspace } = useCurrentWorkspace();
  const { accounts, fetchAccounts } = useAccountsRepository({
    variables: {
      workspaceId: workspace.id,
    },
  });
  const [accountAvailabilities, setAccountAvailabilities] = useState<
    AvailabilityAccountsType | undefined
  >();
  const { updateAccount } = useAccountsActions();

  useEffect(() => {
    const accountsMap = accounts.reduce<AvailabilityAccountsType>(
      (acc, account) => ({
        ...acc,
        [account.id]: account as AccountWithCountsApiType,
      }),
      {} as AvailabilityAccountsType,
    );
    setAccountAvailabilities(accountsMap);
  }, [accounts]);

  const accountsProviderContext = useMemo(
    () => ({
      accountsWithAvailability: accountAvailabilities || {},
      refetchAccounts: () => {
        fetchAccounts();
        return Promise.resolve();
      },
      loading: !accountAvailabilities,
      updateAccount: updateAccount,
    }),
    [accountAvailabilities, fetchAccounts, updateAccount],
  );

  return (
    <AccountsProviderContext.Provider value={accountsProviderContext}>
      {children}
    </AccountsProviderContext.Provider>
  );
};
