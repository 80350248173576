import {
  ExternalServiceAuthTranslation,
  ExternalServiceAuthLocale,
} from './i18n.types';

export const externalServiceAuthLocale: ExternalServiceAuthLocale = {
  [ExternalServiceAuthTranslation.signInWithGoogle]: 'Sign in with Google',
  [ExternalServiceAuthTranslation.signInWindowTitle]:
    'Sign in with {serviceName}',
  [ExternalServiceAuthTranslation.signInWithGoogleDescription]:
    'To preview this file you need to sign in with Google',
  [ExternalServiceAuthTranslation.authorizationFailedHeadTitle]:
    'Authorization failed',
  [ExternalServiceAuthTranslation.externalAuthServiceTitle]:
    'External auth service',
  [ExternalServiceAuthTranslation.externalAuthServiceDescription]:
    'The Google authorization was canceled, please press the button below to return to the app.',
  [ExternalServiceAuthTranslation.externalAuthServiceGoBackButton]:
    'Go back to the application',
  [ExternalServiceAuthTranslation.externalAuthNotSupportedTitle]:
    'To preview this file use native app',
  [ExternalServiceAuthTranslation.externalAuthNotSupportedButton]:
    'Open in native app',
};
