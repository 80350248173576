import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ClearIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.719828 0.72C0.860448 0.57955 1.05108 0.50066 1.24983 0.50066C1.44858 0.50066 1.6392 0.57955 1.77983 0.72L4.99983 3.94L8.21983 0.72C8.28853 0.64631 8.37133 0.58721 8.46333 0.54622C8.55533 0.50523 8.65463 0.48319 8.75533 0.48141C8.85603 0.47963 8.95603 0.49816 9.04943 0.53588C9.14283 0.5736 9.22763 0.62974 9.29883 0.70096C9.37013 0.77218 9.42623 0.85702 9.46393 0.9504C9.50163 1.04379 9.52023 1.14382 9.51843 1.24452C9.51663 1.34523 9.49463 1.44454 9.45363 1.53654C9.41263 1.62854 9.35353 1.71134 9.27983 1.78L6.05983 5L9.27983 8.22C9.35353 8.2887 9.41263 8.3715 9.45363 8.4635C9.49463 8.5555 9.51663 8.6548 9.51843 8.7555C9.52023 8.8562 9.50163 8.9562 9.46393 9.0496C9.42623 9.143 9.37013 9.2278 9.29883 9.299C9.22763 9.3703 9.14283 9.4264 9.04943 9.4641C8.95603 9.5018 8.85603 9.5204 8.75533 9.5186C8.65463 9.5168 8.55533 9.4948 8.46333 9.4538C8.37133 9.4128 8.28853 9.3537 8.21983 9.28L4.99983 6.06L1.77983 9.28C1.63765 9.4125 1.4496 9.4846 1.2553 9.4812C1.061 9.4777 0.875618 9.399 0.738198 9.2616C0.600788 9.1242 0.522078 8.9388 0.518648 8.7445C0.515218 8.5502 0.587348 8.3622 0.719828 8.22L3.93983 5L0.719828 1.78C0.579378 1.63938 0.500488 1.44875 0.500488 1.25C0.500488 1.05125 0.579378 0.86063 0.719828 0.72Z"
        fill="currentColor"
      />
    </svg>
  );
});
