import { useContext } from 'react';
import { AuthContext, CurrentAccountContext } from './Auth.context';
import type { CurrentAccountContextType } from './Auth.types';

export const useAuthContext = () => {
  const { authService, fetchAuthConfig } = useContext(AuthContext);
  return { authService, fetchAuthConfig };
};

export const useAuthService = () => {
  const context = useContext(AuthContext);
  if (!context?.authService) {
    throw new Error('Auth Service should not be used before initialized');
  }
  return context.authService;
};

export const useCurrentAccount = () =>
  useContext(CurrentAccountContext) as CurrentAccountContextType;
