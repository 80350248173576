import { database } from '../../../../Database';
import { TeamsTableType } from '../../../../Database/WorkspaceTeams/WorkspaceTeamsTable';

export const getWorkspaceTeamsFromIDDB = async (
  workspaceId: string,
): Promise<TeamsTableType[]> => {
  try {
    return database.teams.where({ workspaceId }).toArray();
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getWorkspaceTeamFromIDDB = async (
  teamId: string,
): Promise<TeamsTableType | undefined> => {
  try {
    return database.teams.get(teamId);
  } catch (err) {
    console.error(err);
  }
};
