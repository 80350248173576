import { database } from '../../../Database';
import { AccessTableType } from '../../../Database/AccessTable/AccessTable';
import { getLongId } from '../../../../shared/utils/id';

export const getDesktopAccessesIDDB = async (desktopId?: string) => {
  if (!desktopId) {
    return [];
  }
  return database.access.where('desktopId').equals(desktopId).toArray();
};

export const getWorkspaceDesktopAccessesIDDB = (workspaceId: string) => {
  return database.access.where('workspaceId').equals(workspaceId).toArray();
};

export const getAccessByDesktopIdAndWorkspaceTeamId = async (
  desktopId: string,
  teamId: string,
): Promise<AccessTableType | undefined> => {
  try {
    const desktopIri = getLongId('desktops', desktopId);
    const teamIri = getLongId('workspace-teams', teamId);
    const accesses = await database.access
      .where({ desktopId: desktopIri })
      .toArray();

    return accesses.find(
      access => access.sourceData.workspaceTeam?.id === teamIri,
    );
  } catch (e) {
    console.error(e);
  }
};
