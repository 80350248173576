import {
  StreamEventRequest,
  StreamNotificationEvent,
} from '../../GeneralMercure.types';
import { notificationRequestNew } from './Requests';
import { AccountApiType } from '../../../../User/User.types';
import { ApolloClient } from '@apollo/client';
import { notificationRequestUpdate } from './Requests/NotificationRequestUpdate';
import { notificationRequestRemove } from './Requests/NotificationRequestRemove';

export const notificationRequest = (
  event: StreamNotificationEvent,
  updateAccount: (updater: (account: AccountApiType) => AccountApiType) => void,
  apolloClient: ApolloClient<object>,
  account: AccountApiType | null,
) => {
  switch (event['@request']) {
    case StreamEventRequest.NEW:
      notificationRequestNew(event, updateAccount, apolloClient, account);
      break;
    case StreamEventRequest.UPDATE:
      notificationRequestUpdate(event, apolloClient);
      break;
    case StreamEventRequest.REMOVE:
      notificationRequestRemove(event, apolloClient);
      break;
    default:
      break;
  }
};
