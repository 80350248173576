import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const InfoQuestionIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.9699 8.265C10.7604 8.40902 10.5924 8.60559 10.4829 8.835C10.3899 9.00564 10.2344 9.13355 10.049 9.19178C9.86359 9.25001 9.66284 9.23402 9.48901 9.14717C9.31518 9.06032 9.18185 8.9094 9.11709 8.72619C9.05233 8.54298 9.06121 8.34179 9.14185 8.165C9.34185 7.763 9.65485 7.339 10.1389 7.017C10.6269 6.69 11.2439 6.5 11.9999 6.5C12.6579 6.5 13.3689 6.695 13.9339 7.119C14.2488 7.34926 14.5043 7.65142 14.6789 8.00034C14.8536 8.34926 14.9423 8.73484 14.9379 9.125C14.9379 10.158 14.4249 10.845 13.9109 11.34C13.7209 11.523 13.5119 11.698 13.3319 11.848L13.1849 11.971C13.0306 12.0972 12.8853 12.2338 12.7499 12.38V13.75C12.7499 13.9489 12.6708 14.1397 12.5302 14.2803C12.3895 14.421 12.1988 14.5 11.9999 14.5C11.8009 14.5 11.6102 14.421 11.4695 14.2803C11.3289 14.1397 11.2499 13.9489 11.2499 13.75V12.277C11.2499 12.04 11.3169 11.773 11.4969 11.541C11.7169 11.261 11.9829 11.024 12.2149 10.827L12.3979 10.674L12.3989 10.673C12.5709 10.53 12.7229 10.403 12.8689 10.261C13.2369 9.906 13.4379 9.585 13.4379 9.125C13.4423 8.96775 13.4077 8.81184 13.3372 8.6712C13.2667 8.53056 13.1625 8.40956 13.0339 8.319C12.7659 8.118 12.3839 8 11.9999 8C11.5059 8 11.1859 8.121 10.9699 8.265ZM12.9999 17C12.9999 17.2652 12.8945 17.5196 12.707 17.7071C12.5194 17.8946 12.2651 18 11.9999 18C11.7346 18 11.4803 17.8946 11.2927 17.7071C11.1052 17.5196 10.9999 17.2652 10.9999 17C10.9999 16.7348 11.1052 16.4804 11.2927 16.2929C11.4803 16.1054 11.7346 16 11.9999 16C12.2651 16 12.5194 16.1054 12.707 16.2929C12.8945 16.4804 12.9999 16.7348 12.9999 17Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM2.5 12C2.5 9.48044 3.50089 7.06408 5.28249 5.28249C7.06408 3.50089 9.48044 2.5 12 2.5C14.5196 2.5 16.9359 3.50089 18.7175 5.28249C20.4991 7.06408 21.5 9.48044 21.5 12C21.5 14.5196 20.4991 16.9359 18.7175 18.7175C16.9359 20.4991 14.5196 21.5 12 21.5C9.48044 21.5 7.06408 20.4991 5.28249 18.7175C3.50089 16.9359 2.5 14.5196 2.5 12Z"
        fill="currentColor"
      />
    </svg>
  );
});
