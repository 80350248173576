export const isAppleTouchDevice = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isIPad = () => {
  return (
    ['iPad Simulator', 'iPad'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isSafari = () => {
  return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
};

export const isChromium = () => {
  return !!window.chrome;
};

export const isFirefox = () => {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
};

export const isAndroid = () => {
  return navigator.userAgent.includes('Android');
};

export const isMacOS = () => {
  return (
    navigator.platform.toLowerCase().indexOf('mac') >= 0 &&
    !(navigator.maxTouchPoints && navigator.maxTouchPoints > 1)
  );
};
