import { ApiConnection } from './api.types';
import {
  GRAPHQL_TYPENAME_DESKTOP,
  GRAPHQL_TYPENAME_DESKTOP_APP,
  GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
  GRAPHQL_TYPENAME_DESKTOP_APP_EDGE,
} from '../../domains/Desktop/Desktop.constants';
import {
  GRAPHQL_TYPENAME_CATEGORY,
  GRAPHQL_TYPENAME_IMAGE_ASSET,
} from './api.constants';
import {
  GRAPHQL_TYPENAME_CHAT_CONVERSATION,
  GRAPHQL_TYPENAME_CHAT_MESSAGE,
  GRAPHQL_TYPENAME_CHAT_MESSAGE_EDGE,
} from '../../domains/Chat/Chat.constants';
import {
  GRAPHQL_TYPENAME_PASSWORD_RECOVERY_REQUEST,
  GRAPHQL_TYPENAME_VAULT,
  GRAPHQL_TYPENAME_VAULT_ACCESS,
  GRAPHQL_TYPENAME_VAULT_ITEM,
} from '../../domains/Encryption/Encryption.constants';

export const extractNodes = <T>(connection?: ApiConnection<T>) =>
  connection ? connection.edges.map(({ node }) => node) : [];

interface ConnectionDefinition {
  name: string;
  connectionTypename: string;
  edgeTypename: string;
}

interface ApiData {
  [key: string]: any;
}

const ID_TO_TYPENAME_MAP: {
  [key: string]: {
    typename: string;
    connections?: ConnectionDefinition[];
  };
} = {
  desktops: {
    typename: GRAPHQL_TYPENAME_DESKTOP,
    connections: [
      {
        name: 'apps',
        connectionTypename: GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
        edgeTypename: GRAPHQL_TYPENAME_DESKTOP_APP_EDGE,
      },
    ],
  },
  'desktop-apps': {
    typename: GRAPHQL_TYPENAME_DESKTOP_APP,
  },
  apps: {
    typename: 'App',
    connections: [
      {
        name: 'desktops',
        connectionTypename: GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
        edgeTypename: GRAPHQL_TYPENAME_DESKTOP_APP_EDGE,
      },
      {
        name: 'workspaceConfigs',
        connectionTypename: 'WorkspaceAppConfigConnection',
        edgeTypename: 'WorkspaceAppConfigEdge',
      },
    ],
  },
  categories: {
    typename: GRAPHQL_TYPENAME_CATEGORY,
  },
  'assets/images/': {
    typename: GRAPHQL_TYPENAME_IMAGE_ASSET,
  },
  accounts: {
    typename: 'Account',
    connections: [
      {
        name: 'workspaces',
        connectionTypename: 'AccountWorkspaceConnection',
        edgeTypename: 'AccountWorkspaceEdge',
      },
      {
        name: 'groups',
        connectionTypename: 'AccountGroupConnection',
        edgeTypename: 'AccountGroupEdge',
      },
    ],
  },
  'account-groups': {
    typename: 'AccountGroup',
  },
  'account-identities': {
    typename: 'AccountIdentity',
    connections: [
      {
        name: 'myAccounts',
        connectionTypename: 'AccountConnection',
        edgeTypename: 'AccountEdge',
      },
    ],
  },
  'account-workspaces': {
    typename: 'AccountWorkspace',
    connections: [
      {
        name: 'teams',
        connectionTypename: 'WorkspaceTeamConnection',
        edgeTypename: 'WorkspaceTeamEdge',
      },
    ],
  },
  workspaces: {
    typename: 'Workspace',
    connections: [
      {
        name: 'desktops',
        connectionTypename: 'DesktopConnection',
        edgeTypename: 'DesktopEdge',
      },
      {
        name: 'teams',
        connectionTypename: 'WorkspaceTeamConnection',
        edgeTypename: 'WorkspaceTeamEdge',
      },
    ],
  },
  'desktop-accesses': {
    typename: 'DesktopAccess',
  },
  'workspace-teams': {
    typename: 'WorkspaceTeam',
    connections: [
      {
        name: 'desktops',
        connectionTypename: 'DesktopAccessConnection',
        edgeTypename: 'DesktopAccessEdge',
      },
      {
        name: 'accounts',
        connectionTypename: 'AccountWorkspaceConnection',
        edgeTypename: 'AccountWorkspaceEdge',
      },
    ],
  },
  groups: {
    typename: 'Group',
  },
  links: {
    typename: 'Link',
    connections: [
      {
        name: 'tags',
        connectionTypename: 'TagConnection',
        edgeTypename: 'TagEdge',
      },
    ],
  },
  'link-datas': {
    typename: 'LinkData',
  },
  folders: {
    typename: 'Folder',
  },
  tags: {
    typename: 'Tag',
  },
  'workspace-app-configs': {
    typename: 'WorkspaceAppConfig',
  },
  notifications: {
    typename: 'Notification',
  },
  'payment-customers': {
    typename: 'PaymentCustomer',
    connections: [
      {
        name: 'invoices',
        connectionTypename: 'PaymentInvoiceConnection',
        edgeTypename: 'PaymentInvoiceEdge',
      },
    ],
  },
  'payment-invoices': {
    typename: 'PaymentInvoice',
  },
  'chat-messages': {
    typename: GRAPHQL_TYPENAME_CHAT_MESSAGE,
  },
  'chat-conversations': {
    typename: GRAPHQL_TYPENAME_CHAT_CONVERSATION,
  },
  vaults: {
    typename: GRAPHQL_TYPENAME_VAULT,
    connections: [
      {
        name: 'vaultItems',
        connectionTypename: 'VaultItemConnection',
        edgeTypename: 'VaultItemEdge',
      },
      {
        name: 'vaultAccesses',
        connectionTypename: 'VaultAccessConnection',
        edgeTypename: 'VaultAccessEdge',
      },
    ],
  },
  'vault-items': {
    typename: GRAPHQL_TYPENAME_VAULT_ITEM,
  },
  'vault-accesses': {
    typename: GRAPHQL_TYPENAME_VAULT_ACCESS,
  },
  'password-recovery-requests': {
    typename: GRAPHQL_TYPENAME_PASSWORD_RECOVERY_REQUEST,
  },
  'integration-clients': {
    typename: 'IntegrationClient',
  },
};

const GLOBAL_CONNECTIONS: ConnectionDefinition[] = [
  {
    name: 'desktopAccesses',
    connectionTypename: 'DesktopAccessConnection',
    edgeTypename: 'DesktopAccessEdge',
  },
  {
    name: 'apps',
    connectionTypename: 'AppCursorConnection',
    edgeTypename: 'AppEdge',
  },
  {
    name: 'categories',
    connectionTypename: 'CategoryConnection',
    edgeTypename: 'CategoryEdge',
  },
  {
    name: 'desktopApps',
    connectionTypename: GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
    edgeTypename: GRAPHQL_TYPENAME_DESKTOP_APP_EDGE,
  },
  {
    name: 'links',
    connectionTypename: 'LinkConnection',
    edgeTypename: 'LinkEdge',
  },
  {
    name: 'workspaceTeams',
    connectionTypename: 'WorkspaceTeamConnection',
    edgeTypename: 'WorkspaceTeamEdge',
  },
  {
    name: 'accounts',
    connectionTypename: 'AccountConnection',
    edgeTypename: 'AccountEdge',
  },
  {
    name: 'workspaceAccountInvitations',
    connectionTypename: 'AccountInvitationConnection',
    edgeTypename: 'AccountInvitationEdge',
  },
  {
    name: 'listChatMessages',
    connectionTypename: 'ChatMessageConnection',
    edgeTypename: GRAPHQL_TYPENAME_CHAT_MESSAGE_EDGE,
  },
  {
    name: 'vaults',
    connectionTypename: 'VaultCollectionConnection',
    edgeTypename: 'VaultCollectionEdge',
  },
  {
    name: 'password-recovery-requests',
    connectionTypename: 'PasswordRecoveryRequestConnection',
    edgeTypename: 'PasswordRecoveryRequestEdge',
  },
];

export const isApiData = (data: any): data is ApiData =>
  typeof data === 'object' && data !== null && !Array.isArray(data);

export const extractPrefixFromId = (id?: string): string =>
  id ? id.substr(1, id.lastIndexOf('/') - 1) : '';

export const fixConnectionTypenames = (
  data: ApiData,
  { connectionTypename, edgeTypename }: ConnectionDefinition,
) => ({
  ...data,
  ...(data.edges
    ? {
        edges: data.edges.map(
          (edge: { node: { __typename: string } & ApiData }) => ({
            node: fixTypenames(edge.node),
            __typename: edgeTypename,
          }),
        ),
      }
    : undefined),
  __typename: connectionTypename,
});

export const fixTypenames = (data: ApiData): ApiData => {
  const excludeKeys: string[] = [];

  const result: ApiData = {};

  const { id, __typename } = data;

  const prefix = extractPrefixFromId(id);

  const match =
    id &&
    __typename &&
    ID_TO_TYPENAME_MAP[prefix] &&
    ID_TO_TYPENAME_MAP[prefix] !== __typename
      ? ID_TO_TYPENAME_MAP[prefix]
      : null;

  if (match) {
    excludeKeys.push('__typename');
    result.__typename = match.typename;

    (match.connections || []).forEach(connection => {
      const { name } = connection;

      if (data[name]) {
        excludeKeys.push(name);
        result[name] = fixConnectionTypenames(data[name], connection);
      }
    });
  }

  if (__typename) {
    GLOBAL_CONNECTIONS.filter(
      ({ name }) => !excludeKeys.includes(name),
    ).forEach(connection => {
      const { name } = connection;
      if (data[name]) {
        excludeKeys.push(name);
        result[name] = fixConnectionTypenames(data[name], connection);
      }
    });
  }

  return Object.keys(data)
    .filter(key => !excludeKeys.includes(key))
    .reduce((acc, key) => {
      const value = data[key];

      return {
        ...acc,
        [key]: isApiData(value) ? fixTypenames(value) : value,
      };
    }, result);
};

export const updateCursor = (
  cursor: string,
  updater: (oldValue: number) => number,
) => {
  return window.btoa(updater(parseInt(window.atob(cursor), 10)).toString());
};
