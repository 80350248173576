import React, { FC, ReactNode, useMemo } from 'react';
import { Popover, PopoverProps } from '../../Popover';
import { StyledMenu } from './MenuPopover.styled';
import { MenuContext } from '../Menu.context';
import { getBottomLeftAnchor } from '../../Popover/Popover.utils';

const VERTICAL_MARGIN = 8;

interface MenuPopoverProps
  extends Pick<PopoverProps, 'onRequestClose' | 'triggerRef'> {
  menu: ReactNode;
}

export const MenuPopover: FC<MenuPopoverProps> = ({
  menu,
  onRequestClose,
  ...popoverProps
}) => {
  const menuControls = useMemo(
    () => ({
      closeMenu: () => {
        onRequestClose();
      },
    }),
    [onRequestClose],
  );

  return (
    <Popover
      onRequestClose={onRequestClose}
      verticalMargin={VERTICAL_MARGIN}
      anchorGetter={getBottomLeftAnchor}
      {...popoverProps}>
      <StyledMenu>
        <MenuContext.Provider value={menuControls}>{menu}</MenuContext.Provider>
      </StyledMenu>
    </Popover>
  );
};
