export enum FolderTranslation {
  folderFormNameLabel = 'folder_folderFormNameLabel',
  folderFormNamePlaceholder = 'folder_folderFormNamePlaceholder',
  folderFormNameRequired = 'folder_folderFormNameRequired',
  folderFormsCreateButton = 'folder_folderFormsCreateButton',
  folderFormsSaveButton = 'folder_folderFormsSaveButton',
  folderFormCancelButton = 'folder_folderFormCancelButton',
  folderMarkAsFavorite = 'folder_foderMarkAsFavorite',
  folderUnMarkAsFavorite = 'folder_folderUnMarkAsFavorite',
  createFolderModalHeader = 'folder_createFolderModalHeader',
  createFolder = 'folder_createFolder',
  editFolderModalHeader = 'folder_editFolderModalHeader',
  folderNotFound = 'folder_folderNotFound',
  linksViewEditFolder = 'folder_linksViewEditFolder',
  linksViewRemoveFolder = 'folder_linksViewRemoveFolder',
  linksViewRemoveFolderConfirmation = 'folder_linksViewRemoveFolderConfirmation',
  emptyFolderMessage = 'folder_emptyFolderMessage',
  folderAlreadyExistInDesktop = 'folder_folderAlreadyExistInDesktop',
  folderLimitDesktopReached = 'folder_folderLimitDesktopReached',
  subfolderCount = 'folder_subfolderCount',
  subfolderLinksCount = 'folder_subfolderLinksCount',
  desktopRootFolderLabel = 'folder_desktopRootFolderLabel',

  tooltipFolderDotsButton = 'folder_tooltipFolderDotsButton',
}

export type FolderLocale = {
  [key in FolderTranslation]: string;
};
