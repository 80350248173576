import styled, { css } from 'styled-components';
import { ModalThemeOverride } from '.';
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon';

export enum ModalHeaderTitleSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export const StyledModalHeader = styled.div<{
  underline?: boolean;
  border?: boolean;
  themeOverride?: ModalThemeOverride;
}>`
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing.m} 0
    ${({ theme }) => theme.spacing.xl};
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};

  ${({ underline }) =>
    !underline &&
    css`
      box-shadow: none;
    `};

  ${({ border }) =>
    !border &&
    css`
      border: none;
    `};

  button {
    position: relative;
    z-index: 1000;
    color: ${p =>
      p.themeOverride === ModalThemeOverride.DARK
        ? p.theme.colors.AssetsModalHeaderTextColor
        : p.theme.colors.OnSurfaceMediumEmphasis};
  }

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    min-height: 64px;
  `}
`;

export const StyledModalHeaderData = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100% - 1rem);
  font-size: 1rem;
  font-family: ${p => p.theme.fonts.Semibold};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    padding-right: 1.25rem;
  `}
`;

export const ModalHeaderTitle = styled.h2<{
  size?: ModalHeaderTitleSize;
}>`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 1rem;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ size }) =>
    size === ModalHeaderTitleSize.sm &&
    css`
      font-size: 1rem;
    `};

  ${({ size }) =>
    size === ModalHeaderTitleSize.md &&
    css`
      font-size: 1.25rem;
    `};

  ${({ size }) =>
    size === ModalHeaderTitleSize.lg &&
    css`
      font-size: 1.5rem;
    `};
`;

export const ModalHeaderSubtitle = styled.p`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 120%;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ModalHeaderActionsContainer = styled.div`
  display: flex;
`;

export const ModalHeaderActions = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.s};
  padding-right: ${({ theme }) => theme.spacing.s};
  position: relative;
  & > button {
    margin-left: ${({ theme }) => theme.spacing.xs};
  }

  &:after {
    content: ' ';
    height: 20px;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.Divider};
    position: absolute;
    right: 0;
    top: 6px;
  }
`;

export const ModalHeaderActionButton = styled(ButtonWithIcon)<{
  iconSize?: number;
  isActive?: boolean;
}>`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  outline: none;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
        `
      : css`
          &:hover {
            background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
          }

          &:focus {
            background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
          }

          &:focus-visible {
            border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
          }
        `}

  svg {
    width: ${({ iconSize }) => (iconSize ? iconSize : '16')}px;
    height: ${({ iconSize }) => (iconSize ? iconSize : '16')}px;
  }
`;
