import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DeleteIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.5 1.75c0-.138.112-.25.25-.25h2.5c.138 0 .25.112.25.25V3h-3V1.75zm4.5 0V3h2.25c.414 0 .75.336.75.75s-.336.75-.75.75H2.75c-.414 0-.75-.336-.75-.75S2.336 3 2.75 3H5V1.75C5 .784 5.784 0 6.75 0h2.5C10.216 0 11 .784 11 1.75zM4.496 6.675c-.04-.412-.408-.712-.82-.671-.413.04-.714.408-.672.82l.66 6.6c.09.895.842 1.576 1.741 1.576h5.19c.9 0 1.652-.681 1.741-1.576l.66-6.6c.041-.412-.26-.78-.671-.82-.412-.041-.78.26-.821.671l-.66 6.6c-.013.128-.12.225-.249.225h-5.19c-.128 0-.236-.097-.249-.225l-.66-6.6z"
        clipRule="evenodd"
      />
    </svg>
  );
});
