import {
  BaseThemedCssFunction,
  DefaultTheme,
  FlattenSimpleInterpolation,
} from 'styled-components';
import type { OnboardingThemeType } from '../Onboarding/Theme/Onboarding.theme.types';
import type { SharedThemeType } from '../../shared/Theme/Shared.theme.types';
import type { AccountThemeType } from '../Account/theme/Account.theme.types';
import type { UserThemeType } from '../User/Theme/User.theme.types';
import type { BillingThemeType } from '../Billing/Theme/Billing.theme.types';
import type { ChatThemeType } from '../Chat/Theme/Chat.theme.types';
import type { WorkspaceThemeType } from '../Workspace/Theme/Workspace.theme.types';
import type { ConferenceThemeType } from '../Conference/Theme/Conference.theme.types';
import type { LinkThemeType } from '../Link/Theme/Link.theme.types';
import type { CarouselThemeType } from '../../shared/components/Carousel/Theme/Carousele.theme.types';
import type { EditorThemeType } from '../../shared/components/Editor/Theme/Editor.theme.types';
import type { ChatMessageSearchInputThemeType } from '../Chat/ChatMessageSearch/theme/ChatMessageSearchInput.theme.types';
import type { ConferencePreviewThemeType } from '../Conference/CreateConferencePage/ConferencePreview/Theme/ConferencePreview.theme.types';
import { SegmentSwitcherThemeType } from '../Segment/SegmentSwitcher/Theme/SegmentSwitcher.theme.types';

export enum ThemeType {
  light = 'light',
  dark = 'dark',
}

export interface ThemeContextTypes {
  theme: DefaultTheme | null;
  themeType: ThemeType | null;
  setTheme: (theme: ThemeType) => void;
}

type BaseThemeType = {
  colors: {
    Background: string;
    Surface: string;
    OnSurface: string;
    Navbar: string;
    OnNavbar: string;
    IdentityBar: string;
    PwaThemeColor: string;
    White: '#FFF';
    OnSurfaceHighEmphasis: string;
    OnSurfaceMediumEmphasis: string;
    OnSurfaceLightEmphasis: string;
    OnSurfacePressed: string;
    OnSurfaceSelected: string;
    OnSurfaceHover: string;
    OnNavbarMediumEmphasis: string;
    OnNavbarSelected: string;
    OnNavbarHover: string;
    SurfaceBorder: string;
    NavbarBorder: string;
    SurfaceSubdued: string;
    OnNavbarSubdued: string;
    Divider: string;
    OnNavbarDivider: string;
    PrimaryLightest: string;
    PrimaryLight: string;
    Primary: string;
    PrimaryDark: string;
    PrimaryDarker: string;
    PrimaryDarkest: string;
    Success: string;
    SuccessDark: string;
    SuccessLighter: string;
    CriticalLight: string;
    CriticalDark: string;
    CriticalLighter: string;
    Warning: string;
    WarningLight: string;
    WarningDark: string;
    Pending: string;
    PrimaryActiveButtonLinkBackground: string;
    DarkActiveButtonLinkBackground: string;
    WorkspaceSwitcherSkeleton: string;
    DesktopSwitcherSkeleton: string;
    AppTopBarSkeleton: string;
    LinkViewSkeleton: string;
    ChatViewSkeleton: string;
    DesktopViewSkeleton: string;
    AppsViewSkeleton: string;
    LinksViewSkeleton: string;
    DesktopViewHeaderSkeleton: string;
    BorderDefault: string;
    BorderLight: string;
    ModalHeaderBorder: string;
    DisabledElement: string;
    BoxShadowDefault: string;
    PrimaryTextLight: string;
    ExternalProviderApple: string;
    ExternalProviderGoogle: string;
    ExternalProviderFacebook: string;
    ExternalProviderMicrosoft: string;
    NavigatorLinkDraggingShadow: string;
    NavigatorLinkDraggingBackground: string;
    FavoriteIcon: string;
    FavoriteIconHover: string;
    IconLight: string;
    WorkspaceLinkHover: string;
    BackgroundLight: string;
    BackgroundInputAutofill: string;
    TextHighlighterBackgroundColor: string;
  };
  fonts: {
    Regular: string;
    Medium: string;
    Semibold: string;
    Merriweather: string;
  };
  fontTemplates: {
    headerMain: FlattenSimpleInterpolation;
    headerList: FlattenSimpleInterpolation;
    regular: FlattenSimpleInterpolation;
    regularSemibold: FlattenSimpleInterpolation;
    smallSemibold: FlattenSimpleInterpolation;
    tiny: FlattenSimpleInterpolation;
    navbarList: FlattenSimpleInterpolation;
  };
  zIndexLevels: {
    Folder: number;
    FolderLifted: number;
    MessageSearchInput: number;
    PeoplePanel: number;
    Modal: number;
    ModalBackdrop: number;
    Menu: number;
    NotificationList: number;
    Confirm: number;
    MaintenanceOverlay: number;
    Tooltip: number;
    TimeLineDesktopView: number;
  };
  breakpoints: {
    // @ts-ignore
    xs: BaseThemedCssFunction;
    // @ts-ignore
    sm: BaseThemedCssFunction;
    // @ts-ignore
    md: BaseThemedCssFunction;
    // @ts-ignore
    lg: BaseThemedCssFunction;
    // @ts-ignore
    xlg: BaseThemedCssFunction;
    // @ts-ignore
    xxl: BaseThemedCssFunction;
  };
  shadows: {
    default: string;
  };
  spacing: {
    xxs: string;
    xs: string;
    s: string;
    m: string;
    l: string;
    xl: string;
    xxl: string;
    xxxl: string;
    xxxxl: string;
  };
  fixedValues: {
    segmentHeaderHeight: string;
  };
  animationTemplates: {
    opacityOnHoverTransition: string;
  };
};

export type CustomThemeType = BaseThemeType &
  OnboardingThemeType &
  SharedThemeType &
  AccountThemeType &
  UserThemeType &
  BillingThemeType &
  ChatThemeType &
  WorkspaceThemeType &
  ConferenceThemeType &
  LinkThemeType &
  EditorThemeType &
  CarouselThemeType &
  ChatMessageSearchInputThemeType &
  ConferencePreviewThemeType &
  SegmentSwitcherThemeType;
