import {
  StreamRefreshAccountUnreadsEvent,
  StreamRefreshAccountUnreadsEventPayloadTypes,
} from '../../GeneralMercure.types';
import { AccountApiType } from '../../../../User/User.types';
import {
  updateAccountChatMessageUnreads,
  updateAccountUnreadInbox,
} from '../../../Chat/handlers/chatMessage.utils';
import { captureException } from '@sentry/react';
import { UNREAD_REQUEST_TIMEOUT } from '../../../../Account/data/CurrentAccount/CurrentAccount.constants';
import type { UseAccountApiRepositoryProps } from '../../../../Account/data/CurrentAccount/types/CurrentAccountRepository.types';

let unreadChatMessagesRequested: boolean = false;
let unreadAccountUnreadInboxRequested: boolean = false;
let unreadAccountNotificationsRequested: boolean = false;
let unreadChatConferencesRequested: boolean = false;

export const refreshAccountUnreads = async (
  event: StreamRefreshAccountUnreadsEvent,
  updateAccount: (updater: (account: AccountApiType) => AccountApiType) => void,
  accountApiRepository: UseAccountApiRepositoryProps,
  currentAccount: AccountApiType,
) => {
  const { getAccountUnreadNotifications, getAccountUnreadChatConferences } =
    accountApiRepository;

  const unreadsEventType: StreamRefreshAccountUnreadsEventPayloadTypes =
    event['@payload'].types;

  const workspaceId = event['@payload'].workspace;

  Object.values(unreadsEventType).forEach(async unreadEventType => {
    switch (unreadEventType) {
      case StreamRefreshAccountUnreadsEventPayloadTypes.chat:
        if (unreadChatMessagesRequested) {
          return;
        }

        unreadChatMessagesRequested = true;

        setTimeout(() => {
          updateAccountChatMessageUnreads(
            accountApiRepository,
            currentAccount,
            updateAccount,
            workspaceId,
          ).finally(() => {
            unreadChatMessagesRequested = false;
          });
        }, UNREAD_REQUEST_TIMEOUT);

        break;
      case StreamRefreshAccountUnreadsEventPayloadTypes.inbox:
        if (unreadAccountUnreadInboxRequested) {
          return;
        }

        unreadAccountUnreadInboxRequested = true;

        setTimeout(() => {
          updateAccountUnreadInbox(accountApiRepository, updateAccount).finally(
            () => (unreadAccountUnreadInboxRequested = false),
          );
        }, UNREAD_REQUEST_TIMEOUT);
        break;
      case StreamRefreshAccountUnreadsEventPayloadTypes.meetings:
        if (unreadChatConferencesRequested) {
          return;
        }

        unreadChatConferencesRequested = true;

        try {
          const unreadChatConferences = await getAccountUnreadChatConferences();

          updateAccount(account => ({
            ...account,
            unreadChatConferences,
          }));
        } catch (err) {
          captureException(err);
        } finally {
          unreadChatConferencesRequested = false;
        }

        break;
      case StreamRefreshAccountUnreadsEventPayloadTypes.notifications:
        if (unreadAccountNotificationsRequested) {
          return;
        }

        unreadAccountNotificationsRequested = true;

        try {
          const unreadNotifications = await getAccountUnreadNotifications();

          setTimeout(() => {
            updateAccount(account => ({
              ...account,
              unreadNotifications,
            }));
          }, UNREAD_REQUEST_TIMEOUT);
        } catch (err) {
          captureException(err);
        } finally {
          unreadAccountNotificationsRequested = false;
        }

        break;
      default:
        break;
    }
  });
};
