import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../Theme';

export const StyledDesktopViewSkeleton = styled.div<{ isMobile?: boolean }>`
  grid-area: desktop;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.DesktopViewHeaderSkeleton};
  width: 100%;

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: fixed;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      background-color: ${p => p.theme.colors.Surface};
    `}

  .header {
    height: 3.5rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: ${p => p.theme.colors.DesktopViewHeaderSkeleton};

    @media (max-width: ${Breakpoints.md - 1}px) {
      min-width: 100vw;

      .hide-on-mobile {
        display: none;
      }
    }

    .spacer {
      flex-grow: 1;
    }
  }

  .header-navigation {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;
