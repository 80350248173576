import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const CreditcardIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M0 1.75C0 0.7835 0.783502 0 1.75 0H14.25C15.2165 0 16 0.7835 16 1.75V10.25C16 11.2165 15.2165 12 14.25 12H1.75C0.783501 12 0 11.2165 0 10.25V1.75ZM14.5 1.75V3H1.5V1.75C1.5 1.61193 1.61193 1.5 1.75 1.5H14.25C14.3881 1.5 14.5 1.61193 14.5 1.75ZM14.5 4.5H1.5V10.25C1.5 10.3881 1.61193 10.5 1.75 10.5H14.25C14.3881 10.5 14.5 10.3881 14.5 10.25V4.5Z"
      />
    </svg>
  );
});
