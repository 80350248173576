export enum GlobalSearchTranslation {
  cancelButtonText = 'globalSearch_cancelButtonText',
  searchButton = 'globalSearch_searchButton',

  searchInputPlaceholder = 'globalSearch_searchInputPlaceholder',
  searchAppIntegrationsInputPlaceholder = 'globalSearch_searchAppIntegrationsInputPlaceholder',

  expandedSearchAllContent = 'globalSearch_expandedSearchAllContent',
  expandedSearchAppsTab = 'globalSearch_expandedSearchAppsTab',
  expandedSearchLinksTab = 'globalSearch_expandedSearchLinksTab',
  expandedSearchChatsTab = 'globalSearch_expandedSearchChatsTab',
  expandedSearchMeetingsTab = 'globalSearch_expandedSearchMeetingsTab',

  expandedSearchAppType = 'globalSearch_expandedSearchAppType',
  expandedSearchLinkType = 'globalSearch_expandedSearchLinkType',
  expandedSearchFolderType = 'globalSearch_expandedSearchFolderType',
  expandedSearchLinkCommentType = 'globalSearch_expandedSearchLinkCommentType',
  expandedSearchChatType = 'globalSearch_expandedSearchChatType',
  expandedSearchConversationType = 'globalSearch_expandedSearchConversationType',
  expandedSearchMeetingType = 'globalSearch_expandedSearchMeetingType',

  expandedSearchChatIn = 'globalSearch_expandedSearchChatIn',

  expandedSearchRecentSearches = 'globalSearch_expandedSearchRecentSearches',
  expandedSearchRecentApps = 'globalSearch_expandedSearchRecentApps',
  expandedSearchFoundApps = 'globalSearch_expandedSearchFoundApps',
  expandedSearchRecentLinks = 'globalSearch_expandedSearchRecentLinks',
  expandedSearchCopyLink = 'globalSearch_expandedSearchCopyLink',
  expandedSearchLinkWasCopied = 'globalSearch_expandedSearchLinkWasCopied',
  expandedSearchLinkDetails = 'globalSearch_expandedSearchLinkDetails',
  expandedSearchOpenLinkLocation = 'globalSearch_expandedSearchOpenLinkLocation',
  expandedSearchOpenAppLocation = 'globalSearch_expandedSearchOpenAppLocation',
  expandedSearchFoundLinks = 'globalSearch_expandedSearchFoundLinks',
  expandedSearchDeletedAccount = 'globalSearch_expandedSearchDeletedAccount',

  closeSearchButton = 'globalSearch_closeSearchButton',
  openSearchButton = 'globalSearch_openSearchButton',

  searchInAppIntegrationButton = 'globalSearch_searchInAppIntegrationButton',
  searchInAppIntegrationEmptyButton = 'globalSearch_searchInAppIntegrationEmptyButton',
  searchInDesktopButton = 'globalSearch_searchInDesktopButton',
  searchFilterAppsText = 'globalSearch_searchFilterAppsText',
  searchFilterDeselectAll = 'globalSearch_searchFilterDeselectAll',
  searchFilterAllAppsTabName = 'globalSearch_searchFilterAllAppsTabName',
  searchManageIntegrations = 'globalSearch_searchManageIntegrations',
  searchResultNoFound = 'globalSearch_searchResultNoFound',
  appIntegrationsNoAccess = 'globalSearch_appIntegrationsNoAccess',

  tooltipAppIntegrations = 'globalSearch_tooltipAppIntegrations',
  tooltipMeetingsTab = 'globalSearch_tooltipMeetingsTab',
  tooltipAppIntegrationsGoBack = 'globalSearch_tooltipAppIntegrationsGoBack',
  tooltipAllAppsTab = 'globalSearch_tooltipAllAppsTab',
  tooltipManageButton = 'globalSearch_tooltipManageButton',

  searchOnlyInSpecificChannel = 'globalSearch_searchOnlyInSpecificChannel',
}

export type GlobalSearchLocale = {
  [key in GlobalSearchTranslation]: string;
};
