import Color from 'color';
import { BasicColors, ThemeType } from '../../domains/Theme';
import { SharedThemesType } from './Shared.theme.types';

export const ImportantInfoMessageBackground = Color.rgb(54, 159, 255);

export const SharedTheme: SharedThemesType = {
  [ThemeType.light]: {
    colors: {
      CountBadge: '#FFF',
      CountBadgeBackground: '#DE3618',

      ImportantInfoMessage: '#3E4155',
      ImportantInfoMessageBackground: ImportantInfoMessageBackground.alpha(0.1)
        .rgb()
        .string(),

      ModalBackdrop: Color('#212b36').alpha(0.5).rgb().string(),

      TimePickerModal: '#F4F6F8',

      Tooltip: '#212B36',

      SelectedItemLight: '#DFF0F9',
    },
  },
  [ThemeType.dark]: {
    colors: {
      CountBadge: '#FFF',
      CountBadgeBackground: '#DE3618',

      ImportantInfoMessage: BasicColors[ThemeType.dark].OnSurface.alpha(0.87)
        .rgb()
        .string(),
      ImportantInfoMessageBackground: ImportantInfoMessageBackground.alpha(0.1)
        .rgb()
        .string(),

      ModalBackdrop: Color('#021121').alpha(0.7).rgb().string(),

      TimePickerModal: '#F4F6F8',

      Tooltip: '#212B36',

      SelectedItemLight: 'rgba(0, 122, 206, 0.5)',
    },
  },
};
