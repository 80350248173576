import React, { FC, useMemo } from 'react';
import {
  AccountApiType,
  AccountWithCountsApiType,
} from '../../User/User.types';
import { AdminList, AdminListEmptyMessage, AdminListItem } from '../index';
import { getAccountName, isAccountAdmin } from '../../User/User.utils';
import { ListItemContent } from '../../../shared/components/List';
import { Avatar } from '../../User/UserAvatar/Avatar';
import { AvatarMode } from '../../User/UserAvatar/Avatar.styled';
import { ListItemMenu } from '../../Desktop/ShareDesktopPopover/MembersList/ListItemMenu';
import { DeleteIcon } from '../../../shared/icons';
import type {
  DesktopAccessType,
  DesktopApiType,
} from '../../Desktop/data/Desktop/types/Desktop.types';
import { DeleteButton } from './RelatedUsersList.styled';
import { AccessApiType } from '../../Access/data/types/Access.types';
import { createAccountAccessMap } from '../../Access/data/utils/Access.utils';

interface RelatedUsersListProps {
  users: AccountApiType[];
  desktopAccesses?: AccessApiType[];
  hideRemoveButtons?: boolean;
  desktop?: DesktopApiType;
  emptyMessage: string;
  onEditAccess?: (accessId: string, newAccessValue: DesktopAccessType) => void;
  onRemove: (user: AccountApiType) => void;
}

export const RelatedUsersList: FC<RelatedUsersListProps> = ({
  emptyMessage,
  users,
  desktopAccesses = [],
  hideRemoveButtons,
  desktop,
  onEditAccess,
  onRemove,
}) => {
  const accountAccessMap = useMemo(() => {
    return createAccountAccessMap(desktopAccesses);
  }, [desktopAccesses]);

  if (!users?.length) {
    return <AdminListEmptyMessage>{emptyMessage}</AdminListEmptyMessage>;
  }

  return (
    <AdminList data-testid="admin-list">
      {users.map(user => (
        <AdminListItem key={user.id}>
          <ListItemContent
            title={getAccountName(user)}
            subtitle={user.email}
            image={
              <Avatar account={user} size={30} mode={AvatarMode.circle} />
            }>
            {!hideRemoveButtons && !accountAccessMap[user.id]?.[0] && (
              <DeleteButton
                icon={DeleteIcon}
                data-testid="delete-button"
                onClick={() => onRemove(user)}
              />
            )}
            {accountAccessMap[user.id]?.[0] && onEditAccess && desktop && (
              <ListItemMenu
                onDelete={hideRemoveButtons ? undefined : () => onRemove(user)}
                onEdit={value =>
                  onEditAccess(accountAccessMap[user.id]?.[0]!.id, value)
                }
                isOnlyFullAccessAllowed={isAccountAdmin(
                  user as AccountWithCountsApiType,
                )}
                accessType={accountAccessMap[user.id]?.[0].accessType}
              />
            )}
          </ListItemContent>
        </AdminListItem>
      ))}
    </AdminList>
  );
};
