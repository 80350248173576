import { LinkApiType } from '../../Link/Link.types';
import { DatabaseTablesType } from '../Database.types';

export const DESKTOP_LINKS_TABLE_COLUMNS =
  '++id, desktopId, workspaceId, accountFavorite, folder, createdAt, payload';

export interface DesktopLinksTableType
  extends Omit<DatabaseTablesType, 'createdAt'> {
  desktopId: string;
  workspaceId: string;
  accountFavorite: boolean;
  folder: string | null;
  createdAt: string;
  sourceData: LinkApiType;
}
