import { ApolloClient } from '@apollo/client';
import delay from 'delay';
import { differenceInMinutes, differenceInSeconds, parseISO } from 'date-fns';
import {
  MERCURE_RESET_STORE_MAX_ATTEMPTS,
  REFRESH_CHAT_DATAMANAGER_AFTER_SLEEP,
} from './GeneralMercure.constants';
import { MERCURE_RETRY_INTERVAL } from '../Mercure.constants';
import { StreamTopic } from '../Mercure.types';
import type { AccountMercureCategoryInfoApiType } from '../../User/User.types';

export const getMercureUrl = (
  mercureCategoryInfo?: AccountMercureCategoryInfoApiType,
): string | null => {
  if (!mercureCategoryInfo || !process.env.MERCURE_URL) {
    return null;
  }

  const url = new URL(process.env.MERCURE_URL);

  Object.keys(mercureCategoryInfo.topics)
    .filter(topic => topic !== StreamTopic.chat)
    .forEach(topic => {
      const topicUrls = mercureCategoryInfo.topics[topic as StreamTopic];
      if (Array.isArray(topicUrls)) {
        topicUrls.forEach(topicUrl => {
          url.searchParams.append('topic', topicUrl);
        });
      } else {
        url.searchParams.append('topic', topicUrls);
      }
    });

  return url.toString();
};

export const resetApolloStore = (() => {
  let resettingStore = false;
  const resetStore = (
    apolloClient: Pick<ApolloClient<any>, 'resetStore'>,
    attempt = 1,
  ): Promise<unknown> => {
    if (attempt > MERCURE_RESET_STORE_MAX_ATTEMPTS) {
      return Promise.resolve();
    }
    return apolloClient.resetStore().catch(e => {
      if (e.networkError) {
        return delay(MERCURE_RETRY_INTERVAL * attempt).then(() =>
          resetStore(apolloClient, attempt + 1),
        );
      }
    });
  };
  return async (apolloClient: Pick<ApolloClient<any>, 'resetStore'>) => {
    if (resettingStore) {
      return;
    }
    resettingStore = true;
    await resetStore(apolloClient);
    resettingStore = false;
  };
})();

const EVENT_FRESHNESS_DEFAULT_THRESHOLD = 30; // In seconds

export const isEventFresh = (
  event: { createdAt: string },
  thresholdInSeconds = EVENT_FRESHNESS_DEFAULT_THRESHOLD,
) =>
  differenceInSeconds(new Date(), parseISO(event.createdAt)) <=
  thresholdInSeconds;

export const isChatMessageListFresh = (initializationTime: string): boolean => {
  if (!initializationTime) {
    return true;
  }

  return !(
    Math.abs(differenceInMinutes(parseISO(initializationTime), new Date())) >=
    REFRESH_CHAT_DATAMANAGER_AFTER_SLEEP
  );
};
