import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { useApolloClient } from '@apollo/client';

import {
  StreamEvent,
  StreamEventActionRequest,
  StreamEventRequest,
  StreamEventType,
} from './GeneralMercure.types';
import { desktopRequest } from './Requests/DesktopRequest';
import { desktopAppRequest } from './Requests/DesktopAppRequest';
import { linkRequest } from './Requests/LinkRequest';
import { folderRequest } from './Requests/FolderRequest';
import { notificationRequest } from './Requests/NotificationRequest';
import { actionRequest } from './Requests/ActionRequest';
import { timelineRequest } from './Requests/TimelineRequest';
import { MERCURE_WORKER_QUEUE_TAG } from './GeneralMercure.constants';
import { useMercureUrl, useNotificationsDelta } from './GeneralMercure.hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useWorkspacesQuery,
} from '../../Workspace/Workspace.hooks';
import { MercureContext, MercureContextType } from './GeneralMercure.context';
import { useMaintenance } from '../../Maintenance/Maintenance.hooks';
import {
  accountWorkspaceCreatedRequest,
  accountWorkspaceRequest,
} from './Requests/AccountWorkspaceRequest';
import { updateDesktopMembers } from './Requests/DesktopAccessRequest';
import { format, fromUnixTime } from 'date-fns';
import { useWorkerQueue } from '../WorkerQueue/WorkerQueue.hooks';
import { FetchPolicyContext } from '../../Api/FetchPolicy/FetchPolicy.context';

import { updateFileAssetInCache } from '../../Asset/cache/FileAsset.cache';

import './GeneralMercureInterceptor';
import { extractNodes } from '../../../shared/api/api.utils';
import { useScheduledDowntime } from '../../ScheduledDowntime/ScheduledDowntime.hooks';
import { useAccountsContext } from '../../Account';
import { useEffectOnce } from 'react-use';
import { favoriteRequest } from './Requests/FavoriteRequest';
import { GeneralMercureClient } from './GeneralMercureClient';
import { MercureClientType, MercureStatus } from '../Mercure.types';
import { useMercureClient } from '../Mercure.hooks';
import { useIsOnline } from '../../Offline';
import { useDesktopsRepository } from '../../Desktop/data/Desktop/Desktop.repositories';
import { useDesktopsActions } from '../../Desktop/data/Desktop/Desktop.actions';
import { useAccountApiRepository } from '../../Account/data/CurrentAccount/CurrentAccount.repositories';
import { useAccountsActions } from '../../Account/data/Account/Account.actions';
import { putDesktopToIDDB } from '../../Desktop/data/Desktop/operations/DesktopIDDB.operations';
import { teamRequest } from './Requests/TeamRequest';
import {
  addWorkspaceTeamAccountActionRequest,
  removeWorkspaceTeamAccountActionRequest,
} from './Requests/ActionRequest/Requests';
import { AccountApiType } from '../../User/User.types';
import { InvitationMercureProvider } from '../../Invitation/providers/InvitationMercure.provider';

export const GeneralMercureProvider: FC<PropsWithChildren> = ({ children }) => {
  const { generalMercureClient, setGeneralMercureClient } = useMercureClient();
  const { workspace } = useCurrentWorkspace();
  const { refetchPermissions } = useCurrentWorkspacePermissions();
  const { refetchAccounts } = useAccountsContext();
  const { updateAccount } = useAccountsActions();

  const {
    account: currentAccount,
    refetchAccountData,
    updateAccount: updateCurrentAccount,
  } = useCurrentAccount();

  const { setMaintenance } = useMaintenance();
  const { setScheduledDowntime } = useScheduledDowntime();
  const useAccountApiRepositoryRef = useRef(useAccountApiRepository());

  const { refetchDesktops } = useDesktopsRepository({
    variables: {
      filterOnlyAccessible: true,
    },
    fetchPolicy: 'cache-only',
  });

  const { updateDesktopInIDDB, removeDesktopByIdInIDDB } = useDesktopsActions();

  const { data: workspacesData, refetch: refetchWorkspaces } =
    useWorkspacesQuery({
      variables: {
        id: currentAccount!.identityId,
      },
    });

  const logMercureEvent = useCallback(
    (message: string, event?: StreamEvent) => {
      if (window.DESKTOPCOM_LOG_MERCURE_EVENTS) {
        const text = `[${format(new Date(), 'hh:mm:ss.SSS')}] ${message}`;
        if (event) {
          console.log(
            `${text}: `,
            event['@type'] + '.' + event['@request'],
            event,
          );
        } else {
          console.log(text);
        }
      }
    },
    [],
  );

  const workspaceIds = useMemo(
    () =>
      extractNodes(workspacesData?.accountIdentity.myAccounts)
        .map(account => extractNodes(account.workspaces))
        .flat()
        .map(identityWorkspace => identityWorkspace.workspace.id),
    [workspacesData],
  );

  const workspaceIdsRef = useRef<string[]>([]);
  workspaceIdsRef.current = workspaceIds;

  const lastEventReceivedAtRef = useRef<string | null>(null);

  const fetchNotificationsDelta = useNotificationsDelta();

  const apolloClient = useApolloClient();
  const { addToQueue, onMessage } = useWorkerQueue();
  const mercureInitializedAt = useRef<string | null>(null);

  const accountRef = useRef<AccountApiType>(currentAccount as AccountApiType);

  const { resetState } = useContext(FetchPolicyContext);

  useEffectOnce(() => {
    mercureInitializedAt.current = new Date().toISOString();
  });

  const listenersRef = useRef<Array<(e: StreamEvent) => void>>([]);

  const contextValue: MercureContextType = useMemo(
    () => ({
      addListener: listener => {
        listenersRef.current = [...listenersRef.current, listener];
      },
      removeListener: listener => {
        listenersRef.current = listenersRef.current.filter(l => l !== listener);
      },
    }),
    [],
  );

  const handleMercureEvent = useCallback(
    (event: StreamEvent) => {
      logMercureEvent('Mercure event processed', event);
      const eventTimestamp = fromUnixTime(event['@timestamp']).toISOString();

      lastEventReceivedAtRef.current = eventTimestamp;
      mercureInitializedAt.current = eventTimestamp;

      switch (event['@type']) {
        case StreamEventType.DESKTOP:
          desktopRequest(event);
          break;
        case StreamEventType.DESKTOP_APP:
          desktopAppRequest(event);
          break;
        case StreamEventType.DESKTOP_ACCESS:
          updateDesktopMembers(event);
          break;
        case StreamEventType.LINK:
          linkRequest(event);
          break;
        case StreamEventType.FOLDER:
          folderRequest(event);
          break;
        case StreamEventType.FAVORITE:
          favoriteRequest(event);
          break;
        case StreamEventType.TEAM:
          teamRequest(event);
          break;
        case StreamEventType.NOTIFICATION:
          if (generalMercureClient?.skipEventsBefore) {
            if (eventTimestamp < generalMercureClient.skipEventsBefore) {
              break;
            } else {
              generalMercureClient.skipEventsBefore = null;
            }
          }
          notificationRequest(
            event,
            updateCurrentAccount,
            apolloClient,
            accountRef.current,
          );
          break;
        case StreamEventType.FILE_ASSET_UPDATE:
          if (event['@request'] === StreamEventRequest.UPDATE) {
            updateFileAssetInCache(apolloClient, event.id, () => ({
              metadata: event.metadata,
            }));
          }
          break;
        case StreamEventType.ACTION:
          if (
            event['@request'] ===
            StreamEventActionRequest.ADD_WORKSPACE_TEAM_ACCOUNT
          ) {
            addWorkspaceTeamAccountActionRequest(event);
            return;
          }
          if (
            event['@request'] ===
            StreamEventActionRequest.REMOVE_WORKSPACE_TEAM_ACCOUNT
          ) {
            removeWorkspaceTeamAccountActionRequest(event);
            return;
          }
          actionRequest(
            event,
            apolloClient,
            refetchPermissions,
            accountRef.current,
            refetchDesktops,
            refetchWorkspaces,
            refetchAccounts,
            refetchAccountData,
            setMaintenance,
            setScheduledDowntime,
            workspace.id,
            updateCurrentAccount,
            useAccountApiRepositoryRef.current,
            updateDesktopInIDDB,
            putDesktopToIDDB,
            removeDesktopByIdInIDDB,
          );
          break;
        case StreamEventType.TIMELINE:
          timelineRequest(event, apolloClient);
          break;
        case StreamEventType.ACCOUNT_WORKSPACE_CREATED:
          accountWorkspaceCreatedRequest(
            event,
            updateDesktopInIDDB,
            refetchAccounts,
          );
          break;
        case StreamEventType.ACCOUNT_WORKSPACE:
          accountWorkspaceRequest(
            event,
            apolloClient,
            updateCurrentAccount,
            updateAccount,
            refetchWorkspaces,
            refetchAccounts,
          );
          break;
        default:
          break;
      }

      listenersRef.current.forEach(listener => {
        listener(event);
      });
    },
    [
      logMercureEvent,
      apolloClient,
      refetchPermissions,
      generalMercureClient,
      updateCurrentAccount,
      updateDesktopInIDDB,
      refetchAccounts,
      refetchWorkspaces,
      refetchDesktops,
      refetchAccountData,
      setMaintenance,
      setScheduledDowntime,
      workspace.id,
      removeDesktopByIdInIDDB,
      updateAccount,
    ],
  );

  useEffect(() => {
    onMessage<StreamEvent>(MERCURE_WORKER_QUEUE_TAG, handleMercureEvent);
  }, [handleMercureEvent, onMessage]);

  const isOnline = useIsOnline();
  const mercureUrl = useMercureUrl();
  const mercureToken = currentAccount?.mercureInfo?.general?.authorization;

  const mercureClientArgs = useMemo(() => {
    if (!mercureUrl || !mercureToken) {
      return null;
    }

    return {
      mercureClientType: MercureClientType.general,
      mercureUrl,
      initialAuthToken: mercureToken,
      workspaceIds,
      fetchNotificationsDelta,
      resetState,
      updateAccount: updateCurrentAccount,
      addToQueue,
      isOnline,
    };
  }, [
    addToQueue,
    fetchNotificationsDelta,
    mercureToken,
    mercureUrl,
    resetState,
    updateCurrentAccount,
    workspaceIds,
    isOnline,
  ]);

  useEffect(() => {
    if (generalMercureClient && mercureClientArgs) {
      generalMercureClient.updateVariables(mercureClientArgs);
    }
  }, [mercureClientArgs, generalMercureClient]);

  useEffect(() => {
    if (!mercureClientArgs || generalMercureClient) {
      return;
    }

    console.log('[General Mercure] settings client');
    setGeneralMercureClient(new GeneralMercureClient({ ...mercureClientArgs }));
  }, [mercureClientArgs, generalMercureClient, setGeneralMercureClient]);

  useEffect(() => {
    if (
      !isOnline &&
      generalMercureClient?.mercureStatus === MercureStatus.connected
    ) {
      console.log('[General Mercure] disconnecting due to offline');
      generalMercureClient.disconnectMercureClient();
    }
  }, [generalMercureClient, isOnline]);

  useEffect(() => {
    if (
      isOnline &&
      generalMercureClient?.mercureStatus === MercureStatus.disconnected
    ) {
      console.log(
        '[General Mercure] reconnecting after returning back to online',
      );
      generalMercureClient.reconnect();
    }
  }, [generalMercureClient, isOnline]);

  return (
    <MercureContext.Provider value={contextValue}>
      <InvitationMercureProvider>{children}</InvitationMercureProvider>
    </MercureContext.Provider>
  );
};
