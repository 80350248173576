import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const PeopleIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.666 12a4.667 4.667 0 1 1-9.333 0 4.667 4.667 0 0 1 9.333 0zm-.808 5.438a6.667 6.667 0 1 0-7.715 0 10.01 10.01 0 0 0-6.082 8.119 1 1 0 0 0 1.988.22 8.001 8.001 0 0 1 15.902 0 1 1 0 1 0 1.988-.22 10.01 10.01 0 0 0-6.081-8.12zm5.475-8.771a1 1 0 1 0 0 2 3.333 3.333 0 0 1 .47 6.634 1 1 0 0 0-.598 1.666c.167.218.421.366.716.39a7.336 7.336 0 0 1 6.693 6.428 1 1 0 1 0 1.986-.237 9.343 9.343 0 0 0-5.757-7.532 5.333 5.333 0 0 0-3.51-9.349z"
        fill="currentColor"
      />
    </svg>
  );
});
