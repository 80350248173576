import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const SubfolderIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46967 10.0303C9.17678 9.73744 9.17678 9.26256 9.46967 8.96967L11.4393 7H5C2.3767 7 0.250003 4.87337 0.250003 2.25V0.750003C0.250003 0.335803 0.585803 2.86102e-06 1 2.86102e-06C1.4142 2.86102e-06 1.75 0.335803 1.75 0.750003V2.25C1.75 4.04493 3.2051 5.5 5 5.5H11.4393L9.46967 3.5303C9.17678 3.2374 9.17678 2.7626 9.46967 2.4697C9.76256 2.1768 10.2374 2.1768 10.5303 2.4697L13.7803 5.71967C14.0732 6.01256 14.0732 6.48744 13.7803 6.78033L10.5303 10.0303C10.2374 10.3232 9.76256 10.3232 9.46967 10.0303Z"
        fill="currentColor"
      />
    </svg>
  );
});
