import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const FluidIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-3.000000, -3.000000)">
          <rect fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
          <rect
            fill="currentColor"
            x="3"
            y="3"
            width="8"
            height="9"
            rx="1.125"></rect>
          <path
            d="M14.125,11 L19.875,11 C20.4963203,11 21,11.5036797 21,12.125 L21,19.875 C21,20.4963203 20.4963203,21 19.875,21 L14.125,21 C13.5036797,21 13,20.4963203 13,19.875 L13,12.125 C13,11.5036797 13.5036797,11 14.125,11 Z"
            fill="currentColor"></path>
          <rect
            fill="currentColor"
            x="3"
            y="14"
            width="8"
            height="7"
            rx="1.14286"></rect>
          <rect
            fill="currentColor"
            x="13"
            y="3"
            width="8"
            height="6"
            rx="1.125"></rect>
        </g>
      </g>
    </svg>
  );
});
