import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';
import { format as formatTime, parseISO } from 'date-fns';
import { AccountApiType } from '../User/User.types';
import { appEnv } from '../../appEnv';

export const getTZOffsetFromTZName = (timeZone: string): string => {
  if (!timeZone) {
    return '';
  }

  return formatTZ(new Date(), 'zzz', { timeZone });
};

export const getInitialTimeZoneOption = () => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const initialTimeZoneOption = {
    value: currentTimeZone,
    label: `${currentTimeZone} ${getTZOffsetFromTZName(
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    )}`,
  };
  return initialTimeZoneOption;
};

export const timezoneStringToReactSelectValue = (tz: string) => {
  return { value: tz, label: `${tz} ${getTZOffsetFromTZName(tz)}` };
};

export const getLocalAccountTime = (account: AccountApiType) => {
  if (!account.timezone) {
    return null;
  }
  const zoneDate = utcToZonedTime(new Date(), account.timezone);
  // TODO: implement usage of account.dateTimeFormat here
  // return formatTime(parseISO(zoneDate.toISOString()), 'HH:mm'); // 24H
  return formatTime(parseISO(zoneDate.toISOString()), appEnv.TIME_FORMAT); // 12H
};

export const minutesToMilliseconds = (minutes: number) =>
  Math.floor(minutes * 60000);

export const secondsToMilliseconds = (seconds: number) =>
  Math.floor(seconds * 1000);
