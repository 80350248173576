import styled, { css } from 'styled-components';

export const SEGMENT_SWITCHER_WIDTH = 64;

export const StyledSegmentSwitcher = styled.div`
  background-color: ${p => p.theme.colors.Background};
  border-right: 1px solid ${p => p.theme.colors.SurfaceBorder};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  width: ${SEGMENT_SWITCHER_WIDTH}px;
  max-width: ${SEGMENT_SWITCHER_WIDTH}px;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  padding: ${({ theme }) => `${theme.spacing.xs} 0`};
  width: 100%;
`;

export const SegmentButton = styled.button.attrs({
  className: 'segment-button',
})<{ isActive: boolean }>`
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: 0;
  background-color: unset;
  width: 40px;
  height: 28px;
  border-radius: 4px;
  color: ${({ theme, isActive }) =>
    isActive
      ? theme.colors.OnSurfaceHighEmphasis
      : theme.colors.OnSurfaceMediumEmphasis};
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.colors.OnSurfacePressed};
    `}

  :disabled {
    opacity: 0.4;
    background-color: unset;
    cursor: not-allowed;
  }
`;

export const SegmentButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 0.2rem;
  padding-bottom: 0.8rem;

  &:hover .segment-button {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  .segment-button-title {
    &:hover {
      .segment-button {
        background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
      }
    }
  }
`;

export const SegmentButtonTitle = styled.span.attrs({
  className: 'segment-button-title',
})<{
  isActive: boolean;
}>`
  ${({ theme }) => theme.fontTemplates.smallSemibold};
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.SegmentTitleColor};
    `}
`;
