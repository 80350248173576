import styled, { css } from 'styled-components';
import React, {
  ChangeEvent,
  ComponentPropsWithRef,
  forwardRef,
  memo,
  useCallback,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

export enum InputSize {
  sm = 'sm',
  md = 'md',
}

const StyledInput = styled.input<{ inputSize: InputSize }>`
  ${({ theme }) => theme.fontTemplates.regular}
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  --webkit-appearance: none;
  background-color: ${p => p.theme.colors.Surface};
  ${p =>
    !(p.type === 'radio' || p.type === 'checkbox') &&
    css`
      outline: 0;
      border: 0.063rem solid ${p.theme.colors.BorderDefault};
    `}

  font-size: 1rem;
  border-radius: 4px;
  padding: 0.625rem 1rem;
  width: ${p =>
    p.type === 'radio' || p.type === 'checkbox' ? 'auto' : '100%'};

  ::placeholder {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.SurfaceSubdued};
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    cursor: not-allowed;
  }

  &:read-only {
    background-color: ${p => p.theme.colors.SurfaceSubdued};
  }

  &:focus {
    ${p =>
      !(p.type === 'radio' || p.type === 'checkbox') &&
      `border: 0.125rem solid ${p.theme.colors.Primary};
      padding: 0.563rem 0.938rem;`}
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px
      ${p => p.theme.colors.BackgroundInputAutofill} inset;
    -webkit-text-fill-color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }

  ${({ inputSize, type, theme }) =>
    inputSize === InputSize.sm &&
    css`
      padding: 0.325rem 0.875rem;
      font-size: 0.875rem;
      line-height: 1.3;

      &:focus {
        ${!(type === 'radio' || type === 'checkbox') &&
        `border: 0.125rem solid ${theme.colors.Primary};
      padding: 0.25rem 0.75rem;`}
      }
    `}
`;

export interface InputProps
  extends Omit<ComponentPropsWithRef<'input'>, 'size'> {
  debounce?: number;
  size?: InputSize;
}

export const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    ({ onChange, debounce, size = InputSize.md, ...inputProps }, ref) => {
      const debouncedCallback = useDebouncedCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(e);
          }
        },
        debounce || 0,
      );

      const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          if (debounce) {
            event.persist();
            debouncedCallback(event);
          } else {
            onChange && onChange(event);
          }
        },
        [debounce, onChange, debouncedCallback],
      );

      return (
        <StyledInput
          ref={ref}
          inputSize={size}
          onChange={handleChange}
          {...inputProps}
        />
      );
    },
  ),
);
