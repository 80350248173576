import {
  NewChatMessageEvent,
  UpdateChatMessageEvent,
} from '../ChatMercure.types';
import {
  ChatConferenceMessageContextApiType,
  ChatMessageApiType,
  ChatTextMessageApiType,
  ChatTextMessageContext,
  DraftChatMessageApiType,
  MessageType,
} from '../../../Chat/Chat.types';
import { GRAPHQL_TYPENAME_CHAT_MESSAGE } from '../../../Chat/Chat.constants';
import {
  AccountApiType,
  UnreadChatMessagesType,
} from '../../../User/User.types';
import { captureException } from '@sentry/react';
import { UseAccountApiRepositoryProps } from '../../../Account/data/CurrentAccount/types/CurrentAccountRepository.types';

const copyChatMessageFields = (
  chatMessage: Pick<
    ChatMessageApiType,
    | 'message'
    | 'createdAt'
    | 'microtimeAt'
    | 'modified'
    | 'context'
    | 'reactions'
    | 'threadMessagesCount'
    | 'parentChatMessageIri'
    | 'seenBy'
    | 'threadMessagesAccounts'
    | 'lastMessageInThreadAt'
  > & { type: MessageType },
) => ({
  __typename: GRAPHQL_TYPENAME_CHAT_MESSAGE,
  message: chatMessage.message,
  createdAt: chatMessage.createdAt,
  microtimeAt: chatMessage.microtimeAt,
  modified: chatMessage.modified,
  type: chatMessage.type,
  context: chatMessage.context,
  reactions: chatMessage.reactions,
  threadMessagesCount: chatMessage.threadMessagesCount,
  parentChatMessageIri: chatMessage.parentChatMessageIri,
  seenBy: chatMessage.seenBy,
  threadMessagesAccounts: chatMessage.threadMessagesAccounts,
  lastMessageInThreadAt: chatMessage.lastMessageInThreadAt,
});

export const makeNewChatMessage = (
  chatMessageEvent: Omit<NewChatMessageEvent, '@request'>,
): ChatMessageApiType =>
  ({
    ...copyChatMessageFields(chatMessageEvent),
    id: chatMessageEvent['@iri'],
    _id: chatMessageEvent.id,
    optimisticId: chatMessageEvent.optimisticId,
    accountIri: chatMessageEvent.accountIri,
  } as ChatMessageApiType);

export const makeUpdatedChatMessage = (
  chatMessageEvent: UpdateChatMessageEvent,
): ChatMessageApiType => ({
  ...makeNewChatMessage(chatMessageEvent),
});

export const makeDraftChatMessage = (
  chatMessageEvent: Omit<NewChatMessageEvent, '@request'>,
): DraftChatMessageApiType => ({
  type: MessageType.Draft,
  message: chatMessageEvent.message || '',
  context: chatMessageEvent.context as ChatTextMessageApiType['context'],
  conversationIri: chatMessageEvent.conversationIri,
});

export const getChatLinkIri = (
  chatMessageContext?:
    | ChatTextMessageContext
    | ChatConferenceMessageContextApiType
    | [],
) =>
  chatMessageContext &&
  'links' in chatMessageContext &&
  chatMessageContext.links &&
  chatMessageContext.links.length === 1 &&
  chatMessageContext.links[0];

export const updateAccountChatMessageUnreads = async (
  accountApiRepository: UseAccountApiRepositoryProps,
  currentAccount: AccountApiType,
  updateAccount: (updater: (account: AccountApiType) => AccountApiType) => void,
  workspaceId?: string | null,
) => {
  const {
    getAccountUnreadChatMessagesByWorkspace,
    getAccountUnreadChatMessages,
  } = accountApiRepository;
  try {
    let unreadChatMessages: UnreadChatMessagesType[] = [];

    const unreadChatMessagesByWorkspace = workspaceId
      ? await getAccountUnreadChatMessagesByWorkspace(workspaceId)
      : null;

    if (!unreadChatMessagesByWorkspace) {
      unreadChatMessages = await getAccountUnreadChatMessages();
    }

    let accountUnreadChatMessages = currentAccount.unreadChatMessages;

    if (unreadChatMessagesByWorkspace) {
      const unreadChatMessageIndex =
        currentAccount?.unreadChatMessages?.findIndex(
          unreadChatMessage =>
            unreadChatMessage.workspace ===
            unreadChatMessagesByWorkspace.workspace,
        );

      if (unreadChatMessageIndex > -1) {
        accountUnreadChatMessages[unreadChatMessageIndex] =
          unreadChatMessagesByWorkspace;
      } else {
        accountUnreadChatMessages.push(unreadChatMessagesByWorkspace);
      }
    }

    const updatedAccountUnreads = unreadChatMessagesByWorkspace
      ? accountUnreadChatMessages
      : unreadChatMessages;

    updateAccount(account => {
      return {
        ...account,
        unreadChatMessages: [...updatedAccountUnreads],
      };
    });
  } catch (error) {
    captureException(error);
  }
};

export const updateAccountUnreadInbox = async (
  accountApiRepository: UseAccountApiRepositoryProps,
  updateAccount: (updater: (account: AccountApiType) => AccountApiType) => void,
) => {
  const { getAccountInboxUnread } = accountApiRepository;
  try {
    const accountInboxUnreadApiResp = await getAccountInboxUnread();

    updateAccount(account => ({
      ...account,
      unreadMentions: accountInboxUnreadApiResp.mentions,
      unreadThreads: accountInboxUnreadApiResp.threads,
    }));
  } catch (error) {
    captureException(error);
  }
};
