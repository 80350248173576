import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DocumentOfficePptxIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="document-office-pptx-icon"
      {...props}>
      <path
        d="m16.7929 1.75h-10.0429c-.27615 0-.5.22385-.5.5v19.5c0 .2761.22385.5.5.5h14.5c.2761 0 .5-.2239.5-.5v-15.0429c0-.1326-.0527-.25978-.1465-.35355l-4.457-4.4571c-.0938-.09377-.221-.14645-.3536-.14645z"
        fill="#fff"
      />
      <path
        clipRule="evenodd"
        d="m6 2.25c0-.41422.33578-.75.75-.75h10.0429c.1989 0 .3897.07902.5303.21967l4.4571 4.4571c.1407.14066.2197.33142.2197.53033v15.0429c0 .4142-.3358.75-.75.75h-14.5c-.41422 0-.75-.3358-.75-.75zm.75-.25c-.13808 0-.25.11192-.25.25v19.5c0 .1381.11192.25.25.25h14.5c.1381 0 .25-.1119.25-.25v-15.0429c0-.06629-.0263-.12988-.0732-.17677l-4.4571-4.4571c-.0469-.0469-.1105-.07323-.1768-.07323z"
        fill="#979593"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m16.5 6.25v-4.5h.5v4.5c0 .13808.1119.25.25.25h4.5v.5h-4.5c-.4142 0-.75-.33578-.75-.75z"
        fill="#979593"
        fillRule="evenodd"
      />
      <path
        d="m15.5 8.5c2.0711 0 3.75 1.6789 3.75 3.75h-3.75z"
        fill="#f5ba9d"
      />
      <path
        d="m19.25 12.75c0 2.5066-2.17 4.5064-4.7339 4.2232-1.9488-.2152-3.5241-1.7905-3.7393-3.7393-.2832-2.5639 1.7166-4.7339 4.2232-4.7339v4.25zm.5 6.25h-3.5-4.25-3.5c-.13805 0-.25.1119-.25.25s.11195.25.25.25h3.5 4.25 3.5c.1381 0 .25-.1119.25-.25s-.1119-.25-.25-.25zm0-1.25h-3.5-4.25-3.5c-.13805 0-.25.1119-.25.25s.11195.25.25.25h3.5 4.25 3.5c.1381 0 .25-.1119.25-.25s-.1119-.25-.25-.25z"
        fill="#c8c6c4"
      />
      <path
        d="m3 18.5h8c.5523 0 1-.4477 1-1v-8c0-.55228-.4477-1-1-1h-8c-.55228 0-1 .44772-1 1v8c0 .5523.44772 1 1 1z"
        fill="#c43e1c"
      />
      <path
        d="m7.46352 10.5c.73885 0 1.30491.1657 1.69738.4962.39273.3308.5891.81.5891 1.4371 0 .4027-.0972.7608-.29132 1.0743-.19413.3137-.47035.5575-.82835.7315-.35803.1739-.77308.2609-1.24468.2609h-1.1359v2.25h-1.24975v-6.25zm-1.21375 3h.99166c.3866 0 .67859-.0873.87627-.2613.19742-.1743.29602-.4288.29602-.7634 0-.6501-.37832-.9753-1.13539-.9753h-1.02856z"
        fill="#fff"
      />
    </svg>
  );
});
