import {
  EventSourceMessage,
  EventStreamContentType,
  fetchEventSource,
} from '../../3rd-party/fetch-event-source';
import {
  LAST_EVENT_ID,
  LAST_EVENT_RECEIVED_AT,
  MERCURE_RETRY_INTERVAL,
  MERCURE_UNAUTHORIZED_ERROR,
} from './Mercure.constants';
import { MercureClientType, MercureStatus } from './Mercure.types';
import { AccountApiType } from '../User/User.types';
import { getCurrentAccountMercureInfoApi } from '../Account/data/CurrentAccount/dataSources/CurrentAccountApi.dataSources';

export interface MercureClientArgs {
  mercureClientType: MercureClientType;
  mercureUrl: string;
  initialAuthToken: string;
  updateAccount: (updater: (account: AccountApiType) => AccountApiType) => void;
  body?: URLSearchParams | undefined;
  isOnline: boolean;
}

export class MercureClient {
  private mercureUrl: string;
  private initialAuthToken: string;

  isOnline: boolean;

  esContoller = new AbortController();

  updateAccount: (updater: (account: AccountApiType) => AccountApiType) => void;

  mercureClientType: MercureClientType;
  mercureStatus: MercureStatus = MercureStatus.disconnected;
  body: URLSearchParams | undefined;

  constructor(props: MercureClientArgs) {
    this.mercureClientType = props.mercureClientType;
    this.mercureUrl = props.mercureUrl;
    this.initialAuthToken = props.initialAuthToken;
    this.updateAccount = props.updateAccount;
    this.isOnline = props.isOnline;
  }

  getMercureUrl = () => {
    return this.mercureUrl;
  };

  getInitialAuthToken = () => {
    return this.initialAuthToken;
  };

  setMercureUrl = (url: string) => {
    this.mercureUrl = url;
  };

  setInitialAuthToken = (token: string) => {
    this.initialAuthToken = token;
  };

  refreshAccountMercureInfo = async () => {
    this.disconnectMercureClient();

    try {
      const { data: mercureInfo } = await getCurrentAccountMercureInfoApi();

      if (mercureInfo) {
        this.updateAccount(account => ({
          ...account,
          mercureInfo,
        }));

        this.initialAuthToken =
          mercureInfo[this.mercureClientType].authorization;
        this.init();
      }
    } catch (err) {
      console.error(err);
      return;
    }
  };

  handleMercureStreamError = (err: Error) => {
    /**
     * If it's a special error triggered by 401 response, then we stop the stream re-connection loop.
     */
    if (err.message === MERCURE_UNAUTHORIZED_ERROR) {
      this.refreshAccountMercureInfo();
      this.mercureStatus = MercureStatus.reconnecting;

      throw new Error(err.message);
    }

    /**
     * In case of any other error we'll just try to re-connect.
     */
    return MERCURE_RETRY_INTERVAL;
  };

  handleMercureStreamClose = () => {
    /**
     * If stream was closed by the server, we're throwing an error.
     * This way it will automatically re-connect.
     */
    this.mercureStatus = MercureStatus.disconnected;
    throw new Error();
  };

  handleMercureStreamOpen = async (response: Response) => {
    /**
     * On 401 response we refetch account to get new token and throw special error.
     */

    if (response.status === 401) {
      this.mercureStatus = MercureStatus.reconnecting;
      this.refreshAccountMercureInfo();
      throw new Error(MERCURE_UNAUTHORIZED_ERROR);
    }

    /**
     * This is a default content type check.
     * Copied from the original source.
     */
    const contentType = response.headers.get('content-type');
    if (contentType !== EventStreamContentType) {
      throw new Error(
        `Expected content-type to be ${EventStreamContentType}, Actual: ${contentType}`,
      );
    }

    this.mercureStatus = MercureStatus.connected;
  };

  set lastEventReceivedAt(timeAt: string) {
    localStorage.setItem(LAST_EVENT_RECEIVED_AT, timeAt);
  }

  get lastEventReceivedAt() {
    return (
      localStorage.getItem(LAST_EVENT_RECEIVED_AT) ?? new Date().toISOString()
    );
  }

  set lastEventId(id: string) {
    localStorage.setItem(LAST_EVENT_ID, id);
  }

  get lastEventId() {
    return localStorage.getItem(LAST_EVENT_ID) ?? '';
  }

  disconnectMercureClient = () => {
    this.esContoller.abort();

    this.mercureStatus = MercureStatus.disconnected;
  };

  onMessageHandler = (e: EventSourceMessage) => {};

  reconnect = () => {};

  init = () => {
    fetchEventSource(this.mercureUrl, {
      disconnectMercureClient: this.disconnectMercureClient,
      mercureClientRequestController: this.esContoller,
      headers: {
        Authorization: `Bearer ${this.initialAuthToken}`,
      },
      onmessage: this.onMessageHandler,
      onopen: this.handleMercureStreamOpen,
      onerror: this.handleMercureStreamError,
      onclose: this.handleMercureStreamClose,
      openWhenHidden: true,
      signal: this.esContoller.signal,
      lastEventId: this.lastEventId,
      body: this.body,
    }).catch(err => {
      this.esContoller.abort();

      console.error(err);
    });

    this.mercureStatus = MercureStatus.connected;
  };
}
