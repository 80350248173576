import { PasswordLocale, PasswordTranslation } from './i18n.types';

export const enPasswordLocale: PasswordLocale = {
  [PasswordTranslation.tooltipCreatePassword]: 'Add or import passwords',
  [PasswordTranslation.passwordLabel]: 'Password',
  [PasswordTranslation.passwordPlaceholder]: 'Enter password...',
  [PasswordTranslation.passwordRequired]: 'Password is required',
  [PasswordTranslation.passwordMinimum]:
    'Password should contain at least 8 characters',
  [PasswordTranslation.passwordContainLetter]:
    'Password should contain at least 1 letter',
  [PasswordTranslation.passwordContainNumber]:
    'Password should contain at least 1 number',
  [PasswordTranslation.repeatPasswordLabel]: 'Confirm password',
  [PasswordTranslation.repeatPasswordPlaceholder]: 'Confirm password',
  [PasswordTranslation.repeatPasswordRequired]: 'Confirm password is required',
  [PasswordTranslation.repeatPasswordMinimum]:
    'Confirm password should contain at least 8 characters',
  [PasswordTranslation.repeatPasswordContainLetter]:
    'Confirm password should contain at least 1 letter',
  [PasswordTranslation.repeatPasswordContainNumber]:
    'Confirm password should contain at least 1 number',
  [PasswordTranslation.repeatPasswordMatch]:
    'Passwords do not match. Please ensure that you enter the same password.',

  // Set password form
  [PasswordTranslation.setPasswordFormSubmitButton]: 'Set password',
  [PasswordTranslation.setPasswordFormSubmitButtonTitle]: 'Continue',
  [PasswordTranslation.setPasswordFormHelpText]:
    'Create a strong password. The password that you choose is used to unlock your Webtop.com Password Manager.' +
    ' <a href="{learnMoreLink}" target="_blank" rel="noopener noreferrer">Learn more</a>',

  // Set passwords modal
  [PasswordTranslation.setPasswordModalHeader]: 'Set account password',

  [PasswordTranslation.profilePasswordImportantMessage]:
    'The password you use to access your Webtop.com account will be used to access your stored app passwords. Make sure to use a strong password and don’t forget it. You may lose access to your stored app passwords if you need to reset your password. ' +
    '<br><a href="{learnMoreLink}" target="_blank" rel="noopener noreferrer">Learn more</a>' +
    '<br><br> If you are an account owner, please note that a password reset will result in the loss of any stored credentials.',
};
