import { database } from '../../../../Database';
import { AccountApiType } from '../../../../User/User.types';
import { CURRENT_ACCOUNT_IDDB_CACHE_ID } from '../CurrentAccount.constants';
import {
  AccountApiTypeToCurrentAccountTableType,
  CurrentAccountTableTypeToAccountApiType,
} from '../utils/CurrentAccountIDDB.utils';

export const updateCurrentAccountIDDB = (
  updater: (account: AccountApiType | null) => AccountApiType | null,
) => {
  database.currentAccount
    .where('id')
    .equals(CURRENT_ACCOUNT_IDDB_CACHE_ID)
    .modify((value, ref) => {
      const account = CurrentAccountTableTypeToAccountApiType(value);
      ref.value = AccountApiTypeToCurrentAccountTableType(
        account ? updater(account) : account,
      );
    });
};
