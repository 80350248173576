import { ReactNode } from 'react';
import { ApiConnection } from '../../shared/api/api.types';

export enum PaymentPlan {
  FREE = 'free',
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
}

export enum PaymentPlanInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export interface PaymentMethodApiType {
  paymentMethodId: string;
  lastFour: string;
  expirationYear: number;
  expirationMonth: number;
  type: string;
  default: boolean;
}

export interface PaymentPlansApiType {
  workspaceType: PaymentPlan;
  amount: number;
  currency: string;
  interval: PaymentPlanInterval;
}

export interface PaymentCouponApiType {
  id: string;
  name: string;
  percentOff: number;
  finishedAt: string;
}

export interface PaymentCustomerApiType {
  id: string;
  billingName: string;
  billingEmail: string;
  country: string;
  city: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
  coupon?: string;
  invoices?: ApiConnection<PaymentMethodInvoicesApiType>;
  vatType?: string;
  vatNumber?: string;
}

export type BillingContactFormValues = Omit<
  PaymentCustomerApiType,
  'id' | 'billingEmail' | 'invoices'
>;

export interface OnboardBillingContactFormValues
  extends BillingContactFormValues {
  name: string;
  email: string;
}

export interface PaymentMethodInvoicesApiType {
  _id: string;
  periodStart: string;
  periodEnd: string;
  amount: number;
  currency: string;
  paymentMethod: string;
  pdf: {
    contentUrl: string;
  };
}

export interface InvoiceTableDataType {
  date: string;
  paymentmethod: ReactNode;
  amount: string;
  pdf: {
    contentUrl: string;
  };
}
