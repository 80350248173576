import { StreamWorkspaceStatisticsUpdateEventAction } from '../../../GeneralMercure.types';
import { getWorkspaceStatisticsApi } from '../../../../../Account/data/Account/dataSources/AccountApi.dataSources';
import { updateAccountsInIDDB } from '../../../../../Account/data/Account/operations/AccountIDDB.operations';

export const workspaceStatisticsUpdate = async (
  event: StreamWorkspaceStatisticsUpdateEventAction,
) => {
  if (event?.['@payload']) {
    const { workspaceId } = event?.['@payload'];
    const response = await getWorkspaceStatisticsApi(workspaceId);
    if (response?.data) {
      updateAccountsInIDDB(workspaceId, account => ({
        ...account,
        workspaceCache: {
          ...(account.workspaceCache || {}),
          [account.id]:
            response?.data?.[account.id] ||
            account.workspaceCache?.[account.id],
        },
      }));
    }
  }
};
