import React, { FC } from 'react';
import { IconProps } from '../../../icons/icon.types';

export const WarningIcon: FC<IconProps> = props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="16" cy="16" r="16" fill="#FCF1CD" />
      <path
        d="M17 21.5C17 22.0523 16.5523 22.5 16 22.5C15.4477 22.5 15 22.0523 15 21.5C15 20.9477 15.4477 20.5 16 20.5C16.5523 20.5 17 20.9477 17 21.5Z"
        fill="#8A6116"
      />
      <path
        d="M16.75 13.25C16.75 12.8358 16.4142 12.5 16 12.5C15.5858 12.5 15.25 12.8358 15.25 13.25V17.75C15.25 18.1642 15.5858 18.5 16 18.5C16.4142 18.5 16.75 18.1642 16.75 17.75V13.25Z"
        fill="#8A6116"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8362 7.24404C14.7989 5.57946 17.2019 5.57946 18.1646 7.24404L27.131 22.7484C28.0948 24.4151 26.8921 26.5 24.9668 26.5H7.03401C5.10871 26.5 3.90599 24.4151 4.86985 22.7484L13.8362 7.24404ZM16.8661 7.99498C16.481 7.32915 15.5198 7.32915 15.1348 7.99498L6.16835 23.4994C5.78281 24.166 6.26389 25 7.03401 25H24.9668C25.7369 25 26.218 24.166 25.8325 23.4994L16.8661 7.99498Z"
        fill="#8A6116"
      />
    </svg>
  );
};
