import { SpecialMention, SpecialMentionType } from './Chat.types';
import { WORKSPACE_PATHNAME } from '../Workspace/Workspace.constants';

export const CHAT_ROOT_SLUG = `chats`;
export const CHAT_ROOT_ROUTE_PATH = `${CHAT_ROOT_SLUG}/*`;
export const CHAT_ROOT_PATHNAME = `${WORKSPACE_PATHNAME}/${CHAT_ROOT_SLUG}`;
export const CHAT_ID_PATHNAME = `${CHAT_ROOT_PATHNAME}/:chatId`;

export const LAST_OPENED_CHAT = 'last_opened_chat';

export const GRAPHQL_TYPENAME_CHAT_CONVERSATION = 'ChatConversation';
export const GRAPHQL_TYPENAME_CHAT_CONVERSATION_EDGE = 'ChatConversationEdge';
export const GRAPHQL_TYPENAME_CHAT_MESSAGE = 'ChatMessage';
export const GRAPHQL_TYPENAME_CHAT_MESSAGE_EDGE = 'ChatMessageEdge';
export const GRAPHQL_TYPENAME_LINK_DATA = 'LinkData';
export const GRAPHQL_TYPENAME_ADD_REACTION_CHAT_MESSAGE_PAYLOAD =
  'addReactionToChatMessagePayload';
export const GRAPHQL_TYPENAME_REMOVE_REACTION_CHAT_MESSAGE_PAYLOAD =
  'removeReactionFromChatMessagePayload';

export const DEFAULT_GROUP_CHAT_NAME = 'Group';

export const REGEX_MESSAGE_LINK =
  /\bhttps?:\/\/[^,\s()<>]+(?:\([\w\d]+\)|([^,[:punct:]\s]|\/?))/gi; // The same as they use on backend;
export const REGEX_MESSAGE_MENTION = /\[@[a-z0-9\-/]+]/g;

export const SPECIAL_MENTION_HERE: SpecialMention = {
  type: SpecialMentionType.Here,
};
export const SPECIAL_MENTION_CHANNEL: SpecialMention = {
  type: SpecialMentionType.Channel,
};

export const MAXIMUM_CHARACTERS_IN_TITLE = 100;
