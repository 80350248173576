import {
  existsInNestedArray,
  mergeWithArray,
} from '../../../shared/utils/list.utils';
import { ApolloClient } from '@apollo/client';
import { GET_TAGS, GetTagsResponse, GetTagsVariables } from '../Link.queries';
import { TagApiType } from '../Link.types';
import {
  GRAPHQL_TYPENAME_TAG_CONNECTION,
  GRAPHQL_TYPENAME_TAG_EDGE,
} from '../Link.constants';

export const addTagToTagsCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetTagsVariables,
  tag: TagApiType,
) => {
  try {
    const tagsCache = apolloClient.readQuery<GetTagsResponse, GetTagsVariables>(
      {
        query: GET_TAGS,
        variables: queryVariables,
      },
    );
    if (!tagsCache) {
      return;
    }

    const alreadyExist = existsInNestedArray(tagsCache, 'tags.edges', {
      node: { id: tag.id },
    });
    if (alreadyExist) {
      return;
    }

    apolloClient.writeQuery({
      query: GET_TAGS,
      variables: queryVariables,
      data: mergeWithArray(tagsCache, {
        tags: {
          edges: [
            {
              __typename: GRAPHQL_TYPENAME_TAG_EDGE,
              node: tag,
            },
          ],
          __typename: GRAPHQL_TYPENAME_TAG_CONNECTION,
        },
      }),
    });
  } catch (e) {
    /* There is no cache */
  }
};
