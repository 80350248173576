import { AccessApiType } from '../types/Access.types';
import { StreamDesktopAccessEvent } from '../../../Mercure/General';
import { getLongId } from '../../../../shared/utils/id';

export const makeAccessFromMercureEvent = (
  event: StreamDesktopAccessEvent,
): AccessApiType => {
  return {
    id: getLongId('desktop-accesses', event.id),
    account: event.account,
    workspaceTeam: event.workspaceTeam,
    accessType: event.accessType,
    desktopId: getLongId('desktops', event.desktop?.id),
    createdAt: event.createdAt,
    modifiedAt: event.modifiedAt,
  };
};
