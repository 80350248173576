import { DesktopLocale, DesktopTranslation } from '../../Desktop/i18n';
import { WorkspaceLocale, WorkspaceTranslation } from '../../Workspace/i18n';
import { UserLocale, UserTranslation } from '../../User/i18n';
import { TeamLocale, TeamTranslation } from '../../Team/i18n';
import { OfflineLocale, OfflineTranslation } from '../../Offline/i18n';
import { AppStoreLocale, AppStoreTranslation } from '../../AppStore/i18n';
import { LinkLocale, LinkTranslation } from '../../Link/i18n';
import { FolderLocale, FolderTranslation } from '../../Folder/i18n';
import { BillingLocale, BillingTranslation } from '../../Billing/i18n';
import { GeneralSettingsTranslation } from '../../GeneralSettings/i18n';
import { GlobalSearchTranslation } from '../../GlobalSearch/i18n';
import {
  NotificationLocale,
  NotificationTranslation,
} from '../../Notifications/i18n';
import { TimelineLocale, TimelineTranslation } from '../../Timeline/i18n';
import { AppRootLocale, AppRootTranslation } from '../../AppRoot/i18n';
import {
  ChromeExtensionLocale,
  ChromeExtensionTranslation,
} from '../../ChromeExtension/i18n';
import { DomainLocale, DomainTranslation } from '../../Domain/i18n';
import { MaintenanceLocale } from '../../Maintenance/i18n';
import { ChatLocale, ChatTranslation } from '../../Chat/i18n';
import { ConferenceLocale, ConferenceTranslation } from '../../Conference/i18n';
import { EncryptionLocale, EncryptionTranslation } from '../../Encryption/i18n';
import { OnboardingLocale, OnboardingTranslation } from '../../Onboarding/i18n';
import { WebPushLocale, WebPushTranslation } from '../../WebPush/i18n';
import {
  DocumentPreviewLocale,
  DocumentPreviewTranslation,
} from '../../Preview/DocumentPreview/i18n';
import { AccountLocale, AccountTranslation } from '../../Account/i18n';
import {
  SubscriptionLocale,
  SubscriptionTranslation,
} from '../../Subscription/i18n';
import {
  ExternalServiceAuthLocale,
  ExternalServiceAuthTranslation,
} from './../../ExternalServiceAuth/ExternalServiceAuthContent/i18n/i18n.types';
import { InboxLocale, InboxTranslation } from '../../Inbox/i18n';
import { PasswordTranslation } from '../../Password/i18n';
import { SegmentTranslation } from '../../Segment/i18n';
import {
  AppIntegrationsLocale,
  AppIntegrationsTranslation,
} from '../../AppIntegrations/i18n';
import { AuthLocale, AuthTranslation } from '../../Auth/i18n';

export type Translation =
  | GlobalTranslation
  | AuthTranslation
  | AppRootTranslation
  | BillingTranslation
  | DesktopTranslation
  | LinkTranslation
  | FolderTranslation
  | WorkspaceTranslation
  | UserTranslation
  | TeamTranslation
  | OfflineTranslation
  | OnboardingTranslation
  | WebPushTranslation
  | AppStoreTranslation
  | GeneralSettingsTranslation
  | GlobalSearchTranslation
  | NotificationTranslation
  | TimelineTranslation
  | ChromeExtensionTranslation
  | DomainTranslation
  | ChatTranslation
  | ConferenceTranslation
  | PasswordTranslation
  | SegmentTranslation
  | EncryptionTranslation
  | DocumentPreviewTranslation
  | AccountTranslation
  | SubscriptionTranslation
  | ExternalServiceAuthTranslation
  | InboxTranslation
  | AppIntegrationsTranslation;

export type Locale = GlobalLocale &
  AuthLocale &
  AppRootLocale &
  DesktopLocale &
  BillingLocale &
  LinkLocale &
  FolderLocale &
  WorkspaceLocale &
  UserLocale &
  TeamLocale &
  OfflineLocale &
  AppStoreLocale &
  NotificationLocale &
  TimelineLocale &
  ChromeExtensionLocale &
  DomainLocale &
  MaintenanceLocale &
  ChatLocale &
  ConferenceLocale &
  OnboardingLocale &
  WebPushLocale &
  EncryptionLocale &
  DocumentPreviewLocale &
  AccountLocale &
  SubscriptionLocale &
  ExternalServiceAuthLocale &
  InboxLocale &
  AppIntegrationsLocale;

export enum GlobalTranslation {
  headTitle = 'headTitle',

  logoAlt = 'logoAlt',

  labelsNoData = 'labelsNoData',
  labelNothingFound = 'labelNothingFound',
  labelSendinvite = 'labelSendinvite',
  labelClose = 'labelClose',
  labelSelect = 'labelSelect',

  searchInputLabel = 'searchInputLabel',
  searchInputPlaceholder = 'searchInputPlaceholder',
  searchInputClearButtonAriaLabel = 'searchInputClearButtonAriaLabel',

  toastSaveMessage = 'toastSaveMessage',

  save = 'save',
  ok = 'ok',
  cancel = 'cancel',
  confirm = 'confirm',

  featureNotAvailable = 'featureNotAvailable',

  loadMoreButton = 'loadMoreButton',

  reloadAppMessage = 'reloadAppMessage',
  reloadAppButton = 'reloadAppButton',

  browserNotFullySupportedTitle = 'browserNotFullySupportedTitle',
  browserNotFullySupportedMessage = 'browserNotFullySupportedMessage',

  inputConfirmationMessage = 'inputConfirmationMessage',
  logOut = 'logOut',

  copyClipboardErrorMessage = 'copyClipboardErrorMessage',
  invalidDateFormat = 'invalidDateFormat',

  checkEmailMessage = 'checkEmailMessage',

  confirmCloseModalOnOutsideClick = 'confirmCloseModalOnOutsideClick',

  genericErrorTitle = 'genericErrorTitle',
  genericErrorMessage = 'genericErrorMessage',
  userPermissionsWasUpdated = 'userPermissionsWasUpdated',

  scheduledDowntimeDateMessage = 'scheduledDowntimeDateMessage',
  scheduledDowntimeMessage = 'scheduledDowntimeMessage',

  uploadFileSizeMessage = 'uploadFileSizeMessage',
}

export type GlobalLocale = {
  [key in GlobalTranslation]: string;
};
