import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DevicesIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.91651 13.5L18.4165 13.5C18.4828 13.5 18.5464 13.4737 18.5933 13.4268C18.6402 13.3799 18.6665 13.3163 18.6665 13.25L18.6665 12.75C18.6665 12.612 18.5545 12.5 18.4165 12.5L1.91651 12.5C1.85021 12.5 1.78661 12.5263 1.73971 12.5732C1.69281 12.6201 1.66651 12.6837 1.66651 12.75L1.66651 13.25C1.66651 13.3163 1.69281 13.3799 1.73971 13.4268C1.78661 13.4737 1.85021 13.5 1.91651 13.5ZM18.4165 15C18.8806 15 19.3258 14.8156 19.6539 14.4874C19.9821 14.1593 20.1665 13.7141 20.1665 13.25L20.1665 12.75C20.1665 11.784 19.3825 11 18.4165 11L2.91651 11C2.45241 11 1.00731 11.1844 0.679106 11.5126C0.350907 11.8407 0.166506 12.2859 0.166506 12.75L0.166506 13.25C0.166506 13.7141 0.350906 14.1592 0.679106 14.4874C1.00731 14.8156 1.45241 15 1.91651 15L18.4165 15Z"
        fill="currentColor"
      />
      <path
        d="M16.6665 11L16.6665 1.75C16.6665 1.612 16.5545 1.5 16.4165 1.5L3.91651 1.5C3.85021 1.5 3.78661 1.5263 3.73971 1.5732C3.69281 1.6201 3.66651 1.6837 3.66651 1.75L3.66651 11L2.16651 11L2.16651 1.75C2.16651 1.2859 2.35091 0.840799 2.67911 0.512598C3.00731 0.184398 3.45241 -1.46119e-06 3.91651 -1.42062e-06L16.4165 -3.27835e-07C16.8806 -2.8726e-07 17.3258 0.184399 17.6539 0.5126C17.9821 0.8408 18.1665 1.2859 18.1665 1.75L18.1665 11L16.6665 11Z"
        fill="currentColor"
      />
    </svg>
  );
});
