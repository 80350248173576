export enum TeamTranslation {
  adminListHeaderTitle = 'team_adminListHeaderTitle',
  adminListAddButton = 'team_adminListAddButton',
  adminListEmptyMessage = 'team_adminListEmptyMessage',
  adminTeamGeneralInfo = 'team_adminTeamGeneralInfo',
  adminTeamEditButton = 'team_adminTeamEditButton',
  adminTeamMembers = 'team_adminTeamMembers',
  adminTeamMembersManageButton = 'team_adminTeamMembersManageButton',
  adminTeamMembersManageModalHeader = 'team_adminTeamMembersManageModalHeader',
  adminTeamMembersEmptyMessage = 'team_adminTeamMembersEmptyMessage',
  adminTeamMembersRemoveConfirmation = 'team_adminTeamMembersRemoveConfirmation',
  adminTeamDesktops = 'team_adminTeamDesktops',
  adminTeamDesktopsEmptyMessage = 'team_adminTeamDesktopsEmptyMessage',
  adminTeamDesktopsManageButton = 'team_adminTeamDesktopsManageButton',
  adminTeamDesktopsRemoveConfirmation = 'team_adminTeamDesktopsRemoveConfirmation',
  adminTeamDesktopsManageModalHeader = 'team_adminTeamDesktopsManageModalHeader',
  adminTeamDangerZone = 'team_adminTeamDangerZone',
  adminTeamDangerZoneDeleteButton = 'team_adminTeamDangerZoneDeleteButton',
  adminTeamDangerZoneDeleteWarning = 'team_adminTeamDangerZoneDeleteWarning',
  adminTeamDangerZoneDeleteConfirmation = 'team_adminTeamDangerZoneDeleteConfirmation',
  adminTeamNotFound = 'team_adminTeamNotFound',

  manageModalUpdateButton = 'team_manageModalUpdateButton',

  createModalHeader = 'team_createModalHeader',
  createSubmitButton = 'team_createSubmitButton',
  createCancelButton = 'team_createCancelButton',
  editModalHeader = 'team_editModalHeader',
  editSubmitButton = 'team_editSubmitButton',

  formNameLabel = 'team_formNameLabel',
  formNamePlaceholder = 'team_formNamePlaceholder',
  formNameRequiredError = 'team_formNameRequiredError',
  formDescriptionLabel = 'team_formDescriptionLabel',
  formDescriptionPlaceholder = 'team_formDescriptionPlaceholder',
}

export type TeamLocale = {
  [key in TeamTranslation]: string;
};
