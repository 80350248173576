export const GRAPHQL_TYPENAME_WORKSPACE = 'Workspace';
export const GRAPHQL_TYPENAME_WORKSPACE_DESKTOP_CONNECTION =
  'DesktopConnection';
export const GRAPHQL_TYPENAME_WORKSPACE_DESKTOP_EDGE = 'DesktopEdge';

export const WORKSPACE_ROOT_SLUG = 'workspace';
export const WORKSPACE_ROOT_PATHNAME = `/${WORKSPACE_ROOT_SLUG}`;
export const WORKSPACE_ID_SLUG = ':workspaceId';
export const WORKSPACE_PATHNAME = `/${WORKSPACE_ROOT_SLUG}/${WORKSPACE_ID_SLUG}`;
export const WORKSPACE_ADMIN_PATHNAME = '/workspace/:workspaceId/admin';
export const WORKSPACE_ADMIN_BILLING_PATHNAME = `${WORKSPACE_PATHNAME}/admin/billing`;
export const APPS_INTEGRATIONS_PATHNAME = `${WORKSPACE_PATHNAME}/admin/apps-integrations`;

export const LAST_OPENED_WORKSPACE_STORAGE_KEY = 'last_opened_workspace';
export const LAST_OPENED_PATH_MAP_STORAGE_KEY = 'last_opened_path';

export const VALID_URL_SLASH_LENGTH = 4;

export const HIDE_NAVIGATION_STORAGE_KEY = 'navigation_expanded';

export const SUCCESS_SERVICE_AUTH = '/success-service-auth';

export const FAILED_SERVICE_AUTH = '/failed-service-auth';
