import React, {
  forwardRef,
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useCallbackRef } from 'use-callback-ref';
import { StyledMenu } from './Menu.styled';
import {
  Event,
  HorizontalAlignment,
  MenuControlsProps,
  MenuProps,
  VerticalAlignment,
} from './Menu.types';
import { useOnClickOutside } from './Menu.hooks';
import { MenuContext } from './Menu.context';
import { getMenuFixedCoordinates } from './Menu.utils';
import { MenuViaPortal } from './MenuViaPortal';

const DEFAULT_WIDTH = 146;
export const MENU_MARGIN = 8;
export const CLOSE_MENU_DELAY = 200; //ms
const ESCAPE_KEY_CODE = 27;

export const Menu = forwardRef<MenuControlsProps, PropsWithChildren<MenuProps>>(
  (
    {
      children,
      trigger,
      width = DEFAULT_WIDTH,
      maxHeight = null,
      alignTop = false,
      firstLevelHidden = false,
      processContextMenuEvent = false,
      onOpen,
      onClose,
      vAlign = VerticalAlignment.BOTTOM,
      hAlign = HorizontalAlignment.LEFT,
      scrollProbability,
      portalContainerId = 'menu',
      viaPortal = true,
      submenuRef,
      ...props
    },
    componentRef,
  ) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [cursorX, setCursorX] = useState<number | undefined>(undefined);
    const [cursorY, setCursorY] = useState<number | undefined>(undefined);
    const [x, setMenuXPosition] = useState<number>(0);
    const [y, setMenuYPosition] = useState<number>(0);

    const menuRefUpdateHandler = () => {
      if (!menuRef.current) {
        return;
      }

      const menuRect = menuRef.current.getBoundingClientRect();
      const triggerRect = trigger.current?.getBoundingClientRect();

      const coordinates = getMenuFixedCoordinates({
        menuRect: menuRect,
        triggerRect: triggerRect,
        vAlign: vAlign,
        hAlign: hAlign,
        cursorX: cursorX,
        cursorY: cursorY,
        clientWidth: document.body.clientWidth,
        clientHeight: document.body.clientHeight,
      });

      setMenuXPosition(coordinates.x);
      setMenuYPosition(coordinates.y);
    };

    const menuRef: RefObject<any> = useCallbackRef(null, () =>
      menuRefUpdateHandler(),
    );

    useImperativeHandle(componentRef, () => ({
      forceClose: closeMenu,
      menuRef: menuRef.current,
    }));

    const openMenu = useCallback(() => {
      setMenuOpen(true);
      if (onOpen) {
        onOpen();
      }
    }, [onOpen]);

    const openMenuFromContext = useCallback(
      (e: Event) => {
        e.preventDefault();
        const cursorX = e.clientX;
        const cursorY = e.clientY;
        setCursorX(cursorX);
        setCursorY(cursorY);
        openMenu();
      },
      [openMenu],
    );

    const closeMenu = useCallback(() => {
      setMenuOpen(false);
      if (onClose) {
        onClose();
      }
    }, [onClose]);

    useOnClickOutside(
      menuRef,
      () => {
        closeMenu();
      },
      submenuRef,
    );

    useEffect(() => {
      if (processContextMenuEvent) return;
      const triggerElement = trigger.current;
      triggerElement?.addEventListener('click', () => openMenu(), false);
      return () => {
        triggerElement?.removeEventListener('click', () => openMenu(), false);
      };
    }, [trigger, openMenu, processContextMenuEvent]);

    useEffect(() => {
      if (!processContextMenuEvent) return;
      const triggerElement = trigger.current;
      triggerElement?.addEventListener(
        'contextmenu',
        openMenuFromContext,
        false,
      );
      return () => {
        triggerElement?.addEventListener(
          'contextmenu',
          openMenuFromContext,
          false,
        );
      };
    }, [trigger, processContextMenuEvent, openMenuFromContext]);

    useEffect(() => {
      window.addEventListener('resize', closeMenu, false);
      return () => {
        window.removeEventListener('resize', closeMenu, false);
      };
    }, [closeMenu]);

    const menuControls = useMemo(
      () => ({
        closeMenu,
      }),
      [closeMenu],
    );

    const closeOnEscapeKeydown = useCallback(
      (event: KeyboardEvent) => {
        if (event.keyCode === ESCAPE_KEY_CODE) {
          if (menuOpen) {
            closeMenu();
          }
        }
      },
      [closeMenu, menuOpen],
    );

    useEffect(() => {
      document.addEventListener('keydown', closeOnEscapeKeydown, false);
      return () =>
        document.removeEventListener('keydown', closeOnEscapeKeydown, false);
    }, [closeOnEscapeKeydown]);

    const menuRenderer = (
      <StyledMenu
        className={menuOpen ? 'menu opened' : 'menu'}
        role="listbox"
        opened={menuOpen}
        x={x}
        y={y}
        alignTop={alignTop}
        width={width}
        maxHeight={maxHeight}
        ref={menuRef}
        firstLevelHidden={firstLevelHidden}
        data-testid="menu"
        scrollProbability={scrollProbability}
        {...props}>
        <MenuContext.Provider value={menuControls}>
          {children}
        </MenuContext.Provider>
      </StyledMenu>
    );

    if (!menuOpen) {
      return null;
    }

    if (!viaPortal) {
      return menuRenderer;
    }

    return (
      <MenuViaPortal portalContainerId={portalContainerId}>
        {menuRenderer}
      </MenuViaPortal>
    );
  },
);
