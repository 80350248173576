import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon';
import { CloseIcon } from '../../icons';
import { Breakpoints } from '../../../domains/Theme';

const StyledModalHeaderColor = styled.div<{
  backgroundColor: string;
  textColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};

  button {
    color: ${({ textColor }) => textColor};
    padding: 0.625rem;

    @media (max-width: ${Breakpoints.lg - 1}px) {
      position: absolute;
      padding: 14px;
      right: 10px;

      svg {
        margin: 0;
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:focus,
    &:active {
      outline: none;
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
    }
  }
`;

const StyledModalHeaderColorData = styled.div`
  max-width: calc(100% - 1rem);
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
`;

export const ModalHeaderColor: FC<
  PropsWithChildren<{
    onRequestClose: () => void;
    backgroundColor: string;
    textColor: string;
  }>
> = ({ children, onRequestClose, backgroundColor, textColor }) => {
  return (
    <StyledModalHeaderColor
      data-testid="modal-header-color"
      backgroundColor={backgroundColor}
      textColor={textColor}>
      <StyledModalHeaderColorData>{children}</StyledModalHeaderColorData>
      <ButtonWithIcon
        icon={CloseIcon}
        onClick={onRequestClose}
        data-testid="modal-close-icon"
      />
    </StyledModalHeaderColor>
  );
};
