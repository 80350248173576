import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DragHandleIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 2.5C1.94036 2.5 2.5 1.94036 2.5 1.25C2.5 0.559644 1.94036 0 1.25 0C0.559644 0 0 0.559644 0 1.25C0 1.94036 0.559644 2.5 1.25 2.5ZM5.75 2.5C6.44036 2.5 7 1.94036 7 1.25C7 0.559644 6.44036 0 5.75 0C5.05964 0 4.5 0.559644 4.5 1.25C4.5 1.94036 5.05964 2.5 5.75 2.5ZM7 6C7 6.69036 6.44036 7.25 5.75 7.25C5.05964 7.25 4.5 6.69036 4.5 6C4.5 5.30964 5.05964 4.75 5.75 4.75C6.44036 4.75 7 5.30964 7 6ZM1.25 7.25C1.94036 7.25 2.5 6.69036 2.5 6C2.5 5.30964 1.94036 4.75 1.25 4.75C0.559644 4.75 0 5.30964 0 6C0 6.69036 0.559644 7.25 1.25 7.25ZM2.5 10.75C2.5 11.4404 1.94036 12 1.25 12C0.559644 12 0 11.4404 0 10.75C0 10.0596 0.559644 9.5 1.25 9.5C1.94036 9.5 2.5 10.0596 2.5 10.75ZM5.75 12C6.44036 12 7 11.4404 7 10.75C7 10.0596 6.44036 9.5 5.75 9.5C5.05964 9.5 4.5 10.0596 4.5 10.75C4.5 11.4404 5.05964 12 5.75 12Z"
      />
    </svg>
  );
});
