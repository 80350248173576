import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const CallIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="call-icon"
      {...props}>
      <path
        d="M7.51799 5.45263L7.51807 5.45261L7.51465 5.44224C7.26206 4.67765 7.125 3.85705 7.125 3C7.125 2.17329 6.45171 1.5 5.625 1.5H3C2.17329 1.5 1.5 2.17329 1.5 3C1.5 10.4567 7.54329 16.5 15 16.5C15.8267 16.5 16.5 15.8267 16.5 15V12.3825C16.5 11.5558 15.8267 10.8825 15 10.8825C14.1513 10.8825 13.3243 10.7457 12.561 10.4939C12.0279 10.3132 11.4303 10.4515 11.0272 10.8547L9.77363 12.1082C8.16218 11.1762 6.81728 9.8353 5.89086 8.2273L7.14533 6.97283C7.56155 6.55661 7.68055 5.96984 7.51799 5.45263Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
});
