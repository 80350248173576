import { SignUpFlowPath, SignUpStep } from './Onboarding.types';
import { WORKSPACE_PATHNAME } from '../Workspace/Workspace.constants';

export const PLAN_PATHNAME = '/plan';
export const REGISTRATION_PATHNAME = '/register';
export const EXTERNAL_REGISTRATION_PATHNAME = '/register-social';
export const REGISTRATION_FLOW_PATHNAME = '/register/:flowIdentifier';
export const ACTIVATE_ACCOUNT_PATHNAME = '/account/activate';
export const VERIFY_EMAIL_ACCOUNT_PATHNAME = '/account/verify-email';
export const USE_CASE_SLUG = `use-case`;
export const USE_CASE_PATHNAME = `${WORKSPACE_PATHNAME}/${USE_CASE_SLUG}`;
export const ABOUT_YOU_SLUG = `about-you`;
export const ABOUT_YOU_PATHNAME = `${WORKSPACE_PATHNAME}/${ABOUT_YOU_SLUG}`;
export const ABOUT_TEAM_SLUG = `about-team`;
export const ABOUT_TEAM_PATHNAME = `${WORKSPACE_PATHNAME}/${ABOUT_TEAM_SLUG}`;
export const ACTIVATE_WORKSPACE_SLUG = `activate`;
export const ACTIVATE_WORKSPACE_PATHNAME = `${WORKSPACE_PATHNAME}/${ACTIVATE_WORKSPACE_SLUG}`;
export const CHOOSE_FEATURE_SLUG = `choose-feature`;
export const CHOOSE_FEATURE_PATHNAME = `${WORKSPACE_PATHNAME}/${CHOOSE_FEATURE_SLUG}`;
export const ADD_CONTENT_SLUG = `add-content`;
export const ADD_CONTENT_PATHNAME = `${WORKSPACE_PATHNAME}/${ADD_CONTENT_SLUG}`;
export const INVITE_USERS_SLUG = `invite-users`;
export const INVITE_USERS_PATHNAME = `${WORKSPACE_PATHNAME}/${INVITE_USERS_SLUG}`;
export const INSTALL_PLUGIN_SLUG = `install-plugin`;
export const INSTALL_PLUGIN_PATHNAME = `${WORKSPACE_PATHNAME}/${INSTALL_PLUGIN_SLUG}`;
export const ONBOARDING_END_SLUG = `complete`;
export const ONBOARDING_END_PATHNAME = `${WORKSPACE_PATHNAME}/${ONBOARDING_END_SLUG}`;
export const INVITATION_PATHNAME = '/account/invitation';
export const REQUEST_RESET_PASSWORD_PATHNAME = '/account/forgot-password';
export const RESET_PASSWORD_PATHNAME = '/account/new-password';
export const LINK_ACCOUNT_PATHNAME = '/account/link';
export const CONFERENCE_PAGE_PATHNAME = '/meet/:roomName';

export const JOIN_CHAT_PATHNAME = '/join-chat';
export const JOIN_CHAT_SUCCESS_PATHNAME = '/join-chat-success';
export const JOIN_CHAT_ERROR_PATHNAME = '/join-chat-error';

export const DROPBOX_ERROR_PAGE = '/dropbox-error-page';

export const DEFAULT_FLOW = SignUpFlowPath.FREE;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_CHARACTER_REGEX = /[a-zA-Zа-яА-Я]+/;
export const PASSWORD_NUMBER_REGEX = /.*[0-9].*/;

export const REQUIRE_MFA_STORAGE_KEY = 'desktopRequireMfa';
export const REQUIRE_MFA_SCOPE = 'require:mfa';
export const TRIGGER_MFA_VERIFICATION = 'triggerMfaVerification';

export const GRAPHQL_TYPENAME_ACCOUNT_LINK_REQUEST = 'AccountLinkRequest';

export const ONBOARDING_TEMPLATES_APPLIED_COOKIE_KEY =
  'desktopcom_onboardingTemplatesApplied';

export const OPEN_QUICK_START_GUIDE_STORAGE_KEY =
  'webtopCom _openQuickStartGuide';

export const SIGN_UP_STEP_COUNTS: { [key: string]: number } = {
  [SignUpStep.use_case]: 1,
  [SignUpStep.about_you]: 2,
  [SignUpStep.about_team]: 3,
  [SignUpStep.activate_workspace]: 4,
  [SignUpStep.choose_feature]: 5,
  [SignUpStep.add_content]: 6,
  [SignUpStep.invite_users]: 7,
};
