import React, { FC } from 'react';
import { ModalAnimation } from '..';
import { Modal, ModalProps } from '../Modal';

interface DrawerModalProps extends ModalProps {}

const DRAWER_Z_INDEX = 10;
const DRAWER_BORDER_RADIUS = 12;

export const DrawerModal: FC<DrawerModalProps> = ({
  children,
  visible,
  onRequestClose,
  width,
}) => {
  return (
    <Modal
      visible={visible}
      animation={ModalAnimation.slideUp}
      fullScreen={true}
      fullHeight={true}
      borderRadius={DRAWER_BORDER_RADIUS}
      containerZIndex={DRAWER_Z_INDEX}
      onRequestClose={onRequestClose}
      width={width}>
      {children}
    </Modal>
  );
};
