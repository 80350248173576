import { WorkspaceEventApiType } from '../../../GeneralMercure.types';
import { ApolloClient } from '@apollo/client';
import { removeWorkspaceFromWorkspacesCache } from '../../../../../Workspace/cache';
import { AccountApiType } from '../../../../../User/User.types';

export const removedFromWorkspace = (
  account: AccountApiType,
  workspaceDeleted: WorkspaceEventApiType,
  apolloClient: ApolloClient<object>,
  refetchPermissions: () => void,
) => {
  removeWorkspaceFromWorkspacesCache(
    apolloClient,
    workspaceDeleted.id,
    account,
  );
  refetchPermissions();
};
