import { gql } from '@apollo/client';
import { GRAPHQL_TYPENAME_MUTATION } from '../../../../../shared/api/api.constants';
import { LinkFragmentFields } from '../../../Link.fragments';
import { LinkApiType } from '../../../Link.types';

export const EDIT_LINK = gql`
  mutation EditLink($input: editLinkInput!) {
    __typename
    editLink(input: $input) {
      link {
        ${LinkFragmentFields}
      }
    }
  }
`;

export interface EditLinkInputVariables {
  id: string;
  name?: string;
  url?: string;
  folder?: string | null;
  tags?: string[] | null;
  desktop?: string | null;
}

export interface EditLinkVariables {
  input: EditLinkInputVariables;
}

export interface EditLinkResponse {
  __typename?: typeof GRAPHQL_TYPENAME_MUTATION;
  editLink: {
    __typename?: 'EditLinkPayload';
    link: LinkApiType;
  };
}
