import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledModalSubHeaderColor = styled.div<{
  backgroundColor: string;
  textColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};

  svg {
    margin-right: 0.5rem;
  }

  button {
    color: ${({ textColor }) => textColor};
  }
`;

export const ModalSubHeaderColor: FC<
  PropsWithChildren<{
    backgroundColor: string;
    textColor: string;
  }>
> = ({ children, backgroundColor, textColor }) => {
  return (
    <StyledModalSubHeaderColor
      data-testid="modal-subheader-color"
      backgroundColor={backgroundColor}
      textColor={textColor}>
      {children}
    </StyledModalSubHeaderColor>
  );
};
