import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DesktopIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="path-1-inside-desktop-1" fill="white">
        <rect y="3" width="13" height="9" rx="1" />
      </mask>
      <rect
        y="3"
        width="13"
        height="9"
        rx="1"
        stroke="currentColor"
        strokeWidth="3"
        mask="url(#path-1-inside-desktop-1)"
      />
      <mask id="path-2-inside-desktop-2" fill="white">
        <rect x="3" width="13" height="9" rx="1" />
      </mask>
      <rect
        x="3"
        width="13"
        height="9"
        rx="1"
        stroke="currentColor"
        strokeWidth="3"
        mask="url(#path-2-inside-desktop-2)"
      />
    </svg>
  );
});
