import styled, { css } from 'styled-components';
import { Anchor } from './Popover.types';
import { getAnchorStyle } from './Popover.utils';

export const PopoverBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
`;

export const ChildrenWrapper = styled.div<{ anchor?: Anchor }>`
  position: absolute;
  ${p =>
    p.anchor
      ? css(getAnchorStyle(p.anchor))
      : css`
          visibility: hidden;
          pointer-events: none;
        `}
`;
