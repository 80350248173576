import { BasicColors, ThemeType } from '../../Theme';
import { OnboardingThemesType } from './Onboarding.theme.types';

export const OnboardingTheme: OnboardingThemesType = {
  [ThemeType.light]: {
    colors: {
      //Onboarding page
      OnboardingPageBackground: '#F4F6F8',
      OnboardingAccountSection: '#454F5B',
      OnboardingStepBackground: '#FFF',
      OnboardingStepsIllustrationBackground: '#454F5B',

      //Onboarding quick tour cards
      OnboardingWatchIntroVideo: '#C05717',
      OnboardingWatchIntroVideoBackground: '#FCEBDB',
      OnboardingInviteUsers: '#00848E',
      OnboardingInviteUsersBackground: '#E0F5F5',
      OnboardingCreateFirstDesktop: '#0D71E7',
      OnboardingCreateFirstDesktopBackground: '#E3F6FF',
      OnboardingQuickImport: '#8A6116',
      OnboardingQuickImportBackground: '#FCF1CD',
      OnboardingFirstCall: '#50248F',
      OnboardingFirstCallBackground: '#E3D0FF',
      OnboardingAddDesktop: '#C05717',
      OnboardingAddDesktopBackground: '#FCEBDB',

      //External providers (Login page)
      ExternalProviderApple: '#000',
      ExternalProviderGoogle: '#FFF',
      ExternalProviderFacebook: '#1877f2',
      ExternalProviderMicrosoft: '#f3f3f3',
    },
  },
  [ThemeType.dark]: {
    colors: {
      OnboardingPageBackground: '#021121',
      OnboardingAccountSection: BasicColors[ThemeType.dark].OnSurface.alpha(
        0.87,
      )
        .rgb()
        .string(),
      OnboardingStepBackground: BasicColors[ThemeType.dark].OnSurface.alpha(
        0.08,
      )
        .rgb()
        .string(),
      OnboardingStepsIllustrationBackground: '#454F5B',
      OnboardingWatchIntroVideo: '#C05717',
      OnboardingWatchIntroVideoBackground: '#FCEBDB',
      OnboardingInviteUsers: '#00848E',
      OnboardingInviteUsersBackground: '#E0F5F5',
      OnboardingCreateFirstDesktop: '#0D71E7',
      OnboardingCreateFirstDesktopBackground: '#E3F6FF',
      OnboardingQuickImport: '#8A6116',
      OnboardingQuickImportBackground: '#FCF1CD',
      OnboardingFirstCall: '#50248F',
      OnboardingFirstCallBackground: '#E3D0FF',
      OnboardingAddDesktop: '#C05717',
      OnboardingAddDesktopBackground: '#FCEBDB',
      ExternalProviderApple: '#000',
      ExternalProviderGoogle: BasicColors[ThemeType.dark].OnSurface.alpha(0.12)
        .rgb()
        .string(),
      ExternalProviderFacebook: '#1877f2',
      ExternalProviderMicrosoft: '#454F5B',
    },
  },
};
