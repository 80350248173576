import {
  StreamEventRequest,
  StreamFavoriteEvent,
} from '../../GeneralMercure.types';
import {
  favoriteAppRequestNew,
  favoriteAppRequestRemove,
  favoriteFolderRequestNew,
  favoriteFolderRequestRemove,
  favoriteLinkRequestNew,
  favoriteLinkRequestRemove,
} from './Requests';
import { DesktopFoldersTableType } from '../../../../Database/DesktopFoldersTable/DesktopFoldersTable';
import { DesktopAppsTableType } from '../../../../Database/DesktopAppsTable/DesktopAppsTable';
import { DesktopLinksTableType } from '../../../../Database/DesktopLinksTable/DesktopLinksTable';
import { makeFavoriteFromEvent } from './handlers';

export enum FAVORITES_TYPES {
  app = 'app',
  link = 'link',
  folder = 'folder',
}

export const favoriteRequest = (event: StreamFavoriteEvent) => {
  const favorite = makeFavoriteFromEvent(event);

  if (!favorite) {
    return null;
  }

  switch (favorite.dataType) {
    case FAVORITES_TYPES.app:
      switch (event['@request']) {
        case StreamEventRequest.NEW:
          favoriteAppRequestNew(favorite.data as DesktopAppsTableType);
          break;
        case StreamEventRequest.REMOVE:
          favoriteAppRequestRemove(favorite.data as DesktopAppsTableType);
          break;
        default:
          break;
      }
      break;
    case FAVORITES_TYPES.link:
      switch (event['@request']) {
        case StreamEventRequest.NEW:
          favoriteLinkRequestNew(favorite.data as DesktopLinksTableType);
          break;
        case StreamEventRequest.REMOVE:
          favoriteLinkRequestRemove(favorite.data as DesktopLinksTableType);
          break;
        default:
          break;
      }
      break;
    case FAVORITES_TYPES.folder:
      switch (event['@request']) {
        case StreamEventRequest.NEW:
          favoriteFolderRequestNew(favorite.data as DesktopFoldersTableType);
          break;
        case StreamEventRequest.REMOVE:
          favoriteFolderRequestRemove(favorite.data as DesktopFoldersTableType);
          break;
        default:
          break;
      }
      break;
  }
};
