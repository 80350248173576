import React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import {
  GetWorkspacesByIdentityResponse,
  GetWorkspacesByIdentityVariables,
  WorkspacePermissionsResponse,
  WorkspacePermissionsVariables,
} from './Workspace.queries';
import type {
  WorkspaceApiType,
  WorkspaceEdgeApiType,
  WorkspacePermissionsApiType,
} from './Workspace.types';
import type { AccountApiType } from '../User/User.types';
import type { DesktopApiType } from '../Desktop/data/Desktop/types/Desktop.types';
import { WorkspaceInfoApiType } from '../WorkspaceInfo/data/WorkspaceInfo/types/WorkspaceInfo.types';

export interface WorkspaceContextType {
  workspace: WorkspaceApiType;
  workspaceInfo: WorkspaceInfoApiType | null;
  refetchWorkspaces: (
    variables?: GetWorkspacesByIdentityVariables | undefined,
  ) => Promise<ApolloQueryResult<GetWorkspacesByIdentityResponse>>;
  permissions: WorkspacePermissionsApiType;
  permissionsLoaded: boolean;
  refetchPermissions: (
    variables?: WorkspacePermissionsVariables | undefined,
  ) => Promise<ApolloQueryResult<WorkspacePermissionsResponse>>;
  lastOpenedDesktop: DesktopApiType | null;
  setLastOpenedDesktop: (desktop: DesktopApiType | null) => void;
  lastOpenedPath: string | null;
  setLastOpenedPath: (path: string | null) => void;

  lastOpenedAdminPath: Map<string, string>;
  setLastOpenedAdminPath: (path: string) => void;

  workspaceAccountMap: { [key: string]: AccountApiType };
  mobileNavigationSidebarIsOpen: boolean;
  mobileNavigationSidebarToggle: () => void;
  mobileNavigationSidebarClose: () => void;
  identityWorkspaces: WorkspaceEdgeApiType[];
}

export const WorkspaceContext = React.createContext<WorkspaceContextType>({
  workspaceAccountMap: {},
  permissions: {},
  permissionsLoaded: false,
} as WorkspaceContextType);

export interface WorkspaceStateContextType {
  privateDesktopsOpen: boolean;
  togglePrivateDesktops: () => void;
  internalDesktopsOpen: boolean;
  toggleInternalDesktops: () => void;
  sharedDesktopsOpen: boolean;
  toggleSharedDesktops: () => void;
  navExpanded: boolean;
  toggleNav: (state: boolean | ((prevState: boolean) => boolean)) => void;
  navExpandedOnHover: boolean;
  toggleNavOnHover: (
    state: boolean | ((prevState: boolean) => boolean),
  ) => void;
  canBeDisplayedInNav: () => boolean;
  showTextLabel: boolean;
}

export const WorkspaceStateContext =
  React.createContext<WorkspaceStateContextType>({
    privateDesktopsOpen: true,
    togglePrivateDesktops: () => {},
    internalDesktopsOpen: true,
    toggleInternalDesktops: () => {},
    sharedDesktopsOpen: true,
    toggleSharedDesktops: () => {},
    navExpanded: false,
    toggleNav: () => {},
    navExpandedOnHover: false,
    toggleNavOnHover: () => {},
    canBeDisplayedInNav: () => false,
    showTextLabel: false,
  });
