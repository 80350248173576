import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ArrowRightIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M9.2197 0.969668C9.5126 0.676777 9.9874 0.676777 10.2803 0.969668L15.5303 6.2197C15.8232 6.5126 15.8232 6.9874 15.5303 7.2803L10.2803 12.5303C9.9874 12.8232 9.5126 12.8232 9.2197 12.5303C8.9268 12.2374 8.9268 11.7626 9.2197 11.4697L13.1893 7.5H0.75C0.33579 7.5 0 7.1642 0 6.75C0 6.3358 0.33579 6 0.75 6H13.1893L9.2197 2.03033C8.9268 1.73744 8.9268 1.26256 9.2197 0.969668Z"
      />
    </svg>
  );
});
