import { ApolloClient } from '@apollo/client';
import { WorkspaceEventApiType } from '../../../GeneralMercure.types';
import { AccountApiType } from '../../../../../User/User.types';
import { addWorkspaceToWorkspacesCache } from '../../../../../Workspace/cache';
import { WorkspaceApiType } from '../../../../../Workspace/Workspace.types';

const makeWorkspace = (
  workspaceCreated: WorkspaceEventApiType,
): WorkspaceApiType => ({
  __typename: workspaceCreated.__typename,
  id: workspaceCreated.id,
  accountSortPosition: 0,
  name: workspaceCreated.name,
  type: workspaceCreated.type,
  description: '',
  image: null,
  contactEmail: '',
  homepageUrl: '',
  mfaRequired: false,
  membersCount: 1,
  billingOk: true,
  billingRequired: false,
  freeTrialDaysLeft: null,
  nextRenewAt: null,
  canceledAt: null,
  isUpgrading: false,
  uri: workspaceCreated.uri,
  organizeAndSearch: true,
  communicate: true,
});

export const addPersonalWorkspace = (
  account: AccountApiType,
  workspaceCreated: WorkspaceEventApiType,
  apolloClient: ApolloClient<object>,
  refetchPermissions: () => void,
) => {
  addWorkspaceToWorkspacesCache(
    apolloClient,
    makeWorkspace(workspaceCreated),
    account,
  );

  refetchPermissions();
};
