import { FolderApiType } from '../../Folder/Folder.types';
import { DatabaseTablesType } from '../Database.types';

export const DESKTOP_FOLDERS_TABLE_COLUMNS =
  '++id, desktopId, workspaceId, accountFavorite, parentId, createdAt, payload';

export interface DesktopFoldersTableType
  extends Omit<DatabaseTablesType, 'createdAt'> {
  desktopId: string;
  workspaceId: string;
  accountFavorite: boolean;
  parentId: string | null;
  createdAt: string;
  sourceData: FolderApiType;
}
