export enum StreamTopic {
  apps = 'topic_apps',
  desktops = 'topic_desktops',
  links = 'topic_links',
  notifications = 'topic_notifications',
  system = 'topic_system',
  chat = 'chat',
  workspaces = 'workspaces',
}

export const enum MercureStatus {
  connected = 'connected',
  disconnected = 'disconnected',
  reconnecting = 'reconnecting',
  error = 'error',
}

export const enum MercureClientType {
  chat = 'chat',
  ping = 'ping',
  general = 'general',
}
