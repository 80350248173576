import { gql } from '@apollo/client';

export const DELETE_LINK = gql`
  mutation DeleteLink($input: deleteLinkInput!) {
    deleteLink(input: $input) {
      link {
        id
      }
    }
  }
`;

export interface DeleteLinkVariables {
  input: {
    id: string;
  };
}

export interface DeleteLinkResponse {
  deleteLink: {
    link: {
      id: string;
    };
  };
}

export const BULK_DELETE_LINK = gql`
  mutation BulkDeleteLink($input: bulkDeleteLinkInput!) {
    bulkDeleteLink(input: $input) {
      clientMutationId
    }
  }
`;

export interface BulkDeleteLinkVariables {
  input: {
    links: string[];
  };
}

export interface BulkDeleteLinkResponse {}
