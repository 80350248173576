import type {
  UnreadChatMessagesConversationType,
  UnreadChatMessagesType,
  UnreadNotificationsCountApiType,
} from '../User/User.types';
import { UnreadItemsMap } from './Counters.types';

export const normalizeUnreadItems = <T>(unreadItems?: T[]): T[] =>
  unreadItems ?? [];

export const normalizeUnreadItemsCount = (
  item?: UnreadChatMessagesConversationType | number,
) => (typeof item !== 'number' ? (item?.redCount as number) : item) || 0;

export const getUnreadItemsMap = (
  unreadItems: UnreadNotificationsCountApiType[],
): UnreadItemsMap =>
  unreadItems.reduce(
    (acc, item) => ({ ...acc, [item.workspace]: item.count }),
    {},
  );

export const getUnreadMessagesMap = (
  unreadItems: UnreadChatMessagesType[],
): UnreadChatMessagesConversationType =>
  unreadItems.reduce(
    (acc, item) => ({
      ...acc,
      [item.workspace]: {
        ...item,
        count: item.count,
      },
    }),
    {},
  );
export const getUnreadConversationsMap = (
  unreadItems: UnreadChatMessagesType[],
): UnreadItemsMap =>
  unreadItems.reduce(
    (acc, item) => ({
      ...acc,
      ...item.chatConversations,
    }),
    {},
  );

export const updateUnreadItems = (
  unreadItems: UnreadNotificationsCountApiType[] | undefined,
  workspaceId: string,
  count: number | ((prevValue: number) => number),
) => {
  let normalizedUnreadItems = normalizeUnreadItems(unreadItems);
  if (
    !normalizedUnreadItems.some(({ workspace }) => workspace === workspaceId)
  ) {
    normalizedUnreadItems = [
      ...normalizedUnreadItems,
      {
        workspace: workspaceId,
        count: 0,
      },
    ];
  }
  return normalizedUnreadItems.map(item =>
    item.workspace === workspaceId
      ? {
          ...item,
          count: typeof count === 'number' ? count : count(item.count),
        }
      : item,
  );
};

export const mergeUnreadItems = (
  currentUnreadItems?: UnreadChatMessagesType[],
  newUnreadItems?: UnreadChatMessagesType[],
): UnreadChatMessagesType[] => {
  if (!newUnreadItems) {
    return currentUnreadItems ?? [];
  }
  return newUnreadItems.reduce<UnreadChatMessagesType[]>(
    (acc, newItem) => {
      return acc.map(currentItem => {
        if (currentItem.workspace !== newItem.workspace) {
          return currentItem;
        }
        if (typeof newItem.chatConversations === 'undefined') {
          return newItem as UnreadNotificationsCountApiType;
        }
        return {
          ...newItem,
          chatConversations: !('length' in newItem.chatConversations)
            ? newItem.chatConversations
            : {},
        };
      });
    },
    currentUnreadItems ? [...currentUnreadItems] : [],
  );
};
