export enum ExternalServiceAuthTranslation {
  signInWithGoogle = 'externalServiceAuth_signInWithGoogle',
  signInWithGoogleDescription = 'externalServiceAuth_signInWithGoogleDescription',
  signInWindowTitle = 'externalServiceAuth_signInWindowTitle',
  authorizationFailedHeadTitle = 'externalServiceAuth_authorizationFailedHeadTitle',
  externalAuthServiceTitle = 'externalServiceAuth_externalAuthServiceTitle',
  externalAuthServiceDescription = 'externalServiceAuth_externalAuthServiceDescription',
  externalAuthServiceGoBackButton = 'externalServiceAuth_externalAuthServiceGoBackButton',
  externalAuthNotSupportedTitle = 'externalServiceAuth_externalAuthNotSupportedTitle',
  externalAuthNotSupportedButton = 'externalServiceAuth_externalAuthNotSupportedButton',
}

export type ExternalServiceAuthLocale = {
  [key in ExternalServiceAuthTranslation]: string;
};
