import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useCallback,
  useState,
} from 'react';
import { MenuPopoverContext } from './MenuPopover.context';
import { MenuPopover } from './MenuPopover';

export const MenuPopoverProvider: FC<PropsWithChildren> = ({ children }) => {
  const [popoverData, setPopoverData] = useState<{
    menu: ReactNode;
    domRef: RefObject<HTMLElement>;
  }>();

  const showMenuPopover = useCallback(
    (menu: ReactNode, domRef: RefObject<HTMLElement>) => {
      setPopoverData({
        menu,
        domRef,
      });
    },
    [],
  );

  const hideMenuPopover = useCallback(() => {
    setPopoverData(undefined);
  }, []);

  return (
    <MenuPopoverContext.Provider value={{ showMenuPopover, hideMenuPopover }}>
      {children}

      {popoverData && (
        <MenuPopover
          menu={popoverData.menu}
          triggerRef={popoverData.domRef}
          onRequestClose={hideMenuPopover}
        />
      )}
    </MenuPopoverContext.Provider>
  );
};
