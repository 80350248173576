import { FolderLocale, FolderTranslation } from './i18n.types';

export const enFolderLocale: FolderLocale = {
  [FolderTranslation.folderFormNameLabel]: 'Name',
  [FolderTranslation.folderFormNamePlaceholder]: ' ',
  [FolderTranslation.folderFormNameRequired]: 'Folder name is required',
  [FolderTranslation.folderFormsCreateButton]: 'Create',
  [FolderTranslation.folderFormsSaveButton]: 'Update',
  [FolderTranslation.folderFormCancelButton]: 'Cancel',
  [FolderTranslation.folderMarkAsFavorite]: 'Mark as favorite',
  [FolderTranslation.folderUnMarkAsFavorite]: 'Unmark as favorite',

  [FolderTranslation.createFolderModalHeader]: 'Add new folder to',
  [FolderTranslation.createFolder]: 'Add folder',
  [FolderTranslation.editFolderModalHeader]: 'Edit folder',
  [FolderTranslation.folderNotFound]: 'Folder not found',

  [FolderTranslation.linksViewEditFolder]: 'Rename folder',
  [FolderTranslation.linksViewRemoveFolder]: 'Remove folder',
  [FolderTranslation.linksViewRemoveFolderConfirmation]:
    'Do you want to remove this folder?',

  [FolderTranslation.emptyFolderMessage]:
    'There is no content in this folder yet.',

  [FolderTranslation.folderAlreadyExistInDesktop]:
    'A folder with the name <strong>{folderName}</strong> already exists',
  [FolderTranslation.folderLimitDesktopReached]:
    'Unable to add the folder <strong>{folderName}</strong>. Limit of max folders per desktop is reached.',

  [FolderTranslation.subfolderCount]:
    '{subfolderCount} {subfolderCount, plural, one {Subfolder} other {Subfolders}}',
  [FolderTranslation.subfolderLinksCount]:
    '{subfolderLinksCount} {subfolderLinksCount, plural, one {Link} other {Links}}',

  [FolderTranslation.desktopRootFolderLabel]: 'Home folder',

  [FolderTranslation.tooltipFolderDotsButton]: 'Folder options',
};
