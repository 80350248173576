import {
  AppIntegrationsLocale,
  AppIntegrationsTranslation,
} from './i18n.types';

export const enAppIntegrationsLocale: AppIntegrationsLocale = {
  [AppIntegrationsTranslation.appIntegrationCustomAppsTabFilter]: 'Custom apps',
  [AppIntegrationsTranslation.appIntegrationPersonalTitle]: 'Personal',
  [AppIntegrationsTranslation.appIntegrationWorkspaceTitle]: 'Workspace',
  [AppIntegrationsTranslation.appIntegrationTitle]: 'App Integrations',
  [AppIntegrationsTranslation.appIntegrationAvailable]:
    'Available integrations',
  [AppIntegrationsTranslation.appIntegrationCustomTitle]: 'Custom integrations',
  [AppIntegrationsTranslation.appIntegrationCustomLearnMoreLink]:
    'Create a Webtop.com integration using incoming webhooks. <a href="{documentationLink}" rel="noopener noreferrer" target="_blank">Go to documentation</a>',
  [AppIntegrationsTranslation.appIntegrationWorkspaceDisabledText]:
    'Workspace integrations can be done only by the owner or admin',
  [AppIntegrationsTranslation.appIntegrationPersonalDisabledText]:
    'You have no permissions to add Personal integrations',
  [AppIntegrationsTranslation.appIntegrationCustomDisabledText]:
    'Custom integrations can be added only by the owner or admin',
  [AppIntegrationsTranslation.appIntegrationCreateNew]:
    'Create new integration',
  [AppIntegrationsTranslation.appIntegrationViewButton]: 'View',
  [AppIntegrationsTranslation.appIntegrationDeleteButton]: 'Delete',
  [AppIntegrationsTranslation.appIntegrationsNotAvailable]:
    'Please use browser to configure app integrations',
  [AppIntegrationsTranslation.appIntegrationsOpenInBrowser]: 'Open in browser',
  [AppIntegrationsTranslation.appIntegrationViewMarketplaceCategory]: 'View',
  [AppIntegrationsTranslation.appIntegrationConfigureApp]: 'Configure',
  [AppIntegrationsTranslation.appIntegrationConfiguration]: 'Configuration',
  [AppIntegrationsTranslation.appIntegrationDeleteIntegration]:
    'Delete integration',
  [AppIntegrationsTranslation.appCustomIntegrationDefaultTitle]:
    'Desktop integration',
  [AppIntegrationsTranslation.appCustomIntegrationNameLabel]:
    'Integration name',
  [AppIntegrationsTranslation.appCustomIntegrationNamePlaceholder]:
    'Enter integration name',
  [AppIntegrationsTranslation.appCustomIntegrationClientIdLabel]: 'Client ID',
  [AppIntegrationsTranslation.appCustomIntegrationSecretLabel]: 'Secret',
  [AppIntegrationsTranslation.appCustomIntegrationBotNameLabel]: 'Bot name',
  [AppIntegrationsTranslation.appCustomIntegrationBotNamePlaceholder]:
    'Enter bot name',
  [AppIntegrationsTranslation.appCustomIntegrationBotImageLabel]: 'Bot icon',
  [AppIntegrationsTranslation.appCustomIntegrationBotImageButton]:
    'Upload icon',
  [AppIntegrationsTranslation.appCustomIntegrationAddNewHookLabel]:
    'Add new hook',
  [AppIntegrationsTranslation.appCustomIntegrationHookIdLabel]: 'Hook ID',
  [AppIntegrationsTranslation.appCustomIntegrationDesktopLabel]: 'Desktop',
  [AppIntegrationsTranslation.appCustomIntegrationDesktopPlaceholder]:
    'Select a desktop',
  [AppIntegrationsTranslation.appCustomIntegrationAddLabel]: 'Add new custom',
  [AppIntegrationsTranslation.appCustomIntegrationPropertiesTab]: 'Properties',
  [AppIntegrationsTranslation.appCustomIntegrationBotTab]: 'Bot',
  [AppIntegrationsTranslation.appCustomIntegrationHooksTab]: 'Hooks',
  [AppIntegrationsTranslation.appCustomIntegrationSaveButton]: 'Save',
  [AppIntegrationsTranslation.appCustomIntegrationCancelButton]: 'Cancel',
  [AppIntegrationsTranslation.appCustomIntegrationShowSecret]: 'Show secret',
  [AppIntegrationsTranslation.appCustomIntegrationHideSecret]: 'Hide secret',
  [AppIntegrationsTranslation.appCustomIntegrationNameUpdated]:
    'Integration name has been successfully updated',
  [AppIntegrationsTranslation.appCustomIntegrationBotUpdated]:
    'Integration bot information has been successfully updated',
  [AppIntegrationsTranslation.appCustomIntegrationAddNewLabel]:
    'Add new integration',
  [AppIntegrationsTranslation.appCustomIntegrationNameRequired]:
    'Integration name is required',
  [AppIntegrationsTranslation.appCustomIntegrationBotNameRequired]:
    'Bot name is required',
  [AppIntegrationsTranslation.appCustomIntegrationDocumentationLink]:
    'Go to documentation',
  [AppIntegrationsTranslation.deleteCustomIntegrationConfirmation]:
    'Are you sure you want to delete this integration?',
  [AppIntegrationsTranslation.deleteCustomIntegrationHookConfirmation]:
    'Are you sure you want to delete this hook?',
  [AppIntegrationsTranslation.backToAppIntegrationsPage]: 'Back',
  [AppIntegrationsTranslation.backToAppIntegrationsLoginButton]: 'Login',
  [AppIntegrationsTranslation.backToAppIntegrationsInstallButton]: 'Install',
  [AppIntegrationsTranslation.successDeletedAppIntegration]:
    'App integration was deleted',
  [AppIntegrationsTranslation.appIntegrationCreateCredentialEmail]: 'Email',
  [AppIntegrationsTranslation.appIntegrationCreateCredentialPassword]:
    'Password',
  [AppIntegrationsTranslation.appIntegrationCreateCredentialDomain]: 'Domain',
  [AppIntegrationsTranslation.appIntegrationCreateCredentialEmailPlaceholder]:
    'Enter email name',
  [AppIntegrationsTranslation.appIntegrationCreateCredentialPasswordPlaceholder]:
    'Enter password',
  [AppIntegrationsTranslation.appIntegrationCreateCredentialDomainPlaceholder]:
    'Enter domain',
  [AppIntegrationsTranslation.createAppIntegrationCredentialModalTitle]:
    'Login to the app integration',
  [AppIntegrationsTranslation.createAppIntegrationCredentialUrlIsNotValid]:
    'Url is not valid',
  [AppIntegrationsTranslation.createAppIntegrationCredentialUsernameRequired]:
    'User name is required',
  [AppIntegrationsTranslation.createAppIntegrationCredentialPasswordRequired]:
    'Password is required',
  [AppIntegrationsTranslation.createAppIntegrationCredentialUrlRequired]:
    'Url is required',
  [AppIntegrationsTranslation.createAppIntegrationCredentialSave]: 'Save',
  [AppIntegrationsTranslation.createAppIntegrationCredentialCancel]: 'Cancel',
  [AppIntegrationsTranslation.createAppIntegrationSuccessInstalledMessage]:
    'App integration was successfully installed',
  [AppIntegrationsTranslation.createAppIntegrationCredentialJiraLink]:
    'Do not use your private Jira password. Instead you have to create an API password - <a href="{jiraLink}" rel="noopener noreferrer" target="_blank">click here</a>',
};
