import {
  StreamEventRequest,
  StreamTimelineEvent,
} from '../../GeneralMercure.types';
import { ApolloClient } from '@apollo/client';
import { timelineRequestNew } from './Requests/TimelineRequestNew';

export const timelineRequest = (
  event: StreamTimelineEvent,
  apolloClient: ApolloClient<object>,
) => {
  if (event['@request'] === StreamEventRequest.NEW && event.id) {
    const {
      '@class': className,
      '@iri': iri,
      '@request': request,
      '@type': type,
      ...eventData
    } = event;

    timelineRequestNew(eventData as StreamTimelineEvent, apolloClient);
  }
};
