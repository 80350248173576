export const LOGIN_PATHNAME = '/login';
export const HANDLE_LOGIN_PATHNAME = '/handle_login';
export const LOGOUT_PATHNAME = '/logout';
export const RESET_MFA_PATHNAME = '/account/reset-mfa';

export const AUTH0_REDIRECT_URI = `${window.location.origin}${HANDLE_LOGIN_PATHNAME}`;

export const AUTH0_RETURN_URI_HOST_STANDALONE = window.location.origin;

export const SESSION_INSTANCE_ID = 'sessionInstanceId';
export const MFA_INVALID_ERROR_TEXT = 'app.error.mfa_invalid';

export const MFA_SESSION_STARTED_STORAGE_KEY = 'desktopCom_MfaSessionStarted';
export const MFA_SESSION_EXPIRED_TIME = 28 * 24 * 60 * 60 * 1000; // 28 days
export const MFA_SESSION_EXPIRED_TIME_WITH_OFFSET = 45 * 24 * 60 * 60 * 1000; // 45 days
