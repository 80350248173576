import { UserLocale, UserTranslation } from './i18n.types';

export const enUserLocale: UserLocale = {
  [UserTranslation.workspace_guest]: 'Guest',
  [UserTranslation.workspace_member]: 'Member',
  [UserTranslation.workspace_admin]: 'Admin',
  [UserTranslation.workspace_owner]: 'Owner',
  [UserTranslation.workspace_pending]: 'Pending',
  [UserTranslation.workspace_suspended]: 'Suspended',
  [UserTranslation.adminListHeaderTitle]:
    'Members and guests <span class="count">{count}</span>',
  [UserTranslation.adminSingleUserHeaderTitle]: 'Users',
  [UserTranslation.adminListInviteUserButton]: 'Invite members',
  [UserTranslation.adminListEmptyMessage]: 'No users to display',
  [UserTranslation.adminListPendingInvitesHeader]: 'Pending invitations',
  [UserTranslation.adminListPendingEmptyMessage]:
    'There are no pending invitations',
  [UserTranslation.adminListPendingUserExpires]: 'Expires {time}',
  [UserTranslation.adminListPendingUserExpired]: 'Expired {time}',
  [UserTranslation.adminAccountInvitationEmail]: 'Invited: {email}',
  [UserTranslation.adminAccountInvitationCreatedAt]: 'Created {when}',
  [UserTranslation.adminAccountInvitationExpiresAt]:
    'Expires {when} ({timeleft})',
  [UserTranslation.adminAccountInvitationExpiredAt]:
    'Expired {when} ({timeleft})',
  [UserTranslation.adminAccountInvitationSpots]:
    'Accepted invites {taken} / {available}',
  [UserTranslation.adminAccountInvitationAssignedRole]: 'Assigned role: ',
  [UserTranslation.adminAccountInvitationUrlHeader]: 'Invitation URL ',
  [UserTranslation.adminAccountInvitationResend]: 'Resend to {email}',
  [UserTranslation.adminAccountInvitationResendSuccess]:
    'Invitation sent to {email}',
  [UserTranslation.adminListInvitationHeaderTitle]: 'Invitation',
  [UserTranslation.adminListTeam]: 'Group',
  [UserTranslation.adminListTeams]: 'Groups',
  [UserTranslation.adminListDesktop]: 'Desktop',
  [UserTranslation.adminListDesktops]: 'Desktops',
  [UserTranslation.adminGeneralInfo]: 'General information',
  [UserTranslation.adminGeneralInfoEdit]: 'Change user role',
  [UserTranslation.adminDesktopsHeader]: 'Desktops',
  [UserTranslation.adminManageDesktopsButton]: 'Manage',
  [UserTranslation.adminDesktopsEmptyMessage]:
    'This user doesn’t have access to any workspace desktops',
  [UserTranslation.adminTeamsHeader]: 'Group',
  [UserTranslation.adminManageTeamsButton]: 'Manage',
  [UserTranslation.adminTeamsEmptyMessage]:
    'This user doesn’t belong to any group',
  [UserTranslation.adminDangerZone]: 'Danger zone',
  [UserTranslation.adminDangerZoneSuspendButton]: 'Suspend user',
  [UserTranslation.adminDangerZoneSuspendWarning]:
    'Suspending a user removes its access to your Workspace. It is possible to recover suspended users.',
  [UserTranslation.adminDangerZoneSuspendConfirmTitle]: 'Suspend user',
  [UserTranslation.adminDangerZoneSuspendConfirm]:
    'Are you sure you want to suspend {name}?',
  [UserTranslation.adminDangerZoneSuspendSuccess]: '{name} is suspended',
  [UserTranslation.adminDangerZoneActivateButton]: 'Activate user',
  [UserTranslation.adminDangerZoneActivateConfirmTitle]: 'Activate user',
  [UserTranslation.adminDangerZoneActivateConfirm]:
    'Are you sure you want to activate {name}?',
  [UserTranslation.adminDangerZoneActivateSuccess]: '{name} is activated',
  [UserTranslation.adminDangerZoneMakeOwnerButton]: 'Make owner',
  [UserTranslation.adminDangerZoneMakeOwnerConfirmButton]: 'Make owner',
  [UserTranslation.adminDangerZoneMakeOwnerNoKeysetErrorMessage]:
    'Workspace ownership transfer is not possible for this user right now due to inactive Password manager feature (required for credentials access restoring). User should activate it by changing his password.',
  [UserTranslation.adminDangerZoneMakeOwnerWarning]:
    'Change the ownership of the account to this user',
  [UserTranslation.adminDangerZoneMakeOwnerSuccess]:
    'Owner has been successfully changed',
  [UserTranslation.adminDangerZoneRemoveUserSuccess]: '{user} was deleted',
  [UserTranslation.adminDangerZoneRemoveUserModalMessage]:
    'Are you sure you want to delete {user}?',
  [UserTranslation.adminDangerZoneResetMfaModalMessage]:
    'Are you sure you want to reset MFA?',
  [UserTranslation.adminDangerZoneResetMfaModalHeader]: 'Reset MFA',
  [UserTranslation.adminDangerZoneResetMfaSuccessMessage]:
    'MFA reset email has been sent to the user',
  [UserTranslation.adminDangerZoneRemoveUserModalHeader]: 'Delete user',
  [UserTranslation.adminDangerZoneRemoveUserButton]: 'Delete user',
  [UserTranslation.adminDangerZoneRemoveUserWarning]:
    'Deleting a user cannot be reversed',
  [UserTranslation.adminDangerZoneResetMfaButton]: 'Reset MFA',
  [UserTranslation.adminDangerZoneResetMfaWarning]:
    'Reset multi factor authentication',
  [UserTranslation.adminDangerZoneRevokeButton]: 'Revoke invitation',
  [UserTranslation.adminDangerZoneRevokeWarning]: 'Invalidates the invitation',
  [UserTranslation.adminDangerZoneRevokeConfirm]:
    'Are you sure you want to revoke this invitation?',
  [UserTranslation.adminDangerZoneRevokeSuccess]: 'Invitation was revoked',

  [UserTranslation.adminDangerZoneMakeOwnerModalHeader]:
    'Confirm change of ownership',
  [UserTranslation.adminDangerZoneMakeOwnerModalWarning]:
    'By doing so, you’ll become an Admin and your rights will be more limited.',
  [UserTranslation.adminDangerZoneMakeOwnerModalMessage]:
    'Are you sure that you want to transfer the ownership of the {workspaceName} to {user}?',

  [UserTranslation.editModalHeader]: 'Edit user information',
  [UserTranslation.adminUserFormRoleLabel]: 'Role',
  [UserTranslation.adminUserFormCancelButton]: 'Cancel',
  [UserTranslation.adminUserFormSubmitButton]: 'Update',
  [UserTranslation.adminUserTeamRemoveConfirmation]:
    'Remove this group from related list',
  [UserTranslation.adminTeamsManageModalHeader]: 'Manage groups',
  [UserTranslation.manageModalUpdateButton]: 'Update members',
  [UserTranslation.adminUserDesktopsManageModalHeader]: 'Manage desktops',
  [UserTranslation.adminUserDesktopsRemoveConfirmation]:
    'Remove this user access to this desktop?',
  [UserTranslation.adminUserDesktopsPartOfTeam]: 'A part of one or more groups',
  [UserTranslation.adminUserNotFound]: 'User not found',
  [UserTranslation.adminInvitationNotFound]: 'Invitation not found',

  [UserTranslation.inviteUsersModalHeader]: 'Invite members to {workspaceName}',
  [UserTranslation.inviteUsersEmailPlaceholder]: 'name@example.com',
  [UserTranslation.inviteUsersSuccessToast]: 'Invitation was sent',
  [UserTranslation.inviteUsersDuplicatesToast]:
    'The form fields have email duplicates',
  [UserTranslation.inviteUsersAlreadyExistsToast]:
    '<b>{email}</b> is already a participant of this workspace',
  [UserTranslation.inviteUsersTitle]: 'Invite people to your workspace',
  [UserTranslation.inviteUsersDescription]:
    'Let’s invite your team! <br /><br /> You can easily manage user roles, create groups and edit access settings for all invited users in your Settings menu.',
  [UserTranslation.inviteUsersSubmitButton]: 'Send invite',
  [UserTranslation.inviteUsersSkipButton]: 'Skip for now',
  [UserTranslation.inviteUsersRemoveButton]: 'Remove',
  [UserTranslation.inviteUsersAddButton]: 'Add more',
  [UserTranslation.inviteUsersFormEmailInvalid]:
    'The E-mail address is not valid',
  [UserTranslation.inviteUsersFormEmailRequired]: 'E-mail address is required',

  [UserTranslation.profileEditPageHeader]: 'My profile',
  [UserTranslation.profileFormHeader]: 'Profile',
  [UserTranslation.profileFormFirstNameLabel]: 'First name',
  [UserTranslation.profileFormLastNameLabel]: 'Last name',
  [UserTranslation.profileFormEmailLabel]: 'Email',
  [UserTranslation.profileFormPhoneLabel]: 'Phone number',
  [UserTranslation.profileFormPhoneLengthWarning]:
    'Phone number must be 20 characters or less',
  [UserTranslation.profileFormCompanyNameLabel]: 'Company name',
  [UserTranslation.profileFormCompanyNameLengthWarning]:
    'Company name must be less than 256 characters',
  [UserTranslation.profileFormCompanyUrlLabel]: 'Company url',
  [UserTranslation.profileFormTimezoneLabel]: 'Timezone',
  [UserTranslation.profileFormTimezonePlaceholder]: 'Select timezone',
  [UserTranslation.profilePasswordModalHeader]: 'Password',
  [UserTranslation.profileOldPasswordLabel]: 'Old password',
  [UserTranslation.profileNewPasswordLabel]: 'New password',
  [UserTranslation.profileConfirmPasswordLabel]: 'Confirm password',
  [UserTranslation.profileImageUploadButton]: 'Upload image',
  [UserTranslation.profileImageChangeButton]: 'Change image',
  [UserTranslation.profileImageRemoveButton]: 'Remove image',
  [UserTranslation.profilePasswordImportantMessage]:
    'The password you use to access your Webtop.com account will be used to access your stored app passwords. Make sure to use a strong password and don’t forget it. You may lose access to your stored app passwords if you need to reset your password. ' +
    '<a href="{learnMoreLink}" target="_blank" rel="noopener noreferrer">Learn more</a>' +
    '<br><br> If you are an account owner, please note that a password reset will result in the loss of any stored credentials.' +
    '<br><br> Note that you will be logged out after changing your password.',
  [UserTranslation.profileFormButton]: 'Save changes',
  [UserTranslation.formSuccessMessage]: 'Saved',

  [UserTranslation.profileFormFirstNameRequired]: 'First name is required',
  [UserTranslation.profileFormLastNameRequired]: 'Last name is required',
  [UserTranslation.profileFormEmailRequired]: 'Email is required',
  [UserTranslation.profileFormCompanyUrlPlaceholder]: 'https://',
  [UserTranslation.profileFormCompanyUrlIsNotValid]: 'URL is not valid',
  [UserTranslation.profileFormOldPasswordRequired]: 'Old password is required',
  [UserTranslation.profileFormNewPasswordRequired]: 'New password is required',
  [UserTranslation.profileFormPasswordMinimum]:
    'Password should be at least 8 characters long',
  [UserTranslation.profileFormConfirmPasswordRequired]:
    'Confirm password is required',
  [UserTranslation.profileFormPasswordMatch]: 'Passwords should match',
  [UserTranslation.profileFormPasswordDidNotChange]:
    'New password should not be the same as the current one',
  [UserTranslation.profileFormPasswordContainLetter]:
    'Password should contain at least 1 letter',
  [UserTranslation.profileFormPasswordContainNumber]:
    'Password should contain at least 1 number',
  [UserTranslation.profileFormPasswordErrorReencryptingKeys]:
    'Cannot create new Password manager keys. Probably your old password is incorrect.',

  [UserTranslation.profileMarketingConsentLabel]:
    'Receive Webtop.com emails with information about new features, promotional offers and general updates.',

  [UserTranslation.dangerZoneDeleteAccountButton]: 'Delete account',
  [UserTranslation.dangerZoneDeleteWarning]:
    'The account will no longer be available, and all data in the account will be permanently deleted',
  [UserTranslation.dangerZoneConfirmTitle]: 'Delete account',
  [UserTranslation.dangerZoneConfirmWarning]:
    'Are you sure you want to delete your account?',
  [UserTranslation.dangerZoneConfirmMessage]:
    'If you delete your account it will no longer be available, and all data in the account will be permanently deleted.',
  [UserTranslation.confirmDeleteText]: 'delete',
  [UserTranslation.deleteAccountSuccess]: 'Account was successfully deleted',
  [UserTranslation.deleteAccountLinkedAccountsMFAWarning]:
    'This is your main account. If you delete your main account, your registered MFA will be reset for all your linked accounts and you will have to re-register it',

  [UserTranslation.profileSectionTitle]: 'Profile',
  [UserTranslation.profileEditButton]: 'Edit profile',
  [UserTranslation.profileSubdomainSectionTitle]: 'Subdomain',
  [UserTranslation.profileEditSubdomain]: 'Edit subdomain',
  [UserTranslation.profilePasswordSectionTitle]: 'Password',
  [UserTranslation.profileChangePassword]: 'Change password',
  [UserTranslation.profileSetPassword]: 'Set password',
  [UserTranslation.profileChangePasswordDescription]:
    'You can modify your password anytime, please note that your new password should meet the minimal password strength requirements.',

  [UserTranslation.profileEditTitle]: 'Edit profile information',

  [UserTranslation.profileMenuStatusHeading]: 'Status',
  [UserTranslation.profileMenuMyProfile]: 'My profile',
  [UserTranslation.profileMenuPreferences]: 'Preferences',
  [UserTranslation.profileMenuHelpCenter]: 'Help center',
  [UserTranslation.profileMenuLogout]: 'Log out',

  [UserTranslation.profileConfirmChangeEmail]:
    'Please confirm the email change, we have sent an email to you. New email: <strong>{pendingEmail}</strong>. ',
  [UserTranslation.revokeEmailChangeButton]: 'Revoke email change',
  [UserTranslation.revokeEmailChangeConfirm]:
    'Are you sure you want to revoke email change?',
  [UserTranslation.revokeEmailChangeSuccess]: 'Email change was revoked',

  [UserTranslation.tooltipInviteMembers]: 'Invite workspace members',
  [UserTranslation.tooltipEditUserProfile]:
    'Edit your user profile in this workspace',
  [UserTranslation.tooltipEditUserRole]: 'Change the role for this user',
  [UserTranslation.tooltipManageUserGroup]: 'Manage groups for this user',
  [UserTranslation.tooltipManageUserDesktopAccess]:
    'Manage access to desktops for this user',

  [UserTranslation.inviteGoogleUsersButton]: 'Add your Google Workspace team',
  [UserTranslation.inviteGoogleUsersDisclosure]:
    'Webtop`s use and transfer of information received from Google APIs to any other app will adhere to <a href="{googleApiServicesDataPolicyUrl}" target="_blank" ref="noopener noreferrer">Google API Services User Data Policy</a>, including the Limited Use requirements.',
  [UserTranslation.inviteMicrosoftUsersButton]: 'Add your Microsoft 365 team',
  [UserTranslation.inviteRemoveExternalUsersButton]: 'Remove',
  [UserTranslation.inviteGoogleUsersEmptyStateMessage]:
    'You have no contacts in Google Workspace team.',
  [UserTranslation.inviteMicrosoftUsersEmptyStateMessage]:
    'You have no contacts in Microsoft 365 team.',
  [UserTranslation.inviteGoogleUsersTitle]: 'Google Contacts:',
  [UserTranslation.inviteMicrosoftUsersTitle]: 'Microsoft Contacts:',
};
