import { AccountsTableType } from '../types/AccountIDDB.types';
import { AccountApiType } from '../../../../User/User.types';
import { getLongId } from '../../../../../shared/utils/id';

export const AccountApiTypeToAccountTableType = (
  account: AccountApiType,
  workspaceId: string,
  relatedTeams: string[] = [],
  relatedDesktops: string[] = [],
) => {
  const row: AccountsTableType = {
    id: getLongId('accounts', account.id),
    workspaceId: getLongId('workspaces', workspaceId),
    sourceData: account,
    relatedTeams,
    relatedDesktops,
  };
  return row;
};

export const AccountTableTypeToAccountApiType = (
  accountRow: AccountsTableType,
) => {
  return accountRow?.sourceData;
};

export const createAccountRowsMap = (accountRows: AccountsTableType[]) => {
  return (
    accountRows?.reduce((acc, curr) => {
      return { ...acc, [curr.id]: curr };
    }, {} as Record<string, AccountsTableType>) || {}
  );
};
