import styled from 'styled-components';
import { KeyboardArrowRightIcon } from '../../shared/icons';

export const AdminHeader = styled.div`
  display: flex;
  padding: 0.875rem 0;
  margin-bottom: 1.375rem;
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};
`;

export const AdminHeaderSeparator = styled(KeyboardArrowRightIcon)`
  margin: 0 0.5rem;
`;

export const AdminHeaderTitleContainer = styled.div`
  flex: 1 1 100%;
`;

export const AdminHeaderTitle = styled.h2`
  flex-basis: 100%;
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 1.25rem;
  line-height: 30px;
  word-break: break-all;
  word-break: break-word;

  a {
    color: inherit;
  }

  .count {
    font-family: ${p => p.theme.fonts.Regular};
    font-size: 1.125rem;
    line-height: 1.2;
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    margin-left: 4px;
  }
`;

export const AdminHeaderSubtitle = styled.p`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.75rem;
`;

export const AdminHeaderControls = styled.div`
  flex-shrink: 0;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    flex-basis: initial;
  `}

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    justify-content: flex-end;
  `}

  .input-container {
    flex-basis: 100%;

    ${({ theme: { breakpoints } }) => breakpoints.md`
      flex-basis: initial;
    `}
  }

  & > * ~ * {
    margin-left: 16px;
  }
`;
