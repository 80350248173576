import React, { FC } from 'react';
import {
  CustomNotificationsFormValues,
  MessagesNotificationsType,
} from './CustomNotifications.types';
import { Field } from 'formik';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { GeneralSettingsTranslation } from '../../i18n';
import { NotificationsSectionTitleWrapper } from '../NotificationsSettingsForm/NotificationsSettingsForm.styled';
import { FormToggle } from '../../../../shared/components/Toggle/FormToggle';
import {
  CustomNotificationsCheckboxContainer,
  NotificationsSeparatorStyled,
  StyledCustomNotificationsBodyItem,
  StyledNotificationsRadio,
  StyledNotificationsTitle,
} from '.';
import {
  MESSAGES_NOTIFICATIONS,
  NOTIFICATIONS_BILLING,
  NOTIFICATIONS_GENERAL_BELL,
  NOTIFICATIONS_UPCOMMINT_MEETING,
} from '../NotificationsSettingsForm/NotificationsSettingsForm.constants';
import { NotificationsFormValueType } from '../../../Admin/WorkspaceNotifications';

interface CustomNotificationsFormProps {
  values: CustomNotificationsFormValues;
  handleSubmit?: (name: string, value: NotificationsFormValueType) => void;
  isSubmitting: boolean;
}

export const CustomNotificationsFormFields: FC<
  CustomNotificationsFormProps
> = ({ values, isSubmitting, handleSubmit }) => {
  return (
    <>
      <CustomNotificationsCheckboxContainer>
        <StyledNotificationsTitle>
          <FormattedMessage
            id={GeneralSettingsTranslation.notificationsMessages}
          />
        </StyledNotificationsTitle>

        <StyledNotificationsRadio>
          <label>
            <Field
              name={MESSAGES_NOTIFICATIONS}
              type="radio"
              data-testid="notifications-all-message-radio"
              onClick={() =>
                handleSubmit?.(
                  MESSAGES_NOTIFICATIONS,
                  MessagesNotificationsType.all,
                )
              }
              checked={
                values.messagesNotifications === MessagesNotificationsType.all
              }
              disabled={isSubmitting}
              value={MessagesNotificationsType.all}
            />
            <FormattedMessage
              id={GeneralSettingsTranslation.notificationsAboutAllMessages}
            />
          </label>
        </StyledNotificationsRadio>

        <StyledNotificationsRadio>
          <label>
            <Field
              name={MESSAGES_NOTIFICATIONS}
              type="radio"
              data-testid="notifications-direct-metion-radio"
              checked={
                values.messagesNotifications === MessagesNotificationsType.part
              }
              onClick={() =>
                handleSubmit?.(
                  MESSAGES_NOTIFICATIONS,
                  MessagesNotificationsType.part,
                )
              }
              disabled={isSubmitting}
              value={MessagesNotificationsType.part}
            />
            <span>
              <FormattedMessage
                id={
                  GeneralSettingsTranslation.notificationsAboutDirectMentionsReply
                }
              />
            </span>
          </label>
        </StyledNotificationsRadio>

        <StyledNotificationsRadio>
          <label>
            <Field
              name={MESSAGES_NOTIFICATIONS}
              type="radio"
              data-testid="notifications-disable-radio"
              value={MessagesNotificationsType.disable}
              disabled={isSubmitting}
              checked={
                values.messagesNotifications ===
                MessagesNotificationsType.disable
              }
              onClick={() =>
                handleSubmit?.(
                  MESSAGES_NOTIFICATIONS,
                  MessagesNotificationsType.disable,
                )
              }
            />
            <FormattedMessage
              id={
                GeneralSettingsTranslation.notificationsDisableMessagesNotifications
              }
            />
          </label>
        </StyledNotificationsRadio>
      </CustomNotificationsCheckboxContainer>

      <NotificationsSeparatorStyled />

      <StyledCustomNotificationsBodyItem>
        <NotificationsSectionTitleWrapper>
          <FormattedHTMLMessage
            id={GeneralSettingsTranslation.notificationsGeneralBell}
          />
        </NotificationsSectionTitleWrapper>
        <Field
          id="service-updates-toggle"
          data-testid="service-updates-toggle"
          checked={values.generalBellNotificationsEnable}
          disabled={isSubmitting}
          name={NOTIFICATIONS_GENERAL_BELL}
          onClick={() =>
            handleSubmit?.(
              NOTIFICATIONS_GENERAL_BELL,
              !values.generalBellNotificationsEnable,
            )
          }
          component={FormToggle}
        />
      </StyledCustomNotificationsBodyItem>

      <StyledCustomNotificationsBodyItem>
        <NotificationsSectionTitleWrapper>
          <FormattedHTMLMessage
            id={GeneralSettingsTranslation.notificationsBilling}
          />
        </NotificationsSectionTitleWrapper>
        <Field
          data-testid="notifications-billing"
          checked={values.billingNotificationsEnable}
          disabled={isSubmitting}
          name={NOTIFICATIONS_BILLING}
          onClick={() =>
            handleSubmit?.(
              NOTIFICATIONS_BILLING,
              !values.billingNotificationsEnable,
            )
          }
          component={FormToggle}
        />
      </StyledCustomNotificationsBodyItem>

      <StyledCustomNotificationsBodyItem>
        <NotificationsSectionTitleWrapper>
          <FormattedHTMLMessage
            id={GeneralSettingsTranslation.notificationsUpcomingMeetings}
          />
        </NotificationsSectionTitleWrapper>
        <Field
          data-testid="notifications-upcomming-meeting"
          checked={values.upcomingMeetingNotificationsEnable}
          disabled={isSubmitting}
          name={NOTIFICATIONS_UPCOMMINT_MEETING}
          onClick={() =>
            handleSubmit?.(
              NOTIFICATIONS_UPCOMMINT_MEETING,
              !values.upcomingMeetingNotificationsEnable,
            )
          }
          component={FormToggle}
        />
      </StyledCustomNotificationsBodyItem>
    </>
  );
};
