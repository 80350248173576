import { AccountApiType } from '../../../User/User.types';
import { useCallback, useMemo } from 'react';
import { database } from '../../../Database';
import { AccountApiTypeToCurrentAccountTableType } from './utils/CurrentAccountIDDB.utils';
import { updateCurrentAccountIDDB } from './operations/CurrentAccountIDDB.operations';

export const useCurrentAccountActions = () => {
  const putAccountToIDDB = useCallback((account: AccountApiType | null) => {
    try {
      database.currentAccount.put(
        AccountApiTypeToCurrentAccountTableType(account),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const updateCurrentAccount = useCallback(
    (updater: (account: AccountApiType | null) => AccountApiType | null) => {
      try {
        updateCurrentAccountIDDB(updater);
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  return useMemo(
    () => ({
      putAccountToIDDB,
      updateAccount: updateCurrentAccount,
    }),
    [putAccountToIDDB, updateCurrentAccount],
  );
};
