import { ApolloClient } from '@apollo/client';
import { StreamNotificationEvent } from '../../../GeneralMercure.types';
import { removeNotificationFromNotificationsCache } from '../../../../../Notifications/cache';
import { NotificationType } from '../../../../../Notifications/Notifications.types';
import {
  removeMentionFromMentionsCache,
  removeThreadFromThreadsCache,
} from '../../../../../Inbox/cache';
import {
  MENTIONS_INITIAL_LOAD_COUNT,
  THREADS_INITIAL_LOAD_COUNT,
} from '../../../../../Inbox/Inbox.constants';
import { NOTIFICATIONS_INITIAL_LOAD_COUNT } from '../../../../../Notifications/Notifications.constants';

export const notificationRequestRemove = (
  event: StreamNotificationEvent,
  apolloClient: ApolloClient<object>,
) => {
  switch (event.message.type) {
    case NotificationType.USER_CHAT_MENTIONED:
      removeMentionFromMentionsCache(
        apolloClient,
        {
          accountId: event.recipient.id,
          workspaceId: event.workspace.id,
          first: MENTIONS_INITIAL_LOAD_COUNT,
        },
        event.id,
      );
      break;
    case NotificationType.USER_CHAT_THREAD:
      removeThreadFromThreadsCache(
        apolloClient,
        {
          accountId: event.recipient.id,
          workspaceId: event.workspace.id,
          first: THREADS_INITIAL_LOAD_COUNT,
        },
        event.id,
      );
      break;
    default:
      removeNotificationFromNotificationsCache(
        apolloClient,
        {
          accountId: event.recipient.id,
          workspaceId: event.workspace.id,
          first: NOTIFICATIONS_INITIAL_LOAD_COUNT,
        },
        event.id,
      );
  }
};
