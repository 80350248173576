export enum AppIntegrationsTranslation {
  appIntegrationCustomAppsTabFilter = 'appIntegration_appIntegrationCustomAppsTabFilter',
  appIntegrationPersonalTitle = 'appIntegration_appIntegrationPersonalTitle',
  appIntegrationWorkspaceTitle = 'appIntegration_appIntegrationWorkspaceTitle',
  appIntegrationTitle = 'appIntegration_appIntegrationTitle',
  appIntegrationAvailable = 'appIntegration_appIntegrationAvailable',
  appIntegrationCustomTitle = 'appIntegration_appIntegrationCustomTitle',
  appIntegrationCustomLearnMoreLink = 'appIntegration_appIntegrationCustomLearnMoreLink',
  appIntegrationWorkspaceDisabledText = 'appIntegration_appIntegrationWorkspaceDisabledText',
  appIntegrationPersonalDisabledText = 'appIntegration_appIntegrationPersonalDisabledText',
  appIntegrationCustomDisabledText = 'appIntegration_appIntegrationCustomDisabledText',
  appIntegrationCreateNew = 'appIntegration_appIntegrationCreateNew',
  appIntegrationViewButton = 'appIntegration_appIntegrationViewButton',
  appIntegrationDeleteButton = 'appIntegration_appIntegrationDeleteButton',
  appIntegrationsNotAvailable = 'appIntegration_appIntegrationsNotAvailable',
  appIntegrationsOpenInBrowser = 'appIntegration_appIntegrationsOpenInBrowser',
  appIntegrationViewMarketplaceCategory = 'appIntegration_appIntegrationViewMarketplaceCategory',
  appIntegrationConfigureApp = 'appIntegration_appIntegrationConfigureApp',
  appIntegrationConfiguration = 'appIntegration_appIntegrationConfiguration',
  appIntegrationDeleteIntegration = 'appIntegration_appIntegrationDeleteIntegration',
  appCustomIntegrationDefaultTitle = 'appIntegration_appCustomIntegrationDefaultTitle',
  appCustomIntegrationNameLabel = 'appIntegration_appCustomIntegrationNameLabel',
  appCustomIntegrationNamePlaceholder = 'appIntegration_appCustomIntegrationNamePlaceholder',
  appCustomIntegrationClientIdLabel = 'appIntegration_appCustomIntegrationClientIdLabel',
  appCustomIntegrationSecretLabel = 'appIntegration_appCustomIntegrationSecretLabel',
  appCustomIntegrationBotNameLabel = 'appIntegration_appCustomIntegrationBotNameLabel',
  appCustomIntegrationBotNamePlaceholder = 'appIntegration_appCustomIntegrationBotNamePlaceholder',
  appCustomIntegrationBotImageLabel = 'appIntegration_appCustomIntegrationBotImageLabel',
  appCustomIntegrationBotImageButton = 'appIntegration_appCustomIntegrationBotImageButton',
  appCustomIntegrationAddNewHookLabel = 'appIntegration_appCustomIntegrationAddNewHookLabel',
  appCustomIntegrationHookIdLabel = 'appIntegration_appCustomIntegrationHookIdLabel',
  appCustomIntegrationDesktopLabel = 'appIntegration_appCustomIntegrationDesktopLabel',
  appCustomIntegrationDesktopPlaceholder = 'appIntegration_appCustomIntegrationDesktopPlaceholder',
  appCustomIntegrationAddLabel = 'appIntegration_appCustomIntegrationAddLabel',
  appCustomIntegrationPropertiesTab = 'appIntegration_appCustomIntegrationPropertiesTab',
  appCustomIntegrationBotTab = 'appIntegration_appCustomIntegrationBotTab',
  appCustomIntegrationHooksTab = 'appIntegration_appCustomIntegrationHooksTab',
  appCustomIntegrationSaveButton = 'appIntegration_appCustomIntegrationSaveButton',
  appCustomIntegrationCancelButton = 'appIntegration_appCustomIntegrationCancelButton',
  appCustomIntegrationShowSecret = 'appIntegration_appCustomIntegrationShowSecret',
  appCustomIntegrationHideSecret = 'appIntegration_appCustomIntegrationHideSecret',
  appCustomIntegrationNameUpdated = 'appIntegration_appCustomIntegrationNameUpdated',
  appCustomIntegrationBotUpdated = 'appIntegration_appCustomIntegrationBotUpdated',
  appCustomIntegrationAddNewLabel = 'appIntegration_appCustomIntegrationAddNewLabel',
  appCustomIntegrationNameRequired = 'appIntegration_appCustomIntegrationNameRequired',
  appCustomIntegrationBotNameRequired = 'appIntegration_appCustomIntegrationBotNameRequired',
  appCustomIntegrationDocumentationLink = 'appIntegration_appCustomIntegrationBotNameRequired',
  deleteCustomIntegrationConfirmation = 'appIntegration_deleteCustomIntegrationConfirmation',
  deleteCustomIntegrationHookConfirmation = 'appIntegration_deleteCustomIntegrationHookConfirmation',
  backToAppIntegrationsPage = 'appIntegration_backToAppIntegrationsPage',
  backToAppIntegrationsLoginButton = 'appIntegration_backToAppIntegrationsLoginButton',
  backToAppIntegrationsInstallButton = 'appIntegration_backToAppIntegrationsInstallButton',
  successDeletedAppIntegration = 'appIntegration_successDeletedAppIntegration',
  appIntegrationCreateCredentialEmail = 'appIntegration_appIntegrationCreateCredentialEmail',
  appIntegrationCreateCredentialPassword = 'appIntegration_appIntegrationCreateCredentialPassword',
  appIntegrationCreateCredentialDomain = 'appIntegration_appIntegrationCreateCredentialDomain',
  appIntegrationCreateCredentialEmailPlaceholder = 'appIntegration_appIntegrationCreateCredentialEmailPlaceholder',
  appIntegrationCreateCredentialPasswordPlaceholder = 'appIntegration_appIntegrationCreateCredentialPasswordPlaceholder',
  appIntegrationCreateCredentialDomainPlaceholder = 'appIntegration_appIntegrationCreateCredentialDomainPlaceholder',
  createAppIntegrationCredentialModalTitle = 'appIntegration_createAppIntegrationCredentialModalTitle',
  createAppIntegrationCredentialUrlIsNotValid = 'appIntegration_createAppIntegrationCredentialUrlIsNotValid',
  createAppIntegrationCredentialUsernameRequired = 'appIntegration_createAppIntegrationCredentialUsernameRequired',
  createAppIntegrationCredentialPasswordRequired = 'appIntegration_createAppIntegrationCredentialPasswordRequired',
  createAppIntegrationCredentialUrlRequired = 'appIntegration_createAppIntegrationCredentialUrlRequired',
  createAppIntegrationCredentialSave = 'appIntegration_createAppIntegrationCredentialSave',
  createAppIntegrationCredentialCancel = 'appIntegration_createAppIntegrationCredentialCancel',
  createAppIntegrationSuccessInstalledMessage = 'appIntegration_createAppIntegrationSuccessInstalledMessage',
  createAppIntegrationCredentialJiraLink = 'appIntegration_createAppIntegrationCredentialJiraLink',
}

export type AppIntegrationsLocale = {
  [key in AppIntegrationsTranslation]: string;
};
