import { gql } from '@apollo/client';
import { NotificationApiType } from './Notifications.types';
import {
  GRAPHQL_TYPENAME_NOTIFICATION_CONNECTION,
  GRAPHQL_TYPENAME_NOTIFICATION_EDGE,
} from './Notifications.constants';
import { ApiConnection, PageInfoApiType } from '../../shared/api/api.types';

export const GET_NOTIFICATIONS = gql`
  query Notifications(
    $accountId: String
    $workspaceId: String
    $cursor: String
    $first: Int
  ) {
    notifications(
      filterExcludeMentionsAndThreads: true
      recipient: $accountId
      workspace: $workspaceId
      first: $first
      after: $cursor
      order: { createdAt: "desc" }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          _id
          status
          message {
            id
            fields
            type
          }
          createdAt
        }
      }
    }
  }
`;

export interface GetNotificationsVariables {
  accountId: string;
  workspaceId: string;
  cursor?: string;
  first: number;
}

export interface GetNotificationsResponse {
  notifications: {
    __typename: typeof GRAPHQL_TYPENAME_NOTIFICATION_CONNECTION;
    pageInfo: PageInfoApiType;
    edges: Array<{
      node: NotificationApiType;
      __typename: typeof GRAPHQL_TYPENAME_NOTIFICATION_EDGE;
    }>;
  };
}

export const GET_NOTIFICATION_UPDATES = gql`
  query GetChatConversationUpdates($workspaceIri: ID!, $date: String!) {
    deltaCreatedNotifications(workspace: $workspaceIri, date: $date) {
      edges {
        node {
          id
          _id
          status
          message {
            id
            fields
            type
          }
          recipient {
            id
          }
          createdAt
        }
      }
    }
    deltaUpdatedNotifications(workspace: $workspaceIri, date: $date) {
      edges {
        node {
          id
          _id
          status
          message {
            id
            fields
            type
          }
          createdAt
        }
      }
    }
    deltaDeletedNotifications(workspace: $workspaceIri, date: $date) {
      edges {
        node {
          id
          message {
            type
          }
          recipient {
            id
          }
        }
      }
    }
  }
`;

export type NotificationUpdateApiType = NotificationApiType & {
  recipient?: {
    id: string;
  };
};
export type DeletedNotificationUpdateApiType = Pick<
  NotificationUpdateApiType,
  'id' | 'recipient'
> & {
  message: Pick<NotificationApiType['message'], 'type'>;
};
export interface GetNotificationUpdatesResponse {
  deltaCreatedNotifications: ApiConnection<NotificationUpdateApiType>;
  deltaUpdatedNotifications: ApiConnection<NotificationApiType>;
  deltaDeletedNotifications: ApiConnection<DeletedNotificationUpdateApiType>;
}

export interface GetNotificationUpdatesVariables {
  workspaceIri: string;
  date: string;
}
