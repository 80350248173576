import { UnreadChatMessagesConversationType } from '../User/User.types';

export type UnreadItemsMap = Record<string, number>;

export type UnreadChatMessagesMap = Record<
  string,
  UnreadChatMessagesConversationType
>;

export type CountersContextType = {
  unreadNotifications: UnreadItemsMap;
  unreadChatMessages: UnreadChatMessagesMap;
  unreadChatConferences: UnreadItemsMap;
  unreadMentions: UnreadItemsMap;
  unreadThreads: UnreadItemsMap;
  unreadChatConversations: UnreadItemsMap;
};

export enum CounterType {
  Notifications = 'notifications',
  ChatMessages = 'chatMessages',
  ChatConferences = 'chatConferences',
  Mentions = 'mentions',
  Threads = 'threads',
}

export type UnreadWorkspaceItemsMap = Record<
  CounterType,
  number | UnreadChatMessagesConversationType | undefined
>;
