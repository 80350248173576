import React from 'react';
import { DesktopSidebarIdentifier } from './DesktopSidebar.types';

export interface DesktopSidebarContextTypes {
  identifier?: DesktopSidebarIdentifier | null;
  setIdentifier: (identifier: DesktopSidebarIdentifier | null) => void;
}

export const DesktopSidebarContext =
  React.createContext<DesktopSidebarContextTypes>({
    identifier: null,
    setIdentifier: () => {},
  });
