import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  FetchResult,
  from,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Observable,
  Operation,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { persistCache, PersistentStorage } from 'apollo3-cache-persist';
import { GRAPHQL_TYPENAME_MUTATION } from '../../shared/api/api.constants';
import { fixTypenames } from '../../shared/api/api.utils';
import { appEnv } from '../../appEnv';
import { AuthSessionHandler } from '../Auth/Auth.types';
import { getWorkspaceIdFromCurrentUrl } from '../Workspace/Workspace.utils';
import { RestLink } from 'apollo-link-rest';
import { ApiConnection } from '../../shared/api/api.types';
import { generateRandomId, getShortId } from '../../shared/utils/id';
import * as Sentry from '@sentry/browser';
import { triggerMfaVerification } from '../Auth/Auth.utils';
import { MFA_INVALID_ERROR_TEXT } from '../Auth/Auth.constants';
import {
  GRAPHQL_TYPENAME_LINK,
  GRAPHQL_TYPENAME_LINK_CONNECTION,
  GRAPHQL_TYPENAME_LINK_EDGE,
  GRAPHQL_TYPENAME_TAG,
  GRAPHQL_TYPENAME_TAG_CONNECTION,
  GRAPHQL_TYPENAME_TAG_EDGE,
} from '../Link/Link.constants';
import {
  GRAPHQL_TYPENAME_FOLDER,
  GRAPHQL_TYPENAME_FOLDER_CONNECTION,
  GRAPHQL_TYPENAME_FOLDER_EDGE,
} from '../Folder/Folder.constants';
import {
  GRAPHQL_TYPENAME_DESKTOP_APP,
  GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
  GRAPHQL_TYPENAME_DESKTOP_APP_EDGE,
  GRAPHQL_TYPENAME_FAVORITE,
  GRAPHQL_TYPENAME_FAVORITE_CONNECTION,
  GRAPHQL_TYPENAME_FAVORITE_EDGE,
} from '../Desktop/Desktop.constants';
import { FolderApiType } from '../Folder/Folder.types';
import { LinkApiType, TagApiType } from '../Link/Link.types';
import { getDesktopIri } from '../Desktop/Desktop.utils';
import { RestApiClient } from './RestApiClient/RestApiClient';
import type { DesktopAppEdgeApiType } from '../Desktop/data/Desktop/types/Desktop.types';
import { RetryLink } from '@apollo/client/link/retry';

const createAuthLink = (
  authService: AuthSessionHandler,
  fetchAuthConfig?: () => Promise<void>,
) => {
  return setContext((operation, { headers }) => {
    const transactionId = generateRandomId();
    Sentry.configureScope(scope => {
      scope.setTag('transaction_id', transactionId);
    });

    return authService
      .getToken(undefined, undefined, fetchAuthConfig)
      .then(token => {
        return {
          headers: {
            ...headers,
            ...(token ? { Authorization: `Bearer ${token}` } : null),
            'X-ClientVersion': appEnv._VERSION,
            'X-Workspace-ID': getWorkspaceIdFromCurrentUrl(),
            'X-Request-ID': transactionId,
          },
        };
      })
      .catch(error => {
        return error;
      });
  });
};

const createRetryLink = () => {
  return new RetryLink({
    delay: {
      initial: 2000,
      max: 10000,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: error => {
        return error?.message?.indexOf('Failed to fetch') !== -1;
      },
    },
  });
};

const createWaitOnlineLink = () => {
  return new ApolloLink(
    (operation: Operation, forward: NextLink) =>
      new Observable<FetchResult>(observer => {
        // @ts-ignore
        let subscription: ZenObservable.Subscription;
        const handleError = (networkError: Error) => {
          if (networkError && networkError.message === 'Failed to fetch') {
            if (navigator.onLine) {
              subscription = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            } else {
              const handleOnline = () => {
                subscription = forward(operation).subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                });
                window.removeEventListener('online', handleOnline);
              };
              window.addEventListener('online', handleOnline);
            }
          } else {
            observer.error(networkError);
          }
        };

        subscription = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: handleError,
          complete: observer.complete.bind(observer),
        });

        return () => {
          if (subscription) subscription.unsubscribe();
        };
      }),
  );
};

const responseInterceptor = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      //TODO remove ts-ignore
      //@ts-ignore
      let subscription: ZenObservable.Subscription;

      try {
        subscription = forward(operation).subscribe({
          next: (value: FetchResult) => {
            const data = value.data || {};
            const errors = value.errors || [];

            if (
              errors.some(
                err => err.extensions?.category === MFA_INVALID_ERROR_TEXT,
              )
            ) {
              triggerMfaVerification();
            }

            observer.next(
              data.__typename === GRAPHQL_TYPENAME_MUTATION
                ? {
                    ...value,
                    data: fixTypenames(data),
                  }
                : value,
            );
          },
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      } catch (e) {}

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }),
);

// type chatMessagesRefsType = {
//   pageInfo?: PageInfoApiType;
//   edges: Array<{ node: { __ref: string } }>;
// };

const createCache = () =>
  new InMemoryCache({
    dataIdFromObject: object => {
      const pathname = window.location.pathname;
      const pathNameArray = pathname.split('/');
      const workspaceId =
        pathNameArray[1] === 'workspace' ? pathNameArray[2] : '';

      switch (object.__typename) {
        case 'App':
          return `App.${object.id}.${workspaceId}`;
        default:
          return defaultDataIdFromObject(object);
      }
    },
    typePolicies: {
      Query: {
        fields: {
          listChatMessages: {
            keyArgs: args => {
              // use conversation ID as cache key to gather all paginated messages
              if (!args) return false;
              const { conversation, parentChatMessage } = args;
              const key = `${conversation}${
                parentChatMessage ? `/${getShortId(parentChatMessage)}` : ''
              }`;
              return key;
            },
          },
        },
      },
    },
  });

const isEmptyEdges = (item?: Array<unknown> | ApiConnection<unknown>) => {
  if (!item || !('edges' in item)) {
    return false;
  }
  return !item.edges.length;
};

export const responseTransformer = (data: any): any => {
  if (Array.isArray(data)) {
    if (data.length === 0) {
      return {
        edges: [],
      };
    }
    if (!data[0] || typeof data[0] !== 'object') {
      return data;
    }
    const firstItem: { '@id'?: string; '@type'?: string } = data[0];
    if ('@id' in firstItem && '@type' in firstItem) {
      const __typename = `${firstItem['@type']}Edge`;
      return {
        edges: data.map(item => ({
          node: responseTransformer(item),
          __typename,
        })),
      };
    }
    return data;
  }
  const {
    '@id': id,
    '@type': __typename,
    id: _id,
    ...item
  } = data as {
    '@id'?: string;
    '@type'?: string;
    id?: string;
  } & Record<string, any>;
  const result = Object.keys(item).reduce<any>(
    (acc, key) => ({
      ...acc,
      [key]:
        item[key] && (Array.isArray(item[key]) || typeof item[key] === 'object')
          ? responseTransformer(item[key])
          : item[key],
    }),
    {
      ...(id ? { id } : null),
      ...(__typename ? { __typename } : null),
      ...(_id ? (id ? { _id } : { id: _id }) : null),
    },
  );
  if (__typename === 'Folder' && isEmptyEdges(result.subFolderIds)) {
    result.subFolderIds = [];
  }
  if (__typename === 'ChatMessage') {
    if (isEmptyEdges(result.reactions)) {
      result.reactions = [];
    }
    if (isEmptyEdges(result.seenBy)) {
      result.seenBy = [];
    }
    if (isEmptyEdges(result.context?.assets)) {
      result.context.assets = [];
    }
  }
  if (__typename === 'ChatConversation') {
    if (isEmptyEdges(result.accountsThatPinned)) {
      result.accountsThatPinned = [];
    }
    if (isEmptyEdges(result.pendingEmails)) {
      result.pendingEmails = [];
    }
    if (isEmptyEdges(result.users)) {
      result.users = [];
    }
  }

  if (__typename === 'Account') {
    result._id = getShortId(result.id);
  }

  if (__typename === 'Desktop') {
    if (result.accountsThatChatHidden?.edges) {
      result.accountsThatChatHidden = result.accountsThatChatHidden.edges;
    }
  }

  return result;
};

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

let restApiClient: RestApiClient | undefined;

export const getApolloClient = () => {
  return apolloClient;
};

export const createApolloClient = async (
  authService: AuthSessionHandler,
  storage?: PersistentStorage<string>,
  fetchAuthConfig?: () => Promise<void>,
) => {
  const cache = createCache();
  const restLink = new RestLink({
    uri: appEnv.API_REST_URL,
    responseTransformer: async response => {
      // @TODO: RECONSIDER IF WE NEED THIS LOGIC, SINCE IT DUPLICATES responseTransformer UTIL LOGIC (AND PARTLY BREAKS IT)
      // SHOULD BE REMOVED TOGETHER WITH APOLLO-LINK

      const path = response.url.replace(appEnv.API_REST_URL, '');
      const isWorkspaceChatPath =
        path.startsWith('/workspace') && path.endsWith('/chat');
      const isWorkspaceFavoritesPath =
        path.startsWith('/workspace') && path.endsWith('/favorites');
      const isDesktopLinksPath =
        path.startsWith('/desktop') && path.endsWith('/links');
      const isDesktopFoldersPath =
        path.startsWith('/desktop') && path.endsWith('/folders');
      const isDesktopAppsPath =
        path.startsWith('/desktop') && path.endsWith('/apps');
      const isDesktopChatPath =
        path.startsWith('/desktop') && path.endsWith('/chat');
      return response.json().then((data: any) => {
        if (isWorkspaceChatPath) {
          data = data.map((d: { chatConversation: { id: string } }) => ({
            '@id': d.chatConversation.id,
            '@type': 'WorkspaceChatItem',
            ...d,
          }));
        }
        if (isWorkspaceFavoritesPath) {
          // fix response here when api is merged
          data = {
            __typename: GRAPHQL_TYPENAME_FAVORITE_CONNECTION,
            edges: data.map((d: { id: string; desktopId?: string }) => ({
              __typename: GRAPHQL_TYPENAME_FAVORITE_EDGE,
              node: {
                __typename: (d as FolderApiType).id.startsWith('/folders')
                  ? GRAPHQL_TYPENAME_FOLDER
                  : typeof (d as LinkApiType).messagesCount !== 'undefined'
                  ? GRAPHQL_TYPENAME_LINK
                  : typeof (d as DesktopAppEdgeApiType).app !== 'undefined'
                  ? GRAPHQL_TYPENAME_DESKTOP_APP
                  : GRAPHQL_TYPENAME_FAVORITE,
                '@id': d.id,
                '@type': 'DesktopFavorites',
                ...d,
                ...(d.desktopId
                  ? {
                      desktop: {
                        '@type': 'Desktop',
                        '@id': getDesktopIri(d.desktopId),
                        id: d.desktopId,
                      },
                    }
                  : {}),
                ...((d as FolderApiType).desktop?.id
                  ? {
                      desktopId: (d as FolderApiType).desktop?.id,
                    }
                  : {}),
                id: d.id,
              },
            })),
          };
        }
        if (isDesktopLinksPath) {
          data = {
            __typename: GRAPHQL_TYPENAME_LINK_CONNECTION,
            edges: data.map((d: { id: string; tags: TagApiType[] }) => ({
              __typename: GRAPHQL_TYPENAME_LINK_EDGE,
              node: {
                __typename: GRAPHQL_TYPENAME_LINK,
                '@id': d.id,
                '@type': 'DesktopLinks',
                ...d,
                tags: {
                  __typename: GRAPHQL_TYPENAME_TAG_CONNECTION,
                  edges: d.tags.map((tag: TagApiType) => ({
                    __typename: GRAPHQL_TYPENAME_TAG_EDGE,
                    node: {
                      id: tag.id,
                      _id: getShortId(tag.id),
                      name: tag.name,
                      __typename: GRAPHQL_TYPENAME_TAG,
                    },
                  })),
                },
              },
            })),
          };
        }

        if (isDesktopFoldersPath) {
          data = {
            __typename: GRAPHQL_TYPENAME_FOLDER_CONNECTION,
            edges: data.map((d: { id: string }) => ({
              __typename: GRAPHQL_TYPENAME_FOLDER_EDGE,
              node: {
                __typename: GRAPHQL_TYPENAME_FOLDER,
                '@id': d.id,
                '@type': 'DesktopFolders',
                ...d,
              },
            })),
          };
        }

        if (isDesktopAppsPath) {
          data = {
            __typename: GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
            edges: data.map((d: { id: string }) => ({
              __typename: GRAPHQL_TYPENAME_DESKTOP_APP_EDGE,
              node: {
                __typename: GRAPHQL_TYPENAME_DESKTOP_APP,
                '@id': d.id,
                '@type': 'DesktopApp',
                ...d,
              },
            })),
          };
        }

        if (isDesktopChatPath) {
          data = {
            chatConversation: data.chatConversation,
          };
        }
        return responseTransformer(data);
      });
    },
  });
  if (storage) {
    await persistCache({
      cache,
      storage,
      maxSize: 4 * 1024 * 1024, // 4 Megabytes
    });
  }

  const client = new ApolloClient({
    cache,
    link: from([
      responseInterceptor,
      createAuthLink(authService, fetchAuthConfig),
      createRetryLink(),
      restLink,
      createUploadLink({
        uri: process.env.API_URL,
      }),
    ] as ApolloLink[]),
  });
  apolloClient = client;
  return client;
};

export const createRestApiClient = async (authService: AuthSessionHandler) => {
  restApiClient = new RestApiClient({ authService });

  return restApiClient;
};

export const getRestApiClient = () => {
  return restApiClient;
};

export const evictApolloEntitiesByType = (
  client: ApolloClient<any>,
  typeName: string,
) => {
  const data = client.cache.extract();

  for (let key in data) {
    if (key.startsWith(`${typeName}:`)) {
      client.cache.evict({ id: key });
    }
  }

  client.cache.gc();
};
