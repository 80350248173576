import { StreamInvitationEvent } from '../types/InvitationMercure.types';
import { AccountInvitationApiType } from '../types/Invitation.types';

export const InvitationMercureEventToApiType = (
  invitationEvent: StreamInvitationEvent,
): AccountInvitationApiType => {
  const {
    '@iri': iri,
    '@request': request,
    '@type': type,
    '@timestamp': timestamp,
    ...invitation
  } = invitationEvent;
  return invitation;
};
