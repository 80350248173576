import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { OfflineContext } from './Offline.context';
import { showToastError } from '../../shared/components/Toast';
import { useIntl } from 'react-intl';
import { OfflineTranslation } from './i18n';

export const OfflineProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      return;
    }

    const toastId = showToastError(
      formatMessage({
        id: OfflineTranslation.offlineModalTitle,
      }),
      {
        closeOnClick: false,
      },
    );

    return () => {
      toast.dismiss(toastId);
    };
  }, [formatMessage, isOnline]);

  return (
    <OfflineContext.Provider value={{ isOnline }}>
      {children}
    </OfflineContext.Provider>
  );
};
