import type { StreamDesktopEvent } from '../../../../Mercure/General';
import type { DesktopApiType } from '../types/Desktop.types';

export const mercureDesktopEventToDesktopApiType = (
  event: StreamDesktopEvent,
): Partial<DesktopApiType> => {
  return {
    id: event.id,
    accountIds: event.accountIds,
    name: event.name,
    description: event.description,
    shareable: event.shareable,
    memberCount: event.memberCount,
    userCount: event.userCount,
    teamCount: event.teamCount,
    accountSortPosition: event.accountSortPosition,
    chatConversationIri: event.chatConversationIri,
    chatEnabled: event.chatEnabled,
    isGeneral: event.isGeneral,
    accountsThatChatHidden: event.accountsThatChatHidden,
  };
};
