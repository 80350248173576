export enum PasswordTranslation {
  tooltipCreatePassword = 'password_tooltipCreatePassword',
  passwordLabel = 'password_passwordLabel',
  passwordPlaceholder = 'password_passwordPlaceholder',
  passwordRequired = 'password_passwordRequired',
  passwordMinimum = 'password_passwordMinimum',
  passwordContainLetter = 'password_passwordContainLetter',
  passwordContainNumber = 'password_passwordContainNumber',
  repeatPasswordLabel = 'password_repeatPasswordLabel',
  repeatPasswordPlaceholder = 'password_repeatPasswordPlaceholder',
  repeatPasswordRequired = 'password_repeatPasswordRequired',
  repeatPasswordMinimum = 'password_repeatPasswordMinimum',
  repeatPasswordContainLetter = 'password_repeatPasswordContainLetter',
  repeatPasswordContainNumber = 'password_repeatPasswordContainNumber',
  repeatPasswordMatch = 'password_repeatPasswordMatch',

  // Set password form
  setPasswordFormSubmitButton = 'password_setPasswordFormSubmitButton',
  setPasswordFormSubmitButtonTitle = 'password_setPasswordFormSubmitButtonTitle',
  setPasswordFormHelpText = 'password_setPasswordFormHelpText',

  // Set passwords modal
  setPasswordModalHeader = 'password_setPasswordModalHeader',
  profilePasswordImportantMessage = 'password_profilePasswordImportantMessage',
}

export type PasswordLocale = {
  [key in PasswordTranslation]: string;
};
