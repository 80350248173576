import { getLongId } from '../../../shared/utils/id';
import type { DesktopAppEdgeApiType } from '../../Desktop/data/Desktop/types/Desktop.types';
import type { DesktopAppsTableType } from './DesktopAppsTable';

/**
 * Converts a DesktopAppEdgeApiType to DesktopAppsTableType
 * @param app
 * @param desktopId
 * @param workspaceId
 * @returns DesktopLinksTableType
 */
export const DesktopAppApiTypeToDesktopAppsTableRow = (
  appEdge: DesktopAppEdgeApiType,
  desktopId: string,
  workspaceId: string,
): DesktopAppsTableType => ({
  id: getLongId('desktop-apps', appEdge.id),
  desktopId: getLongId('desktops', desktopId),
  workspaceId: getLongId('workspaces', workspaceId),
  accountFavorite: appEdge.accountFavorite ?? false,
  createdAt: appEdge.createdAt,
  sourceData: appEdge,
  appId: appEdge.app.id,
});
