import React, { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { useMobile } from '../../../shared/hooks';
import { useDesktopSidebar } from '../../Desktop/DesktopSidebar';
import { usePreviewSegment } from '../../PreviewSegment/PreviewSegment.hooks';
import { PwaDarkThemeColor, useTheme } from '../../Theme';
import {
  useCurrentWorkspacePermissions,
  useMobileNavigationSidebar,
} from '../../Workspace/Workspace.hooks';
import {
  MobileMenuCloseOverlay,
  StyledSegmentContent,
} from './SegmentContent.styled';

type SegmentContentProps = {
  secondarySegment?: boolean;
};

export const SegmentContent: FC<PropsWithChildren<SegmentContentProps>> = ({
  children,
  secondarySegment = false,
}) => {
  const isMobile = useMobile();
  const { mobileNavigationSidebarIsOpen, mobileNavigationSidebarClose } =
    useMobileNavigationSidebar();
  const { identifier } = useDesktopSidebar();
  const { theme } = useTheme();
  const { isPreviewMode } = usePreviewSegment();

  const {
    permissions: {
      canMenuDesktopsSidebarItem,
      canMenuChatsSidebarItem,
      canMenuMeetingsSidebarItem,
    },
  } = useCurrentWorkspacePermissions();

  const canMenuOnlyDesktopSidebarItem =
    canMenuDesktopsSidebarItem &&
    !canMenuChatsSidebarItem &&
    !canMenuMeetingsSidebarItem;

  return (
    <StyledSegmentContent
      data-testid="segment-content"
      className={mobileNavigationSidebarIsOpen ? 'menu-open' : ''}
      isMobile={isMobile}
      withSidebar={!!identifier && !isPreviewMode}
      withSwitcher={!canMenuOnlyDesktopSidebarItem}
      withTwoSegments={secondarySegment}>
      <Helmet>
        <meta
          name="theme-color"
          content={
            mobileNavigationSidebarIsOpen
              ? theme?.colors.Navbar
              : PwaDarkThemeColor
          }
        />
      </Helmet>
      {children}
      {mobileNavigationSidebarIsOpen && isMobile && (
        <MobileMenuCloseOverlay
          onClick={mobileNavigationSidebarClose}
          data-testid="segment-content-close-mobile-menu-overlay"
        />
      )}
    </StyledSegmentContent>
  );
};
