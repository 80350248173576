import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const GoogleSlidesIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <rect x="3" y="3" width="18" height="18" rx="1.6875" fill="#F4B400" />
      <rect x="5.25" y="8.0625" width="13.5" height="7.875" fill="white" />
    </svg>
  );
});
