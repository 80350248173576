import localForage from 'localforage';
import {
  PERSISTED_STORAGE_KEY_APOLLO_CACHE,
  PERSISTED_STORAGE_KEY_AUTH_CONFIG,
  PERSISTED_STORAGE_KEY_AUTHENTICATED_ACCOUNT,
  STORAGE_NAME,
  STORAGE_STORE_NAME,
} from './PersistedStorage.constants';

export const makePersistedStorage = () =>
  localForage.createInstance({
    name: STORAGE_NAME,
    storeName: STORAGE_STORE_NAME,
  });

export const clearPersistedStorageCache = () => {
  const storage = makePersistedStorage();
  return Promise.allSettled([
    storage.removeItem(PERSISTED_STORAGE_KEY_APOLLO_CACHE),
    storage.removeItem(PERSISTED_STORAGE_KEY_AUTH_CONFIG),
    storage.removeItem(PERSISTED_STORAGE_KEY_AUTHENTICATED_ACCOUNT),
  ]);
};

export const clearPersistedStorage = () => {
  const storage = makePersistedStorage();
  return storage.clear();
};
