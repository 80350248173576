import React, { FC, useMemo } from 'react';
import { AdminList, AdminListEmptyMessage } from '../List.styled';
import type {
  DesktopAccessType,
  DesktopApiType,
} from '../../Desktop/data/Desktop/types/Desktop.types';
import { AccessApiType } from '../../Access/data/types/Access.types';
import { RelatedDesktopsListItem } from './RelatedDesktopsListItem';

interface RelatedItemsListProps {
  emptyMessage: string;
  desktops: DesktopApiType[];
  desktopAccessesMap: Record<string, AccessApiType[]>;
  deletableItemsMap?: { [key: string]: boolean };
  accountId?: string;
  teamId?: string;
  onChangeAccess: (
    desktop: DesktopApiType,
    newAccessValue: DesktopAccessType,
  ) => void;
  onRemove: (desktop: DesktopApiType) => void;
  isOnlyFullAccessAllowed?: boolean;
  disableAccessMenu?: boolean;
}

export const RelatedDesktopsList: FC<RelatedItemsListProps> = ({
  emptyMessage,
  desktops,
  desktopAccessesMap,
  deletableItemsMap,
  onChangeAccess,
  onRemove,
  teamId,
  accountId,
  isOnlyFullAccessAllowed,
}) => {
  const desktopAccesses = useMemo(() => {
    return desktops.map(desktop =>
      desktopAccessesMap?.[desktop.id]?.find(access => {
        return (
          (accountId && access.account?.id === accountId) ||
          (teamId && access.workspaceTeam?.id === teamId)
        );
      }),
    );
  }, [desktops, desktopAccessesMap, accountId, teamId]);

  if (!desktops?.length) {
    return <AdminListEmptyMessage>{emptyMessage}</AdminListEmptyMessage>;
  }

  return (
    <AdminList data-testid="admin-list">
      {desktops.map((desktop, i) => (
        <RelatedDesktopsListItem
          key={desktop.id}
          desktop={desktop}
          desktopAccess={desktopAccesses[i]}
          disableAccessChange={
            deletableItemsMap && !deletableItemsMap?.[desktop.id]
          }
          isOnlyFullAccessAllowed={isOnlyFullAccessAllowed}
          onChangeAccess={onChangeAccess}
          onRemove={onRemove}
        />
      ))}
    </AdminList>
  );
};
