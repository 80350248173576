import { ThemeType } from './../../../../domains/Theme/Theme.types';
import { ChatMessageSearchInputThemesType } from './ChatMessageSearchInput.theme.types';

export const ChatMessageSearchInputTheme: ChatMessageSearchInputThemesType = {
  [ThemeType.light]: {
    colors: {
      ChatMessageSearchInputColor: 'rgb(235 236 237)',
    },
  },
  [ThemeType.dark]: {
    colors: {
      ChatMessageSearchInputColor: 'rgb(50 63 77)',
    },
  },
};
