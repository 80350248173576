import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ArrowLeftIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7803 17.7803C13.4874 18.0732 13.0126 18.0732 12.7197 17.7803L7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697L12.7197 6.21967C13.0126 5.92678 13.4874 5.92678 13.7803 6.21967C14.0732 6.51256 14.0732 6.98744 13.7803 7.28033L9.06066 12L13.7803 16.7197C14.0732 17.0126 14.0732 17.4874 13.7803 17.7803Z"
        fill="currentColor"
      />
    </svg>
  );
});
