import type {
  ApiConnection,
  CategoryApiType,
  ImageApiType,
} from '../../../../../shared/api/api.types';
import type { AppStoreAppApiType } from '../../../../AppStore/AppStore.types';
import type { FolderWithLinksApiType } from '../../../../Folder/Folder.types';
import type { LinkApiType } from '../../../../Link/Link.types';
import type { TeamApiType } from '../../../../Team/data/Team/types/Team.types';
import type {
  AccountApiType,
  AccountWithCountsApiType,
} from '../../../../User/User.types';
import type {
  DesktopTab,
  GRAPHQL_TYPENAME_APP,
  GRAPHQL_TYPENAME_DESKTOP,
  GRAPHQL_TYPENAME_DESKTOP_APP,
  GRAPHQL_TYPENAME_FAVORITE,
  GRAPHQL_TYPENAME_FAVORITE_CONNECTION,
  GRAPHQL_TYPENAME_FAVORITE_EDGE,
  GRAPHQL_TYPENAME_RECENTAPPS,
} from '../../../Desktop.constants';

export interface DesktopAppApiType {
  __typename?: typeof GRAPHQL_TYPENAME_APP;
  id: string;
  _id: string;
  name: string;
  fullName: string;
  logo: ImageApiType;
  category: CategoryApiType;
  groupApp: Pick<AppStoreAppApiType, 'id'>;
  hidden?: boolean;
  uri: string;
  openUri: string;
  loginUri: string;
  custom: boolean;
  hasSsoOverride: boolean;
  loginUriPattern: string;
  groupEnabled: boolean;
}

export interface DesktopAppEdgeApiType {
  __typename?: typeof GRAPHQL_TYPENAME_DESKTOP_APP;
  id: string;
  _id: string;
  app: DesktopAppApiType;
  desktop?: Pick<DesktopApiType, 'id'>;
  desktopId: string;
  workspaceId: string;
  accountFavorite: boolean;
  accountSortPosition: number;
  createdAt: string;
}

export interface DesktopApiType {
  __typename?: typeof GRAPHQL_TYPENAME_DESKTOP;
  id: string;
  accountIds: string[];
  name: string;
  description: string;
  shareable: boolean;
  memberCount: number;
  userCount: number;
  teamCount: number;
  accountSortPosition: number;
  chatConversationIri: string;
  chatEnabled: boolean;
  isGeneral: boolean;
  createdAt: string;
  createdBy?: Pick<AccountApiType, 'id' | 'firstName' | 'lastName'> | null;
  accountsThatChatHidden: string[];
  workspace: WorkspaceEventType;
}

export interface WorkspaceEventType {
  id: string;
  accountSortPosition: null;
  callLimit: null;
  createdAt: string;
  modifiedAt: string;
  name: string;
  type: string;
  uri: string;
  _id: string;
}

export enum DesktopAccessType {
  Manager = 'MANAGER',
  EditLink = 'EDIT_LINKS',
  CanComment = 'CAN_COMMENT',
}

export type DesktopAccessEdgeCommonValues = {
  id: string;
  accessType: DesktopAccessType;
  isChatHidden: boolean;
};

export type DesktopAccessEdgeApiType = DesktopAccessEdgeCommonValues &
  (
    | { account: AccountWithCountsApiType; workspaceTeam: null }
    | { account: null; workspaceTeam: TeamApiType }
  );

export interface DesktopWithAccessApiType extends DesktopApiType {
  access: ApiConnection<DesktopAccessEdgeApiType>;
}

export interface DesktopFormValues {
  name: string;
  description: string;
  shareable: boolean;
  addAccount?: boolean;
}

export interface DesktopForImportFormValues {
  name: string;
}

export interface DesktopCreateInputType extends DesktopFormValues {
  workspace: string;
}

export interface DesktopEditInputType
  extends Omit<DesktopFormValues, 'shareable'> {
  id: string;
}

export interface EditHidingChatInputType {
  id: string;
  isChatHidden: boolean;
}

export interface AccountDesktopInputType {
  id: string;
  account: string;
  accessType: DesktopAccessType;
}

export enum PermissionContext {
  user = 'user_context',
  admin = 'admin_context',
}

export interface DesktopPermissionsContextApiType {
  canAddApp: boolean;
  canAddLink: boolean;
  canEnableChat: boolean;
  canCreateFolder: boolean;
  canEdit: boolean;
  canEditFolder: boolean;
  canEditLink: boolean;
  canRemove: boolean;
  canRemoveApp: boolean;
  canRemoveFolder: boolean;
  canRemoveLink: boolean;
  canShare: boolean;
  canView: boolean;
  canViewFolder: boolean;
  canLeave: boolean;
  canCopyDesktopAppsSection: boolean;
  canCopyDesktopLinksSection: boolean;
  canPasteDesktopAppsSection: boolean;
  canPasteDesktopLinksSection: boolean;
  canDeleteDesktopAppsSection: boolean;
  canDeleteDesktopLinksSection: boolean;
  canHideChat: boolean;
  canCommentLinks: boolean;
}

export interface DesktopPermissionsApiType {
  createdInAdmin: boolean;
  desktop: string;
  name: string;
  admin_context: DesktopPermissionsContextApiType;
  user_context: DesktopPermissionsContextApiType;
}

export interface DesktopSortType {
  id: string;
  position: number;
}

export interface DesktopAppSortType {
  id: string;
  position: number;
}

export interface OrganizedDesktopsType {
  personalDesktops: DesktopApiType[];
  companyDesktops: DesktopApiType[];
}

export type FavoritesApiType = ApiConnection<
  FavoritesEdgeApiType,
  typeof GRAPHQL_TYPENAME_FAVORITE_CONNECTION,
  typeof GRAPHQL_TYPENAME_FAVORITE_EDGE
>;

export interface FavoritesEdgeApiType {
  id?: string;
  desktopApp?: DesktopAppEdgeApiType;
  link?: LinkApiType;
  folder?: FolderWithLinksApiType;
  __typename: typeof GRAPHQL_TYPENAME_FAVORITE;
}

export interface RecentAppsApiType {
  desktopApp: DesktopAppEdgeApiType;
  __typename: typeof GRAPHQL_TYPENAME_RECENTAPPS;
}

export enum DesktopAppGroupType {
  google = '/apps/uuid-app-group-google',
}

export interface DesktopContentCopyPasteType {
  section: DesktopTab.links | DesktopTab.apps | null;
  fromDesktop: string;
}
