import { getMercureUrl } from './GeneralMercure.utils';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { extractNodes } from '../../../shared/api/api.utils';
import { NotificationType } from '../../Notifications/Notifications.types';
import {
  DeletedNotificationUpdateApiType,
  GET_NOTIFICATION_UPDATES,
  GetNotificationUpdatesResponse,
  GetNotificationUpdatesVariables,
  NotificationUpdateApiType,
} from '../../Notifications/Notifications.queries';
import {
  MENTIONS_INITIAL_LOAD_COUNT,
  THREADS_INITIAL_LOAD_COUNT,
} from '../../Inbox/Inbox.constants';
import {
  addMentionToMentionsCache,
  addThreadToThreadsCache,
  removeMentionFromMentionsCache,
  removeThreadFromThreadsCache,
} from '../../Inbox/cache';
import {
  addNotificationToNotificationsCache,
  removeNotificationFromNotificationsCache,
  updateNotificationInCache,
} from '../../Notifications/cache';
import { NOTIFICATIONS_INITIAL_LOAD_COUNT } from '../../Notifications/Notifications.constants';
import { MercureContext } from './GeneralMercure.context';
import { StreamEvent } from './GeneralMercure.types';

export const useMercureUrl = () => {
  const { account } = useCurrentAccount();
  return useMemo(() => {
    return getMercureUrl(account?.mercureInfo?.general);
  }, [account]);
};

export const useNotificationsDelta = () => {
  const apolloClient = useApolloClient();

  const handleCreatedNotifications = useCallback(
    (
      createdNotifications: NotificationUpdateApiType[],
      workspaceId: string,
    ) => {
      createdNotifications.forEach(createdNotification => {
        const { recipient, ...notification } = createdNotification;
        if (!recipient) {
          return;
        }
        if (
          notification.message.type === NotificationType.USER_CHAT_MENTIONED
        ) {
          addMentionToMentionsCache(
            apolloClient,
            {
              accountId: recipient.id,
              workspaceId: workspaceId,
              first: MENTIONS_INITIAL_LOAD_COUNT,
            },
            notification,
          );
        } else if (
          notification.message.type === NotificationType.USER_CHAT_THREAD
        ) {
          addThreadToThreadsCache(
            apolloClient,
            {
              accountId: recipient.id,
              workspaceId: workspaceId,
              first: THREADS_INITIAL_LOAD_COUNT,
            },
            notification,
          );
        } else {
          addNotificationToNotificationsCache(
            apolloClient,
            {
              accountId: recipient.id,
              workspaceId: workspaceId,
              first: NOTIFICATIONS_INITIAL_LOAD_COUNT,
            },
            notification,
          );
        }
      });
    },
    [apolloClient],
  );

  const handleUpdatedNotifications = useCallback(
    (updatedNotifications: NotificationUpdateApiType[]) => {
      updatedNotifications.forEach(updatedMessage => {
        updateNotificationInCache(apolloClient, updatedMessage.id, {
          status: updatedMessage.status,
          message: {
            id: updatedMessage.message.id,
            fields: updatedMessage.message.fields,
          },
        });
      });
    },
    [apolloClient],
  );

  const handleDeletedNotifications = useCallback(
    (
      deletedNotifications: DeletedNotificationUpdateApiType[],
      workspaceId: string,
    ) => {
      deletedNotifications.forEach(deletedNotification => {
        const { id, recipient, message } = deletedNotification;
        if (!recipient) {
          return;
        }
        if (message.type === NotificationType.USER_CHAT_MENTIONED) {
          removeMentionFromMentionsCache(
            apolloClient,
            {
              accountId: recipient.id,
              workspaceId: workspaceId,
              first: MENTIONS_INITIAL_LOAD_COUNT,
            },
            id,
          );
        } else if (message.type === NotificationType.USER_CHAT_THREAD) {
          removeThreadFromThreadsCache(
            apolloClient,
            {
              accountId: recipient.id,
              workspaceId: workspaceId,
              first: THREADS_INITIAL_LOAD_COUNT,
            },
            id,
          );
        } else {
          removeNotificationFromNotificationsCache(
            apolloClient,
            {
              accountId: recipient.id,
              workspaceId: workspaceId,
              first: NOTIFICATIONS_INITIAL_LOAD_COUNT,
            },
            id,
          );
        }
      });
    },
    [apolloClient],
  );

  return useCallback(
    (variables: GetNotificationUpdatesVariables) => {
      return apolloClient
        .query<GetNotificationUpdatesResponse, GetNotificationUpdatesVariables>(
          {
            query: GET_NOTIFICATION_UPDATES,
            fetchPolicy: 'no-cache',
            variables,
          },
        )
        .then(response => {
          const createdNotifications = extractNodes(
            response.data.deltaCreatedNotifications,
          );
          const createdNotificationIds = createdNotifications.map(
            ({ id }) => id,
          );
          handleCreatedNotifications(
            createdNotifications,
            variables.workspaceIri,
          );
          handleUpdatedNotifications(
            extractNodes(response.data.deltaUpdatedNotifications).filter(
              ({ id }) => !createdNotificationIds.includes(id),
            ),
          );
          handleDeletedNotifications(
            extractNodes(response.data.deltaDeletedNotifications),
            variables.workspaceIri,
          );
        });
    },
    [
      apolloClient,
      handleCreatedNotifications,
      handleDeletedNotifications,
      handleUpdatedNotifications,
    ],
  );
};

export const useMercureListener = () => useContext(MercureContext);

export const useMercureListenerEffect = (
  listener: (e: StreamEvent) => void,
) => {
  const { addListener, removeListener } = useMercureListener();
  useEffect(() => {
    addListener(listener);
    return () => removeListener(listener);
  }, []); // eslint-disable-line
};
