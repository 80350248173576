import { SegmentLocale, SegmentTranslation } from './i18n.types';

export const enSegmentLocale: SegmentLocale = {
  [SegmentTranslation.segmentDesktops]: 'Desktops',
  [SegmentTranslation.segmentChats]: 'Chats',
  [SegmentTranslation.segmentMeetings]: 'Meetings',
  [SegmentTranslation.segmentPasswords]: 'Passwords',
  [SegmentTranslation.segmentVault]: 'Vault',
  [SegmentTranslation.segmentVaultPwmAvailable]:
    'To active a password manager please <br> reset or change your password',
};
