import { ConversationType } from '../../Chat/Chat.types';
import { DatabaseTablesType } from '../Database.types';

export const CHAT_CONVERSATIONS_TABLE_COLUMNS =
  '++id, workspaceId, title, type, userIds, lastMessageAt, createdById, pendingEmails, desktopId, pinnedAt, createdAt';

export interface ChatConversationsTableType
  extends Omit<DatabaseTablesType, 'createdAt'> {
  workspaceId: string;
  title: string;
  type: ConversationType;
  userIds: string[];
  lastMessageAt: string | null;
  createdById: string;
  pendingEmails: string[];
  desktopId: string | null;
  pinnedAt: string | null;
  createdAt: string;
  isHidden: boolean;
  isHiddenAt: string | null;
}
