import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon';
import { Button, ButtonMode, ButtonSize } from '../Button/Button';
import { Breakpoints } from '../../../domains/Theme';

export const StyledToastContainer = styled(ToastContainer).attrs({
  toastClassName: 'toast',
  bodyClassName: 'body',
})`
  .toast {
    align-items: flex-start;
    padding: 0.75rem;
    background-color: ${p => p.theme.colors.Surface};
    border: 1px solid ${p => p.theme.colors.BorderDefault};
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .body {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.3;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    word-break: break-word;
    img {
      margin-right: 1rem;
    }
    svg {
      flex-shrink: 0;
    }
    .toast-icon {
      margin-right: 1rem;
    }
  }
`;

export const StyledCloseButton = styled(ButtonWithIcon)`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  svg {
    width: 0.625rem;
    height: 0.625rem;
    transition: color 0.2s ease;
    &:hover {
      color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    }
  }

  @media (max-width: ${Breakpoints.lg - 1}px) {
    padding: 16px;
    transform: translate(12px, -12px);
  }
`;

export const ReloadAppMessageContainer = styled.div`
  padding: 10px 0 10px 10px;
  display: flex;
  justify-content: space-between;
`;

export const ReloadAppMessage = styled.span`
  flex-basis: 100%;
`;

export const ReloadAppButton = styled(Button).attrs({
  size: ButtonSize.sm,
  mode: ButtonMode.primary,
})`
  flex-shrink: 0;
  margin-left: 10px;
`;

export const BrowserNotFullySupportedContainer = styled.div`
  h3 {
    font-size: 0.875rem;
  }
  p {
    font-size: 0.75rem;
  }
`;

export const ScheduledDowntimeContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    font-family: ${p => p.theme.fonts.Semibold};
  }
`;
