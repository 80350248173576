import { SkeletonBox } from '../../../../../shared/components/SkeletonBox';
import { StyledChatBlock } from './AppsViewSkeleton.styled';
import React from 'react';
import { AppsHeader } from './AppsViewSkeleton';
import { useTheme } from 'styled-components';
import { MessageListSkeleton } from '../../../../Chat/ChatView/ConversationView/MessagesList/MessageListSkeleton/MessageListSkeleton';

export const ChatViewSkeleton = () => {
  const { colors } = useTheme();

  return (
    <StyledChatBlock>
      <AppsHeader buttonsLength={4} />
      <div className="chat-messages">
        <MessageListSkeleton data-testid="message-list-skeleton" />
        <div className="chat-input">
          <SkeletonBox
            width="100%"
            height={2.625}
            color={colors.ChatViewSkeleton}
            borderRadius={0.5}
            margin={[1, 0, 0, 0]}
          />
        </div>
      </div>
    </StyledChatBlock>
  );
};
