import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const CheckmarkIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="checkmark-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M11.78 0.219828C11.9204 0.360448 11.9993 0.551078 11.9993 0.749828C11.9993 0.948578 11.9204 1.1392 11.78 1.27983L4.53 8.52983C4.38937 8.67023 4.19875 8.74913 4 8.74913C3.80125 8.74913 3.61062 8.67023 3.47 8.52983L0.220004 5.27983C0.0875236 5.13765 0.0154036 4.94961 0.0188236 4.7553C0.0222536 4.561 0.100963 4.37562 0.238383 4.2382C0.375793 4.10079 0.561184 4.02208 0.755484 4.01865C0.949784 4.01522 1.13782 4.08735 1.28 4.21983L4 6.93983L10.72 0.219828C10.8606 0.0793781 11.0512 0.000488281 11.25 0.000488281C11.4487 0.000488281 11.6394 0.0793781 11.78 0.219828Z"
      />
    </svg>
  );
});
