import { gql } from '@apollo/client';
import { ApiConnection } from '../../../../../shared/api/api.types';
import { FolderFragmentFields } from '../../../../Folder/Folder.fragments';
import { FolderApiType } from '../../../../Folder/Folder.types';

export const GET_DESKTOP_FOLDERS_REST = gql`
  query GetDesktopFoldersRest($desktopId: ID!) {
    desktopFolders(desktopId: $desktopId)
      @rest(path: "/desktop/{args.desktopId}/folders")
      @connection(key: "desktop_folders_rest", filter: ["args.desktopId"]) {
        edges {
          node {
            ${FolderFragmentFields}
          }
        }
    }
  }
`;

export interface GetDesktopFoldersRestVariables {
  desktopId: string;
}

export interface GetDesktopFoldersRestResponse {
  desktopFolders: ApiConnection<FolderApiType>;
}
