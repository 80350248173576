import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalProps,
} from '../../../shared/components/Modal';
import styled from 'styled-components';
import { OfflineTranslation } from '../i18n';
import { FormattedMessage } from 'react-intl';
import { offlineIllustrationUrl } from '../Offline.constants';

const OfflineModalContent = styled.div`
  text-align: center;

  h2 {
    margin-bottom: 0.5rem;
  }

  img {
    width: 100%;
    margin-top: 3.5rem;
  }
`;

interface OfflineModalProps
  extends Omit<ModalProps, 'visible' | 'onRequestClose'> {}

export const OfflineModal: FC<OfflineModalProps> = () => {
  return (
    <Modal visible={true} onRequestClose={() => {}}>
      <ModalContent>
        <OfflineModalContent>
          <FormattedMessage
            tagName="h2"
            id={OfflineTranslation.offlineModalTitle}
          />
          <FormattedMessage
            tagName="p"
            id={OfflineTranslation.offlineModalContent}
          />
          <img src={offlineIllustrationUrl} role="presentation" />
        </OfflineModalContent>
      </ModalContent>
    </Modal>
  );
};
