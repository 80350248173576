import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const KeyboardArrowUpIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21967 14.7803C5.92678 14.4874 5.92678 14.0126 6.21967 13.7197L11.4697 8.46967C11.7626 8.17678 12.2374 8.17678 12.5303 8.46967L17.7803 13.7197C18.0732 14.0126 18.0732 14.4874 17.7803 14.7803C17.4874 15.0732 17.0126 15.0732 16.7197 14.7803L12 10.0607L7.28033 14.7803C6.98744 15.0732 6.51256 15.0732 6.21967 14.7803Z"
        fill="currentColor"
      />
    </svg>
  );
});
