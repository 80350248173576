import { ThemeType } from '../../Theme';
import { AvailabilityThemesType } from './Account.theme.types';

export const AccountTheme: AvailabilityThemesType = {
  [ThemeType.light]: {
    colors: {
      OnlineStatusActive: '#50B83C',
      OnlineStatusAway: '#EEC200',
      OnlineStatusBusy: '#DE3618',
      OnlineStatusOffline: '#FFF',
      OnlineStatusOfflineBorder: '#454F5B',
      OnlineStatusDefaultBorder: '#FFF',
    },
  },
  [ThemeType.dark]: {
    colors: {
      OnlineStatusActive: '#50B83C',
      OnlineStatusAway: '#EEC200',
      OnlineStatusBusy: '#DE3618',
      OnlineStatusOffline: '#FFF',
      OnlineStatusOfflineBorder: '#454F5B',
      OnlineStatusDefaultBorder: '#021121',
    },
  },
};
