import { createGlobalStyle } from 'styled-components';

export const SCROLLBAR_WIDTH = 6;
export const HIDE_SCROLL_CLASSNAME = 'hide-scroll';

export const StyledRootStyles = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fonts.Regular};
    color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  }
`;

export const StyledCustomScrollbars = createGlobalStyle`
  body {
    /* Firefox */
    * {
      scrollbar-width: thin;
      scrollbar-color: ${({ theme }) =>
        theme.colors.OnSurfaceLightEmphasis} transparent;
    }

    /* Webkit */
    *::-webkit-scrollbar {
      width: ${SCROLLBAR_WIDTH}px;
      height: ${SCROLLBAR_WIDTH}px;
    }

    *::-webkit-scrollbar-track,
    *::-webkit-scrollbar-corner {
      background: transparent;
    }

    *::-webkit-scrollbar-thumb {
      border-radius: ${SCROLLBAR_WIDTH / 2}px;
      background: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
      transition: 0.2s background ease-out;
    }
  }

  .${HIDE_SCROLL_CLASSNAME}:not(:hover) {
    scrollbar-color: transparent transparent;
    transition: 0.2s scrollbar-color ease-out;

    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
`;
