import { DesktopLocale, DesktopTranslation } from './i18n.types';

export const enDesktopLocale: DesktopLocale = {
  [DesktopTranslation.menuManageUsersLink]: 'Manage users',
  [DesktopTranslation.menuEditDesktop]: 'Rename desktop',
  [DesktopTranslation.menuLeaveDesktop]: 'Leave {desktopName}',
  [DesktopTranslation.menuRemoveDesktop]: 'Remove desktop',
  [DesktopTranslation.menuTimeline]: 'Activity',

  [DesktopTranslation.desktopSubtitleSharedBy]: 'Shared by {name}',
  [DesktopTranslation.desktopSubtitleSharedExternally]: 'External guests',
  [DesktopTranslation.desktopSubtitleSharedExternallyBy]:
    'External guests – shared by {name}',
  [DesktopTranslation.desktopSubtitleCurrentUserName]: 'you',

  [DesktopTranslation.shareButtonLabel]: 'Share',
  [DesktopTranslation.shareModalTitle]: 'Manage users',

  [DesktopTranslation.accessRemoveAccess]: 'Remove',
  [DesktopTranslation.accessRemoveAccessConfirmation]:
    'Remove access for this user?',
  [DesktopTranslation.accessRemoveTeamAccessConfirmation]:
    'Remove access for this group?',
  [DesktopTranslation.accessNotShared]: 'Not shared with anybody yet',
  [DesktopTranslation.accessPendingInvitation]: 'Pending invitation',
  [DesktopTranslation.accessRevokePendingInvitation]: 'Revoke',
  [DesktopTranslation.accessInviteAccountToPrivateDesktopPlaceholder]:
    'Add members or external guests',
  [DesktopTranslation.accessInviteAccountToInternalDesktopPlaceholder]:
    'Add members or groups',
  [DesktopTranslation.accessInviteAccountSubmit]: 'Invite',
  [DesktopTranslation.accessInviteAccountTooltip]: 'Start typing to search',
  [DesktopTranslation.accessInternalDesktopsInviteAccountHelpText]:
    'You can invite more workspace members',
  [DesktopTranslation.accessPrivateDesktopsInviteAccountHelpText]:
    'Invite existing members or guests. To invite a new external guest user to this desktop, type their email address.',
  [DesktopTranslation.accessInviteAccountLinkText]: 'here.',
  [DesktopTranslation.accessTeam]: 'Group',
  [DesktopTranslation.accessTeamMembers]:
    '{count} {count, plural, =1 {member} other {members}}',
  [DesktopTranslation.accessTeamTitle]: '{team} at {workspace}',
  [DesktopTranslation.accessAccount]: 'Account ({email})',
  [DesktopTranslation.accessGuestAccount]: 'Guest account ({email})',
  [DesktopTranslation.accessGuest]: 'Send invitation to {email}',
  [DesktopTranslation.accessPickerDisabledForAdmin]:
    'Cannot select this permission for admin',

  [DesktopTranslation.appsLayoutGrid]: 'Grid',
  [DesktopTranslation.appsLayoutFluid]: 'Fluid',

  [DesktopTranslation.adminListHeaderTitle]: 'Desktops',
  [DesktopTranslation.adminListHeaderSubtitle]: 'Internal desktops',
  [DesktopTranslation.adminListAddButton]: 'New desktop',
  [DesktopTranslation.adminListEmptyMessage]: 'No desktops to display',
  [DesktopTranslation.adminSingleDesktopGeneralInfo]: 'General information',
  [DesktopTranslation.adminSingleDesktopEditButton]: 'Edit',
  [DesktopTranslation.adminSingleDesktopOpenButton]: 'Open desktop',
  [DesktopTranslation.adminSingleDesktopApps]: 'Apps',
  [DesktopTranslation.adminSingleDesktopAppStoreLink]: 'Open App Store',
  [DesktopTranslation.adminSingleDesktopNotFound]: 'Desktop not found',

  [DesktopTranslation.adminDesktopTeams]: 'Groups',
  [DesktopTranslation.adminDesktopTeamsEmptyMessage]:
    'This desktop has no related groups yet',
  [DesktopTranslation.adminDesktopTeamsManageButton]: 'Manage',
  [DesktopTranslation.adminDesktopTeamsRemoveConfirmation]:
    'Remove this group from related list?',
  [DesktopTranslation.adminDesktopTeamsManageModalHeader]: 'Manage groups',

  [DesktopTranslation.adminDesktopUsers]: 'Members',
  [DesktopTranslation.adminDesktopUsersEmptyMessage]:
    'This desktop has no related members yet',
  [DesktopTranslation.adminDesktopUsersManageButton]: 'Manage',
  [DesktopTranslation.adminDesktopUsersRemoveConfirmation]:
    'Remove this member from desktop?',
  [DesktopTranslation.adminDesktopUsersManageModalHeader]: 'Manage members',

  [DesktopTranslation.adminSingleDesktopDangerZone]: 'Danger zone',
  [DesktopTranslation.adminSingleDesktopDangerZoneDeleteButton]:
    'Delete desktop',
  [DesktopTranslation.adminSingleDesktopDangerZoneDeleteWarning]:
    'Deleting a desktop cannot be reversed',
  [DesktopTranslation.adminSingleDesktopDangerZoneDeleteConfirmation]:
    'Delete this desktop?',

  [DesktopTranslation.createConferenceConfirmationTitle]:
    'Call all members of {desktopName}',
  [DesktopTranslation.createConferenceConfirmationDescription]:
    'You are about to call all {countOfUsersForCall} members of {desktopName}, are you sure you want to proceed?',

  [DesktopTranslation.manageModalUpdateButton]: 'Update',

  [DesktopTranslation.listTitle]: 'Desktops',
  [DesktopTranslation.listFavorites]: 'Favorites',
  [DesktopTranslation.listVault]: 'Vault',
  [DesktopTranslation.listPasswords]: 'Passwords',
  [DesktopTranslation.listRecent]: 'Recent',
  [DesktopTranslation.listChats]: 'Chats',
  [DesktopTranslation.listConferences]: 'Meetings',
  [DesktopTranslation.listInternalDesktops]: 'Internal desktops',
  [DesktopTranslation.listSharedDesktops]: 'Shared desktops',
  [DesktopTranslation.listPrivateDesktops]: 'Private desktops',
  [DesktopTranslation.listExternallySharedDesktopLabel]: 'Ext',
  [DesktopTranslation.listAddButton]: 'New desktop',
  [DesktopTranslation.listInternalDesktopsEmptyText]:
    'You’re not part of any internal desktop <a href="{desktopTypesUrl}" target="_blank" rel="noopener noreferrer">Learn more</a>',
  [DesktopTranslation.listSharedDesktopsEmptyText]:
    'You don\'t have any shared desktops <a href="{desktopTypesUrl}" target="_blank" rel="noopener noreferrer">Learn more</a>',
  [DesktopTranslation.listPrivateDesktopsEmptyText]:
    'You don\'t have any private desktops <a href="{desktopTypesUrl}" target="_blank" rel="noopener noreferrer">Learn more</a>',
  [DesktopTranslation.listNewMeetingButtonTooltip]: 'New meeting',
  [DesktopTranslation.listScheduleConferenceButtonTooltip]: 'Schedule meeting',
  [DesktopTranslation.listAddNewDesktopButton]: 'Add new desktop',

  [DesktopTranslation.createModalHeader]: 'Add new desktop',
  [DesktopTranslation.createInternalDesktopModalHeader]:
    'Add new internal desktop',
  [DesktopTranslation.createSubmitButton]: 'Create',
  [DesktopTranslation.createCancelButton]: 'Cancel',
  [DesktopTranslation.editModalHeader]: 'Rename desktop',
  [DesktopTranslation.editSubmitButton]: 'Update',
  [DesktopTranslation.formInternalDesktopMessageTitle]:
    'Make this desktop internal',
  [DesktopTranslation.formInternalDesktopSubMessageTitle]: 'Admins only',
  [DesktopTranslation.formInternalDesktopMessageText]:
    'This will create a desktop for internal teams only. No guests can have access – <a href="{learnMoreUrl}" target="_blank" rel="noopener noreferrer">Learn more</a>',
  [DesktopTranslation.formNameLabel]: 'Name',
  [DesktopTranslation.formNamePlaceholder]: 'Type the desktop name',
  [DesktopTranslation.formNameRequiredError]: 'Please enter desktop name',
  [DesktopTranslation.formDescriptionLabel]: 'Description',
  [DesktopTranslation.formDescriptionPlaceholder]:
    'Add an optional description',
  [DesktopTranslation.formAddAccountLabel]: 'Add me to this desktop',
  [DesktopTranslation.formAddImportDesktopLabel]: 'Import desktop name',
  [DesktopTranslation.formDefaultDesktopName]: 'Browser import',
  [DesktopTranslation.formContinueButton]: 'Continue',

  [DesktopTranslation.headerAddToDesktop]: 'Add to desktop',
  [DesktopTranslation.headerApps]: 'Apps',
  [DesktopTranslation.headerLinks]: 'Links',
  [DesktopTranslation.headerChat]: 'Chat',
  [DesktopTranslation.headerAddButton]: 'Add',
  [DesktopTranslation.headerButtonAddApp]: 'Add app',
  [DesktopTranslation.headerButtonAddLink]: 'Add link',
  [DesktopTranslation.headerAddApp]: 'Add an app',
  [DesktopTranslation.headerAddLink]: 'Add a link',
  [DesktopTranslation.headerCreateFolder]: 'Create links folder',
  [DesktopTranslation.headerEnableChat]: 'Enable chat',
  [DesktopTranslation.headerChatIsEnabled]: 'Chat already enabled',
  [DesktopTranslation.headerTeamsCounter]:
    '{teamsCount} {teamsCount, plural, one {group} other {groups}}',
  [DesktopTranslation.headerUsersCounter]:
    '{usersCount} {usersCount, plural, one {user} other {users}}',

  [DesktopTranslation.sectionTitlebarOpenInChatsTooltip]: 'Open in chats',
  [DesktopTranslation.sectionTitlebarAddLinkTooltip]: 'Add link',
  [DesktopTranslation.sectionTitlebarCreateFolderTooltip]: 'Add folder',
  [DesktopTranslation.sectionTitlebarOptionsTooltip]: 'More',
  [DesktopTranslation.sectionTitlebarMaximiseTooltip]: 'Maximise',
  [DesktopTranslation.sectionTitlebarFilterAppsTooltip]: 'Filter apps',
  [DesktopTranslation.sectionTitlebarFilterAppsPlaceHolder]: 'Filter apps',
  [DesktopTranslation.sectionTitlebarFilterLinksTooltip]: 'Filter links',
  [DesktopTranslation.sectionTitlebarFilterLinksPlaceHolder]: 'Filter links',
  [DesktopTranslation.sectionTitlebarFilterMessagePlaceHolder]:
    'Search messages',
  [DesktopTranslation.sectionDeleteMenuItem]: 'Delete section',
  [DesktopTranslation.minimizeButtonTooltip]: 'Minimize',
  [DesktopTranslation.sectionDeleteAppsConfirmationText]:
    'Are you sure want to delete the entire section? {deleteCount} {deleteCount, plural, =1 {app} other {apps}} will be deleted.',
  [DesktopTranslation.sectionDeleteLinksConfirmationText]:
    'Are you sure want to delete the entire section? {deleteCount} {deleteCount, plural, =1 {link} other {links}} will be deleted.',

  [DesktopTranslation.notAvailableDesktop]:
    'This desktop does not exist, or you have no access.',
  [DesktopTranslation.desktopEmptyState]: 'Your desktop will appear here.',
  [DesktopTranslation.emptyHeader]: 'Start adding content to your desktop!',
  [DesktopTranslation.importYourApps]:
    'Import your apps and bookmarks from a chromium based browser',
  [DesktopTranslation.emptyAppsStateHelpText]:
    'Need help starting out? Start an',
  [DesktopTranslation.emptyAppsStateOnboardingTour]: ' onboarding tour ',
  [DesktopTranslation.emptyAppsStateFaqLink]:
    'or consult our  <a href="{faqUrl}" target="_blank" rel="noopener noreferrer">FAQ</a>.',
  [DesktopTranslation.addNewApp]: 'Add a new app from the App store',
  [DesktopTranslation.emptyMessage]:
    'Desktops let you keep your apps organized in one place. All your apps will be listed here.',
  [DesktopTranslation.emptyAddAppsButton]: 'Add new apps',
  [DesktopTranslation.emptyAddAppsOr]: 'or',
  [DesktopTranslation.emptyAppsAppDummyTitle]: 'App',
  [DesktopTranslation.emptyAppsMessageFavorites]:
    'You don’t have any favorite apps at the moment',
  [DesktopTranslation.emptyAppsMessageFavoritesInstructions]:
    'Hover over an app to add them to your favorites',

  [DesktopTranslation.importFromBrowserButton]: 'Import from browser',
  [DesktopTranslation.importLoginComputerBrowserWarning]:
    'You can import your existing browser bookmarks when logged in on a computer.<br> <a href="{importFaqUrl}" target="_blank" rel="noopener noreferrer">Learn more</a>',

  [DesktopTranslation.appLinkFavorite]: 'Add to favorites',
  [DesktopTranslation.appLinkSSOLabel]: 'SSO',
  [DesktopTranslation.appLinkAddToDesktop]: 'Add to desktop',
  [DesktopTranslation.appSelectVaultApp]: 'Choose password',
  [DesktopTranslation.appLinkAppLinks]: 'App links',
  [DesktopTranslation.appLinkCopyLoginApp]: 'Copy login',
  [DesktopTranslation.appLinkCopyPasswordApp]: 'Copy password',
  [DesktopTranslation.appShowDetailsApp]: 'Show details',
  [DesktopTranslation.appLinkRemoveApp]: 'Remove app',
  [DesktopTranslation.appLinkRemoveAppConfirmation]:
    'Are you sure? This action will remove the app for all users with access to this desktop',
  [DesktopTranslation.appLinkLoginCopiedMessage]:
    'Login was copied successfully',
  [DesktopTranslation.appLinkPasswordCopiedMessage]:
    'Password was copied successfully',

  [DesktopTranslation.leaveConfirmation]: 'Leave this desktop?',
  [DesktopTranslation.deleteConfirmation]: 'Delete this desktop?',

  [DesktopTranslation.appStoreModalHeaderTitle]: 'Add apps to {desktopName}',

  [DesktopTranslation.upgradeBannerProTitle]: 'Unlock all features',
  [DesktopTranslation.upgradeBannerProText]:
    '<li>More members</li><li>More storage</li><li>Larger meetings</li><li>App integrations + +</li>',
  [DesktopTranslation.upgradeBannerProCtaText]: 'Upgrade to pro',

  [DesktopTranslation.moveItemToDesktopConfirmation]:
    'Are you sure you want to move <strong class="truncate-content">{itemName}</strong> to <strong>{desktopName}</strong>?',
  [DesktopTranslation.moveItemsToDesktopConfirmation]:
    'Are you sure you want to move {count} links to <strong>{desktopName}</strong>?',
  [DesktopTranslation.moveItemToDesktopConfirmButtonText]: 'Move',

  [DesktopTranslation.addItemToFavorites]: 'Add to favorites',
  [DesktopTranslation.removeItemFromFavorites]: 'Remove from favorites',

  [DesktopTranslation.selectLinkCheckbox]: 'Select this link',

  [DesktopTranslation.moveLinkConfirmHeader]: 'Move link',
  [DesktopTranslation.moveFolderConfirmHeader]: 'Move folder',
  [DesktopTranslation.moveAppConfirmHeader]: 'Move app',
  [DesktopTranslation.movedLinkToDesktopSuccess]:
    'Link was moved to {desktopName}',
  [DesktopTranslation.movedLinksToDesktopSuccess]:
    '{count} links were moved to {desktopName}',
  [DesktopTranslation.movedAppToDesktopSuccess]: 'Moved app to {desktopName}',
  [DesktopTranslation.movedFolderToDesktopSuccess]:
    'Moved folder to {desktopName}',
  [DesktopTranslation.noMoveLinkPermission]:
    'You\'re not allowed to move the link <strong class="truncate-content">{linkName}</strong> to <strong>{desktopName}</strong>',
  [DesktopTranslation.noMoveFolderPermission]:
    'You\'re not allowed to move the folder <strong class="truncate-content">{folderName}</strong> to <strong>{desktopName}</strong>',
  [DesktopTranslation.noMoveAppPermission]:
    'You\'re not allowed to move the app <strong class="truncate-content">{appName}</strong> to <strong>{desktopName}</strong>',

  [DesktopTranslation.showOptionsButtonLabel]: 'Show app menu',
  [DesktopTranslation.hideOptionsButtonLabel]: 'Hide app menu',

  [DesktopTranslation.showChatButtonLabel]: 'Show chat',
  [DesktopTranslation.hideChatButtonLabel]: 'Hide chat',

  [DesktopTranslation.searchAppsResultNoFound]: 'No results found',
  [DesktopTranslation.filterButton]: 'Filter',
  [DesktopTranslation.filterInputPlaceholder]: 'Filter...',
  [DesktopTranslation.filterCancelButtonText]: 'Cancel',
  [DesktopTranslation.filterEmptyStateTitle]: 'No results found',
  [DesktopTranslation.filterEmptyStateDescription]:
    'You can try searching in all the workspace instead',

  [DesktopTranslation.desktopEmptyViewTitle]:
    'Start adding content to <strong>{desktopName}</strong>',
  [DesktopTranslation.desktopEmptyViewSubTitle]:
    'Click the + Add button to add content to your desktop You can add link, apps and chats.',
  [DesktopTranslation.desktopEmptyViewFooter]:
    'Need some help to get started? ',
  [DesktopTranslation.desktopEmptyViewFooterStartAn]: 'Start an ',
  [DesktopTranslation.desktopEmptyFavoritesHeader]:
    "You don't have any favorite apps or links at the moment",
  [DesktopTranslation.desktopEmptyFavoritesMessage]:
    'Click the heart icon on an app or link to add it to your favorites.',
  [DesktopTranslation.desktopEmptyViewFooterOnboardingTour]: 'onboarding tour ',
  [DesktopTranslation.desktopEmptyViewFooterFaq]:
    'our consult our <a href={faqUrl} target="_blank" rel="noopener noreferrer">FAQ.</a>',

  [DesktopTranslation.chromeExtensionCredentials]:
    'You must install our browser plugin to use stored username and password when opening apps. <a href={extensionLink} target="_blank" rel="noopener noreferrer">Take me there</a>',

  [DesktopTranslation.tooltipManageDesktop]: 'Manage desktop',
  [DesktopTranslation.tooltipGlobalSearchWorkspace]: 'Search and recent',
  [DesktopTranslation.tooltipPeopleButton]: 'People',
  [DesktopTranslation.tooltipNotificationsButton]: 'Notifications',
  [DesktopTranslation.tooltipInboxButton]: 'Inbox',
  [DesktopTranslation.tooltipActivityButton]: 'View this desktop activity',
  [DesktopTranslation.tooltipShareDesktopButton]: 'Share this desktop',
  [DesktopTranslation.tooltipAddDesktopContentButton]:
    'Add content to this desktop',
  [DesktopTranslation.tooltipVideoCallButton]:
    'Video call all members of<br>{desktopName}',
  [DesktopTranslation.tooltipAudioCallButton]:
    'Call all members of<br>{desktopName}',
  [DesktopTranslation.tooltipProfileAndSettings]: 'Your profile and settings',

  [DesktopTranslation.callToJustOneOrCoupleUsers]:
    'To call just one or a couple of users, go to Meetings in the left side panel.',
  [DesktopTranslation.callAllMembersButton]: 'Call all members',
  [DesktopTranslation.callAllMembersEmptyList]:
    'Your desktop is empty, please fill free to share it with somebody.',
  [DesktopTranslation.tooltipInternalDesktopList]:
    'Desktops accessible only to <br> members of the workspace ',
  [DesktopTranslation.tooltipSharedlDesktopList]:
    'Desktops shared with you or by you',
  [DesktopTranslation.tooltipPrivateDesktopList]:
    'Only you can see and manage <br> your private desktops',
  [DesktopTranslation.mobileMenuFavorites]: 'Favorites',

  [DesktopTranslation.copyDesktopSectionMenuItem]: 'Copy section to...',
  [DesktopTranslation.copyDesktopAppsSectionMenuItem]: 'Copy all apps',
  [DesktopTranslation.copyDesktopLinksSectionMenuItem]: 'Copy all links',

  [DesktopTranslation.copyDesktopAppsSectionTooltip]:
    'Copy all apps to clipboard',
  [DesktopTranslation.copyDesktopLinksSectionTooltip]:
    'Copy all links to clipboard',

  [DesktopTranslation.copyDesktopAppsSuccess]:
    'Successfully pasted to the apps section.',
  [DesktopTranslation.copyDesktopLinksSuccess]:
    'Successfully pasted to the links section.',
  [DesktopTranslation.copyDesktopAppsDataNote]: 'Apps copied to the clipboard',
  [DesktopTranslation.copyDesktopLinksDataNote]:
    'Links copied to the clipboard',

  [DesktopTranslation.pasteDataDesktopControlsMenuItem]: 'Paste {pasteSection}',
  [DesktopTranslation.pasteDesktopDataMenuItem]: 'Paste in section',
  [DesktopTranslation.pasteDesktopAppsDataMenuItem]: 'Paste apps',
  [DesktopTranslation.pasteDesktopAppsSectionTooltip]:
    'Paste apps from clipboard',
  [DesktopTranslation.pasteDesktopLinksDataMenuItem]: 'Paste links',
  [DesktopTranslation.pasteDesktopLinksSectionTooltip]:
    'Paste links from clipboard',
  [DesktopTranslation.pasteDesktopDataSectionTooltip]:
    'Paste {pasteDataType} from clipboard',

  [DesktopTranslation.pasteClipboardAppsConfirmationTitle]:
    'Paste apps in this desktop.',
  [DesktopTranslation.pasteClipboardAppsConfirmationDescription]:
    'Are you sure you want to paste apps in the desktop {desktopName} ?',
  [DesktopTranslation.pasteClipboardAppsConfirmationButtonLabel]: 'Paste apps',
  [DesktopTranslation.pasteClipboardSingleApp]: 'app',
  [DesktopTranslation.pasteClipboardManyApps]: 'apps',

  [DesktopTranslation.pasteClipboardLinksConfirmationTitle]:
    'Paste links in this desktop.',
  [DesktopTranslation.pasteClipboardLinksConfirmationDescription]:
    'Are you sure you want to paste links in the desktop {desktopName} ?',
  [DesktopTranslation.pasteClipboardLinksConfirmationButtonLabel]:
    'Paste links',
  [DesktopTranslation.pasteClipboardSingleLink]: 'link',
  [DesktopTranslation.pasteClipboardManyLinks]: 'links',

  [DesktopTranslation.chatSearchMessageListNoResults]: 'No results found',

  [DesktopTranslation.tooltipCreateDesktop]: 'Create a new desktop',
  [DesktopTranslation.tooltipFavoritesMenuItem]: 'Your favorite apps and links',

  [DesktopTranslation.tooltipPasswordsMenuItem]: 'Your stored passwords',
  [DesktopTranslation.tooltipPasswordsUnlockButton]:
    'You need to enter your password to unlock this page',
  [DesktopTranslation.tooltipChatsMenuItem]: 'Your chats',
  [DesktopTranslation.tooltipCallsAndMeetingsMenuItem]:
    'Your calls and meetings',

  [DesktopTranslation.tooltipExt]: 'External guests have access',
  [DesktopTranslation.tooltipAppMoreActions]: 'More actions',
  [DesktopTranslation.tooltipShowNavigation]: 'Always show menu column',
  [DesktopTranslation.tooltipHideNavigation]: 'Auto-hide menu column',
  [DesktopTranslation.appMarkAsFavorite]: 'Mark as favorite',
  [DesktopTranslation.appUnMarkAsFavorite]: 'Unmark as favorite',

  [DesktopTranslation.usersSelectGroupTypeGroups]: 'Groups',
  [DesktopTranslation.userRightsFullAccess]: 'Full access',
  [DesktopTranslation.userRightsFullAccessSubTitle]:
    'Can edit everything and manage users',
  [DesktopTranslation.userRightsCanEdit]: 'Can edit links',
  [DesktopTranslation.userRightsCanEditSubTitle]: 'Can edit links and comment',
  [DesktopTranslation.userRightsCanComment]: 'Can comment',
  [DesktopTranslation.userRightsCanCommentSubTitle]:
    'Can only view and comment',
};
