import {
  minutesToMilliseconds,
  secondsToMilliseconds,
} from '../TimeDate/TimeDate.utils';

export const TICK_DURATION = minutesToMilliseconds(1); // should be 1 for prod
export const PING_ONLINE_AT_TICK = 1;
export const PING_AWAY_AT_TICK = 4;
export const PING_BUSY_AT_TICK = PING_AWAY_AT_TICK;

export const AWAY_THRESHOLD = minutesToMilliseconds(15);
export const OFFLINE_THRESHOLD = minutesToMilliseconds(30);

export const USER_ACTIVITY_DEBOUNCE_TIME = secondsToMilliseconds(1);

export const BUSY_STATE_VALID_TO_TIMESTAMP_KEY = 'busy_valid';
export const INVISIBLE_STATE_VALID_TO_TIMESTAMP_KEY = 'invisible_valid';

export const HOUR_OF_DAY_FOR_VALID_TIMESTAMP = 8;
export const DELETED_ACCOUNT_ID = 'deleted-account';

export const userActivityEvents = ['mousemove', 'keydown', 'touchstart'];
