import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const VideoCallIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}>
      <path
        d="M16 3.75c0-.27-.145-.52-.38-.653-.236-.133-.524-.13-.756.01L11 5.425V4.75C11 3.784 10.216 3 9.25 3h-7.5C.784 3 0 3.784 0 4.75v6.5C0 12.216.784 13 1.75 13h7.5c.966 0 1.75-.784 1.75-1.75v-.675l3.864 2.318c.232.14.52.143.755.01.236-.133.381-.383.381-.653v-8.5zm-5 5.075l3.5 2.1v-5.85l-3.5 2.1v1.65zM9.5 6.75v-2c0-.138-.112-.25-.25-.25h-7.5c-.138 0-.25.112-.25.25v6.5c0 .138.112.25.25.25h7.5c.138 0 .25-.112.25-.25v-4.5z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
});
