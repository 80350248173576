import { GlobalLocale, GlobalTranslation, Locale } from './i18n.types';
import { enDesktopLocale } from '../../Desktop/i18n';
import { enOnboardingLocale } from '../../Onboarding/i18n';
import { enWorkspaceLocale } from '../../Workspace/i18n';
import { enBillingLocale } from '../../Billing/i18n';
import { enAccountLocale } from '../../Account/i18n';
import { enTeamLocale } from '../../Team/i18n';
import { enOfflineLocale } from '../../Offline/i18n';
import { enUserLocale } from '../../User/i18n';
import { enAppStoreLocale } from '../../AppStore/i18n';
import { enGeneralSettingsLocale } from '../../GeneralSettings/i18n';
import { enLinkLocale } from '../../Link/i18n';
import { enGlobalSearchLocale } from '../../GlobalSearch/i18n';
import { enNotificationLocale } from '../../Notifications/i18n';
import { enTimelineLocale } from '../../Timeline/i18n';
import { enAppRootLocale } from '../../AppRoot/i18n';
import { enChromeExtensionLocale } from '../../ChromeExtension/i18n';
import { enDomainLocale } from '../../Domain/i18n';
import { enMaintenanceLocale } from '../../Maintenance/i18n';
import { enChatLocale } from '../../Chat/i18n';
import { enConferenceLocale } from '../../Conference/i18n';
import { enEncryptionLocale } from '../../Encryption/i18n';
import { enInboxLocale } from '../../Inbox/i18n';
import { enWebPushLocale } from '../../WebPush/i18n';
import { enFolderLocale } from '../../Folder/i18n';
import { enVideoPlayerLocale } from '../../VideoPlayer/i18n';
import { enDocumentPreviewLocale } from '../../Preview/DocumentPreview/i18n';
import { enSubscriptionLocale } from '../../Subscription/i18n';
import { externalServiceAuthLocale } from '../../ExternalServiceAuth/ExternalServiceAuthContent/i18n';
import { enPasswordLocale } from '../../Password/i18n';
import { enSegmentLocale } from '../../Segment/i18n';
import { enAnnouncementLocale } from '../../Announcement/i18n';
import { enAppIntegrationsLocale } from '../../AppIntegrations/i18n';
import { enAuthLocale } from '../../Auth/i18n';
import { enDropBoxErrorPageLocale } from '../../../shared/components/DropboxErrorPage/i18n';
import { enPwaLocale } from '../../PWA/i18n';

export const enGlobalLocale: GlobalLocale = {
  [GlobalTranslation.headTitle]: 'Webtop.com App',

  [GlobalTranslation.logoAlt]: 'Desktops.com logo',

  [GlobalTranslation.labelsNoData]: 'No data',
  [GlobalTranslation.labelNothingFound]: 'Nothing found',
  [GlobalTranslation.labelSendinvite]: 'Send invite',
  [GlobalTranslation.labelClose]: 'Close',
  [GlobalTranslation.labelSelect]: 'Select',

  [GlobalTranslation.searchInputLabel]: 'Search',
  [GlobalTranslation.searchInputPlaceholder]: 'Search...',
  [GlobalTranslation.searchInputClearButtonAriaLabel]: 'Clear search input',

  [GlobalTranslation.toastSaveMessage]: 'Saved',

  [GlobalTranslation.save]: 'Save',
  [GlobalTranslation.ok]: 'Ok',
  [GlobalTranslation.cancel]: 'Cancel',
  [GlobalTranslation.confirm]: 'Confirm',

  [GlobalTranslation.featureNotAvailable]: 'This feature is not available yet',

  [GlobalTranslation.loadMoreButton]: 'Load more',

  [GlobalTranslation.reloadAppMessage]: 'New Webtop.com version is ready!',
  [GlobalTranslation.reloadAppButton]: 'Reload app',

  [GlobalTranslation.browserNotFullySupportedTitle]:
    'Browser does not support installation',
  [GlobalTranslation.browserNotFullySupportedMessage]:
    'You are using a browser that is not fully supported for this device. <a href="{supportPageUrl}" target="_blank" rel="noopener noreferrer">Learn more</a>',

  [GlobalTranslation.inputConfirmationMessage]:
    'Please type <span class="highlight">{expectedValue}</span> to proceed or close this modal to cancel.',
  [GlobalTranslation.logOut]: 'Log out',

  [GlobalTranslation.copyClipboardErrorMessage]:
    'Something went wrong when copying data from the clipboard. Please check that your browser permissions for the app allows Clipboard.',
  [GlobalTranslation.invalidDateFormat]: 'Invalid Date Format',

  [GlobalTranslation.checkEmailMessage]:
    'If you do not see our email in a few minutes, check your “junk mail” or “spam” folder. To make sure you receive future emails, please add "account@webtop.com" to your Safe Sender or contact list.',
  [GlobalTranslation.confirmCloseModalOnOutsideClick]: 'Close this modal?',

  [GlobalTranslation.genericErrorTitle]: 'Something went wrong',
  [GlobalTranslation.genericErrorMessage]:
    'This error has been reported to the Webtop.com team.<br/>Please try to reload the app.',
  [GlobalTranslation.userPermissionsWasUpdated]:
    'Your permissions were updated',

  [GlobalTranslation.scheduledDowntimeDateMessage]:
    'Scheduled downtime is planned for {expectedValue}',
  [GlobalTranslation.scheduledDowntimeMessage]:
    'Scheduled downtime to start in {expectedValue} {expectedValue, plural, one {minute} other {minutes}}',

  [GlobalTranslation.uploadFileSizeMessage]: 'The upload file is too large',
};

export const enLocale: Locale = {
  ...enGlobalLocale,
  ...enAuthLocale,
  ...enAppRootLocale,
  ...enDesktopLocale,
  ...enLinkLocale,
  ...enFolderLocale,
  ...enOnboardingLocale,
  ...enWebPushLocale,
  ...enWorkspaceLocale,
  ...enBillingLocale,
  ...enAccountLocale,
  ...enTeamLocale,
  ...enOfflineLocale,
  ...enPwaLocale,
  ...enUserLocale,
  ...enAppStoreLocale,
  ...enGeneralSettingsLocale,
  ...enGlobalSearchLocale,
  ...enNotificationLocale,
  ...enInboxLocale,
  ...enTimelineLocale,
  ...enChromeExtensionLocale,
  ...enDomainLocale,
  ...enMaintenanceLocale,
  ...enChatLocale,
  ...enConferenceLocale,
  ...enEncryptionLocale,
  ...enPasswordLocale,
  ...enSegmentLocale,
  ...enVideoPlayerLocale,
  ...enDocumentPreviewLocale,
  ...enSubscriptionLocale,
  ...externalServiceAuthLocale,
  ...enAnnouncementLocale,
  ...enAppIntegrationsLocale,
  ...enDropBoxErrorPageLocale,
};
