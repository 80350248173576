import { ApolloClient } from '@apollo/client';
import {
  SEARCH_IN_TIMELINES,
  SearchInTimelinesResponse,
  SearchInTimelinesVariables,
} from '../Timeline.queries';
import {
  existsInNestedArray,
  mergeWithArray,
} from '../../../shared/utils/list.utils';
import {
  GRAPHQL_TYPENAME_TIMELINE_EVENT,
  TIMELINE_EVENTS_PAGE_SIZE,
} from '../Timeline.constants';
import { GRAPHQL_TYPENAME_IMAGE_ASSET } from '../../Image/Image.constants';
import { extractNodes } from '../../../shared/api/api.utils';

export const addTimelineToCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: SearchInTimelinesVariables,
  event: any,
) => {
  try {
    const timelineDesktopCache = apolloClient.readQuery<
      SearchInTimelinesResponse,
      SearchInTimelinesVariables
    >({
      query: SEARCH_IN_TIMELINES,
      variables: queryVariables,
    });
    if (!timelineDesktopCache) {
      return;
    }

    const alreadyExist = existsInNestedArray(
      timelineDesktopCache,
      'searchInTimelineEvents.edges',
      { id: event.id },
    );
    if (alreadyExist) {
      return;
    }

    let { searchInTimelineEvents } = timelineDesktopCache;

    let searchInTimelines = extractNodes(searchInTimelineEvents);

    if (!(searchInTimelines.length % TIMELINE_EVENTS_PAGE_SIZE)) {
      searchInTimelines = searchInTimelines.slice(0, -1);
    }

    const data = mergeWithArray(
      {
        searchInTimelines: [
          {
            ...event,
            ...(event.image
              ? {
                  image: {
                    ...event.image,
                    __typename: GRAPHQL_TYPENAME_IMAGE_ASSET,
                  },
                }
              : {}),
            __typename: GRAPHQL_TYPENAME_TIMELINE_EVENT,
          },
        ],
      },
      { searchInTimelines },
    );

    apolloClient.writeQuery({
      query: SEARCH_IN_TIMELINES,
      variables: queryVariables,
      data,
    });
  } catch (e) {
    /* There is no cache */
  }
};
