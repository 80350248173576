import { gql } from '@apollo/client';
import {
  AppVaultItemConfigApiType,
  KeysetApiType,
  KeysetFirstTimeInputType,
  KeysetInputType,
  PasswordRecoveryRequest,
  PasswordRecoveryRequestStatus,
  VaultAccessApiType,
  VaultAccessInputType,
  VaultApiType,
  VaultItemCreateInputType,
  VaultItemEditInputType,
} from './Encryption.types';
import {
  KeysetFragmentFields,
  PasswordRecoveryRequestFragment,
  VaultAccessFragmentFields,
} from './Encryption.fragments';
import { ApiConnection } from '../../shared/api/api.types';

/*
 * KEYSET
 * */

export const CREATE_CURRENT_ACCOUNT_KEYSET_FIRST_TIME = gql`
  mutation CreateForTheFirstTimeKeyset($input: createForTheFirstTimeKeysetInput!) {
    createForTheFirstTimeKeyset(input: $input) {
      keyset {
        ${KeysetFragmentFields}
      }
    }
  }
`;

export interface CreateCurrentAccountKeysetFirstTimeVariables {
  input: KeysetFirstTimeInputType;
}

export interface CreateCurrentAccountKeysetFirstTimeResponse {
  keyset: KeysetApiType;
}

export const UPDATE_KEYSET = gql`
  mutation UpdateKeyset($input: updateKeysetInput!) {
    updateKeyset(input: $input) {
      keyset {
        ${KeysetFragmentFields}
      }
    }
  }
`;

export interface UpdateKeysetVariables {
  input: KeysetInputType & { id: string };
}

export interface UpdateKeysetResponse {
  keyset: KeysetApiType;
}

/*
 * VAULT ITEM
 * */

export const CREATE_VAULT_ITEM = gql`
  mutation createVaultItem($input: createVaultItemInput!) {
    createVaultItem(input: $input) {
      vaultItem {
        id
        vault {
          id
        }
        appVaultItemConfigs {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export interface CreateVaultItemVariables {
  input: VaultItemCreateInputType;
  vaultItemConfigDesktopsAccountId?: string;
}

export interface CreateVaultItemResponse {
  createVaultItem: {
    vaultItem: {
      id: string;
      vault: Pick<VaultApiType, 'id'>;
      appVaultItemConfigs: ApiConnection<Pick<AppVaultItemConfigApiType, 'id'>>;
    };
  };
}

export const CREATE_WITHOUT_APP_VAULT_ITEM = gql`
  mutation CreateWithoutAppVaultItem($input: createWithoutAppVaultItemInput!) {
    createWithoutAppVaultItem(input: $input) {
      vaultItem {
        id
        vault {
          id
        }
        appVaultItemConfigs {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export interface CreateWithoutAppVaultItemVariables {
  input: Omit<VaultItemCreateInputType, 'appId'>;
  vaultItemConfigDesktopsAccountId?: string;
}

export interface CreateWithoutAppVaultItemResponse {
  createWithoutAppVaultItem: {
    vaultItem: {
      id: string;
      vault: Pick<VaultApiType, 'id'>;
      appVaultItemConfigs: ApiConnection<Pick<AppVaultItemConfigApiType, 'id'>>;
    };
  };
}

export const UPDATE_VAULT_ITEM = gql`
  mutation updateVaultItem($input: updateVaultItemInput!) {
    __typename
    updateVaultItem(input: $input) {
      clientMutationId
    }
  }
`;

export interface UpdateVaultItemVariables {
  input: VaultItemEditInputType;
}

export interface UpdateVaultItemResponse {
  updateVaultItem: {
    clientMutationId: string;
  };
}

export const DELETE_VAULT_ITEM = gql`
  mutation deleteVaultItem($input: deleteVaultItemInput!) {
    deleteVaultItem(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeleteVaultItemVariables {
  input: {
    id: string;
  };
}

export interface DeleteVaultItemResponse {
  clientMutationId: string;
}

export const BATCH_REMOVE_VAULT_ITEM = gql`
  mutation BatchRemoveVaultItem($input: batchRemoveVaultItemInput!) {
    batchRemoveVaultItem(input: $input) {
      clientMutationId
      __typename
    }
  }
`;

export interface BatchRemoveVaultItemVariables {
  input: {
    vaultItems: string[];
  };
}

export interface BatchRemoveVaultItemResponse {
  clientMutationId: string;
}

/*
 * VAULT ITEM CONFIG
 * */

export const SELECT_VAULT_ITEM_FOR_APP = gql`
  mutation selectVaultItemForApp(
    $input: selectVaultItemForAppVaultItemConfigDesktopInput!
  ) {
    selectVaultItemForAppVaultItemConfigDesktop(input: $input) {
      appVaultItemConfigDesktop {
        id
        __typename
        appVaultItemConfig {
          id
          __typename
        }
      }
    }
  }
`;

export interface SelectVaultItemForAppVariables {
  input: {
    app: string;
    desktop: string;
    vaultItem: string;
  };
}

export interface SelectVaultItemForAppResponse {
  selectVaultItemForAppVaultItemConfigDesktop: {
    appVaultItemConfigDesktop: {
      id: string;
      appVaultItemConfig: {
        id: string;
      };
    };
  };
}

export const UNSELECT_FOR_APP_VAULT_ITEM_CONFIG = gql`
  mutation UnselectAllVaultItemForApp(
    $input: unselectAllVaultItemForAppInput!
  ) {
    unselectAllVaultItemForApp(input: $input) {
      clientMutationId
    }
  }
`;

export interface UnselectForAppVaultItemConfigVariables {
  input: {
    id: string;
    desktop: string;
  };
}

export interface UnselectForAppVaultItemConfigResponse {
  clientMutationId: string;
}

/*
 * VAULT ACCESS
 * */

export const CREATE_VAULT_ACCESS = gql`
  mutation createVaultAccess ($input: createVaultAccessInput!) {
    __typename
    createVaultAccess (input: $input) {
      vaultAccess {
        ${VaultAccessFragmentFields}
      }
    }
  }
`;

export interface CreateVaultAccessVariables {
  input: VaultAccessInputType;
}

export interface CreateVaultAccessResponse {
  createVaultAccess: {
    vaultAccess: VaultAccessApiType;
  };
}

export const CREATE_MULTIPLE_VAULT_ACCESS = gql`
  mutation CreateMultipleVaultAccess($input: createMultipleVaultAccessInput!) {
    createMultipleVaultAccess(input: $input) {
      clientMutationId
    }
  }
`;

export interface CreateMultipleVaultAccessVariables {
  input: {
    vaultAccesses: VaultAccessInputType[];
  };
}

export interface CreateMultipleVaultAccessResponse {
  createMultipleVaultAccess: {
    vaultAccess: VaultAccessApiType;
  };
}

export const REMOVE_VAULT_ACCESS = gql`
  mutation removeVaultAccess($input: removeVaultAccessInput!) {
    removeVaultAccess(input: $input) {
      clientMutationId
    }
  }
`;

export interface RemoveVaultAccessVariables {
  input: {
    id: string;
    workspace: string;
  };
}

export interface RemoveVaultAccessResponse {
  clientMutationId: string;
}

/*
 * Password Recovery Request
 * */

export const EDIT_PASSWORD_RECOVERY_REQUEST = gql`
  mutation EditPasswordRecoveryRequest(
    $input: editPasswordRecoveryRequestInput!
  ) {
    __typename
    editPasswordRecoveryRequest(input: $input) {
      passwordRecoveryRequest {
        ${PasswordRecoveryRequestFragment}
      }
    }
  }
`;

export interface EditPasswordRecoveryRequestVariables {
  input: {
    id: string;
    status: PasswordRecoveryRequestStatus;
  };
}

export interface EditPasswordRecoveryRequestResponse {
  editPasswordRecoveryRequest: {
    passwordRecoveryRequest: PasswordRecoveryRequest;
  };
}

export const CREATE_MULTIPLE_VAULT_ITEMS = gql`
  mutation CreateMultipleVaultAccessesWithVaultItem(
    $input: createMultipleVaultAccessesWithVaultItemInput!
  ) {
    createMultipleVaultAccessesWithVaultItem(input: $input) {
      vaultItem {
        id
      }
      __typename
    }
  }
`;

export interface VaultItemForBatchCreation {
  login: string | undefined;
  password: string | undefined;
  url: string | undefined;
  workspace: string;
  keyset?: string;
  vaultKeyEncrypted: string;
  implicitlySharedVaultKeyEncrypted: string | null;
}

export interface CreateMultipleVaultItemVariables {
  input: {
    vaultItems: VaultItemForBatchCreation[];
  };
}

export interface CreateMultipleVaultItemResponse {
  vaultItem: {
    id: string;
  };
}

export interface CreateVaultItemWithAccessVariables {
  input: {
    login: string;
    password: string;
    app: string;
    desktop: string;
    workspace: string;
    vaultAccesses: VaultAccessInputType[];
  };
}

export interface CreateVaultItemWithAccessResponse {
  createVaultItemWithVaultAccess: {
    vaultAccess: VaultAccessApiType;
    __typename: string;
  };
}

export const CREATE_VAULT_WITH_VAULT_ACCESS = gql`
  mutation createVaultItemWithAccess(
    $input: createVaultItemWithVaultAccessInput!
  ) {
    createVaultItemWithVaultAccess(input: $input) {
      vaultAccess {
        ${VaultAccessFragmentFields}
        vault {
          id
        }
      }
      __typename
    }
  }
`;

export interface CreateWithoutAppVaultItemWithAccessVariables {
  input: {
    url?: string;
    login: string;
    desktop?: string;
    image?: string;
    password: string;
    workspace: string;
    vaultAccesses: VaultAccessInputType[];
  };
}

export interface CreateWithoutAppVaultItemWithAccessResponse {
  createWithoutAppVaultItemWithVaultAccess: {
    vaultAccess: VaultAccessApiType;
    __typename: string;
  };
}

export const CREATE_WITHOUT_APP_VAULT_ITEM_WITH_ACCESS = gql`
  mutation createWithoutAppVaultItemWithAccess(
    $input: createWithoutAppVaultItemWithVaultAccessInput!
  ) {
    createWithoutAppVaultItemWithVaultAccess(input: $input) {
      vaultAccess {
        ${VaultAccessFragmentFields}
        vault {
          id
        }
      }
      __typename
    }
  }
`;
