import { ConversationEventApiType } from '../../../GeneralMercure.types';
import { ChatConversationApiToChatConversationsTableRow } from '../../../../../Database/ChatConversationsTable/ChatConversationsTable.utils';
import { createConversationIDDB } from '../../../../../Chat/Data/DataSource/ChatConversation/ChatConversationsIndexedDBSource';

export const addToConversation = (
  event: ConversationEventApiType,
  workspaceId: string,
  accountId: string,
) => {
  const convertedConversation = ChatConversationApiToChatConversationsTableRow(
    {
      ...event,
      id: event.id,
    },
    {
      workspaceId,
      accountId,
    },
  );

  createConversationIDDB(convertedConversation);
};
