import { database } from '../../../../Database';
import { DesktopsTableType } from '../../../../Database/DesktopTable/DesktopsTable';
import { getDesktopByIdFromIDDB } from '../dataSources/DesktopIDDB.dataSource';
import { DesktopApiType } from '../types/Desktop.types';

export const putDesktopToIDDB = async (
  workspaceId: string,
  desktop: DesktopApiType,
  accessible: boolean = false,
) => {
  try {
    const cachedDesktop = await getDesktopByIdFromIDDB(desktop.id);
    database.desktops.put({
      id: desktop.id,
      workspaceId,
      createdAt: desktop.createdAt,
      sourceData: {
        ...desktop,
      },
      relatedTeams: cachedDesktop?.relatedTeams || [],
      relatedAccounts: desktop.accountIds,
      shareable: desktop.shareable.toString(),
      accessible: accessible.toString(),
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateDesktopInIDDB = (
  desktopId: string,
  updater: (desktop: DesktopsTableType) => DesktopsTableType,
): Promise<unknown> => {
  try {
    return database.desktops.where({ id: desktopId }).modify((value, ref) => {
      ref.value = updater(value);
    });
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};

export const deleteDesktopFromIDDB = (desktopId: string): Promise<void> => {
  try {
    return database.desktops.delete(desktopId);
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};

export const bulkDeleteDesktopsFromIDDB = (
  desktopIds: string[],
): Promise<void> => {
  try {
    return database.desktops.bulkDelete(desktopIds);
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};
