import { StreamNotificationEvent } from '../../../GeneralMercure.types';
import { AccountApiType } from '../../../../../User/User.types';
import { addNotificationToNotificationsCache } from '../../../../../Notifications/cache';
import { ApolloClient } from '@apollo/client';
import { NOTIFICATIONS_INITIAL_LOAD_COUNT } from '../../../../../Notifications/Notifications.constants';
import { updateUnreadItems } from '../../../../../Counters';
import { NotificationType } from '../../../../../Notifications/Notifications.types';
import {
  addMentionToMentionsCache,
  addThreadToThreadsCache,
} from '../../../../../Inbox/cache';
import {
  MENTIONS_INITIAL_LOAD_COUNT,
  THREADS_INITIAL_LOAD_COUNT,
} from '../../../../../Inbox/Inbox.constants';
import { playMentionSound } from '../../../../../Chat/Chat.utils';
import { isEventFresh } from '../../../GeneralMercure.utils';
import { MessagesNotificationsType } from '../../../../../GeneralSettings/NotificationsSettingsAdmin/CustomNotificationsSettingsForm/CustomNotifications.types';

export const notificationRequestNew = (
  event: StreamNotificationEvent,
  updateAccount: (updater: (account: AccountApiType) => AccountApiType) => void,
  apolloClient: ApolloClient<object>,
  account: AccountApiType | null,
) => {
  const notificationsDisabled =
    account &&
    (!account.identity?.accountIdentitySetting?.soundsEnable ||
      account.identity?.accountIdentitySetting?.messagesNotifications ===
        MessagesNotificationsType.disable);

  if (
    !event.recipient.onCall &&
    isEventFresh(event) &&
    !notificationsDisabled
  ) {
    playMentionSound();
  }

  switch (event.message.type) {
    case NotificationType.USER_CHAT_MENTIONED:
      addMentionToMentionsCache(
        apolloClient,
        {
          accountId: event.recipient.id,
          workspaceId: event.workspace.id,
          first: MENTIONS_INITIAL_LOAD_COUNT,
        },
        event,
      );
      updateAccount(cachedAccount => ({
        ...cachedAccount,
        unreadMentions: updateUnreadItems(
          cachedAccount.unreadMentions,
          event.workspace.id,
          prevCount => prevCount + 1,
        ),
      }));
      break;
    case NotificationType.USER_CHAT_THREAD:
      addThreadToThreadsCache(
        apolloClient,
        {
          accountId: event.recipient.id,
          workspaceId: event.workspace.id,
          first: THREADS_INITIAL_LOAD_COUNT,
        },
        event,
      );
      updateAccount(cachedAccount => ({
        ...cachedAccount,
        unreadThreads: updateUnreadItems(
          cachedAccount.unreadThreads,
          event.workspace.id,
          prevCount => prevCount + 1,
        ),
      }));
      break;
    default:
      addNotificationToNotificationsCache(
        apolloClient,
        {
          accountId: event.recipient.id,
          workspaceId: event.workspace.id,
          first: NOTIFICATIONS_INITIAL_LOAD_COUNT,
        },
        event,
      );
      updateAccount(cachedAccount => ({
        ...cachedAccount,
        unreadNotifications: updateUnreadItems(
          cachedAccount.unreadNotifications,
          event.workspace.id,
          prevCount => prevCount + 1,
        ),
      }));
  }
};
