import {
  REQUIRE_MFA_STORAGE_KEY,
  TRIGGER_MFA_VERIFICATION,
} from '../Onboarding/Onboarding.constants';
import { getCookie, removeCookie, setCookie } from '../../shared/utils/storage';
import { LAST_OPENED_WORKSPACE_STORAGE_KEY } from '../Workspace/Workspace.constants';
import { LAST_OPENED_DESKTOPS_MAP_STORAGE_KEY } from '../Desktop/Desktop.constants';
import {
  AUTH0_RETURN_URI_HOST_STANDALONE,
  MFA_SESSION_STARTED_STORAGE_KEY,
} from './Auth.constants';
import { HIDE_PERMISSION_MODAL_STORAGE_KEY } from '../WebPush/WebPush.constants';
import { clearPersistedStorage } from '../PersistedStorage/PersistedStorage.utils';
import { captureException } from '../ErrorInterceptor';

export const rememberMfaIsRequired = () => {
  return setCookie(REQUIRE_MFA_STORAGE_KEY, 'yes');
};

export const isMfaRequired = () => {
  return getCookie(REQUIRE_MFA_STORAGE_KEY) === 'yes';
};

export const triggerMfaVerification = () => {
  return setCookie(TRIGGER_MFA_VERIFICATION, 'yes');
};

export const isMfaRequiredBasedOnAPIErrors = () => {
  return getCookie(TRIGGER_MFA_VERIFICATION);
};

export const clearTriggerMfaVerification = () => {
  removeCookie(TRIGGER_MFA_VERIFICATION);
};

export const getAuth0ReturnUriHost = () => {
  return AUTH0_RETURN_URI_HOST_STANDALONE;
};

export const clearBrowserStoredData = async () => {
  try {
    [
      LAST_OPENED_WORKSPACE_STORAGE_KEY,
      LAST_OPENED_DESKTOPS_MAP_STORAGE_KEY,
      REQUIRE_MFA_STORAGE_KEY,
      MFA_SESSION_STARTED_STORAGE_KEY,
      HIDE_PERMISSION_MODAL_STORAGE_KEY,
      TRIGGER_MFA_VERIFICATION,
    ].forEach(key => removeCookie(key));
    localStorage.clear();
    await clearPersistedStorage();
  } catch (e) {
    captureException(e as Error);
  }
};

export const getAccountId = (shortId: string): string => {
  return '/accounts/' + shortId;
};
