import { FileAssetApiType } from './../Asset.types';
import { ApolloClient, gql } from '@apollo/client';
import { GRAPHQL_TYPENAME_FILE_ASSET } from '../Asset.constants';

const FileAssetFragment = gql`
  fragment FileAssetFragment on FileAsset {
    metadata
  }
`;

type FileAssetFragmentType = Pick<FileAssetApiType, 'metadata'>;

export const updateFileAssetInCache = (
  proxy: {
    readFragment: ApolloClient<any>['readFragment'];
    writeFragment: ApolloClient<any>['writeFragment'];
  },
  fileAssetId: string,
  updater: (fileAsset: FileAssetFragmentType) => Partial<FileAssetFragmentType>,
) => {
  try {
    const cacheId = `${GRAPHQL_TYPENAME_FILE_ASSET}:${fileAssetId}`;

    const fragmentCache = proxy.readFragment<FileAssetFragmentType, undefined>({
      id: cacheId,
      fragment: FileAssetFragment,
    });
    if (!fragmentCache) {
      return;
    }

    proxy.writeFragment<FileAssetFragmentType, undefined>({
      id: cacheId,
      fragment: FileAssetFragment,
      data: {
        ...fragmentCache,
        ...updater(fragmentCache),
      },
    });
  } catch (e) {}
};
