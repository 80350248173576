import { database } from '../../../../Database';
import {
  addEntityIdsToIDDBRelatedEntitiesList,
  removeEntityIdsFromIDDBRelatedEntitiesList,
} from '../../../../../shared/utils/iddb.utils';

export const addRelatedTeamsToAccountsIDDB = async (
  accountIds: string[] = [],
  teamIds: string[] = [],
) => {
  try {
    return database.accounts
      .where('id')
      .anyOf(accountIds)
      .modify((value, ref) => {
        ref.value = {
          ...value,
          relatedTeams: addEntityIdsToIDDBRelatedEntitiesList(
            value.relatedTeams,
            teamIds,
          ),
        };
      });
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};

export const removeRelatedTeamsFromAccountsIDDB = async (
  accountIds: string[] = [],
  teamIds: string[] = [],
) => {
  try {
    return database.accounts
      .where('id')
      .anyOf(accountIds)
      .modify((value, ref) => {
        ref.value = {
          ...value,
          relatedTeams: removeEntityIdsFromIDDBRelatedEntitiesList(
            value.relatedTeams,
            teamIds,
          ),
        };
      });
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};
