import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const BusinessIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.666 16a1 1 0 0 0 0 2h.667a1 1 0 1 0 0-2h-.667zm-1-3.667a1 1 0 0 1 1-1h.667a1 1 0 1 1 0 2h-.667a1 1 0 0 1-1-1zm1-5.666a1 1 0 1 0 0 2h.667a1 1 0 0 0 0-2h-.667zM13.333 17a1 1 0 0 1 1-1H15a1 1 0 1 1 0 2h-.667a1 1 0 0 1-1-1zm1-5.667a1 1 0 1 0 0 2H15a1 1 0 1 0 0-2h-.667zm-1-3.666a1 1 0 0 1 1-1H15a1 1 0 1 1 0 2h-.667a1 1 0 0 1-1-1zM19 16a1 1 0 0 0 0 2h.666a1 1 0 1 0 0-2H19zm-1-3.667a1 1 0 0 1 1-1h.666a1 1 0 1 1 0 2H19a1 1 0 0 1-1-1zm1-5.666a1 1 0 0 0 0 2h.666a1 1 0 1 0 0-2H19z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 26.667a2.667 2.667 0 0 0 2.667 2.666h5a1 1 0 0 0 1-1v-3h4v3a1 1 0 0 0 1 1h5c.122 0 .244-.008.362-.024.073.016.147.024.222.024h5.416a2.667 2.667 0 0 0 2.666-2.666V16.5a2.667 2.667 0 0 0-1.066-2.133l-1.334-1a1.001 1.001 0 0 0-1.46 1.31 1 1 0 0 0 .26.29l1.334 1a.667.667 0 0 1 .266.533v10.167a.667.667 0 0 1-.666.666h-3.418c.055-.213.084-.436.084-.666V4a2.667 2.667 0 0 0-2.666-2.667h-16A2.667 2.667 0 0 0 4 4v22.667zm2.667.666A.666.666 0 0 1 6 26.667V4a.667.667 0 0 1 .667-.667h16a.667.667 0 0 1 .666.667v22.667a.667.667 0 0 1-.666.666h-4v-3a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v3h-4z"
        fill="currentColor"
      />
    </svg>
  );
});
