import React, { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { FetchPolicyContext } from './FetchPolicy.context';

export const FetchPolicyProvider: FC<PropsWithChildren> = ({ children }) => {
  const stateRef = useRef<Record<string, boolean>>({});
  const addItem = useCallback((key: string) => {
    stateRef.current = {
      ...stateRef.current,
      [key]: true,
    };
  }, []);
  const resetState = useCallback(() => {
    stateRef.current = {};
  }, []);
  return (
    <FetchPolicyContext.Provider
      value={{
        state: stateRef.current,
        addItem,
        resetState,
      }}>
      {children}
    </FetchPolicyContext.Provider>
  );
};
