import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { GlobalTranslation } from '../../../domains/Intl/i18n';
import { FieldProps } from 'formik';
import { ErrorMessage, StyledFormInput, StyledToggle } from './Toggle.styled';
import { ToggleProps } from './Toggle';

export const FormToggle: FC<FieldProps & ToggleProps> = ({
  field,
  form,
  label,
  ...props
}) => {
  const errorIdToDisplay = form.touched[field.name] && form.errors[field.name];

  return (
    <StyledFormInput invalid={!!errorIdToDisplay}>
      <StyledToggle>
        <label htmlFor={field.name} className="text">
          {label ?? ''}
        </label>
        <input
          {...props}
          {...field}
          type="checkbox"
          className="toggle-button"
        />
      </StyledToggle>
      {errorIdToDisplay && (
        <ErrorMessage>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </StyledFormInput>
  );
};
