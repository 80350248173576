// TODO: add better typing instead any. Example:
// interface ObjectWithId {
//   id: string;
// }
//
// export const getListChanges = <T extends ObjectWithId>(...)

import _mergeWith from 'lodash/mergeWith';
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';
import _some from 'lodash/some';

export interface ListChanges<T> {
  added: T[];
  removed: T[];
}

export type ListItem = ({ [key: string]: any } & { id: string }) | null;

export const getListMap = (list: ListItem[] = []): Map<string, ListItem> => {
  return new Map(list.filter(item => item).map(item => [item!.id, item]));
};

export const getListChanges = (
  oldList: ListItem[] = [],
  newList: ListItem[] = [],
): ListChanges<any> => {
  const oldListMap = getListMap(oldList);
  const newListMap = getListMap(newList);
  return {
    removed: oldList.filter(item => item && !newListMap.has(item?.id)),
    added: newList.filter(item => item && !oldListMap.has(item?.id)),
  };
};

interface ObjectWithCreationDate {
  createdAt: string;
}

interface AppObjectWithName {
  app: {
    name: string;
  };
}

export const sortByCreationDate = (
  a: ObjectWithCreationDate,
  b: ObjectWithCreationDate,
) => {
  if (a === b) {
    return 0;
  }
  return a.createdAt < b.createdAt ? 1 : -1;
};

export const sortByAppName = (a: AppObjectWithName, b: AppObjectWithName) =>
  a.app.name.localeCompare(b.app.name);

export function sortByProp<T>(prop: keyof T, direction: 'asc' | 'desc') {
  return (a: T, b: T) => {
    if (a === b) {
      return 0;
    }
    if (direction === 'asc') {
      return a[prop] < b[prop] ? -1 : 1;
    }
    return a[prop] < b[prop] ? 1 : -1;
  };
}

export function sortByPropWithFallback<T>(
  prop: keyof T,
  propFallback: keyof T,
  direction: 'asc' | 'desc',
) {
  return (a: T, b: T) => {
    if (a === b) {
      return 0;
    }

    const valueA = a[prop] || a[propFallback];
    const valueB = b[prop] || b[propFallback];

    if (direction === 'asc') {
      return valueA < valueB ? -1 : 1;
    }
    return valueA < valueB ? 1 : -1;
  };
}

interface AccountSortable {
  accountSortPosition: number;
}

export const sortByAccountSort = (
  a: AccountSortable & { [key: string]: any },
  b: AccountSortable & { [key: string]: any },
) => {
  if (a.accountSortPosition === b.accountSortPosition) return 0;
  return a.accountSortPosition > b.accountSortPosition ? 1 : -1;
};

export const switchAccountSort = <T extends AccountSortable>(
  arr: T[],
  i1: number,
  i2: number,
): T[] => {
  const el1 = arr[i1];
  const el2 = arr[i2];
  return arr.map((el, i) =>
    i === i1
      ? { ...el, accountSortPosition: el2.accountSortPosition }
      : i === i2
      ? { ...el, accountSortPosition: el1.accountSortPosition }
      : el,
  );
};

interface ObjectWithMoveDate {
  movedAt: string;
}

/**
 * @todo: find out a smart way to combine these sorting functions
 */
export const sortByMoveDate = (
  a: ObjectWithMoveDate,
  b: ObjectWithMoveDate,
) => {
  if (a === b) {
    return 0;
  }
  return a.movedAt < b.movedAt ? 1 : -1;
};

export const mergeWithArray = (
  objA: { [key: string]: any },
  objB: { [key: string]: any },
) => {
  return _mergeWith({}, objA, objB, (objValue, srcValue) => {
    if (_isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  });
};

export const existsInNestedArray = (
  object: { [key: string]: any },
  path: string,
  match: { [key: string]: any },
): boolean => {
  const items = _get(object, path);
  return _some(items, match);
};
