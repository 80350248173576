import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const MinimizeIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M8.49005 6.76039C8.49018 6.95914 8.56918 7.14972 8.70972 7.29026C8.85026 7.43079 9.04084 7.5098 9.23959 7.50993L13.5429 7.50993C13.7371 7.50307 13.9211 7.4211 14.056 7.28129C14.191 7.14147 14.2664 6.95472 14.2664 6.76039C14.2664 6.56606 14.191 6.37932 14.056 6.2395C13.9211 6.09969 13.7371 6.01772 13.5429 6.01086L11.0498 6.01086L14.7803 2.28032C14.921 2.13966 15 1.9489 15 1.74999C15 1.55107 14.921 1.36031 14.7803 1.21966C14.6397 1.079 14.4489 0.999985 14.25 0.999985C14.0511 0.999985 13.8603 1.079 13.7197 1.21965L9.98912 4.9502L9.98912 2.45709C9.98226 2.26288 9.90029 2.07891 9.76048 1.94395C9.62066 1.80898 9.43392 1.73355 9.23959 1.73355C9.04526 1.73355 8.85851 1.80898 8.71869 1.94395C8.57888 2.07891 8.49691 2.26288 8.49005 2.45709L8.49005 6.76039Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M6.76039 8.49994C6.95914 8.50006 7.14972 8.57907 7.29026 8.71961C7.43079 8.86015 7.5098 9.05072 7.50993 9.24947V13.5528C7.50307 13.747 7.4211 13.931 7.28129 14.0659C7.14147 14.2009 6.95472 14.2763 6.76039 14.2763C6.56606 14.2763 6.37932 14.2009 6.2395 14.0659C6.09968 13.931 6.01771 13.747 6.01086 13.5528V11.0597L2.28032 14.7902C2.13966 14.9309 1.9489 15.0099 1.74998 15.0099C1.55107 15.0099 1.36031 14.9309 1.21966 14.7902C1.079 14.6496 0.999985 14.4588 0.999985 14.2599C0.999985 14.061 1.079 13.8702 1.21966 13.7296L4.9502 9.99901L2.45709 9.99901C2.26288 9.99215 2.07891 9.91018 1.94395 9.77037C1.80898 9.63055 1.73355 9.44381 1.73355 9.24947C1.73355 9.05514 1.80898 8.8684 1.94395 8.72858C2.07891 8.58877 2.26288 8.5068 2.45709 8.49994L6.76039 8.49994Z"
      />
    </svg>
  );
});
