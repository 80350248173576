import { useCallback, useContext, useMemo } from 'react';
import { NativeWrapperContext } from './NativeWrapper.context';
import { StreamTopic } from '../Mercure/Mercure.types';
import { useQueryParams } from '../../shared/hooks';
import {
  isIOSNativeWrapperAvailable,
  parseNativePlatform,
} from './NativeWrapper.utils';
import { CallStartedMessage } from './NativeWrapper.types';
import { appEnv } from '../../appEnv';
import { captureException } from '../ErrorInterceptor';
import { useAccountApiRepository } from '../Account/data/CurrentAccount/CurrentAccount.repositories';

export const useNativeWrapper = () => useContext(NativeWrapperContext);

export const useNativeWrapperMercureData = () => {
  const { getAccountMercureInfo } = useAccountApiRepository();
  const getNativeWrapperMercureData = useCallback(async (): Promise<
    Pick<CallStartedMessage, 'mercureToken' | 'mercureTopics'>
  > => {
    if (!isIOSNativeWrapperAvailable()) {
      return {
        mercureToken: null,
        mercureTopics: null,
      };
    }
    return getAccountMercureInfo().then(mercureInfo => {
      if (!mercureInfo) {
        throw new Error('Empty mercureInfo received from the API.');
      }
      const generalMercure = mercureInfo.general;
      return {
        mercureToken: generalMercure.authorization,
        mercureTopics: Object.keys(generalMercure.topics)
          .reduce<string[]>((acc, topicKey) => {
            const topics = generalMercure.topics[topicKey as StreamTopic];
            return [...acc, ...(Array.isArray(topics) ? topics : [topics])];
          }, [])
          .join(','),
      };
    });
  }, []); // eslint-disable-line
  return {
    getNativeWrapperMercureData,
  };
};

export const useJoinCallInNativeWrapper = () => {
  const { getNativeWrapperMercureData } = useNativeWrapperMercureData();
  const { sendMessageToNativeWrapper } = useNativeWrapper();
  return useCallback(
    (callData: Pick<CallStartedMessage, 'roomName' | 'token' | 'callType'>) => {
      getNativeWrapperMercureData()
        .then(mercureData => {
          sendMessageToNativeWrapper({
            type: 'CALL_JOINED',
            domain: appEnv.JITSI_DOMAIN,
            ...callData,
            ...mercureData,
          });
          window.close();
        })
        .catch(e => {
          captureException(e);
          console.error(e);
          alert('Unable to join a call');
        });
    },
    [getNativeWrapperMercureData, sendMessageToNativeWrapper],
  );
};

export const useCreateCallInNativeWrapper = () => {
  const { getNativeWrapperMercureData } = useNativeWrapperMercureData();
  const { sendMessageToNativeWrapper } = useNativeWrapper();
  return useCallback(
    (
      callData: Pick<CallStartedMessage, 'roomName' | 'token' | 'callType'> & {
        recipientName: string | null;
        recipientAvatarUrl: string | null;
      },
    ) => {
      getNativeWrapperMercureData()
        .then(mercureData => {
          sendMessageToNativeWrapper({
            type: 'CALL_CREATED',
            domain: appEnv.JITSI_DOMAIN,
            ...callData,
            ...mercureData,
          });
          window.close();
        })
        .catch(e => {
          captureException(e);
          console.error(e);
          alert('Unable to start the call');
        });
    },
    [getNativeWrapperMercureData, sendMessageToNativeWrapper],
  );
};

export const useNativePlatformFromUrl = () => {
  const { platform } = useQueryParams();
  return useMemo(() => parseNativePlatform(platform), [platform]);
};
