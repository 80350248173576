import styled, { css } from 'styled-components';
import { ComponentPropsWithRef } from 'react';

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  mode?: ButtonMode;
  size?: ButtonSize;
  fullWidth?: boolean;
}

export enum ButtonMode {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  dark = 'dark',
  danger = 'danger',
}

export enum ButtonSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export const Button = styled.button<ButtonProps>`
  appearance: none;
  border: 0;
  border-radius: 4px;
  text-align: left;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  color: inherit;
  transition: background-color 0.25s ease;

  &[disabled] {
    background-color: ${p => p.theme.colors.SurfaceSubdued};
    color: ${p => p.theme.colors.DisabledElement};
    cursor: default;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      text-align: center;
    `};

  ${({ size }) =>
    size === ButtonSize.xs &&
    css`
      padding: 0.25rem 0.25rem;
      font-size: 0.75rem;
      font-family: ${p => p.theme.fonts.Medium};
      line-height: 14px;
    `};

  ${({ size, theme }) =>
    size === ButtonSize.sm &&
    css`
      ${theme.fontTemplates.smallSemibold};
      padding: 0.375rem 0.75rem;
    `};

  ${({ size }) =>
    size === ButtonSize.md &&
    css`
      padding: 0.625rem 1.5rem;
      font-size: 0.875rem;
      font-family: ${({ theme }) => theme.fonts.Semibold};
      line-height: 18px;
    `};

  ${({ size }) =>
    size === ButtonSize.lg &&
    css`
      padding: 0.6875rem 3rem;
      font-size: 1.25rem;
      font-family: ${({ theme }) => theme.fonts.Semibold};
      line-height: 30px;
    `};

  ${({ mode, theme }) =>
    mode === ButtonMode.primary &&
    css`
      background-color: ${theme.colors.Primary};
      color: ${theme.colors.PrimaryTextLight};

      &:not([disabled]) {
        &:hover {
          background-color: ${theme.colors.PrimaryDark};
        }

        &:focus:not(:focus-visible),
        &:active {
          outline: none;
          background-color: ${theme.colors.PrimaryDarker};
        }

        &:focus-visible {
          outline: none;
          background-color: ${theme.colors.Primary};
          border: 2px solid ${theme.colors.PrimaryLight};
        }
      }

      &[disabled] {
        background-color: ${p => p.theme.colors.Primary};
        color: ${p => p.theme.colors.White};
        opacity: 0.5;
        cursor: default;
      }
    `}

  ${({ mode, theme }) =>
    mode === ButtonMode.secondary &&
    css`
      color: ${theme.colors.OnSurfaceHighEmphasis};
      border: 1px solid ${theme.colors.BorderDefault};

      &:not([disabled]) {
        &:hover {
          background-color: ${theme.colors.OnSurfaceHover};
          border: 1px solid ${theme.colors.OnSurfaceHover};
        }

        &:focus:not(:focus-visible),
        &:active {
          outline: none;
          background-color: ${theme.colors.OnSurfacePressed};
          border: 1px solid ${theme.colors.BorderDefault};
        }

        &:focus-visible {
          outline: none;
          background-color: ${theme.colors.OnSurfacePressed};
          border: 2px solid ${theme.colors.PrimaryLight};
        }
      }

      &[disabled] {
        background-color: ${p => p.theme.colors.SurfaceSubdued};
        color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
        border: 1px solid ${theme.colors.BorderDefault};
        cursor: default;
      }
    `}

  ${({ mode, theme }) =>
    mode === ButtonMode.tertiary &&
    css`
      color: ${theme.colors.OnSurfaceLightEmphasis};

      &:not([disabled]) {
        &:hover {
          color: ${theme.colors.PrimaryLight};
        }

        &:active {
          outline: none;
          color: ${theme.colors.PrimaryLight};
        }
      }
    `}

    ${({ mode, theme }) =>
    mode === ButtonMode.dark &&
    css`
      color: ${theme.colors.PrimaryTextLight};
      background-color: ${theme.colors.PrimaryDark};

      &:not([disabled]) {
        &:hover {
          background-color: ${theme.colors.PrimaryLight};
        }

        &:focus {
          outline: none;
          background-color: ${theme.colors.PrimaryLight};
        }

        &:active {
          outline: none;
          background-color: ${theme.colors.DarkActiveButtonLinkBackground};
        }
      }
    `}
  
  ${({ mode, theme }) =>
    mode === ButtonMode.danger &&
    css`
      color: ${theme.colors.OnNavbar};
      background-color: ${theme.colors.CriticalLight};

      &:not([disabled]) {
        &:hover {
          background-color: ${theme.colors.CriticalDark};
        }

        &:focus {
          outline: none;
          background-color: ${theme.colors.CriticalDark};
        }

        &:active {
          outline: none;
          background-color: ${theme.colors.CriticalDark};
        }
      }
    `}
`;
