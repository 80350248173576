import { css } from 'styled-components';
import { getBreakpointCss } from '../../../shared/utils/style.utils';
import { Breakpoints } from '../';

const fonts = {
  Regular: "'ProximaNova-Regular', Helvetica, Arial, sans-serif",
  Medium: "'ProximaNova-Medium', Helvetica, Arial, sans-serif",
  Semibold: "'ProximaNova-Semibold', Helvetica, Arial, sans-serif",
  Merriweather: 'Merriweather, Georgia, serif',
};

export const BasicTheme = {
  fonts,
  fontTemplates: {
    /* purple color in Theodore's sketch */
    headerMain: css`
      font-family: ${fonts.Semibold};
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: normal;
    `,
    headerList: css`
      /* green color in Theodore's sketch */
      font-family: ${fonts.Semibold};
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.48px;
    `,
    regular: css`
      /* yellow color in Theodore's sketch */
      font-family: ${fonts.Regular};
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: normal;
    `,
    regularSemibold: css`
      /* orange color in Theodore's sketch */
      font-family: ${fonts.Semibold};
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: normal;
    `,
    smallSemibold: css`
      /* cyan color in Theodore's sketch */
      font-family: ${fonts.Semibold};
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: normal;
    `,
    tiny: css`
      /* blue */
      font-family: ${fonts.Regular};
      font-size: 0.625rem;
      line-height: 1.4;
      letter-spacing: normal;
    `,
    navbarList: css`
      font-family: ${fonts.Regular};
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: normal;
    `,
  },
  zIndexLevels: {
    Folder: 1,
    FolderLifted: 2,
    MessageSearchInput: 6,
    PeoplePanel: 9,
    ModalBackdrop: 1000,
    Modal: 2000,
    Menu: 4000,
    NotificationList: 5000,
    MaintenanceOverlay: 10000,
    Tooltip: 50000,
    TimeLineDesktopView: 100001,
    Confirm: 100002,
  },
  breakpoints: {
    xs: getBreakpointCss(Breakpoints.xs),
    sm: getBreakpointCss(Breakpoints.sm),
    md: getBreakpointCss(Breakpoints.md),
    lg: getBreakpointCss(Breakpoints.lg),
    xlg: getBreakpointCss(Breakpoints.xlg),
    xxl: getBreakpointCss(Breakpoints.xxl),
  },
  shadows: {
    default: '0 1px 8px rgba(0, 0, 0, 0.25)',
  },
  spacing: {
    xxs: '0.25rem',
    xs: '0.5rem',
    s: '0.75rem',
    m: '1rem',
    l: '1.25rem',
    xl: '1.5rem',
    xxl: '2rem',
    xxxl: '3rem',
    xxxxl: '4rem',
  },
  fixedValues: {
    segmentHeaderHeight: '56px',
  },
  animationTemplates: {
    opacityOnHoverTransition: '0.2s opacity ease-out',
  },
};
