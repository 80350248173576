import { css } from 'styled-components';

export const getBreakpointMediaQuery = (minWidth: number): string => {
  return `(min-width: ${minWidth}px)`;
};

export const getBreakpointCss = (minWidth: number) => (
  first: TemplateStringsArray,
  ...innerCss: TemplateStringsArray
) => css`
  @media ${getBreakpointMediaQuery(minWidth)} {
    ${css(first, ...innerCss)}
  }
`;
