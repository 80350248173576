import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const CustomAppsIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 2.5H2.75C2.6837 2.5 2.62011 2.52634 2.57322 2.57322C2.52634 2.62011 2.5 2.6837 2.5 2.75V13.25C2.5 13.388 2.612 13.5 2.75 13.5H13.25C13.3163 13.5 13.3799 13.4737 13.4268 13.4268C13.4737 13.3799 13.5 13.3163 13.5 13.25V2.75C13.5 2.6837 13.4737 2.62011 13.4268 2.57322C13.3799 2.52634 13.3163 2.5 13.25 2.5ZM2.75 1H13.25C14.216 1 15 1.784 15 2.75V13.25C15 13.7141 14.8156 14.1592 14.4874 14.4874C14.1592 14.8156 13.7141 15 13.25 15H2.75C2.28587 15 1.84075 14.8156 1.51256 14.4874C1.18437 14.1592 1 13.7141 1 13.25V2.75C1 1.784 1.784 1 2.75 1Z"
        fill="currentColor"
      />
      <path
        d="M10.5 6.25009V6C10.5 5.44772 10.0523 5 9.5 5H6.5C5.94772 5 5.5 5.44772 5.5 6V10C5.5 10.5523 5.94772 11 6.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V9.79132"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
