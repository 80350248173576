import styled from 'styled-components';

export const StyledScheduleRepeatEveryRow = styled.div`
  margin-top: 1.75rem;
  display: grid;
  grid-template-columns: 6rem 4.5rem max-content;
  text-align: left;
  column-gap: 0.5rem;
  font-size: 1rem;
  align-items: center;
`;

export const StyledScheduleRepeatRow = styled.div`
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    margin-top: 0.625rem;
    display: flex;
  }
`;

export const StyledScheduleRepeatOnRow = styled.div`
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledScheduleRepeatDay = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  margin-left: 0.5rem;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 2.5rem;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  background: ${p => p.active && p.theme.colors.Primary};
  color: ${p =>
    p.active
      ? p.theme.colors.OnNavbar
      : p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const StyledScheduleRepeatRadio = styled.div`
  tab-index: 1;
  height: 3.125rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .schedule-repeating-calendar {
    width: 100%;
    div {
      width: 9rem;
    }
  }

  .schedule-repeating-occurrences {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: ${p => p.theme.fonts.Medium};

    input {
      width: 5rem;
      margin-right: 1rem;
    }
  }

  label {
    width: 50%;
    display: flex;
    align-items: center;
    font-size: 1rem;
    .input-container {
      margin-left: 2.25rem;
      width: 8rem;
    }

    input {
      margin-right: 0.625rem;
    }

    input[type='radio'] {
      background-color: ${p => p.theme.colors.Surface};
      border: 2px solid ${p => p.theme.colors.BorderDefault};
      border-radius: 10px;
      cursor: pointer;
      display: inline-block;
      height: 1rem;
      position: relative;
      width: 1rem;
      -webkit-appearance: none;
      padding-right: 0.625rem;
    }

    input[type='radio']:after {
      background-color: ${p => p.theme.colors.Surface};
      border-radius: 1.563rem;
      content: '';
      display: block;
      height: 0.5rem;
      left: 0.125rem;
      position: relative;
      top: 0.125rem;
      width: 0.5rem;
    }

    input[type='radio']:checked {
      background-color: ${p => p.theme.colors.Primary};
    }
  }
`;

export const StyledScheduleRepeatButtonsControls = styled.div`
  margin-top: 3.5rem;
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-left: 1rem;
  }
`;
