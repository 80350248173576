import { ThemeType } from '../../Theme';
import { ConferenceThemesType } from './Conference.theme.types';

export const ConferenceTheme: ConferenceThemesType = {
  [ThemeType.light]: {
    colors: {
      CallAccept: '#50B83C',
      CallReject: '#DE3618',
      CallTextColor: 'rgba(255,255,255, 0.6)',
    },
  },
  [ThemeType.dark]: {
    colors: {
      CallAccept: '#50B83C',
      CallReject: '#DE3618',
      CallTextColor: 'rgba(255,255,255, 0.6)',
    },
  },
};
