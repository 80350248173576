import { ThemeType } from '../../Theme';
import { UserThemesType } from './User.theme.types';

export const UserTheme: UserThemesType = {
  [ThemeType.light]: {
    colors: {
      GroupTag: '#454F5B',
      AvatarBackground: '#C4CDD5',
      AvatarPlaceholder: '#DFE3E8',
    },
  },
  [ThemeType.dark]: {
    colors: {
      GroupTag: '#454F5B',
      AvatarBackground: '#637381',
      AvatarPlaceholder: '#637381',
    },
  },
};
