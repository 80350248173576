import { StreamDesktopEventAction } from '../../GeneralMercure.types';
import { database } from '../../../../Database';
import { captureException } from '@sentry/react';

export const removeDesktopAppsSection = async (
  event: StreamDesktopEventAction,
) => {
  const { id } = event['@payload'];

  try {
    const desktopApps = await database.desktopApps
      .where({ desktopId: id })
      .toArray();

    const desktopAppsIds = desktopApps.map(item => item.id);

    if (desktopAppsIds) {
      database.desktopApps.bulkDelete(desktopAppsIds);
    }
  } catch (error) {
    captureException(error);
  }
};

export const removeDesktopLinksSection = async (
  event: StreamDesktopEventAction,
) => {
  const { id } = event['@payload'];

  try {
    const desktopLinks = await database.desktopLinks
      .where({ desktopId: id })
      .toArray();

    const desktopLinksIds = desktopLinks.map(item => item.id);

    const desktopFolders = await database.desktopFolders
      .where({ desktopId: id })
      .toArray();

    const desktopFoldersIds = desktopFolders.map(item => item.id);

    if (desktopLinksIds) {
      database.desktopLinks.bulkDelete(desktopLinksIds);
    }

    if (desktopFoldersIds) {
      database.desktopFolders.bulkDelete(desktopFoldersIds);
    }
  } catch (error) {
    captureException(error);
  }
};
