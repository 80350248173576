import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ChatDocumentIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="chat-document-icon"
      {...props}>
      <path
        d="M16 0C16.0426 0 16.0846 0.00266262 16.1257 0.00783047C16.1359 0.00922376 16.1464 0.010711 16.1568 0.012361C16.1884 0.0172036 16.2193 0.0236158 16.2497 0.0314174C16.2598 0.0342797 16.2699 0.0370499 16.2799 0.0399762C16.3109 0.0487322 16.3414 0.0592262 16.3711 0.071104C16.38 0.0749696 16.3891 0.0787342 16.398 0.0826309C16.4263 0.0946382 16.4537 0.108068 16.4803 0.122674C16.4907 0.128558 16.5013 0.134613 16.5118 0.140865C16.5399 0.157498 16.567 0.175549 16.5932 0.194845C16.633 0.224207 16.6713 0.257049 16.7071 0.292893L16.6138 0.210506C16.6177 0.213539 16.6216 0.2166 16.6254 0.219689L16.7071 0.292893L25.7071 9.29289C25.7364 9.32223 25.764 9.35338 25.7895 9.38617C25.7952 9.39354 25.8004 9.40042 25.8055 9.40736C25.8245 9.43297 25.8425 9.46009 25.8592 9.4881C25.8654 9.49874 25.8714 9.50934 25.8773 9.52005C25.8919 9.54634 25.9054 9.5737 25.9175 9.60173C25.9213 9.61094 25.925 9.61998 25.9287 9.62907C25.9408 9.65865 25.9513 9.68906 25.9603 9.72009C25.9629 9.73011 25.9657 9.7402 25.9683 9.75033C25.9764 9.78069 25.9828 9.81161 25.9877 9.84301C25.9958 9.89476 26 9.94716 26 10L25.9922 9.87425C25.9926 9.87729 25.9929 9.88033 25.9933 9.88338L26 10V28C26 30.2091 24.2091 32 22 32H4C1.79086 32 0 30.2091 0 28V4C0 1.79086 1.79086 0 4 0H16ZM15 2H4C2.89543 2 2 2.89543 2 4V28C2 29.1046 2.89543 30 4 30H22C23.1046 30 24 29.1046 24 28V11H16C15.4872 11 15.0645 10.614 15.0067 10.1166L15 10V2ZM19 22.5C19.5523 22.5 20 22.9477 20 23.5C20 24.0128 19.614 24.4355 19.1166 24.4933L19 24.5H7C6.44772 24.5 6 24.0523 6 23.5C6 22.9872 6.38604 22.5645 6.88338 22.5067L7 22.5H19ZM19 16.5C19.5523 16.5 20 16.9477 20 17.5C20 18.0128 19.614 18.4355 19.1166 18.4933L19 18.5H7C6.44772 18.5 6 18.0523 6 17.5C6 16.9872 6.38604 16.5645 6.88338 16.5067L7 16.5H19ZM10 10.5C10.5523 10.5 11 10.9477 11 11.5C11 12.0128 10.614 12.4355 10.1166 12.4933L10 12.5H7C6.44772 12.5 6 12.0523 6 11.5C6 10.9872 6.38604 10.5645 6.88338 10.5067L7 10.5H10ZM17 3.415V9H22.585L17 3.415Z"
        fill="currentColor"
      />
    </svg>
  );
});
