import { ThemeType } from '../../../Theme';
import { SegmentSwitcherThemesType } from './SegmentSwitcher.theme.types';

export const SegmentSwitcherTheme: SegmentSwitcherThemesType = {
  [ThemeType.light]: {
    colors: {
      SegmentTitleColor: '#000',
      PreviewSegmentArrowColor: '#fff',
      PreviewSegmentBorderColor: 'transparent',
    },
  },
  [ThemeType.dark]: {
    colors: {
      SegmentTitleColor: '#fff',
      PreviewSegmentArrowColor: '#021121',
      PreviewSegmentBorderColor: 'rgba(255, 255, 255, 0.24)',
    },
  },
};
