import { DatabaseTablesType } from '../../../../Database/Database.types';
import { AccountInvitationApiType } from './Invitation.types';

export const INVITATIONS_TABLE_COLUMNS = 'id, workspaceId, desktopId, payload';

export interface InvitationsTableType
  extends Omit<DatabaseTablesType, 'createdAt'> {
  workspaceId: string;
  sourceData: AccountInvitationApiType;
}
