import type { DesktopApiType } from '../../../../../Desktop/data/Desktop/types/Desktop.types';
import { StreamDesktopEventAction } from '../../../GeneralMercure.types';

export const addedToDesktop = (
  event: StreamDesktopEventAction,
  refetchPermissions: () => void,
  addDesktopToIDDB: (
    workspaceId: string,
    desktop: DesktopApiType,
    accessible: boolean,
  ) => Promise<void>,
) => {
  const payload = event['@payload'];

  if (!payload) {
    return;
  }

  addDesktopToIDDB(payload.workspace.id, payload, true);

  refetchPermissions();
};
