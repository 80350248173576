import Color from 'color';
import { getCurrentTheme } from '../../Theme';

export const DROPZONE_DRAGGING_OVER_WRAPPER_CLASSNAME =
  'external-url-dragging-over';

export const DROPZONE_ENABLED_CLASSNAME = 'enabled';
export const DROPZONE_ACTIVE_CLASSNAME = 'active';

export const DROPZONE_BACKGROUND_COLOR_DEFAULT = Color(
  getCurrentTheme().colors.Primary,
)
  .alpha(0.2)
  .rgb()
  .string();
export const DROPZONE_BACKGROUND_COLOR_ACTIVE = Color(
  getCurrentTheme().colors.Primary,
)
  .alpha(0.8)
  .rgb()
  .string();

export const MAX_FILE_SIZE = 10 * 1024; // 10Kb
