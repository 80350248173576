import { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';
import { DesktopApiType } from '../types/Desktop.types';

export const getAccessibleDesktopsApi = async (
  workspaceId: string,
): Promise<RestApiClientResponse<DesktopApiType[]> | undefined> => {
  const shortWorkspaceId = getShortId(workspaceId);
  const restApiClient = getRestApiClient();
  return restApiClient!.fetch(
    `/workspace/${shortWorkspaceId}/accessible/desktops`,
  );
};

export const getWorkspaceInternalDesktopsApi = async (
  workspaceId: string,
): Promise<RestApiClientResponse<DesktopApiType[]> | undefined> => {
  const shortWorkspaceId = getShortId(workspaceId);
  const restApiClient = getRestApiClient();
  return restApiClient!.fetch(
    `/workspace/${shortWorkspaceId}/internal/desktops`,
  );
};

export const getDesktopApi = async (
  desktopId: string,
): Promise<RestApiClientResponse<DesktopApiType> | undefined> => {
  try {
    const shortDesktopId = getShortId(desktopId);
    const restApiClient = getRestApiClient();
    return restApiClient!.fetch(`/desktop/${shortDesktopId}`);
  } catch (e) {
    console.error(e);
  }
};
