export enum VideoPlayerTranslation {
  playerErrorMessage = 'videoPlayerTranslation_playerErrorMessage',
  playerPlayButton = 'videoPlayerTranslation_playerPlayButton',
  playerExitFullScreen = 'videoPlayerTranslation_playerExitFullScreen',
  playerPauseButton = 'videoPlayerTranslation_playerPauseButton',
  vimeo = 'videoPlayerTranslation_vimeoAlt',
  youtube = 'videoPlayerTranslation_youtubeAlt',
  externalAlt = 'videoPlayerTranslation_externalAlt',
  videoIsBeingProcessed = 'videoPlayerTranslation_videoIsBeingProcessed',
}

export type VideoPlayerLocale = {
  [key in VideoPlayerTranslation]: string;
};
