import styled, { createGlobalStyle, css } from 'styled-components';
import { SCROLLBAR_WIDTH } from '../../../domains/AppRoot/AppRoot.styled';
import { ModalAnimation, ModalProps, ModalThemeOverride } from '.';

export const StyledModalContainer = styled.div<
  Pick<ModalProps, 'visible' | 'containerZIndex'>
>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  font-family: ${p => p.theme.fonts.Regular};
  background-color: ${({ theme }) => theme.colors.ModalBackdrop};

  ${({ visible }) =>
    visible &&
    css`
      display: block;
    `}

  ${({ containerZIndex }) =>
    containerZIndex &&
    css`
      z-index: ${containerZIndex};
    `}

  .truncate-content {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const StyledModalScroll = styled.div<{
  fullHeight: boolean;
  topAligned?: boolean;
}>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: fill-available;
  -webkit-overflow-scrolling: touch;

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100vh;
    `};

  ${({ theme: { breakpoints }, topAligned }) =>
    topAligned &&
    breakpoints.md`
    align-items: flex-start;
  `}

  ${({ theme: { breakpoints, spacing }, fullHeight }) =>
    fullHeight &&
    breakpoints.md`
    padding: ${spacing.xxxl} 0;
  `}

  ${({ theme: { breakpoints, spacing }, fullHeight }) =>
    fullHeight &&
    breakpoints.lg`
    padding: ${spacing.xxxl} 0;
  `}

  ${({ theme: { breakpoints, spacing }, fullHeight }) =>
    fullHeight &&
    breakpoints.xxl`
    padding: ${spacing.xxxxl} 0;
  `}
`;

export const StyledModalWindow = styled.div.attrs({
  className: 'modal-window',
})<{
  width: number;
  minHeight: 'auto' | number;
  animation: ModalAnimation;
  borderRadius: number;
  fullScreen?: boolean;
  fullHeight?: boolean;
  isColoredModal: boolean;
  topAligned?: boolean;
  themeOverride?: ModalThemeOverride;
}>`
  position: relative;
  width: 100%;
  z-index: ${p => p.theme.zIndexLevels.Modal};
  background-color: ${p =>
    p.themeOverride === ModalThemeOverride.DARK
      ? p.theme.colors.AssetsModalBackground
      : p.theme.colors.Surface};
  border-radius: ${p => p.borderRadius}px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  margin: 20px;
  display: flex;
  flex-direction: column;

  ${({ isColoredModal }) =>
    isColoredModal &&
    css`
      overflow: hidden;
    `}

  ${({ theme: { breakpoints }, topAligned }) =>
    topAligned &&
    breakpoints.md`
    margin-top: 4%;
  `}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100vh;
      overflow: hidden;
    `}

  ${({ fullScreen }) =>
    fullScreen &&
    css`
      margin: 0;
      width: 100%;
      min-height: 100vh;
      min-height: fill-available;

      // iOS devices
      @supports (-webkit-touch-callout: none) {
        padding-bottom: 80px;
      }
    `}

  ${({ animation }) =>
    animation === ModalAnimation.fadeIn &&
    css`
      animation: fadeIn 0.1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    `}

  ${({ animation }) =>
    animation === ModalAnimation.slideUp &&
    css`
      animation: slideUp 0.2s linear forwards;
    `}

  ${({ theme: { breakpoints }, width, minHeight }) => breakpoints.md`
    width: ${width}px;
    min-height: ${minHeight === 'auto' ? minHeight : minHeight + 'px'};
  `};

  @keyframes fadeIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes slideUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const StyledModalBackdrop = styled.div<{}>`
  position: absolute;
  top: 0;
  left: 0;
  right: ${SCROLLBAR_WIDTH + 2}px;
  bottom: 0;
  z-index: ${p => p.theme.zIndexLevels.ModalBackdrop};
`;

// GlobalStyledModalBody is used for PWA. When modal is open, user is able to Pull to refresh. This style blocks Pull to refresh if modal is opened.
export const GlobalStyledModalBody = createGlobalStyle`
  body {
    overscroll-behavior-y: none;
  }
`;
