import { gql } from '@apollo/client';
import {
  PaymentCouponApiType,
  PaymentCustomerApiType,
  PaymentMethodApiType,
  PaymentPlansApiType,
} from './Billing.types';

export const GET_PAYMENT_CUSTOMER_BY_WORKSPACE = gql`
  query Workspace($id: ID!) {
    workspace(id: $id) @connection(key: "payment_customers") {
      paymentCustomer {
        id
        billingName
        billingEmail
        country
        city
        line1
        line2
        postalCode
        state
        vatNumber
        vatType
        invoices(order: { createdAt: "desc" }) {
          edges {
            node {
              id
              _id
              periodStart
              periodEnd
              amount
              currency
              paymentMethod
              pdf {
                contentUrl
              }
            }
          }
        }
      }
    }
  }
`;

export interface GetPaymentCustomerByWorkspaceVariables {
  id: string;
}

export interface GetPaymentCustomerByWorkspaceResponse {
  workspace: {
    paymentCustomer: PaymentCustomerApiType;
  };
}

export const GET_REPEATED_PAYMENT_CUSTOMER_BY_WORKSPACE = gql`
  query Workspace($id: ID!) {
    workspace(id: $id) @connection(key: "payment_customers") {
      paymentCustomer {
        id
        billingName
        billingEmail
        country
        city
        line1
        line2
        postalCode
        state
        vatNumber
        vatType
      }
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($paymentCustomer: ID!) {
    getPaymentMethods(paymentCustomer: $paymentCustomer) {
      paymentMethodId
      lastFour
      expirationYear
      expirationMonth
      type
      default
    }
  }
`;

export interface GetPaymentMethodsVariables {
  paymentCustomer: string;
}

export interface GetPaymentMethodsResponse {
  getPaymentMethods: PaymentMethodApiType[];
}

export const GET_PAYMENT_PLANS = gql`
  query GetPaymentPlans($workspace: ID!) {
    getPaymentPlans(workspace: $workspace) {
      workspaceType
      amount
      currency
      interval
    }
  }
`;

export interface GetPaymentPlansVariables {
  workspace: string;
}

export interface GetPaymentPlansResponse {
  getPaymentPlans: PaymentPlansApiType[];
}

export const GET_PAYMENT_COUPON = gql`
  query GetPaymentCoupon($paymentCustomer: ID!) {
    getPaymentCoupon(paymentCustomer: $paymentCustomer) {
      id
      name
      percentOff
      finishedAt
    }
  }
`;

export interface GetPaymentCouponVariables {
  paymentCustomer: string;
}

export interface GetPaymentCouponResponse {
  getPaymentCoupon: PaymentCouponApiType;
}
