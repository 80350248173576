import { StreamEventActions } from '../../../GeneralMercure.types';
import { evictApolloEntitiesByType } from '../../../../../Api/Api.utils';
import { ApolloClient } from '@apollo/client';
import { GRAPHQL_TYPENAME_NOTIFICATION } from '../../../../../Notifications/Notifications.constants';

export const refreshAccountNotifications = async (
  event: StreamEventActions,
  apolloClient: ApolloClient<any>,
) => {
  if (event) {
    evictApolloEntitiesByType(apolloClient, GRAPHQL_TYPENAME_NOTIFICATION);
  }
};
