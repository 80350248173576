import Color from 'color';
import styled, { css } from 'styled-components';

const APPTOPBAR_HEIGHT = 40;
export const APPTOPBAR_MOBILE_HEIGHT = 56;

export const StyledTopBar = styled.div<{
  isMobile: boolean;
}>`
  background-color: ${p => p.theme.colors.IdentityBar};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: ${({ isMobile }) =>
    isMobile ? APPTOPBAR_MOBILE_HEIGHT : APPTOPBAR_HEIGHT}px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100vw;
  grid-gap: ${({ theme }) => theme.spacing.m};

  ${({ theme: { breakpoints, colors, spacing } }) => breakpoints.md`
      position: initial;
      border-bottom: 1px solid ${colors.OnNavbarDivider};
      display: flex;
      justify-content: space-between;
      padding: 0 ${spacing.xs};
  `}
`;

export const SearchSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  position: relative;
  min-height: 100%;
  max-height: 100%;
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const OtherOptionsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-around;
  flex: 1;
  grid-gap: ${({ theme }) => theme.spacing.m};

  ${({ theme }) => theme.breakpoints.md`
      width: 25vw;
      flex: unset;
      justify-content: flex-end;
      margin-right: ${theme.spacing.m};
  `}
`;

export const Option = styled.div<{ active?: boolean }>`
  color: ${({ theme }) => theme.colors.OnNavbarMediumEmphasis};
  transition: 0.1s color ease-out;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 4px;

  ${({ theme: { breakpoints, colors } }) => breakpoints.md`
      flex: unset;
  `}

  ${p =>
    p.active &&
    `
    color: ${p.theme.colors.OnNavbar};
    background-color: ${p.theme.colors.OnNavbarSelected};
  `}

  ${p =>
    !p.active &&
    css`
      &:hover {
        color: ${p.theme.colors.OnNavbar};
        background-color: ${p.theme.colors.OnNavbarHover};
      }
    `}
`;

export const MobileSearchWrapper = styled.div`
  background-color: ${({ theme }) =>
    Color(theme.colors.Surface).alpha(0.2).rgb().string()};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  z-index: 100;
`;
