import { StreamDesktopAppEvent } from '../../../GeneralMercure.types';
import { getDesktopAppsRepository } from '../../../../../Desktop/deprecatedData/Repository/DesktopRepository';
import { getLongId, getShortId } from '../../../../../../shared/utils/id';

export const desktopAppRequestNew = (event: StreamDesktopAppEvent) => {
  // To avoid porting between mercure event and DesktopAppApiType
  // we'll just fetch desktop apps for now.
  // TODO: @bqk
  getDesktopAppsRepository(
    getShortId(event.desktop.id),
    getLongId('workspaces', event.desktop.workspace.id),
  );
};
