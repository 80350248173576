import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../domains/Theme';

export const useMobile = () =>
  useMediaQuery({
    maxWidth: Breakpoints.md - 1,
  });

export const useTablet = () =>
  useMediaQuery({
    maxWidth: Breakpoints.lg - 1,
  });

export const useLaptop = () =>
  useMediaQuery({
    maxWidth: Breakpoints.xlg - 1,
  });

export const useMediumScreen = () =>
  useMediaQuery({
    maxWidth: Breakpoints.xxl - 1,
  });
