import { useCallback, useContext, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { makeKey } from './FetchPolicy';
import { FetchPolicyContext } from './FetchPolicy/FetchPolicy.context';
import { useIsOnline } from '../Offline';

export const useRefreshableQuery: typeof useQuery = (query, options) => {
  const variables = options?.variables;
  const key = makeKey(
    ...(Object.values(variables ?? {}) as Parameters<typeof makeKey>),
  );
  const ignore = options?.skip === true;

  const { state, addItem } = useContext(FetchPolicyContext);
  const isRefreshed = state[key];
  const isOnline = useIsOnline();

  const fetchPolicy = ignore
    ? undefined
    : !isOnline
    ? 'cache-only'
    : isRefreshed
    ? 'cache-first'
    : 'cache-and-network';

  return useQuery(
    query,
    options
      ? {
          ...options,
          ...(fetchPolicy ? { fetchPolicy } : null),
          onCompleted: (data: any) => {
            addItem?.(key);
            options.onCompleted?.(data);
          },
        }
      : undefined,
  );
};

export const useAbortController = () => {
  const abortControllerRef = useRef<AbortController>();

  const getAbortController = useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }
    return abortControllerRef.current;
  }, []);

  useEffect(() => {
    return () => getAbortController().abort();
  }, [getAbortController]);

  const getSignal = useCallback(
    () => getAbortController().signal,
    [getAbortController],
  );

  return getSignal;
};
