import styled from 'styled-components';
import {
  DROPZONE_ACTIVE_CLASSNAME,
  DROPZONE_BACKGROUND_COLOR_ACTIVE,
  DROPZONE_BACKGROUND_COLOR_DEFAULT,
  DROPZONE_DRAGGING_OVER_WRAPPER_CLASSNAME,
  DROPZONE_ENABLED_CLASSNAME,
} from './ExternalUrlDropZone.constants';

export const StyledExternalUrlDropZone = styled.div`
  position: absolute;
  background-color: ${DROPZONE_BACKGROUND_COLOR_DEFAULT};
  border: 1px solid ${p => p.theme.colors.Primary};
  border-radius: 8px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease, background-color 0.2s ease;

  .${DROPZONE_DRAGGING_OVER_WRAPPER_CLASSNAME} &.${DROPZONE_ENABLED_CLASSNAME} {
    opacity: 1;
    pointer-events: auto;

    &.${DROPZONE_ACTIVE_CLASSNAME} {
      background-color: ${DROPZONE_BACKGROUND_COLOR_ACTIVE};
    }
  }
`;
