import React, { useEffect } from 'react';
import { ErrorMessageContainer } from './ErrorMessage.styled';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { GlobalTranslation } from '../../Intl/i18n';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { LOGOUT_PATHNAME } from '../../Auth/Auth.constants';
import { useNativeWrapper } from '../../NativeWrapper';
import { clearBrowserStorageData } from '../../BrowserStorage/BrowserStorage.utils';

export const ErrorMessage = () => {
  const {
    isNativeWrapperAvailable,
    sendMessageToNativeWrapper,
  } = useNativeWrapper();

  useEffect(() => {
    clearBrowserStorageData();

    if (isNativeWrapperAvailable) {
      sendMessageToNativeWrapper({
        type: 'ERROR_PAGE_SHOWN',
      });
    }
  }, [isNativeWrapperAvailable]); // eslint-disable-line

  return (
    <ErrorMessageContainer>
      <h1>
        <FormattedMessage id={GlobalTranslation.genericErrorTitle} />
      </h1>
      <p>
        <FormattedHTMLMessage id={GlobalTranslation.genericErrorMessage} />
      </p>
      <div>
        <Button
          onClick={() => {
            window.location.href = '/';
          }}
          mode={ButtonMode.primary}
          size={ButtonSize.md}>
          <FormattedMessage id={GlobalTranslation.reloadAppButton} />
        </Button>
        <Button
          onClick={() => {
            window.location.href = LOGOUT_PATHNAME;
          }}
          mode={ButtonMode.secondary}
          size={ButtonSize.md}>
          <FormattedMessage id={GlobalTranslation.logOut} />
        </Button>
      </div>
    </ErrorMessageContainer>
  );
};
