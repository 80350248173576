import { CSSObject } from 'styled-components';
import { Anchor, PopoverOptions } from './Popover.types';

const ANCHOR_POSITION_FALLBACK = 16;

const getAnchorOptions = (
  options?: Partial<PopoverOptions>,
): PopoverOptions => ({
  viewportWidth: document.documentElement.clientWidth,
  viewportHeight: document.documentElement.clientHeight,
  horizontalMargin: 0,
  verticalMargin: 0,
  pageBottomMargin: 0,
  ...options,
});

export const getDefaultAnchor = (
  triggerRect: DOMRect,
  elementRect: DOMRect,
  options?: Partial<PopoverOptions>,
): Anchor => {
  const {
    viewportWidth,
    viewportHeight,
    horizontalMargin,
    pageBottomMargin,
  } = getAnchorOptions(options);

  const result: Anchor = {};

  if (
    elementRect.width + triggerRect.right + horizontalMargin <=
    viewportWidth
  ) {
    result.left = triggerRect.right + horizontalMargin;
  } else {
    result.left = triggerRect.left - horizontalMargin - elementRect.width;
  }

  if (elementRect.height + triggerRect.top <= viewportHeight) {
    result.top = triggerRect.top;
    if (result.left < 0) {
      result.left = triggerRect.left;
      result.top = triggerRect.top + triggerRect.height;
    }
  } else {
    result.bottom = pageBottomMargin;
  }

  if (result.left < 0) result.left = ANCHOR_POSITION_FALLBACK;
  if (result.top && result.top < 0) result.top = ANCHOR_POSITION_FALLBACK;

  return result;
};

export const getAnchorStyle = (anchor: Anchor) =>
  Object.keys(anchor).reduce<CSSObject>(
    (acc, item) => ({ ...acc, [item]: `${anchor[item as keyof Anchor]}px` }),
    {},
  );

export const getBottomLeftAnchor = (
  triggerRect: DOMRect,
  elementRect: DOMRect,
  options?: Partial<PopoverOptions>,
) => {
  const { viewportWidth, viewportHeight, verticalMargin } = getAnchorOptions(
    options,
  );

  const result: Anchor = {};

  if (triggerRect.left + elementRect.width > viewportWidth) {
    result.left = viewportWidth - elementRect.width;
  } else {
    result.left = triggerRect.left;
  }

  if (
    triggerRect.top + triggerRect.height + verticalMargin + elementRect.height <
    viewportHeight
  ) {
    result.top = triggerRect.top + triggerRect.height + verticalMargin;
  } else {
    result.bottom = viewportHeight - triggerRect.top + verticalMargin;
  }

  return result;
};

export const getBottomCenterPage = (
  triggerRect: DOMRect,
  elementRect: DOMRect,
) => {
  if (!elementRect) {
    return {};
  }

  const marginLeft =
    (document.documentElement.clientWidth - elementRect.width) / 2;
  const marginTop =
    document.documentElement.clientHeight - elementRect.height - 10;
  return {
    left: marginLeft,
    top: marginTop,
    position: 'fixed',
  };
};

export const getTopLeftAnchor = (
  triggerRect: DOMRect,
  elementRect: DOMRect,
  options?: Partial<PopoverOptions>,
) => {
  const { viewportWidth, verticalMargin } = getAnchorOptions(options);

  const result: Anchor = {};

  if (triggerRect.left + elementRect.width > viewportWidth) {
    result.left = viewportWidth - elementRect.width;
  } else {
    result.left = triggerRect.left;
  }

  result.top = triggerRect.top + triggerRect.height + verticalMargin;

  return result;
};
