import React from 'react';
import { ChatMercureClient } from './Chat/ChatMercureClient';
import { GeneralMercureClient } from './General/GeneralMercureClient';
import { PingMercureClient } from './Ping/PingMercureClient';

export interface MercureContextType {
  generalMercureClient: GeneralMercureClient | null;
  chatMercureClient: ChatMercureClient | null;
  pingMercureClient: PingMercureClient | null;
  setGeneralMercureClient: (
    generalMercureClient: GeneralMercureClient | null,
  ) => void;
  setChatMercureClient: (chatMercureClient: ChatMercureClient | null) => void;
  setPingMercureClient: (pingMercureClient: PingMercureClient | null) => void;
}

export const MercureContext = React.createContext<MercureContextType>({
  generalMercureClient: null,
  chatMercureClient: null,
  pingMercureClient: null,
  setGeneralMercureClient: () => {},
  setChatMercureClient: () => {},
  setPingMercureClient: () => {},
});
