import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const PersonIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.333 11.333a5.333 5.333 0 1 1-10.666 0 5.333 5.333 0 0 1 10.666 0zm-1.249 6.092a7.333 7.333 0 1 0-8.169 0C8 18.938 5.136 22.564 4.72 26.905a1 1 0 0 0 1.99.19 9.334 9.334 0 0 1 18.581 0 1 1 0 0 0 1.99-.19c-.416-4.341-3.28-7.967-7.196-9.48z"
        fill="currentColor"
        fillOpacity=".87"
      />
    </svg>
  );
});
