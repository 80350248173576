export const addEntityIdsToIDDBRelatedEntitiesList = (
  relatedIds: string[] = [],
  newRelatedIds: string[] = [],
) => {
  return Array.from(new Set([...relatedIds, ...newRelatedIds]));
};

export const removeEntityIdsFromIDDBRelatedEntitiesList = (
  relatedIds: string[] = [],
  relatedIdsToRemove: string[] = [],
) => {
  return relatedIds.filter(id => !relatedIdsToRemove.includes(id));
};
