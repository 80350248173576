import type { AuthSessionHandler } from '../../Auth/Auth.types';

export enum RequestMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface RestApiClientVariables<T> {
  bodyVariables: T;
}

export interface RestApiClientProps {
  authService: AuthSessionHandler;
}

export interface RestApiClientResponse<T> {
  data: T;
  status: number;
  ok: boolean;
}

export interface RestApiError {
  error: string;
  message: string;
  field: string | null;
  detail: string | null;
}

export interface RestApiErrorResponse {
  errors: RestApiError[];
  status: number;
  ok: boolean;
}
