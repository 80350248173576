import styled, { css } from 'styled-components';

export const StyledToggle = styled.div`
  display: flex;
  justify-content: space-between;

  input label {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .toggle-button {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.5rem;
    margin: 0;
    vertical-align: top;
    background: ${p => p.theme.colors.OnSurfaceSelected};
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover {
      background-color: ${p => p.theme.colors.SurfaceBorder};
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${p => p.theme.colors.SurfaceSubdued};
    }
  }

  .toggle-button::after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 1.125rem;
    height: 1.125rem;
    background: ${p => p.theme.colors.Surface};
    border-radius: 50%;
    transform: translateX(0);
    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .toggle-button:checked::after {
    transform: translateX(calc(100% - 3px));
    background: ${p => p.theme.colors.Surface};
  }

  .toggle-button:checked {
    background: ${p => p.theme.colors.Primary};

    &:disabled {
      background: ${p => p.theme.colors.Primary} !important;
      opacity: 0.25;
      cursor: not-allowed;
    }
  }
`;

export const StyledFormInput = styled.div<{ invalid: boolean }>`
  ${({ invalid, theme }) =>
    invalid &&
    css`
      input {
        box-shadow: inset 0 0 0 0.063rem ${theme.colors.CriticalLight};
        &:focus {
          box-shadow: inset 0 0 0 0.125rem ${theme.colors.CriticalLight};
        }
      }
    `}
`;

export const ErrorMessage = styled.div`
  color: ${p => p.theme.colors.CriticalDark};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-top: 0.25rem;
`;
