import { StreamAddWorkspaceTeamAccountEvent } from '../../../GeneralMercure.types';
import { addRelatedTeamsToAccountsIDDB } from '../../../../../Account/data/TeamAccounts/operations/TeamAccount.operations';
import { addRelatedAccountsToTeamsIDDB } from '../../../../../Team/data/AccountTeam/operations/AccountTeamIDDB.oprations';

export const addWorkspaceTeamAccountActionRequest = async (
  event: StreamAddWorkspaceTeamAccountEvent,
) => {
  if (event['@payload']?.workspaceTeamId && event['@payload']?.accountId) {
    addRelatedTeamsToAccountsIDDB(
      [event['@payload']?.accountId],
      [event['@payload']?.workspaceTeamId],
    );
    addRelatedAccountsToTeamsIDDB(
      [event['@payload']?.workspaceTeamId],
      [event['@payload']?.accountId],
    );
  }
};
