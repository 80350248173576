import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const GoogleIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M19.7917 12.1515C19.7917 11.4878 19.7386 11.0035 19.6237 10.5012H12.1553V13.4969H16.5391C16.4507 14.2413 15.9735 15.3625 14.9128 16.1158L14.898 16.2161L17.2594 18.0725L17.423 18.0891C18.9255 16.6809 19.7917 14.609 19.7917 12.1515Z"
          fill="#4285F4"
        />
        <path
          d="M12.1554 20.0443C14.3031 20.0443 16.1061 19.3267 17.4231 18.089L14.913 16.1158C14.2413 16.5911 13.3397 16.923 12.1554 16.923C10.0519 16.923 8.26655 15.5149 7.63012 13.5686L7.53683 13.5766L5.08143 15.505L5.04932 15.5956C6.35739 18.2325 9.04427 20.0443 12.1554 20.0443Z"
          fill="#34A853"
        />
        <path
          d="M7.62995 13.5686C7.46203 13.0664 7.36484 12.5282 7.36484 11.9721C7.36484 11.416 7.46203 10.8779 7.62112 10.3756L7.61667 10.2687L5.13049 8.30933L5.04915 8.34859C4.51003 9.44283 4.20068 10.6716 4.20068 11.9721C4.20068 13.2726 4.51003 14.5014 5.04915 15.5956L7.62995 13.5686Z"
          fill="#FBBC05"
        />
        <path
          d="M12.1554 7.02112C13.6491 7.02112 14.6566 7.67586 15.2312 8.22301L17.4761 5.99867C16.0974 4.69815 14.3031 3.8999 12.1554 3.8999C9.04427 3.8999 6.35739 5.71164 5.04932 8.34853L7.62128 10.3756C8.26654 8.42929 10.0519 7.02112 12.1554 7.02112Z"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            x="4.19995"
            y="3.8999"
            width="15.6"
            height="16.2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
});
