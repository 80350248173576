import { AppStoreLocale, AppStoreTranslation } from './i18n.types';

export const enAppStoreLocale: AppStoreLocale = {
  [AppStoreTranslation.appCardAddButton]: 'Add',
  [AppStoreTranslation.appCardDeleteButton]: 'Remove',
  [AppStoreTranslation.appStoreTabFilter]: 'App Store',
  [AppStoreTranslation.personalTabFilter]: 'My apps',
  [AppStoreTranslation.companyTabFilter]: 'Used at {workspaceName}',
  [AppStoreTranslation.customAppsTabFilter]: 'Custom apps',
  [AppStoreTranslation.customAppsIntegrationsTabFilter]: 'App integrations',
  [AppStoreTranslation.myPasswordsTabFilter]: 'My passwords',

  [AppStoreTranslation.appMenu]: 'App menu',
  [AppStoreTranslation.editCustomAppButton]: 'Edit',
  [AppStoreTranslation.editCustomAppNotification]:
    '{appName} has been updated.',
  [AppStoreTranslation.deleteCustomAppButton]: 'Delete',
  [AppStoreTranslation.deleteCustomAppConfirmation]: 'Delete this app?',
  [AppStoreTranslation.deleteCustomAppNotification]:
    '{appName} has been deleted.',

  [AppStoreTranslation.categoriesSectionTitle]: 'Categories',

  [AppStoreTranslation.appsSectionTitle]: 'App Store',
  [AppStoreTranslation.appsSectionSearchPlaceholder]: 'Search...',
  [AppStoreTranslation.noAppsAvailable]: 'No apps available',
  [AppStoreTranslation.noAppsAvailableMyPassword]:
    '<p>You have not added any passwords to your apps yet.</p><p><a href="{supportPasswordManagerUrl}" rel="noopener" target="_blank">Learn how to manage your app credentials.</a></p>',
  [AppStoreTranslation.noAppsAvailableCustomApps]:
    '<p>No apps added.</p> <p>To create a custom app go to Create custom app.</p> <p>For more information see <br> <a href="{supportCustomAppsUrl}" rel="noopener" target="_blank">{supportCustomAppsUrl}</a></p>',
  [AppStoreTranslation.noAppsAvailablePrivateApps]:
    '<p>No apps added.</p> <p>Go to the App Store or select a Category to add an App.</p> <p>For more information see <a href="{supportAddAppsUrl}" rel="noopener" target="_blank">{supportAddAppsUrl}</a></p>',
  [AppStoreTranslation.noAppsAvailableComapny]:
    'No apps are added to your workspace. As soon as apps are added to any desktops in your workspace they will appear here.',
  [AppStoreTranslation.noAppsAvailableCategory]:
    'There are no apps available for the selected category.',
  [AppStoreTranslation.noAppsAvailableFilter]:
    'There are no apps matching <strong>{search}</strong>.',

  [AppStoreTranslation.customAppBannerTitle]: 'Can’t find an app?',
  [AppStoreTranslation.customAppBannerDescription]:
    'Add it yourself, for yourself, your workspace, or everyone!',
  [AppStoreTranslation.customAppBannerButton]: 'Create custom app',

  [AppStoreTranslation.customAppFormCreateButton]: 'Add application',
  [AppStoreTranslation.customAppFormUpdateButton]: 'Update',
  [AppStoreTranslation.customAppFormManagePasswordsButton]: 'Change password',
  [AppStoreTranslation.customAppFormManagePasswordsDisabledTooltip]:
    'To access Manage passwords feature change your own password in My profile section',
  [AppStoreTranslation.customAppFormNameLabel]: 'App name',
  [AppStoreTranslation.customAppFormNamePlaceholder]: ' ',
  [AppStoreTranslation.customAppFormNameRequired]: 'App name is required',
  [AppStoreTranslation.customAppFormUrlRequired]: 'Url is required',
  [AppStoreTranslation.customAppFormUrlIsNotValid]: 'Url is not valid',
  [AppStoreTranslation.customAppFormUrlLabel]: 'App URL',
  [AppStoreTranslation.customAppFormUrlPlaceholder]: ' ',
  [AppStoreTranslation.customAppFormUrlInvalid]: 'Url is invalid',
  [AppStoreTranslation.customAppFormUrlIncomplete]: 'Url is not complete',
  [AppStoreTranslation.customAppFormCategoryLabel]: 'Category',
  [AppStoreTranslation.customAppFormCategoryPlaceholder]: 'Select category',
  [AppStoreTranslation.customAppFormCategoryRequired]: 'Category is required',
  [AppStoreTranslation.customAppFormLogoLabel]: 'App icon',
  [AppStoreTranslation.customAppFormLogoButton]: 'Upload image',
  [AppStoreTranslation.customAppFormFetchAppImageByUrl]:
    'Fetch app image by URL',
  [AppStoreTranslation.customAppFormPreviewInfo]:
    'Input app url to fetch the image',
  [AppStoreTranslation.customAppFormPreviewError]:
    'This app url has no image preview',
  [AppStoreTranslation.customAppFormLogoRequired]: 'Logo is required',

  [AppStoreTranslation.createCustomAppModalHeader]: 'Add custom app',
  [AppStoreTranslation.createCustomAppSuccessMessage]:
    'Custom app has been added successfully',

  [AppStoreTranslation.editCustomAppModalHeader]: 'Edit app',
  [AppStoreTranslation.editCustomAppSuccessMessage]:
    'App has been updated successfully',

  [AppStoreTranslation.customAppPreviewHeader]: 'All set!',
  [AppStoreTranslation.customAppPreviewSuccessMessage]:
    '{appName} was successfully created. The app is located under "Used at {workspaceName}"',
  [AppStoreTranslation.customAppPreviewDesktopButton]:
    'Add custom app to the desktop',
  [AppStoreTranslation.customAppAddedToDesktop]:
    'Custom app has been added to desktop',
  [AppStoreTranslation.customAppPreviewContinueButton]:
    'Continue to App Store!',

  [AppStoreTranslation.ssoAppTabName]: 'SSO',
  [AppStoreTranslation.ssoAppGridTitle]: 'SSO',
  [AppStoreTranslation.ssoAppGridSubtitle]:
    '{appCount} {appCount, plural, one {compatible app} other {compatible apps}}',
  [AppStoreTranslation.ssoAppGridIntroText]: `<p>Webtop.com provides a Single Sign-On service that lets users access supported web apps using their Webtop.com credentials. In this way, users will only be prompted to log in when accessing Webtop.com, and not when opening the supported web apps.</p>
<p>
  <strong>Support links:</strong>
  <ul>
    <li><a target="_blank" rel="noopener noreferrer" href="{supportSsoUrl}">Predefined SSO configurations</a></li>
    <li><a target="_blank" rel="noopener noreferrer" href="{supportSsoCustomAppUrl}">Compatible SSO configurations and custom SSO configurations</a></li>
  </ul>
</p>`,
  [AppStoreTranslation.ssoAppGridCustomAppSsoMessage]:
    'Can’t find an app you’d like to configure SSO for?',
  [AppStoreTranslation.ssoAppGridCustomAppSsoButton]: 'Add your custom SSO.',
  [AppStoreTranslation.ssoAppGridConfiguredTitle]: 'SSO Configured',
  [AppStoreTranslation.ssoAppGridPredefinedTitle]: 'SSO Predefined',
  [AppStoreTranslation.ssoAppGridCompatibleTitle]: 'SSO Compatible',
  [AppStoreTranslation.ssoAppGridPredefinedSubtitle]:
    '{groupCount} {groupCount, plural, one {configuration} other {configurations}} – {appCount} {appCount, plural, one {app} other {apps}}',
  [AppStoreTranslation.ssoAppGridCompatibleSubtitle]:
    '{appCount} {appCount, plural, one {app} other {apps}}',
  [AppStoreTranslation.ssoAppGridCompatibleEmptyText]:
    'There are no SSO compatible apps',
  [AppStoreTranslation.ssoAppCardLabelsConfigure]: 'Configure',
  [AppStoreTranslation.ssoAppCardLabelsEdit]: 'Edit',
  [AppStoreTranslation.ssoAppCardLabelsEnable]: 'Enable',
  [AppStoreTranslation.ssoAppCardLabelsEnabled]: 'Enabled',
  [AppStoreTranslation.ssoAppCardLabelsEnabledForApps]:
    'Enabled for {appCount} apps',
  [AppStoreTranslation.ssoAppCardLabelsDisable]: 'Disable',
  [AppStoreTranslation.ssoAppCardLabelsDisabled]: 'Disabled',
  [AppStoreTranslation.ssoAppCardLabelsDisabledForApps]:
    'Disabled for {appCount} apps',
  [AppStoreTranslation.ssoAppCardLabelsSave]: 'Save',
  [AppStoreTranslation.ssoAppCardLabelsInitialize]: 'Initialize',
  [AppStoreTranslation.ssoAppCardLabelsCopy]: 'Copy',
  [AppStoreTranslation.ssoAppConfigureCompatibleApps]: 'Compatible apps',
  [AppStoreTranslation.ssoAppConfigureFieldRequired]: 'This field is required',
  [AppStoreTranslation.ssoAppConfigureFieldRequiredEnabled]:
    'This field is required when SSO is enabled',
  [AppStoreTranslation.ssoAppDangerZoneTitle]: 'Danger zone',
  [AppStoreTranslation.ssoAppDangerZoneDeleteButton]: 'Delete configuration',
  [AppStoreTranslation.ssoAppDangerZoneDeleteDescription]:
    'This operation will disable SSO and delete its configuration.',
  [AppStoreTranslation.ssoAppMessagesConfigurationCreated]:
    'SSO configuration has been successfully created',
  [AppStoreTranslation.ssoAppMessagesConfigurationUpdated]:
    'SSO configuration has been successfully updated',
  [AppStoreTranslation.ssoAppMessagesConfigurationEnabled]:
    'SSO has been successfully enabled',
  [AppStoreTranslation.ssoAppMessagesConfigurationDisabled]:
    'SSO has been successfully disabled',
  [AppStoreTranslation.ssoAppMessagesConfigurationDeleted]:
    'SSO configuration has been successfully deleted',
  [AppStoreTranslation.ssoAppConfirmationConfigurationDelete]:
    'Delete SSO configuration?',

  [AppStoreTranslation.customSsoClaimsNameIdentifier]: 'Name ID',
  [AppStoreTranslation.customSsoClaimsEmail]: 'Email',
  [AppStoreTranslation.customSsoClaimsGivenName]: 'Given name',
  [AppStoreTranslation.customSsoClaimsFamilyName]: 'Family name',
  [AppStoreTranslation.customSsoClaimsCustom]: 'Custom',
  [AppStoreTranslation.customSsoClaimValuesUserId]: 'User ID',
  [AppStoreTranslation.customSsoClaimValuesEmail]: 'Email',
  [AppStoreTranslation.customSsoClaimValuesGivenName]: 'Given name',
  [AppStoreTranslation.customSsoClaimValuesFamilyName]: 'Family name',
  [AppStoreTranslation.customSsoNameIdFormatLabel]: 'Name ID format',
  [AppStoreTranslation.customSsoClaimNameLabel]: 'Claim type',
  [AppStoreTranslation.customSsoClaimValueLabel]: 'Value',
  [AppStoreTranslation.customSsoAddClaimButton]: 'Add claim',
};
