import { database } from '../../../Database';
import { AccessApiTypeToAccessTableType } from '../../../Database/AccessTable/AccessTable.utils';
import { AccessApiType } from '../types/Access.types';
import { DesktopAccessType } from '../../../Desktop/data/Desktop/types/Desktop.types';
import { getAccessByDesktopIdAndWorkspaceTeamId } from '../dataSources/AccessIDDB.dataSource';

export const removeAccessByWorkspaceTeamIdAndDesktopIdIDDB = async (
  teamId: string,
  desktopId: string,
) => {
  try {
    const access = await getAccessByDesktopIdAndWorkspaceTeamId(
      desktopId,
      teamId,
    );

    if (access) {
      await database.access.delete(access.id);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addAccessToIDDB = async (
  apiAccess: AccessApiType,
  workspaceId: string,
) => {
  const rowAccess = AccessApiTypeToAccessTableType(apiAccess, workspaceId);

  database.access.put(rowAccess);
};

export const bulkPutAccessesToIDDB = (
  apiAccesses: AccessApiType[],
  workspaceId: string,
) => {
  try {
    const rowAccesses = apiAccesses.map(apiAccess =>
      AccessApiTypeToAccessTableType(apiAccess, workspaceId),
    );

    return database.access.bulkPut(rowAccesses);
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};

export const bulkDeleteAccessesFromIDDB = (apiAccessIds: string[]) => {
  try {
    return database.access.bulkDelete(apiAccessIds);
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};

export const removeAccessByIdIDDB = async (accessId: string) => {
  try {
    const cachedAccess = await database.access.where({ id: accessId });

    if (cachedAccess) {
      cachedAccess.delete();
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateAccessInIDDB = async (
  accessId: string,
  accessType: DesktopAccessType,
) => {
  try {
    const cachedAccess = await database.access.where({ id: accessId });

    cachedAccess.modify((value, ref) => {
      ref.value = {
        ...value,
        sourceData: {
          ...value.sourceData,
          accessType,
        },
      };
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateAccessByDesktopIdInIDDB = async (
  desktopId: string,
  accessType: DesktopAccessType,
) => {
  try {
    const cachedAccess = await database.access.where({ desktopId });

    cachedAccess.modify((value, ref) => {
      ref.value = {
        ...value,
        sourceData: {
          ...value.sourceData,
          accessType,
        },
      };
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateAccessByTeamIdInIDDB = async (
  teamId: string,
  accessType: DesktopAccessType,
) => {
  try {
    const cachedAccess = await database.access.where({ teamId });

    cachedAccess.modify((value, ref) => {
      ref.value = {
        ...value,
        sourceData: {
          ...value.sourceData,
          accessType,
        },
      };
    });
  } catch (error) {
    console.error(error);
  }
};
