import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const FacebookIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M16.5999 8.9999C16.5999 4.52639 12.9734 0.899902 8.4999 0.899902C4.02639 0.899902 0.399902 4.52639 0.399902 8.9999C0.399902 13.0428 3.36194 16.3938 7.23428 17.0015V11.3413H5.17764V8.9999H7.23428V7.21537C7.23428 5.18531 8.44358 4.06397 10.2938 4.06397C11.1797 4.06397 12.1069 4.22217 12.1069 4.22217V6.21553H11.0856C10.0794 6.21553 9.76553 6.83996 9.76553 7.48115V8.9999H12.012L11.6529 11.3413H9.76553V17.0015C13.6379 16.3938 16.5999 13.0428 16.5999 8.9999Z"
        fill="currentColor"
      />
    </svg>
  );
});
