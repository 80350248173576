import { gql } from '@apollo/client';
import { ApiConnection } from '../../../../../shared/api/api.types';
import { LinkFragmentFields } from '../../../../Link/Link.fragments';
import { LinkApiType } from '../../../../Link/Link.types';

export const GET_DESKTOP_LINKS_REST = gql`
  query GetDesktopLinksRest($desktopId: ID!) {
    desktopLinks(desktopId: $desktopId)
      @rest(path: "/desktop/{args.desktopId}/links")
      @connection(key: "desktop_links_rest", filter: ["args.desktopId"]) {
        edges {
          node {
            ${LinkFragmentFields}
          }
        }
    }
  }
`;

export interface GetDesktopLinksRestVariables {
  desktopId: string;
}

export interface GetDesktopLinksRestResponse {
  desktopLinks: ApiConnection<LinkApiType>;
}
