import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DocumentOfficeWordIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="document-office-word-icon"
      {...props}>
      <path
        d="M16.793 1.75H6.75a.5.5 0 0 0-.5.5v19.5a.5.5 0 0 0 .5.5h14.5a.5.5 0 0 0 .5-.5V6.707a.5.5 0 0 0-.146-.353l-4.457-4.458a.5.5 0 0 0-.354-.146z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.25a.75.75 0 0 1 .75-.75h10.043a.75.75 0 0 1 .53.22l4.457 4.457c.141.14.22.331.22.53V21.75a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V2.25zM6.75 2a.25.25 0 0 0-.25.25v19.5c0 .138.112.25.25.25h14.5a.25.25 0 0 0 .25-.25V6.707a.25.25 0 0 0-.073-.177L16.97 2.073A.25.25 0 0 0 16.793 2H6.75z"
        fill="#979593"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 6.25v-4.5h.5v4.5c0 .138.112.25.25.25h4.5V7h-4.5a.75.75 0 0 1-.75-.75z"
        fill="#979593"
      />
      <path
        d="M19.75 15.25H12v-.5h7.75a.25.25 0 1 1 0 .5zm0-1.5H12v-.5h7.75a.25.25 0 1 1 0 .5zm0-1.5H12v-.5h7.75a.25.25 0 1 1 0 .5zm0-1.5H12v-.5h7.75a.25.25 0 1 1 0 .5zm0 6H12v-.5h7.75a.25.25 0 1 1 0 .5z"
        fill="#C8C6C4"
      />
      <path
        d="M3 18.5h8a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1z"
        fill="#185ABD"
      />
      <path
        d="M5.406 15.161c.017.13.027.245.033.342h.019a5.764 5.764 0 0 1 .107-.664L6.447 11H7.59l.914 3.782c.046.187.084.424.115.713h.015c.013-.2.044-.43.095-.69L9.46 11h1.04l-1.28 5.5H8.005l-.872-3.644a8.448 8.448 0 0 1-.144-.778h-.016c-.01.09-.03.222-.059.399-.029.176-.052.306-.07.39L6.025 16.5H4.791L3.5 11h1.059l.796 3.847c.018.08.035.184.051.314z"
        fill="#fff"
      />
    </svg>
  );
});
