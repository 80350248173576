import styled, { keyframes } from 'styled-components';

const ANIM_SPEED = 2;
const LOW_OPACITY = 0.3;

const animTopRight = keyframes`
  0% { opacity: 1; }
  50% { opacity: ${LOW_OPACITY}; }
  100% { opacity: 1; }
`;

const animBottomLeft = keyframes`
  0% { opacity: ${LOW_OPACITY}; }
  50% { opacity: 1; }
  100% { opacity: ${LOW_OPACITY}; }
`;

export const StyledSpinner = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${p => (p.height ? p.height + 'px' : 'initial')};

  .top-right {
    animation: ${animTopRight} ${ANIM_SPEED}s linear infinite;
  }
  .bottom-left {
    animation: ${animBottomLeft} ${ANIM_SPEED}s linear infinite;
  }
`;

const dotsAnimation = keyframes`
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
`;

export const SpinnerTitle = styled.div`
  ${({ theme }) => theme.fontTemplates.smallSemibold};
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};

  &::after {
    display: inline-block;
    animation: ${dotsAnimation} steps(1, end) 1s infinite;
    content: '';
    width: 10px;
  }
`;
