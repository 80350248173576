import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DocumentGoogleSpreadsheetsIcon: FC<IconProps> = React.memo(
  props => {
    return (
      <svg
        fill="none"
        height="46"
        viewBox="0 0 46 46"
        width="46"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="document-google-spreadsheets-icon"
        {...props}>
        <g fill="#108043">
          <path
            clipRule="evenodd"
            d="m11.02 6.708a.48.48 0 0 0 -.479.48v31.625c0 .264.215.479.48.479h23.958a.48.48 0 0 0 .48-.48v-21.562h-7.189a3.354 3.354 0 0 1 -3.355-3.354v-7.188h-13.894zm16.772 1.074v6.114c0 .264.214.479.479.479h6.114zm-20.127-.594a3.355 3.355 0 0 1 3.355-3.355h15.498c.89 0 1.742.353 2.37.984l8.46 8.46c.632.629.984 1.481.984 2.37v23.166a3.357 3.357 0 0 1 -3.354 3.354h-23.957a3.354 3.354 0 0 1 -3.355-3.355v-31.623z"
            fillRule="evenodd"
          />
          <rect height="3.833" rx="1.438" width="7.667" x="14.375" y="19.167" />
          <rect height="3.833" rx="1.438" width="7.667" x="14.375" y="24.917" />
          <rect height="3.833" rx="1.438" width="7.667" x="14.375" y="30.667" />
          <rect height="3.833" rx="1.438" width="7.667" x="23.959" y="19.167" />
          <rect height="3.833" rx="1.438" width="7.667" x="23.959" y="24.917" />
          <rect height="3.833" rx="1.438" width="7.667" x="23.959" y="30.667" />
        </g>
      </svg>
    );
  },
);
