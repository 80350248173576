import React from 'react';

type MaintenanceContextType = {
  maintenanceOn: boolean;
  setMaintenance: (_: boolean) => void;
};

export const MaintenanceContext = React.createContext<MaintenanceContextType>({
  maintenanceOn: false,
} as MaintenanceContextType);
