import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const OpenDesktopIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}>
      <g clipPath="url(#a)" fill="currentColor" fillOpacity=".6">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2.25v4.146a.25.25 0 0 1-.427.177L14.03 5.03l-3.75 3.75a.75.75 0 1 1-1.06-1.06l3.75-3.75-1.543-1.543A.25.25 0 0 1 11.604 2h4.146a.25.25 0 0 1 .25.25z"
        />
        <path d="M2.75 4.5a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h10.5a.25.25 0 0 0 .25-.25v-2.5a.75.75 0 1 1 1.5 0v2.5A1.75 1.75 0 0 1 13.25 14H2.75A1.75 1.75 0 0 1 1 12.25v-7.5C1 3.784 1.784 3 2.75 3h5.5a.75.75 0 0 1 0 1.5h-5.5z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});
