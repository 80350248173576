import { gql } from '@apollo/client';
import { GRAPHQL_TYPENAME_LINK_DATA } from '../Chat/Chat.constants';
import { GRAPHQL_TYPENAME_LINK } from './Link.constants';

export const LinkDataFragmentFields = `
  _id,
  id
  url
  realUrl
  title
  description
  icon {
    id
    contentUrl
  }
  image {
    id
    contentUrl
    dimensions
  }
  screenshot {
    id
    contentUrl
    dimensions
  }
  status
  abuseChecked
  safe
  abuseDescription
  metadata
`;

export const LinkFragmentFields = `
  id
  _id
  accountFavorite
  createdAt
  movedAt
  createdBy {
    id
    _id
    email
    firstName
    lastName
    image {
      id
      contentUrl
    }
    createdAt
    modifiedAt
  }
  tags {
    edges {
      node {
        id
        _id
        name
      }
    }
  }
  customTitle
  data {
    ${LinkDataFragmentFields}
  }
  customImage {
    id
    contentUrl
    dimensions
  }
  desktopId
  folder {
    id
    _id
  }
  chatConversationIri
  messagesCount
`;

export const LinkFragment = gql`
  fragment LinkFragment on ${GRAPHQL_TYPENAME_LINK} {
    ${LinkFragmentFields}
  }
`;

export const LinkDataFragment = gql`
  fragment LinkDataFragment on ${GRAPHQL_TYPENAME_LINK_DATA} {
    ${LinkDataFragmentFields}
  }`;
