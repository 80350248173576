import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { CountersContext } from './Counters.context';
import { useCurrentAccount } from '../Auth/Auth.hooks';
import {
  getUnreadConversationsMap,
  getUnreadItemsMap,
  getUnreadMessagesMap,
  normalizeUnreadItems,
} from './Counters.utils';
import { CountersContextType, UnreadChatMessagesMap } from './Counters.types';

export const CountersProvider: FC<PropsWithChildren> = ({ children }) => {
  const { account } = useCurrentAccount();
  const normalizedUnreadItems = useMemo(
    () => ({
      unreadNotifications: normalizeUnreadItems(account?.unreadNotifications),
      unreadChatMessages: normalizeUnreadItems(account?.unreadChatMessages),
      unreadChatConferences: normalizeUnreadItems(
        account?.unreadChatConferences,
      ),
      unreadMentions: normalizeUnreadItems(account?.unreadMentions),
      unreadThreads: normalizeUnreadItems(account?.unreadThreads),
    }),
    [account],
  );
  const unreadNotifications = useMemo(
    () => getUnreadItemsMap(normalizedUnreadItems.unreadNotifications),
    [normalizedUnreadItems.unreadNotifications],
  );
  const unreadChatMessages = useMemo(
    () =>
      getUnreadMessagesMap(
        normalizedUnreadItems.unreadChatMessages,
      ) as UnreadChatMessagesMap,
    [normalizedUnreadItems.unreadChatMessages],
  );
  const unreadChatConferences = useMemo(
    () => getUnreadItemsMap(normalizedUnreadItems.unreadChatConferences),
    [normalizedUnreadItems.unreadChatConferences],
  );
  const unreadMentions = useMemo(
    () => getUnreadItemsMap(normalizedUnreadItems.unreadMentions),
    [normalizedUnreadItems.unreadMentions],
  );
  const unreadThreads = useMemo(
    () => getUnreadItemsMap(normalizedUnreadItems.unreadThreads),
    [normalizedUnreadItems.unreadThreads],
  );
  const unreadChatConversations = useMemo(
    () => getUnreadConversationsMap(normalizedUnreadItems.unreadChatMessages),
    [normalizedUnreadItems.unreadChatMessages],
  );
  const contextValue: CountersContextType = useMemo(
    () => ({
      unreadNotifications,
      unreadChatMessages,
      unreadChatConferences,
      unreadMentions,
      unreadThreads,
      unreadChatConversations,
    }),
    [
      unreadNotifications,
      unreadChatMessages,
      unreadChatConferences,
      unreadMentions,
      unreadThreads,
      unreadChatConversations,
    ],
  );
  const totalUnreadItems = useMemo(
    () =>
      [
        ...normalizedUnreadItems.unreadNotifications,
        ...normalizedUnreadItems.unreadChatMessages,
        ...normalizedUnreadItems.unreadChatConferences,
        ...normalizedUnreadItems.unreadMentions,
        ...normalizedUnreadItems.unreadThreads,
      ].reduce((acc, item) => acc + item.count, 0),
    [
      normalizedUnreadItems.unreadChatConferences,
      normalizedUnreadItems.unreadChatMessages,
      normalizedUnreadItems.unreadMentions,
      normalizedUnreadItems.unreadNotifications,
      normalizedUnreadItems.unreadThreads,
    ],
  );
  useEffect(() => {
    if (totalUnreadItems >= 0) {
      navigator.setAppBadge?.(totalUnreadItems);
    }

    return () => {
      navigator.clearAppBadge?.();
    };
  }, [totalUnreadItems]);
  return (
    <CountersContext.Provider value={contextValue}>
      {children}
    </CountersContext.Provider>
  );
};
