export const GRAPHQL_TYPENAME_KEYSET = 'Keyset';
export const GRAPHQL_TYPENAME_VAULT = 'Vault';
export const GRAPHQL_TYPENAME_VAULT_EDGE = 'VaultEdge';
export const GRAPHQL_TYPENAME_VAULT_ITEM = 'VaultItem';
export const GRAPHQL_TYPENAME_VAULT_ITEM_EDGE = 'VaultItemNode';
export const GRAPHQL_TYPENAME_VAULT_ACCESS = 'VaultAccess';
export const GRAPHQL_TYPENAME_VAULT_ACCESS_EDGE = 'VaultAccessNode';
export const GRAPHQL_TYPENAME_VAULT_APP_CONFIG = 'AppVaultItemConfig';
export const GRAPHQL_TYPENAME_VAULT_APP_CONFIG_EDGE = 'AppVaultItemConfigNode';
export const GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP =
  'AppVaultItemConfigDesktop';
export const GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP_EDGE =
  'AppVaultItemConfigDesktopNode';
export const GRAPHQL_TYPENAME_PASSWORD_RECOVERY_REQUEST =
  'PasswordRecoveryRequest';

export const MASTER_PASSWORD_LOCK_TOAST_ID = 'master-password-lock-toast';

export const DEFAULT_APPS_SIZE = 30;

export const STRONG_PASSWORD_REGEXP = new RegExp(
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
);
export const MEDIUM_PASSWORD_REGEXP = new RegExp(
  '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))',
);

export const VAULTS_LIST_FILTER_QUERY_PARAM = 'filterPasswords';

export const VAULTS_LIST_SEARCH_BAR = 'searchBar';

export const SELECT_ALL_VAULTS_QUERY_PARAM = 'selectAll';

export const DELETE_MULTIPLE_VAULTS_INPUT_VALUE = 'delete';
