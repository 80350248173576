import { Locale as DateFnsLocale } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { LOCALE_NAME_ENGLISH } from './i18n.constants';

/**
 * Import locales for other languages when we need them.
 */
export const DateLocales: { [key: string]: DateFnsLocale } = {
  [LOCALE_NAME_ENGLISH]: enGB,
};
