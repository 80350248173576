import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const HomeIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      fill="none"
      height="15"
      viewBox="0 0 14 15"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
        d="m7.156 1.83498c-.0443-.03538-.09931-.05464-.156-.05464s-.1117.01926-.156.05464l-5.25 4.2c-.02941.02349-.05314.05331-.06942.08725-.01627.03394-.02468.07111-.02458.10875v7.01902c0 .138.112.25.25.25h2.75v-5.25002c0-.19891.07902-.38968.21967-.53033s.33142-.21967.53033-.21967h3.5c.19891 0 .38968.07902.53033.21967s.21967.33142.21967.53033v5.25002h2.75c.0663 0 .1299-.0264.1768-.0732.0469-.0469.0732-.1105.0732-.1768v-7.02002c-.0001-.03747-.0085-.07445-.0248-.1082s-.0399-.06342-.0692-.0868zm-1.25-1.171c.31034-.248381.696-.383707 1.0935-.383707s.78316.135326 1.0935.383707l5.25 4.2c.415.332.657.835.657 1.367v7.01902c0 .4641-.1844.9092-.5126 1.2374s-.7733.5126-1.2374.5126h-3.5c-.19891 0-.38968-.079-.53033-.2197-.14065-.1406-.21967-.3314-.21967-.5303v-5.25002h-2v5.25002c0 .1989-.07902.3897-.21967.5303-.14065.1407-.33142.2197-.53033.2197h-3.5c-.46413 0-.909248-.1844-1.237437-.5126s-.512563-.7733-.512563-1.2374v-7.02002c0-.531.242-1.034.657-1.366l5.25-4.2z"
      />
    </svg>
  );
});
