import React, { FC, PropsWithChildren, useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { ErrorMessage } from './ErrorMessage';

export const ErrorInterceptor: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    // Workaround for loads of 'ResizeObserver loop limit exceeded' errors.
    // Sources for fix:
    // https://github.com/petyosi/react-virtuoso/issues/254
    // https://github.com/DevExpress/testcafe/issues/4857#issuecomment-598775956
    const errorHandler = (e: ErrorEvent) => {
      if (
        [
          'ResizeObserver loop completed with undelivered notifications.',
          'ResizeObserver loop limit exceeded',
          "NotAllowedError: play() failed because the user didn't interact with the document first",
          'Failed to fetch',
          'Non-Error exception captured',
          'Non-Error promise rejection captured',
        ].some(message => e.message?.startsWith(message))
      ) {
        e.stopImmediatePropagation();
      }
    };
    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  return <ErrorBoundary fallback={<ErrorMessage />}>{children}</ErrorBoundary>;
};
