import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const AttachmentIcon: FC<IconProps> = props => {
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="attachment-icon"
      {...props}>
      <path
        d="M4.25 0C6.52817 0 8.38769 1.79251 8.4951 4.04408L8.5 4.25V10.297C8.5 11.8158 7.26878 13.047 5.75 13.047C4.28747 13.047 3.0916 11.9053 3.00502 10.4646L3 10.297V4.25C3 3.83579 3.33579 3.5 3.75 3.5C4.1297 3.5 4.44349 3.78215 4.49315 4.14823L4.5 4.25V10.297C4.5 10.9874 5.05964 11.547 5.75 11.547C6.39721 11.547 6.92953 11.0552 6.99355 10.4248L7 10.297V4.25C7 2.73122 5.76878 1.5 4.25 1.5C2.78747 1.5 1.5916 2.6417 1.50502 4.08248L1.5 4.25V10.5C1.5 12.7091 3.29086 14.5 5.5 14.5C7.6422 14.5 9.39108 12.816 9.4951 10.6996L9.5 10.5V6.25C9.5 5.83579 9.83579 5.5 10.25 5.5C10.6297 5.5 10.9435 5.78215 10.9932 6.14823L11 6.25V10.5C11 13.5376 8.53757 16 5.5 16C2.53652 16 0.120461 13.6562 0.0043672 10.7212L0 10.5V4.25C0 1.90279 1.90279 0 4.25 0Z"
        fill="currentColor"
        fillOpacity="0.87"
      />
    </svg>
  );
};
