export enum TimelineTranslation {
  headerTitle = 'timeline_headerTitle',

  openTimelineButton = 'timeline_openTimelineButton',
  closeTimelineButton = 'timeline_closeTimelineButton',

  noEventsMessage = 'timeline_noEventsMessage',
  loadMoreEventsButton = 'timeline_loadMoreEventsButton',

  eventTime = 'timeline_eventTime',

  eventsFilterInputPlaceholder = 'timeline_eventsFilterInputPlaceholder',

  eventsFilterOptionsHeading = 'timeline_eventsFilterOptionsHeading',
  eventsFilterOptionsApps = 'timeline_eventsFilterOptionsApps',
  eventsFilterOptionsLinks = 'timeline_eventsFilterOptionsLinks',
  eventsFilterOptionsEvents = 'timeline_eventsFilterOptionsEvents',
  eventsFilterOptionsAll = 'timeline_eventsFilterOptionsAll',
  eventTitleTheApp = 'timeline_eventTitleTheApp',
  eventTitleTheLink = 'timeline_eventTitleTheLink',
  eventTitleTheAppsSection = 'timeline_eventTitleTheAppsSection',
  eventTitleTheLinksSection = 'timeline_eventTitleTheLinksSection',

  /*
   * Day date custom formats
   * */

  dayDateToday = 'timeline_dayDateToday',
  dayDateYesterday = 'timeline_dayDateYesterday',

  /*
   * Timeline event messages
   * */

  eventDesktopCreated = 'timeline_eventDesktopCreated',
  eventDesktopDeleted = 'timeline_eventDesktopDeleted',

  eventAppOpened = 'timeline_eventAppOpened',
  eventAppAddedToDesktop = 'timeline_eventAppAddedToDesktop',
  eventAppRemovedFromDesktop = 'timeline_eventAppRemovedFromDesktop',
  eventCustomAppAddedToDesktop = 'timeline_eventCustomAppAddedToDesktop',
  eventCustomAppRemovedFromDesktop = 'timeline_eventCustomAppRemovedFromDesktop',

  eventLinkOpened = 'timeline_eventLinkOpened',
  eventLinkAddedToDesktop = 'timeline_eventLinkAddedToDesktop',
  eventLinkUpdated = 'timeline_eventLinkUpdated',
  eventLinkMovedToFolder = 'timeline_eventLinkMovedToFolder',
  eventLinkMovedFromFolder = 'timeline_eventLinkMovedFromFolder',
  eventLinkRemovedFromDesktop = 'timeline_eventLinkRemovedFromDesktop',
  eventLinkComment = 'timeline_eventLinkComment',

  eventFolderAddedToDesktop = 'timeline_eventFolderAddedToDesktop',
  eventFolderRemovedFromDesktop = 'timeline_eventFolderRemovedFromDesktop',

  eventTagAddedToLink = 'timeline_eventTagAddedToLink',

  eventTeamAddedToDesktop = 'timeline_eventTeamAddedToDesktop',
  eventTeamRemovedFromDesktop = 'timeline_eventTeamRemovedFromDesktop',

  eventMemberAddedToDesktop = 'timeline_eventMemberAddedToDesktop',
  eventMemberRemovedFromDesktop = 'timeline_eventMemberRemovedFromDesktop',

  eventMemberLeftTheDesktop = 'timeline_eventMemberLeftTheDesktop',

  eventAddTeam = 'timeline_eventAddTeam',
  eventTeamRemoved = 'timeline_eventTeamRemoved',
  eventAccountAddToTeam = 'timeline_eventAccountAddToTeam',
  eventAccountAddedToTeam = 'timeline_eventAccountAddedToTeam',
  eventAccountToRemoveFromTeam = 'timeline_eventAccountToRemoveFromTeam',
  eventAccountRemovedFromTeam = 'timeline_eventAccountRemovedFromTeam',

  eventUserAddedDesktop = 'timeline_eventUserAddedDesktop',
  eventUserRemovedFromDesktop = 'timeline_eventUserRemovedFromDesktop',

  eventPaymentFailed = 'timeline_eventPaymentFailed',

  eventGuestLimitExceeded = 'timeline_eventGuestLimitExceeded',

  eventAccountToActivate = 'timeline_eventAccountToActivate',
  eventAccountActivated = 'timeline_eventAccountActivated',
  eventAccountToSuspend = 'timeline_eventAccountToSuspend',
  eventAccountSuspended = 'timeline_eventAccountSuspended',
  eventAccountToRemove = 'timeline_eventAccountToRemove',
  eventAccountRemoved = 'timeline_eventAccountRemoved',
  eventAccountToAssignAdmin = 'timeline_eventAccountToAssignAdmin',
  eventAccountAssignedAdmin = 'timeline_eventAccountAssignedAdmin',
  eventAccountLostOwner = 'timeline_eventAccountLostOwner',
  eventAccountGainedOwner = 'timeline_eventAccountGainedOwner',

  eventCreatedBy = 'timeline_eventCreatedBy',
  eventCreatedByMeLabel = 'timeline_eventCreatedByMeLabel',
  eventThisAccountLabel = 'timeline_eventThisAccountLabel',
  eventToLabel = 'timeline_eventToLabel',
  eventFromLabel = 'timeline_eventFromLabel',
  eventInLabel = 'timeline_eventInLabel',

  eventAppSectionRemoved = 'timeline_eventAppSectionRemoved',
  eventLinkSectionRemoved = 'timeline_eventLinkSectionRemoved',

  eventLinkDetailsAdd = 'timeline_eventLinkDetailsAdd',
  eventLinkDetailsUpdate = 'timeline_eventLinkDetailsUpdate',
  eventLinkDetailsComment = 'timeline_eventLinkDetailsComment',
  eventLinkDetailsOpen = 'timeline_eventLinkDetailsOpen',

  eventUndoMenuItem = 'timeline_eventUndoMenuItem',
  eventUnDoItemConfirmationTitle = 'timeline_eventUnDoItemConfirmationTitle',
  eventUnDoItemConfirmationDescription = 'timeline_eventUnDoItemConfirmationDescription',
  eventRestoreItemMessage = 'timeline_eventRestoreItemMessage',

  eventAppSectionRestoreTitle = 'timeline_eventAppSectionRestoreTitle',
  eventLinkSectionRestoreTitle = 'timeline_eventLinkSectionRestoreTitle',
}

export type TimelineLocale = {
  [key in TimelineTranslation]: string;
};
