import React, { FC, PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface ListItemProps {
  title?: string;
  subtitle?: string;
  subtitleSecondLine?: string;
  image?: ReactNode;
  spaced?: boolean;
}

const StyledListItemContent = styled.div<{ spaced: boolean }>`
  display: flex;
  ${p =>
    p.spaced &&
    css`
      padding: ${p.theme.spacing.m};
    `};
`;

const StyledItemData = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.5rem;

  * + & {
    padding-left: 13px;
  }
`;

const StyledItemTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 1rem;
  line-height: 20px;
  word-break: break-all;
  word-break: break-word;
`;

const StyledItemSubtitle = styled.p`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 14px;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  word-break: break-all;
  word-break: break-word;
  white-space: pre-line;
`;

const StyledItemControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ListItemContent: FC<PropsWithChildren<ListItemProps>> = ({
  title,
  subtitle,
  subtitleSecondLine,
  children,
  image,
  spaced = true,
}) => {
  return (
    <StyledListItemContent data-testid="list-item" spaced={spaced}>
      {image}
      <StyledItemData data-testid="data">
        <StyledItemTitle data-testid="title">{title}</StyledItemTitle>
        {(subtitle || subtitleSecondLine) && (
          <StyledItemSubtitle data-testid="subtitle">
            {subtitle && <p>{subtitle}</p>}
            {subtitleSecondLine && <p>{subtitleSecondLine}</p>}
          </StyledItemSubtitle>
        )}
      </StyledItemData>
      <StyledItemControls data-testid="controls">{children}</StyledItemControls>
    </StyledListItemContent>
  );
};
