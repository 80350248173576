import { AccountApiType } from '../../../User/User.types';
import { useCallback } from 'react';
import { database } from '../../../Database';
import { AccountApiTypeToAccountTableType } from './utils/AccountIDDB.utils';

export const useAccountsActions = () => {
  const updateAccount = useCallback(
    async (
      accountId: string,
      accountUpdater: (_: AccountApiType) => AccountApiType,
    ) => {
      try {
        const cachedAccounts = await database.accounts
          .where({
            id: accountId,
          })
          .toArray();

        if (cachedAccounts?.length) {
          const newCachedAccounts = cachedAccounts.map(accountRow => {
            return AccountApiTypeToAccountTableType(
              accountUpdater(accountRow.sourceData),
              accountRow.workspaceId,
              accountRow.relatedTeams,
              accountRow.relatedDesktops,
            );
          });
          database.accounts.bulkPut(newCachedAccounts);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  return {
    updateAccount,
  };
};
