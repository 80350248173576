import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DocumentGoogleDocumentIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      fill="none"
      height="46"
      viewBox="0 0 46 46"
      width="46"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="document-google-document-icon"
      {...props}>
      <g fill="#026cc8">
        <path
          clipRule="evenodd"
          d="m11.02 6.708a.48.48 0 0 0 -.479.48v31.624c0 .265.215.48.48.48h23.958a.48.48 0 0 0 .48-.48v-21.562h-7.189a3.354 3.354 0 0 1 -3.355-3.354v-7.188h-13.894zm16.772 1.074v6.114c0 .264.214.479.479.479h6.114zm-20.127-.595a3.355 3.355 0 0 1 3.355-3.354h15.498c.89 0 1.742.353 2.37.984l8.46 8.46c.632.628.984 1.481.984 2.37v23.166a3.357 3.357 0 0 1 -2.07 3.098 3.355 3.355 0 0 1 -1.284.256h-23.957a3.354 3.354 0 0 1 -3.355-3.355v-31.624z"
          fillRule="evenodd"
        />
        <path d="m31.146 32.493c0-.628-.51-1.138-1.138-1.138h-13.658l-.132.008a1.138 1.138 0 0 0 .132 2.269h13.657l.132-.008a1.138 1.138 0 0 0 1.006-1.13zm0-6.828c0-.628-.51-1.138-1.138-1.138h-13.658l-.132.008a1.138 1.138 0 0 0 .132 2.268h13.657l.132-.007a1.138 1.138 0 0 0 1.006-1.13zm-10.242-6.828c0-.628-.51-1.138-1.139-1.138h-3.414l-.132.008a1.138 1.138 0 0 0 .132 2.268h3.415l.132-.007a1.138 1.138 0 0 0 1.006-1.13z" />
      </g>
    </svg>
  );
});
