import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import _debounce from 'lodash/debounce';
import { ChildrenWrapper, PopoverBackdrop } from './Popover.styled';
import { getDefaultAnchor } from './Popover.utils';
import { PopoverOptions } from './Popover.types';

export interface PopoverProps
  extends Partial<PropsWithChildren<PopoverOptions>> {
  onRequestClose: () => void;
  triggerRef?: RefObject<HTMLElement>;
  triggerRect?: DOMRect;
  anchorGetter?: typeof getDefaultAnchor;
  viaPortal?: boolean;
}

export const Popover: FC<PopoverProps> = ({
  onRequestClose,
  triggerRef,
  triggerRect,
  children,
  horizontalMargin,
  verticalMargin,
  pageBottomMargin,
  anchorGetter,
  viaPortal = true,
}) => {
  const [triggerRectVersion, setTriggerRectVersion] = useState(1);

  triggerRect = useMemo(
    () =>
      triggerRectVersion
        ? triggerRef
          ? triggerRef.current?.getBoundingClientRect()
          : triggerRect
        : undefined,
    [triggerRect, triggerRef, triggerRectVersion],
  );

  useEffect(() => {
    const handleWindowResize = _debounce(
      () => setTriggerRectVersion(prevState => prevState + 1),
      50,
    );
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const childrenWrapperRef = useRef<HTMLDivElement>(null);
  const [childrenRect, setChildrenRect] = useState<DOMRect>();

  useEffect(() => {
    if (!childrenWrapperRef.current) {
      return;
    }

    setChildrenRect(childrenWrapperRef.current.getBoundingClientRect());

    const ro = new ResizeObserver(entries => {
      if (entries.length) {
        setChildrenRect(entries[0].target.getBoundingClientRect());
      }
    });

    ro.observe(childrenWrapperRef.current);

    return () => {
      ro.disconnect();
      setChildrenRect(undefined);
    };
  }, []);

  const anchorOptions = useMemo(
    () => ({
      ...(typeof horizontalMargin !== 'undefined'
        ? { horizontalMargin }
        : null),
      ...(typeof verticalMargin !== 'undefined' ? { verticalMargin } : null),
      ...(typeof pageBottomMargin !== 'undefined'
        ? { pageBottomMargin }
        : null),
    }),
    [horizontalMargin, pageBottomMargin, verticalMargin],
  );

  const anchor = useMemo(
    () =>
      triggerRect &&
      childrenRect &&
      (anchorGetter
        ? anchorGetter(triggerRect, childrenRect, anchorOptions)
        : getDefaultAnchor(triggerRect, childrenRect, anchorOptions)),
    [anchorGetter, anchorOptions, childrenRect, triggerRect],
  );

  const popopverRenderer = (
    <PopoverBackdrop
      onClick={e => {
        const shouldClose =
          e.target instanceof Element &&
          !childrenWrapperRef.current?.contains(e.target);
        if (shouldClose) {
          onRequestClose();
        }
      }}>
      <ChildrenWrapper
        data-testid="popover-wrapper"
        ref={childrenWrapperRef}
        anchor={anchor}>
        {children}
      </ChildrenWrapper>
    </PopoverBackdrop>
  );

  if (!viaPortal) {
    return popopverRenderer;
  }

  return ReactDOM.createPortal(
    popopverRenderer,
    document.getElementById('modal') as HTMLDivElement,
  );
};
