import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const WarningIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13 17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5C11 16.9477 11.4477 16.5 12 16.5C12.5523 16.5 13 16.9477 13 17.5Z"
        fill="currentColor"
      />
      <path
        d="M12.75 9.25C12.75 8.83579 12.4142 8.5 12 8.5C11.5858 8.5 11.25 8.83579 11.25 9.25V13.75C11.25 14.1642 11.5858 14.5 12 14.5C12.4142 14.5 12.75 14.1642 12.75 13.75V9.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83575 3.24404C10.7984 1.57946 13.2014 1.57946 14.1641 3.24404L23.1305 18.7484C24.0943 20.4151 22.8916 22.5 20.9663 22.5H3.03352C1.10822 22.5 -0.094495 20.4151 0.86936 18.7484L9.83575 3.24404ZM12.8656 3.99498C12.4805 3.32915 11.5193 3.32915 11.1343 3.99498L2.16786 19.4994C1.78232 20.166 2.2634 21 3.03352 21H20.9663C21.7364 21 22.2175 20.166 21.832 19.4994L12.8656 3.99498Z"
        fill="currentColor"
      />
    </svg>
  );
});
