import styled, { css } from 'styled-components';
import React, { FC, PropsWithChildren } from 'react';
import { MenuItem } from './MenuItem';
import { ArrowLeftIcon } from '../../icons';
import { MenuSeparator } from './MenuSeparator';

const StyledMenuLevel = styled.div<{ visible: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${p => p.theme.colors.Surface};
  display: none;
  animation: slide 0.15s ease-in forwards;

  ${({ visible }) =>
    visible &&
    css`{
    display: block;
    transform: translateX(0);
  }

  @keyframes slide {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }`}}
`;

export const MenuLevel: FC<
  PropsWithChildren<{
    visible: boolean;
    onRequestClose: () => void;
  }>
> = ({ visible, onRequestClose, children }) => {
  return (
    <StyledMenuLevel visible={visible} data-testid="menu-level">
      <MenuItem
        onClick={onRequestClose}
        icon={ArrowLeftIcon}
        closeOnClick={false}
        data-testid="back">
        Back
      </MenuItem>
      <MenuSeparator />
      {children}
    </StyledMenuLevel>
  );
};
