import React, {
  DetailedHTMLProps,
  FC,
  SelectHTMLAttributes,
  useMemo,
  useRef,
} from 'react';
import { StyledSelect } from './Select.styled';
import { KeyboardArrowDownIcon } from '../../icons';
import { useNativeWrapper } from '../../../domains/NativeWrapper/NativeWrapper.hooks';
import { useMenuPopoverControls } from '../Menu/MenuPopover';
import { MenuItem } from '../Menu';

export const Select: FC<
  DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
> = props => {
  const { isNativeWrapperAvailable } = useNativeWrapper();
  const { showMenuPopover } = useMenuPopoverControls();
  const buttonRef = useRef<HTMLElement | null>(null);
  const optionsMap = useMemo(() => {
    if (!isNativeWrapperAvailable) {
      return {};
    }

    return ((Array.isArray(props.children) ? props.children : []) as Array<{
      props: { value: string; children: string };
    }>)
      .reduce<
        Array<{
          props: { value: string; children: string };
        }>
      >(
        (acc, item) => [
          ...acc,
          ...(Array.isArray(item)
            ? item
            : item && 'props' in item
            ? [item]
            : []),
        ],
        [],
      )
      .reduce<Record<string, string>>(
        (acc, item) => ({
          ...acc,
          [item.props.value]: item.props.children,
        }),
        {},
      );
  }, [isNativeWrapperAvailable, props.children]);
  return (
    <StyledSelect>
      {isNativeWrapperAvailable ? (
        <button
          type="button"
          onClick={e => {
            if (!(e.currentTarget instanceof HTMLElement)) {
              return;
            }

            buttonRef.current = e.currentTarget;

            showMenuPopover(
              <>
                {Object.keys(optionsMap).map(key => (
                  <MenuItem
                    key={key}
                    closeOnClick
                    onClick={() => {
                      props.onChange?.({
                        target: {
                          id: props.id,
                          name: props.name,
                          value: key,
                        },
                      } as any);
                    }}>
                    {optionsMap[key]}
                  </MenuItem>
                ))}
              </>,
              buttonRef,
            );
          }}>
          {optionsMap[props.value as string]}
        </button>
      ) : (
        <select {...props} />
      )}
      <KeyboardArrowDownIcon />
    </StyledSelect>
  );
};
