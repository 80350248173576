import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { GeneralSettingsTranslation } from '../../GeneralSettings/i18n';
import { Button } from '../../../shared/components/Button/Button';
import { ModalProps } from '../../../shared/components/Modal';
import { ArrowLeftIcon } from '../../../shared/icons';

const StyledDrawerHeader = styled.div`
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 24px;
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};
  background-color: ${p => p.theme.colors.Surface};
  position: relative;

  button {
    color: ${({ theme }) => theme.colors.Primary};
  }
`;

const DrawerHeaderData = styled.div`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  flex-basis: 100%;
  font-family: ${p => p.theme.fonts.Semibold};
`;

const DrawerBackButton = styled(Button)`
  position: absolute;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  left: 0;

  svg {
    transform: translateX(0.5rem);
    width: 2rem;
    height: 2rem;
  }
`;

const DrawerCloseButton = styled(Button)`
  position: absolute;
  right: 20px;
`;

interface DrawerHeaderProps extends Pick<ModalProps, 'onRequestClose'> {
  onBackButtonClick?: () => void;
  backButtonText?: string;
}

export const DrawerHeader: FC<PropsWithChildren<DrawerHeaderProps>> = ({
  children,
  onRequestClose,
  onBackButtonClick,
  backButtonText,
}) => {
  return (
    <StyledDrawerHeader data-testid="drawer-header">
      {onBackButtonClick && (
        <DrawerBackButton onClick={onBackButtonClick}>
          <ArrowLeftIcon />
          {backButtonText ? (
            backButtonText
          ) : (
            <FormattedMessage
              id={GeneralSettingsTranslation.settingsDrawerBackButton}
            />
          )}
        </DrawerBackButton>
      )}
      <DrawerHeaderData>{children}</DrawerHeaderData>
      <DrawerCloseButton
        onClick={onRequestClose}
        data-testid="drawer-close-button">
        <FormattedMessage
          id={GeneralSettingsTranslation.settingsDrawerCloseButton}
        />
      </DrawerCloseButton>
    </StyledDrawerHeader>
  );
};
