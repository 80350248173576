import styled from 'styled-components';
import { Breakpoints } from '../../Theme';

export const StyledAppShellSkeleton = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAppContentSkeleton = styled.div`
  display: grid;
  width: 100vw;
  height: calc(100vh - 2.5rem);

  @media (min-width: ${Breakpoints.md}px) {
    grid-template-areas: 'workspaces desktops desktop';
    grid-template-columns: 56px 256px auto;
  }
`;

export const StyledFallbackSkeleton = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.Background};
  z-index: 999;
  display: flex;
`;
