import { gql } from '@apollo/client';

export const BULK_MOVE_LINKS = gql`
  mutation BulkMoveLink($input: bulkMoveLinkInput!) {
    bulkMoveLink(input: $input) {
      clientMutationId
    }
  }
`;

export interface BulkMoveLinkInputVariables {
  folder: string | null;
  desktop: string | null;
  links: string[];
  copy?: boolean;
}

export interface BulkMoveLinkVariables {
  input: BulkMoveLinkInputVariables;
}

export interface MoveLinksToFolderResponse {}
