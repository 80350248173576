import styled from 'styled-components';
import { StyledMenu as BaseMenu } from '../Menu.styled';

export const StyledMenu = styled(BaseMenu).attrs({
  opened: true,
  maxHeight: null,
  isWrapped: true,
  x: 0,
  y: 0,
  firstLevelHidden: false,
})`
  position: relative;
  max-height: 18rem;
`;
