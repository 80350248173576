import { appEnv } from '../../../appEnv';
import * as Sentry from '@sentry/browser';
import { captureException } from '@sentry/browser';
import type { AuthSessionHandler } from '../../Auth/Auth.types';
import { getWorkspaceIdFromCurrentUrl } from '../../Workspace/Workspace.utils';
import type { RestApiClientProps } from './RestApiClient.types';
import { RequestMethod, RestApiClientResponse } from './RestApiClient.types';
import { generateRandomId } from '../../../shared/utils/id';
import { delay } from '../../../shared/utils/retry.utils';
import { waitForOnlineStatus } from '../../Offline/Offline.utils';

const FAILED_FECTH_RETRY_DELAY = 2000;

export class RestApiClient {
  private authService: AuthSessionHandler;

  constructor({ authService }: RestApiClientProps) {
    this.authService = authService;
  }

  private async getHeaders(headers?: HeadersInit) {
    const transactionId = generateRandomId();
    Sentry.configureScope(scope => {
      scope.setTag('transaction_id', transactionId);
    });

    try {
      const token = await this.authService.getToken();

      return {
        ...headers,
        ...(token ? { Authorization: `Bearer ${token}` } : null),
        'X-ClientVersion': appEnv._VERSION || '',
        'X-Workspace-ID': getWorkspaceIdFromCurrentUrl() || '',
        'X-Request-ID': transactionId,
      };
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  async fetch<T>(
    apiUrl: string,
    requestMethod: RequestMethod = RequestMethod.GET,
    variables?: T,
    headers?: HeadersInit,
    avoidRetry?: boolean,
  ): Promise<RestApiClientResponse<any>> {
    const fetchHeaders = await this.getHeaders(headers);

    if (!navigator.onLine) {
      await waitForOnlineStatus();
    }

    return fetch(`${appEnv.API_REST_URL}${apiUrl}`, {
      method: requestMethod,
      headers: {
        ...fetchHeaders,
      },
      ...(requestMethod !== RequestMethod.GET && {
        body: JSON.stringify({
          ...variables,
        }),
      }),
    })
      .then(async response => {
        const responseJson = await response.json();
        return {
          data: responseJson,
          status: response.status,
          ok: response.ok,
        };
      })
      .catch(async error => {
        captureException(error);

        if (
          error?.message?.indexOf('Failed to fetch') !== -1 &&
          (!avoidRetry || !navigator.onLine)
        ) {
          await delay(FAILED_FECTH_RETRY_DELAY);
          await waitForOnlineStatus();
          return this.fetch(apiUrl, requestMethod, variables, headers, true);
        }

        throw new Error(error);
      });
  }
}
