export enum WebPushTranslation {
  permissionModalTitle = 'webPushTranslation_permissionModalTitle',
  permissionModalDescription = 'webPushTranslation_permissionModalDescription',
  permissionModalSubmitButton = 'webPushTranslation_permissionModalSubmitButton',
  permissionModalCancelButton = 'webPushTranslation_permissionModalCancelButton',
  permissionModalImageAlt = 'webPushTranslation_permissionModalImageAlt',
}

export type WebPushLocale = {
  [key in WebPushTranslation]: string;
};
