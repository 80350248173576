import { gql } from '@apollo/client';

export const PING_MERCURE = gql`
  mutation PingMercure($data: String!) {
    pingMercure(input: { data: $data }) {
      clientMutationId
    }
  }
`;

export interface PingMercureVariables {
  data: string;
}
