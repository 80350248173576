import { useCallback, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import {
  generateSegmentLocalstorageKey,
  SegmentType,
  verifySegmentPathValue,
} from '.';
import {
  CHATS_CONVERSATION_PATHNAME,
  CHATS_FIND_ACCOUNT_CHAT_PATHNAME,
  CHATS_ROOT_PATHNAME,
  CONFERENCES_CONFERENCE_PATHNAME,
  CONFERENCES_ROOT_PATHNAME,
  DESKTOP_CHAT_ID_PATHNAME,
  DESKTOP_ID_PATHNAME,
  DESKTOP_ROOT_PATHNAME,
  DESKTOP_TAB_PATHNAME,
  FAVORITES_ROOT_PATHNAME,
  FAVORITES_TAB_PATHNAME,
  PASSWORDS_ROOT_PATHNAME,
  PASSWORDS_VAULT_PATHNAME,
} from '../Desktop/Desktop.constants';
import { useCurrentWorkspace } from '../Workspace/Workspace.hooks';
import { useCurrentAccount } from '../Auth/Auth.hooks';
import { getShortId } from '../../shared/utils/id';

export const useIsDesktopsSegment = () => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      !!(
        matchPath(DESKTOP_ROOT_PATHNAME, pathname) ||
        matchPath(DESKTOP_ID_PATHNAME, pathname) ||
        matchPath(DESKTOP_CHAT_ID_PATHNAME, pathname) ||
        matchPath(DESKTOP_TAB_PATHNAME, pathname) ||
        matchPath(FAVORITES_ROOT_PATHNAME, pathname) ||
        matchPath(FAVORITES_TAB_PATHNAME, pathname)
      ),
    [pathname],
  );
};

export const useIsChatsSegment = () => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      !!(
        matchPath(CHATS_ROOT_PATHNAME, pathname) ||
        matchPath(CHATS_FIND_ACCOUNT_CHAT_PATHNAME, pathname) ||
        matchPath(CHATS_CONVERSATION_PATHNAME, pathname)
      ),
    [pathname],
  );
};

export const useIsMeetingsSegment = () => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      !!(
        matchPath(CONFERENCES_ROOT_PATHNAME, pathname) ||
        matchPath(CONFERENCES_CONFERENCE_PATHNAME, pathname)
      ),
    [pathname],
  );
};

export const useIsPasswordsSegment = () => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      !!(
        matchPath(PASSWORDS_ROOT_PATHNAME, pathname) ||
        matchPath(PASSWORDS_VAULT_PATHNAME, pathname)
      ),
    [pathname],
  );
};

/**
 * Retrieve the current active segment based on path name
 *
 * @returns SegmentType
 */
export const useGetCurrentSegment = (): SegmentType => {
  const isDesktopsSegment = useIsDesktopsSegment();

  const isChatsSegment = useIsChatsSegment();

  const isMeetingsSegment = useIsMeetingsSegment();

  const isPasswordsSegment = useIsPasswordsSegment();

  if (isDesktopsSegment) return SegmentType.DESKTOPS;
  if (isChatsSegment) return SegmentType.CHATS;
  if (isMeetingsSegment) return SegmentType.MEETINGS;
  if (isMeetingsSegment) return SegmentType.MEETINGS;
  if (isPasswordsSegment) return SegmentType.PASSWORDS;
  return SegmentType.UNKNOWN;
};

/**
 * Retreive the last segment visited by an account ID for a workspace ID
 *
 * @returns SegmentType
 */
export const useGetLastUsedSegment = (): SegmentType => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentAccount();
  const key = useMemo(
    () =>
      account?.id && workspace
        ? generateSegmentLocalstorageKey(
            getShortId(workspace.id),
            getShortId(account.id),
          )
        : null,
    [account, workspace],
  );

  if (!key) return SegmentType.UNKNOWN;
  const data = localStorage.getItem(key);
  return (data as SegmentType) || SegmentType.UNKNOWN;
};

/**
 * Set the last segment visited by an account ID for a workspace ID
 */
export const useSetLastUsedSegment = () => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentAccount();
  const key = useMemo(
    () =>
      account?.id && workspace
        ? generateSegmentLocalstorageKey(
            getShortId(workspace.id),
            getShortId(account.id),
          )
        : null,
    [account, workspace],
  );

  return useCallback(
    (segment: SegmentType) => {
      if (!key) return;
      localStorage.setItem(key, segment);
    },
    [key],
  );
};

/**
 * Retreive the segment path an account ID for a workspace ID
 *
 * @returns string (path)
 */
export const useGetLastUsedSegmentPath = () => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentAccount();

  return useCallback(
    (segment: SegmentType) => {
      const key =
        account?.id && workspace
          ? generateSegmentLocalstorageKey(
              getShortId(workspace.id),
              getShortId(account.id),
              segment,
            )
          : null;

      if (!key) return null;
      const data = localStorage.getItem(key);
      return verifySegmentPathValue(key, data) ? data : null;
    },
    [account, workspace],
  );
};

/**
 * Set the last segment path visited for an account ID for a workspace ID
 * Should be set when clicking any item in SegmentNavigator
 */
export const useSetLastUsedSegmentPath = (segment: SegmentType) => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentAccount();
  const currentSegment = useGetCurrentSegment();
  const key = useMemo(
    () =>
      account?.id && workspace
        ? generateSegmentLocalstorageKey(
            getShortId(workspace.id),
            getShortId(account.id),
            segment,
          )
        : null,
    [account, segment, workspace],
  );

  return useCallback(
    (path: string | null) => {
      if (!key) return;
      if (path && segment === currentSegment) {
        localStorage.setItem(key, path);
      } else {
        localStorage.removeItem(key);
      }
    },
    [currentSegment, key, segment],
  );
};
