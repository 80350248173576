import styled from 'styled-components';

export const StyledListItemMenuWrapper = styled.div`
  button {
    font-size: 0.875rem;
    white-space: nowrap;
  }
`;

export const StyledMenuItemContent = styled.div<{ isRemoveItem?: boolean }>`
  display: flex;
  flex-direction: column;

  div {
    font-size: 1rem;
    color: ${p =>
      p.isRemoveItem
        ? p.theme.colors.CriticalDark
        : p.theme.colors.OnSurfaceHighEmphasis};
    line-height: 1.5rem;
  }

  span {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
`;
