import { gql } from '@apollo/client';
import { TimelineEventApiType } from './Timeline.types';
import { ApiConnection } from '../../shared/api/api.types';

export const SEARCH_IN_TIMELINES = gql`
  query SearchInTimelines(
    $workspace: ID!
    $pageSize: Int
    $page: Int
    $query: String
    $minDate: String
    $maxDate: String
    $scope: String
    $scopeId: String
    $desktop: ID
  ) {
    searchInTimelineEvents(
      pageSize: $pageSize
      page: $page
      workspace: $workspace
      query: $query
      minDate: $minDate
      maxDate: $maxDate
      scope: $scope
      scopeId: $scopeId
      desktop: $desktop
      orderType: "desc"
    ) {
      edges {
        node {
          id
          action
          image {
            id
            contentUrl
            __typename
          }
          scope
          scopeType
          scopeId
          workspaceIri
          context
          occurredAt
          canUndo
          __typename
        }
      }
    }
  }
`;

export interface SearchInTimelinesVariables {
  workspace: string;
  pageSize?: number;
  page?: number;
  query?: string;
  minDate?: string;
  maxDate?: string;
  scope?: string;
  scopeId?: string;
  desktop?: string;
}

export interface SearchInTimelinesResponse {
  searchInTimelineEvents: ApiConnection<TimelineEventApiType>;
}
