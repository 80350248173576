import type { AccessApiType } from '../../Access/data/types/Access.types';
import type { AccessTableType } from './AccessTable';
import { getLongId } from '../../../shared/utils/id';

export const AccessApiTypeToAccessTableType = (
  access: AccessApiType,
  workspaceId: string,
): AccessTableType => {
  return {
    id: access.id,
    desktopId: access.desktopId
      ? getLongId('desktops', access.desktopId)
      : null,
    teamId: access.workspaceTeam?.id
      ? getLongId('workspace-teams', access.workspaceTeam?.id)
      : null,
    workspaceId,
    sourceData: access,
  };
};
