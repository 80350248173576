import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const BurgerMenuIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 6C3.33579 6 3 6.33579 3 6.75C3 7.16421 3.33579 7.5 3.75 7.5H20.25C20.6642 7.5 21 7.16421 21 6.75C21 6.33579 20.6642 6 20.25 6H3.75ZM3.25 11.75C3.25 11.3358 3.58579 11 4 11H20.25C20.6642 11 21 11.3358 21 11.75C21 12.1642 20.6642 12.5 20.25 12.5H4C3.58579 12.5 3.25 12.1642 3.25 11.75ZM3.25 16.6875C3.25 16.2733 3.5858 15.9375 4 15.9375H20.25C20.6642 15.9375 21 16.2733 21 16.6875C21 17.1017 20.6642 17.4375 20.25 17.4375H4C3.5858 17.4375 3.25 17.1017 3.25 16.6875Z"
        fill="currentColor"
      />
    </svg>
  );
});
