import { DatabaseTablesType } from '../../../../Database/Database.types';
import { WorkspaceInfoApiType } from './WorkspaceInfo.types';

export const WORKSPACE_INFO_TABLE_COLUMNS = '[id+accountId], sourceData';

export interface WorkspaceInfoTableType
  extends Omit<DatabaseTablesType, 'createdAt'> {
  id: string; // workspace id
  accountId: string;
  sourceData: WorkspaceInfoApiType;
}
