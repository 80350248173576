import type { TeamApiType } from '../../Team/data/Team/types/Team.types';
import type { DatabaseTablesType } from '../Database.types';

export const TEAMS_COLUMNS =
  '++id, workspaceId, sourceData, *relatedAccounts, *relatedDesktops';

export interface TeamsTableType extends Omit<DatabaseTablesType, 'createdAt'> {
  workspaceId: string;
  sourceData: TeamApiType;
  relatedAccounts: string[];
  relatedDesktops: string[];
}
