import { database } from '../../../../../../Database';
import { DesktopAppsTableType } from '../../../../../../Database/DesktopAppsTable/DesktopAppsTable';

export const favoriteAppRequestRemove = (appNode: DesktopAppsTableType) => {
  database.desktopApps
    .update(appNode.id, {
      accountFavorite: appNode.accountFavorite,
      'sourceData.accountFavorite': appNode.accountFavorite,
    })
    //Add element to database if update return 0 https://dexie.org/docs/Table/Table.update()
    .then(updated => {
      if (!updated) {
        database.desktopApps.put(appNode).catch(error => {
          console.error(error);
        });
      }
    });
};
