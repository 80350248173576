import {
  DropBoxErrorPageLocale,
  DropBoxErrorPageTranslation,
} from './i18n.types';

export const enDropBoxErrorPageLocale: DropBoxErrorPageLocale = {
  [DropBoxErrorPageTranslation.errorPageDescription]:
    'Something went wrong while loading Dropbox link. Please try again later.',
  [DropBoxErrorPageTranslation.errorPageClose]: 'Close page',
};
