export enum DomainTranslation {
  adminListHeaderTitle = 'domain_adminListHeaderTitle',
  adminListNewDomainButton = 'domain_adminListNewDomainButton',
  adminListCreateDomainButton = 'domain_adminListCreateDomainButton',
  adminListDeleteDomainConfirmation = 'domain_adminListDeleteDomainConfirmation',
  adminListEnableDomainConfirmation = 'domain_adminListEnableDomainConfirmation',
  adminListDisableDomainConfirmation = 'domain_adminListDisableDomainConfirmation',
  adminListEmptyMessage = 'domain_adminListEmptyMessage',
  adminListEditModalHeader = 'domain_adminListEditModalHeader',

  adminListFormNamePlaceholder = 'domain_adminListFormNamePlaceholder',
  adminListFormNameLabel = 'domain_adminListFormNameLabel',
  adminListFormCheckboxLabel = 'domain_adminListFormCheckboxLabel',
  adminListCancelButton = 'domain_adminListCancelButton',
  adminListCreateSubmitButton = 'domain_adminListCreateSubmitButton',
  adminListEditSubmitButton = 'domain_adminListEditSubmitButton',
  adminListFormDomainRequired = 'domain_adminListFormDomainRequired',

  adminListDomainSectionHeader = 'domain_adminListDomainSectionHeader',
  adminListDomainSectionDescription = 'domain_adminListDomainSectionDescription',
  adminListDomainSectionTitle = 'domain_adminListDomainSectionTitle',
  adminListEnableButtonLabel = 'domain_adminListEnableButtonLabel',
  adminListDisableButtonLabel = 'domain_adminListDisableButtonLabel',

  adminListCreateDomainToastMessage = 'domain_adminListCreateDomainToastMessage',
  adminListDeleteDomainToastMessage = 'domain_adminListDeleteDomainToastMessage',
  adminListEditDomainToastMessage = 'domain_adminListEditDomainToastMessage',
  adminListEnableDomainToastMessage = 'domain_adminListEnableDomainToastMessage',
  adminListDisableDomainToastMessage = 'domain_adminListDisableDomainToastMessage',
}

export type DomainLocale = {
  [key in DomainTranslation]: string;
};
