import React from 'react';
import { useTheme } from 'styled-components';
import { SkeletonBox } from '../../../../../../shared/components/SkeletonBox';
import {
  StyledMessage,
  StyledMessageListSkeleton,
} from './MessageListSkeleton.styled';

function randomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const Message = () => {
  const { colors } = useTheme();

  return (
    <StyledMessage>
      <div className="message-wrapper">
        <SkeletonBox
          width={2.25}
          height={2.25}
          borderRadius={50}
          color={colors.ChatViewSkeleton}
        />

        <div>
          <SkeletonBox
            width={8}
            height={0.75}
            color={colors.ChatViewSkeleton}
            margin={[0, 0, 0, 1]}
          />
          <SkeletonBox
            width={5}
            height={0.5}
            color={colors.ChatViewSkeleton}
            margin={[0, 0, 0, 0.5]}
          />
          <br />
          <SkeletonBox
            width={randomNumber(2, 10)}
            height={0.75}
            color={colors.ChatViewSkeleton}
            margin={[0, 0, 0, 1]}
          />
          <SkeletonBox
            width={randomNumber(2, 10)}
            height={0.75}
            color={colors.ChatViewSkeleton}
            margin={[0, 0, 0, 1]}
          />
          <SkeletonBox
            width={randomNumber(2, 10)}
            height={0.75}
            color={colors.ChatViewSkeleton}
            margin={[0, 0, 0, 1]}
          />
          <SkeletonBox
            width={randomNumber(2, 10)}
            height={0.75}
            color={colors.ChatViewSkeleton}
            margin={[0, 0, 0, 1]}
          />
          <SkeletonBox
            width={randomNumber(2, 10)}
            height={0.75}
            color={colors.ChatViewSkeleton}
            margin={[0, 0, 0, 1]}
          />
          <SkeletonBox
            width={randomNumber(2, 10)}
            height={0.75}
            color={colors.ChatViewSkeleton}
            margin={[0, 0, 0, 1]}
          />

          <br />

          <SkeletonBox
            width={15}
            height={4}
            color={colors.ChatViewSkeleton}
            borderRadius={0.5}
            margin={[0.75, 0, 0, 1]}
          />
        </div>
      </div>
    </StyledMessage>
  );
};

export const MessageListSkeleton = React.memo(() => {
  return (
    <StyledMessageListSkeleton>
      <Message />
      <Message />
      <Message />
    </StyledMessageListSkeleton>
  );
});
