import { BasicColors, ThemeType } from '../../Theme';
import { ChatThemesType } from './Chat.theme.types';

export const ChatTheme: ChatThemesType = {
  [ThemeType.light]: {
    colors: {
      OwnMessageBubble: BasicColors[ThemeType.light].OnSurface.alpha(0.04)
        .rgb()
        .string(),
      ChatTypingBackground: BasicColors[ThemeType.light].OnSurface.alpha(0.04)
        .rgb()
        .string(),
      ChatTypingDot: '#C4C4C4',
      ChatParticipantBackground: '#FFF',
    },
  },
  [ThemeType.dark]: {
    colors: {
      OwnMessageBubble: BasicColors[ThemeType.dark].OnSurface.alpha(0.08)
        .rgb()
        .string(),
      ChatTypingBackground: BasicColors[ThemeType.dark].OnSurface.alpha(0.12)
        .rgb()
        .string(),
      ChatTypingDot: BasicColors[ThemeType.dark].OnSurface.alpha(0.6)
        .rgb()
        .string(),
      ChatParticipantBackground: '#021121',
    },
  },
};
