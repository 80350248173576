import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const GooglePdfIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <rect x="3" y="3" width="18" height="18" rx="2.45455" fill="#EA4335" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2911 9.1875H10.0703V14.9732H12.2911C13.1948 14.9732 13.9275 14.2406 13.9275 13.3369V10.8239C13.9275 9.92013 13.1948 9.1875 12.2911 9.1875ZM12.481 10.6339H11.5167V13.7678H12.481V10.6339Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.341 11.5982V10.6339L18.7517 10.6339V9.18749L14.8945 9.18749V9.1875H14.8945V14.9732H16.341V13.0446L17.7874 13.0446V11.5982L16.341 11.5982Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 9.1875H6.69643V9.1984C6.75874 9.1912 6.82212 9.1875 6.88636 9.1875H7.47078C8.37452 9.1875 9.10714 9.92012 9.10714 10.8239V11.4083C9.10714 12.312 8.37452 13.0446 7.47078 13.0446H6.88636C6.82212 13.0446 6.75874 13.0409 6.69643 13.0337V14.9732H5.25V11.4083V10.8239V9.1875ZM7.6607 10.6339H6.69642V11.5982H7.6607V10.6339Z"
        fill="white"
      />
    </svg>
  );
});
