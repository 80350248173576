import { AccountApiType } from '../../../../User/User.types';
import { database } from '../../../../Database';
import {
  AccountApiTypeToAccountTableType,
  AccountTableTypeToAccountApiType,
} from '../utils/AccountIDDB.utils';
import { AccountsTableType } from '../types/AccountIDDB.types';

export const updateAccountInIDDB = (
  accountId: string,
  workspaceId: string,
  accountUpdater: (_: AccountApiType) => AccountApiType,
) => {
  return database.accounts
    .where({ id: accountId, workspaceId })
    .modify((value, ref) => {
      const account = AccountTableTypeToAccountApiType(value);
      if (account) {
        ref.value = AccountApiTypeToAccountTableType(
          accountUpdater(account),
          workspaceId,
          value.relatedTeams,
          value.relatedDesktops,
        );
      }
    });
};

export const updateAccountsInIDDB = (
  workspaceId: string,
  accountUpdater: (_: AccountApiType) => AccountApiType,
) => {
  return database.accounts.where({ workspaceId }).modify((value, ref) => {
    const account = AccountTableTypeToAccountApiType(value);
    if (account) {
      ref.value = AccountApiTypeToAccountTableType(
        accountUpdater(account),
        workspaceId,
        value.relatedTeams,
        value.relatedDesktops,
      );
    }
  });
};

export const bulkPutAccountsToIDDB = (accountRows: AccountsTableType[]) => {
  database.accounts.bulkPut(accountRows);
};

export const deleteAccountFromWorkspace = (
  accountId: string,
  workspaceId: string,
) => {
  database.accounts.where({ id: accountId, workspaceId }).delete();
};

export const bulkDeleteAccountsFromIDDB = (
  accountIds: string[],
  workspaceId: string,
) => {
  // bulkDelete doesn't work with compound keys for some reason, so we use this approach
  database.accounts
    .where('id')
    .anyOf(accountIds)
    .filter(account => account.workspaceId === workspaceId)
    .delete();
};
