import { useCallback, useContext, useMemo } from 'react';
import { CounterType, UnreadWorkspaceItemsMap } from './Counters.types';
import { CountersContext } from './Counters.context';
import { normalizeUnreadItemsCount } from './Counters.utils';
import { WorkspaceApiType } from '../Workspace/Workspace.types';
import type {
  UnreadChatMessagesConversationType,
  UnreadChatMessagesType,
  UnreadNotificationsCountApiType,
} from '../User/User.types';

export const useUnreadCounters = () => useContext(CountersContext);

export const useUnreadWorkspaceItemsMapBuilder = (): ((
  workspaceId: string,
) => UnreadWorkspaceItemsMap) => {
  const {
    unreadNotifications,
    unreadChatMessages,
    unreadChatConferences,
    unreadMentions,
    unreadThreads,
  } = useUnreadCounters();

  return useCallback(
    (workspaceId: string) => ({
      [CounterType.Notifications]: unreadNotifications[workspaceId],
      [CounterType.ChatMessages]: unreadChatMessages[workspaceId],
      [CounterType.Mentions]: unreadMentions[workspaceId],
      [CounterType.Threads]: unreadThreads[workspaceId],
      [CounterType.ChatConferences]: unreadChatConferences[workspaceId],
    }),
    [
      unreadChatConferences,
      unreadChatMessages,
      unreadMentions,
      unreadNotifications,
      unreadThreads,
    ],
  );
};

export const useUnreadCounter = (
  workspaceId: string | null | undefined,
  type: CounterType,
) => {
  const buildUnreadWorkspaceItemsMap = useUnreadWorkspaceItemsMapBuilder();

  return useMemo(() => {
    if (!workspaceId) {
      return 0;
    }

    const unreadItemsCountMap = buildUnreadWorkspaceItemsMap(workspaceId);

    if (type === CounterType.ChatMessages) {
      return normalizeUnreadItemsCount(
        (
          unreadItemsCountMap[
            CounterType.ChatMessages
          ] as UnreadChatMessagesType
        )?.count,
      );
    }
    return normalizeUnreadItemsCount(unreadItemsCountMap[type]);
  }, [buildUnreadWorkspaceItemsMap, type, workspaceId]);
};

export const useUnreadCounterGetter = () => {
  const buildUnreadWorkspaceItemsMap = useUnreadWorkspaceItemsMapBuilder();

  return useCallback(
    (workspaceId: string) => {
      const unreadItemsCountMap = buildUnreadWorkspaceItemsMap(workspaceId);
      return Object.values(unreadItemsCountMap).reduce<number>((acc, item) => {
        if ((item as UnreadNotificationsCountApiType)?.count) {
          return (
            acc +
            normalizeUnreadItemsCount(
              (item as UnreadNotificationsCountApiType).count,
            )
          );
        }
        return acc + normalizeUnreadItemsCount(item);
      }, 0);
    },
    [buildUnreadWorkspaceItemsMap],
  );
};

export const useAllWorkspacesUnreadCounterGetter = () => {
  const getUnreadCounterGetter = useUnreadCounterGetter();

  return useCallback(
    (workspaces: WorkspaceApiType[]) => {
      let total = 0;
      workspaces.forEach(workspace => {
        total += getUnreadCounterGetter(workspace.id);
      });
      return total;
    },
    [getUnreadCounterGetter],
  );
};

export const useChatConversationUnreadCounterGetter = () => {
  const { unreadChatConversations } = useUnreadCounters();

  return useCallback(
    (chatConversationId: string) => {
      return normalizeUnreadItemsCount(
        unreadChatConversations[chatConversationId] ?? 0,
      );
    },
    [unreadChatConversations],
  );
};

export const useChatConversationHasUnreadCount = (
  workspaceId: string,
  conversationId: string,
): boolean => {
  const { unreadChatMessages } = useUnreadCounters();

  return useMemo(() => {
    return (
      (
        unreadChatMessages[workspaceId] as UnreadNotificationsCountApiType & {
          chatConversations?: Record<
            string,
            UnreadChatMessagesConversationType
          >;
        }
      )?.chatConversations?.[conversationId]?.hasUnread || false
    );
  }, [unreadChatMessages, workspaceId, conversationId]);
};
