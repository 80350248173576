import { PaymentMethodApiType } from './Billing.types';
import { BillingTranslation } from './i18n';
import { WorkspaceApiType } from '../Workspace/Workspace.types';
import { GetPaymentPlansResponse } from './Billing.queries';

export const getDefaultPaymentMethodId = (
  paymentMethods: PaymentMethodApiType[],
): string => {
  const paymentMethod = paymentMethods.filter(method => method.default);
  return paymentMethod[0] ? paymentMethod[0].paymentMethodId : '';
};

export const stripeErrorCodeToMessage = (
  code: string | undefined,
): BillingTranslation => {
  if (code === 'incomplete_number') {
    return BillingTranslation.stripeIncompleteNumber;
  } else if (code === 'invalid_number') {
    return BillingTranslation.stripeInvalidNumber;
  } else if (code === 'incomplete_cvc') {
    return BillingTranslation.stripeIncompleteCvc;
  } else if (code === 'incomplete_expiry') {
    return BillingTranslation.stripeIncompleteExpiry;
  } else if (code === 'invalid_expiry_month_past') {
    return BillingTranslation.stripeInvalidExpiryMonthPast;
  } else if (code === 'invalid_expiry_year_past') {
    return BillingTranslation.stripeInvalidExpiryYearPast;
  } else {
    return code as BillingTranslation;
  }
};

export const getPaymentPlanByWorkspace = (
  paymentPlans: GetPaymentPlansResponse | undefined,
  workspace: WorkspaceApiType | undefined,
) => {
  if (
    paymentPlans &&
    workspace &&
    paymentPlans.getPaymentPlans &&
    workspace.type
  ) {
    for (let plan of paymentPlans.getPaymentPlans) {
      if (plan.workspaceType === workspace.type) {
        return plan;
      }
    }
  }

  return undefined;
};

export const billingIsOk = (
  workspace: WorkspaceApiType | undefined,
): Boolean => {
  if (!workspace) return false;

  return (
    !workspace.billingRequired ||
    (workspace.billingRequired && workspace.billingOk) ||
    false
  );
};
