import {
  StreamDesktopEvent,
  StreamEventRequest,
} from '../../GeneralMercure.types';
import { desktopRequestRemove, desktopRequestUpdate } from './Requests';
import { putDesktopToIDDB } from '../../../../Desktop/data/Desktop/operations/DesktopIDDB.operations';
import { mercureDesktopEventToDesktopApiType } from '../../../../Desktop/data/Desktop/utils/DesktopMercure.utils';
import { DesktopApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';

export const desktopRequest = (event: StreamDesktopEvent) => {
  switch (event['@request']) {
    case StreamEventRequest.NEW:
      const newDesktop = mercureDesktopEventToDesktopApiType(event);
      putDesktopToIDDB(event.workspace?.id, newDesktop as DesktopApiType);
      break;
    case StreamEventRequest.REMOVE:
      desktopRequestRemove(event);
      break;
    case StreamEventRequest.UPDATE:
      desktopRequestUpdate(event);
      break;
    default:
      break;
  }
};
