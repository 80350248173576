import styled from 'styled-components';

export const ErrorMessageContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  background-color: ${p => p.theme.colors.Background};

  h1 {
    margin-bottom: 1rem;
    color: ${p => p.theme.colors.OnSurface};
  }

  p {
    margin-bottom: 1rem;
    color: ${p => p.theme.colors.OnSurface};
  }

  button + button {
    margin-left: 1rem;
  }
`;
