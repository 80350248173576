import { useCallback, useContext, useMemo } from 'react';
import { StreamTopic } from '../Mercure.types';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { AvailabilityStatusType } from '../../User/User.types';
import {
  clearStatusValidTimestamps,
  isStatusValidTimestampExpired,
} from '../../Account/Account.utils';
import { showToastInfoMessage } from '../../../shared/components/Toast';
import { AccountTranslation } from '../../Account/i18n';
import { getPingTimestamp } from './PingMercure.utils';
import { PingMercureContext } from './PingMercure.context';
import { useMercureClient } from '../Mercure.hooks';

export const usePingMercureUrl = () => {
  const { account: currentAccount } = useCurrentAccount();

  return useMemo(() => {
    if (!currentAccount?.mercureInfo?.ping || !process.env.PING_MERCURE_URL) {
      return null;
    }

    const url = new URL(process.env.PING_MERCURE_URL);
    const mercureCategoryInfo = currentAccount?.mercureInfo?.ping;

    let publishTopics = mercureCategoryInfo.topics[StreamTopic.workspaces];
    if (!Array.isArray(publishTopics)) {
      publishTopics = [publishTopics];
    }

    publishTopics.forEach(topicUrl => {
      url.searchParams.append('topic', topicUrl);
    });

    return url.toString();
  }, [currentAccount?.mercureInfo?.ping]);
};

export const usePublishPing = () => {
  const { pingMercureClient } = useMercureClient();

  const { account: currentAccount } = useCurrentAccount();

  const pingMercureUrl = usePingMercureUrl();
  const pingMercureInfo = currentAccount?.mercureInfo?.ping;
  const pingMercureInitalToken =
    currentAccount?.mercureInfo?.ping?.authorization;

  return useCallback(
    (status: AvailabilityStatusType, time?: string) => {
      if (!pingMercureUrl || !pingMercureInfo || !pingMercureInitalToken) {
        return;
      }

      const timestamp = time || getPingTimestamp();

      // If busy or invisible, we should force Online "the next day"
      if (
        status === AvailabilityStatusType.Busy ||
        status === AvailabilityStatusType.Invisible
      ) {
        const shouldForceOnline = isStatusValidTimestampExpired(status);
        if (shouldForceOnline) {
          clearStatusValidTimestamps();
          status = AvailabilityStatusType.Online;
          showToastInfoMessage(AccountTranslation.statusForcedOnline);
        }
      }

      const data = {
        ping: 'pong',
        identity: currentAccount?.identityId,
        state: status,
        time: timestamp,
      };

      const formData = new URLSearchParams();
      formData.append('id', '');
      formData.append('type', '');
      formData.append('retry', '');
      formData.append('private', '1');
      formData.append('data', JSON.stringify(data));
      (pingMercureInfo.topics.workspaces as []).forEach(
        (topicWorkspace: string) => {
          formData.append('topic', topicWorkspace);
        },
      );

      window.DESKTOPCOM_LOG_PING_PUBLISH_MERCURE_EVENTS &&
        console.log('[PING event OUT]', data);

      if (!pingMercureClient) {
        return;
      }

      pingMercureClient.pong(formData);
    },
    [
      currentAccount?.identityId,
      pingMercureClient,
      pingMercureInfo,
      pingMercureInitalToken,
      pingMercureUrl,
    ],
  );
};

export const usePingMercure = () => useContext(PingMercureContext);
