export const CURRENT_ACCOUNT_IDDB_CACHE_ID = 'current_account';

// TODO: constants bellow are copied form previous implementation, some may not belong here
export const UNREAD_REQUEST_TIMEOUT = 3000;
export const UNREAD_CHAT_MESSAGES_REQUEST_TIMEOUT = 3000;

export const GET_ACCOUNT_NETWORK_ERROR = 'Network error while fetching account';
export const GET_ACCOUNT_ACCESS_ERROR = 'Access denied while fetching account';
export const GET_ACCOUNT_INTERNAL_SERVER_ERROR =
  'Internal server error while fetching account';

export const GET_ACCOUNT_RESPONSE_NOT_OK_ERROR = 'Account response not ok';
