import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const EditIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.013 1.427c.328-.328.773-.512 1.237-.512.464 0 .909.184 1.237.512l1.086 1.086c.328.328.512.773.512 1.237 0 .464-.184.909-.512 1.237l-8.61 8.61c-.21.21-.47.364-.756.445l-3.25.93c-.13.037-.266.038-.395.005-.13-.034-.248-.101-.343-.196-.094-.095-.162-.213-.195-.343-.034-.13-.032-.265.005-.394l.93-3.25c.08-.287.234-.547.444-.758l8.61-8.61zm1.414 1.06c-.023-.023-.05-.042-.081-.054-.03-.013-.063-.02-.096-.02s-.065.007-.096.02c-.03.012-.058.03-.08.054L10.81 3.75l1.44 1.44 1.262-1.263c.023-.023.042-.05.055-.081.012-.03.018-.063.018-.096s-.006-.066-.018-.096c-.013-.03-.032-.058-.055-.081l-1.086-1.086zM11.19 6.25L9.75 4.81l-6.286 6.287c-.03.03-.052.067-.064.108l-.558 1.953 1.953-.558c.041-.012.078-.034.108-.064L11.19 6.25z"
        fill="currentColor"
      />
    </svg>
  );
});
