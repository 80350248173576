export const GRAPHQL_TYPENAME_MUTATION = 'Mutation';
export const GRAPHQL_TYPENAME_IMAGE_ASSET = 'ImageAsset';
export const GRAPHQL_TYPENAME_CATEGORY = 'Category';

export const GRAPHQL_QUERY_PAGINATION_VARIABLE_TYPES = `
  $first: Int
  $last: Int
  $before: String
  $after: String
`;

export const GRAPHQL_QUERY_PAGINATION_VARIABLES = `
  first: $first
  last: $last
  before: $before
  after: $after
`;

export const GRAPHQL_QUERY_PAGE_INFO = `
  pageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    startCursor
  }
`;

export const NO_REST_API_CLIENT_ERROR = 'NO_REST_API_CLIENT_ERROR';
