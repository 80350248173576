import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const AppleIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3342 2.48704C16.3342 3.48559 15.9169 4.48415 15.3279 5.2081C14.6897 6.00695 13.5975 6.5936 12.7262 6.5936C12.628 6.5936 12.5298 6.58112 12.4685 6.56863C12.4562 6.51871 12.4316 6.36892 12.4316 6.21914C12.4316 5.2081 12.9348 4.20955 13.487 3.57297C14.1743 2.74916 15.3401 2.13754 16.2974 2.1001C16.3219 2.21244 16.3342 2.34974 16.3342 2.48704ZM13.7811 7.36432C14.4094 7.1099 15.1604 6.80576 16.0029 6.80576C16.5551 6.80576 18.5187 6.85569 19.8072 8.75295C19.7995 8.75907 19.7827 8.77022 19.758 8.78653C19.4312 9.0029 17.7332 10.127 17.7332 12.4601C17.7332 15.0563 19.7357 16.1476 20.2031 16.3636L20.2981 16.4044C20.2859 16.4793 19.9054 17.8024 18.985 19.1754L18.8494 19.375C18.0769 20.5044 17.2314 21.5844 16.0029 21.5844C15.3753 21.5844 14.9705 21.4014 14.5515 21.2119C14.102 21.0087 13.6361 20.798 12.8612 20.798C12.0857 20.798 11.5757 21.023 11.0927 21.236C10.6585 21.4275 10.2463 21.6094 9.6827 21.6094C8.62456 21.6094 7.84763 20.7218 7.01575 19.569L6.70056 19.1255C5.59606 17.5153 4.7002 15.0314 4.7002 12.6598C4.7002 8.87777 7.11781 6.85569 9.51089 6.85569C10.2153 6.85569 10.855 7.11929 11.4213 7.3527C11.8712 7.53811 12.2749 7.70446 12.628 7.70446C12.9411 7.70446 13.3294 7.54724 13.7811 7.36432Z"
        fill="currentColor"
      />
    </svg>
  );
});
