import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const UserIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="user-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5.00001C10.5 5.66305 10.2366 6.29893 9.76777 6.76777C9.29893 7.23662 8.66304 7.50001 8 7.50001C7.33696 7.50001 6.70108 7.23662 6.23224 6.76777C5.76339 6.29893 5.5 5.66305 5.5 5.00001C5.5 4.33697 5.76339 3.70108 6.23224 3.23224C6.70108 2.7634 7.33696 2.50001 8 2.50001C8.66304 2.50001 9.29893 2.7634 9.76777 3.23224C10.2366 3.70108 10.5 4.33697 10.5 5.00001ZM10.561 8.07301C11.1923 7.54665 11.6461 6.83859 11.8608 6.04515C12.0754 5.2517 12.0403 4.41141 11.7604 3.63859C11.4805 2.86577 10.9693 2.19794 10.2964 1.72595C9.62345 1.25397 8.82145 1.00076 7.9995 1.00076C7.17755 1.00076 6.37555 1.25397 5.70262 1.72595C5.02969 2.19794 4.51849 2.86577 4.23859 3.63859C3.95868 4.41141 3.92364 5.2517 4.13825 6.04515C4.35286 6.83859 4.80669 7.54665 5.438 8.07301C4.45359 8.53841 3.61427 9.26276 3.0099 10.1685C2.40553 11.0743 2.05886 12.1273 2.007 13.215C2.00218 13.411 2.07428 13.6011 2.20788 13.7445C2.34147 13.888 2.52593 13.9735 2.72177 13.9826C2.9176 13.9918 3.10923 13.9239 3.25562 13.7935C3.40201 13.6631 3.49153 13.4806 3.505 13.285C3.55952 12.1295 4.0569 11.0394 4.89391 10.241C5.73091 9.44254 6.84324 8.99708 8 8.99708C9.15676 8.99708 10.2691 9.44254 11.1061 10.241C11.9431 11.0394 12.4405 12.1295 12.495 13.285C12.4975 13.3849 12.5198 13.4832 12.5608 13.5743C12.6018 13.6654 12.6606 13.7474 12.7337 13.8154C12.8068 13.8835 12.8928 13.9363 12.9865 13.9707C13.0803 14.0051 13.18 14.0204 13.2798 14.0157C13.3796 14.0111 13.4774 13.9865 13.5676 13.9435C13.6577 13.9005 13.7384 13.84 13.8048 13.7654C13.8713 13.6908 13.9221 13.6037 13.9544 13.5092C13.9868 13.4147 13.9999 13.3146 13.993 13.215C13.941 12.1272 13.5942 11.0741 12.9897 10.1683C12.3851 9.26256 11.5456 8.53827 10.561 8.07301Z"
        fill="currentColor"
      />
    </svg>
  );
});
