import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const SearchThinIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="search-thin-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7.5C14 9.0913 13.3679 10.6174 12.2426 11.7426C11.1174 12.8679 9.5913 13.5 8 13.5C6.40871 13.5 4.88258 12.8679 3.75737 11.7426C2.63215 10.6174 2.00001 9.0913 2.00001 7.5C2.00001 5.9087 2.63215 4.38258 3.75737 3.25736C4.88258 2.13214 6.40871 1.5 8 1.5C9.5913 1.5 11.1174 2.13214 12.2426 3.25736C13.3679 4.38258 14 5.9087 14 7.5ZM12.746 13.307C11.2563 14.5248 9.3554 15.1234 7.43669 14.9791C5.51795 14.8348 3.72808 13.9585 2.43726 12.5316C1.14645 11.1046 0.453453 9.2361 0.501603 7.3126C0.549753 5.38901 1.33537 3.55753 2.69595 2.19695C4.05654 0.836355 5.88802 0.0507352 7.8116 0.00259521C9.7351 -0.0455548 11.6036 0.647435 13.0306 1.93826C14.4575 3.22907 15.3338 5.01894 15.4781 6.93769C15.6225 8.85639 15.0238 10.7572 13.806 12.247L18.28 16.72C18.3537 16.7887 18.4128 16.8715 18.4538 16.9635C18.4948 17.0555 18.5168 17.1548 18.5186 17.2555C18.5204 17.3562 18.5019 17.4562 18.4641 17.5496C18.4264 17.643 18.3703 17.7278 18.299 17.799C18.2278 17.8703 18.143 17.9264 18.0496 17.9641C17.9562 18.0018 17.8562 18.0204 17.7555 18.0186C17.6548 18.0168 17.5555 17.9948 17.4635 17.9538C17.3715 17.9128 17.2887 17.8537 17.22 17.78L12.747 13.307H12.746Z"
        fill="currentColor"
      />
    </svg>
  );
});
