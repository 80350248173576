import { getApolloClient } from '../../../../Api/Api.utils';
import {
  DELETE_DESKTOP,
  EditDesktopResponse,
  type DeleteDesktopResponse,
  type DeleteDesktopVariables,
  EditDesktopVariables,
  EDIT_DESKTOP,
} from '../../../Desktop.mutations';
import { DesktopEditInputType } from '../types/Desktop.types';

export const deleteDesktopByIdGraphQLApi = (desktopId: string) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<DeleteDesktopResponse, DeleteDesktopVariables>({
      mutation: DELETE_DESKTOP,
      variables: {
        input: {
          id: desktopId,
        },
      },
      fetchPolicy: 'no-cache',
    })
    .then(response => response.data);
};

export const updateDesktopByIdGraphQLApi = (
  variables: DesktopEditInputType,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<EditDesktopResponse, EditDesktopVariables>({
      mutation: EDIT_DESKTOP,
      variables: {
        input: {
          ...variables,
        },
      },
      fetchPolicy: 'no-cache',
    })
    .then(response => response.data);
};
