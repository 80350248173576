import { ThemeType } from '../../../../Theme';
import type { ConferencePreviewThemesType } from './ConferencePreview.theme.types';

export const ConferencePreviewTheme: ConferencePreviewThemesType = {
  [ThemeType.light]: {
    colors: {
      ConferencePreviewInputBackground: 'rgba(2, 17, 33, 0.04)',
      ConferencePreviewCopyButtonBackground: 'rgb(245 246 246)',
    },
  },
  [ThemeType.dark]: {
    colors: {
      ConferencePreviewInputBackground: 'rgba(2, 17, 33, 0.04)',
      ConferencePreviewCopyButtonBackground: '#0C1B2A',
    },
  },
};
