import { useCallback, useContext, useEffect, useMemo } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import {
  QueryHookOptions,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  GET_WORKSPACES_BY_IDENTITY,
  GetWorkspaceOwnerAccountResponse,
  GetWorkspaceOwnerAccountVariables,
  GetWorkspacesByIdentityResponse,
  GetWorkspacesByIdentityVariables,
  WORKSPACE_OWNER_ACCOUNT,
  WORKSPACE_PERMISSIONS,
  WorkspacePermissionsResponse,
  WorkspacePermissionsVariables,
} from './Workspace.queries';
import { WorkspaceContext, WorkspaceStateContext } from './Workspace.context';
import {
  ACTIVATE_WORKSPACE,
  ActivateWorkspaceResponse,
  ActivateWorkspaceVariables,
  CANCEL_UPGRADE_WORKSPACE,
  CancelUpgradeWorkspaceResponse,
  CancelUpgradeWorkspaceVariables,
  CHANGE_FEATURE_FOR_WORKSPACE,
  changeFeatureForWorkspaceResponse,
  ChangeFeatureForWorkspaceVariables,
  DOWNGRADE_WORKSPACE,
  DowngradeWorkspaceResponse,
  DowngradeWorkspaceVariables,
  UPDATE_IDENTIFIER_WORKSPACE,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_SORT,
  UpdateIdentifierWorkspaceResponse,
  UpdateIdentifierWorkspaceVariables,
  UpdateWorkspaceResponse,
  UpdateWorkspaceSortResponse,
  UpdateWorkspaceSortVariables,
  UpdateWorkspaceVariables,
  UPGRADE_WORKSPACE,
  UpgradeWorkspaceResponse,
  UpgradeWorkspaceVariables,
} from './Workspace.mutations';
import { useAuthService, useCurrentAccount } from '../Auth/Auth.hooks';
import { useGetPaymentPlansQuery } from '../Billing/Billing.hooks';
import { getPaymentPlanByWorkspace } from '../Billing/Billing.utils';
import { rememberMfaIsRequired } from '../Auth/Auth.utils';
import { useRefreshableQuery } from '../Api/Api.hooks';
import { generateLastUsedWorkspaceLocalstorageKey } from './Workspace.utils';
import { WorkspaceApiType, WorkspaceEdgeApiType } from './Workspace.types';
import { useQueryParams } from '../../shared/hooks';
import { getQueryParamsFrom } from '../../shared/utils/url.utils';
import { WORKSPACE_PATHNAME } from './Workspace.constants';
import { getShortId } from '../../shared/utils/id';

export const useWorkspacesQuery = (
  options?: QueryHookOptions<
    GetWorkspacesByIdentityResponse,
    GetWorkspacesByIdentityVariables
  >,
) =>
  useQuery<GetWorkspacesByIdentityResponse, GetWorkspacesByIdentityVariables>(
    GET_WORKSPACES_BY_IDENTITY,
    options,
  );

export const useCurrentWorkspace = () => {
  const { workspace, refetchWorkspaces } = useContext(WorkspaceContext);
  return { workspace, refetchWorkspaces };
};

export const useCurrentWorkspaceInfo = () => {
  const { workspaceInfo } = useContext(WorkspaceContext);
  return { workspaceInfo };
};

export const useCurrentWorkspacePermissions = () => {
  const { permissions, permissionsLoaded, refetchPermissions } =
    useContext(WorkspaceContext) || {};
  return { permissions, permissionsLoaded, refetchPermissions };
};

export const useMobileNavigationSidebar = () => {
  const {
    mobileNavigationSidebarIsOpen,
    mobileNavigationSidebarToggle,
    mobileNavigationSidebarClose,
  } = useContext(WorkspaceContext) || {};

  return {
    mobileNavigationSidebarIsOpen,
    mobileNavigationSidebarToggle,
    mobileNavigationSidebarClose,
  };
};

export const useWorkspaceAccountMap = () => {
  const { workspaceAccountMap } = useContext(WorkspaceContext) || {
    workspaceAccountMap: {},
  };
  return { workspaceAccountMap };
};

export const useWorkspaceAccount = (id: string) => {
  const { workspaceAccountMap } = useWorkspaceAccountMap();

  return { account: workspaceAccountMap?.[id] || null };
};

export const useCurrentWorkspaceAccount = () => {
  const { workspace } = useCurrentWorkspace();
  return useWorkspaceAccount(workspace?.id);
};

// TODO: deprecate in favor of useWorkspaceLastOpenedPath?
// Or is it needed for the admin pages?
export const useWorkspaceLastOpenedDesktop = () => {
  const { lastOpenedDesktop, setLastOpenedDesktop } =
    useContext(WorkspaceContext) || {};
  return { lastOpenedDesktop, setLastOpenedDesktop };
};

export const useWorkspaceLastOpenedPath = () => {
  const { lastOpenedPath, setLastOpenedPath } =
    useContext(WorkspaceContext) || {};
  return { lastOpenedPath, setLastOpenedPath };
};

export const useActivateWorkspaceMutation = () =>
  useMutation<ActivateWorkspaceResponse, ActivateWorkspaceVariables>(
    ACTIVATE_WORKSPACE,
  );

export const useUpgradeWorkspaceMutation = () =>
  useMutation<UpgradeWorkspaceResponse, UpgradeWorkspaceVariables>(
    UPGRADE_WORKSPACE,
  );

export const useDowngradeWorkspaceMutation = () =>
  useMutation<DowngradeWorkspaceResponse, DowngradeWorkspaceVariables>(
    DOWNGRADE_WORKSPACE,
  );

export const useCancelUpgradeWorkspaceMutation = () =>
  useMutation<CancelUpgradeWorkspaceResponse, CancelUpgradeWorkspaceVariables>(
    CANCEL_UPGRADE_WORKSPACE,
  );

export const useUpdateWorkspaceMutation = () =>
  useMutation<UpdateWorkspaceResponse, UpdateWorkspaceVariables>(
    UPDATE_WORKSPACE,
  );

export const useUpdateWorkspaceSortMutation = () =>
  useMutation<UpdateWorkspaceSortResponse, UpdateWorkspaceSortVariables>(
    UPDATE_WORKSPACE_SORT,
  );

export const useUpdateIdentifierWorkspaceMutation = () =>
  useMutation<
    UpdateIdentifierWorkspaceResponse,
    UpdateIdentifierWorkspaceVariables
  >(UPDATE_IDENTIFIER_WORKSPACE);

export const useChangeFeatureForWorkspaceMutation = () =>
  useMutation<
    changeFeatureForWorkspaceResponse,
    ChangeFeatureForWorkspaceVariables
  >(CHANGE_FEATURE_FOR_WORKSPACE);

export const useAccountPermissionsForWorkspaceQuery = (
  options?: QueryHookOptions<
    WorkspacePermissionsResponse,
    WorkspacePermissionsVariables
  >,
) =>
  useQuery<WorkspacePermissionsResponse, WorkspacePermissionsVariables>(
    WORKSPACE_PERMISSIONS,
    options,
  );

export const useGetWorkspaceOwnerAccountVariablesQuery = (
  options?: QueryHookOptions<
    GetWorkspaceOwnerAccountResponse,
    GetWorkspaceOwnerAccountVariables
  >,
) =>
  useRefreshableQuery<
    GetWorkspaceOwnerAccountResponse,
    GetWorkspaceOwnerAccountVariables
  >(WORKSPACE_OWNER_ACCOUNT, options);

export const useWorkspaceOwnerAccount = (workspaceId: string) => {
  const { data: workspaceOwnerData, loading: workspaceOwnerAccountLoading } =
    useGetWorkspaceOwnerAccountVariablesQuery({
      variables: {
        workspace: workspaceId,
      },
    });
  const workspaceOwnerAccount =
    workspaceOwnerData?.getWorkspaceOwnerAccount.account;
  return { workspaceOwnerAccount, workspaceOwnerAccountLoading };
};

export const useEvictAccountWorkspace = () => {
  const apolloClient = useApolloClient();
  return useCallback(
    (accountWorkspaceId: string) => {
      apolloClient.cache.evict({
        id: `AccountWorkspace:${accountWorkspaceId}`,
      });
    },
    [apolloClient.cache],
  );
};

export const useLoginWithMfa = () => {
  const authService = useAuthService();
  const { pathname } = useLocation();
  const { refetchAccountData } = useCurrentAccount();
  const evictAccountWorkspace = useEvictAccountWorkspace();

  return useCallback(
    (accountWorkspaceId?: string) => {
      rememberMfaIsRequired();
      authService
        .triggerMfa({
          appState: pathname,
        })
        .then(async shouldUpdate => {
          if (shouldUpdate) {
            await refetchAccountData?.();
            if (accountWorkspaceId) {
              evictAccountWorkspace(accountWorkspaceId);
            }
          }
        })
        .catch(e => {
          console.log('[Workspace.hooks] useLoginWithMfa error', e);
        });
    },
    [authService, evictAccountWorkspace, pathname, refetchAccountData],
  );
};

export const useCurrentWorkspacePaymentPlan = () => {
  const { workspace } = useCurrentWorkspace();
  const { data: paymentPlans } = useGetPaymentPlansQuery({
    variables: {
      workspace: workspace.id,
    },
  });

  return useMemo(() => {
    const workspacePaymentPlan = getPaymentPlanByWorkspace(
      paymentPlans,
      workspace,
    );
    return workspacePaymentPlan?.workspaceType;
  }, [paymentPlans, workspace]);
};

export const useWorkspaceState = () => useContext(WorkspaceStateContext);

export const useNavlinkIsActive = (matchPath: string) => {
  const { pathname } = useLocation();

  return useMemo(() => {
    return !!pathname.match(matchPath);
  }, [matchPath, pathname]);
};

/**
 * Retrieve the last workspace ID opened by an identity ID
 *
 * @returns workspaceId string
 */
export const useGetLastUsedWorkspaceId = (): string | null => {
  const { account } = useCurrentAccount();
  const key = useMemo(
    () =>
      account
        ? generateLastUsedWorkspaceLocalstorageKey(account.identityId)
        : null,
    [account],
  );

  return !key ? null : localStorage.getItem(key);
};

/**
 * Set the last workspace ID opened by an identity ID
 */
export const useSetLastUsedWorkspaceId = () => {
  const { account } = useCurrentAccount();
  const key = useMemo(
    () =>
      account
        ? generateLastUsedWorkspaceLocalstorageKey(account.identityId)
        : null,
    [account],
  );

  return useCallback(
    (workspaceId?: string) => {
      if (!key || !workspaceId) return;
      localStorage.setItem(key, workspaceId);
    },
    [key],
  );
};

/**
 * Redirect workspaces logic
 */
export const useRedirectWorkspace = (
  identityWorkspaces: WorkspaceEdgeApiType[],
  workspace?: WorkspaceApiType,
) => {
  const { account } = useCurrentAccount();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const lastUsedWorkspaceId = useGetLastUsedWorkspaceId();
  const setLastUsedWorkspaceId = useSetLastUsedWorkspaceId();

  useEffect(() => {
    if (workspace?.id) {
      setLastUsedWorkspaceId(workspace.id);
    }
  }, [setLastUsedWorkspaceId, workspace]);

  const workspaces = useMemo(
    () => identityWorkspaces.map(({ workspace }) => workspace),
    [identityWorkspaces],
  );

  useEffect(() => {
    if (workspaces?.length && account?.workspaceInfo) {
      if (!workspace?.id) {
        let redirectId = null;

        if (
          lastUsedWorkspaceId &&
          workspaces.some(
            workspace =>
              getShortId(workspace.id) === getShortId(lastUsedWorkspaceId),
          )
        ) {
          redirectId = getShortId(lastUsedWorkspaceId);
        }

        if (!redirectId) {
          redirectId = getShortId(workspaces[0].id);
        }

        navigate(
          {
            pathname: generatePath(WORKSPACE_PATHNAME, {
              workspaceId: redirectId,
            }),
            search: getQueryParamsFrom(queryParams),
          },
          { replace: true },
        );
      }
    }
  }, [workspaces, workspace, account]); // eslint-disable-line
};
