import { EncryptionLocale, EncryptionTranslation } from './i18n.types';

export const enEncryptionLocale: EncryptionLocale = {
  // Master Password lock open button and screen
  [EncryptionTranslation.masterPasswordLockOpenButtonText]:
    'Unlock credentials',
  [EncryptionTranslation.masterPasswordUnlockMessageText]:
    'Welcome to your Password Manager!',
  [EncryptionTranslation.masterPasswordUnlockMessageFirstRow]:
    'Seamlessly move between your different apps',
  [EncryptionTranslation.masterPasswordUnlockMessageSecondRow]:
    'Manage multiple accounts with ease',
  [EncryptionTranslation.masterPasswordUnlockMessageThirdRow]:
    'Share group passwords with your team',
  [EncryptionTranslation.masterPasswordUnlockInputTitle]:
    'To get started, enter your account password below',
  [EncryptionTranslation.masterPasswordCreatePasswordTitle]:
    'Welcome to your Password Manager!',
  [EncryptionTranslation.masterPasswordCreatePasswordSubTitle]:
    'To get started, create a password for your account',
  [EncryptionTranslation.masterPasswordUnlockButtonText]:
    'Unlock Password Manager',
  [EncryptionTranslation.masterPasswordUnlockInputPlaceholder]:
    'Enter your password',
  [EncryptionTranslation.masterPasswordUnlockScreenErrorMessage]:
    'Unable to unlock credentials with this password',
  [EncryptionTranslation.masterPasswordUnlockExplanationMessage]:
    'Confirm your password to use<br>password management features',
  [EncryptionTranslation.masterPasswordUnlockDisabledMessage]:
    'To activate Password Manager feature<br>change your password in My profile section',
  [EncryptionTranslation.masterPasswordSetPasswordMessage]:
    'To activate Password Manager feature<br>set your password in My profile section',

  // Master Password lock screen for Make Owner modal
  [EncryptionTranslation.masterPasswordUnlockOnMakeOwnerInputTitle]:
    'To change ownership of this Workspace please confirm by typing your password in the field below.',
  [EncryptionTranslation.masterPasswordUnlockOnMakeOwnerInputPlaceholder]:
    'Enter your password',
  [EncryptionTranslation.masterPasswordUnlockOnMakeOwnerScreenErrorMessage]:
    'Unable to confirm change ownership with this password',
  [EncryptionTranslation.masterPasswordUnlockOnMakeOwnerButtonText]:
    'Change ownership of the workspace',

  // Passwords page
  [EncryptionTranslation.passwordsPagePasswordSharedIcon]:
    'Password shared with other users',
  [EncryptionTranslation.passwordsPagePasswordLaunchIcon]:
    'Open app with credentials',
  [EncryptionTranslation.passwordsPageEmptyStateDescription]:
    'You have not added any passwords yet. Before adding passwords, you should enable MFA in <a href="{url}">Settings > Preferences</a>',
  [EncryptionTranslation.passwordsPageEmptyStateSupportText]:
    '<a href="{url}" rel="noopener noreferrer" target="_blank">Learn how to manage your app credentials. </a>',

  // Create vault form
  [EncryptionTranslation.createVaultFormUpdateButton]: 'Create',
  [EncryptionTranslation.createVaultModalHeader]: 'Create login/password',
  [EncryptionTranslation.createVaultModalTabManual]: 'Manual',
  [EncryptionTranslation.createVaultModalTabGroup]: 'Group',
  [EncryptionTranslation.createVaultModalTabImport]: 'Import',

  // Edit vault form
  [EncryptionTranslation.editVaultFormUpdateButton]: 'Update',
  [EncryptionTranslation.editVaultFormShowPasswordButton]: 'Show passwords',
  [EncryptionTranslation.editVaultFormOwnerNoSharingWarningMessage]:
    'The credentials you store here can be lost forever if you forget your account password. As a workspace owner you are also the only backup method for other workspace members if they lose their password. <a href="{learnMoreLink}" target="_blank" rel="noopener noreferrer">Learn more</a>',
  [EncryptionTranslation.editVaultFormSuccessMessage]:
    'Your app credentials updated successfully',
  [EncryptionTranslation.editVaultFormEncryptionErrorMessage]:
    'Encryption error occurred',
  [EncryptionTranslation.editVaultOwnerHasNoKeysetErrorMessage]:
    'The password manager feature can only be activated if the workspace owner has activated it. Please ask the workspace owner to change his password before proceeding.',

  // Edit vault modal and form
  [EncryptionTranslation.editPasswordsModalHeader]: 'Edit password',
  [EncryptionTranslation.editPasswordsPasswordLength]: 'Password Length',
  [EncryptionTranslation.editPasswordsUppercase]: 'Uppercase',
  [EncryptionTranslation.editPasswordsLowercase]: 'Lowercase',
  [EncryptionTranslation.editPasswordsDigits]: 'Digits',
  [EncryptionTranslation.editPasswordsSymbols]: 'Symbols',
  [EncryptionTranslation.editPasswordsGenerateNewPassword]:
    'Generate new password',
  [EncryptionTranslation.editPasswordsCancel]: 'Cancel',
  [EncryptionTranslation.editPasswordsErrorText]:
    'At least one rule should be selected',
  [EncryptionTranslation.editPasswordLoginInputLabel]: 'Login',
  [EncryptionTranslation.editPasswordInputLabel]: 'Password',
  [EncryptionTranslation.editPasswordUrlInputLabel]: 'Url',
  [EncryptionTranslation.formUrlRequired]: 'Url is required',
  [EncryptionTranslation.editPasswordCopiedMessage]:
    'Password was successfully copied',
  [EncryptionTranslation.editPasswordShowPassword]: 'Show password',
  [EncryptionTranslation.editPasswordHidePassword]: 'Hide password',
  [EncryptionTranslation.editPasswordCopyPassword]: 'Copy password',

  // Select vault item app group
  [EncryptionTranslation.vaultItemGroupSelectDescription]:
    'Passwords for Google, Apple or Microsoft give access to a group of apps. You can add the group passwords here, and they will be a choice for all related apps. <a href={clickHereLink} target="_blank" rel="noopener noreferrer">Click here to learn more</a>',

  // Share vault modal and form
  [EncryptionTranslation.sharePasswordsModalHeader]: 'Share password: {login}',
  [EncryptionTranslation.sharePasswordsFormShareButton]: 'Share',
  [EncryptionTranslation.sharePasswordsAccountsListDeleteButton]: 'Remove',
  [EncryptionTranslation.sharePasswordsAccountsListDeleteConfirmationMessage]:
    'Are you sure you want to remove access for this user?',

  // Select vault modal and form
  [EncryptionTranslation.selectVaultForDesktopModalHeader]: 'Choose password',
  [EncryptionTranslation.selectVaultEncryptedItemPlaceholder]: 'Encrypted item',
  [EncryptionTranslation.selectVaultNoneOption]: 'None',
  [EncryptionTranslation.selectVaultItemExpandButton]: 'Expand vault edit form',
  [EncryptionTranslation.selectVaultAddPasswordButton]: 'Add a login/password',
  [EncryptionTranslation.selectVaultCloseButton]: 'Close',

  // Delete vault
  [EncryptionTranslation.deleteVaultModalHeader]: 'Delete password',
  [EncryptionTranslation.deleteVaultDescription]:
    'Are you sure you want to delete password for <strong>{name}</strong>?',
  [EncryptionTranslation.deleteVaultDeleteButton]: 'Delete',
  [EncryptionTranslation.deleteVaultCancelButton]: 'Cancel',

  // Unshare vault
  [EncryptionTranslation.unshareVaultModalHeader]: 'Delete password',
  [EncryptionTranslation.unshareVaultDescription]:
    'Are you sure you want to delete password for <strong>{name}</strong> (shared) from your list?',
  [EncryptionTranslation.unshareVaultDeleteButton]: 'Delete',
  [EncryptionTranslation.unshareVaultCancelButton]: 'Cancel',

  // Delete multiple vaults
  [EncryptionTranslation.deleteMultipleVaultsModalHeader]:
    'Delete {vaultsCount} {vaultsCount, plural, one {password} other {passwords}}',
  [EncryptionTranslation.deleteMultipleVaultsDescription]:
    'Are you sure you want to delete {vaultsCount, plural, one {this} other {these}} {vaultsCount} {vaultsCount, plural, one {selected password} other {selected passwords}}?',
  [EncryptionTranslation.deleteMultipleVaultsDeleteButton]:
    'Delete {vaultsCount} {vaultsCount, plural, one {password} other {passwords}}',
  [EncryptionTranslation.deleteMultipleVaultsInputDescription]:
    'Please type <span class="highlighted">delete</span> to proceed or close this modal to cancel.',
  [EncryptionTranslation.deleteMultipleVaultsCancelButton]: 'Cancel',

  // Reencrypt vault accesses
  [EncryptionTranslation.reencryptAccessesModalHeader]:
    'Access recovery request',
  [EncryptionTranslation.reencryptAccessesDescription]:
    'This access recovery request was created automatically when a user in this workspace reset the password. ' +
    "By accepting the request, you will recover the user's access to all previously added credentials." +
    '<br><br>',
  [EncryptionTranslation.reencryptAccessesAccountInfo]:
    'Name: <strong data-testid="recovery-request-name">{fullName}</strong><br> Email: <strong data-testid="recovery-request-email">{email}</strong>',
  [EncryptionTranslation.reencryptAccessesAccept]: 'Accept',
  [EncryptionTranslation.reencryptAccessesAcceptConfirmationMessage]:
    'Are you sure you want to confirm this recovery request?',
  [EncryptionTranslation.reencryptAccessesDecline]: 'Decline',
  [EncryptionTranslation.reencryptAccessesRequestAcceptedMessage]:
    'This recovery request was accepted.',
  [EncryptionTranslation.reencryptAccessesRequestDeclinedMessage]:
    'This recovery request was declined.',

  //Passwords
  [EncryptionTranslation.switcherHeader]: 'Passwords',
  [EncryptionTranslation.downloadPasswordsButton]: 'Download passwords',
  [EncryptionTranslation.passwordCreateButton]: 'Create new password',
  [EncryptionTranslation.passwordFilter]: 'Filter',
  [EncryptionTranslation.passwordEditPassword]: 'Edit',
  [EncryptionTranslation.passwordSharePassword]: 'Share',
  [EncryptionTranslation.passwordSharePasswordTooltipMessage]:
    'Sharing is a Pro feature',
  [EncryptionTranslation.passwordSelectPassword]: 'Select',
  [EncryptionTranslation.passwordUnselectPassword]: 'Unselect this item',
  [EncryptionTranslation.passwordUnselectAllPasswords]: 'Unselect all items',
  [EncryptionTranslation.passwordSelectAllPasswords]: 'Select all items',
  [EncryptionTranslation.passwordEditApp]: 'Edit App',
  [EncryptionTranslation.passwordDeletePassword]: 'Delete item',
  [EncryptionTranslation.passwordDeleteSeveralPassword]:
    'Delete {selectedPasswordsCount} items',
  [EncryptionTranslation.passwordViewLoginRow]: 'Login',
  [EncryptionTranslation.passwordViewLoginCopyButton]: 'Copy login',
  [EncryptionTranslation.passwordViewPasswordRow]: 'Password',
  [EncryptionTranslation.passwordViewPasswordCopyButton]: 'Copy password',
  [EncryptionTranslation.passwordViewStrengthRow]: 'Strength',
  [EncryptionTranslation.passwordViewWebsiteRow]: 'Website',
  [EncryptionTranslation.passwordStrengthVeryStrong]: 'Very Strong',
  [EncryptionTranslation.passwordStrengthMedium]: 'Medium',
  [EncryptionTranslation.passwordStrengthWeak]: 'Weak',
  [EncryptionTranslation.passwordControlsEdit]: 'Edit',
  [EncryptionTranslation.passwordControlsDelete]: 'Delete',
  [EncryptionTranslation.passwordControlsUnshare]: 'Delete',
  [EncryptionTranslation.passwordControlsShare]: 'Share',
  [EncryptionTranslation.passwordControlsSharedWithTitle]: 'Shared with',
  [EncryptionTranslation.passwordControlsSharedByTitle]: 'Shared by ',
  [EncryptionTranslation.passwordControlsSharingDisabledNonAdmin]:
    'Sharing is a feature for admins.',
  [EncryptionTranslation.passwordControlsSharingDisabledNonPro]:
    'Sharing is a Pro feature.',
  [EncryptionTranslation.passwordCopyLoginToastText]: 'Login was copied',
  [EncryptionTranslation.passwordCopyPasswordToastText]: 'Password was copied',
  [EncryptionTranslation.passwordVaultItemExpandTooltip]: 'Details',
  [EncryptionTranslation.passwordSharePasswordInfo]:
    'Only workspace members who have enabled the password manager will be listed here. It is not possible to share passwords with external guests.',
  [EncryptionTranslation.passwordUnableToDecryptMessage]:
    'Unable to decrypt credentials. <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">Learn more</a>',
  [EncryptionTranslation.passwordSelectTooltip]: 'Select',
  [EncryptionTranslation.passwordLaunchButtonTooltip]: 'Launch',
  [EncryptionTranslation.passwordMoreButtonTooltip]: 'More',

  [EncryptionTranslation.importPasswords]: 'Import passwords',
  [EncryptionTranslation.importPasswordsModalSubTitle]:
    'You can import passwords from other password managers such as LastPass, 1Password, Bitwarden, Chrome and Safari to mention some.',
  [EncryptionTranslation.importPasswordsModalUploadButton]:
    '<span>Browse your computer</span> to upload a file',
  [EncryptionTranslation.importPasswordsModalUploadButtonOnDragging]:
    'Drop your file here',
  [EncryptionTranslation.importPasswordsControlsHelpLink]: 'Learn more',
  [EncryptionTranslation.importPasswordsControlsSubmitButton]:
    'Upload your file',
  [EncryptionTranslation.importPasswordsControlsRemoveButton]: 'Remove',
  [EncryptionTranslation.importPasswordsErrorInFile]:
    'Cannot proceed your file',
  [EncryptionTranslation.importPasswordsImportingMessage]:
    'The creation of your imported passwords is being processed. It may take some time.',
  [EncryptionTranslation.importPasswordsImportingOkButton]: 'Ok',

  [EncryptionTranslation.passwordsAppPasswords]: 'App passwords',
  [EncryptionTranslation.passwordsGroupPasswords]: 'Group passwords',
  [EncryptionTranslation.passwordsOtherPasswords]: 'Other passwords',

  [EncryptionTranslation.importPasswordsSuccessImportMessage]:
    'We successfully saved <strong>{savedVaultsCount}</strong> vaults',

  [EncryptionTranslation.passwordsBadFileErrorMessage]:
    'Cannot process your file',
  [EncryptionTranslation.passwordsWrongFileTypeErrorMessage]:
    'We support only .csv and .1pif file types',
  [EncryptionTranslation.passwordsWrongRowsErrorMessage]:
    'We cannot process <strong>{errorsInRowsCount}</strong> rows and <strong>{errorsInColumnsCount}</strong> columns in your file, you can proceed without them',
  [EncryptionTranslation.passwordsRowsLimitErrorMessage]:
    'Your file must be less than 1000 rows',
  [EncryptionTranslation.passwordsDuplicatedMessage]:
    'We already have <strong>{duplicatedCount}</strong> vaults in our system',
};
