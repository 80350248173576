import { SegmentType } from '.';

const SEGMENT_KEY_PREFIX = 'segment';
const SEGMENT_KEY_PATTERN = /segment:path:(.*):/;

export const generateSegmentLocalstorageKey = (
  workspaceId: string,
  accountId: string,
  segment?: SegmentType,
) => {
  if (segment) {
    return `${SEGMENT_KEY_PREFIX}:path:${segment}:${accountId}-${workspaceId}`;
  } else {
    return `${SEGMENT_KEY_PREFIX}:${accountId}-${workspaceId}`;
  }
};

export const verifySegmentPathValue = (
  key: string,
  value: string | null,
): boolean => {
  if (!key || !value) return false;

  const keySegment = key.match(SEGMENT_KEY_PATTERN);

  if (
    keySegment?.length &&
    Object.values(SegmentType).includes(keySegment[1] as SegmentType)
  ) {
    let valueSegment = value.split('/')[3];
    if (valueSegment === 'desktop') valueSegment = SegmentType.DESKTOPS;
    if (valueSegment === 'calls') valueSegment = SegmentType.MEETINGS;

    return keySegment[1] === valueSegment;
  }

  return false;
};
