import React from 'react';
import { AuthSessionHandler, CurrentAccountContextType } from './Auth.types';

export const AuthContext = React.createContext<{
  authService: AuthSessionHandler | null;
  fetchAuthConfig: () => Promise<void>;
}>({
  authService: null,
  fetchAuthConfig: () => Promise.resolve(),
});

export const CurrentAccountContext =
  React.createContext<CurrentAccountContextType>({
    account: null,
    refetchAccountData: () => Promise.resolve(undefined),
    updateAccountUnreadNotifications: () => {},
    updateAccount: () => {},
    updateAccountTheme: () => {},
  });
