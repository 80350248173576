export const GRAPHQL_TYPENAME_FOLDER = 'Folder';
export const GRAPHQL_TYPENAME_FOLDER_EDGE = 'FolderEdge';
export const GRAPHQL_TYPENAME_FOLDER_CONNECTION = 'FolderConnection';

export const FOLDER_PATHNAME =
  '/workspace/:workspaceId/desktop/:desktopId/links';

export enum FolderCrumbsLocationContext {
  LINKS_CONSOLIDATED = 'consolidated',
  LINKS_TAB = 'tab',
  LINKDETAILS = 'linkdetails',
}
