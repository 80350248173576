import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ScrollBottomIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1704 0.329501C15.6098 0.768836 15.6098 1.48116 15.1704 1.92049L8.79549 8.29545C8.35616 8.7348 7.64384 8.7348 7.2045 8.29545L0.829501 1.92049C0.390166 1.48116 0.390166 0.768836 0.829501 0.329501C1.26884 -0.109834 1.98116 -0.109834 2.42049 0.329501L8 5.90901L13.5795 0.329501C14.0189 -0.109834 14.7311 -0.109834 15.1704 0.329501Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </svg>
  );
});
