import { StreamLinkEvent } from '../../../GeneralMercure.types';
import { makeLink } from './LinkRequests.utils';
import { LinksApiTypeToDesktopLinksTableRow } from '../../../../../Database/DesktopLinksTable/DesktopLinksTable.utils';
import { database } from '../../../../../Database';

export const linkRequestUpdate = (event: StreamLinkEvent) => {
  const link = makeLink(event);
  // TODO: consider returning previous link fetch from API approach in case of issue
  const linkRow = LinksApiTypeToDesktopLinksTableRow(
    link,
    event.desktop.workspace.id,
  );
  database.desktopLinks.put(linkRow, link.id);
};
