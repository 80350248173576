import { WorkspaceLocale, WorkspaceTranslation } from './i18n.types';

export const enWorkspaceLocale: WorkspaceLocale = {
  [WorkspaceTranslation.menuProfileLink]: 'My profile',
  [WorkspaceTranslation.menuSettingsLink]: 'Workspace settings',
  [WorkspaceTranslation.menuCreateLinkWorkspace]: 'Create or link a workspace',
  [WorkspaceTranslation.menuLogoutLink]: 'Log out',
  [WorkspaceTranslation.workspaceSelectorTooltip]: 'Workspaces and Settings',
  [WorkspaceTranslation.workspaceSelectorAdditionalNotifications]:
    '{notificationCount} notifications in {workspaceCount} other {workspaceCount, plural, one {workspace} other {workspaces}}',

  [WorkspaceTranslation.noAccessMessage]: 'You have no access to this account',
  [WorkspaceTranslation.noWorkspaces]:
    '<p data-testid="no-access-to-workspaces">You don`t have access to any workspaces.<br/>You may have been removed or suspended from your workspace(s).</p><br/><p data-testid="contact-admin">Please contact your workspace owner(s) or reach out to <a href="{supportUrl}" rel="noopener" target="_blank" data-testid="support-url">Webtop.com support</a>.</p> <br/><p data-testid="create-workspace-suggestion">Also you can create your own workspace or you can delete your account.</p>',
  [WorkspaceTranslation.billingNotOk]:
    "<b>{workspaceName}</b> account doesn't have a valid payment method. Please notify the account owner.",
  [WorkspaceTranslation.billingNotOkOwner]:
    'There seems to be a problem with your subscription or payment options. You can fix this in the <a href="{billingUrl}">billing section</a>.',

  [WorkspaceTranslation.featureForWorkspaceMembers]:
    'This feature is only for workspace members',

  [WorkspaceTranslation.adminNavGroupGeneralHeader]: 'General',
  [WorkspaceTranslation.adminNavGroupAccountHeader]: 'Workspace',
  [WorkspaceTranslation.adminHeaderTitle]: 'Settings',
  [WorkspaceTranslation.adminHeaderCloseButton]: 'Close',
  [WorkspaceTranslation.adminNavUserProfileLink]: 'My profile',
  [WorkspaceTranslation.adminNavUserNotificationsLink]: 'Notifications',
  [WorkspaceTranslation.adminNavUserNotificationsSounds]: 'Sounds',
  [WorkspaceTranslation.adminNavUserNotificationsIntegrationsSounds]:
    'Use the settings defined in the global notification',
  [WorkspaceTranslation.adminNavUserNotificationsIntegrations]:
    'Integrations notification sounds',
  [WorkspaceTranslation.adminNavUserNotificationsIntegrationsSubTitle]:
    'Only integrations with notifications are shown',
  [WorkspaceTranslation.adminNavUserNotificationsListIsEmpty]:
    'You have no app integrations with notifications.',
  [WorkspaceTranslation.adminNavUserNotificationsLinkSubTitle]:
    'Your Notifications for the {workspace} workspace',
  [WorkspaceTranslation.adminNavDomainsLink]: 'Domains',
  [WorkspaceTranslation.adminNavNotificationSettingsLink]:
    'Global Notifications',
  [WorkspaceTranslation.adminNavUserSettingsLink]: 'Preferences',
  [WorkspaceTranslation.adminNavCompanyProfileLink]: 'Workspace settings',
  [WorkspaceTranslation.adminNavUsersLink]: 'Users',
  [WorkspaceTranslation.adminNavTeamsLink]: 'Groups',
  [WorkspaceTranslation.adminNavDesktopsLink]: 'Desktops',
  [WorkspaceTranslation.adminNavBillingLink]: 'Billing',

  [WorkspaceTranslation.adminSwitchToWorkspaceHeading]: 'Switch to:',

  [WorkspaceTranslation.companyRoleDirector]: 'Director',
  [WorkspaceTranslation.companyRoleSalesAndMarketing]: 'Sales & marketing',
  [WorkspaceTranslation.companyRoleTechnology]: 'Technology',
  [WorkspaceTranslation.companyRoleHumanResources]: 'Human resources (HR)',
  [WorkspaceTranslation.companyRoleOther]: 'Other',

  [WorkspaceTranslation.formNameLabel]: 'Workspace Name',
  [WorkspaceTranslation.formNameRequiredError]: 'Workspace Name is required',
  [WorkspaceTranslation.formIdentifierLabel]: 'Workspace URL',
  [WorkspaceTranslation.formIdentifierRequiredError]: 'Subdomain is required',
  [WorkspaceTranslation.formCompanySizeLabel]: 'Company size',
  [WorkspaceTranslation.formRoleLabel]: 'Your role',
  [WorkspaceTranslation.formDescriptionLabel]: 'Description',
  [WorkspaceTranslation.formEmailLabel]: 'Admin contact',
  [WorkspaceTranslation.formEmailSubLabel]:
    'This email will be the support contact for the workspace',
  [WorkspaceTranslation.formEmailError]: 'Please enter valid e-mail',
  [WorkspaceTranslation.formUrlLabel]: 'Website',
  [WorkspaceTranslation.formUrlPlaceholder]: 'https://',
  [WorkspaceTranslation.formSubdomainLabel]: 'Subdomain',
  [WorkspaceTranslation.formUrlError]: 'Please enter valid url',
  [WorkspaceTranslation.formMfaLabel]: 'Multi-factor authentication',
  [WorkspaceTranslation.formMfaDescription]:
    'Force every user to use MFA when accessing this workspace',
  [WorkspaceTranslation.formSaveButton]: 'Update',
  [WorkspaceTranslation.formCancelButton]: 'Cancel',
  [WorkspaceTranslation.formContinueButton]: 'Let’s customize your workspace',
  [WorkspaceTranslation.formMfaConfirmTitle]: 'Activate MFA',
  [WorkspaceTranslation.formMfaConfirmMessage]:
    'You must activate MFA for yourself before you can force every user in the workspace to use MFA',
  [WorkspaceTranslation.formMfaConfirmButton]: 'Activate',
  [WorkspaceTranslation.formSuccessMessage]: 'Saved',
  [WorkspaceTranslation.formSubdomainWarning]:
    'This subdomain can only be changed once',
  [WorkspaceTranslation.formSubdomainErrorMessage]:
    'The subdomain {subdomainName} is not available or not valid',

  [WorkspaceTranslation.formFeaturesLabel]: 'Features',
  [WorkspaceTranslation.formFeaturesDescription]:
    'Turn on/off feature package for your workspace',
  [WorkspaceTranslation.formFeatureOrganizeLabel]: 'Organize & Search',
  [WorkspaceTranslation.formFeatureOrganizeDescription]:
    'Search across apps, links & integrations. Organize and share online content and tools.',
  [WorkspaceTranslation.formFeatureCommunicateLabel]: 'Communicate',
  [WorkspaceTranslation.formFeatureCommunicateDescription]:
    'Chat, Meetings & Video Conferencing.',
  [WorkspaceTranslation.formFeatureSelectAtLeastOne]:
    'Please pick at least one feature',
  [WorkspaceTranslation.formFeatureDeactivateConfirmationTitle]:
    'Deactivate {featureName} feature',
  [WorkspaceTranslation.formFeatureDeactivateConfirmationBody]:
    'Are you sure? This will deactivate the feature for all users of your workspace',
  [WorkspaceTranslation.formFeatureDeactivateConfirmationMessage]:
    'Note that turning off a feature that was previously turned on will not delete any of its saved data, just hide it',

  [WorkspaceTranslation.workspaceSettingsPageTitle]: 'Workspace settings',
  [WorkspaceTranslation.workspaceSettingsInfoTitle]: 'Workspace info',
  [WorkspaceTranslation.workspaceSettingsNoDescription]: 'No description',
  [WorkspaceTranslation.workspaceSettingsInfoButton]: 'Edit workspace info',
  [WorkspaceTranslation.workspaceSettingsEditModalHeader]:
    'Edit Workspace information',
  [WorkspaceTranslation.editFormUploadImageButton]: 'Upload image',
  [WorkspaceTranslation.editFormChangeImageButton]: 'Change image',
  [WorkspaceTranslation.editFormRemoveImageButton]: 'Remove image',

  [WorkspaceTranslation.mfaModalTitle]: 'Identity verification',
  [WorkspaceTranslation.mfaModalText]:
    '<b>{workspaceName}</b> workspace requires all users to verify their identities before proceeding. The Multi-Factor Authentication (MFA) method is used for the verification. All MFA information will be stored under your main account.<br><br>' +
    'Please, learn more about the MFA <a href="{mfaLearnMoreUrl}" target="_blank" rel="noopener noreferrer">here</a>.',
  [WorkspaceTranslation.mfaModalCancelButton]: 'Cancel',
  [WorkspaceTranslation.mfaModalConfirmButton]: 'Verify identity',

  [WorkspaceTranslation.dangerZoneTitle]: 'Danger zone',
  [WorkspaceTranslation.dangerZoneDeleteAccountButton]:
    'Permanently delete account',
  [WorkspaceTranslation.dangerZoneConfirmTitle]: 'Confirmation required',
  [WorkspaceTranslation.dangerZoneConfirmWarning]:
    'You are going to delete your account. Deleted accounts CANNOT be restored! Are you ABSOLUTELY sure?',
  [WorkspaceTranslation.dangerZoneConfirmMessage]:
    'This action can lead to data loss. To prevent accidental actions we ask you to confirm your intention.',

  [WorkspaceTranslation.identifierModalLabel]: 'Configure your subdomain',
  [WorkspaceTranslation.identifierWorkspaceNameLabel]: 'Workspace Name',
  [WorkspaceTranslation.identifierWorkspaceNamePlaceholder]:
    'Select a name for your workspace',
  [WorkspaceTranslation.identifierWorkspaceNameRequired]:
    'Workspace name is required',
  [WorkspaceTranslation.identifierLabel]: 'Account URL',
  [WorkspaceTranslation.identifierPlaceholder]: 'subdomain',
  [WorkspaceTranslation.identifierRequired]: 'Account URL is required',
  [WorkspaceTranslation.identifierSubmitButton]: 'Validate',

  [WorkspaceTranslation.formFreeWorkspaceNamePlaceholderText]: 'Workspace name',
  [WorkspaceTranslation.formProWorkspaceNamePlaceholderText]: 'Workspace name',

  [WorkspaceTranslation.onboardingHelpLabel]: 'Help',
  [WorkspaceTranslation.onboardingQuickStartGuid]: 'Quick start guide',
  [WorkspaceTranslation.onboardingLatestUpdate]: 'Latest update',
  [WorkspaceTranslation.onboardingHelpCenter]: 'Help center',
  [WorkspaceTranslation.onboardingContactSupport]: 'Contact support',
  [WorkspaceTranslation.onboardingWelcomeToDesktop]:
    'Get started with Webtop.com',
  [WorkspaceTranslation.onboardingWelcomeToDesktopWithTutorial]:
    'Webtop.com start up guides',
  [WorkspaceTranslation.onboardingYourSetUpGuide]:
    'This quick start up guide is always accessible via the Help icon in the bottom left sidebar of your workspace. For a full list of guides and tutorials - please visit our <a href="{helpCenterLink}" target="_blank" rel="noopener noreferrer">Help Center</a>.',

  [WorkspaceTranslation.onboardingWatchIntroVideo]: 'Watch intro video',
  [WorkspaceTranslation.onboardingQuickImport]: 'Import bookmarks and apps',
  [WorkspaceTranslation.onboardingInviteUsersToChat]: 'Invite people to chat',
  [WorkspaceTranslation.onboardingCreateFirstDesktop]: 'Create a new desktop',
  [WorkspaceTranslation.onboardingFirstCall]: 'Make your first call',
  [WorkspaceTranslation.onboardingAddDesktop]: 'Add Webtop.com to your devices',
  [WorkspaceTranslation.onboardingGetStartedIntroVideo]:
    'Welcome to Webtop.com!',
  [WorkspaceTranslation.onboardingGetStartedIntroDesctription]:
    'This is your setup guide. You can access it any time by clicking the help icon in the bottom left sidebar',
  [WorkspaceTranslation.onboardingMoreHelpToGetStarted]: 'I need more help',

  [WorkspaceTranslation.linkAccountModalHeader]: 'Add new account',
  [WorkspaceTranslation.linkAccountNewHeading]: 'Create new account',
  [WorkspaceTranslation.linkAccountNewDescription]:
    'Select the type of account you want to create and link to your existing account',
  [WorkspaceTranslation.linkAccountNewLearnMore]: 'Learn more',
  [WorkspaceTranslation.linkAccountNewButton]: 'Create account',
  [WorkspaceTranslation.linkAccountSeparatorMessage]: 'OR',
  [WorkspaceTranslation.linkAccountExistingHeading]: 'Link an existing account',
  [WorkspaceTranslation.linkAccountExistingDescription]:
    'Already have an account you would like to link?',
  [WorkspaceTranslation.linkAccountExistingButton]: 'Link your account',
  [WorkspaceTranslation.linkAccountExistingPlanCost]:
    '<span class="cost">${cost}</span><span class="period">/mo</span>',
  [WorkspaceTranslation.linkAccountExistingPlanPerUserCaption]: 'per user',

  [WorkspaceTranslation.tooltipAddWorkspaceButton]: 'Add workspaces',
  [WorkspaceTranslation.tooltipHelpCenterButton]: 'Help center',
  [WorkspaceTranslation.tooltipFormNameLabel]:
    'Workspaces are shared environments where <br/> teams can chat, call and securely access their <br/> customized collections of apps and links.',
  [WorkspaceTranslation.tooltipFormIdentifierLabel]:
    'Your custom workspace URL lets you easily <br/> share the link with others and access your <br/> workspace straight from the search bar in your <br/> browser.',

  [WorkspaceTranslation.tooltipAdminNavUserSettingsLink]:
    'Your user preferences',
  [WorkspaceTranslation.tooltipAdminNavNotificationsSettingsLink]:
    'Your email preferences',
  [WorkspaceTranslation.tooltipAdminNavSelectWorkspace]: 'Switch workspace',
  [WorkspaceTranslation.tooltipAdminNavUserProfile]:
    'Your profile in this workspace',
  [WorkspaceTranslation.tooltipAdminNavDomains]: 'Domain name restrictions',
  [WorkspaceTranslation.tooltipAdminNavCompanyProfile]:
    'Manage your workspace settings',
  [WorkspaceTranslation.tooltipAdminNavUsers]:
    'Manage workspace members and guests',
  [WorkspaceTranslation.tooltipAdminNavGroups]: 'Manage and create groups',
  [WorkspaceTranslation.tooltipAdminDesktops]: 'Manage and create desktops',
  [WorkspaceTranslation.tooltipAdminNavBilling]:
    'Manage your billing information',
  [WorkspaceTranslation.tooltipAdminNavSso]: 'Single Sign-On configurations',
  [WorkspaceTranslation.tooltipAdminNavAppsIntegrations]:
    'Manage app integrations',
  [WorkspaceTranslation.tooltipAdminNavCustomApps]: 'Manage custom apps',
  [WorkspaceTranslation.tooltipChangeWorkspaceImage]:
    'Change the workspace image',
  [WorkspaceTranslation.tooltipAdminNavUserNotificationsLink]:
    'Manage workspace notifications',
  [WorkspaceTranslation.createLinkedUserWorkspace]: 'Create workspace',
  [WorkspaceTranslation.createLinkedUserWorkspaceAccountPlan]:
    'Choose your account plan',
};
