import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Modal } from '../Modal';
import { Button, ButtonMode, ButtonSize } from '../../Button/Button';
import {
  ConfirmModalContent,
  ConfirmModalControls,
} from './ConfirmModal.styled';
import { ConfirmOptions } from './Confirm.provider';
import { ModalHeader, ModalHeaderTitle } from '../';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { GlobalTranslation } from '../../../../domains/Intl/i18n/i18n.types';
import styled, { useTheme } from 'styled-components';
import { Input } from '../../Input';

const ConfirmTextType = styled.div.attrs({
  'data-testid': 'confirmation-text-type-label',
})`
  font-size: 0.875rem;
  .highlight {
    padding: 0 4px;
    font-family: ${({ theme }) => theme.fonts.Semibold};
    background-color: ${p => p.theme.colors.BackgroundLight};
    border-radius: 3px;
  }
  input {
    margin-top: 10px;
  }
`;

interface ConfirmModalProps {
  headerTitle?: string | React.ReactNode;
  options?: ConfirmOptions;
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
}

export const ConfirmModal: FC<PropsWithChildren<ConfirmModalProps>> = ({
  headerTitle,
  options,
  children,
  visible,
  onConfirm,
  onCancel,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const [confirmationInputValue, setConfirmationInputValue] = useState('');

  const theme = useTheme();
  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setConfirmationInputValue(event.target.value);
  }, []);

  useEffect(() => {
    setConfirmationInputValue('');
  }, [onClose, onCancel]);

  return (
    <Modal
      visible={visible}
      width={260}
      containerZIndex={theme.zIndexLevels.Confirm}
      onRequestClose={onClose}
      data-testid="modal"
      {...options}>
      {headerTitle && (
        <ModalHeader onRequestClose={onClose}>
          <ModalHeaderTitle data-testid="title">{headerTitle}</ModalHeaderTitle>
        </ModalHeader>
      )}
      <ConfirmModalContent data-testid="confirm-modal">
        {children}

        {options?.userList}

        {options?.confirmationInput && (
          <ConfirmTextType>
            <FormattedHTMLMessage
              id={GlobalTranslation.inputConfirmationMessage}
              values={{ expectedValue: options.confirmationInput }}
            />
            <Input
              value={confirmationInputValue}
              onChange={handleOnChange}
              data-testid="confirmation-text-type-input"
            />
          </ConfirmTextType>
        )}

        {options?.customControls ? (
          options?.customControls(onConfirm, onCancel)
        ) : (
          <ConfirmModalControls dangerConfirm={options?.dangerConfirm}>
            <Button
              mode={ButtonMode.secondary}
              size={ButtonSize.md}
              fullWidth
              onClick={onCancel}
              data-testid="cancel-button">
              {options?.cancelButtonText
                ? options.cancelButtonText
                : formatMessage({ id: GlobalTranslation.cancel })}
            </Button>
            <Button
              disabled={
                options?.disabled ||
                (!!options?.confirmationInput &&
                  options?.confirmationInput !== confirmationInputValue)
              }
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              fullWidth
              onClick={onConfirm}
              data-testid="confirm-button">
              {options?.confirmButtonText
                ? options.confirmButtonText
                : formatMessage({ id: GlobalTranslation.confirm })}
            </Button>
          </ConfirmModalControls>
        )}
      </ConfirmModalContent>
    </Modal>
  );
};
