import { database } from '../../../../../../Database';
import { DesktopLinksTableType } from '../../../../../../Database/DesktopLinksTable/DesktopLinksTable';

export const favoriteLinkRequestRemove = (linkNode: DesktopLinksTableType) => {
  database.desktopLinks
    .update(linkNode.id, {
      accountFavorite: linkNode.accountFavorite,
      'sourceData.accountFavorite': linkNode.accountFavorite,
    })
    //Add element to database if update return 0 https://dexie.org/docs/Table/Table.update()
    .then(updated => {
      if (!updated) {
        database.desktopLinks.put(linkNode).catch(error => {
          console.error(error);
        });
      }
    });
};
