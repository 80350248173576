import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const CopyIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 3C4.28587 3 3.84075 3.18437 3.51256 3.51256C3.18437 3.84075 3 4.28587 3 4.75V14.25C3 15.216 3.784 16 4.75 16H6.25C6.44891 16 6.63968 15.921 6.78033 15.7803C6.92098 15.6397 7 15.4489 7 15.25C7 15.0511 6.92098 14.8603 6.78033 14.7197C6.63968 14.579 6.44891 14.5 6.25 14.5H4.75C4.6837 14.5 4.62011 14.4737 4.57322 14.4268C4.52634 14.3799 4.5 14.3163 4.5 14.25V4.75C4.5 4.6837 4.52634 4.62011 4.57322 4.57322C4.62011 4.52634 4.6837 4.5 4.75 4.5H14.25C14.3163 4.5 14.3799 4.52634 14.4268 4.57322C14.4737 4.62011 14.5 4.6837 14.5 4.75V6.25C14.5 6.44891 14.579 6.63968 14.7197 6.78033C14.8603 6.92098 15.0511 7 15.25 7C15.4489 7 15.6397 6.92098 15.7803 6.78033C15.921 6.63968 16 6.44891 16 6.25V4.75C16 4.28587 15.8156 3.84075 15.4874 3.51256C15.1592 3.18437 14.7141 3 14.25 3H4.75ZM9.75 8C9.28587 8 8.84075 8.18437 8.51256 8.51256C8.18437 8.84075 8 9.28587 8 9.75V19.25C8 20.216 8.784 21 9.75 21H19.25C19.7141 21 20.1592 20.8156 20.4874 20.4874C20.8156 20.1592 21 19.7141 21 19.25V9.75C21 9.28587 20.8156 8.84075 20.4874 8.51256C20.1592 8.18437 19.7141 8 19.25 8H9.75ZM9.5 9.75C9.5 9.6837 9.52634 9.62011 9.57322 9.57322C9.62011 9.52634 9.6837 9.5 9.75 9.5H19.25C19.3163 9.5 19.3799 9.52634 19.4268 9.57322C19.4737 9.62011 19.5 9.6837 19.5 9.75V19.25C19.5 19.3163 19.4737 19.3799 19.4268 19.4268C19.3799 19.4737 19.3163 19.5 19.25 19.5H9.75C9.6837 19.5 9.62011 19.4737 9.57322 19.4268C9.52634 19.3799 9.5 19.3163 9.5 19.25V9.75Z"
        fill="currentColor"
      />
    </svg>
  );
});
