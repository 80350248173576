import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DocumentIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="document-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M1.75 2.30029C1.6837 2.30029 1.62011 2.32663 1.57322 2.37351C1.52634 2.4204 1.5 2.48399 1.5 2.55029V18.0503C1.5 18.1883 1.612 18.3003 1.75 18.3003H14.25C14.3163 18.3003 14.3799 18.274 14.4268 18.2271C14.4737 18.1802 14.5 18.1166 14.5 18.0503V7.80029H10.75C10.2859 7.80029 9.8408 7.61592 9.5126 7.28773C9.1844 6.95954 9 6.51442 9 6.05029V2.30029H1.75ZM10.5 2.86029V6.05029C10.5 6.18829 10.612 6.30029 10.75 6.30029H13.94L10.5 2.86029ZM0 2.55029C0 1.58429 0.784 0.800293 1.75 0.800293H9.836C10.3 0.800293 10.745 0.984293 11.073 1.31329L15.487 5.72729C15.816 6.05529 16 6.50029 16 6.96429V18.0503C16 18.2801 15.9547 18.5077 15.8668 18.72C15.7788 18.9323 15.6499 19.1252 15.4874 19.2877C15.3249 19.4502 15.132 19.5791 14.9197 19.6671C14.7074 19.755 14.4798 19.8003 14.25 19.8003H1.75C1.28587 19.8003 0.84075 19.6159 0.51256 19.2877C0.18437 18.9595 0 18.5144 0 18.0503V2.55029Z"></path>
    </svg>
  );
});
