export const waitForOnlineStatus = () => {
  return new Promise(resolve => {
    if (navigator.onLine) {
      resolve(true);
    } else {
      window.addEventListener('online', function onlineListener() {
        resolve(true);
        window.removeEventListener('online', onlineListener);
      });
    }
  });
};
