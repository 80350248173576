import { StreamFavoriteEvent } from '../../../GeneralMercure.types';
import { DesktopAppApiTypeToDesktopAppsTableRow } from '../../../../../Database/DesktopAppsTable/DesktopAppsTable.utils';
import { LinksApiTypeToDesktopLinksTableRow } from '../../../../../Database/DesktopLinksTable/DesktopLinksTable.utils';
import { FolderApiTypeToDesktopFolderTableRow } from '../../../../../Database/DesktopFoldersTable/DesktopFoldersTable.utils';
import { FAVORITES_TYPES } from '../FavoriteRequest';

export const makeFavoriteFromEvent = (event: StreamFavoriteEvent) => {
  if (event.desktopApp) {
    const { desktopId, workspaceId } = event.desktopApp;
    return {
      dataType: FAVORITES_TYPES.app,
      data: DesktopAppApiTypeToDesktopAppsTableRow(
        event.desktopApp,
        desktopId,
        workspaceId,
      ),
    };
  }

  if (event.link) {
    const { workspaceId } = event.link;
    return {
      dataType: FAVORITES_TYPES.link,
      data: LinksApiTypeToDesktopLinksTableRow(event.link, workspaceId),
    };
  }

  if (event.folder) {
    const { workspaceId } = event.folder;
    return {
      dataType: FAVORITES_TYPES.folder,
      data: FolderApiTypeToDesktopFolderTableRow(
        { ...event.folder, desktop: { id: event.folder.desktopId } },
        workspaceId,
      ),
    };
  }
};
