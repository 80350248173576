import styled, { css } from 'styled-components';

export const StyledFormSelect = styled.div<{
  invalid?: boolean;
  inlineError?: boolean;
}>`
  ${({ inlineError }) => inlineError && `position: relative;`}

  .error {
    margin-right: 1rem;
  }

  label {
    display: block;
    margin-bottom: 0.25rem;
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 0.875rem;
    line-height: 1.25;
    cursor: pointer;
  }

  ${({ invalid, theme }) =>
    invalid &&
    css`
      select {
        border-color: ${theme.colors.CriticalLight} !important;
      }
    `}
`;
