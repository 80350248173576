import { gql } from '@apollo/client';
import { LinkFragmentFields } from '../../../../Link/Link.fragments';

export const GET_DESKTOP_FOLDER_LINKS_REST = gql`
  query GetDesktopLinksRest($desktopId: ID!, $folderId: ID!) {
    desktopLinks(desktopId: $desktopId, folderId: $folderId)
      @rest(path: "/desktop/{args.desktopId}/folder/{args.folderId}/links")
      @connection(key: "desktop_folder_links_rest", filter: ["args.desktopId", "args.folderId"]) {
        edges {
          node {
            ${LinkFragmentFields}
          }
        }
    }
  }
`;

export interface GetDesktopFolderLinksRestVariables {
  desktopId: string;
  folderId: string;
}
