import { gql } from '@apollo/client';
import {
  GRAPHQL_TYPENAME_MENTIONS_CONNECTION,
  GRAPHQL_TYPENAME_MENTIONS_EDGE,
} from './Inbox.constants';
import { PageInfoApiType } from '../../shared/api/api.types';
import { MentionsApiType, ThreadNotificationApiType } from './Inbox.types';

export const GET_MENTIONS = gql`
  query Notifications(
    $accountId: String
    $workspaceId: String
    $cursor: String
    $first: Int
  ) {
    notifications(
      filterOnlyMentions: true
      recipient: $accountId
      workspace: $workspaceId
      first: $first
      after: $cursor
      order: { createdAt: "desc" }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          _id
          status
          message {
            id
            fields
            type
          }
          createdAt
        }
      }
    }
  }
`;

export interface GetMentionsVariables {
  accountId: string;
  workspaceId: string;
  cursor?: string;
  first: number;
}

export interface GetMentionsResponse {
  notifications: {
    __typename: typeof GRAPHQL_TYPENAME_MENTIONS_CONNECTION;
    pageInfo: PageInfoApiType;
    edges: Array<{
      node: MentionsApiType;
      __typename: typeof GRAPHQL_TYPENAME_MENTIONS_EDGE;
    }>;
  };
}

export const GET_THREADS = gql`
  query Notifications(
    $accountId: String
    $workspaceId: String
    $cursor: String
    $first: Int
  ) {
    notifications(
      filterOnlyThreads: true
      recipient: $accountId
      workspace: $workspaceId
      first: $first
      after: $cursor
      order: { createdAt: "desc" }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          _id
          status
          message {
            id
            fields
            type
          }
          createdAt
        }
      }
    }
  }
`;

export interface GetThreadsVariables {
  accountId: string;
  workspaceId: string;
  cursor?: string;
  first: number;
}

export interface GetThreadsResponse {
  notifications: {
    __typename: typeof GRAPHQL_TYPENAME_MENTIONS_CONNECTION;
    pageInfo: PageInfoApiType;
    edges: Array<{
      node: ThreadNotificationApiType;
      __typename: typeof GRAPHQL_TYPENAME_MENTIONS_EDGE;
    }>;
  };
}
