import { gql } from '@apollo/client';
import { ChatConversationFields } from '../../../../Chat/Chat.fragments';
import { ChatConversationApiType } from '../../../../Chat/Chat.types';

export const GET_DESKTOP_CHAT_REST = gql`
  query GetDesktopChatRest($desktopId: ID!) {
    desktopChat(desktopId: $desktopId)
      @rest(path: "/desktop/{args.desktopId}/chat")
      @connection(key: "desktop_chat_rest", filter: ["args.desktopId"]) {
      chatConversation {
        ${ChatConversationFields}
      }
    }
  }
`;

export interface GetDesktopChatRestVariables {
  desktopId: string;
}

export interface GetDesktopChatRestResponse {
  desktopChat: {
    chatConversation: ChatConversationApiType;
  };
}
