import { getRestApiClient } from '../../../../Api/Api.utils';
import type {
  AccountMercureInfoApiType,
  UnreadNotificationsCountApiType,
} from '../../../../User/User.types';
import type {
  AccountChatUnreadRestApiType,
  AccountInboxUnreadRestApiType,
  AccountMeetingsUnreadRestApiType,
} from '../types/CurrentAccountApi.types';
import { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import { WorkspaceInfoApiType } from '../../../../WorkspaceInfo/data/WorkspaceInfo/types/WorkspaceInfo.types';

/* CURRENT ACCOUNT */

export const getCurrentAccountApi = () => {
  const restApiClient = getRestApiClient();
  return restApiClient!.fetch('/myaccount');
};

export const getCurrentAccountUnreadNotificationsApi = (): Promise<
  RestApiClientResponse<UnreadNotificationsCountApiType>
> => {
  const restApiClient = getRestApiClient();

  return restApiClient!.fetch('/myaccount/unread/notifications');
};

export const getCurrentAccountInboxUnreadApi = (): Promise<
  RestApiClientResponse<AccountInboxUnreadRestApiType>
> => {
  const restApiClient = getRestApiClient();

  return restApiClient!.fetch('/myaccount/unread/inbox');
};

export const getCurrentAccountChatUnreadApi = (): Promise<
  RestApiClientResponse<AccountChatUnreadRestApiType[]>
> => {
  const restApiClient = getRestApiClient();

  return restApiClient!.fetch('/myaccount/unread/chat');
};

export const getCurrentAccountChatUnreadByWorkspaceApi = (
  workspaceId: string,
): Promise<RestApiClientResponse<AccountChatUnreadRestApiType>> => {
  const restApiClient = getRestApiClient();

  return restApiClient!.fetch(`/myaccount/unread/chat${workspaceId}`);
};

export const getCurrentAccountUnreadChatConferencesApi = (): Promise<
  RestApiClientResponse<AccountMeetingsUnreadRestApiType>
> => {
  const restApiClient = getRestApiClient();

  return restApiClient!.fetch('/myaccount/unread/meetings');
};

export const getCurrentAccountMercureInfoApi = (): Promise<
  RestApiClientResponse<AccountMercureInfoApiType | undefined>
> => {
  const restApiClient = getRestApiClient();

  return restApiClient!.fetch('/myaccount/info/mercure');
};

export const getCurrentAccountWorkspaceInfoApi = (): Promise<
  RestApiClientResponse<WorkspaceInfoApiType[]>
> => {
  const restApiClient = getRestApiClient();

  return restApiClient!.fetch('/myaccount/info/workspace');
};
