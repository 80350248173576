export const STORAGE_NAME = 'Webtop.com';
export const STORAGE_STORE_NAME = 'persisted-data-storage';

export const PERSISTED_STORAGE_KEY_APOLLO_CACHE = 'apollo-cache-persist';
export const PERSISTED_STORAGE_KEY_AUTH_CONFIG = 'auth/config';
export const PERSISTED_STORAGE_KEY_AUTHENTICATED_ACCOUNT = 'auth/account';

export const KEY_TO_STORAGE_KEY_MAP = {
  authConfig: PERSISTED_STORAGE_KEY_AUTH_CONFIG,
  authenticatedAccount: PERSISTED_STORAGE_KEY_AUTHENTICATED_ACCOUNT,
};
