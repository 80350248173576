import { WORKSPACE_PATHNAME } from '../Workspace/Workspace.constants';
import { DesktopPermissionsContextApiType } from './data/Desktop/types/Desktop.types';

export enum DesktopTab {
  apps = 'apps',
  links = 'links',
  chat = 'chat',
}

export enum DesktopType {
  INTERNAL = 'internal',
  SHARED = 'shared',
  PRIVATE = 'private',
}

export enum AppLoginMethod {
  PWM = 'PWM',
  SSO = 'SSO',
  DEFAULT = 'DEFAULT',
}

export enum DesktopLayout {
  TABBED = 'tabbed',
  CONSOLIDATED = 'consolidated',
}

export const DESKTOP_CONTENT_PRELOAD_BATCH_SIZE = 2;

export const LAST_OPENED_DESKTOPS_MAP_STORAGE_KEY = 'last_opened_desktops';
export const INITIAL_SIDEBAR_OPEN_KEY = 'sidebar_open';
export const DESKTOP_LAYOUT_KEY = 'desktop:layout:';

export const ADMIN_PANEL_PATHNAME = '/admin';

const COMPANY_DESKTOPS_ROOT_PATHNAME = `${WORKSPACE_PATHNAME}${ADMIN_PANEL_PATHNAME}/desktops`;
export const COMPANY_DESKTOP_ID_PATHNAME = `${COMPANY_DESKTOPS_ROOT_PATHNAME}/:desktopId`;

export const DESKTOP_ROOT_SLUG = `desktop`;
export const DESKTOP_ROOT_ROUTE_PATH = `${DESKTOP_ROOT_SLUG}`;
export const DESKTOP_ROOT_PATHNAME = `${WORKSPACE_PATHNAME}/${DESKTOP_ROOT_SLUG}`;
export const DESKTOP_ID_SLUG = `:desktopId`;
export const DESKTOP_ID_PATHNAME = `${DESKTOP_ROOT_PATHNAME}/${DESKTOP_ID_SLUG}`;
export const DESKTOP_CHAT_ID_PATHNAME = `${DESKTOP_ID_PATHNAME}/chat`;

export const DESKTOP_TAB_SLUG = `:tab`;
export const DESKTOP_TAB_PATHNAME = `${DESKTOP_ID_PATHNAME}/${DESKTOP_TAB_SLUG}`;

export const FAVORITES_ROOT_SLUG = `favorites`;
export const FAVORITES_ROOT_ROUTE_PATH = `${FAVORITES_ROOT_SLUG}`;
export const FAVORITES_ROOT_PATHNAME = `${WORKSPACE_PATHNAME}/${FAVORITES_ROOT_SLUG}`;
export const FAVORITES_TAB_SLUG = `:tab`;
export const FAVORITES_TAB_PATHNAME = `${FAVORITES_ROOT_PATHNAME}/${FAVORITES_TAB_SLUG}`;

export const PASSWORDS_ROOT_SLUG = `passwords`;
export const PASSWORDS_ROOT_ROUTE_PATH = `${PASSWORDS_ROOT_SLUG}`;
export const PASSWORDS_ROOT_PATHNAME = `${WORKSPACE_PATHNAME}/${PASSWORDS_ROOT_SLUG}`;
export const PASSWORDS_VAULT_SLUG = `:vaultId`;
export const PASSWORDS_VAULT_PATHNAME = `${PASSWORDS_ROOT_PATHNAME}/${PASSWORDS_VAULT_SLUG}`;

export const CHATS_ROOT_PATHNAME = `${WORKSPACE_PATHNAME}/chats`;
export const CHATS_CONVERSATION_PATHNAME_SLUG = `:conversationId`;
export const CHATS_CONVERSATION_PATHNAME = `${CHATS_ROOT_PATHNAME}/${CHATS_CONVERSATION_PATHNAME_SLUG}`;
export const CHATS_FIND_ACCOUNT_URL_PREFIX = `find-account`;
export const CHATS_FIND_ACCOUNT_CHAT_SLUG = `${CHATS_FIND_ACCOUNT_URL_PREFIX}/:accountId`;
export const CHATS_FIND_ACCOUNT_CHAT_PATHNAME = `${CHATS_ROOT_PATHNAME}/${CHATS_FIND_ACCOUNT_URL_PREFIX}/:accountId`;

export const CONFERENCES_ROOT_PATHNAME = `${WORKSPACE_PATHNAME}/calls`;
export const CONFERENCES_CONFERENCE_PATHNAME = `${CONFERENCES_ROOT_PATHNAME}/:conferenceId`;

export const CUSTOM_INTEGRATION_PATHNAME = `${WORKSPACE_PATHNAME}${ADMIN_PANEL_PATHNAME}/apps-integrations/custom-integration/:integrationId`;

export const GRAPHQL_TYPENAME_DESKTOP = 'Desktop';
export const GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION = 'DesktopAppConnection';
export const GRAPHQL_TYPENAME_DESKTOP_APP_EDGE = 'DesktopAppEdge';
export const GRAPHQL_TYPENAME_DESKTOP_ACCESS = 'DesktopAccess';
export const GRAPHQL_TYPENAME_DESKTOP_APP = 'DesktopApp';
export const GRAPHQL_TYPENAME_APP = 'App';

export const GRAPHQL_TYPENAME_FAVORITE_CONNECTION = 'FavoriteConnection';
export const GRAPHQL_TYPENAME_FAVORITE_EDGE = 'FavoriteEdge';
export const GRAPHQL_TYPENAME_FAVORITE = 'Favorite';

export const GRAPHQL_TYPENAME_RECENTAPPS = 'RecentApps';

export const NO_PERMISSIONS: DesktopPermissionsContextApiType = {
  canShare: false,
  canAddApp: false,
  canAddLink: false,
  canEnableChat: false,
  canRemove: false,
  canRemoveLink: false,
  canEdit: false,
  canView: false,
  canRemoveApp: false,
  canEditLink: false,
  canViewFolder: false,
  canCreateFolder: false,
  canEditFolder: false,
  canRemoveFolder: false,
  canLeave: false,
  canHideChat: false,
  canCommentLinks: false,
  canCopyDesktopAppsSection: false,
  canCopyDesktopLinksSection: false,
  canPasteDesktopAppsSection: false,
  canPasteDesktopLinksSection: false,
  canDeleteDesktopAppsSection: false,
  canDeleteDesktopLinksSection: false,
};

export const EDIT_LINK_MODAL_Z_INDEX = 80;

export const DESKTOP_SECTION_COPY_LINKS_KEY = 'copy-link-section';
export const DESKTOP_SECTION_COPY_APPS_KEY = 'copy-app-section';
