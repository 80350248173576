import { database } from '../../../../Database';
import {
  addEntityIdsToIDDBRelatedEntitiesList,
  removeEntityIdsFromIDDBRelatedEntitiesList,
} from '../../../../../shared/utils/iddb.utils';

export const addRelatedTeamsToDesktopsIDDB = async (
  desktopIds: string[] = [],
  teamIds: string[] = [],
) => {
  try {
    return database.desktops
      .where('id')
      .anyOf(desktopIds)
      .modify((value, ref) => {
        ref.value = {
          ...value,
          relatedTeams: addEntityIdsToIDDBRelatedEntitiesList(
            value.relatedTeams,
            teamIds,
          ),
        };
      });
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};

export const removeRelatedTeamsFromDesktopsIDDB = async (
  desktopIds: string[] = [],
  teamIds: string[] = [],
) => {
  try {
    return database.desktops
      .where('id')
      .anyOf(desktopIds)
      .modify((value, ref) => {
        ref.value = {
          ...value,
          relatedTeams: removeEntityIdsFromIDDBRelatedEntitiesList(
            value.relatedTeams,
            teamIds,
          ),
        };
      });
  } catch (e) {
    console.error(e);
    return Promise.resolve();
  }
};
