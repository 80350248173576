import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const AddFolderIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 4.5C3.6837 4.5 3.62011 4.52634 3.57322 4.57322C3.52634 4.62011 3.5 4.6837 3.5 4.75V19.25C3.5 19.388 3.612 19.5 3.75 19.5H20.25C20.3163 19.5 20.3799 19.4737 20.4268 19.4268C20.4737 19.3799 20.5 19.3163 20.5 19.25V7.687C20.5 7.6207 20.4737 7.55711 20.4268 7.51022C20.3799 7.46334 20.3163 7.437 20.25 7.437H11.779C11.4937 7.43709 11.2128 7.36743 10.9606 7.2341C10.7084 7.10076 10.4926 6.9078 10.332 6.672L8.928 4.61C8.90502 4.576 8.87403 4.54818 8.83775 4.529C8.80148 4.50982 8.76104 4.49986 8.72 4.5H3.75ZM2 4.75C2 3.784 2.784 3 3.75 3H8.721C9.301 3 9.841 3.286 10.168 3.765L11.572 5.828C11.5949 5.86186 11.6257 5.88959 11.6618 5.90877C11.6979 5.92794 11.7381 5.93798 11.779 5.938H20.25C21.216 5.938 22 6.721 22 7.688V19.25C22 19.7141 21.8156 20.1592 21.4874 20.4874C21.1592 20.8156 20.7141 21 20.25 21H3.75C3.28587 21 2.84075 20.8156 2.51256 20.4874C2.18437 20.1592 2 19.7141 2 19.25V4.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9C12.4142 9 12.75 9.33579 12.75 9.75V12.25H15.25C15.6642 12.25 16 12.5858 16 13C16 13.4142 15.6642 13.75 15.25 13.75H12.75V16.25C12.75 16.6642 12.4142 17 12 17C11.5858 17 11.25 16.6642 11.25 16.25V13.75H8.75C8.33579 13.75 8 13.4142 8 13C8 12.5858 8.33579 12.25 8.75 12.25H11.25V9.75C11.25 9.33579 11.5858 9 12 9Z"
        fill="currentColor"
      />
    </svg>
  );
});
