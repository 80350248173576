import React, { FC, PropsWithChildren, useCallback } from 'react';
import {
  SidebarHeaderTitle,
  StyledSidebarHeader,
} from './DesktopSidebarHeader.styled';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { CloseIcon } from '../../../../shared/icons';
import { useDesktopSidebar } from '..';

export const DesktopSidebarHeader: FC<PropsWithChildren> = ({ children }) => {
  const desktopSidebar = useDesktopSidebar();
  const setIdentifier = useCallback(() => {
    desktopSidebar.setIdentifier(null);
  }, [desktopSidebar]);

  return (
    <StyledSidebarHeader data-testid="sidebar-header">
      <SidebarHeaderTitle data-testid="header-title">
        {children}
      </SidebarHeaderTitle>
      <ButtonWithIcon
        icon={CloseIcon}
        onClick={setIdentifier}
        data-testid="close-button"
      />
    </StyledSidebarHeader>
  );
};
