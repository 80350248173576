import { getLongId } from '../../../shared/utils/id';
import { ConversationType } from '../../Chat/Chat.types';
import type {
  ChatConversationApiType,
  ChatConversationInternalType,
  ChatConversationUserContext,
} from '../../Chat/Chat.types';
import type { DesktopApiType } from '../../Desktop/data/Desktop/types/Desktop.types';
import type { ChatConversationsTableType } from './ChatConversationsTable';

/**
 * Converts a ChatConversationInternalType to ChatConversationsTableRow
 * @param conversation
 * @returns ChatConversationsTableRow
 */
export const ChatConversationInternalToChatConversationsTableRow = (
  conversation: ChatConversationInternalType,
): ChatConversationsTableType => ({
  id: conversation.id,
  workspaceId: conversation.workspaceId,
  createdAt: conversation.createdAt,
  title: conversation.title,
  type: conversation.type,
  userIds: conversation.userIds,
  lastMessageAt: conversation.lastMessageAt,
  createdById: conversation.createdById,
  pendingEmails: conversation.pendingEmails,
  desktopId: conversation.desktopId,
  pinnedAt: conversation.pinnedAt,
  isHidden: conversation.isHidden,
  isHiddenAt: conversation.isHiddenAt,
});

export const ChatConversationApiToChatConversationsTableRow = (
  conversation: ChatConversationApiType,
  userContext: ChatConversationUserContext,
): ChatConversationsTableType => ({
  id: getLongId('chat-conversations', conversation.id),
  workspaceId: getLongId('workspaces', userContext.workspaceId),
  createdAt: conversation.createdAt,
  title: conversation.conversationTitle,
  type: conversation.type,
  userIds: conversation.users,
  lastMessageAt: conversation.lastMessageAt,
  createdById: conversation.createdByIri,
  pendingEmails: conversation.pendingEmails,
  desktopId: conversation.desktopIri
    ? getLongId('desktops', conversation.desktopIri)
    : null,
  pinnedAt:
    conversation.accountsThatPinned.find(
      user => user.accountIri === userContext.accountId,
    )?.pinnedAt || null,
  isHidden: !!conversation.accountsThatHidden?.find?.(
    user => user.accountIri === userContext.accountId,
  )?.hiddenAt,
  isHiddenAt:
    conversation.accountsThatHidden?.find?.(
      user => user.accountIri === userContext.accountId,
    )?.hiddenAt || null,
});

export const ChatConversationEventTypeToChatConversationsTableRow = (
  desktop: DesktopApiType,
  workspaceId: string,
): ChatConversationsTableType => ({
  id: desktop.chatConversationIri,
  createdAt: desktop.createdAt,
  type: ConversationType.desktop,
  userIds: desktop.accountIds,
  title: desktop.name,
  lastMessageAt: null,
  pendingEmails: [],
  desktopId: desktop.id,
  pinnedAt: null,
  workspaceId,
  createdById: desktop.createdBy?.id || '',
  isHidden: false,
  isHiddenAt: null,
});
