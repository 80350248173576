export enum WorkspaceTranslation {
  menuProfileLink = 'workspace_menuProfileLink',
  menuSettingsLink = 'workspace_menuSettingsLink',
  menuCreateLinkWorkspace = 'workspace_menuCreateLinkWorkspace',
  menuLogoutLink = 'workspace_menuLogoutLink',
  workspaceSelectorTooltip = 'workspace_workspaceSelectorTooltip',
  workspaceSelectorAdditionalNotifications = 'workspace_workspaceSelectorAdditionalNotifications',

  noAccessMessage = 'workspace_noAccessMessage',
  noWorkspaces = 'workspace_noWorkspaces',
  billingNotOk = 'workspace_billingNotOk',
  billingNotOkOwner = 'workspace_billingNotOkOwner',

  featureForWorkspaceMembers = 'workspace_featureForWorkspaceMembers',

  adminNavGroupGeneralHeader = 'workspace_adminNavGroupGeneralHeader',
  adminNavGroupAccountHeader = 'workspace_adminNavGroupAccountHeader',
  adminHeaderTitle = 'workspace_adminHeaderTitle',
  adminHeaderCloseButton = 'workspace_adminHeaderCloseButton',
  adminNavUserProfileLink = 'workspace_adminNavUserProfileLink',
  adminNavDomainsLink = 'workspace_adminNavDomainsLink',
  adminNavNotificationSettingsLink = 'workspace_adminNavNotificationSettingsLink',
  adminNavUserSettingsLink = 'workspace_adminNavUserSettingsLink',
  adminNavCompanyProfileLink = 'workspace_adminNavCompanyProfileLink',
  adminNavUsersLink = 'workspace_adminNavUsersLink',
  adminNavTeamsLink = 'workspace_adminNavTeamsLink',
  adminNavDesktopsLink = 'workspace_adminNavDesktopsLink',
  adminNavBillingLink = 'workspace_adminNavBillingLink',
  adminNavUserNotificationsLink = 'workspace_adminNavUserNotificationsLink',
  adminNavUserNotificationsLinkSubTitle = 'workspace_adminNavUserNotificationsLinkSubTitle',
  adminNavUserNotificationsSounds = 'workspace_adminNavUserNotificationsSounds',
  adminNavUserNotificationsIntegrationsSounds = 'workspace_adminNavUserNotificationsIntegrationsSounds',
  adminNavUserNotificationsIntegrations = 'workspace_adminNavUserNotificationsIntegrations',
  adminNavUserNotificationsIntegrationsSubTitle = 'workspace_adminNavUserNotificationsIntegrationsSubTitle',
  adminNavUserNotificationsListIsEmpty = 'workspace_adminNavUserNotificationsListIsEmpty',
  adminSwitchToWorkspaceHeading = 'workspace_adminSwitchToWorkspaceHeading',

  companyRoleDirector = 'workspace_companyRoleDirector',
  companyRoleSalesAndMarketing = 'workspace_companyRoleSalesAndMarketing',
  companyRoleTechnology = 'workspace_companyRoleTechnology',
  companyRoleHumanResources = 'workspace_companyRoleHumanResources',
  companyRoleOther = 'workspace_companyRoleOther',

  formNameLabel = 'workspace_formNameLabel',
  formNameRequiredError = 'workspace_formNameRequiredError',
  formIdentifierLabel = 'workspace_formIdentifierLabel',
  formIdentifierRequiredError = 'workspace_formIdentifierRequiredError',
  formCompanySizeLabel = 'workspace_formCompanySizeLabel',
  formRoleLabel = 'workspace_formRoleLabel',
  formDescriptionLabel = 'workspace_formDescriptionLabel',
  formEmailLabel = 'workspace_formEmailLabel',
  formEmailSubLabel = 'workspace_formEmailSubLabel',
  formEmailError = 'workspace_formEmailError',
  formUrlLabel = 'workspace_formUrlLabel',
  formUrlPlaceholder = 'workspace_formUrlPlaceholder',
  formSubdomainLabel = 'workspace_formSubdomainLabel',
  formUrlError = 'workspace_formUrlError',
  formMfaLabel = 'workspace_formMfaLabel',
  formMfaDescription = 'workspace_formMfaDescription',
  formMfaConfirmTitle = 'workspace_formMfaConfirmTitle',
  formMfaConfirmMessage = 'workspace_formMfaConfirmMessage',
  formMfaConfirmButton = 'workspace_formMfaConfirmButton',
  formSaveButton = 'workspace_formSaveButton',
  formCancelButton = 'workspace_formCancelButton',
  formContinueButton = 'workspace_formContinueButton',
  workspaceSettingsPageTitle = 'workspace_workspaceSettingsPageTitle',
  workspaceSettingsInfoTitle = 'workspace_workspaceSettingsInfoTitle',
  workspaceSettingsNoDescription = 'workspace_workspaceSettingsNoDescription',
  workspaceSettingsInfoButton = 'workspace_workspaceSettingsInfoButton',
  workspaceSettingsEditModalHeader = 'workspace_workspaceSettingsEditModalHeader',
  editFormUploadImageButton = 'workspace_editFormUploadImageButton',
  editFormChangeImageButton = 'workspace_editFormChangeImageButton',
  editFormRemoveImageButton = 'workspace_editFormRemoveImageButton',
  formSuccessMessage = 'workspace_formSuccessMessage',
  formSubdomainWarning = 'workspace_formSubdomainWarning',
  formSubdomainErrorMessage = 'workspace_formSubdomainErrorMessage',

  formFeaturesLabel = 'workspace_formFeaturesLabel',
  formFeaturesDescription = 'workspace_formFeaturesDescription',
  formFeatureOrganizeLabel = 'workspace_formFeatureOrganizeLabel',
  formFeatureOrganizeDescription = 'workspace_formFeatureOrganizeDescription',
  formFeatureCommunicateLabel = 'workspace_formFeatureCommunicateLabel',
  formFeatureCommunicateDescription = 'workspace_formFeatureCommunicateDescription',
  formFeatureSelectAtLeastOne = 'workspace_formFeatureSelectAtLeastOne',
  formFeatureDeactivateConfirmationTitle = 'workspace_formFeatureDeactivateConfirmationTitle',
  formFeatureDeactivateConfirmationBody = 'workspace_formFeatureDeactivateConfirmationBody',
  formFeatureDeactivateConfirmationMessage = 'workspace_formFeatureDeactivateConfirmationMessage',

  mfaModalTitle = 'workspace_mfaModalTitle',
  mfaModalText = 'workspace_mfaModalText',
  mfaModalCancelButton = 'workspace_mfaModalCancelButton',
  mfaModalConfirmButton = 'workspace_mfaModalConfirmButton',

  dangerZoneTitle = 'workspace_dangerZoneTitle',
  dangerZoneDeleteAccountButton = 'workspace_dangerZoneDeleteAccountButton',
  dangerZoneConfirmTitle = 'workspace_dangerZoneConfirmTitle',
  dangerZoneConfirmWarning = 'workspace_dangerZoneConfirmWarning',
  dangerZoneConfirmMessage = 'workspace_dangerZoneConfirmMessage',

  identifierModalLabel = 'workspace_identifierModalLabel',
  identifierWorkspaceNameLabel = 'workspace_identifierWorkspaceNameLabel',
  identifierWorkspaceNamePlaceholder = 'workspace_identifierWorkspaceNamePlaceholder',
  identifierWorkspaceNameRequired = 'workspace_identifierWorkspaceNameRequired',
  identifierLabel = 'workspace_identifierLabel',
  identifierPlaceholder = 'workspace_identifierPlaceholder',
  identifierRequired = 'workspace_identifierRequired',
  identifierSubmitButton = 'workspace_identifierSubmitButton',

  formFreeWorkspaceNamePlaceholderText = 'workspace_formFreeWorkspaceNamePlaceholderText',
  formProWorkspaceNamePlaceholderText = 'workspace_formProWorkspaceNamePlaceholderText',

  onboardingHelpLabel = 'workspace_onboardingHelpLabel',
  onboardingQuickStartGuid = 'workspace_onboardingGuickStartGuid',
  onboardingLatestUpdate = 'workspace_onboardingLatestUpdate',
  onboardingHelpCenter = 'workspace_onboardingHelpCenter',
  onboardingContactSupport = 'workspace_onboardingContactSupport',
  onboardingWelcomeToDesktop = 'workspace_onboardingWelcomeToDesktop',
  onboardingWelcomeToDesktopWithTutorial = 'workspace_onboardingWelcomeToDesktopWithTutorial',
  onboardingYourSetUpGuide = 'workspace_onboardingYourSetUpGuide',
  onboardingWatchIntroVideo = 'workspace_onboardingWatchIntroVideo',
  onboardingQuickImport = 'workspace_onboardingQuickImport',
  onboardingInviteUsersToChat = 'workspace_onboardingInviteUsersToChat',
  onboardingCreateFirstDesktop = 'workspace_onboardingCreateFirstDesktop',
  onboardingFirstCall = 'workspace_onboardingFirstCall',
  onboardingAddDesktop = 'workspace_onboardingAddDesktop',
  onboardingGetStartedIntroVideo = 'workspace_onboardingGetStartedIntroVideo',
  onboardingMoreHelpToGetStarted = 'workspace_onboardingMoreHelpToGetStarted',
  onboardingGetStartedIntroDesctription = 'workspace_onboardingGetStartedIntroDesctription',

  linkAccountModalHeader = 'workspace_linkAccountModalHeader',
  linkAccountNewHeading = 'workspace_linkAccountNewHeading',
  linkAccountNewDescription = 'workspace_linkAccountNewDescription',
  linkAccountNewLearnMore = 'workspace_linkAccountNewLearnMore',
  linkAccountNewButton = 'workspace_linkAccountNewButton',
  linkAccountSeparatorMessage = 'workspace_linkAccountSeparatorMessage',
  linkAccountExistingButton = 'workspace_linkAccountExistingButton',
  linkAccountExistingHeading = 'workspace_linkAccountExistingHeading',
  linkAccountExistingDescription = 'workspace_linkAccountExistingDescription',
  linkAccountExistingPlanCost = 'workspace_linkAccountExistingPlanCost',
  linkAccountExistingPlanPerUserCaption = 'workspace_linkAccountExistingPlanPerUserCaption',

  tooltipAddWorkspaceButton = 'workspace_tooltipAddWorkspaceButton',
  tooltipHelpCenterButton = 'workspace_tooltipHelpCenterButton',
  tooltipFormNameLabel = 'workspace_tooltipFormNameLabel',
  tooltipFormIdentifierLabel = 'workspace_tooltipFormIdentifierLabel',

  tooltipAdminNavUserSettingsLink = 'workspace_tooltipAdminNavUserSettingsLink',
  tooltipAdminNavNotificationsSettingsLink = 'workspace_tooltipAdminNavNotificationsSettingsLink',
  tooltipAdminNavSelectWorkspace = 'workspace_tooltipAdminNavSelectWorkspace',
  tooltipAdminNavUserProfile = 'workspace_tooltipAdminNavUserProfile',
  tooltipAdminNavDomains = 'workspace_tooltipAdminNavDomains',
  tooltipAdminNavCompanyProfile = 'workspace_tooltipAdminNavCompanyProfile',
  tooltipAdminNavUsers = 'workspace_tooltipAdminNavUsers',
  tooltipAdminNavGroups = 'workspace_tooltipAdminNavGroups',
  tooltipAdminDesktops = 'workspace_tooltipAdminDesktops',
  tooltipAdminNavBilling = 'workspace_tooltipAdminNavBilling',
  tooltipAdminNavSso = 'workspace_tooltipAdminNavSso',
  tooltipAdminNavAppsIntegrations = 'workspace_tooltipAdminNavAppsIntegrations',
  tooltipAdminNavCustomApps = 'workspace_tooltipAdminNavCustomApps',
  tooltipChangeWorkspaceImage = 'workspace_tooltipChangeWorkspaceImage',
  tooltipAdminNavUserNotificationsLink = 'workspace_tooltipAdminNavUserNotificationsLink',
  createLinkedUserWorkspace = 'workspace_createLinkedUserWorkspace',
  createLinkedUserWorkspaceAccountPlan = 'workspace_createLinkedUserWorkspaceAccountPlan',
}

export type WorkspaceLocale = {
  [key in WorkspaceTranslation]: string;
};
