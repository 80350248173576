/*
 * It's a temporary solution for error codes. Here we get an error code as a part of error message string and parse it.
 * TODO: implement better solution after related API issues are fixed
 * */

import { GraphQLError } from 'graphql';

export enum ERROR_CODE {
  FILE_STORAGE_LIMIT_REACHED = 100,
}

const ERROR_CODE_REGEXP = /^\[(\d+)\]/;

export const getErrorCodeFrom = (
  graphQLErrors: ReadonlyArray<GraphQLError>,
): number | null => {
  if (graphQLErrors && graphQLErrors.length) {
    // @ts-ignore – debugMessage will be deprecated
    const message = graphQLErrors[0].debugMessage || graphQLErrors[0].message;
    if (message) {
      const errorCodeString = ERROR_CODE_REGEXP.exec(message)?.[1];
      const errorCode = errorCodeString ? parseInt(errorCodeString) : null;
      return isNaN(errorCode as number) ? null : errorCode;
    }
  }

  return null;
};

export const removeErrorCodeFrom = (
  message: string | undefined,
): string | undefined => {
  if (message) {
    return message.replace(ERROR_CODE_REGEXP, '').trim();
  }
};
