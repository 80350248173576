import { GraphQLError } from 'graphql';
import { getApolloClient } from '../../../Api/Api.utils';
import {
  CREATE_LINK,
  CreateLinkInputVariables,
  CreateLinkResponse,
  CreateLinkVariables,
} from '../Operations/Mutations/CreateLink.mutation';
import {
  BULK_DELETE_LINK,
  BulkDeleteLinkResponse,
  BulkDeleteLinkVariables,
  DELETE_LINK,
  DeleteLinkResponse,
  DeleteLinkVariables,
} from '../Operations/Mutations/DeleteLink.mutation';
import {
  EDIT_LINK,
  EditLinkInputVariables,
  EditLinkResponse,
  EditLinkVariables,
} from '../Operations/Mutations/EditLink.mutation';
import {
  GET_LINK,
  GetLinkResponse,
  GetLinkVariables,
} from '../Operations/Queries/GetLink.query';
import {
  BULK_MOVE_LINKS,
  BulkMoveLinkVariables,
  MoveLinksToFolderResponse,
} from '../Operations/Mutations/MoveLinks.mutation';

/**
 * Edit link using API
 * @param linkId
 * @param updatedData
 * @returns Link
 */
export const editLinkApi = async (
  linkId: string,
  updatedData: Omit<EditLinkInputVariables, 'id'>,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: EditLinkResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      EditLinkResponse,
      EditLinkVariables
    >({
      mutation: EDIT_LINK,
      variables: {
        input: {
          id: linkId,
          ...updatedData,
        },
      },
      errorPolicy: 'all',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Create link using API
 * @param linkData
 * @returns Link
 */
export const createLinkApi = async (
  linkData: CreateLinkInputVariables,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: CreateLinkResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      CreateLinkResponse,
      CreateLinkVariables
    >({
      mutation: CREATE_LINK,
      variables: {
        input: {
          ...linkData,
        },
      },
      errorPolicy: 'all',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Delete link using API
 * @param linkId
 */
export const deleteLinkApi = async (
  linkId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: DeleteLinkResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      DeleteLinkResponse,
      DeleteLinkVariables
    >({
      mutation: DELETE_LINK,
      variables: {
        input: {
          id: linkId,
        },
      },
      errorPolicy: 'all',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

export const bulkDeleteLinkApi = async (
  linkIds: string[],
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: BulkDeleteLinkResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      BulkDeleteLinkResponse,
      BulkDeleteLinkVariables
    >({
      mutation: BULK_DELETE_LINK,
      variables: {
        input: {
          links: linkIds,
        },
      },
      errorPolicy: 'all',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Get link using API
 * @param linkId
 */
export const getLinkApi = async (
  linkId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: GetLinkResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.query<
      GetLinkResponse,
      GetLinkVariables
    >({
      query: GET_LINK,
      variables: {
        id: linkId,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    });

    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Move links to folder in a bulk using API
 * @param linkIds
 * @param folderId
 */
export const moveLinks = async (
  linkIds: string[],
  desktopId?: string,
  folderId?: string,
  copy?: boolean,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: MoveLinksToFolderResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      MoveLinksToFolderResponse,
      BulkMoveLinkVariables
    >({
      mutation: BULK_MOVE_LINKS,
      variables: {
        input: {
          folder: folderId || null,
          desktop: desktopId || null,
          links: linkIds,
          copy,
        },
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    });

    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};
