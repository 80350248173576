import { appEnv } from '../../appEnv';
import { isAndroid, isAppleTouchDevice } from './user-agent';

const isMobileDevice = (isAppleTouchDevice() || isAndroid()) ?? false;

const EXTENSION_SUPPORT_RECHECK_DELAY = 3000;

export const extensionSupported = !!window.chrome && !isMobileDevice;

export const checkExtensionInstalled = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const delay = !extensionSupported ? EXTENSION_SUPPORT_RECHECK_DELAY : 0;
    setTimeout(() => {
      if (!extensionSupported) {
        reject(new Error('Chromium-based browser is not used'));
      }

      if (typeof window.chrome?.runtime === 'undefined') {
        resolve(false);
      } else {
        sendExtensionMessage({ ping: true }, () => {
          if (chrome.runtime.lastError) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
      }
    }, delay);
  });
};

export const sendExtensionMessage = <T>(
  message: any,
  callback?: (_?: T) => void,
) => {
  if (!extensionSupported) {
    return null;
  }

  const sendMessage = chrome.runtime?.sendMessage;

  return typeof sendMessage === 'function'
    ? sendMessage(appEnv.IMPORT_EXTENSION_ID, message, callback)
    : undefined;
};
