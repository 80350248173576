import { AccountApiType } from '../../../../User/User.types';
import { CurrentAccountTableType } from '../types/CurrentAccountIDDB.types';
import { CURRENT_ACCOUNT_IDDB_CACHE_ID } from '../CurrentAccount.constants';

export const AccountApiTypeToCurrentAccountTableType = (
  account: AccountApiType | null,
): CurrentAccountTableType => {
  return {
    id: CURRENT_ACCOUNT_IDDB_CACHE_ID,
    sourceData: account,
  };
};

export const CurrentAccountTableTypeToAccountApiType = (
  accountRow: CurrentAccountTableType,
) => {
  return accountRow?.sourceData;
};
