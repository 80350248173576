export const appEnv = {
  PROTOCOL: process.env.PROTOCOL,
  SENTRY_ENV: process.env.SENTRY_ENV,
  MERCURE_URL: process.env.MERCURE_URL,
  UA_CODE: process.env.UA_CODE,
  UA_DEBUG: process.env.UA_DEBUG,
  SENTRY_DSN: process.env.SENTRY_DSN,
  ENABLE_SOCIAL_REGISTRATION: process.env.ENABLE_SOCIAL_REGISTRATION,
  STRIPE_URL: process.env.STRIPE_URL,
  STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
  DETECTED_ABUSE_URL: process.env.DETECTED_ABUSE_URL,
  TERMS_OF_USE_URL: process.env.TERMS_OF_USE_URL,
  PRIVACY_POLICY_URL: process.env.PRIVACY_POLICY_URL,
  API_URL: process.env.API_URL,
  API_REST_URL: process.env.API_REST_URL,
  APP_HOSTNAME: process.env.APP_HOSTNAME,
  READER_URL: process.env.READER_URL,
  RECAPTCHA_API_KEY: process.env.RECAPTCHA_API_KEY,
  VAT_LEARN_MORE_URL: process.env.VAT_LEARN_MORE_URL,
  WORKSPACE_DOMAIN: process.env.WORKSPACE_DOMAIN,
  SUPPORT_URL: process.env.SUPPORT_URL,
  SUPPORT_LINKING_ACCOUNTS_URL: process.env.SUPPORT_LINKING_ACCOUNTS_URL,
  SUPPORT_IMPORT_BOOKMARKS_URL: process.env.SUPPORT_IMPORT_BOOKMARKS_URL,
  SUPPORT_PASSWORD_MANAGER_URL: process.env.SUPPORT_PASSWORD_MANAGER_URL,
  SUPPORT_PASSWORD_GROUPS_URL: process.env.SUPPORT_PASSWORD_GROUPS_URL,
  SUPPORT_SIGN_UP_WITH_FACEBOOK_URL:
    process.env.SUPPORT_SIGN_UP_WITH_FACEBOOK_URL,
  SUPPORT_INVITE_TO_CHAT_URL: process.env.SUPPORT_INVITE_TO_CHAT_URL,
  SUPPORT_WORKSPACE_URL_INFO_URL: process.env.SUPPORT_WORKSPACE_URL_INFO_URL,
  SUPPORT_WORKSPACE_URL_INFO_NAME: process.env.SUPPORT_WORKSPACE_URL_INFO_NAME,
  PRICING_URL: process.env.PRICING_URL,
  IMPORT_EXTENSION_LINK: process.env.IMPORT_EXTENSION_LINK,
  IMPORT_EXTENSION_ID: process.env.IMPORT_EXTENSION_ID,
  SUPPORT_DESKTOP_TYPES_URL: process.env.SUPPORT_DESKTOP_TYPES_URL,
  WEB_PUSH_SERVER_KEY: process.env.WEB_PUSH_SERVER_KEY,
  JITSI_EXTERNAL_API_SCRIPT_LINK: process.env.JITSI_EXTERNAL_API_SCRIPT_LINK,
  JITSI_DOMAIN: process.env.JITSI_DOMAIN,
  ONBOARDING_CHAT_URL: process.env.ONBOARDING_CHAT_URL,
  ONBOARDING_CREATE_USER_DESKTOP_URL:
    process.env.ONBOARDING_CREATE_USER_DESKTOP_URL,
  ONBOARDING_FIRST_CALL_AND_VIDEO_URL:
    process.env.ONBOARDING_FIRST_CALL_AND_VIDEO_URL,
  ONBOARDING_FIRST_HOW_TO_INSTALL_DESKTOP:
    process.env.ONBOARDING_FIRST_HOW_TO_INSTALL_DESKTOP,
  ONBOARDING_QUICK_TOUR_VIDEO: process.env.ONBOARDING_QUICK_TOUR_VIDEO,
  ONBOARDING_CONTACT_DESKTOP_URL: process.env.ONBOARDING_CONTACT_DESKTOP_URL,
  ONBOARDING_HOW_TO_IMPORT_APPS: process.env.ONBOARDING_HOW_TO_IMPORT_APPS,
  SUPPORT_MFA_URL: process.env.SUPPORT_MFA_URL,
  SUPPORT_FAQ_URL: process.env.SUPPORT_FAQ_URL,
  SUPPORT_SSO_URL: process.env.SUPPORT_SSO_URL,
  SUPPORT_SSO_CUSTOM_APP_URL: process.env.SUPPORT_SSO_CUSTOM_APP_URL,
  SUPPORT_CUSTOM_APPS_URL: process.env.SUPPORT_CUSTOM_APPS_URL,
  SUPPORT_ADD_APPS_URL: process.env.SUPPORT_ADD_APPS_URL,
  SUPPORT_TECHNICAL_REQUIREMENTS_URL:
    process.env.SUPPORT_TECHNICAL_REQUIREMENTS_URL,
  SUPPORT_DIFFERENCE_BETWEEN_PACKAGES_URL:
    process.env.SUPPORT_DIFFERENCE_BETWEEN_PACKAGES_URL,
  APPS_INTEGRATIONS_READ_MORE_LINK:
    process.env.APPS_INTEGRATIONS_READ_MORE_LINK,
  APPS_INTEGRATIONS_SETUP_GUIDE: process.env.APPS_INTEGRATIONS_SETUP_GUIDE,
  FEATURES_INTEGRATIONS: process.env.FEATURES_INTEGRATIONS,
  SUPPORT_DOWNLOAD_BROWSER_EXTENSION:
    process.env.SUPPORT_DOWNLOAD_BROWSER_EXTENSION,
  CUSTOM_INTEGRATIONS_DOCUMENTATION_LINK:
    process.env.CUSTOM_INTEGRATIONS_DOCUMENTATION_LINK,
  MFA_LEARN_MORE_URL: process.env.MFA_LEARN_MORE_URL,
  PRICING_LEARN_MORE_URL: process.env.PRICING_LEARN_MORE_URL,
  _VERSION: process.env._VERSION,
  ALLOW_ENTERPRISE_REGISTRATION: process.env.ALLOW_ENTERPRISE_REGISTRATION,
  //Temporary solution until https://norselab.atlassian.net/browse/NODESKMVP-2458 will be done
  TIME_FORMAT: 'h:mm aa',
  GOOGLE_AUTH_CHECKER_URL: process.env.GOOGLE_AUTH_CHECKER_URL,
  IMPORT_PASSWORDS_ROWS_IN_FILE_LIMIT:
    process.env.IMPORT_PASSWORDS_ROWS_IN_FILE_LIMIT,
  SUPPORT_PASSWORD_IMPORT_HELP_URL:
    process.env.SUPPORT_PASSWORD_IMPORT_HELP_URL,
  NATIVE_WRAPPER_PROTOCOL_IOS: process.env.NATIVE_WRAPPER_PROTOCOL_IOS,
  NATIVE_WRAPPER_PROTOCOL_ANDROID: process.env.NATIVE_WRAPPER_PROTOCOL_ANDROID,
  PAYMENT_PRICE_FREE: process.env.PAYMENT_PRICE_FREE,
  PAYMENT_PRICE_PRO: process.env.PAYMENT_PRICE_PRO,
  PAYMENT_PRICE_ENTERPRISE: process.env.PAYMENT_PRICE_ENTERPRISE,
  MAX_UPLOAD_FILE_SIZE: process.env.MAX_UPLOAD_FILE_SIZE,
  GOOGLE_API_SERVICES_DATA_POLICY_URL:
    process.env.GOOGLE_API_SERVICES_DATA_POLICY_URL,
};
