import React, { FC, memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import { Input } from './Input';
import { GlobalTranslation } from '../../../domains/Intl/i18n/i18n.types';
import { IconProps } from '../../icons/icon.types';

export const StyledFormInput = styled.div<{
  invalid?: boolean;
  inlineError?: boolean;
}>`
  ${({ invalid, inlineError, theme }) =>
    invalid &&
    css`
      ${inlineError &&
      css`
          position: relative;
          input {
            box-shadow: inset 0 0px 0px 1px ${theme.colors.CriticalLight};
            &:focus {
              box-shadow: inset 0 0px 0px 2px ${theme.colors.CriticalLight};
            }
        `}

      ${!inlineError &&
      css`
          input {
            border: 0.063rem solid ${theme.colors.CriticalLight};
            &:focus {
              border: 0.125rem solid ${theme.colors.CriticalLight};
            }
        `}
    `}
`;

export const FormInputLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.43;
  cursor: pointer;
  svg {
    margin-left: ${({ theme }) => theme.spacing.xs};
    color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
  }
`;

export const ErrorMessage = styled.div<{ inlineError?: boolean }>`
  color: ${p => p.theme.colors.CriticalDark};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 1.2;
  margin-top: 0.25rem;

  ${({ inlineError }) =>
    inlineError &&
    css`
      position: absolute;
      top: 10px;
      right: 1rem;
    `}
`;

export interface FormInputProps {
  label?: string;
  className?: string;
  disabled?: boolean;
  inlineError?: boolean;
  displayErrors?: boolean;
  maxLength?: number | null;
  labelIcon?: FC<IconProps> | null;
}

export const FormInput: FC<FieldProps & FormInputProps> = memo(
  ({
    field,
    form,
    label,
    className,
    inlineError = false,
    displayErrors = true,
    maxLength = 255,
    labelIcon: Icon,
    ...props
  }) => {
    const errorIdToDisplay = useMemo(
      () => _get(form.touched, field.name) && _get(form.errors, field.name),
      [form.touched, form.errors, field.name],
    );
    return (
      <StyledFormInput
        className={className}
        invalid={displayErrors && !!errorIdToDisplay}
        inlineError={inlineError}>
        {label && (
          <FormInputLabel htmlFor={field.name}>
            <div>{label}</div>
            {Icon && <Icon />}
          </FormInputLabel>
        )}

        <Input
          id={field.name}
          maxLength={maxLength || undefined}
          {...field}
          {...props}
        />
        {displayErrors && errorIdToDisplay && (
          <ErrorMessage inlineError={inlineError}>
            <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
          </ErrorMessage>
        )}
      </StyledFormInput>
    );
  },
);
