import { database } from '../../../../Database';
import { AccountInvitationApiType } from '../types/Invitation.types';
import { InvitationsTableType } from '../types/InvitationIDDB.types';
import { InvitationsApiTypeToInvitationTableType } from '../utils/InvitationIDDB.utils';

/*
 * Put
 * */

export const bulkPutInvitationsToIDDB = (
  invitations: AccountInvitationApiType[],
  workspaceId: string,
  desktopId?: string,
) => {
  try {
    const invitationRows = invitations.map(invitation =>
      InvitationsApiTypeToInvitationTableType(
        invitation,
        workspaceId,
        desktopId,
      ),
    );
    database.invitations.bulkPut(invitationRows);
  } catch (e) {
    console.error(e);
  }
};

export const bulkPutInvitationRowsToIDDB = (
  invitationRows: InvitationsTableType[],
) => {
  try {
    return database.invitations.bulkPut(invitationRows);
  } catch (e) {
    console.error(e);
  }
};
/*
 * Delete
 * */

export const bulkDeleteInvitationsFromIDDB = (invitationIds: string[]) => {
  try {
    return database.invitations.bulkDelete(invitationIds);
  } catch (e) {
    console.error(e);
  }
};

export const deleteInvitationFromIDDB = (invitationId: string) => {
  try {
    return database.invitations.delete(invitationId);
  } catch (e) {
    console.error(e);
  }
};
