import { gql } from '@apollo/client';
import {
  GRAPHQL_QUERY_PAGE_INFO,
  GRAPHQL_QUERY_PAGINATION_VARIABLE_TYPES,
  GRAPHQL_QUERY_PAGINATION_VARIABLES,
} from '../../shared/api/api.constants';
import {
  ApiConnection,
  ApiPaginationVariables,
} from '../../shared/api/api.types';
import { LinkFragment } from './Link.fragments';
import { LinkApiType, RecentLinksApiType, TagApiType } from './Link.types';

/**
 * LINKS
 */

export const GET_LINKS = gql`
  query GetDesktopLinks(
    $desktop: String
    $desktop_workspace: String
    $filterFreeTextSearch: String
    $filterOnlyAccessible: Boolean
    $folderId: String
    $hasFolder: Boolean
    ${GRAPHQL_QUERY_PAGINATION_VARIABLE_TYPES}
  ) {
    links(
      desktop: $desktop
      desktop_workspace: $desktop_workspace
      filterFreeTextSearch: $filterFreeTextSearch
      filterOnlyAccessible: $filterOnlyAccessible
      folder: $folderId
      exists: {
        folder: $hasFolder,
      }
      order: { createdAt: "asc" }
      ${GRAPHQL_QUERY_PAGINATION_VARIABLES}
    ) {
      ${GRAPHQL_QUERY_PAGE_INFO}
      totalCount
      edges {
        node {
          ...LinkFragment
        }
      }
    }
  }
  ${LinkFragment}
`;

export interface GetLinksVariables extends ApiPaginationVariables {
  desktop?: string;
  desktop_workspace: string;
  filterFreeTextSearch?: string;
  filterOnlyAccessible: boolean;
  folderId?: string;
  hasFolder?: boolean;
}

export interface GetLinksResponse {
  links: ApiConnection<LinkApiType>;
}

export const GET_TOTAL_LINKS_COUNT = gql`
  query GetTotalDesktopLinksCount($desktopId: String, $workspaceId: String) {
    links(desktop: $desktopId, desktop_workspace: $workspaceId) {
      totalCount
    }
  }
`;

export const FILTER_LINKS = gql`
  query FilterDesktopLinks(
    $desktop: String
    $desktop_workspace: String
    $filterFreeTextSearch: String
    $filterOnlyAccessible: Boolean
  ) {
    links(
      desktop: $desktop
      desktop_workspace: $desktop_workspace
      filterFreeTextSearch: $filterFreeTextSearch
      filterOnlyAccessible: $filterOnlyAccessible
      first: 500
    ) {
      edges {
        node {
          ...LinkFragment
        }
      }
    }
  }
  ${LinkFragment}
`;

export interface FilterLinksVariables {
  desktop?: string;
  desktop_workspace: string;
  filterOnlyAccessible: boolean;
  filterFreeTextSearch: string;
}

export interface FilterLinksResponse {
  links: ApiConnection<LinkApiType>;
}

/**
 * TAGS
 */

export const GET_TAGS = gql`
  query GetTags($workspace: String) {
    tags(workspace: $workspace) {
      edges {
        node {
          id
          _id
          name
        }
      }
    }
  }
`;

export interface GetTagsVariables {
  workspace: string;
}

export interface GetTagsResponse {
  tags: ApiConnection<TagApiType>;
}

export const SEARCH_TAGS = gql`
  query SearchTags($workspace: ID!, $text: String!) {
    searchTags(workspace: $workspace, text: $text) {
      id
      name
    }
  }
`;

export interface SearchTagsVariables {
  workspace: string;
  text: string;
}

export interface SearchTagsResponse {
  searchTags: Omit<TagApiType, '_id'>[];
}

export const GET_RECENT_LINKS = gql`
  query GetRecentLinks($workspace: ID!) {
    getRecentLinks(
      workspace: $workspace
      orderBy: "occurredAt"
      orderType: "desc"
    ) {
      link {
        ...LinkFragment
      }
    }
  }
  ${LinkFragment}
`;

export interface GetRecentLinksResponse {
  getRecentLinks: RecentLinksApiType[];
}

export interface GetRecentLinksVariables {
  workspace: string;
}
