export enum WorkspaceAccountGroupIdentity {
  GUEST = 'workspace_guest',
  MEMBER = 'workspace_member',
  ADMIN = 'workspace_admin',
  OWNER = 'workspace_owner',
  PENDING = 'workspace_pending',
  SUSPENDED = 'workspace_suspended',
}

export const PASSWORD_CHARACTER_REGEX = /[a-zA-Zа-яА-Я]+/;
export const PASSWORD_NUMBER_REGEX = /.*[0-9].*/;

export const GRAPHQL_TYPENAME_ACCOUNT_INVITATION = 'AccountInvitation';
export const GRAPHQL_TYPENAME_ACCOUNT_INVITATION_ITEM = 'AccountInvitationItem';

export const ADMIN_PROFILE_PATHNAME = '/workspace/:workspaceId?/admin/profile';
export const ADMIN_PREFERENCES_PATHNAME =
  '/workspace/:workspaceId?/admin/settings';
export const ADMIN_USER_PATHNAME =
  '/workspace/:workspaceId?/admin/users/:userId?';

export const GRAPHQL_TYPENAME_ACCOUNT = 'Account';
