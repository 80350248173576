import styled from 'styled-components';
import { Breakpoints } from '../../../Theme';

export const StyledSidebarHeader = styled.div`
  ${({ theme }) => theme.fontTemplates.headerMain}
  display: flex;
  min-height: 3.5rem;
  padding: 0.25rem 1rem;
  border-bottom: 1px solid ${p => p.theme.colors.SurfaceBorder};

  button {
    align-self: center;
    padding: 0.625rem;

    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    @media (max-width: ${Breakpoints.lg}px) {
      padding: 14px;
      transform: translateX(14px);
    }
  }
`;

export const SidebarHeaderTitle = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-basis: 100%;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 1rem;
`;
