import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const DocumentGoogleDriveIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="document-google-drive-icon"
      {...props}>
      <path
        d="m6.948 37.278 1.772 3.06c.368.644.897 1.15 1.518 1.519l6.328-10.954h-12.656c0 .714.184 1.427.552 2.071z"
        fill="#0066da"
      />
      <path
        d="m24 18.017-6.33-10.953a4.178 4.178 0 0 0 -1.519 1.518l-11.69 20.25a4.17 4.17 0 0 0 -.552 2.072h12.656z"
        fill="#00ac47"
      />
      <path
        d="m37.76 41.857a4.178 4.178 0 0 0 1.52-1.519l.736-1.266 3.52-6.098a4.17 4.17 0 0 0 .553-2.07h-12.657l2.693 5.292 3.636 5.66z"
        fill="#ea4335"
      />
      <path
        d="m24 18.017 6.328-10.953a4.045 4.045 0 0 0 -2.07-.553h-8.515c-.736 0-1.45.207-2.071.553z"
        fill="#00832d"
      />
      <path
        d="m31.432 30.903h-14.866l-6.328 10.954a4.045 4.045 0 0 0 2.071.552h23.38c.736 0 1.45-.207 2.07-.552z"
        fill="#2684fc"
      />
      <path
        d="m37.692 18.707-5.845-10.125a4.18 4.18 0 0 0 -1.519-1.518l-6.328 10.953 7.433 12.887h12.633c0-.714-.184-1.427-.552-2.072z"
        fill="#ffba00"
      />
    </svg>
  );
});
