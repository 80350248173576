import { OfflineLocale, OfflineTranslation } from './i18n.types';

export const enOfflineLocale: OfflineLocale = {
  // Page
  [OfflineTranslation.offlinePageTitle]: "It seems like you're offline",
  [OfflineTranslation.offlinePageContent]:
    'Webtop.com cannot be loaded.<br />Please check your internet connection.',

  // Modal
  [OfflineTranslation.offlineModalTitle]: "It seems like you're offline",
  [OfflineTranslation.offlineModalContent]:
    'Please check your internet connection.',
};
