import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const HeartFullIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="heart-full-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentcolor"
        d="M7.655 13.9159L7.65269 13.9147L7.64731 13.9119L7.62883 13.9022C7.61313 13.8939 7.59074 13.882 7.5621 13.8665C7.50484 13.8356 7.42254 13.7904 7.3188 13.7317C7.1114 13.6142 6.81771 13.442 6.46642 13.2199C5.76538 12.7767 4.82717 12.13 3.8853 11.3181C2.04459 9.7312 0 7.35031 0 4.5C0 1.83579 2.08579 0 4.25 0C5.79736 0 7.15289 0.801509 8 2.01995C8.84711 0.801509 10.2026 0 11.75 0C13.9142 0 16 1.83579 16 4.5C16 7.35031 13.9554 9.7312 12.1147 11.3181C11.1728 12.13 10.2346 12.7767 9.53358 13.2199C9.18229 13.442 8.8886 13.6142 8.6812 13.7317C8.57746 13.7904 8.49517 13.8356 8.4379 13.8665C8.40926 13.882 8.38687 13.8939 8.37117 13.9022L8.35269 13.9119L8.34731 13.9147L8.34501 13.9159C8.22746 13.996 8.16325 12.9349 7.655 13.9159ZM8.34501 13.9159C8.12889 14.0277 7.87111 14.0277 7.655 13.9159C8.16325 12.9349 8.22746 13.996 8.34501 13.9159Z"
      />
    </svg>
  );
});
