import { DesktopsTableType } from '../../../../Database/DesktopTable/DesktopsTable';
import { DesktopApiType } from '../types/Desktop.types';

export const getRedundantCacheDesktops = (
  cacheDesktops: DesktopsTableType[] = [],
  apiDesktops: DesktopApiType[] = [],
) => {
  const apiDesktopsMap = apiDesktops.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {} as Record<string, DesktopApiType>,
  );

  return cacheDesktops.filter(desktop => !apiDesktopsMap[desktop.id]);
};
