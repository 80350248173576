import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  BATCH_REMOVE_VAULT_ITEM,
  BatchRemoveVaultItemResponse,
  BatchRemoveVaultItemVariables,
  CREATE_CURRENT_ACCOUNT_KEYSET_FIRST_TIME,
  CREATE_MULTIPLE_VAULT_ACCESS,
  CREATE_MULTIPLE_VAULT_ITEMS,
  CREATE_VAULT_WITH_VAULT_ACCESS,
  CREATE_WITHOUT_APP_VAULT_ITEM_WITH_ACCESS,
  CreateCurrentAccountKeysetFirstTimeResponse,
  CreateCurrentAccountKeysetFirstTimeVariables,
  CreateMultipleVaultAccessResponse,
  CreateMultipleVaultAccessVariables,
  CreateMultipleVaultItemResponse,
  CreateMultipleVaultItemVariables,
  CreateVaultItemWithAccessResponse,
  CreateVaultItemWithAccessVariables,
  CreateWithoutAppVaultItemWithAccessResponse,
  CreateWithoutAppVaultItemWithAccessVariables,
  DELETE_VAULT_ITEM,
  DeleteVaultItemResponse,
  DeleteVaultItemVariables,
  EDIT_PASSWORD_RECOVERY_REQUEST,
  EditPasswordRecoveryRequestResponse,
  EditPasswordRecoveryRequestVariables,
  REMOVE_VAULT_ACCESS,
  RemoveVaultAccessResponse,
  RemoveVaultAccessVariables,
  SELECT_VAULT_ITEM_FOR_APP,
  SelectVaultItemForAppResponse,
  SelectVaultItemForAppVariables,
  UNSELECT_FOR_APP_VAULT_ITEM_CONFIG,
  UnselectForAppVaultItemConfigResponse,
  UnselectForAppVaultItemConfigVariables,
  UPDATE_VAULT_ITEM,
  UpdateVaultItemResponse,
  UpdateVaultItemVariables,
} from './Encryption.mutations';
import {
  GET_PASSWORD_RECOVERY_REQUEST,
  GET_VAULT_ACCESSES_CHANGE_OWNER,
  GET_VAULT_ACCESSES_RECOVERY_REQUEST,
  GET_VAULTS,
  GetPasswordRecoveryRequestResponse,
  GetPasswordRecoveryRequestVariables,
  GetVaultAccessesChangeOwnerResponse,
  GetVaultAccessesChangeOwnerVariables,
  GetVaultAccessesRecoveryRequestResponse,
  GetVaultAccessesRecoveryRequestVariables,
  GetVaultsResponse,
  GetVaultsVariables,
} from './Encryption.queries';
import { useCallback, useContext, useMemo } from 'react';
import { EncryptionContext } from './Encryption.context';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../Workspace/Workspace.hooks';
import {
  getAccountKeyset,
  getAccountPreviousKeysets,
  getVaultItemDesktopsMap,
} from './Encryption.utils';
import { useAccountsContext } from '../Account';
import { useCurrentDesktop } from '../Desktop/Desktop.hooks';
import { AccountWithCountsApiType } from '../User/User.types';
import { removeVault } from './utils/Vault.utils';

/*
 * KEYSET
 * */

export const useCreateCurrentAccountKeysetFirstTimeMutation = () =>
  useMutation<
    CreateCurrentAccountKeysetFirstTimeResponse,
    CreateCurrentAccountKeysetFirstTimeVariables
  >(CREATE_CURRENT_ACCOUNT_KEYSET_FIRST_TIME);

/*
 * VAULT
 * */

export const useUpdateVaultItemMutation = () =>
  useMutation<UpdateVaultItemResponse, UpdateVaultItemVariables>(
    UPDATE_VAULT_ITEM,
  );

export const useDeleteVaultItemMutation = () =>
  useMutation<DeleteVaultItemResponse, DeleteVaultItemVariables>(
    DELETE_VAULT_ITEM,
  );

export const useBatchRemoveVaultItemMutation = () =>
  useMutation<BatchRemoveVaultItemResponse, BatchRemoveVaultItemVariables>(
    BATCH_REMOVE_VAULT_ITEM,
  );

export const useRemoveVaultAccessMutation = () =>
  useMutation<RemoveVaultAccessResponse, RemoveVaultAccessVariables>(
    REMOVE_VAULT_ACCESS,
  );

export const useGetVaultsRestQuery = (
  options?: QueryHookOptions<GetVaultsResponse, GetVaultsVariables>,
) => useQuery<GetVaultsResponse, GetVaultsVariables>(GET_VAULTS, options);

/*
 * VAULT ITEM CONFIG
 * */

export const useSelectVaultItemForAppMutation = () =>
  useMutation<SelectVaultItemForAppResponse, SelectVaultItemForAppVariables>(
    SELECT_VAULT_ITEM_FOR_APP,
  );

export const useUnselectForAppVaultItemConfigMutation = () =>
  useMutation<
    UnselectForAppVaultItemConfigResponse,
    UnselectForAppVaultItemConfigVariables
  >(UNSELECT_FOR_APP_VAULT_ITEM_CONFIG);

/*
 * VAULT ACCESS
 * */

export const useCreateMultipleVaultAccessMutation = () =>
  useMutation<
    CreateMultipleVaultAccessResponse,
    CreateMultipleVaultAccessVariables
  >(CREATE_MULTIPLE_VAULT_ACCESS);

export const useGetVaultAccessesChangeOwnerQuery = (
  options?: QueryHookOptions<
    GetVaultAccessesChangeOwnerResponse,
    GetVaultAccessesChangeOwnerVariables
  >,
) =>
  useQuery<
    GetVaultAccessesChangeOwnerResponse,
    GetVaultAccessesChangeOwnerVariables
  >(GET_VAULT_ACCESSES_CHANGE_OWNER, options);

export const useGetVaultAccessesRecoveryRequestQuery = (
  options?: QueryHookOptions<
    GetVaultAccessesRecoveryRequestResponse,
    GetVaultAccessesRecoveryRequestVariables
  >,
) =>
  useQuery<
    GetVaultAccessesRecoveryRequestResponse,
    GetVaultAccessesRecoveryRequestVariables
  >(GET_VAULT_ACCESSES_RECOVERY_REQUEST, options);

/*
 * PASSWORD RECOVERY REQUEST
 * */

export const useGetPasswordRecoveryRequestQuery = (
  options?: QueryHookOptions<
    GetPasswordRecoveryRequestResponse,
    GetPasswordRecoveryRequestVariables
  >,
) =>
  useQuery<
    GetPasswordRecoveryRequestResponse,
    GetPasswordRecoveryRequestVariables
  >(GET_PASSWORD_RECOVERY_REQUEST, options);

export const useEditPasswordRecoveryRequest = () =>
  useMutation<
    EditPasswordRecoveryRequestResponse,
    EditPasswordRecoveryRequestVariables
  >(EDIT_PASSWORD_RECOVERY_REQUEST);

export const useCreateMultipleVaultItems = () =>
  useMutation<
    CreateMultipleVaultItemResponse,
    CreateMultipleVaultItemVariables
  >(CREATE_MULTIPLE_VAULT_ITEMS);

export const useCreateVaultWithVaultAccess = () =>
  useMutation<
    CreateVaultItemWithAccessResponse,
    CreateVaultItemWithAccessVariables
  >(CREATE_VAULT_WITH_VAULT_ACCESS);

export const useCreateWithoutAppVaultItemWithAccess = () =>
  useMutation<
    CreateWithoutAppVaultItemWithAccessResponse,
    CreateWithoutAppVaultItemWithAccessVariables
  >(CREATE_WITHOUT_APP_VAULT_ITEM_WITH_ACCESS);

/*
 * ENCRYPTION CONTEXT
 * */

export const useEncryptionContext = () => useContext(EncryptionContext);

// Keyset
export const useCurrentAccountKeyset = () => {
  const { account } = useCurrentWorkspaceAccount();
  const keyset = useMemo(() => {
    return getAccountKeyset(account);
  }, [account]);
  const previousKeysets = useMemo(() => {
    return getAccountPreviousKeysets(account);
  }, [account]);
  return { keyset, previousKeysets };
};
export const useCurrentWorkspaceAccountPublicKey = () => {
  const { account } = useCurrentWorkspaceAccount();
  const { accountPublicKeysMap } = useEncryptionContext();
  const publicKey = account && accountPublicKeysMap[account.id];
  return { publicKey };
};
export const useCurrentWorkspaceAccountPrivateKey = () => {
  const { account } = useCurrentWorkspaceAccount();
  const {
    accountPrivateKeysMap,
    decryptCurrentAccountPrivateKey,
    showMasterPasswordLockToast,
  } = useEncryptionContext();
  const privateKey = account && accountPrivateKeysMap[account.id];
  return {
    privateKey,
    decryptCurrentAccountPrivateKey,
    showMasterPasswordLockToast,
  };
};

export const useCurrentWorkspaceKeysetsToAccountsMap = (
  includePreviousKeyset?: boolean,
) => {
  const { accountsWithAvailability: workspaceAccountsMap } =
    useAccountsContext();
  return useMemo(() => {
    const accountsWithKeysets: AccountWithCountsApiType[] = Object.values(
      workspaceAccountsMap,
    ).filter(account => getAccountKeyset(account));
    return new Map([
      ...accountsWithKeysets.map(
        account =>
          [getAccountKeyset(account)?.id, account] as [
            string,
            AccountWithCountsApiType,
          ],
      ),
      ...(includePreviousKeyset
        ? accountsWithKeysets.reduce<Array<[string, AccountWithCountsApiType]>>(
            (acc, curr) => {
              const previousKeysetIdsMapItems =
                getAccountPreviousKeysets(curr)?.map(keyset => [
                  keyset.id,
                  curr,
                ]) || [];
              return [...acc, ...previousKeysetIdsMapItems] as Array<
                [string, AccountWithCountsApiType]
              >;
            },
            [],
          )
        : []),
    ]);
  }, [workspaceAccountsMap, includePreviousKeyset]);
};

// Vaults
export const useCurrentWorkspaceVaultsList = () => {
  const { workspace } = useCurrentWorkspace();
  const { workspaceVaultsMap, workspaceVaultsLoading, refetchWorkspaceVaults } =
    useEncryptionContext();
  const vaultsMap = workspace && workspaceVaultsMap?.[workspace.id];
  return useMemo(
    () => ({
      vaults: (vaultsMap && Object.values(vaultsMap)) || [],
      workspaceVaultsLoading,
      refetchWorkspaceVaults,
    }),
    [refetchWorkspaceVaults, workspaceVaultsLoading, vaultsMap],
  );
};
export const useWorkspaceVaultById = (vaultId: string) => {
  const { vaults, workspaceVaultsLoading, refetchWorkspaceVaults } =
    useCurrentWorkspaceVaultsList();
  const vault = useMemo(() => {
    return vaults?.find(vault => vault.id === vaultId);
  }, [vaults, vaultId]);
  return {
    vault,
    workspaceVaultsLoading,
    refetchWorkspaceVaults,
  };
};
export const useWorkspaceVaultsByIds = (vaultIds: string[]) => {
  const { vaults, workspaceVaultsLoading, refetchWorkspaceVaults } =
    useCurrentWorkspaceVaultsList();
  const filteredVaults = useMemo(() => {
    return vaults?.filter(vault => vaultIds.includes(vault.id));
  }, [vaults, vaultIds]);
  return {
    vaults: filteredVaults,
    workspaceVaultsLoading,
    refetchWorkspaceVaults,
  };
};
export const useCurrentWorkspaceAppVaultsList = (appId?: string) => {
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;
  const {
    workspaceAppVaultsMap,
    workspaceVaultsLoading,
    refetchWorkspaceVaults,
  } = useEncryptionContext();

  const vaults = useMemo(() => {
    return (
      (workspaceId &&
        appId &&
        workspaceAppVaultsMap?.[workspaceId]?.[appId] &&
        Object.values(workspaceAppVaultsMap[workspaceId][appId])) ||
      []
    );
  }, [workspaceId, appId, workspaceAppVaultsMap]);

  return {
    vaults,
    workspaceVaultsLoading,
    refetchWorkspaceVaults,
  };
};

export const useCurrentWorkspaceAppGroupVaultsList = (groupAppId?: string) => {
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;
  const {
    workspaceAppGroupVaultsMap,
    workspaceVaultsLoading,
    refetchWorkspaceVaults,
  } = useEncryptionContext();
  const { vaults: vaultList } = useCurrentWorkspaceVaultsList();

  const vaults = useMemo(() => {
    const groupVaultsMap =
      workspaceId &&
      groupAppId &&
      workspaceAppGroupVaultsMap?.[workspaceId]?.[groupAppId];
    return (
      (groupVaultsMap &&
        vaultList.filter(vaultItem => groupVaultsMap[vaultItem.id])) ||
      []
    );
  }, [workspaceId, groupAppId, vaultList, workspaceAppGroupVaultsMap]);

  return {
    vaults,
    workspaceVaultsLoading,
    refetchWorkspaceVaults,
  };
};
export const useCurrentWorkspaceAllAppRelatedVaultsList = (
  appId: string,
  groupAppId?: string,
) => {
  const {
    vaults: appVaults,
    refetchWorkspaceVaults,
    workspaceVaultsLoading,
  } = useCurrentWorkspaceAppVaultsList(appId);
  const { vaults: groupVaults } =
    useCurrentWorkspaceAppGroupVaultsList(groupAppId);
  const vaults = groupVaults.length ? groupVaults : appVaults;
  return {
    vaults,
    refetchWorkspaceVaults,
    workspaceVaultsLoading,
  };
};
export const useDesktopAppVault = (
  appId: string,
  groupAppId?: string,
  desktopId?: string,
) => {
  const { vaults } = useCurrentWorkspaceAllAppRelatedVaultsList(
    appId,
    groupAppId,
  );
  const currentDesktop = useCurrentDesktop();
  const currentDesktopId = desktopId || currentDesktop?.id;
  return useMemo(() => {
    return vaults.find(vault => {
      const vaultDesktopsMap = getVaultItemDesktopsMap(vault, appId);
      return vaultDesktopsMap[currentDesktopId as string];
    });
  }, [currentDesktopId, appId, vaults]);
};
export const useCurrentWorkspaceLogins = () => {
  const { workspace } = useCurrentWorkspace();
  const { workspaceAppDesktopVaultsMap, refetchWorkspaceVaults } =
    useEncryptionContext();
  return {
    loginsMap: workspace && workspaceAppDesktopVaultsMap?.[workspace.id],
    refetchWorkspaceVaults,
  };
};
export const useCurrentWorkspaceVaultLoginsMap = () => {
  const { workspace } = useCurrentWorkspace();
  const { workspaceVaultLoginsMap, refetchWorkspaceVaults } =
    useEncryptionContext();
  return {
    loginsMap: workspace && workspaceVaultLoginsMap?.[workspace.id],
    refetchWorkspaceVaults,
  };
};
export const useCurrentWorkspaceVaultLogin = (vaultId?: string) => {
  const { loginsMap, refetchWorkspaceVaults } =
    useCurrentWorkspaceVaultLoginsMap();
  return {
    login: vaultId ? loginsMap?.[vaultId] : null,
    refetchWorkspaceVaults,
  };
};
export const useDesktopAppVaultLogin = (appId: string, desktopId?: string) => {
  const vaultItemId = useDesktopAppVaultItemId(appId, desktopId);
  return useCurrentWorkspaceVaultLogin(vaultItemId);
};

export const useDesktopAppVaultItemId = (appId: string, desktopId?: string) => {
  const currentDesktop = useCurrentDesktop();
  const currentDesktopId = desktopId || currentDesktop?.id;
  const { loginsMap } = useCurrentWorkspaceLogins();
  return appId && currentDesktopId && loginsMap?.[appId]?.[currentDesktopId];
};

export const useVaultsListUpdate = () => {
  const { setWorkspaceVaultsCache } = useEncryptionContext();
  const { workspace } = useCurrentWorkspace();

  return {
    removeVault: useCallback(
      (vaultId: string) =>
        setWorkspaceVaultsCache(cache => ({
          ...cache,
          [workspace.id]: removeVault(cache[workspace.id], vaultId),
        })),
      [setWorkspaceVaultsCache, workspace.id],
    ),
  };
};
