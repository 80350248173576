import { TeamsTableType } from '../../../../Database/WorkspaceTeams/WorkspaceTeamsTable';
import { TeamApiType } from '../types/Team.types';

export const TeamApiTypeToTeamTableType = (
  team: TeamApiType,
  workspaceId: string,
): TeamsTableType => {
  return {
    id: team.id,
    workspaceId,
    sourceData: team,
    relatedDesktops: [],
    relatedAccounts: [],
  };
};

export const TeamTableTypeToTeamApiType = (teamRow: TeamsTableType) => {
  return teamRow.sourceData;
};
