import { WebPushLocale, WebPushTranslation } from './i18n.types';

export const enWebPushLocale: WebPushLocale = {
  [WebPushTranslation.permissionModalTitle]: 'Turn on notifications',
  [WebPushTranslation.permissionModalDescription]:
    'In order to be notified for incoming calls and important events, please turn on notifications.',
  [WebPushTranslation.permissionModalSubmitButton]: 'Turn On',
  [WebPushTranslation.permissionModalCancelButton]: 'Not Now',
  [WebPushTranslation.permissionModalImageAlt]: 'Webtop.com',
};
