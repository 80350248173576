import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ConfirmModal } from './ConfirmModal';

export type ConfirmOptions = {
  width?: number;
  dangerConfirm?: boolean;
  confirmationInput?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  disabled?: boolean;
  userList?: React.ReactNode;
  customControls?: (onConfirm: () => void, onCancel: () => void) => ReactNode;
};

interface ConfirmContextProps {
  askConfirmation: (
    message: string | ReactNode,
    headerTitle?: string | ReactNode,
    options?: ConfirmOptions,
  ) => Promise<boolean | null>;
}

const ConfirmContext = React.createContext<ConfirmContextProps>({
  askConfirmation: () => Promise.resolve(false),
});

export const ConfirmProvider: FC<PropsWithChildren> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState<
    string | React.ReactNode
  >('');
  const [confirmationTitle, setConfirmationTitle] = useState<
    string | React.ReactNode | undefined
  >('');
  const [confirmationOptions, setConfirmationOptions] = useState<
    ConfirmOptions | undefined
  >();

  const confirmationResolverRef = useRef<(confirm: boolean | null) => void>();

  const askConfirmation = useCallback(
    (
      message: string | React.ReactNode,
      headerTitle?: string | React.ReactNode,
      options?: ConfirmOptions,
    ): Promise<boolean | null> => {
      setConfirmationMessage(message);
      setConfirmationTitle(headerTitle);
      setConfirmationOptions(options);
      setVisible(true);
      return new Promise<boolean | null>(
        resolve => (confirmationResolverRef.current = resolve),
      );
    },
    [],
  );

  const handleClose = () => {
    if (confirmationResolverRef.current) {
      confirmationResolverRef.current(null);
    }
    setVisible(false);
  };

  const handleConfirm = useCallback(() => {
    if (confirmationResolverRef.current) {
      confirmationResolverRef.current(true);
    }
    setVisible(false);
  }, []);

  const handleCancel = useCallback(() => {
    if (confirmationResolverRef.current) {
      confirmationResolverRef.current(false);
    }
    setVisible(false);
  }, []);

  const value = useMemo(() => ({ askConfirmation }), [askConfirmation]);

  return (
    <ConfirmContext.Provider value={value}>
      <ConfirmModal
        headerTitle={confirmationTitle}
        options={confirmationOptions}
        visible={visible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        onClose={handleClose}>
        {confirmationMessage}
      </ConfirmModal>
      {children}
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => useContext(ConfirmContext);
