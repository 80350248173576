export enum DesktopTranslation {
  menuManageUsersLink = 'desktop_menuManageUsersLink',
  menuEditDesktop = 'desktop_menuEditDesktop',
  menuLeaveDesktop = 'desktop_menuLeaveDesktop',
  menuRemoveDesktop = 'desktop_menuRemoveDesktop',
  menuTimeline = 'desktop_menuTimeline',

  desktopSubtitleSharedBy = 'desktop_desktopSubtitleSharedBy',
  desktopSubtitleSharedExternally = 'desktop_desktopSubtitleSharedExternally',
  desktopSubtitleSharedExternallyBy = 'desktop_desktopSubtitleSharedExternallyBy',
  desktopSubtitleCurrentUserName = 'desktop_desktopSubtitleCurrentUserName',

  shareButtonLabel = 'desktop_shareButtonLabel',
  shareModalTitle = 'desktop_shareModalTitle',

  accessRemoveAccess = 'desktop_accessRemoveAccess',
  accessRemoveAccessConfirmation = 'desktop_accessRemoveAccessConfirmation',
  accessRemoveTeamAccessConfirmation = 'desktop_accessRemoveTeamAccessConfirmation',
  accessNotShared = 'desktop_accessNotShared',
  accessPendingInvitation = 'desktop_accessPendingInvitation',
  accessRevokePendingInvitation = 'desktop_accessRevokePendingInvitation',
  accessInviteAccountToPrivateDesktopPlaceholder = 'desktop_accessInviteAccountToPrivateDesktopPlaceholder',
  accessInviteAccountToInternalDesktopPlaceholder = 'desktop_accessInviteAccountToInternalDesktopPlaceholder',
  accessInviteAccountSubmit = 'desktop_accessInviteAccountSubmit',
  accessInviteAccountTooltip = 'desktop_accessInviteAccountTooltip',
  accessInternalDesktopsInviteAccountHelpText = 'desktop_accessInternalDesktopsInviteAccountHelpText',
  accessPrivateDesktopsInviteAccountHelpText = 'desktop_accessPrivateDesktopsInviteAccountHelpText',

  accessInviteAccountLinkText = 'desktop_accessInviteAccountLinkText',
  accessTeam = 'desktop_accessTeam',
  accessTeamMembers = 'desktop_accessTeamMembers',
  accessTeamTitle = 'desktop_accessTeamTitle',
  accessAccount = 'desktop_accessAccount',
  accessGuestAccount = 'desktop_accessGuestAccount',
  accessGuest = 'desktop_accessGuest',
  accessPickerDisabledForAdmin = 'desktop_accessPickerDisabledForAdmin',

  appsLayoutGrid = 'desktop_appsLayoutGrid',
  appsLayoutFluid = 'desktop_appsLayoutFluid',

  adminListHeaderTitle = 'desktop_adminListHeaderTitle',
  adminListHeaderSubtitle = 'desktop_adminListHeaderSubtitle',
  adminListAddButton = 'desktop_adminListAddButton',
  adminListEmptyMessage = 'desktop_adminListEmptyMessage',
  adminSingleDesktopGeneralInfo = 'desktop_adminSingleDesktopGeneralInfo',
  adminSingleDesktopEditButton = 'desktop_adminSingleDesktopEditButton',
  adminSingleDesktopOpenButton = 'desktop_adminSingleDesktopOpenButton',
  adminSingleDesktopApps = 'desktop_adminSingleDesktopApps',
  adminSingleDesktopAppStoreLink = 'desktop_adminSingleDesktopAppStoreLink',
  adminSingleDesktopNotFound = 'desktop_adminSingleDesktopNotFound',

  adminDesktopTeams = 'desktop_adminDesktopTeams',
  adminDesktopTeamsEmptyMessage = 'desktop_adminDesktopTeamsEmptyMessage',
  adminDesktopTeamsManageButton = 'desktop_adminDesktopTeamsManageButton',
  adminDesktopTeamsRemoveConfirmation = 'desktop_adminDesktopTeamsRemoveConfirmation',
  adminDesktopTeamsManageModalHeader = 'desktop_adminDesktopTeamsManageModalHeader',

  adminDesktopUsers = 'adminDesktopUsers',
  adminDesktopUsersEmptyMessage = 'adminDesktopUsersEmptyMessage',
  adminDesktopUsersManageButton = 'adminDesktopUsersManageButton',
  adminDesktopUsersRemoveConfirmation = 'adminDesktopUsersRemoveConfirmation',
  adminDesktopUsersManageModalHeader = 'adminDesktopUsersManageModalHeader',

  adminSingleDesktopDangerZone = 'desktop_adminSingleDesktopDangerZone',
  adminSingleDesktopDangerZoneDeleteButton = 'desktop_adminSingleDesktopDangerZoneDeleteButton',
  adminSingleDesktopDangerZoneDeleteWarning = 'desktop_adminSingleDesktopDangerZoneDeleteWarning',
  adminSingleDesktopDangerZoneDeleteConfirmation = 'desktop_adminSingleDesktopDangerZoneDeleteConfirmation',

  manageModalUpdateButton = 'desktop_manageModalUpdateButton',

  listTitle = 'desktop_listTitle',
  listFavorites = 'desktop_listFavorites',
  listVault = 'desktop_listVault',
  listPasswords = 'desktop_listPasswords',
  listRecent = 'desktop_listRecent',
  listChats = 'desktop_listChats',
  listConferences = 'desktop_listConferences',
  listInternalDesktops = 'desktop_listInternalDesktops',
  listSharedDesktops = 'desktop_listSharedDesktops',
  listPrivateDesktops = 'desktop_listPrivateDesktops',
  listExternallySharedDesktopLabel = 'desktop_listExternallySharedDesktopLabel',
  listAddButton = 'desktop_listAddButton',
  listInternalDesktopsEmptyText = 'desktop_listInternalDesktopsEmptyText',
  listSharedDesktopsEmptyText = 'desktop_listSharedDesktopsEmptyText',
  listPrivateDesktopsEmptyText = 'desktop_listPrivateDesktopsEmptyText',
  listNewMeetingButtonTooltip = 'desktop_listNewMeetingButtonTooltip',
  listScheduleConferenceButtonTooltip = 'desktop_listScheduleConferenceButtonTooltip',
  listAddNewDesktopButton = 'desktop_listAddNewDesktopButton',

  createModalHeader = 'desktop_createModalHeader',
  createInternalDesktopModalHeader = 'desktop_createInternalDesktopModalHeader',
  createSubmitButton = 'desktop_createSubmitButton',
  createCancelButton = 'desktop_createCancelButton',
  editModalHeader = 'desktop_editModalHeader',
  editSubmitButton = 'desktop_editSubmitButton',
  formInternalDesktopMessageTitle = 'desktop_formInternalDesktopMessageTitle',
  formInternalDesktopSubMessageTitle = 'desktop_formInternalDesktopSubMessageTitle',
  formInternalDesktopMessageText = 'desktop_formInternalDesktopMessageText',
  formNameLabel = 'desktop_formNameLabel',
  formNamePlaceholder = 'desktop_formNamePlaceholder',
  formNameRequiredError = 'desktop_formNameRequiredError',
  formDescriptionLabel = 'desktop_formDescriptionLabel',
  formDescriptionPlaceholder = 'desktop_formDescriptionPlaceholder',
  formAddAccountLabel = 'desktop_formAddAccountLabel',
  formAddImportDesktopLabel = 'desktop_formAddImportDesktopLabel',
  formDefaultDesktopName = 'desktop_formDefaultDesktopName',
  formContinueButton = 'desktop_formContinueButton',

  headerAddToDesktop = 'desktop_headerAddToDesktop',
  headerApps = 'desktop_headerApps',
  headerLinks = 'desktop_headerLinks',
  headerChat = 'desktop_headerChat',
  headerAddButton = 'desktop_headerAddButton',
  headerButtonAddApp = 'desktop_headerButtonAddApp',
  headerButtonAddLink = 'desktop_headerButtonAddLink',
  headerAddApp = 'desktop_headerAddApp',
  headerAddLink = 'desktop_headerAddLink',
  headerCreateFolder = 'desktop_headerCreateFolder',
  headerEnableChat = 'desktop_headerEnableChat',
  headerChatIsEnabled = 'desktop_headerChatIsEnabled',
  headerTeamsCounter = 'desktop_headerTeamsCounter',
  headerUsersCounter = 'desktop_headerUsersCounter',

  sectionTitlebarOpenInChatsTooltip = 'desktop_sectionTitlebarOpenInChatsTooltip',
  sectionTitlebarAddLinkTooltip = 'desktop_sectionTitlebarAddLinkTooltip',
  sectionTitlebarCreateFolderTooltip = 'desktop_sectionTitlebarCreateFolderTooltip',
  sectionTitlebarOptionsTooltip = 'desktop_sectionTitlebarOptionsTooltip',
  sectionTitlebarMaximiseTooltip = 'desktop_sectionTitlebarMaximiseTooltip',
  sectionTitlebarFilterAppsTooltip = 'desktop_sectionTitlebarFilterAppsTooltip',
  sectionTitlebarFilterAppsPlaceHolder = 'desktop_sectionTitlebarFilterAppsPlaceHolder',
  sectionTitlebarFilterLinksTooltip = 'desktop_sectionTitlebarFilterLinksTooltip',
  sectionTitlebarFilterLinksPlaceHolder = 'desktop_sectionTitlebarFilterLinksPlaceHolder',
  sectionTitlebarFilterMessagePlaceHolder = 'desktop_sectionTitlebarFilterMessagePlaceHolder',
  sectionDeleteMenuItem = 'desktop_sectionDeleteMenuItem',
  minimizeButtonTooltip = 'desktop_minimizeButtonTooltip',
  sectionDeleteAppsConfirmationText = 'desktop_sectionDeleteAppsConfirmationText',
  sectionDeleteLinksConfirmationText = 'desktop_sectionDeleteLinksConfirmationText',

  notAvailableDesktop = 'desktop_notAvailableDesktop',
  desktopEmptyState = 'desktop_desktopEmptyState',
  emptyHeader = 'emptyHeader',
  emptyMessage = 'emptyMessage',
  importYourApps = 'desktop_importYourApps',
  emptyAppsStateHelpText = 'desktop_emptyAppsStateHelpText',
  emptyAppsStateOnboardingTour = 'desktop_emptyAppsStateOnboardingTour',
  emptyAppsStateFaqLink = 'desktop_emptyAppsStateFaqLink',
  addNewApp = 'desktop_addNewApp',
  emptyAddAppsButton = 'desktop_emptyAddAppsButton',
  emptyAddAppsOr = 'desktop_emptyAddAppsOr',
  emptyAppsAppDummyTitle = 'desktop_emptyAppsAppDummyTitle',
  emptyAppsMessageFavorites = 'desktop_emptyAppsMessageFavorites',
  emptyAppsMessageFavoritesInstructions = 'desktop_emptyAppsMessageFavoritesInstructions',

  importFromBrowserButton = 'desktop_importFromBrowserButton',
  importLoginComputerBrowserWarning = 'desktop_importLoginComputerBrowserWarning',

  appLinkFavorite = 'desktop_appLinkFavorite',
  appLinkSSOLabel = 'desktop_appLinkSSOLabel',
  appLinkAddToDesktop = 'desktop_appLinkAddToDesktop',
  appLinkAppLinks = 'desktop_appLinkAppLinks',
  appSelectVaultApp = 'desktop_desktop_appSelectVaultApp',
  appLinkCopyLoginApp = 'desktop_appLinkCopyLoginApp',
  appLinkCopyPasswordApp = 'desktop_appLinkCopyPasswordApp',
  appShowDetailsApp = 'desktop_appShowDetailsApp',
  appLinkRemoveApp = 'desktop_appLinkRemoveApp',
  appLinkRemoveAppConfirmation = 'desktop_appLinkRemoveAppConfirmation',
  appLinkLoginCopiedMessage = 'desktop_appLinkLoginCopiedMessage',
  appLinkPasswordCopiedMessage = 'desktop_appLinkPasswordCopiedMessage',
  leaveConfirmation = 'desktop_leaveConfirmation',
  deleteConfirmation = 'desktop_deleteConfirmation',
  createConferenceConfirmationTitle = 'desktop_createConferenceConfirmationTitle',
  createConferenceConfirmationDescription = 'desktop_createConferenceConfirmationDescription',

  appStoreModalHeaderTitle = 'desktop_appStoreModalHeaderTitle',

  upgradeBannerProTitle = 'desktop_upgradeBannerProTitle',
  upgradeBannerProText = 'desktop_upgradeBannerProText',
  upgradeBannerProCtaText = 'desktop_upgradeBannerProCtaText',

  addItemToFavorites = 'desktop_addItemToFavorites',
  removeItemFromFavorites = 'desktop_removeItemFromFavorites',

  selectLinkCheckbox = 'desktop_selectLinkCheckbox',

  moveLinkConfirmHeader = 'desktop_moveLinkConfirmHeader',
  moveFolderConfirmHeader = 'desktop_moveFolderConfirmHeader',
  moveAppConfirmHeader = 'desktop_moveAppConfirmHeader',
  moveItemToDesktopConfirmation = 'desktop_moveItemToDesktopConfirmation',
  moveItemsToDesktopConfirmation = 'desktop_moveItemsToDesktopConfirmation',
  moveItemToDesktopConfirmButtonText = 'desktop_moveItemToDesktopConfirmButtonText',

  movedLinkToDesktopSuccess = 'desktop_movedLinkToDesktopSuccess',
  movedLinksToDesktopSuccess = 'desktop_movedLinksToDesktopSuccess',
  movedAppToDesktopSuccess = 'desktop_movedAppToDesktopSuccess',
  movedFolderToDesktopSuccess = 'desktop_movedFolderToDesktopSuccess',
  noMoveLinkPermission = 'desktop_noMoveLinkPermission',
  noMoveFolderPermission = 'desktop_noMoveFolderPermission',
  noMoveAppPermission = 'desktop_noMoveAppPermission',

  showOptionsButtonLabel = 'desktop_showOptionsButtonLabel',
  hideOptionsButtonLabel = 'desktop_hideOptionsButtonLabel',

  showChatButtonLabel = 'desktop_showChatButtonLabel',
  hideChatButtonLabel = 'desktop_hideChatButtonLabel',

  searchAppsResultNoFound = 'desktop_searchAppsResultNoFound',
  filterButton = 'desktop_filterButton',
  filterInputPlaceholder = 'desktop_filterInputPlaceholder',
  filterCancelButtonText = 'desktop_filterCancelButtonText',
  filterEmptyStateTitle = 'desktop_filterEmptyStateTitle',
  filterEmptyStateDescription = 'desktop_filterEmptyStateDescription',

  desktopEmptyViewTitle = 'desktop_desktopEmptyViewTitle',
  desktopEmptyViewSubTitle = 'desktop_desktopEmptyViewSubTitle',
  desktopEmptyViewFooter = 'desktop_desktopEmptyViewFooter',
  desktopEmptyViewFooterStartAn = 'desktop_desktopEmptyViewFooterStartAn',
  desktopEmptyFavoritesHeader = 'desktop_desktopEmptyFavoritesHeader',
  desktopEmptyFavoritesMessage = 'desktop_desktopEmptyFavoritesMessage',
  desktopEmptyViewFooterOnboardingTour = 'desktop_desktopEmptyViewFooterOnboardingTour',
  desktopEmptyViewFooterFaq = 'desktop_desktopEmptyViewFooterFaq',

  chromeExtensionCredentials = 'desktop_chromeExtensionCredentials',

  tooltipManageDesktop = 'desktop_tooltipManageDesktop',
  tooltipGlobalSearchWorkspace = 'desktop_tooltipGlobalSearchWorkspace',
  tooltipPeopleButton = 'desktop_tooltipPeopleButton',
  tooltipNotificationsButton = 'desktop_tooltipNotificationsButton',
  tooltipInboxButton = 'desktop_tooltipInboxButton',
  tooltipActivityButton = 'desktop_tooltipActivityButton',
  tooltipShareDesktopButton = 'desktop_tooltipShareDesktopButton',
  tooltipAddDesktopContentButton = 'desktop_tooltipAddDesktopContentButton',
  tooltipVideoCallButton = 'desktop_tooltipVideoCallButton',
  tooltipAudioCallButton = 'desktop_tooltipAudioCallButton',
  tooltipProfileAndSettings = 'desktop_tooltipProfileAndSettings',

  callToJustOneOrCoupleUsers = 'desktop_callToJustOneOrCoupleUsers',
  callAllMembersButton = 'desktop_callAllMembersButton',
  callAllMembersEmptyList = 'desktop_callAllMembersEmptyList',
  tooltipInternalDesktopList = 'desktop_tooltipInternalDesktopList',
  tooltipSharedlDesktopList = 'desktop_tooltipSharedlDesktopList',
  tooltipPrivateDesktopList = 'desktop_tooltipPrivateDesktopList',
  mobileMenuFavorites = 'desktop_mobileMenuFavorites',

  copyDesktopSectionMenuItem = 'desktop_copyDesktopSectionMenuItem',
  copyDesktopAppsSectionMenuItem = 'desktop_copyDesktopAppsSectionMenuItem',
  copyDesktopLinksSectionMenuItem = 'desktop_copyDesktopLinksSectionMenuItem',
  copyDesktopAppsSectionTooltip = 'copyDesktopAppsSectionTooltip',
  copyDesktopLinksSectionTooltip = 'desktop_copyDesktopLinksSectionTooltip',
  copyDesktopAppsSuccess = 'desktop_copyDesktopAppsSuccess',
  copyDesktopLinksSuccess = 'desktop_copyDesktopLinksSuccess',
  copyDesktopAppsDataNote = 'desktop_copyDesktopAppsDataNote',
  copyDesktopLinksDataNote = 'desktop_copyDesktopLinksDataNote',

  pasteDataDesktopControlsMenuItem = 'desktop_pasteDataDesktopControlsMenuItem',
  pasteDesktopDataMenuItem = 'desktop_pasteDesktopDataMenuItem',
  pasteDesktopAppsDataMenuItem = 'desktop_pasteDesktopAppsDataMenuItem',
  pasteDesktopAppsSectionTooltip = 'desktop_pasteDesktopAppsSectionTooltip',
  pasteDesktopLinksDataMenuItem = 'desktop_pasteDesktopLinksDataMenuItem',
  pasteDesktopLinksSectionTooltip = 'desktop_pasteDesktopLinksSectionTooltip',
  pasteDesktopDataSectionTooltip = 'desktop_pasteDesktopDataSectionTooltip',

  pasteClipboardAppsConfirmationTitle = 'desktop_pasteClipboardAppsConfirmationTitle',
  pasteClipboardAppsConfirmationDescription = 'desktop_pasteClipboardAppsConfirmationDescription',
  pasteClipboardAppsConfirmationButtonLabel = 'desktop_pasteClipboardAppsConfirmationButtonLabel',

  pasteClipboardSingleApp = 'desktop_pasteClipboardSingleApp',
  pasteClipboardManyApps = 'desktop_pasteClipboardManyApps',

  pasteClipboardLinksConfirmationTitle = 'desktop_pasteClipboardLinksConfirmationTitle',
  pasteClipboardLinksConfirmationDescription = 'desktop_pasteClipboardLinksConfirmationDescription',
  pasteClipboardLinksConfirmationButtonLabel = 'desktop_pasteClipboardLinksConfirmationButtonLabel',

  pasteClipboardSingleLink = 'desktop_pasteClipboardSingleLink',
  pasteClipboardManyLinks = 'desktop_pasteClipboardManyLinks',

  chatSearchMessageListNoResults = 'desktop_chatSearchMessageListNoResults',

  tooltipCreateDesktop = 'desktop_tooltipCreateDesktop',
  tooltipFavoritesMenuItem = 'desktop_tooltipFavoritesMenuItem',
  tooltipPasswordsMenuItem = 'desktop_tooltipPasswordsMenuItem',
  tooltipPasswordsUnlockButton = 'desktop_tooltipPasswordsUnlockButton',
  tooltipChatsMenuItem = 'desktop_tooltipChatsMenuItem',
  tooltipCallsAndMeetingsMenuItem = 'desktop_tooltipCallsAndMeetingsMenuItem',

  tooltipExt = 'desktop_tooltipExt',
  tooltipAppMoreActions = 'desktop_tooltipAppMoreActions',
  tooltipShowNavigation = 'desktop_tooltipShowNavigation',
  tooltipHideNavigation = 'desktop_tooltipHideNavigation',
  appMarkAsFavorite = 'desktop_appMarkAsFavorite',
  appUnMarkAsFavorite = 'desktop_appUnMarkAsFavorite',

  usersSelectGroupTypeGroups = 'desktop_usersSelectGroupTypeGroups',
  userRightsFullAccess = 'desktop_userRightsFullAccess',
  userRightsFullAccessSubTitle = 'desktop_userRightsFullAccessSubTitle',
  userRightsCanEdit = 'desktop_userRightsCanEdit',
  userRightsCanEditSubTitle = 'desktop_userRightsCanEditSubTitle',
  userRightsCanComment = 'desktop_userRightsCanComment',
  userRightsCanCommentSubTitle = 'desktop_userRightsCanCommentSubTitle',
}

export type DesktopLocale = {
  [key in DesktopTranslation]: string;
};
